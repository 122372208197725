import { Card, Icon } from '@shopify/polaris'
import React, { useEffect } from 'react'
import {
    DragHandleMinor, TextMajor, PinMajor, ImageWithTextMajor, MobileBackArrowMajor
} from '@shopify/polaris-icons';
import dayjs from 'dayjs';

const Upsells = (props) => {
    const { showEditUpsell, setShowEditUpsell, allUpsells, formatDate, setShowHeader, setUpsellRowData, setUpsellFormData, upsellInitValues } = props

    useEffect(() => {
        setShowHeader(false);
    }, [])

    const editUpsell = (upsell) => {
        setShowEditUpsell(true);
        upsell.popup_translations ||= {}
        if (upsell?.start_date) {
            upsell.active_date = dayjs(upsell?.start_date).format('YYYY-MM-DD')
            upsell.active_time = dayjs(upsell?.start_time).format('hh:mm')
        }
        if (upsell?.end_date) {
            upsell.end_date = dayjs(upsell?.end_date).format('YYYY-MM-DD')
            upsell.end_time = dayjs(upsell?.end_time).format('hh:mm')
        }
        setUpsellRowData(upsell);
    }

    return (
        <div className='upsell_index_container'>
            <div className='custom_sub_header_wrapper'>
                <h1 className="shopify_style_header">
                    Upsells
                </h1>
                <div className='chargezen_button_group'>
                    <button className='chargezen_primary' onClick={() => { setShowEditUpsell(true); setUpsellRowData(upsellInitValues); setUpsellFormData(upsellInitValues) }}>Add upsell</button>
                </div>
            </div>
            <Card
                title={<div className="heading_title">Customer portal upsells</div>}
                sectioned
            >
                <div className="card-box upsell_form_wrapper">
                    <ul className='upsell_listings'>
                        {
                            allUpsells?.map((upsell) => {
                                return (
                                    <li onClick={() => editUpsell(upsell)}>
                                        <div className='upsell_listing_content'>
                                            <div className='left_section'>
                                                <div className='drg_svg_wrapper'>
                                                    <Icon
                                                        source={DragHandleMinor}
                                                        tone="base"
                                                    />
                                                </div>
                                                <div className='upsell_desc_wrapper'>
                                                    <h5>{upsell?.title}</h5>
                                                    <div className='start_end_wrapper'>
                                                        {
                                                            upsell?.start_date && (
                                                                <span className='start_end_body'>
                                                                    Start date: {formatDate(upsell?.start_date)}
                                                                </span>
                                                            )
                                                        }
                                                        {
                                                            upsell?.end_date && (
                                                                <span className='start_end_body' style={{ marginLeft: "6px" }}>
                                                                    End date: {formatDate(upsell?.end_date)}
                                                                </span>
                                                            )
                                                        }

                                                    </div>
                                                </div>
                                            </div>
                                            <div className='right_section'>
                                                <div className=' upsell_sec'>
                                                    <div className='drg_svg_wrapper'>
                                                        <Icon
                                                            source={TextMajor}
                                                            tone="base"
                                                        />
                                                    </div>
                                                    <span>{upsell?.upsell_type}</span>
                                                </div>
                                                <div className=' upsell_sec'>
                                                    {upsell?.status}
                                                </div>
                                                <div className=' upsell_sec'>
                                                    <div className='drg_svg_wrapper pin_icon'>
                                                        <Icon
                                                            source={PinMajor}
                                                            tone="base"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                )
                            })
                        }
                    </ul>
                </div>
            </Card>
        </div>
    )
}

export default Upsells