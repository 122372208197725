import React, { useEffect, useState } from 'react';
import { Frame, Modal, Select, TextField } from '@shopify/polaris';

const EditAffiliateModal = (props) => {
  const {
    editAffiliate,
    setEditAffiliate,
    apiData,
    domain,
    setToastContent,
    fetchAffiliate,
  } = props;
  const [currentState, setCurrentState] = useState([]);

  let initialValues = {
    id: apiData?.user_affiliate_info?.id,
    address1: apiData?.user_affiliate_info?.address1 || '',
    address2: apiData?.user_affiliate_info?.address2 || '',
    city_town: apiData?.user_affiliate_info?.city_town || '',
    country: apiData?.user_affiliate_info?.country,
    state: apiData?.user_affiliate_info?.state,
    postcode: apiData?.user_affiliate_info?.postcode,
    primary_region: apiData?.user_affiliate_info?.primary_region,
    street_name: apiData?.user_affiliate_info?.street_name,
    company_name: apiData?.user_affiliate_info?.company_name,
  };

  const [formData, setFormData] = useState(initialValues);
  const [countryBasedLabels, setCountryBasedLabels] = useState({});
  const [fieldsToNotShow, setFieldsToNotShow] = useState([]);

  const getAllStates = () => {
    fetch(`/get_country_states?country=${formData.country}`, {
      method: 'GET',
    })
      .then((r) => r.json())
      .then((response) => {
        let allStatesOptions = response.states || [];
        setCurrentState([...allStatesOptions]);
      });
  };

  const renderCountryBasedForm = () => {
    let fieldsNotToRender = [];
    let customLabels = {};
    if (formData?.country == 'Australia') {
      customLabels = {
        postcode: 'Postcode',
        city_town: 'Suburb',
        address2: 'Apartment, suite, etc.',
        state: 'State/territory',
      };
    } else if (formData?.country == 'Austria') {
      customLabels = {
        postcode: 'Postal code',
        city_town: 'City',
        address1: 'Street and house number',
        address2: 'Additional address',
      };
      fieldsNotToRender.push('state');
    } else if (formData?.country == 'Bahamas') {
      customLabels = {
        city_town: 'City',
        address1: 'Address',
        address2: 'Apartment, suite, etc.',
      };
      fieldsNotToRender.push('state', 'postcode');
    } else if (formData?.country == 'Belarus') {
      customLabels = {
        postcode: 'Postal code',
        city_town: 'City',
        address1: 'Address',
        address2: 'Apartment, suite, etc.',
      };
      fieldsNotToRender.push('state');
    } else if (formData?.country == 'Belgium') {
      customLabels = {
        postcode: 'Postal code',
        city_town: 'City',
        address1: 'Address',
        address2: 'Apartment, suite, etc.',
      };
      fieldsNotToRender.push('state');
    } else if (formData?.country == 'Belize') {
      customLabels = {
        city_town: 'City',
        address1: 'Address',
        address2: 'Apartment, suite, etc.',
      };
      fieldsNotToRender.push('state', 'postcode');
    } else if (formData?.country == 'Benin') {
      customLabels = {
        city_town: 'City',
        address1: 'Address',
        address2: 'Apartment, suite, etc.',
      };
      fieldsNotToRender.push('state', 'postcode');
    } else if (formData?.country.includes('Bolivia')) {
      customLabels = {
        city_town: 'City',
        address1: 'Address',
        address2: 'Apartment, suite, etc.',
      };
      fieldsNotToRender.push('state', 'postcode');
    } else if (formData?.country?.includes('Bosnia')) {
      customLabels = {
        postcode: 'Postal code',
        city_town: 'City',
        address1: 'Address',
        address2: 'Apartment, suite, etc.',
      };
      fieldsNotToRender.push('state');
    } else if (formData?.country == 'Botswana') {
      customLabels = {
        city_town: 'City',
        address1: 'Address',
        address2: 'Apartment, suite, etc.',
      };
      fieldsNotToRender.push('state', 'postcode');
    } else if (formData?.country == 'Brazil') {
      customLabels = {
        state: 'State',
        postcode: 'Postal code',
        city_town: 'City',
        address1: 'Street and house number',
        address2: 'Apartment, suite, etc.',
      };
    } else if (formData?.country == 'Netherlands') {
      customLabels = {
        postcode: 'Postal code',
        city_town: 'City',
        address1: 'Street and house number',
        address2: 'Apartment, suite, etc.',
      };
      fieldsNotToRender.push('state');
    } else if (formData?.country == 'Germany') {
      customLabels = {
        postcode: 'Postal code',
        city_town: 'City',
        address1: 'Street and house number',
        address2: 'Apartment, suite, etc.',
      };
      fieldsNotToRender.push('state');
    }

    setFieldsToNotShow([...fieldsNotToRender]);
    setCountryBasedLabels({ ...customLabels });
  };

  console.log('allstates', currentState, formData);

  useEffect(() => {
    getAllStates();
    renderCountryBasedForm();
  }, [formData?.country]);

  const handleChange = (e, key) => {
    setFormData({ ...formData, [key]: e });
  };

  const updateAffiliateUserInfo = () => {
    let formdata = formData;
    formdata.shopify_domain = domain;
    fetch(`/updateAffiliateUserInfo`, {
      method: 'PUT',
      body: JSON.stringify(formdata),
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((r) => r.json())
      .then((data) => {
        if (data.success) {
          fetchAffiliate();
          setToastContent('Updated successfully...');
          setEditAffiliate(false);
        }
      });
  };

  return (
    <div>
      <Modal
        open={editAffiliate}
        onClose={() => {
          setEditAffiliate(false);
        }}
        title="Edit affiliate profile"
        primaryAction={{
          content: 'Save',
          onAction: () => {
            updateAffiliateUserInfo();
          },
        }}
        secondaryActions={[
          {
            content: 'Cancel',
            onAction: () => {
              setEditAffiliate(false);
            },
          },
        ]}
      >
        <Modal.Section>
          <div className="chargezen_form_row">
            <TextField
              label="Legal company name"
              value={formData?.company_name}
              onChange={(e) => {
                handleChange(e, 'company_name');
              }}
            />
          </div>
          <div className="chargezen_form_row" style={{ margin: '16px 0 32px' }}>
            <Select
              label="Country"
              disabled
              options={[apiData?.user_affiliate_info?.country]}
              onChange={(e) => {}}
              value={apiData?.user_affiliate_info?.country}
              helpText={
                <div className="affiliate_country_helptext">
                  If you want to change your country, you’ll need to{' '}
                  <a href="#" target="_blank">
                    create a new affiliate account
                  </a>
                </div>
              }
            />
          </div>
          <div className="chargezen_form_row flex_wrapper">
            {!fieldsToNotShow.includes('address1') && (
              <TextField
                label={countryBasedLabels?.address1 || 'Address1'}
                value={formData?.address1}
                onChange={(e) => {
                  handleChange(e, 'address1');
                }}
              />
            )}
            {!fieldsToNotShow.includes('address2') && (
              <TextField
                label={countryBasedLabels?.address2 || 'Address2'}
                value={formData?.address2}
                onChange={(e) => {
                  handleChange(e, 'address2');
                }}
              />
            )}
          </div>
          <div className="chargezen_form_row">
            <TextField
              label="Primary residence"
              value={formData?.primary_region}
              onChange={(e) => {
                handleChange(e, 'primary_region');
              }}
            />
          </div>
          <div
            className={`chargezen_form_row ${
              fieldsToNotShow.includes('street_name') ? 'hidden' : ''
            }`}
          >
            <TextField
              label="Apartment, suite, etc."
              value={formData?.street_name}
              onChange={(e) => {
                handleChange(e, 'street_name');
              }}
            />
          </div>
          <div className="chargezen_form_row flex_wrapper">
            {!fieldsToNotShow.includes('city_town') && (
              <TextField
                label={countryBasedLabels?.city_town || 'City'}
                value={formData?.city_town}
                onChange={(e) => {
                  handleChange(e, 'city_town');
                }}
              />
            )}
            {!fieldsToNotShow.includes('state') && (
              <Select
                label={countryBasedLabels?.state || 'State'}
                options={currentState}
                onChange={(e) => {
                  handleChange(e, 'state');
                }}
                value={formData?.state}
              />
            )}
          </div>
          <div
            className={`chargezen_form_row ${
              fieldsToNotShow.includes('postcode') ? 'hidden' : ''
            }`}
          >
            <TextField
              label={countryBasedLabels?.postcode || 'Zipcode'}
              value={formData?.postcode}
              onChange={(e) => {
                handleChange(e, 'postcode');
              }}
            />
          </div>
        </Modal.Section>
      </Modal>
    </div>
  );
};

export default EditAffiliateModal;
