import { gql, useLazyQuery } from '@apollo/client';
import { Button, Checkbox, TextField } from '@shopify/polaris';
import React, { useEffect, useState } from 'react';
import './style.css';

const SearchCommission = (props) => {
  const {
    domain,
    commissionSearchQuery,
    setCommissionSearchQuery,
    formData,
    setFormData,
    selectedCommission,
    setSelectedCommission,
    setShowCommissionSearch,
  } = props;
  const [allCommissions, setAllCommissions] = useState([]);

  const getCommission = () => {
    fetch(
      `/search_commissions?searchquery=${commissionSearchQuery}&shop=${domain}`,
      {
        method: 'GET',
      }
    )
      .then((r) => r.json())
      .then((response) => {
        if (response?.commissions?.length > 0) {
          setAllCommissions([...response.commissions]);
        }
        console.log('response.affiliates', response, response.commissions);
      });
  };

  useEffect(() => {
    getCommission();
  }, [commissionSearchQuery]);

  const handleChange = (e, commission) => {
    let selectedcommission = [];
    if (e) {
      selectedcommission.push(commission);
    } else {
      selectedcommission = selectedcommission?.filter(
        (aff) => aff.id !== commission.id
      );
    }
    setSelectedCommission(selectedcommission);
  };

  const closeModal = () => {
    setShowCommissionSearch();
  };

  return (
    <div className="chargezen_search_model">
      <div className="modal-content-wrapper">
        <div className="chargezen_search_model_header_container">
          <h2>Select a commission profile</h2>
        </div>
        <div className="chargezen_search_model_body">
          <TextField
            value={commissionSearchQuery}
            label=""
            placeholder="Search commission profiles"
            onChange={(e) => {
              setCommissionSearchQuery(e);
            }}
          />
          <div className="segments_list_wrapper_container">
            <ul className="segments_lists">
              {allCommissions?.map((commission) => {
                return (
                  <li>
                    <Checkbox
                      label={
                        <div className="name_email_wrapper">
                          <p className="name_wrapper">
                            {commission?.automatic_tracking_title}
                          </p>
                          <p className="email_wrapper">
                            {commission?.commission}
                          </p>
                        </div>
                      }
                      checked={selectedCommission.some(
                        (a) => a.id === commission.id
                      )}
                      onChange={(e) => {
                        handleChange(e, commission);
                      }}
                    />
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
        <div className="chargezen_search_model_footer">
          <Button onClick={() => closeModal()}>Cancel</Button>
          <Button
            onClick={() => {
              setFormData({
                ...formData,
                commission_profile: selectedCommission[0],
              });
              closeModal();
            }}
          >
            Select
          </Button>
        </div>
      </div>
    </div>
  );
};

export default SearchCommission;
