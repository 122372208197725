import {
  Badge,
  Banner,
  Button,
  ButtonGroup,
  Card,
  Checkbox,
  DataTable,
  Form,
  FormLayout,
  Frame,
  Icon,
  List,
  Page,
  RadioButton,
  ResourceItem,
  ResourceList,
  Select,
  Stack,
  TextField,
  Toast,
} from '@shopify/polaris';
import {
  ChevronRightMinor,
  MobileBackArrowMajor,
} from '@shopify/polaris-icons';
import React, { useCallback, useEffect, useState } from 'react';
import { gql, useQuery } from '@apollo/client';

import EditPointsProgramTiers from './EditPointsProgramTiers';
import LoadingScreen from '../../LoadingScreen';
import PixelIcon from '../../../images/PixelIcon';
import AddApplePassBackFields from './AddApplePassBackFields';

const AppleWalletPasses = (props) => {
  const { i18n, domain, detailView, setDetailView } = props;
  const defaultLogo =
    'https://cdn.shopify.com/shopifycloud/web/assets/v1/c30f6bec42c375fd.png';
  const defaultBanner =
    'https://cdn.shopify.com/shopifycloud/web/assets/v1/7db28644100d0c6d.png';
  const initValue = {
    pass_header: 'My store',
    pass_color: '#e33434',
    regular_text_color: '#fff',
    secondary_text_color: '#eccece',
  };
  const [formData, setFormData] = useState(initValue);
  const [dynamicStyle, setDynamicStyle] = useState(``);
  const [deatilPreview, setDeatilPreview] = useState(false);
  const [backFieldsData, setBackFieldsData] = useState([]);

  const fetchApplePaySetting = () => {
    fetch('/fetch_apple_pass_setting', {
      method: 'GET',
    })
      .then((r) => r.json())
      .then((data) => {
        let preview_image = {};
        if (data.apple_pass_setting) {
          setFormData(data.apple_pass_setting);
        }
        if (data.back_fields?.length > 0) {
          setBackFieldsData(data?.back_fields);
        }
        preview_image.customLogoImage = data.custom_logo || defaultLogo;
        preview_image.customBannerImage = data.custom_banner || defaultBanner;
        setPreviewImages(preview_image);
      });
  };

  useEffect(() => {
    fetchApplePaySetting();
  }, []);

  const saveAppleWalletSetting = () => {
    let _formData = new FormData();
    _formData.append('activate_apple_wallet', formData.activate_apple_wallet);
    _formData.append('title', formData.title);
    _formData.append('subtitle', formData.subtitle);
    _formData.append('pass_header', formData.pass_header);
    _formData.append('pass_color', formData.pass_color);
    _formData.append('regular_text_color', formData.regular_text_color);
    _formData.append('secondary_text_color', formData.secondary_text_color);
    _formData.append('secondary_text_color', formData.secondary_text_color);
    _formData.append('shopify_domain', domain);
    _formData.append('backFieldsData_length', backFieldsData.length);
    _formData.append('back_fields', JSON.stringify(backFieldsData));

    if (formData?.custom_logo_image) {
      _formData.append('custom_logo', formData.custom_logo_image);
    }
    if (formData?.custom_banner_image) {
      _formData.append('custom_banner', formData.custom_banner_image);
    }

    fetch('/save_apple_wallet_pass_setting', {
      method: 'POST',
      body: _formData,
    })
      .then((r) => r.json())
      .then((data) => {
        setToastActive(true);
        if (data.status) {
          setToastContent('Saved Successfully');
        } else {
          setToastContent('Something went wrong...');
        }
      });
  };

  useEffect(() => {
    const _style = `
            .screen_main_container{
                background-color: ${formData?.pass_color} !important;
            }
            .regular_text_color {
                color: ${formData?.regular_text_color} !important;
            }
            .secondary_text_color {
                color: ${formData?.secondary_text_color} !important;
            }
        `;
    setDynamicStyle(_style);
  }, [
    formData?.pass_color,
    formData?.regular_text_color,
    formData?.secondary_text_color,
  ]);

  const [toastContent, setToastContent] = useState('');
  const [toastActive, setToastActive] = useState(false);
  const toggleToastActive = useCallback(
    () => setToastActive((toastActive) => !toastActive),
    []
  );
  const [previewImages, setPreviewImages] = useState({});

  useEffect(() => {
    let customLogoImage = defaultLogo;
    let customBannerImage = defaultBanner;
    if (formData?.custom_logo_image) {
      customLogoImage = URL.createObjectURL(formData?.custom_logo_image);
    }
    if (formData?.custom_banner_image) {
      customBannerImage = URL.createObjectURL(formData?.custom_banner_image);
    }
    setPreviewImages({
      customLogoImage: customLogoImage,
      customBannerImage: customBannerImage,
    });
  }, [formData?.custom_logo_image, formData?.custom_banner_image]);

  const addMoreBackFields = () => {
    setBackFieldsData([
      ...backFieldsData,
      { label: '', value: '', attributed_value: '' },
    ]);
  };
  return (
    <>
      {toastActive && (
        <Toast content={toastContent} onDismiss={toggleToastActive} />
      )}
      <style dangerouslySetInnerHTML={{ __html: dynamicStyle }} />
      <div className="without_header_card">
        <Card
          primaryFooterAction={{
            content: 'Save',
            onAction: saveAppleWalletSetting,
          }}
        >
          <Card.Section>
            <div className={'applepay_wallet_passes_container'}>
              <div className="color_row_wrapper pass_page_settings_wrapper">
                <h5 class="custom_label">Pass page settings</h5>
                <div className="form_row">
                  <TextField
                    label="Title"
                    type="text"
                    value={formData.title}
                    onChange={(e) => {
                      setFormData({ ...formData, title: e });
                    }}
                    autoComplete="off"
                  />
                  <TextField
                    label="Subtitle"
                    type="text"
                    value={formData.subtitle}
                    onChange={(e) => {
                      setFormData({ ...formData, subtitle: e });
                    }}
                    autoComplete="off"
                  />
                </div>
              </div>
              <div className="form_section">
                <h2 className="form_main_label">Apple Wallet Passes</h2>
                <Checkbox
                  label="Activate Apple Wallet Passes for your loyalty program"
                  checked={formData?.activate_apple_wallet}
                  onChange={(e) => {
                    setFormData({ ...formData, activate_apple_wallet: e });
                  }}
                />
                {formData?.activate_apple_wallet && (
                  <div className="apple_pay_customizer_form_flex">
                    <div className="apple_pay_custom_customizer_form">
                      <h3 className="apple_pay_customizer_header">
                        You can customize the look and feel of your wallet pass
                        with the following options.
                      </h3>
                      <TextField
                        label="Apple Pass Header"
                        type="text"
                        value={formData.pass_header}
                        onChange={(e) => {
                          setFormData({ ...formData, pass_header: e });
                        }}
                        autoComplete="off"
                      />
                      <div className="color_row_wrapper">
                        <h5 class="custom_label">Pass Color</h5>
                        <div className="form_row">
                          <TextField
                            label=""
                            type="color"
                            value={formData.pass_color}
                            onChange={(e) => {
                              setFormData({ ...formData, pass_color: e });
                            }}
                            autoComplete="off"
                          />
                          <TextField
                            label=""
                            type="text"
                            value={formData.pass_color}
                            onChange={(e) => {
                              setFormData({ ...formData, pass_color: e });
                            }}
                            autoComplete="off"
                          />
                        </div>
                      </div>
                      <div className="color_row_wrapper">
                        <h5 class="custom_label">Regular text color</h5>
                        <div className="form_row">
                          <TextField
                            label=""
                            type="color"
                            value={formData.regular_text_color}
                            onChange={(e) => {
                              setFormData({
                                ...formData,
                                regular_text_color: e,
                              });
                            }}
                            autoComplete="off"
                          />
                          <TextField
                            label=""
                            type="text"
                            value={formData.regular_text_color}
                            onChange={(e) => {
                              setFormData({
                                ...formData,
                                regular_text_color: e,
                              });
                            }}
                            autoComplete="off"
                          />
                        </div>
                      </div>
                      <div className="color_row_wrapper">
                        <h5 class="custom_label">Secondary text color</h5>
                        <div className="form_row">
                          <TextField
                            label=""
                            type="color"
                            value={formData.secondary_text_color}
                            onChange={(e) => {
                              setFormData({
                                ...formData,
                                secondary_text_color: e,
                              });
                            }}
                            autoComplete="off"
                          />
                          <TextField
                            label=""
                            type="text"
                            value={formData.secondary_text_color}
                            onChange={(e) => {
                              setFormData({
                                ...formData,
                                secondary_text_color: e,
                              });
                            }}
                            autoComplete="off"
                          />
                        </div>
                      </div>

                      <div className="color_row_wrapper">
                        <div className="upload_custom_image_main_container">
                          <div className="upload_custom_image_container">
                            <Checkbox
                              label="Use a custom logo (90px × 90px)"
                              checked={formData?.custom_logo}
                              onChange={(e) => {
                                setFormData({ ...formData, custom_logo: e });
                              }}
                            />
                            {formData?.custom_logo && (
                              <div className="choose_file_wrapper">
                                <input
                                  type="file"
                                  multiple="false"
                                  accept="image/*"
                                  onChange={(e) => {
                                    setFormData({
                                      ...formData,
                                      custom_logo_image: e?.target?.files?.[0],
                                    });
                                  }}
                                />
                              </div>
                            )}
                          </div>
                          <div className="upload_custom_image_container">
                            <Checkbox
                              label="Use a custom banner (1125px x 432px)"
                              checked={formData?.custom_banner}
                              onChange={(e) => {
                                setFormData({ ...formData, custom_banner: e });
                              }}
                            />
                            {formData?.custom_banner && (
                              <div className="choose_file_wrapper">
                                <input
                                  type="file"
                                  multiple="false"
                                  accept="image/*"
                                  onChange={(e) => {
                                    setFormData({
                                      ...formData,
                                      custom_banner_image:
                                        e?.target?.files?.[0],
                                    });
                                  }}
                                />
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="color_row_wrapper">
                        <Checkbox
                          label={`${
                            deatilPreview
                              ? 'Show front preview'
                              : 'Show details preview'
                          } `}
                          checked={deatilPreview}
                          onChange={(e) => {
                            setDeatilPreview(e);
                          }}
                        />
                      </div>

                      <div className="color_row_wrapper">
                        {backFieldsData?.map((work, index) => {
                          return (
                            <AddApplePassBackFields
                              {...{
                                domain,
                                backFieldsData,
                                setBackFieldsData,
                                index,
                                i18n,
                              }}
                            />
                          );
                        })}
                      </div>
                      <div className="color_row_wrapper">
                        <Button
                          id="add_more_back_fields"
                          onClick={() => {
                            addMoreBackFields();
                          }}
                        >
                          <span className="plus_icon">+</span>
                          <span>Add more back fields</span>
                        </Button>
                      </div>
                    </div>
                    <div className="mobile_simulator_wrapper">
                      <div className="mobile_simulator_screen_container">
                        {deatilPreview ? (
                          <>
                            <div className="screen_main_container details_view_container">
                              <div class="details_view back_fields_wrapper">
                                {backFieldsData?.map((backField) => {
                                  return (
                                    <div
                                      class={`back_field_list ${
                                        backField.attributed_value.includes(
                                          '</a>'
                                        )
                                          ? ''
                                          : 'since-text'
                                      }`}
                                    >
                                      <span class="label_wrapper">
                                        {backField.label}
                                      </span>

                                      <span
                                        class={`value_wrapper ${
                                          backField.attributed_value.includes(
                                            '</a>'
                                          )
                                            ? 'contains anchor_tag'
                                            : ''
                                        }`}
                                        dangerouslySetInnerHTML={{
                                          __html: backField.attributed_value,
                                        }}
                                      />
                                    </div>
                                  );
                                })}
                              </div>
                            </div>
                          </>
                        ) : (
                          <>
                            <div className="screen_main_container">
                              <div className="mobile_header_wrapper">
                                <div className="left_section flex_wrapper">
                                  <div className="logo_wrapper">
                                    <img
                                      src={previewImages?.customLogoImage}
                                      alt=" "
                                    />
                                  </div>
                                  <div className="logo_title regular_text_color">
                                    {formData?.pass_header}
                                  </div>
                                </div>
                                <div className="right_section">
                                  <div className="balance_wrapper">
                                    <div className="balance_title secondary_text_color">
                                      BALANCE
                                    </div>
                                    <div className="balanace_container regular_text_color">
                                      $13.56
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="mobile_banner_wrapper">
                                <div className="banner_container">
                                  <img
                                    src={previewImages?.customBannerImage}
                                    alt=" "
                                  />
                                </div>
                              </div>
                              <div className="mobile_scanner_wrapper">
                                <div className="qr_code_wrapper">
                                  <img
                                    src="https://cdn.shopify.com/shopifycloud/web/assets/v1/d622771b83fc2479.png"
                                    alt=" "
                                  />
                                </div>
                              </div>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </Card.Section>
        </Card>
      </div>
    </>
  );
};

export default AppleWalletPasses;
