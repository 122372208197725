import { gql, useLazyQuery } from '@apollo/client';
import { Checkbox, TextField } from '@shopify/polaris';
import React, { useEffect, useState } from 'react';

const SearchCustomerSegments = (props) => {
  const { segmentSearchQuery, setSegmentSearchQuery } = props;
  const [allSegments, setAllSegments] = useState([]);
  const [selectedSegments, setSelectedSegments] = useState([]);
  const GET_SEGMENTS = gql`
    query ($query: String) {
      segments(first: 20, query: $query) {
        edges {
          node {
            id
            name
            query
          }
        }
      }
    }
  `;
  const [GetSegments, { loading, data, errors }] = useLazyQuery(GET_SEGMENTS, {
    fetchPolicy: 'no-cache',
    context: { clientName: 'shopify-link' },
  });

  useEffect(() => {
    GetSegments({ variables: { query: `` } });
  }, []);

  useEffect(() => {
    GetSegments({ variables: { query: `name:*${segmentSearchQuery}*` } });
  }, [segmentSearchQuery]);

  useEffect(() => {
    if (data && data.segments) {
      let allsegements = data.segments?.edges?.map((segment) => {
        return { id: segment.node.id, name: segment.node.name };
      });
      setAllSegments([...allsegements]);
    }
  }, [data]);

  const handleChange = (e, segementid) => {
    let selectedsegments = [...selectedSegments];
    if (e) {
      selectedsegments.push(segementid);
    } else {
      let index = selectedsegments.findIndex(
        (segment) => segment.id === segementid
      );
      selectedsegments.splice(index, 1);
    }
    setSelectedSegments(selectedsegments);
  };

  return (
    <div className="chargezen_search_model">
      <div className="chargezen_search_model_header_container">
        <h2>Add customer segments</h2>
      </div>
      <div className="chargezen_search_model_body">
        <TextField
          value={segmentSearchQuery}
          label=""
          placeholder="Search customer segments"
          onChange={(e) => {
            setSegmentSearchQuery(e);
          }}
        />
        <div className="segments_list_wrapper_container">
          <ul className="segments_lists">
            {allSegments?.map((segment) => {
              return (
                <li>
                  <Checkbox
                    label={segment?.name}
                    checked={setSelectedSegments?.includes(segment.id)}
                    onChange={(e) => {
                      handleChange();
                    }}
                  />
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default SearchCustomerSegments;
