import { gql, useMutation } from '@apollo/client';
import { Text, Card, Toast, ChoiceList, Page, PageActions, TextField, Layout, Banner, List, Frame, Select, Icon, Checkbox, Form, DropZone, Thumbnail, Stack, FormLayout, Button } from '@shopify/polaris';
import React, { useCallback, useEffect, useState } from 'react';
import PixelIcon from '../../../images/PixelIcon';
import { MobileBackArrowMajor } from '@shopify/polaris-icons';
import SearchProduct from '../../upsell/SearchProduct';
import Preview from '../../plans/Preview';

const PointsProgramTiersPerks = () => {
    const [formData, setFormData] = useState({
        title: '',
        free_gifts: false,
        discounts: false,
        with_bundles: false,
        new_products: false,
        exclusive_sales: false,
        events: false,
    });
    const validImageTypes = ['image/gif', 'image/jpeg', 'image/png'];

    const [fieldError, setFieldError] = useState({ title: '', free_gifts: '' });

    const handleChange = useCallback(
        (newChecked, key) => setFormData({ ...formData, [key]: newChecked }),
        [],
    );

    const handleSubmit = () => {
        return (<></>)
    }


    return (
        <Frame>
            <Page
                title={
                    <div className='back_button_wrapper'>
                        <div className="back-button pointer" style={{ marginTop: '15px' }} onClick={() => setEditForm(false)}>
                            <Icon
                                source={MobileBackArrowMajor}
                                color="base" />
                        </div>
                        <div class="text_wrapper">
                            {formData?.id ? i18n.t('edit') : i18n.t('add')}
                        </div>
                    </div>}
            >

                <Layout>
                    <Layout.Section>
                        <Card title={
                            <div className="heading_title">
                                <PixelIcon />
                                {i18n.t("points_programs.points_program_tiers_perks")}

                            </div>
                        } sectioned>
                            <Form>
                                <FormLayout>
                                    <FormLayout.Group>
                                        <TextField
                                            label={i18n.t("title")}
                                            type="text"
                                            value={`${formData?.title}`}
                                            onChange={(e) => {
                                                setFormData({ ...formData, title: e })
                                            }}
                                            error={fieldError?.title}
                                            autoComplete="off"
                                        />
                                    </FormLayout.Group>

                                    <FormLayout.Group>
                                        <Checkbox
                                            label={i18n.t("points_programs.free_gifts")}
                                            formData={formData?.free_gifts}
                                            onChange={(e) => handleChange(e, "free_gifts")}
                                        />

                                        <Checkbox
                                            label={i18n.t("points_programs.discounts")}
                                            formData={formData?.discounts}
                                            onChange={(e) => handleChange(e, "discounts")}
                                        />
                                    </FormLayout.Group>
                                    <FormLayout.Group>
                                        <Checkbox
                                            label={i18n.t("points_programs.with_bundles")}
                                            formData={formData?.with_bundles}
                                            onChange={(e) => handleChange(e, "with_bundles")}
                                        />

                                        <Checkbox
                                            label={i18n.t("points_programs.new_products")}
                                            formData={formData?.new_products}
                                            onChange={(e) => handleChange(e, "new_products")}
                                        />
                                    </FormLayout.Group>
                                    <FormLayout.Group>
                                        <Checkbox
                                            label={i18n.t("points_programs.exclusive_sales")}
                                            formData={formData?.exclusive_sales}
                                            onChange={(e) => handleChange(e, "exclusive_sales")}
                                        />

                                        <Checkbox
                                            label={i18n.t("points_programs.events")}
                                            formData={formData?.events}
                                            onChange={(e) => handleChange(e, "events")}
                                        />
                                    </FormLayout.Group>

                                </FormLayout>
                                <FormLayout>
                                    <FormLayout.Group>
                                        <Button primary onClick={handleSubmit}>{i18n.t("save")}</Button>
                                    </FormLayout.Group>
                                </FormLayout>
                            </Form>
                        </Card>
                    </Layout.Section>
                </Layout>
            </Page>
        </Frame >
    )
}

export default PointsProgramTiersPerks;