import React from 'react';

const CustomScrollbar = () => {
  const style = `
    /* width */
    ::-webkit-scrollbar {
      width: 10px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
      box-shadow: inset 0 0 5px white; 
      border-radius: 10px;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: gray; 
      border-radius: 10px;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
      background: gray; 
    }

    .Polaris-FooterHelp {
        display: none !important;
    }
  `;

  return (
    <style
      dangerouslySetInnerHTML={{
        __html: style,
      }}
    />
  );
};

export default CustomScrollbar;
