import React, { useEffect, useState } from 'react';
import { MobileCancelMajor } from '@shopify/polaris-icons';
import { Icon, TextField } from '@shopify/polaris';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const PreviewCollection = ({ allProducts, setAllProducts, setUpdated, isUpdate, collectionField, values = null, index = null, setFieldValue = null }) => {

  var currentProducts = allProducts[collectionField] ? allProducts[collectionField] : []
  const [removeFlag, setRemoveFlag] = useState(false);

  const slickConfig = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
  };

  const handleAllProducts = (title) => {
    var index = currentProducts.map(prod => {
      return prod.title;
    }).indexOf(title);
    let hasDestroy = currentProducts[index].hasOwnProperty('_destroy')
    if (index > -1) {
      if (isUpdate && hasDestroy) {
        const products = currentProducts.map((prod, i) => (i === index ? { ...prod, _destroy: true } : prod));
        setAllProducts({ ...allProducts, [collectionField]: products });
      } else {
        currentProducts.splice(index, 1);
        setAllProducts({ ...allProducts, [collectionField]: currentProducts });
      }

      setUpdated && setUpdated(flag => flag = true);
      setRemoveFlag(flag => flag = !removeFlag);
    }
  }
  const previewCard = currentProducts?.filter(prod => (!prod._destroy)).map((product) => {

    return (
      <div key={Math.random()} className="preview-item" id={`preview-${product.title.replaceAll(' ', '_')}`}>
        <div className="img">
          <div onClick={() => handleAllProducts(product.title)} className="cancel">
            <Icon
              source={MobileCancelMajor} />
          </div>
          <img
            alt=""
            src={product.image}
          />
        </div>
        <p>
          {product.title}
          {product.title.length <= 26 ? <div><br /></div> : ''}
        </p>
        {
          values?.collections?.[index]?.manageInventory && (
            <TextField
              label="Inventory Quantity"
              type="number"
              value={`${values?.collections?.[index]?.inventory?.[product.productId?.match(/\d+/)?.[0]] || 0}`}
              autoComplete="off"
              onChange={
                (e) => {
                  let currentInventory = values?.collections?.[index]?.inventory || {}
                  currentInventory[product.productId?.match(/\d+/)?.[0]] = e
                  setFieldValue(`collections[${index}].inventory`, currentInventory)
                }
              }
            />
          )
        }
      </div>
    )
  });

  return (
    <div className="preview-container" style={currentProducts.length > 0 ? { display: "block" } : { display: "none" }}>
      <div className="slider-preview">
        <Slider {...slickConfig}>
          {previewCard}
        </Slider>
      </div>
    </div>
  );
}

export default PreviewCollection;