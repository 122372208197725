import React, { useState, useCallback } from 'react';
import { Button, DatePicker, Popover } from '@shopify/polaris';
import dayjs from 'dayjs';
import utc from "dayjs/plugin/utc";
import timeZone from "dayjs/plugin/timezone";

const RangePicker = ({ selectedDates, setSelectedDates, handleFilterByDate }) => {
    dayjs.extend(utc);
    dayjs.extend(timeZone);
    dayjs.tz.setDefault("America/Los_Angeles")
    const [{ month, year }, setDate] = useState({ month: parseInt(`${+dayjs().format("M") - 1}`, 10), year: +dayjs().format("YYYY") });

    const handleMonthChange = useCallback(
        (month, year) => setDate({ month, year }),
        [],
    );
    const [popoverActive, setPopoverActive] = useState(true);
    const togglePopoverActive = useCallback(
        () => setPopoverActive((popoverActive) => !popoverActive),
        [],
    );
    const activator = (
        <Button onClick={togglePopoverActive} disclosure className="chooseDateButton">
            Choose Date
        </Button>
    );
    const handleDateChange = (date) => {
        setSelectedDates(date)

    }
    return (
        <div className="rangePicker" style={{ width: '100%', left: '815px !important' }}>
            <Popover
                active={popoverActive}
                activator={activator}
                onClose={togglePopoverActive}
                sectioned={true}
                fullWidth={true}
                fluidContent={true}
                className="datePickerPopOver"
            >
                <DatePicker
                    month={month}
                    year={year}
                    onChange={handleDateChange}
                    onMonthChange={handleMonthChange}
                    selected={selectedDates}
                    allowRange
                />
            </Popover>
        </div>
    )
}

export default RangePicker
