import React, { useState, useCallback, useEffect, useRef } from 'react';
import { SearchMinor } from '@shopify/polaris-icons';
import { gql, useMutation, useQuery, useLazyQuery } from '@apollo/client';
import {
  Autocomplete,
  TextField,
  Icon,
  TextContainer,
  Spinner,
} from '@shopify/polaris';
import DeleteSVG from '../../../assets/images/delete.svg';
const SearchPlan = (props) => {
  const {
    setFieldValue,
    fieldName,
    error,
    disabled,
    i18n,
    setFormData = null,
    formData = null,
    index,
    field = null,
    subField = null,
    multiple = true,
  } = props;

  const [allSelectedPlans, setAllSelectedPlans] = useState([]);

  // Search product to add
  const GET_SELLING_PLAN = gql`
    query ($name: String!) {
      fetchSellingPlanByName(name: $name) {
        id
        name
        shopifyId
      }
    }
  `;

  const [sellingPlanList, setSellingPlanList] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);

  const [value, setValue] = useState('');

  const [GetPlans, { loading, data, errors }] = useLazyQuery(GET_SELLING_PLAN, {
    fetchPolicy: 'no-cache',
  });

  useEffect(() => {
    if (data && data.fetchSellingPlanByName) {
      handleSetSellingPlan(data.fetchSellingPlanByName);
    }
  }, [data]);

  const handleSetSellingPlan = useCallback(
    (plans) => {
      const result = [];
      plans.map((plan) =>
        result.push({
          value: plan.shopifyId,
          label: plan.name,
        })
      );
      if (!loading) {
        setSellingPlanList(result);
      }
    },
    [sellingPlanList]
  );

  useEffect(() => {}, [sellingPlanList]);
  const updateText = (e) => {
    setValue(e);
  };

  useEffect(() => {
    GetPlans({ variables: { name: value } });
  }, [value]);

  const updateSelection = useCallback(
    (selected) => {
      if (selected?.length > 0) {
        const plan = sellingPlanList.find((item) => item.value === selected[0]);

        setSelectedOptions(selected);

        if (Array.isArray(allSelectedPlans)) {
          let flag = true;
          for (let i = 0; allSelectedPlans?.length > i; i++) {
            if (allSelectedPlans[i].sellingPlanName == plan.value) {
              flag = false;
              break;
            } else {
              flag = true;
            }
          }

          let selecteds = null;
          if (flag) {
            if (multiple) {
              selecteds = allSelectedPlans || [];
              selecteds.push({
                sellingPlanName: plan.label,
                sellingPlanId: plan.value,
              });
              console.log(selecteds);
              setAllSelectedPlans && setAllSelectedPlans([...selecteds]);
            } else {
              setAllSelectedPlans([
                {
                  sellingPlanName: plan.label,
                  sellingPlanId: plan.value,
                },
              ]);
            }
          }
        }
      }
    },
    [sellingPlanList, value]
  );

  const textField = (
    <Autocomplete.TextField
      onChange={updateText}
      label=""
      type="text"
      value={value}
      prefix={<Icon source={SearchMinor} color="base" />}
      placeholder={i18n.t('search_plan_to_add')}
      error={error}
      suffix={
        loading && (
          <Spinner accessibilityLabel="Small spinner example" size="small" />
        )
      }
      disabled={disabled}
    />
  );

  const emptyState = (
    <React.Fragment>
      <Icon source={SearchMinor} />
      <div style={{ textAlign: 'center' }}>
        <TextContainer>Could not find any results</TextContainer>
      </div>
    </React.Fragment>
  );

  console.log('[formData?.[field]?.[subField]]', field, 'subField', subField);

  useEffect(() => {
    if (field) {
      setAllSelectedPlans(formData?.[field]?.[subField] || []);
    } else if (formData?.conditions[index]?.selling_plans) {
      setAllSelectedPlans(formData?.conditions[index]?.selling_plans || []);
    }
  }, []);

  useEffect(() => {
    if (field) {
      let form_data_actions = formData?.[field];
      form_data_actions[index][subField] = [...allSelectedPlans];
      setFormData({ ...formData, [field]: form_data_actions });
    } else {
      let form_data_conditions = formData?.conditions;
      form_data_conditions[index]['selling_plans'] = [...allSelectedPlans];
      setFormData({ ...formData, conditions: form_data_conditions });
    }
  }, [allSelectedPlans]);

  return (
    <div className="">
      <div className="selected_plan_wrapper">
        {allSelectedPlans?.map((plan, i) => {
          return (
            <div className="selectedPlan">
              <div
                className="removePlan"
                onClick={() => {
                  let allPlans = [...allSelectedPlans];
                  allPlans?.splice(i, 1);
                  setAllSelectedPlans([...allPlans]);
                }}
              >
                x
              </div>
              {plan?.sellingPlanName}
            </div>
          );
        })}
      </div>
      <Autocomplete
        options={sellingPlanList}
        selected={selectedOptions}
        onSelect={updateSelection}
        textField={textField}
        emptyState={emptyState}
      />
    </div>
  );
};

export default SearchPlan;
