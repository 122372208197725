import React, { useState, useCallback, useEffect } from 'react';
import { Card, Tabs, Frame, Page, Icon } from '@shopify/polaris';
import '@shopify/polaris/dist/styles.css';
import SlideItem from './slideItem';
import Customize from './customize';
import { useHistory } from 'react-router-dom';
import './style.css';
import { useParams } from 'react-router-dom';
import {
  DeleteMajor,
  NoteMinor,
  ChevronRightMinor,
  MobileBackArrowMajor,
} from '@shopify/polaris-icons';

function ZennPortal({ domain, i18n, formatDate }) {
  const { edit_id } = useParams();
  const [selected, setSelected] = useState(0);
  const [apiData, setApiData] = useState([]);
  const [templateId, setTemplateId] = useState();
  const history = useHistory();

  useEffect(() => {
    if (edit_id) {
      setTemplateId(edit_id);
    }
  }, [edit_id]);

  const handleTabChange = useCallback((selectedTabIndex) => {
    setSelected(selectedTabIndex);
  }, []);

  const tabs = [
    {
      id: 'all-customers-1',
      content: 'All',
      accessibilityLabel: 'All customers',
      panelID: 'all-customers-content-1',
    },
    {
      id: 'Food-&-Beverage',
      content: 'Food & Beverage',
      panelID: 'Food-&-Beverage',
    },
    {
      id: 'Health-&-Beauty',
      content: 'Health & Beauty',
      panelID: 'Health-&-Beauty',
    },
    {
      id: 'Gift-Guide',
      content: 'Gift Guide',
      panelID: 'Gift-Guide',
    },
    {
      id: 'Fashion',
      content: 'Fashion',
      panelID: 'Fashion',
    },
    {
      id: 'Furniture',
      content: 'Furniture',
      panelID: 'Furniture',
    },
    {
      id: 'Technology',
      content: 'Technology',
      panelID: 'Technology',
    },
  ];

  useState(() => {
    fetch('/customer_portal/get_templates', {
      method: 'GET',
    })
      .then((response) => response.json())
      .then((data) => {
        let _data = [...data.templates];
        setApiData([..._data]);
      });
  }, []);

  useEffect(() => { }, [templateId]);

  const RenderCustomHeader = () => {
    return (
      <div className="chargezen_custom_page_header">
        <div className="page_title_wrapper">
          <div className="back_button_wrapper">
            {templateId && (
              <div
                className="back-button pointer"
                onClick={() => {
                  history.push('/zenn');
                }}
              >
                <Icon source={MobileBackArrowMajor} color="base" />
              </div>
            )}
            <div class="text_wrapper page_heading_title_wrapper">
              <div
                class="Polaris-Header-Title__TitleAndSubtitleWrapper"
                style={{
                  width: '100%',
                }}
              >
                <div class="Polaris-Header-Title">
                  <h1 class="Polaris-DisplayText Polaris-DisplayText--sizeLarge">
                    <span class="Polaris-TextStyle--variationStrong">
                      <div class="back_button_wrapper">
                        {' '}
                        <div
                          class="text_wrapper page_heading_title_wrapper"
                          style={{
                            width: '100%',
                          }}
                        >
                          <span>Customer Portal</span>
                        </div>
                      </div>
                    </span>
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="sidebar_summary header_description">
          Customize the experience of the customer portal with ready-made themes
          & offers.
        </div>
      </div>
    );
  };

  return (
    <Frame>
      <div className="rewards_template_wrapper zenn_portal_wrapper">
        <Page>
          <RenderCustomHeader />
          {templateId ? (
            <Customize
              domain={domain}
              i18n={i18n}
              selectedTemplateId={templateId}
              formatDate={formatDate}
            />
          ) : (
            <>
              <div className="rewards_choose_template show_content_2 upsells_templates_container">
                <Card>
                  <Tabs
                    tabs={tabs}
                    selected={selected}
                    onSelect={handleTabChange}
                  >
                    <Card.Section title={tabs[selected].content}>
                      {/* <p>Tab {selected} selected</p> */}
                      <div class="sort-by">
                        <div class="inner-wrap-sort">
                          <label>Sort by:</label>
                          <select
                            id="PolarisSelect1"
                            class="Polaris-Select__Input"
                            aria-invalid="false"
                          >
                            <option value="popular">Popularity</option>
                            <option value="recent">Most recent</option>
                          </select>
                          <svg
                            viewBox="0 0 20 20"
                            class="Polaris-Icon__Svg"
                            focusable="false"
                            aria-hidden="true"
                          >
                            <path d="M7.676 9h4.648c.563 0 .879-.603.53-1.014l-2.323-2.746a.708.708 0 0 0-1.062 0l-2.324 2.746c-.347.411-.032 1.014.531 1.014Zm4.648 2h-4.648c-.563 0-.878.603-.53 1.014l2.323 2.746c.27.32.792.32 1.062 0l2.323-2.746c.349-.411.033-1.014-.53-1.014Z"></path>
                          </svg>
                        </div>
                      </div>
                      {apiData?.map((data) => {
                        return (
                          <SlideItem
                            data={data}
                            templateId={templateId}
                            setTemplateId={setTemplateId}
                          />
                        );
                      })}
                    </Card.Section>
                  </Tabs>
                </Card>
              </div>
            </>
          )}
        </Page>
      </div>
    </Frame>
  );
}

export default ZennPortal;
