import {
  Button,
  ButtonGroup,
  Card,
  ProgressBar,
  Page,
  Layout,
  Icon,
} from '@shopify/polaris';
import React, { useCallback, useEffect, useState } from 'react';

import { gql, useLazyQuery } from '@apollo/client';
import dayjs from 'dayjs';
import { isEmpty } from 'lodash';

import SubHeader from '../home/SubHeader';
import ChatWithUs from '../home/ChatWithUs';
import SetupGuide from '../home/SetupGuide';
import RevenueHighlight from '../home/RevenueHighlight';

const Overview = (props) => {
  const {
    setupTitle,
    i18n,
    customNavTabs,
    floatingSetupGuide,
    setFloatingSetupGuide,
    subHeaderData,
  } = props;
  return (
    <>
      <div className="overview_main_div">
        <div className="dashboard_left">
          <div className="dashboard_sub_header">
            {subHeaderData?.map((headerData) => {
              return (
                <SubHeader
                  title={headerData?.title}
                  value={headerData?.value}
                  desc={headerData?.desc}
                />
              );
            })}
          </div>
          <div className="dashboard_content">
            {setupTitle && <h1>{setupTitle}</h1>}

            <div className="schedule_content_main">
              <div className="top_section">
                <div className="revenue_overview_div">
                  <SetupGuide
                    {...{
                      customNavTabs,
                      floatingSetupGuide,
                      setFloatingSetupGuide,
                    }}
                  />
                </div>
              </div>
              <div className="bottom_section">
                <ChatWithUs />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Overview;
