import React, { useEffect, useState } from "react";
import {
    Card,
    Button,
    Form,
    FormLayout,
    TextField,
    TextContainer,
    Frame,
    ContextualSaveBar,
    Select,
    Subheading,
    Toast,
    Banner,
    List,
    Page,
    ButtonGroup,
    TextStyle,
    Checkbox,
    Stack,
    Heading,
    Spinner,
    Autocomplete,
    Icon,
} from '@shopify/polaris';
import DeleteSVG from '../../../assets/images/delete.svg'
import SearchPlan from '../upsell/SearchPlan';

const SelectPlan = ({ pack, allSelectedPlans, setAllSelectedPlans, i18n, setFieldValue, values }) => {
    const [updated, setUpdated] = useState(false);
    const handleRemovePlan = (id) => {
        setUpdated(true);
        setFieldValue(`pack${pack}`, { sellingPlanId: '', sellingPlanName: [] });
    };

    const [selectPlan, setSlectedPlan] = useState([])

    return (
        <>
            <div className="build-box-search">
                <TextContainer>
                    <Subheading>Subscription Plan</Subheading>
                </TextContainer>
                <Select
                    options={[{ label: 'is any', value: 'is_any' }]}
                    label=""
                    value={'is_any'}
                />

                <div className="search">
                    <SearchPlan
                        idForTextField={`searchPlan-${pack}-${Math.random()}`}
                        value={values[`sellingPlans${pack}`]}
                        setFieldValue={setFieldValue}
                        fieldName={`sellingPlans${pack}`}
                        allSelectedPlans={selectPlan || []}
                        setAllSelectedPlans={setSlectedPlan}
                        i18n={i18n}
                    />
                </div>
            </div>
            <div>
                <Stack vertical={true}>
                    {values[`sellingPlans${pack}`]?.length > 0 &&
                        <Stack.Item>
                            <div className="selected-plan-container">
                                <span>{values[`sellingPlans${pack}`]?.sellingPlanName}</span>
                                <img
                                    src={DeleteSVG}
                                    onClick={() =>
                                        handleRemovePlan(values[`sellingPlans${pack}`]?.sellingPlanId)
                                    }
                                    alt="Delete"
                                />
                            </div>
                        </Stack.Item>
                    }
                </Stack>
            </div>
        </>
    )
}

export default SelectPlan;

