import {
  Button,
  Frame,
  Icon,
  Navigation,
  Select,
  Tabs,
} from '@shopify/polaris';
import React, { useCallback, useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import '../layout/nav_style.scss';
import {
  HomeMajor,
  SettingsMinor,
  AnalyticsMajor,
  HintMajor,
  ProfileMajor,
  InstallMinor,
  CustomersMajor,
  GiftCardMajor,
} from '@shopify/polaris-icons';
import {
  DashboardIcon,
  MangeIcon,
  SubscriptionIcon,
  ToolboxIcon,
  CrystalBall,
  GetStartedIcon,
  ZenBrainIcon,
  SubscriptionsIcon,
  ReplenishIcon,
  MoreIcon,
  SubscriptionContractsIcon,
  CustomersIcon,
  AnalyticsIcon,
  ZennPortalIcon,
  ThemeIcon,
  HelpdeskIcon,
  TiazenIcon,
  SmartyMessageIcon,
} from '../layout/CustomIcon';
import useRouter from '../useRouterHook';
import mixpanel from 'mixpanel-browser';

const Nav = (props) => {
  const { navCollapse, setNavCollapse, selected, i18n, mixpanelId } = props;
  const history = useHistory();
  const router = useRouter();
  const [navigations, setNavigations] = useState([]);

  const [dynamicStyle, setDynamicStyle] = useState(``);

  useEffect(() => {
    setNavigation();
  }, [window.location.href]);

  useEffect(() => {
    let lineHeight = location.search?.includes('_subitems') ? 229 : 233;
    let style = `
      .navBar .sidBartabs nav.Polaris-Navigation .Polaris-Navigation__PrimaryNavigation ul.Polaris-Navigation__Section>li>div>div>ul>li.Polaris-Navigation__ListItem > .Polaris-Navigation__ItemWrapper + .Polaris-Navigation__SecondaryNavigation.Polaris-Navigation--isExpanded {
        padding-left: 40px;
      }
      
      .navBar .sidBartabs nav.Polaris-Navigation .Polaris-Navigation__PrimaryNavigation ul.Polaris-Navigation__Section>li>div>div>ul>li.Polaris-Navigation__ListItem > .Polaris-Navigation__ItemWrapper + .Polaris-Navigation__SecondaryNavigation.Polaris-Navigation--isExpanded ul.Polaris-Navigation__List li a.Polaris-Navigation--subNavigationActive:after {
          content: '';
          position: absolute;
          width: 2px;
          left: 9px;
          background: #b5b5b5;
          top: -227.5px;
          height: ${lineHeight}px;
          opacity: 1;
          visibility: visible;
          z-index: 99999;
          color: #000;
          opacity: 0.7;
      }

      .navBar .sidBartabs nav.Polaris-Navigation .Polaris-Navigation__PrimaryNavigation ul.Polaris-Navigation__Section>li>.Polaris-Navigation__SecondaryNavigation>div>ul.Polaris-Navigation__List>li.Polaris-Navigation__ListItem>.Polaris-Navigation__ItemWrapper>a.Polaris-Navigation__Item.Polaris-Navigation--subNavigationActive {
        background: #fafafa!important;
      }
      
      
      .navBar .sidBartabs nav.Polaris-Navigation .Polaris-Navigation__PrimaryNavigation ul.Polaris-Navigation__Section>li>div>div>ul>li.Polaris-Navigation__ListItem > .Polaris-Navigation__ItemWrapper + .Polaris-Navigation__SecondaryNavigation.Polaris-Navigation--isExpanded ul.Polaris-Navigation__List li a.Polaris-Navigation--subNavigationActive:before {
          content: '';
          position: absolute;
          width: 12px;
          height: 2px;
          background: #fff;
          left: -15px;
          top: 45%;
      }

      .module-navbar.dashboardArea .navBar nav.Polaris-Navigation ul.Polaris-Navigation__Section>li.Polaris-Navigation__ListItem>.Polaris-Navigation__SecondaryNavigation>div>ul.Polaris-Navigation__List>li.Polaris-Navigation__ListItem>.Polaris-Navigation__SecondaryNavigation>div>ul.Polaris-Navigation__List>li>div>a:before 
        {
          width: 20.99px;
          height: 25px;
          display: block;
          opacity: 1;
          visibility: 
          visible;
          z-index: 1;
          position: absolute;
          left: 0;
          background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='21' height='28' fill='none'%3E%3Cpath fill='%23B5B5B5' d='M19 14.25h.75v1.5H19v-1.5Zm-8.923-.888.668-.34-.668.34Zm1.311 1.311.34-.668-.34.668ZM10.5 0v10.2H9V0h1.5Zm4.05 14.25H19v1.5h-4.45v-1.5ZM10.5 10.2c0 .853 0 1.447.038 1.91.037.453.107.714.207.912l-1.336.68c-.227-.444-.321-.924-.366-1.47C9 11.694 9 11.027 9 10.2h1.5Zm4.05 5.55c-.828 0-1.494 0-2.032-.043-.546-.045-1.026-.14-1.47-.366l.68-1.336c.198.1.459.17.913.207.462.037 1.056.038 1.909.038v1.5Zm-3.805-2.729c.216.424.56.768.984.984l-.681 1.336a3.75 3.75 0 0 1-1.64-1.638l1.337-.681Z'/%3E%3Cpath stroke='%23B5B5B5' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='m17 12 3 3-3 3'/%3E%3C/svg%3E");
          content: "";
          transition: all 0.2s ease;
          opacity: 0;
          top: 0;
          border-radius: 5px 0 0 5px;
        }

      .module-navbar.dashboardArea .navBar nav.Polaris-Navigation ul.Polaris-Navigation__Section>li.Polaris-Navigation__ListItem>.Polaris-Navigation__SecondaryNavigation>div>ul.Polaris-Navigation__List>li.Polaris-Navigation__ListItem>.Polaris-Navigation__SecondaryNavigation>div>ul.Polaris-Navigation__List>li>div>a.Polaris-Navigation__Item {
          padding-left: 15px !important;
      }


      .module-navbar.dashboardArea .navBar nav.Polaris-Navigation ul.Polaris-Navigation__Section>li.Polaris-Navigation__ListItem>.Polaris-Navigation__SecondaryNavigation>div>ul.Polaris-Navigation__List>li.Polaris-Navigation__ListItem>.Polaris-Navigation__SecondaryNavigation>div>ul.Polaris-Navigation__List>li>div>a:hover:before {opacity: 1 !important;display: block !important;}

      .module-navbar.dashboardArea .navBar nav.Polaris-Navigation ul.Polaris-Navigation__Section>li.Polaris-Navigation__ListItem>.Polaris-Navigation__SecondaryNavigation>div>ul.Polaris-Navigation__List>li.Polaris-Navigation__ListItem>.Polaris-Navigation__SecondaryNavigation>div>ul.Polaris-Navigation__List>li>div>a.Polaris-Navigation__Item.Polaris-Navigation--subNavigationActive::before {
          opacity: 1;
      }

    `;
    setDynamicStyle(style);
  }, [location.search]);

  const ConditionalCss = () => {
    //May need to change the nth value if new nav tabs is added before more
    const style = `
    .navBar .sidBartabs nav.Polaris-Navigation .Polaris-Navigation__PrimaryNavigation ul.Polaris-Navigation__Section>li:nth-child(2)>.Polaris-Navigation__SecondaryNavigation>div#PolarisSecondaryNavigation7>ul.Polaris-Navigation__List>li.Polaris-Navigation__ListItem:last-child>.Polaris-Navigation__SecondaryNavigation>div#PolarisSecondaryNavigation12 {
      opacity: 0 !important;
      max-height: 0 !important;
      overflow: hidden !important;
    }
    .navBar .sidBartabs nav.Polaris-Navigation .Polaris-Navigation__PrimaryNavigation ul.Polaris-Navigation__Section>li:nth-child(2)>.Polaris-Navigation__SecondaryNavigation>div#PolarisSecondaryNavigation7>ul.Polaris-Navigation__List>li.Polaris-Navigation__ListItem:last-child>.Polaris-Navigation__ItemWrapper a {
      background: transparent !important;
        }
  `;

    return (
      <style
        dangerouslySetInnerHTML={{
          __html: style,
        }}
      />
    );
  };

  const setNavigation = () => {
    let navigation = [];

    navigation.push({
      url: '',
      label: i18n.t('revenue_channels'),
      disabled: true,
      selected: true,
      subNavigationItems: [
        {
          icon: DashboardIcon,
          label: 'Dashboard',
          url: '/',
          selected: window.location.pathname == `/affiliate/`,
        },
        {
          icon: SubscriptionContractsIcon,
          label: 'Affiliate orders',
          url: '/affiliateOrders',
          selected: window.location.pathname.includes(`/affiliateOrders`),
        },
        {
          icon: SettingsMinor,
          label: 'Settings',
          url: '/settings',
          disabled: true,
          selected: window.location.pathname.includes(`/settings`)
            ? true
            : false,
        },
      ],
    });

    setNavigations(navigation);
  };

  useEffect(() => {
    setNavigation();
  }, []);

  //Mixpanel
  const [currentMixpanel, setCurrentMixpanel] = useState();
  //Initialise Mixpanel
  mixpanel.init(mixpanelId, {
    debug: true,
    loaded: function () {
      setCurrentMixpanel(mixpanel.get_distinct_id());
    },
  });

  const getMixpanelUser = () => {
    fetch(`/getMixpanelUser/${currentMixpanel}`, {
      method: 'Get',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    })
      .then((response) => response.json())
      .then((data) => {
        let event = window.location.pathname.split('/').pop();
        event = event ? event : 'Admin Dashboard';
        if (data?.user) {
          mixpanel.track(`Admin ${event}`, {
            shop: `${props?.domain}`,
            userEmail: `${data?.user}`,
          });
        } else {
          mixpanel.track(`Admin ${event}`, {
            shop: `${props?.domain}`,
            userEmail: `${localStorage.getItem('currentuser')}`,
          });
        }
      });
  };
  useEffect(() => {
    console.log('userMixPanelId', currentMixpanel);
    if (currentMixpanel) {
      getMixpanelUser();
    }
  }, [window.location.pathname]);

  return (
    <div className="navBar">
      <div className="logo_main">
        <div className="collapsible_button">
          <Button
            onClick={() => {
              setNavCollapse(!navCollapse);
            }}
          >
            {navCollapse ? (
              <div className="arrow-icon-right">
                <svg
                  width="11"
                  height="16"
                  viewBox="0 0 11 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M7.93891 0.938995L0.878906 8L7.93891 15.061L10.0609 12.939L5.12091 8L10.0609 3.061L7.93891 0.938995Z"
                    fill="#445BFF"
                  />
                </svg>
              </div>
            ) : (
              <div className="arrow-icon-left">
                <svg
                  width="11"
                  height="16"
                  viewBox="0 0 11 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M7.93891 0.938995L0.878906 8L7.93891 15.061L10.0609 12.939L5.12091 8L10.0609 3.061L7.93891 0.938995Z"
                    fill="#445BFF"
                  />
                </svg>
              </div>
            )}
          </Button>
        </div>
      </div>
      {navigations.length > 0 ? (
        <div className="sidBartabs">
          <Navigation location={router.pathname}>
            <Navigation.Section
              items={navigations ? navigations : []}
              selected={selected}
            />
          </Navigation>
        </div>
      ) : (
        <div />
      )}
      <div className="logout_link">
        {localStorage.getItem('currentuser') && (
          <>
            <a
              href={`${window?.location?.origin}/users/sign_out`}
              className="adminLogoutLink"
              data-method="delete"
            >
              {i18n.t('log_out')}
            </a>
          </>
        )}
      </div>
    </div>
  );
};

export default Nav;
