import {
  Button,
  ButtonGroup,
  Card,
  Checkbox,
  FormLayout,
  Frame,
  Page,
  TextField,
  Toast,
} from '@shopify/polaris';
import React, { useCallback, useEffect, useState } from 'react';
import PixelIcon from '../../../images/PixelIcon';
import DragAndDrop from './DragAndDrop';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
const ManageButtons = ({ i18n, selectedTemplateId, domain, toggleActive }) => {
  const [allButtons, setAllButtons] = useState([]);
  const [contractBoxButtons, setContractBoxButtons] = useState([]);
  const [sideBarButtons, setSideBarButtons] = useState([]);
  const [editSubButtons, setEditSubButtons] = useState([]);
  const [allButtonsCategory, setAllButtonsCategory] = useState([]);
  const [formData, setFormData] = useState({});
  const [toastContent, setToastContent] = useState('');
  const [toastActive, setToastActive] = useState(false);
  const toggleToastActive = useCallback(
    () => setToastActive((toastActive) => !toastActive),
    []
  );

  useEffect(() => {
    fetch(
      `/fetch_customer_portal_buttons?shopify_domain=${domain}&template_id=${selectedTemplateId}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      }
    )
      .then((response) => response.json())
      .then((data) => {
        let contract_box_buttons = data?.contract_box_buttons || [];
        let side_bar_buttons = data?.side_bar_buttons || [];
        let edit_sub_buttons = data?.edit_sub_buttons || [];
        console.log('contract_box_buttons', data);
        setFormData(data?.current_buttons_setting);
        setSideBarButtons(side_bar_buttons);
        setContractBoxButtons(contract_box_buttons);
        setEditSubButtons(edit_sub_buttons);
        let all_buttons_category = [];
        contract_box_buttons?.map((button) => {
          all_buttons_category.push(button);
        });
        edit_sub_buttons?.map((button) => {
          all_buttons_category.push(button);
        });
        side_bar_buttons?.map((button) => {
          all_buttons_category.push(button);
        });

        editSubButtons.push([...edit_sub_buttons]);
        setAllButtonsCategory([...all_buttons_category]);
        setAllButtons({
          contract_box_buttons: contract_box_buttons,
          side_bar_buttons: side_bar_buttons,
          edit_sub_buttons: edit_sub_buttons,
        });
      });
  }, []);

  const saveButtonsSettings = (settingsFor) => {
    let formdata = formData[settingsFor];
    let buttons_with_index =
      settingsFor == 'side_bar_buttons'
        ? sideBarButtons
        : settingsFor == 'contract_box_buttons'
        ? contractBoxButtons
        : editSubButtons;
    fetch('/save_customer_portal_buttons', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        data: formData,
        shopify_domain: domain,
        template_id: selectedTemplateId,
        buttons_with_index: buttons_with_index,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        setToastContent(data?.response);
        toggleActive();
      });
  };
  useEffect(() => {
    console.log('allButtonsCategory', allButtonsCategory);
    let contract_box_buttons = contractBoxButtons;
    let side_bar_buttons = sideBarButtons;
    let edit_sub_buttons = editSubButtons;
    Object.keys(formData)?.map((k) => {
      let currentButton = allButtonsCategory?.find(
        (b) => +b.id === +k.match(/\d+/)?.[0]
      );
      if (formData[k]) {
        if (currentButton?.category == 'contract_box') {
          let alreadyExists = contract_box_buttons.find(
            (b) => +b.id == +currentButton.id
          );
          if (!alreadyExists) {
            contract_box_buttons.push(currentButton);
          }
        } else if (currentButton?.category == 'side_bar') {
          let alreadyExists = side_bar_buttons.find(
            (b) => +b.id == +currentButton.id
          );
          if (!alreadyExists) {
            side_bar_buttons.push(currentButton);
          }
        } else if (currentButton?.category == 'edit_subscripion_popup') {
          let alreadyExists = edit_sub_buttons.find(
            (b) => +b.id == +currentButton.id
          );
          if (!alreadyExists) {
            edit_sub_buttons.push(currentButton);
          }
        }
      } else {
        if (currentButton?.category == 'contract_box') {
          contract_box_buttons = contract_box_buttons.filter(
            (b) => +b.id !== +currentButton.id
          );
        } else if (currentButton?.category == 'side_bar') {
          side_bar_buttons = side_bar_buttons.filter(
            (b) => +b.id !== +currentButton.id
          );
        } else if (currentButton?.category == 'edit_subscripion_popup') {
          edit_sub_buttons = edit_sub_buttons.filter(
            (b) => +b.id !== +currentButton.id
          );
        }
      }
    });
    setContractBoxButtons([...contract_box_buttons]);
    setSideBarButtons([...side_bar_buttons]);
    setEditSubButtons([...edit_sub_buttons]);
  }, [formData, allButtonsCategory]);
  return (
    <DndProvider backend={HTML5Backend}>
      <div className="manage_button_settings_container">
        <Card
          title={
            <div className="heading_title">
              {i18n.t('zennportal_form.button_settings')}
            </div>
          }
        >
          <Card.Section>
            <FormLayout>
              <div className="button_checkbox_container">
                <h3 className="manage_buttons_title manage_visibility_title">
                  {i18n.t('zennportal_form.manage_visibility')}
                </h3>
                {allButtons?.side_bar_buttons?.map((button) => {
                  return (
                    <FormLayout.Group>
                      <Checkbox
                        label={i18n.t(
                          `zennportal_form.button_name.${button?.button_name}`
                        )}
                        checked={formData[`visible_${button.id}`]}
                        onChange={(e) => {
                          setFormData({
                            ...formData,
                            [`visible_${button.id}`]: e,
                          });
                        }}
                      />
                    </FormLayout.Group>
                  );
                })}
              </div>
              <div className="button_drag_and_drop_container">
                <h3 className="manage_buttons_title">
                  {i18n.t('zennportal_form.manage_ordering')}
                </h3>
                <DragAndDrop
                  cards={sideBarButtons}
                  setCards={setSideBarButtons}
                  i18n={i18n}
                />
              </div>
              <FormLayout.Group>
                <Button
                  onClick={() => {
                    saveButtonsSettings('side_bar_buttons');
                  }}
                >
                  {i18n.t('save')}
                </Button>
              </FormLayout.Group>
            </FormLayout>
          </Card.Section>
        </Card>
        <Card
          title={
            <div className="heading_title">
              <PixelIcon />
              {i18n.t('zennportal_form.contract_box_buttons')}
            </div>
          }
        >
          <Card.Section>
            <FormLayout>
              <div className="button_checkbox_container">
                <h3 className="manage_buttons_title manage_visibility_title">
                  {i18n.t('zennportal_form.manage_visibility')}
                </h3>
                {allButtons?.contract_box_buttons?.map((button) => {
                  return (
                    <FormLayout.Group>
                      <Checkbox
                        label={i18n.t(
                          `zennportal_form.button_name.${button?.button_name}`
                        )}
                        checked={formData[`visible_${button.id}`]}
                        onChange={(e) => {
                          setFormData({
                            ...formData,
                            [`visible_${button.id}`]: e,
                          });
                        }}
                      />
                    </FormLayout.Group>
                  );
                })}
              </div>
              <div className="button_drag_and_drop_container">
                <h3 className="manage_buttons_title">
                  {i18n.t('zennportal_form.manage_ordering')}
                </h3>
                <DragAndDrop
                  cards={contractBoxButtons}
                  setCards={setContractBoxButtons}
                  i18n={i18n}
                />
              </div>
              <FormLayout.Group>
                <Button
                  onClick={() => {
                    saveButtonsSettings('contract_box_buttons');
                  }}
                >
                  {i18n.t('save')}
                </Button>
              </FormLayout.Group>
            </FormLayout>
          </Card.Section>
        </Card>
        <Card
          title={
            <div className="heading_title">
              <PixelIcon />
              {i18n.t('zennportal_form.edit_subscription_section')}
            </div>
          }
        >
          <Card.Section>
            <FormLayout>
              <div className="button_checkbox_container">
                <h3 className="manage_buttons_title manage_visibility_title">
                  {i18n.t('zennportal_form.manage_visibility')}
                </h3>
                {allButtons?.edit_sub_buttons?.map((button) => {
                  return (
                    <FormLayout.Group>
                      <Checkbox
                        label={i18n.t(
                          `zennportal_form.button_name.${button?.button_name}`
                        )}
                        checked={formData[`visible_${button.id}`]}
                        onChange={(e) => {
                          setFormData({
                            ...formData,
                            [`visible_${button.id}`]: e,
                          });
                        }}
                      />
                    </FormLayout.Group>
                  );
                })}
              </div>
              <div className="button_drag_and_drop_container">
                <h3 className="manage_buttons_title">
                  {i18n.t('zennportal_form.manage_ordering')}
                </h3>
                <DragAndDrop
                  cards={editSubButtons}
                  setCards={setEditSubButtons}
                  i18n={i18n}
                />
              </div>
              <FormLayout.Group>
                <Button
                  onClick={() => {
                    saveButtonsSettings('edit_sub_buttons');
                  }}
                >
                  {i18n.t('save')}
                </Button>
              </FormLayout.Group>
            </FormLayout>
          </Card.Section>
        </Card>
      </div>
    </DndProvider>
  );
};
export default ManageButtons;
