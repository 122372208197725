import {
  Badge,
  Banner,
  Button,
  ButtonGroup,
  Card,
  DataTable,
  Frame,
  Icon,
  List,
  Modal,
  Page,
  ResourceItem,
  ResourceList,
  Stack,
  TextContainer,
  Toast,
} from '@shopify/polaris';
import React, { useCallback, useEffect, useState } from 'react';
// import {
//     StoreMinor
// } from '@shopify/polaris-icons';
import { gql, useQuery } from '@apollo/client';
import LoadingScreen from '../LoadingScreen';
import { values } from 'lodash';
import AppLayout from '../layout/Layout';
import PixelIcon from '../../images/PixelIcon';
import EditReferralRewards from './EditReferralRewards';

import {
  ChevronRightMinor,
  MobileBackArrowMajor,
} from '@shopify/polaris-icons';
import { useHistory, useParams } from 'react-router-dom';
const ReferralCustomerRewards = (props) => {
  const { i18n, domain, detailView, setDetailView } = props;
  const { edit_id } = useParams();
  const history = useHistory();
  const [editForm, setEditForm] = useState(false);
  const [currentView, setcurrentView] = useState(false);
  const [pointsReferrals, setPointsReferral] = useState([]);
  const [rowData, setRowData] = useState([]);
  const [formErrors, setFormErrors] = useState([]);
  const [saveSuccess, setSaveSuccess] = useState(false);
  const hideSaveSuccess = useCallback(() => setSaveSuccess(false), []);
  const [titleOptions, setTitleOptions] = useState([]);
  const [allProducts, setAllProducts] = useState([]);
  const [formData, setFormData] = useState({
    id: '',
    title: '',
    points: '',
    referralType: 'referring_customer',
    appliesTo: '',
    points: '',
    prefixDiscountCode: '',
    collectionImages: [],
    minimumPurchaseAmount: '',
    discountValue: '',
    summary: '',
    minPointsRequired: '',
    maxPointsRequired: '',
    maxShippingAmount: '',
  });
  const [pointProgramStatus, setPointProgramStatus] = useState(false);
  const [renderConfirmDelete, setRenderConfirmDelete] = useState(false);
  const [selectedCollections, setSelectedCollections] = useState([]);

  const GET_REDEEM = gql`
    query ($type: String!) {
      fetchPointsReferrals(type: $type) {
        pointsReferrals {
          id
          title
          appliesTo
          points
          status
          prefixDiscountCode
          minimumPurchaseAmount
          discountValue
          minPointsRequired
          summary
          minPointsRequired
          maxPointsRequired
          maxShippingAmount
          collectionImages {
            collectionId
            collectionTitle
            _destroy
            products {
              productId
              image
              _destroy
            }
          }
        }
        allTitles
        pointProgramStatus
      }
    }
  `;

  const {
    data: redeemData,
    loading: redeemLoading,
    error: redeemError,
    refetch: redeemRefetch,
  } = useQuery(GET_REDEEM, {
    fetchPolicy: 'no-cache',
    variables: {
      type: 'referring_customer',
    },
  });

  useEffect(() => {
    redeemRefetch();
  }, []);

  useEffect(() => {
    if (editForm) {
      setcurrentView('Referred customer reward');
    }
  }, [editForm]);

  useEffect(() => {
    console.log('edit_id_edit_id_edit_id', edit_id);
    if (edit_id === 'editCustomerReward' && pointsReferrals.length > 0) {
      const row = pointsReferrals[0];
      setFormData({
        id: row?.id,
        title: row?.title,
        points: '',
        referralType: 'referring_customer',
        appliesTo: row?.appliesTo,
        prefixDiscountCode: row?.prefixDiscountCode,
        minimumPurchaseAmount: row?.minimumPurchaseAmount,
        discountValue: row?.discountValue,
        summary: row?.summary,
        minPointsRequired: row?.minPointsRequired,
        maxPointsRequired: row?.maxPointsRequired,
        maxShippingAmount: row?.maxShippingAmount,
      });
      setSelectedCollections(row?.collectionImages);
      setEditForm(true);
    } else if (edit_id === 'createNew') {
      setFormData({
        id: '',
        title: titleOptions[0]?.value,
        referralType: 'referring_customer',
        appliesTo: 'entire_order',
        points: '',
        prefixDiscountCode: '',
        collectionImages: [],
        minimumPurchaseAmount: '',
        discountValue: '',
        summary: '',
        minPointsRequired: '',
        maxPointsRequired: '',
        maxShippingAmount: '',
      });
      setEditForm(true);
    }
  }, [edit_id, rowData]);

  useEffect(() => {
    if (redeemData?.fetchPointsReferrals?.pointsReferrals?.length > 0) {
      setPointsReferral(redeemData?.fetchPointsReferrals?.pointsReferrals);
      setSelectedCollections(
        redeemData?.fetchPointsReferrals?.collectionImages
      );
    }
    if (redeemData?.fetchPointsReferrals?.allTitles) {
      let allTitles = JSON.parse(redeemData?.fetchPointsReferrals?.allTitles);
      let allTitleArr = [];
      Object.keys(allTitles)?.map((k, v) => {
        allTitleArr.push({ label: `${k}`, value: `${k}` });
      });
      console.log(
        'allTitle',
        allTitleArr,
        redeemData?.fetchPointsReferrals?.allTitles
      );
      setTitleOptions(allTitleArr);
    }
    if (redeemData?.fetchPointsReferrals?.pointProgramStatus) {
      setPointProgramStatus(
        redeemData?.fetchPointsReferrals?.pointProgramStatus
      );
    }
  }, [redeemData]);

  useEffect(() => {
    if (pointsReferrals?.length > 0) {
      setRowData(pointsReferrals);
    }
  }, [pointsReferrals]);

  useEffect(() => {
    fetch('/togglePointsProgram', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        active: pointProgramStatus,
        shopify_domain: domain,
      }),
    });
  }, [pointProgramStatus]);

  const formatRedeemData = (rows) => {
    return rows?.map((row) => {
      return [
        row.title,
        row?.status ? (
          <Badge status="success">{i18n.t('active')}</Badge>
        ) : (
          <Badge>{i18n.t('disabled')}</Badge>
        ),
        `${
          row?.points
            ? `${row?.points} points`
            : `${row?.discountValue} discount`
        }`,
        <div className="primary_button_wrapper">
          <Button
            primary
            onClick={() => {
              history.push(
                `/pointsProgram/referral_rewards/editCustomerReward`
              );
            }}
          >
            {i18n.t('edit')}
          </Button>
        </div>,
      ];
    });
  };

  return (
    <>
      {saveSuccess && (
        <Toast
          content={i18n.t('points_programs.referral_reward_save')}
          onDismiss={hideSaveSuccess}
        />
      )}
      {formErrors.length > 0 && (
        <>
          <Banner
            title={i18n.t('points_programs.referral_reward_not_save')}
            status="critical"
          >
            <List type="bullet">
              {formErrors.map((message, index) => (
                <List.Item key={index}>{message.message}</List.Item>
              ))}
            </List>
          </Banner>
          <br />
        </>
      )}
      {editForm ? (
        <EditReferralRewards
          setFormData={setFormData}
          formData={formData}
          refetch={redeemRefetch}
          setEditForm={setEditForm}
          formErrors={formErrors}
          setFormErrors={setFormErrors}
          saveSuccess={saveSuccess}
          setSaveSuccess={setSaveSuccess}
          titleOptions={titleOptions}
          i18n={i18n}
          allProducts={allProducts}
          setAllProducts={setAllProducts}
          selectedCollections={selectedCollections}
          setSelectedCollections={setSelectedCollections}
          detailView={detailView}
          setRenderConfirmDelete={setRenderConfirmDelete}
          currentView={currentView}
        />
      ) : redeemLoading ? (
        <LoadingScreen />
      ) : (
        <div className="without_header_card">
          <Card
            // title={
            //   <div className="heading_title">
            //     <PixelIcon />
            //     {i18n.t('points_programs.referring_customer_reward')}
            //     <div class="card_title_desc">
            //       {i18n.t('points_programs.referrals_summary2')}
            //     </div>
            //   </div>
            // }
            actions={{
              content: (
                <div className="card_header_button_group">
                  <div className="card_header_buttons secondary_button_group">
                    {redeemData?.fetchPointsReferrals?.pointsReferrals?.length >
                    0 ? (
                      <Button
                        onClick={() => {
                          setRenderConfirmDelete(true);
                        }}
                      >
                        {i18n.t('change')}
                      </Button>
                    ) : (
                      <Button
                        onClick={() => {
                          history.push(
                            `/pointsProgram/referral_rewards/createNew`
                          );
                        }}
                      >
                        {i18n.t('points_programs.add_referrals_reward')}
                      </Button>
                    )}
                  </div>
                </div>
              ),
            }}
          >
            <Card.Section>
              <div className={'table customer-subscription-tbl' + ' '}>
                <DataTable
                  columnContentTypes={['text', 'text', 'text', 'text', 'text']}
                  headings={[]}
                  rows={formatRedeemData(
                    pointsReferrals?.length > 0 ? pointsReferrals : []
                  )}
                />
              </div>
            </Card.Section>
          </Card>
          {renderConfirmDelete && (
            <Modal
              open={renderConfirmDelete}
              onClose={() => setRenderConfirmDelete(false)}
              title={i18n.t('points_programs.change_coupon', {
                title:
                  redeemData?.fetchPointsReferrals?.pointsReferrals?.[0]?.title,
              })}
              primaryAction={{
                destructive: true,
                content: i18n.t('points_programs.delete_reward'),
                onAction: () => {
                  history.push(`/pointsProgram/referral_rewards/createNew`);
                },
              }}
              secondaryActions={[
                {
                  content: i18n.t('points_programs.delete_reward'),
                  onAction: () => {
                    setRenderConfirmDelete(false);
                  },
                },
              ]}
            >
              <Modal.Section>
                <TextContainer>
                  <p>
                    {i18n.t('points_programs.delete_reward_add_new_reward')}
                  </p>
                  <ul>
                    <li>
                      {i18n.t(
                        'points_programs.previously_sent_referral_reward'
                      )}
                    </li>
                  </ul>
                </TextContainer>
              </Modal.Section>
            </Modal>
          )}
        </div>
      )}
    </>
  );
};

export default ReferralCustomerRewards;
