import React, { useState } from 'react';
import { Card, Icon } from '@shopify/polaris';
import { HorizontalDotsMinor } from '@shopify/polaris-icons';
import dayjs from 'dayjs';
import { useHistory } from 'react-router-dom';
import { useParams } from 'react-router-dom';

const AlreadyCreatedBundles = ({
  bundle,
  setEditBundle,
  setToastContent,
  fetchAllBundles,
  formatDate,
}) => {
  const [moreOptions, setMoreOptions] = useState(false);

  const history = useHistory();

  const deleteOffer = (offer_id) => {
    fetch(`/subscription_bundle/delete_bundle/${offer_id}`, {
      method: 'DELETE',
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status) {
          fetchAllBundles();
          setToastContent('Bundle Deleted');
        }
      })
      .catch((e) => {
        console.log('Somethind went wrong' + e);
      });
  };

  const duplicateOffer = (offer_id) => {
    fetch(`/subscription_bundle/duplicate_bundle/${offer_id}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status) {
          fetchAllBundles();
          setToastContent('Bundle Duplicated');
        }
      })
      .catch((e) => {
        console.log('Somethind went wrong' + e);
      });
  };

  return (
    <div className="upsell_offer_wrapper_container">
      <Card>
        <div className="upsell_offer_wrapper">
          <div
            className="more_options"
            onClick={() => {
              setMoreOptions(!moreOptions);
            }}
          >
            <div className="more_options icon_wrapper">
              <Icon source={HorizontalDotsMinor} color="base" />
            </div>
            {moreOptions && (
              <div className="options_lists">
                <div
                  className="option_wrapper"
                  onClick={() =>
                    history.push(
                      `/customersTab/subscriptionBundles/${bundle.id}/${bundle.template_bundle_id}`
                    )
                  }
                >
                  Edit
                </div>
                <div
                  className="option_wrapper"
                  onClick={() => duplicateOffer(bundle.id)}
                >
                  Duplicate
                </div>
                <div
                  className="option_wrapper delete_offer"
                  onClick={() => deleteOffer(bundle.id)}
                >
                  Delete
                </div>
              </div>
            )}
          </div>
          <div className="img_wrapper">
            <img src={bundle?.image} />
          </div>
          <div className="content_block">
            <span
              className="offer_title"
              onClick={() =>
                history.push(
                  `/customersTab/subscriptionBundles/${bundle.id}/${bundle.template_bundle_id}`
                )
              }
            >
              {bundle.name}
            </span>
            <div className="date_wrapper">
              Last updated:{' '}
              {`${formatDate(bundle.created_at)}, ${dayjs(
                bundle.created_at
              )?.format('hh-mm a')}`}
            </div>
          </div>
        </div>
      </Card>
    </div>
  );
};

export default AlreadyCreatedBundles;
