import React, { useEffect, useState } from "react";
import SearchVariants from "./SearchVariants";
import Preview from "../../plans/Preview";

const AddCondtionProduct = ({ setConditionBlock, conditionBlock, index, i18n }) => {
    const [allVariants, setAllVariants] = useState(conditionBlock[index].variant_images);
    const [updated, setUpdated] = useState(false);
    const [formdata, setFormData] = useState();

    useEffect(() => {
        let condtion_block = conditionBlock
        condtion_block[index].variant_images = allVariants;
        console.log("condtion_block", condtion_block, allVariants)
        setConditionBlock([...condtion_block])
    }, [allVariants])

    return (
        <div className="field_row_content">
            <SearchVariants
                value={conditionBlock[index]?.variant_images || [[]]}
                setFieldValue={setFormData}
                fieldName={`variant_images`}
                allVariants={allVariants || [[]]}
                setAllVarients={setAllVariants}
                i18n={i18n}
                setFormData={setFormData}
                formData={formdata}
            />
            <div className="preview_variants_wrapper">
                <Preview
                    isUpdate={false}
                    allProducts={allVariants || [[]]}
                    setAllProducts={setAllVariants}
                    setUpdated={setUpdated}
                    i18n={i18n}
                />
            </div>
        </div>
    )
}

export default AddCondtionProduct;