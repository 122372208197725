import React, { useEffect, useState } from 'react';

import './rewardPage.css';
import Welcome from './DynamicComponents/Welcome';
const RewardsPageHtml = (props) => {
  const { formData, currentForm, contentBlocks } = props;
  const [dynamicStyle, setdynamicStyle] = useState();

  const defaultGiftCard =
    'https://cdn.shopify.com/s/files/1/0654/8042/5686/products/gift-card.png?v=1668795781';

  const getImageUrl = (fieldName) => {
    const file = formData?.[fieldName];
    try {
      return window.URL.createObjectURL(file);
    } catch (e) {
      return file;
    }
  };
  useEffect(() => {}, [formData, currentForm]);

  return (
    <div className="chargezen_rewards_wrapper container">
      <Welcome {...{ contentBlocks, formData }} />
      <style dangerouslySetInnerHTML={{ __html: dynamicStyle }} />
    </div>
  );
};
export default RewardsPageHtml;
