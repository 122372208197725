import React, { useEffect, useState } from 'react';
import { GiftCardMajor } from '@shopify/polaris-icons';
import {
  MangeIcon,
  SubscriptionContractsIcon,
  SmartyMessageIcon,
  ReplenishIcon,
} from './CustomIcon';
import { Icon } from '@shopify/polaris';
import './ManageApp.css';

const ManageApp = (props) => {
  const {
    domain,
    i18n,
    showManageApps,
    setShowManageApps,
    customNavTabs,
    setCustomNavTabs,
    fetchNavBarTabs,
  } = props;
  const [selectedTab, setSelectedTab] = useState(0);

  const tabListings = [
    {
      title: 'Subscriptions Home',
      icon: <SubscriptionContractsIcon />,
      desc: 'Manage all subscription contracts; view upcoming, billed, & failed charges; and configure subscription-specific flows, notifications & settings.',
      field: 'subscription_home',
    },
    {
      title: 'Affiliates & Influencers',
      icon: (
        <>
          {' '}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="lucide lucide-box"
          >
            <path d="M21 8a2 2 0 0 0-1-1.73l-7-4a2 2 0 0 0-2 0l-7 4A2 2 0 0 0 3 8v8a2 2 0 0 0 1 1.73l7 4a2 2 0 0 0 2 0l7-4A2 2 0 0 0 21 16Z" />
            <path d="m3.3 7 8.7 5 8.7-5" />
            <path d="M12 22V12" />
          </svg>
        </>
      ),
      desc: 'Launch an affiliate marketing program and reach new customers',
      field: 'affiliate_influencer',
    },
    {
      title: 'Upsells',
      icon: <MangeIcon />,
      desc: 'Scale revenue with targeted post-purchase Upsells on the shopify checkout.',
      field: 'upsells',
    },
    {
      title: 'SMSmarty',
      icon: <SmartyMessageIcon />,
      desc: 'Boost conversions; recover abandoned carts & keep your customers in the loop with SMS',
      field: 'sms_smarty',
    },
    {
      title: 'Kiosk & POS',
      icon: (
        <>
          {' '}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="lucide lucide-box"
          >
            <path d="M21 8a2 2 0 0 0-1-1.73l-7-4a2 2 0 0 0-2 0l-7 4A2 2 0 0 0 3 8v8a2 2 0 0 0 1 1.73l7 4a2 2 0 0 0 2 0l7-4A2 2 0 0 0 21 16Z" />
            <path d="m3.3 7 8.7 5 8.7-5" />
            <path d="M12 22V12" />
          </svg>
        </>
      ),
      desc: 'Launch self-serve restarant menus; manage reservations & kitchen back office operations.',
      field: 'kiosk_pos',
    },
    {
      title: 'Loyalty & Referrals',
      icon: <Icon source={GiftCardMajor} tone="base" />,
      desc: 'All the tools you need to enable your customers earn points; redeem points; launch apple wallet passes; and scale revenue with customer loyalty.',
      field: 'loyalty_rewards',
    },
    {
      title: 'Checkout Extensions',
      icon: (
        <>
          {' '}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="lucide lucide-box"
          >
            <path d="M21 8a2 2 0 0 0-1-1.73l-7-4a2 2 0 0 0-2 0l-7 4A2 2 0 0 0 3 8v8a2 2 0 0 0 1 1.73l7 4a2 2 0 0 0 2 0l7-4A2 2 0 0 0 21 16Z" />
            <path d="m3.3 7 8.7 5 8.7-5" />
            <path d="M12 22V12" />
          </svg>
        </>
      ),
      desc: 'Customize the shopify checkout with flexible & dynamic extensions',
      field: 'checkout_extensions',
    },
    {
      title: 'Gift cards & Store Credit',
      icon: <SubscriptionContractsIcon />,
      desc: 'Launch customer gift cards & issue store credit to your customers',
      field: 'gift_card_store_credit',
    },
    {
      title: 'ReplenishMe',
      icon: <ReplenishIcon />,
      desc: 'Launch omni-channel replenishment workflows via QR codes, SMS or Email',
      field: 'replenish_me',
    },
    {
      title: 'iOS Wallet passes',
      icon: (
        <>
          {' '}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="lucide lucide-box"
          >
            <path d="M21 8a2 2 0 0 0-1-1.73l-7-4a2 2 0 0 0-2 0l-7 4A2 2 0 0 0 3 8v8a2 2 0 0 0 1 1.73l7 4a2 2 0 0 0 2 0l7-4A2 2 0 0 0 21 16Z" />
            <path d="m3.3 7 8.7 5 8.7-5" />
            <path d="M12 22V12" />
          </svg>
        </>
      ),
      desc: 'Reward & engage with customers online & offline via branded wallet passes & push notifications',
      field: 'ios_wallet_passes',
    },
    {
      title: 'Pre-orders',
      icon: (
        <>
          {' '}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="lucide lucide-box"
          >
            <path d="M21 8a2 2 0 0 0-1-1.73l-7-4a2 2 0 0 0-2 0l-7 4A2 2 0 0 0 3 8v8a2 2 0 0 0 1 1.73l7 4a2 2 0 0 0 2 0l7-4A2 2 0 0 0 21 16Z" />
            <path d="m3.3 7 8.7 5 8.7-5" />
            <path d="M12 22V12" />
          </svg>
        </>
      ),
      desc: 'Launch & manage pre-orders on shopify',
      field: 'pre_orders',
    },
    {
      title: 'Try-before-you-buy',
      icon: <SubscriptionContractsIcon />,
      desc: 'Launch & manage try-before-you-buy programs on shopify',
      field: 'try_before_you_buy',
    },
  ];

  const toggleSetting = () => {
    let fieldvalue = !!!customNavTabs[tabListings[selectedTab].field];
    setCustomNavTabs({
      ...customNavTabs,
      [tabListings[selectedTab].field]: fieldvalue,
    });
    fetch('/toggle_nav_bar_setting', {
      method: 'POST',
      body: JSON.stringify({
        shopify_domain: props?.domain,
        nav_bar_tab: { [tabListings[selectedTab].field]: fieldvalue },
      }),
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((r) => r.json())
      .then((data) => {
        console.log('status', data);
        fetchNavBarTabs();
      });
  };

  return (
    <div
      className={`manage_app_container ${showManageApps ? '' : 'hidden'}`}
    >
      <div className="manage_app_container_wrapper">
        <div className="left_section_wrapper">
          <ul>
            {tabListings?.map((tab, i) => {
              return (
                <li
                  className={`manage_app_list_wrapper ${+selectedTab === +i ? 'activate' : ''
                    }`}
                  onClick={() => setSelectedTab(i)}
                >
                  <span className="title">{tab?.title}</span>
                </li>
              );
            })}
          </ul>
        </div>
        <div className="right_section_wrapper">
          <div
            className="close_icon_wrapper"
            onClick={() => setShowManageApps(false)}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="lucide lucide-x"
            >
              <path d="M18 6 6 18" />
              <path d="m6 6 12 12" />
            </svg>
          </div>
          <div className="title_icon_wrapper">
            {tabListings[selectedTab]?.icon}
            <h1>{tabListings[selectedTab]?.title}</h1>
          </div>
          <p className="desc_wrapper">{tabListings[selectedTab]?.desc}</p>
          <button
            className="activate_deactivate_button"
            onClick={() => toggleSetting()}
          >
            {customNavTabs?.[tabListings[selectedTab].field]
              ? `Deactivate`
              : `Activate`}
          </button>
        </div>
      </div>
    </div>
  );
};

export default ManageApp;
