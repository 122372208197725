import React, { useState, useCallback, useEffect, useRef } from 'react';
import { SearchMinor } from '@shopify/polaris-icons';
import { gql, useMutation, useQuery, useLazyQuery } from '@apollo/client';
import {
  Autocomplete,
  TextField,
  Icon,
  TextContainer,
  Spinner,
} from '@shopify/polaris';
import DeleteSVG from '../../../../assets/images/delete.svg';
const SearchPlan = (props) => {
  const { i18n, setFormData = null, formData = null, index } = props;

  const [allSelectedPlans, setAllSelectedPlans] = useState({});

  // Search product to add
  const GET_SELLING_PLAN = gql`
    query ($name: String!) {
      fetchSellingPlanByName(name: $name) {
        id
        name
        shopifyId
      }
    }
  `;

  const [sellingPlanList, setSellingPlanList] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);

  const [value, setValue] = useState('');

  const [GetPlans, { loading, data, errors }] = useLazyQuery(GET_SELLING_PLAN, {
    fetchPolicy: 'no-cache',
  });

  useEffect(() => {
    if (data && data.fetchSellingPlanByName) {
      handleSetSellingPlan(data.fetchSellingPlanByName);
    }
  }, [data]);

  const handleSetSellingPlan = useCallback(
    (plans) => {
      const result = [];
      plans.map((plan) =>
        result.push({
          value: plan.shopifyId,
          label: plan.name,
        })
      );
      if (!loading) {
        setSellingPlanList(result);
      }
    },
    [sellingPlanList]
  );

  const updateText = (e) => {
    setValue(e);
  };

  useEffect(() => {
    GetPlans({ variables: { name: value } });
  }, [value]);

  const updateSelection = useCallback(
    (selected) => {
      if (selected?.length > 0) {
        const plan = sellingPlanList.find((item) => item.value === selected[0]);
        const planobj = { planName: plan.label, id: plan.value };
        updateText(planobj.planName);
        setSelectedOptions(selected);
        setAllSelectedPlans(planobj);
      }
    },
    [sellingPlanList]
  );

  const textField = (
    <Autocomplete.TextField
      onChange={updateText}
      label=""
      type="text"
      value={value}
      prefix={<Icon source={SearchMinor} color="base" />}
      placeholder={i18n.t('search_plan_to_add')}
      suffix={
        loading && (
          <Spinner accessibilityLabel="Small spinner example" size="small" />
        )
      }
    />
  );

  const emptyState = (
    <React.Fragment>
      <Icon source={SearchMinor} />
      <div style={{ textAlign: 'center' }}>
        <TextContainer>Could not find any results</TextContainer>
      </div>
    </React.Fragment>
  );

  useEffect(() => {
    if (formData?.variants[index]?.selling_plan) {
      setAllSelectedPlans(formData?.variants[index]?.selling_plan);
    }
  }, []);

  useEffect(() => {
    let variants = formData?.variants;
    variants[index]['selling_plan'] = allSelectedPlans;
    if (allSelectedPlans?.id) {
      variants[index].type = 'add_subscription';
    } else {
      variants[index].type = 'add_variant';
    }
    setFormData({ ...formData, variants: variants });
  }, [allSelectedPlans]);

  return (
    <div className="">
      {allSelectedPlans?.id && (
        <div className="selected_plan_wrapper upsell_plan_wrapper mt-16">
          <div className="selectedPlan">
            <div
              className="removePlan"
              onClick={() => {
                setAllSelectedPlans({});
              }}
            >
              x
            </div>
            {allSelectedPlans?.planName}
          </div>
        </div>
      )}

      <Autocomplete
        options={sellingPlanList}
        selected={selectedOptions}
        onSelect={updateSelection}
        textField={textField}
        emptyState={emptyState}
      />
    </div>
  );
};

export default SearchPlan;
