import { Card, Frame, Icon, Page, Toast } from '@shopify/polaris';
import React, { useCallback, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import AffiliateSettingSideBar from './AffiliateSettingSideBar';
import {
  EditMajor,
  ChevronRightMinor,
  ChevronDownMinor,
} from '@shopify/polaris-icons';
import { ProfileMajor, PaymentsMajor } from '@shopify/polaris-icons';
import EditAffiliateModal from '../Affiliates/AffiliateDetail/EditAffiliateModal';

const AffiliateSettings = (props) => {
  const { userToken, domain } = props;
  const { selectedTab, user_token } = useParams();
  const [apiData, setApiData] = useState({});
  const [editAffiliate, setEditAffiliate] = useState();
  const [toastContent, setToastContent] = useState();
  const hideSaveSuccess = useCallback(() => setToastContent(), []);
  const [toggleTab, setToggleTab] = useState({});
  const [promotionalToggle, setPromotionalToggler] = useState({});
  const history = useHistory();

  const fetchAffiliate = () => {
    fetch(`/getAffiliateDetail/current_user?shopify_domain=${domain}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setApiData(data);
      });
  };

  const settingsSideBar = [
    {
      icon: <Icon source={ProfileMajor} tone="base" />,
      title: 'Affiliate profile',
      tab: 'profile',
    },
    {
      icon: <Icon source={PaymentsMajor} tone="base" />,
      title: 'Payouts',
      tab: 'payouts',
    },
  ];

  const changeTab = (tab) => {
    history.push(`/settings/${tab}/${userToken}`);
  };

  useEffect(() => {
    if (userToken !== user_token) {
      let selectedtab = selectedTab || settingsSideBar[0].tab;
      history.push(`/settings/${selectedtab}/${userToken}`);
    } else {
      fetchAffiliate();
    }
  }, []);

  return (
    <Frame>
      {toastContent && (
        <Toast content={toastContent} onDismiss={hideSaveSuccess} />
      )}
      <Page>
        {editAffiliate && (
          <EditAffiliateModal
            {...{
              editAffiliate,
              setEditAffiliate,
              apiData,
              domain,
              setToastContent,
              fetchAffiliate,
            }}
          />
        )}
        <div className="affiliate_setting_main_container">
          <div className="affiliate_setting_wrapper">
            <AffiliateSettingSideBar {...{ settingsSideBar, changeTab }} />
            <div className="affiliate_setting_body_container">
              <h1 className="shopify_style_header">User Details</h1>
              <div className="shopify_base_card">
                <Card
                  title={
                    <div className="card_title">
                      <span>User details</span>
                      <span
                        className="icon_wrapper"
                        onClick={() => {
                          setEditAffiliate(true);
                        }}
                      >
                        <Icon source={EditMajor} tone="base" />
                      </span>
                    </div>
                  }
                >
                  <div className="chargezen_shopify_style_settings_section">
                    <div className="contentsTab_container">
                      <div className="contents_tab_wrapper">
                        <span>Company Profile</span>
                      </div>
                      <div className={`contents_wrapper_container`}>
                        <div className="profile_row">
                          <h6>Company name</h6>
                          <p>{apiData?.user_affiliate_info?.company_name}</p>
                        </div>
                        <div className="profile_row">
                          <h6>Tax residence</h6>
                          <p>{apiData?.user_affiliate_info?.tax_residency}</p>
                        </div>
                        <div className="profile_row">
                          <h6>Email</h6>
                          <p>{apiData?.user_affiliate_info?.email}</p>
                        </div>
                      </div>
                    </div>
                    <div className="contentsTab_container">
                      <div className="contents_tab_wrapper">
                        <span>Primary Residence</span>
                      </div>
                      <div className={`contents_wrapper_container`}>
                        <div className="profile_row">
                          <h6>Address</h6>
                          <p>{apiData?.user_affiliate_info?.primary_country}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </Card>
                <Card
                  title={
                    <div
                      className="card_title"
                      onClick={() =>
                        setToggleTab({
                          ...toggleTab,
                          promotional_type: !toggleTab?.promotional_type,
                        })
                      }
                    >
                      <span>Promotional type</span>
                      <span
                        className={`icon_wrapper ${
                          toggleTab?.promotional_type ? 'active' : ''
                        }`}
                      >
                        <Icon source={ChevronDownMinor} tone="base" />
                      </span>
                    </div>
                  }
                >
                  <div
                    className={`chargezen_shopify_style_settings_section ${
                      toggleTab?.promotional_type ? '' : 'hidden'
                    }`}
                  >
                    <div className="contentsTab_container">
                      <div className="contents_tab_wrapper">
                        <span>Promotional Type</span>
                      </div>
                      <div className={`contents_wrapper_container`}>
                        <div className="profile_row">
                          <h6>Primary Region</h6>
                          <p>{apiData?.user_affiliate_info?.primary_region}</p>
                        </div>
                        <div className="profile_row">
                          <h6>Promotional Type</h6>
                          <div className="promotional_types_card">
                            {apiData?.promotional_types?.map((pt, i) => {
                              return (
                                <div className="promotional_types_container">
                                  <div
                                    className={`promotion_type_toggle ${
                                      promotionalToggle[i] ? 'opened' : ''
                                    }`}
                                    onClick={() => {
                                      setPromotionalToggler({
                                        ...promotionalToggle,
                                        [i]: !promotionalToggle[i],
                                      });
                                    }}
                                  >
                                    <span className="icon_wrapper">
                                      <ChevronRightMinor />
                                    </span>
                                    <span>{pt?.title}</span>
                                  </div>
                                  {pt?.questions?.map((question) => {
                                    return (
                                      <div
                                        className={`promotional_types_wrapper ${
                                          promotionalToggle[i] ? '' : 'hidden'
                                        }`}
                                      >
                                        <div className="promotional_types_title">
                                          <div className="promitional_type_question">
                                            <input
                                              type="checkbox"
                                              checked={question.value}
                                            />
                                            <span className="question_title">
                                              {question?.title}
                                            </span>
                                          </div>

                                          <div className="primary_promotional_type">
                                            <input
                                              type="radio"
                                              checked={
                                                question?.primary_promotional_type
                                              }
                                            />
                                            <span>
                                              Primary promotional type
                                            </span>
                                          </div>
                                        </div>
                                        <div className="promotional_types_helptext">
                                          {question?.helptext}
                                        </div>
                                      </div>
                                    );
                                  })}
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Card>
                <Card
                  title={
                    <div className="card_title">
                      <span>Promotional space</span>
                      <span className="icon_wrapper">
                        <Icon source={EditMajor} tone="base" />
                      </span>
                    </div>
                  }
                >
                  <div className="chargezen_shopify_style_settings_section"></div>
                </Card>
              </div>
            </div>
          </div>
        </div>
      </Page>
    </Frame>
  );
};

export default AffiliateSettings;
