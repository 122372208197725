import React, { useCallback, useContext, useEffect, useState } from 'react';
import {
  Form,
  FormLayout,
  Button,
  Frame,
  Toast,
  Page,
  Modal,
  Stack,
  DropZone,
  Thumbnail,
  Caption,
  Select,
  TextField,
  Card,
  Icon,
} from '@shopify/polaris';
import './style.css';
import CustomerAutocomplete from './CustomerAutocomplete';
import SellingPlans from './SellingPlans';
import { ChevronRightMinor, NoteMinor } from '@shopify/polaris-icons';
import Papa from 'papaparse';
import mixpanel from 'mixpanel-browser';
import { DomainContext } from '../domain-context';
import { useQuery, gql } from '@apollo/client';
import PixelIcon from '../../images/PixelIcon';
import { MobileBackArrowMajor } from '@shopify/polaris-icons';
const CustomerMigration = (props) => {
  const { i18n, handleBack, setDetailView, detailView } = props;
  const { domain } = useContext(DomainContext);
  const [lineItems, setLineItems] = useState([
    { variant_id: '', quantity: '', current_price: '' },
  ]);
  const initFormValues = {
    customer_id: '',
    next_billing_date: '',
    delivery_date: '',
    delivery_price: '',
    variant_id: '',
    quantity: '',
    current_price: '',
    billing_policy_interval: '',
    billing_policy_interval_count: '',
    delivery_policy_interval: '',
    delivery_policy_interval_count: '',
    payment_method: '',
    currency: '',
  };
  const [formField, setFormField] = useState(initFormValues);
  const [allCurrencies, setAllCurrencies] = useState([]);
  const handleChange = (e) => {
    setFormField({ ...formField, [e.target.name]: e.target.value });
  };
  const [toastActive, setToastActive] = useState(false);
  const toggleToastActive = useCallback(
    () => setToastActive((toastActive) => !toastActive),
    []
  );
  const [toastContent, setToastContent] = useState('');
  const paymentOptions = [
    { label: 'Shopify Payment', value: 'shopify_payment' },
    { label: 'Stripe', value: 'stripe' },
  ];
  //Initialise Mixpanel
  mixpanel.init('467d5df251a711e7b0ae20d18c8fb2e1', {
    debug: true,
    cross_subdomain_cookie: false,
  });
  const mixpanelId = localStorage.getItem('distinct_id_admin_chargezen');

  //Currency
  const GET_CURRENCY = gql`
    query {
      fetchCurrency {
        allCurrencies {
          label
          value
        }
        currentCurrency
      }
    }
  `;
  const { data, loading, error, refetch } = useQuery(GET_CURRENCY, {
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    refetch();
  }, []);

  useEffect(() => {
    setAllCurrencies(data?.fetchCurrency?.allCurrencies);
    setFormField({
      ...formField,
      currency: data?.fetchCurrency?.currentCurrency,
    });
  }, [data]);

  const submitForm = () => {
    mixpanel.identify(mixpanelId);
    mixpanel.track('Created Customer Migration', {
      customer_id: selectedCustomers,
    });
    fetch('/subscriptions/customer_migration', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        data: formField,
        customer_id: selectedCustomers,
        sellingplan: selectedSellingPlan,
        shopify_domain: domain,
        lineItems: lineItems,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        setToastContent(data?.response);
        if (!data?.error) {
          setTimeout(() => {
            window.location.reload();
          }, 3000);
        }
        setToastActive(true);
      });
  };

  const [selectedSellingPlan, setSelectedSellingPlan] = useState('');
  const [selectedCustomers, setSelectedCustomers] = useState();

  const [deselectedOptions, setDeselectedOptions] = useState([]);
  //upload file
  const [active, setActive] = useState(false);
  const toggleActive = useCallback(() => setActive((active) => !active), []);
  const handleDropZoneDrop = useCallback(
    (_dropFiles, acceptedFiles, _rejectedFiles) =>
      setFile((file) => acceptedFiles[0]),
    []
  );
  const [file, setFile] = useState();
  const fileUpload = !file && <DropZone.FileUpload />;
  const csvData = [];

  const parsedata =
    file &&
    Papa.parse(file, {
      header: false,
      step: function (result) {
        csvData.push(result.data);
      },
      complete: function (results, file) {
        // console.log('csvData: ', csvData);
      },
    });
  const uploadedFile = file && (
    <Stack>
      <Thumbnail size="small" alt={file.name} source={NoteMinor} />
      <div>
        {file.name} <Caption>{file.size} bytes</Caption>
      </div>
    </Stack>
  );

  const fetchCustomers = (value) => {
    fetch(
      `/subscriptions/fetch_customers_from_shopify?query=${value}&shopify_domain=${domain}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      }
    )
      .then((response) => response.json())
      .then((data) => {
        var tempData = [];
        data.customers?.map((customer) => {
          tempData.push({
            value: customer.data.node.id,
            label: customer.data.node.email,
          });
        });
        setDeselectedOptions(tempData);
      });
  };
  useEffect(() => {
    fetchCustomers('');
  }, []);

  const handleImportCustomer = () => {
    const rcs = [];
    const tempData = csvData.slice(1);
    tempData?.map((item) => {
      if (item[0]) {
        rcs.push({
          customer_email: item[0],
          sellingplan: `gid://shopify/SellingPlan/${item[8]}`,
          data: {
            customer_email: item[0],
            payment_method: item[1],
            next_billing_date: item[2],
            delivery_date: item[3],
            delivery_price: `${[4]}`,
            variant_id: item[5],
            quantity: item[6],
            current_price: item[7],
            currency: item[9],
          },
        });
      }
    });
    mixpanel.identify(mixpanelId);
    mixpanel.track('Import Customer Migration', {});
    fetch('/subscriptions/import_customer_migrations', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ data: rcs, shopify_domain: domain }),
    })
      .then((response) => response.json())
      .then((data) => {
        setToastActive(true);
        setTimeout(() => {
          window.location.reload();
        }, 3000);
      });
    setFile();
  };

  const handleLineItemChange = (index, fieldName, value) => {
    let allLineItems = [...lineItems];
    allLineItems[index][fieldName] = value;
    setLineItems(allLineItems);
  };
  console.log('lineItems', lineItems);
  return (
    <div className="customer_migration">
      <Frame>
        {toastActive && (
          <Toast content={toastContent} onDismiss={toggleToastActive} />
        )}
        <Card>
          <Form>
            <FormLayout>
              <FormLayout.Group>
                <label>
                  <CustomerAutocomplete
                    i18n={i18n}
                    fetchCustomers={fetchCustomers}
                    deselectedOptions={deselectedOptions}
                    setSelectedCustomers={setSelectedCustomers}
                  />
                </label>
              </FormLayout.Group>
            </FormLayout>
            <FormLayout>
              <FormLayout.Group>
                <Select
                  label="Select Customer Payment Method:"
                  name="payment_method"
                  options={paymentOptions}
                  onChange={(e) =>
                    setFormField({ ...formField, payment_method: e })
                  }
                  value={formField?.payment_method}
                />
              </FormLayout.Group>
            </FormLayout>
            <FormLayout>
              <FormLayout.Group>
                <Select
                  label="Select Currency:"
                  name="currency"
                  options={allCurrencies}
                  onChange={(e) => setFormField({ ...formField, currency: e })}
                  value={formField?.currency}
                />
              </FormLayout.Group>
            </FormLayout>
            <FormLayout>
              <FormLayout.Group>
                <label>
                  Next Billing Date: {'\n'}
                  <input
                    type="date"
                    className="form-control"
                    onChange={(e) => handleChange(e)}
                    name="next_billing_date"
                  />
                </label>
                <label>
                  Delivery Date: {'\n'}
                  <input
                    type="date"
                    className="form-control"
                    onChange={(e) => handleChange(e)}
                    name="delivery_date"
                  />
                </label>
              </FormLayout.Group>
            </FormLayout>
            <FormLayout>
              <FormLayout.Group>
                <TextField
                  label="Delivery Price:"
                  value={formField?.delivery_price}
                  name="delivery_price"
                  onChange={(e) =>
                    setFormField({ ...formField, delivery_price: e })
                  }
                  autoComplete="off"
                />
              </FormLayout.Group>
            </FormLayout>
            <FormLayout>
              <FormLayout.Group>
                <label>
                  <SellingPlans
                    i18n={i18n}
                    setSelectedSellingPlan={setSelectedSellingPlan}
                    selectedSellingPlan={selectedSellingPlan}
                  />
                </label>
              </FormLayout.Group>
            </FormLayout>
          </Form>
        </Card>
        {lineItems?.map((val, i) => {
          return (
            <Card title={<div className="heading_title">Add product</div>}>
              <Form>
                <FormLayout>
                  <FormLayout.Group>
                    <TextField
                      label="Variant Id:"
                      value={lineItems[i]['variant_id']}
                      name="variant_id"
                      onChange={(e) => handleLineItemChange(i, 'variant_id', e)}
                      autoComplete="off"
                    />
                  </FormLayout.Group>
                  <FormLayout.Group>
                    <TextField
                      label="Quantity:"
                      value={lineItems[i]['quantity']}
                      name="quantity"
                      onChange={(e) => handleLineItemChange(i, 'quantity', e)}
                      autoComplete="off"
                    />
                    <TextField
                      label="Current Price:"
                      value={lineItems[i]['current_price']}
                      placeholder="Enter Price of one item only"
                      name="current_price"
                      onChange={(e) =>
                        handleLineItemChange(i, 'current_price', e)
                      }
                      autoComplete="off"
                    />
                  </FormLayout.Group>
                </FormLayout>
              </Form>
            </Card>
          );
        })}

        <Card sectioned>
          <div className="add_more_line_item">
            <Button
              onClick={() =>
                setLineItems([
                  ...lineItems,
                  { variant_id: '', quantity: '', current_price: '' },
                ])
              }
            >
              Add another product
            </Button>
          </div>
          <Button onClick={submitForm}>Submit</Button>
        </Card>
        <Modal
          large
          open={active}
          onClose={toggleActive}
          title="Import customer migrations by CSV"
          primaryAction={{
            content: 'Import customer migrations',
            onAction: () => {
              toggleActive();
              handleImportCustomer();
              // parsedata;
            },
          }}
          secondaryActions={[
            {
              content: 'Cancel',
              onAction: () => {
                toggleActive();
                setFile();
              },
            },
          ]}
        >
          <Modal.Section>
            <Stack vertical>
              <DropZone
                allowMultiple={false}
                onDrop={handleDropZoneDrop}
                accept=".csv"
                errorOverlayText="File type must be .csv"
                type="file"
              >
                {uploadedFile}
                {fileUpload}
              </DropZone>
              {/* <Checkbox
              checked={checked}
              label="Overwrite existing customers that have the same customer ID"
              onChange={handleCheckbox}
            /> */}
            </Stack>
          </Modal.Section>
        </Modal>
      </Frame>
    </div>
  );
};
export default CustomerMigration;
