import React, { useState } from 'react';
import {
  MobileBackArrowMajor,
  MobileChevronMajor,
  MobileMajor,
  DesktopMajor,
  ChevronUpMinor,
} from '@shopify/polaris-icons';
import { Button, Checkbox, Icon, Select, TextField } from '@shopify/polaris';
const DesignSettings = ({
  toggleOnButton,
  subForm,
  setSubForm,
  setToggleOnButton,
  contentBlock,
  setContentBlock,
  initContentBlock,
  formData,
  setFormData,
  handleSubmit,
  handleFormVariantData,
}) => {
  const [toggleContentBlock, setToogleContentBlock] = useState({});

  const handleContentChange = (value, key, index) => {
    let content_block = contentBlock;
    content_block[index][key] = value;
    setContentBlock([...content_block]);
  };

  const handleContetnImageUpload = (e, index) => {
    if (e.target.files.length > 0) {
      let content_block = contentBlock;
      content_block[index]['image'] = e.target.files[0];
      content_block[index]['image_url'] = URL.createObjectURL(
        e.target.files[0]
      );
      setContentBlock([...content_block]);
    }
  };

  const handleFormDataChange = (value, key) => {
    let form_data = formData;
    form_data[key] = value;
    setFormData({ ...form_data });
  };

  const handleContentRemove = (index) => {
    let content_block = contentBlock;
    content_block.splice(index, 1);
    setContentBlock([...content_block]);
  };

  const supportedLanguages = [
    { label: 'Arabic', value: 'ar' },
    { label: 'Danish', value: 'da' },
    { label: 'Dutch', value: 'nl' },
    { label: 'English', value: 'en' },
    { label: 'Finnish', value: 'fi' },
    { label: 'French', value: 'fr' },
    { label: 'German', value: 'de' },
    { label: 'Italian', value: 'it' },
    { label: 'Japanese', value: 'ja' },
    { label: 'Korean', value: 'ko' },
    { label: 'Norwegian Bokmål', value: 'nb' },
    { label: 'Portuguese - Brazil', value: 'pt-BR' },
    { label: 'Spanish', value: 'es' },
    { label: 'Swedish', value: 'sv' },
    { label: 'Turkish', value: 'tr' },
  ];

  return (
    <div className="card_content_wrapper">
      {/* <div className="main_header_content">
        <button
          className="back_button_pointer"
          onClick={() => {
            setSubForm('');
          }}
        >
          <span className="icon">
            <Icon source={MobileChevronMajor} color="base" />
          </span>
        </button>
        <span className="button_text">{subForm}</span>
      </div> */}
      <div className="form_field_wrapper">
        {subForm === 'DESIGN_TEXT' ? (
          <>
            <div className="field_row Polaris-Card__Section">
              {formData?.variants?.length <= 0 && (
                <div className="no_products_selected">
                  No products selected.
                </div>
              )}
              {formData?.variants?.map((v, i) => {
                return (
                  <div className="variants_setting_card">
                    <div className="field_row_content">
                      <Checkbox
                        label="Localize"
                        checked={v.localize}
                        onChange={(e) => {
                          handleFormVariantData(e, i, 'localize');
                        }}
                      />
                    </div>
                    {v.localize && (
                      <>
                        <div className="field_row_label mt-16">
                          Select Language
                        </div>
                        <div className="field_row_content">
                          <Select
                            label={``}
                            options={supportedLanguages}
                            onChange={(e) => {
                              handleFormVariantData(e, i, 'locale');
                            }}
                            value={v.locale}
                          />
                        </div>
                      </>
                    )}
                    <div className="field_row_label mt-16">Headline</div>
                    <div className="field_row_content">
                      <TextField
                        label=""
                        type="text"
                        value={v?.offerTitle || ''}
                        onChange={(e) => {
                          handleFormVariantData(e, i, 'offerTitle');
                        }}
                      />
                    </div>
                    <div className="field_row_label mt-16">Subhead</div>
                    <div className="field_row_content">
                      <TextField
                        label=""
                        type="text"
                        value={v?.mainTitle || ''}
                        onChange={(e) => {
                          handleFormVariantData(e, i, 'mainTitle');
                        }}
                      />
                    </div>
                    <div className="for_variant_container">
                      <img src={v?.image} />
                      <p className="title">{v.title}</p>
                    </div>
                  </div>
                );
              })}

              {/* <div className="field_row_label mt-16">Headline size</div>
              <div className="field_row_content">
                <TextField
                  label=""
                  type="number"
                  value={formData.design_healine_size}
                  onChange={(e) => {
                    handleFormDataChange(e, 'design_healine_size');
                  }}
                  suffix="px"
                />
              </div> */}
            </div>
            <div className="field_row navi Polaris-Card__Section">
              <div className="flex justify-between sidebar_action_buttons">
                <Button
                  onClick={() => {
                    handleSubmit();
                  }}
                >
                  Save
                </Button>
              </div>
            </div>
          </>
        ) : subForm === 'DESIGN_SETTINGS' ? (
          <>
            <div className="field_row Polaris-Card__Section product_settings_wrapper">
              <div className="field_row_label">PRODUCT SETTINGS</div>
              <Checkbox
                label="Hide product description"
                checked={formData?.hide_product_desc}
                onChange={(e) => {
                  handleFormDataChange(e, 'hide_product_desc');
                }}
              />
              <Checkbox
                label="Hide product thumbnails"
                checked={formData?.hide_product_thumbnail}
                onChange={(e) => {
                  handleFormDataChange(e, 'hide_product_thumbnail');
                }}
              />
            </div>
            <div className="field_row navi Polaris-Card__Section">
              <div className="flex justify-between sidebar_action_buttons">
                <Button
                  onClick={() => {
                    handleSubmit();
                  }}
                >
                  Save
                </Button>
              </div>
            </div>
          </>
        ) : subForm === 'DESIGN_CONTENT' ? (
          <>
            <div className="field_row Polaris-Card__Section">
              <div className="button_group toggleButtons">
                <Button
                  onClick={() => {
                    setFormData({ ...formData, design_content: true });
                  }}
                  primary={formData?.design_content}
                >
                  On
                </Button>
                <Button
                  onClick={() => {
                    setFormData({ ...formData, design_content: false });
                  }}
                  primary={!formData?.design_content}
                >
                  Off
                </Button>
              </div>
              <div className="order_condition_help_text">
                Content is turned on and will display below product.
              </div>
            </div>
            {formData?.design_content && (
              <>
                <div className="field_row Polaris-Card__Section">
                  <div className="field_row_label">LAYOUT</div>
                  <div className="layout_selector_wrapper">
                    <div className="layout_subselector_wrapper">
                      <div className="img_wrapper">
                        <img
                          alt=""
                          src="https://postpurchaseapp.com/_next/image?url=%2Fimages%2Flayout_2.jpg&w=1920&q=75"
                        />
                      </div>
                      <div className="text">Checker</div>
                    </div>
                    <div className="layout_subselector_wrapper">
                      <div className="img_wrapper">
                        <img
                          alt=""
                          src="https://postpurchaseapp.com/_next/image?url=%2Fimages%2Flayout_1.jpg&w=1920&q=75"
                        />
                      </div>
                      <div className="text">Side By Side</div>
                    </div>
                  </div>
                </div>

                <div className="field_row Polaris-Card__Section">
                  <div
                    className="add_new_content_container"
                    style={{ border: 0, marginTop: 0 }}
                  >
                    <div className="flex justify-between">
                      <div className="field_row_label">CONTENT</div>
                      <button
                        className="add_new_content_button"
                        onClick={() => {
                          setContentBlock([...contentBlock, initContentBlock]);
                        }}
                      >
                        +
                      </button>
                    </div>
                    <div className="content_block_wrapper">
                      {contentBlock?.map((content, i) => {
                        return (
                          <div className="sub_contentBlock">
                            <div className="header_content toggleAccordian">
                              <div className="text">Content{i + 1}</div>
                              <button
                                className="icon_wrapper"
                                onClick={() => {
                                  setToogleContentBlock({
                                    ...toggleContentBlock,
                                    [`block${i}`]:
                                      !toggleContentBlock[`block${i}`],
                                  });
                                }}
                              >
                                <Icon source={ChevronUpMinor} color="base" />
                              </button>
                            </div>
                            <div
                              className={`content_block_form_wrapper ${
                                toggleContentBlock[`block${i}`] ? '' : 'hidden'
                              }`}
                            >
                              <div className="row_wrapper">
                                <label htmlFor={`content_img_${i}`}>
                                  Upload Image
                                </label>
                                <input
                                  id={`content_img_${i}`}
                                  type="file"
                                  accept=""
                                  onChange={(e) => {
                                    handleContetnImageUpload(e, i);
                                  }}
                                />
                              </div>
                              <div className="row_wrapper">
                                <Select
                                  label="Desktop image alignment"
                                  options={['Left', 'Right']}
                                  value={content?.desktop_image_alignment}
                                  onChange={(e) => {
                                    handleContentChange(
                                      e,
                                      'desktop_image_alignment',
                                      i
                                    );
                                  }}
                                />
                              </div>
                              <div className="row_wrapper">
                                <Select
                                  label="Desktop image width"
                                  options={['Default', '75%']}
                                  value={content?.desktop_image_width}
                                  onChange={(e) => {
                                    handleContentChange(
                                      e,
                                      'desktop_image_width',
                                      i
                                    );
                                  }}
                                />
                              </div>
                              <div className="row_wrapper">
                                <TextField
                                  label="Headline"
                                  type="text"
                                  value={content?.headline}
                                  onChange={(e) => {
                                    handleContentChange(e, 'headline', i);
                                  }}
                                />
                              </div>
                              <div className="row_wrapper">
                                <TextField
                                  label="Text"
                                  type="text"
                                  multiline={5}
                                  value={content?.description}
                                  onChange={(e) => {
                                    handleContentChange(e, 'description', i);
                                  }}
                                />
                              </div>
                              <div className="removeButtonWrapper">
                                <button
                                  className="remove_upsell_block"
                                  onClick={() => {
                                    handleContentRemove(i);
                                  }}
                                >
                                  Remove
                                </button>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </>
            )}
            <div className="field_row navi Polaris-Card__Section">
              <div className="flex justify-between sidebar_action_buttons">
                <Button
                  onClick={() => {
                    handleSubmit();
                  }}
                >
                  Save
                </Button>
              </div>
            </div>
          </>
        ) : (
          ''
        )}
      </div>
    </div>
  );
};
export default DesignSettings;
