import React from 'react';
import { FooterHelp, Link } from '@shopify/polaris';

const Footer = (props) => {
  const { i18n } = props
  return (
    <FooterHelp>
      <Link target="_blank" rel="noopener noreferrer" url={process.env.APP_TYPE == "public" ? "https://www.chargezen.co/term-of-use" : "#"}>
        {i18n.t('terms_and_conditions')}
      </Link>
      <span> • </span>
      <Link target="_blank" rel="noopener noreferrer" url={process.env.APP_TYPE == "public" ? "https://www.chargezen.co/privacypolicy" : "#"}>
        Privacy Policy
      </Link>
    </FooterHelp>
  );
};

export default Footer;
