import { gql, useMutation, useQuery } from '@apollo/client';
import {
  Badge,
  Banner,
  Button,
  ButtonGroup,
  Caption,
  Card,
  Checkbox,
  ChoiceList,
  DataTable,
  DropZone,
  Filters,
  Frame,
  Icon,
  List,
  Modal,
  Page,
  Spinner,
  Stack,
  Tabs,
  Thumbnail,
  Toast,
} from '@shopify/polaris';
import {
  DeleteMajor,
  NoteMinor,
  MobileBackArrowMajor,
} from '@shopify/polaris-icons';
import Papa from 'papaparse';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { CSVLink } from 'react-csv';
import { useHistory } from 'react-router-dom';
import swapIcon from '../../../assets/images/icons/swap.svg';
import AppLayout from '../layout/Layout';
import moment from 'moment';
import { Pagination } from '@shopify/polaris';
import LoadingScreen from '../LoadingScreen';
import './customerModal.css';
import HeaderButtons from '../HeaderButtons/HeaderButtons';
import PixelIcon from '../../images/PixelIcon';
import { DomainContext } from '../domain-context';
import { sortBy } from 'lodash';
import CustomerDetail from './CustomerDetail/CustomerDetail';
import { useParams } from 'react-router-dom';

// import json2csv from 'json2csv';
const subscriptions = [
  'active',
  'new',
  'returning',
  'paused',
  'cancelled',
  'all',
];

const {
  Parser,
  transforms: { unwind },
} = require('json2csv');

// let sortOrder = 0;

const ButtonRemove = (props) => {
  const { selectedCustomers, refetch, setselectedCustomers } = props;
  const DELETE_CUSTOMER = gql`
    mutation ($input: DeleteCustomersInput!) {
      deleteCustomers(input: $input) {
        result
      }
    }
  `;

  const [deleteCustomer, { loading: deleting, error: deleteError }] =
    useMutation(DELETE_CUSTOMER);

  const handleRemoveCustomers = () => {
    if (deleting) return;
    deleteCustomer({
      variables: {
        input: { params: selectedCustomers },
      },
    }).then((resp) => {
      const errors = resp.errors;
      if (errors) {
      } else {
        refetch({
          variables: {
            page: page.toString(),
            searchquery: searchQuery,
            sortBy: sortFilter,
          },
        }).then(() => setselectedCustomers([]));
      }
    });
  };

  return (
    <Button
      destructive
      icon={DeleteMajor}
      onClick={() => handleRemoveCustomers()}
      loading={deleting}
    >
      Delete Customer
    </Button>
  );
};

const CustomerModal = (props) => {
  const { shopifyDomain, i18n } = props;
  const { token } = useParams();
  const { domain } = useContext(DomainContext);
  const history = useHistory();
  const [uid, setuid] = useState();
  // Start Tabs
  const [headerButton, setHeaderButton] = useState('all');
  const headerButtons = [
    {
      val: 'all',
      name: 'All',
    },
    {
      val: 'subscriptions',
      name: 'Subscriptions',
    },
    {
      val: 'replenishme',
      name: 'Replenishme',
    },
    {
      val: 'preorders',
      name: 'Preorders',
    },
    {
      val: 'memberships',
      name: 'Memberships',
    },
  ];

  // End tabs
  const [sortOrder, setSortOrder] = useState(0);

  const [moneySpent, setMoneySpent] = useState(null);
  const [taggedWith, setTaggedWith] = useState(null);
  const [queryValue, setQueryValue] = useState(null);
  const [loadingScreen, setLoadingScreen] = useState(false);

  const handleMoneySpentChange = useCallback(
    (value) => setMoneySpent(value),
    []
  );
  const handleTaggedWithChange = useCallback(
    (value) => setTaggedWith(value),
    []
  );

  const handleMoneySpentRemove = useCallback(() => setMoneySpent(null), []);
  const handleTaggedWithRemove = useCallback(() => setTaggedWith(null), []);
  const handleQueryValueRemove = useCallback(() => setQueryValue(null), []);
  const handleFiltersClearAll = useCallback(() => {
    handleMoneySpentRemove();
    handleTaggedWithRemove();
    handleQueryValueRemove();
  }, [handleMoneySpentRemove, handleQueryValueRemove, handleTaggedWithRemove]);

  const filters = [
    {
      key: 'taggedWith',
      label: 'Tagged with',
      filter: (
        <ChoiceList
          title="Tagged with"
          titleHidden
          choices={[
            { label: 'Cancelled', value: 'cancelled' },
            { label: 'Active', value: 'active' },
            { label: 'In Trial', value: 'inTrial' },
            { label: 'Future', value: 'future' },
          ]}
          selected={taggedWith || []}
          onChange={handleTaggedWithChange}
        // allowMultiple
        />
      ),
      shortcut: true,
    },
    {
      key: 'moneySpent',
      label: 'Money spent',
      // filter: (
      //   <RangeSlider
      //     label="Money spent is between"
      //     labelHidden
      //     value={moneySpent || [0, 500]}
      //     prefix="$"
      //     output
      //     min={0}
      //     max={2000}
      //     step={1}
      //     onChange={handleMoneySpentChange}
      //   />
      // ),
    },
  ];

  const appliedFilters = [];
  if (!isEmpty(moneySpent)) {
    const key = 'moneySpent';
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, moneySpent),
      onRemove: handleMoneySpentRemove,
    });
  }
  if (!isEmpty(taggedWith)) {
    const key = 'taggedWith';
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, taggedWith),
      onRemove: handleTaggedWithRemove,
    });
  }

  function disambiguateLabel(key, value) {
    switch (key) {
      case 'moneySpent':
        return `Money spent is between $${value[0]} and $${value[1]}`;
      case 'taggedWith':
        return `Tagged with ${value}`;
      default:
        return value;
    }
  }

  function isEmpty(value) {
    if (Array.isArray(value)) {
      return value.length === 0;
    } else {
      return value === '' || value == null;
    }
  }

  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [totalPages, setTotalPages] = useState(1);
  const [hasPrevious, setHasPrevious] = useState(false);
  const [status, setStatus] = useState('active');
  const [searchQuery, setSearchQuery] = useState();
  const [sortFilter, setSortFilter] = useState('newest');

  // -------------------
  const GET_CUSTOMERS = gql`
    query ($page: String, $searchquery: String, $sortBy: String, $tab: String) {
      fetchCustomerModel(
        page: $page
        searchquery: $searchquery
        sortBy: $sortBy
        tab: $tab
      ) {
        customerSubscriptions {
          indexId
          shopifyId
          name
          email
          uid
          contracts
        }
        totalPages
        totalCount
        pageNumber
      }
    }
  `;
  const { data, loading, error, refetch } = useQuery(GET_CUSTOMERS, {
    fetchPolicy: 'no-cache',
    variables: {
      page: page.toString(),
      searchquery: searchQuery,
      sortBy: sortFilter,
      tab: headerButton,
    },
  });

  useEffect(() => {
    if (token) {
      setuid(token);
    }
  }, [token]);

  useEffect(() => {
    refetch({
      variables: {
        page: page.toString(),
        searchquery: searchQuery,
        sortBy: sortFilter,
      },
    });
  }, [page, searchQuery]);

  const handleFiltersQueryChange = (value) => {
    console.log('fsdafdsafdfwe', value, value.length);
    setQueryValue(value);
    if (value.length > 1) {
      setSearchQuery(value);
    } else {
      setSearchQuery();
    }
  };

  useEffect(() => {
    setTotalPages(data?.fetchCustomerModel?.totalPages);
    if (+page < +totalPages) {
      setHasMore(true);
    } else {
      setHasMore(false);
    }
    if (+page <= 1) {
      setHasPrevious(false);
    } else {
      setHasPrevious(true);
    }
  }, [data]);

  const filterCustomersValue = () => {
    const rowsData = customers.filter((item) => {
      return (
        (item.subscription === headerButton ||
          headerButton === 'paused' ||
          headerButton === 'active' ||
          headerButton === 'returning' ||
          headerButton === 'cancelled' ||
          headerButton === 'new' ||
          headerButton === 'all') &&
        (item.name?.toLowerCase()?.includes(queryValue?.toLowerCase()) ||
          !queryValue) &&
        (item.subscription?.toLowerCase()?.includes(taggedWith) || !taggedWith)
      );
    });

    setFilterCustomers(rowsData);
  };
  useEffect(() => {
    if (customers) {
      filterCustomersValue();
    }
    // console.log('searchvalue: ', queryValue);
  }, [queryValue, taggedWith, customers]);

  const [formErrors, setFormErrors] = useState([]);
  const [saveSuccess, setSaveSuccess] = useState(false);
  const hideSaveSuccess = useCallback(() => setSaveSuccess(false), []);

  const [selectedCustomers, setselectedCustomers] = useState([]);
  const handleChangeCheckedCustomers = (newChecked, customerId) => {
    if (newChecked) {
      setselectedCustomers([...selectedCustomers, customerId]);
    } else {
      const index = selectedCustomers.indexOf(customerId);
      setselectedCustomers([
        ...selectedCustomers.slice(0, index),
        ...selectedCustomers.slice(index + 1),
      ]);
    }
  };
  //upper first letter
  const capitalize = (s) => {
    if (typeof s !== 'string') return '';
    return s.charAt(0).toUpperCase() + s.slice(1);
  };
  const showDetail = (token) => {
    history.push(`/customer-model/${token}`);
  };
  //each row in data table
  const formatRows = (rows) => {
    let apiData;
    return rows?.map((row) => {
      try {
        apiData =
          row?.apiData != null
            ? JSON.parse(
              row?.apiData?.replaceAll('=>', ':')?.replaceAll('nil', '"nil"')
            )
            : '';
      } catch (e) {
        apiData = '';
      }
      return row?.subscription !== null
        ? [
          <Checkbox
            label={
              <div
                class=""
                onClick={() => {
                  showDetail(row?.uid);
                }}
              >
                {row?.indexId}
              </div>
            }
            checked={selectedCustomers.indexOf(row?.shopifyId) != -1}
            onChange={(newChecked) =>
              handleChangeCheckedCustomers(newChecked, row?.shopifyId)
            }
          />,
          <div
            class=""
            onClick={() => {
              showDetail(row?.uid);
            }}
          >
            {row?.name}
          </div>,
          <div
            class=""
            onClick={() => {
              showDetail(row?.uid);
            }}
          >
            {row?.email}
          </div>,
          <ul>
            {row?.contracts &&
              JSON.parse(row?.contracts)?.map((contract) => {
                contract = JSON.parse(contract);
                return (
                  <li className="contracts_list">
                    {contract?.subscription} :
                    <span
                      className={
                        contract?.status === 'PAUSED'
                          ? 'cancelled'
                          : row?.status === 'ACTIVE'
                            ? 'active'
                            : 'future'
                      }
                    >
                      <Badge>{capitalize(contract?.status)}</Badge>
                    </span>
                  </li>
                );
              })}
          </ul>,
        ]
        : [];
    });
  };
  const [customers, setCustomers] = useState([]);
  const [filteredCustomer, setFilteredCustomer] = useState([]);
  const [filterCustomers, setFilterCustomers] = useState([]);

  // useEffect(() => {
  //   filterCustomersValue();
  // }, [selectedCustomers]);

  useEffect(() => {
    if (data && data.fetchCustomerModel) {
      let rowsData = formatRows(data.fetchCustomerModel?.customerSubscriptions);
      setCustomers(data.fetchCustomerModel?.customerSubscriptions);
      // console.log('data: ', data);
    }
  }, [data]);

  const fields = [
    'id',
    'firstName',
    'lastName',
    'email',
    'communication',
    'phone',
    'language',
    'autoCollection',
    'status',
    'subscription',

    'additionalContacts.id',
    'additionalContacts.email',
    'additionalContacts.firstName',
    'additionalContacts.lastName',
    'additionalContacts.companyName',
    'additionalContacts.phone',

    'billingAddress.firstName',
    'billingAddress.lastName',
    'billingAddress.email',
    'billingAddress.company',
    'billingAddress.phone',
    'billingAddress.address1',
    'billingAddress.address2',
    'billingAddress.city',
    'billingAddress.language',
    'billingAddress.zipCode',
  ];
  const transforms = [
    unwind({ paths: ['additionalContacts', 'billingAddress'] }),
  ];
  const json2csvParser = new Parser({ fields, transforms });
  let csv = json2csvParser.parse(dataSelected);

  //
  // const exportCSV = () => {
  const dataSelected = [...filterCustomers].filter((item) =>
    selectedCustomers.find((select) => select === item.id)
  );
  // };

  //import customer by csv:
  const [active, setActive] = useState(false);
  const [checked, setChecked] = useState(false);

  const toggleActive = useCallback(() => setActive((active) => !active), []);

  const handleCheckbox = useCallback((value) => setChecked(value), []);

  //upload file
  const [file, setFile] = useState();

  const handleDropZoneDrop = useCallback(
    (_dropFiles, acceptedFiles, _rejectedFiles) =>
      setFile((file) => acceptedFiles[0]),
    []
  );

  // const validImageTypes = ['image/gif', 'image/jpeg', 'image/png'];

  const fileUpload = !file && <DropZone.FileUpload />;
  const uploadedFile = file && (
    <Stack>
      <Thumbnail size="small" alt={file.name} source={NoteMinor} />
      <div>
        {file.name} <Caption>{file.size} bytes</Caption>
      </div>
    </Stack>
  );
  //config

  const csvData = [];

  const parsedata =
    file &&
    Papa.parse(file, {
      header: false,
      step: function (result) {
        csvData.push(result.data);
      },
      complete: function (results, file) {
        // console.log('csvData: ', csvData);
      },
    });
  const handleImportCustomer = () => {
    const rcs = [];
    const tempData = csvData.slice(1);
    tempData?.map((item) => {
      const index = rcs.findIndex((customer) => customer.email == item[3]);

      if (index !== -1) {
        rcs[index].additionalContacts.push({
          // id: item[10],
          email: item[11],
          firstName: item[12],
          lastName: item[13],
          companyName: item[14],
          phone: item[15],
        });
      } else {
        rcs.push({
          // id: item[0],
          firstName: item[1],
          lastName: item[2],
          email: item[3],
          communication: item[4],
          phone: item[5],
          language: item[6],
          autoCollection: Boolean(item[7]),
          status: item[8],
          subscription: item[9],
          additionalContacts:
            item[11] === ''
              ? []
              : [
                {
                  email: item[11],
                  firstName: item[12],
                  lastName: item[13],
                  companyName: item[14],
                  phone: item[15],
                },
              ],
          billingAddress: {
            firstName: item[16],
            lastName: item[17],
            email: item[18],
            company: item[19],
            phone: item[20],
            address1: item[21],
            address2: item[22],
            city: item[23],
            language: item[24],
            zipCode: item[25],
          },
        });
      }
    });

    createCustomer({
      variables: {
        input: { params: rcs },
      },
    }).then((resp) => {
      // const errors = resp.errors;
      const data = resp.data;
      const errors = data.errors;

      if (errors) {
        setFormErrors(errors);
      } else {
        setSaveSuccess(true);
        refetch({
          variables: {
            page: page.toString(),
            searchquery: searchQuery,
            sortBy: sortFilter,
          },
        });
      }
    });
    setFile();
    // refetch();
  };
  const CREATE_CUSTOMER = gql`
    mutation ($input: AddCustomersInput!) {
      addCustomers(input: $input) {
        result
      }
    }
  `;
  const [createCustomer] = useMutation(CREATE_CUSTOMER);

  useEffect(() => {
    setPage(1);
    setStatus(headerButton);
  }, [headerButton]);
  const deepCopy = (obj) => JSON.parse(JSON.stringify(obj));
  useEffect(() => {
    if (status == 'subscriptions') {
      filterCustomer();
    } else {
      setFilteredCustomer([]);
    }
  }, [status, customers]);

  const filterCustomer = () => {
    // let allcustomers = deepCopy(customers)
    // let filteredAllCustomers = allcustomers.map((customer) => {
    //   let contracts = []
    //   JSON.parse(customer?.contracts)?.map((contract) => {
    //     try {
    //       contract = JSON.parse(contract)
    //     }
    //     catch (e) {
    //       console.log("error", e)
    //     }
    //     if (contract?.status == "ACTIVE") { contracts.push(JSON.stringify(contract)) }
    //   })

    //   console.log("contracts", contracts);
    //   customer.contracts = JSON.stringify(contracts);
    //   return customer
    // })
    let allcustomers = deepCopy(customers);
    let filteredAllCustomers = allcustomers.filter((customer) => {
      return JSON.parse(customer?.contracts)?.length > 0;
    });
    setFilteredCustomer([...filteredAllCustomers]);
  };

  const RenderCustomHeader = () => {
    return (
      <div className="chargezen_custom_page_header">
        <div className="page_title_wrapper">
          <div className="back_button_wrapper">
            {/* {summaryView && (
              <div
                className="back-button pointer"
                onClick={() => {
                  let toRedirect = edit_id
                    ? `/customersTab/${selected_tab}`
                    : `/customers`;
                  history.push(toRedirect);
                }}
              >
                <Icon source={MobileBackArrowMajor} color="base" />
              </div>
            )} */}
            <div class="text_wrapper page_heading_title_wrapper">
              <div
                class="Polaris-Header-Title__TitleAndSubtitleWrapper"
                style={{
                  width: '100%',
                }}
              >
                <div class="Polaris-Header-Title">
                  <h1 class="Polaris-DisplayText Polaris-DisplayText--sizeLarge">
                    <span class="Polaris-TextStyle--variationStrong">
                      <div class="back_button_wrapper">
                        {' '}
                        <div
                          class="text_wrapper page_heading_title_wrapper"
                          style={{
                            width: '100%',
                          }}
                        >
                          <span>{i18n.t('subscription_customers')}</span>
                          <div className="create_selling_plan_subscription_wrapper">
                            <Button onClick={() => { }}>
                              {dataSelected.length > 0 ? (
                                <CSVLink
                                  data={json2csvParser.parse(dataSelected)}
                                  filename={'customers.csv'}
                                >
                                  Export
                                </CSVLink>
                              ) : (
                                'Export'
                              )}
                            </Button>
                            {shopifyDomain == 'bagamour.myshopify.com' && (
                              <Button
                                onClick={() => {
                                  fetch(
                                    `/subscriptions/sync_stripe?shopify_domain=${domain}`,
                                    {
                                      headers: {
                                        'Content-Type': 'application/json',
                                        Accept: 'application/json',
                                      },
                                      credentials: 'same-origin',
                                    }
                                  )
                                    .then((response) => response.json())
                                    .then(() => {
                                      try {
                                        var Toast =
                                          window['app-bridge'].actions.Toast;
                                        Toast.create(window.app, {
                                          message:
                                            'Syncing Stripe Subscriptions',
                                          duration: 5000,
                                        }).dispatch(Toast.Action.SHOW);
                                      } catch (e) {
                                        console.error(
                                          'Error syncing data: ',
                                          e
                                        );
                                      }
                                    })
                                    .catch((error) => {
                                      console.error(
                                        'Error syncing data: ',
                                        error
                                      );
                                    });
                                }}
                              >
                                Sync Stripe Subscriptions
                              </Button>
                            )}
                          </div>
                        </div>
                      </div>
                    </span>
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="sidebar_summary header_description">
          Manage customers of your store
        </div>
      </div>
    );
  };

  return uid ? (
    <CustomerDetail domain={domain} uid={uid} setuid={setuid} />
  ) : (
    <>
      <Frame>
        {saveSuccess && (
          <Toast
            content="Customer data has been saved successfully"
            onDismiss={hideSaveSuccess}
          />
        )}

        {formErrors.length > 0 && (
          <>
            <Banner title="Customer data has not been saved" status="critical">
              <List type="bullet">
                {formErrors.map((message, index) => (
                  <List.Item key={index}>{message.message}</List.Item>
                ))}
              </List>
            </Banner>
            <br />
          </>
        )}
        {loadingScreen && (
          <>
            <LoadingScreen />
          </>
        )}
        <Page>
          <RenderCustomHeader />
          <div className="without_header_card">
            <Card
              // title={
              //   <div className="heading_title">
              //     <PixelIcon />
              //     All Customers
              //   </div>
              // }
              actions={{
                content: (
                  <div className="card_header_button_group">
                    <div className="card_header_buttons primary_button_group">
                      <HeaderButtons
                        headerButtons={headerButtons}
                        setHeaderButton={setHeaderButton}
                        headerButton={headerButton}
                      />
                    </div>
                  </div>
                ),
              }}
            >
              <Card.Section>
                <div
                  className="filter custom_filter_main"
                  style={{
                    display: 'flex',
                    width: '100%',
                  }}
                >
                  <Filters
                    queryValue={queryValue}
                    filters={filters}
                    appliedFilters={appliedFilters}
                    onQueryChange={handleFiltersQueryChange}
                    onQueryClear={handleQueryValueRemove}
                    onClearAll={handleFiltersClearAll}
                  />
                  <span className="separate"></span>
                  <div className="btn-sort">
                    <Button
                      onClick={() => {
                        setSortFilter(
                          sortFilter == 'newest' ? 'oldest' : 'newest'
                        );
                      }}
                    >
                      <img src={swapIcon} />
                      {sortFilter == 'newest' ? (
                        <>Sort By oldest (Oldest First)</>
                      ) : (
                        <>Sort By last update (Newest First)</>
                      )}
                    </Button>
                  </div>
                </div>
                <div className={'table customer-subscription-tbl' + ' '}>
                  <DataTable
                    columnContentTypes={['text', 'text', 'text', 'text']}
                    headings={['ID', 'Name', 'Email', 'Contracts']}
                    rows={
                      filteredCustomer.length > 0
                        ? formatRows(filteredCustomer)
                        : formatRows(customers)
                    }
                  />
                </div>
                {loading && <Spinner />}
                <div
                  style={{
                    width: '100%',
                    justifyContent: 'center',
                    display: 'flex',
                  }}
                >
                  <Pagination
                    hasPrevious={hasPrevious}
                    onPrevious={() => {
                      setPage((prev) => +prev - 1);
                    }}
                    hasNext={hasMore}
                    onNext={() => {
                      setPage((prev) => +prev + 1);
                    }}
                  />
                </div>
              </Card.Section>
            </Card>
          </div>
        </Page>
      </Frame>
      <Modal
        large
        open={active}
        onClose={toggleActive}
        title="Import customers by CSV"
        primaryAction={{
          content: 'Import customers',
          onAction: () => {
            toggleActive();
            handleImportCustomer();
            // parsedata;
          },
        }}
        secondaryActions={[
          {
            content: 'Cancel',
            onAction: () => {
              toggleActive();
              setFile();
            },
          },
        ]}
      >
        <Modal.Section>
          <Stack vertical>
            <DropZone
              allowMultiple={false}
              onDrop={handleDropZoneDrop}
              accept=".csv"
              errorOverlayText="File type must be .csv"
              type="file"
            >
              {uploadedFile}
              {fileUpload}
            </DropZone>
            {/* <Checkbox
              checked={checked}
              label="Overwrite existing customers that have the same customer ID"
              onChange={handleCheckbox}
            /> */}
          </Stack>
        </Modal.Section>
      </Modal>
    </>
  );
};

export default CustomerModal;
