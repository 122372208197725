import React, { useCallback, useEffect, useRef, useState } from 'react'
import "../customerModal.css";
import { Badge, TextField } from '@shopify/polaris';
import {
    ChevronDownMinor,
    MobilePlusMajor,
} from "@shopify/polaris-icons";
const AdjustPointsBalance = (props) => {
    const {
        setPointBalance,
        pointBalance,
        setPointsFormData,
        pointsFormData,
        customer,
        domain,
        fetchCustomer,
        setToastContent,
        setSaveSuccess,
    } = props;


    let currentLoyaltyPoints = +customer?.loyalty_points || 0;
    // const pointsInputRef = useRef();
    const handleChange = (value, key) => {
        setPointsFormData({ ...pointsFormData, [key]: value })
    }

    const focusInput = () => {
        pointsInputRef.current.focus()
    }

    const createCustomerPointsActions = () => {
        setToastContent("Adjusting points balance …")
        if (pointsFormData?.points) {
            let formData = pointsFormData;
            formData.shopify_domain = domain;
            formData.uid = customer.uid
            console.log("formdata", formData)
            fetch(`/customer/adjust_points_balance?shopify_domain=${domain}`, {
                method: "POST",
                body: JSON.stringify(formData),
                headers: {
                    'Content-Type': 'application/json'
                },

            }).then((response) => response.json())
                .then((data) => {
                    setPointBalance(false)
                    if (data?.success) {
                        setPointsFormData({
                            points: 0,
                            reason: "",
                            note: ""
                        })
                        setToastContent("Points balance updated successfully")
                        setTimeout(() => {
                            fetchCustomer()
                        }, [3000])
                    } else {
                        setToastContent("Something went wrong.")
                    }
                    setSaveSuccess(true)
                })
        }
    }
    return (
        <div className="create_billing modal-popup-cstm">
            <div className="modal-dialouge">
                <div className="Modal-header">
                    <h2>Adjust customer points balance</h2>
                    <button
                        class="close-btn"
                        onClick={() => { setPointBalance(false) }}
                    >
                        <svg
                            aria-hidden="true"
                            class="Polaris-Icon__Svg"
                            focusable="false"
                            viewBox="0 0 20 20"
                        >
                            <path d="m11.414 10 6.293-6.293a1 1 0 1 0-1.414-1.414l-6.293 6.293-6.293-6.293a1 1 0 0 0-1.414 1.414l6.293 6.293-6.293 6.293a1 1 0 1 0 1.414 1.414l6.293-6.293 6.293 6.293a.998.998 0 0 0 1.707-.707.999.999 0 0 0-.293-.707l-6.293-6.293z"></path>
                        </svg>
                    </button>
                </div>
                <div className="modal-content">
                    <h4>{`${customer?.first_name} ${customer.last_name}`} <p>{currentLoyaltyPoints} points</p></h4>
                    <div className='points_adjustment_form'>
                        <TextField
                            type="number"
                            label="Points adjustment"
                            value={pointsFormData?.points}
                            onChange={(e) => handleChange(e, "points")}
                            autoComplete="off"
                            helpText="Use negative points to subtract from the customer's point balance."
                        //   ref={pointsInputRef}
                        />
                        <TextField
                            type="text"
                            label="Reason for adjustment"
                            value={pointsFormData?.reason}
                            onChange={(e) => handleChange(e, "reason")}

                            autoComplete="off"
                            helpText="This message is for internal use."
                        //    ref={pointsInputRef}
                        />
                        <TextField
                            type="text"
                            label="Leave a customer facing note."
                            value={pointsFormData?.note}
                            onChange={(e) => handleChange(e, "note")}
                            autoComplete="off"
                            helpText="This message is visible to your customer."
                        //  ref={pointsInputRef}
                        />

                        <div className='new_balance_wrapper'>
                            NEW BALANCE
                            <span>{currentLoyaltyPoints + +pointsFormData?.points} points</span>
                        </div>
                    </div>
                </div>
                <div className="modal-footer">
                    <button className="btn-green" onClick={() => createCustomerPointsActions()}>Adjust Points</button>
                    <button className="btn-red" onClick={() => { setPointBalance(false) }}>
                        Cancel
                    </button>
                </div>
            </div>
            <div className="modal-cstm-backdrop"></div>
        </div>
    );
}

export default AdjustPointsBalance