import React from 'react';

const MultipleProductHtml = ({
  mobileView,
  contentBlock,
  formData,
  allVariants,
}) => {
  let totalPrice = 0;
  allVariants.map((v) => {
    totalPrice += +v.price;
  });
  if (allVariants?.length <= 0) {
    allVariants = [
      {
        image:
          'https://cdn.shopify.com/s/files/1/0554/6541/0659/products/Main_9129b69a-0c7b-4f66-b6cf-c4222f18028a.jpg?v=1700118145',
        price: '59.00',
        title: 'The 3p Fulfilled Snowboard',
        productId: 'gid://shopify/Product/123',
        variantId: 'gid://shopify/ProductVariant/1234',
        descriptionHtml:
          'A confidence-inspiring platform for learning, this soft-flexing board boasts a forgiving rocker profile and wide edges for effortless turns and stability.',
      },
      {
        image:
          'https://cdn.shopify.com/s/files/1/0724/7046/2766/products/Main_d624f226-0a89-4fe1-b333-0d1548b43c06.jpg?v=1676462511',
        price: '35.00',
        title: 'The Collection Snowboard: Hydrogen',
        productId: 'gid://shopify/Product/123',
        variantId: 'gid://shopify/ProductVariant/1234',
        descriptionHtml:
          "Your gateway to carving and gliding, this board's mellow design makes mastering the basics smooth and fun, with playful graphics to match.",
      },
      {
        image:
          'https://cdn.shopify.com/s/files/1/0724/7046/2766/products/Main_0a4e9096-021a-4c1e-8750-24b233166a12.jpg?v=1676462511',
        price: '69.00',
        title: 'Masculine Energy ',
        productId: 'gid://shopify/Product/8119911940417',
        variantId: 'gid://shopify/ProductVariant/44485309956417',
        descriptionHtml:
          'Stability meets fun. This beginner-friendly ride features a comfortable, progressive flex for easy turns and control, setting you up for endless mountain adventures',
      },
    ];
  }
  console.log('allVariants?.length', allVariants?.length);
  const htmlTemplate = `
 <div class='chargezen_upsell_setting_container'>
 <div class='template_preview_container'>
 <div class='chargezen_upsell_container'>
     <div class='sub_header_wrapper'>
     <div class='header_content'>
         <div class='order_number_block'>
         <div class='icon'></div>
         <div class='text'>
             Order <span class='order_number'>#1033</span>
             <div class='paid_order_text'>You’ve paid for your order.</div>
             <div class='view_order_confirm'>
             <a href=''>View order confirmation ›</a>
             </div>
         </div>
         </div>
     </div>
     </div>
     <div class='upsell_sub_body_wrapper'>
     <div class='top_banner_section'>
         <div class='banner_heading_wrapper'>
         <div class='banner_header_text sm hidden'>
             ${
               formData?.offer_time == '5min' ? '04:59' : '09:59'
             } Limited Time Offer
         </div>
         <div class='banner_header_text'>
            ${formData?.design_headline_text || ''}
         </div>
         </div>
         <div class='banner_button_wrapper'>
         <button class='upsell_primaryButton'>
             Pay now - <span>${totalPrice}</span>
         </button>
         </div>
     </div>

     <div class='products_cardWrapper'>
      ${
        allVariants.length > 0
          ? allVariants
              ?.map((variant) => {
                return `
                            <div class='productCard'>
                              <div class='image_wrapper'>
                                  <img
                                  src='${variant.image}'
                                  alt=''
                                  />
                              </div>
                              <div class='card_body_content'>
                                  <h4>${variant.title}</h4>
                                  <div class='price_wrapper'>$${variant.price}</div>
                                  <div class='product_desc'>
                                    ${variant.descriptionHtml}
                                  </div>
                                  <div class='card_button_wrapper'>
                                  <button class='upsell_primaryButton'>
                                      Item added
                                  </button>
                                  </div>
                              </div>
                            </div>
                          `;
              })
              .join('')
          : `
          <div class='productCard'>
            <div class='image_wrapper'>
                <img
                src='https://y4y4uny6m7x6zja4-72470462766.shopifypreview.com/cdn/shop/files/snowboard.webp?v=1694073847&width=823'
                alt=''
                />
            </div>
            <div class='card_body_content'>
                <h4>The Minimal Snowboard</h4>
                <div class='price_wrapper'>$85.95 USD</div>
                <div class='product_desc'>
                  Experience the power of the Minimal Snowboard. This board has features designed to provide maximum control and maneuverability on the slopes. Its lightweight design and responsive construction make it an ideal choice for experienced boarders.
                </div>
                <div class='card_button_wrapper'>
                <button class='upsell_primaryButton'>
                    Item added
                </button>
                </div>
            </div>
          </div>
        `
      }
        
     </div>
     <div class='upsell_cartWrapper'>
         <div class='upsell_cart_right_wrapper'>
         ${
           allVariants?.length > 0
             ? allVariants
                 ?.map((variant) => {
                   return `
                    <div class='upsell_row'>
                      <div class='img_wrapper'>
                      <img
                          src='${variant.image}'
                          alt=''
                      />
                      </div>
                      <div class='row_content_wrapper'>${variant.title}</div>
                      <div class='row_content_wrapper cart_quantity_manager'>
                      <label for=''>Quantity</label>
                      <select class='cartQuantityManage'>
                          <option>1</option>
                          <option>2</option>
                          <option>3</option>
                      </select>
                      </div>
                      <div class='row_content_wrapper'>
                      <a class='remove_button'>Remove</a>
                      </div>
                      <div class='row_content_wrapper'>$${variant.price}</div>
                  </div>
                    `;
                 })
                 .join('')
             : `
          <div class='upsell_row'>
              <div class='img_wrapper'>
              <img
                src='https://y4y4uny6m7x6zja4-72470462766.shopifypreview.com/cdn/shop/files/snowboard.webp?v=1694073847&width=823'
                alt=''
              />
              </div>
              <div class='row_content_wrapper'>The Minimal Snowboard</div>
              <div class='row_content_wrapper cart_quantity_manager'>
              <label for=''>Quantity</label>
              <select class='cartQuantityManage'>
                  <option>1</option>
                  <option>2</option>
                  <option>3</option>
              </select>
              </div>
              <div class='row_content_wrapper'>
              <a class='remove_button'>Remove</a>
              </div>
              <div class='row_content_wrapper'>$85.95 USD</div>
          </div>
        `
         }
       

         <div class='upsell_cart_totalWrapper'>
             <div class='upsell_row'>
             <div class='upsell_text'>Subtotal</div>
             <div class='upsell_text'>$63.00</div>
             </div>
             <div class='upsell_row'>
             <div class='upsell_text'>Shipping</div>
             <div class='upsell_text'>Free</div>
             </div>
             <div class='upsell_row'>
             <div class='upsell_text'>Taxes</div>
             <div class='upsell_text'>$4.57</div>
             </div>
         </div>
         <div class='upsell_row'>
             <div class='upsell_text h6'>Total</div>
             <div class='upsell_text h6'>$${totalPrice || '$85.95 USD'}</div>
         </div>
         <div class='upsell_row'>
             <button class='upsell_secondaryButton'>
             Decline this offer
             </button>
             <button class='upsell_primaryButton'>Pay now - $${
               totalPrice || '$85.95 USD'
             }</button>
         </div>
         </div>
     </div>
     <div class='bottom-product-section'>
      ${
        contentBlock.length > 0
          ? contentBlock
              ?.map((content, i) => {
                return `
                  <div class='prodcut-hori-image-section ${
                    i % 2 === 0 ? 'even' : 'odd'
                  }'>
                    <div class='prouct-image-inner'>
                        <img
                        src='${
                          content.image_url ||
                          'https://cdn.shopify.com/s/files/1/0724/7046/2766/files/snowboard.webp?v=1694073847'
                        }'
                        />
                    </div>
                    <div class='product-info'>
                        <h2>${content.headline}</h2>
                        <p>
                        ${content.description}
                        </p>
                    </div>
                  </div>
                  `;
              })
              .join('')
          : `
        <div class='prodcut-hori-image-section even'>
          <div class='prouct-image-inner'>
              <img
              src='https://y4y4uny6m7x6zja4-72470462766.shopifypreview.com/cdn/shop/files/snowboard.webp?v=1694073847&width=823'
              />
          </div>
          <div class='product-info'>
              <h2>Default Content</h2>
              <p>
                There is no content yet. Please Enter your Content
              </p>
          </div>
        </div>
        `
      }
        
         
     </div>
     </div>
 </div>
 </div>
</div>
<style>
body {
  padding: 0;
  margin: 0;
}

* {
  box-sizing: border-box;
}

.chargezen_upsell_setting_container {
  overflow: hidden;
}

.chargezen_upsell_setting_container .main_header_content {
  display: flex;
  justify-content: space-between;
  padding: 17px 20px;
  background: #fff;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.chargezen_upsell_setting_container button.back_button_pointer {
  font-weight: 400;
  position: relative;
  background: transparent;
  border: 0;
  padding: 0;
}

.chargezen_upsell_setting_container .template_preview_container {
  margin: 0px auto;
  width: 100%;
  max-height: calc(100vh - 181px);
  overflow-y: auto;
  max-width: 100%;
  box-shadow: none;
}

.chargezen_upsell_setting_container
  .template_preview_container
  .sub_header_wrapper {
  padding: 23px 17px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.chargezen_upsell_setting_container
  .template_preview_container
  .sub_header_wrapper
  .order_number_block {
  display: flex;
  width: 100%;
  max-width: 992px;
  margin: 0px auto;
}

.chargezen_upsell_setting_container
  .template_preview_container
  .sub_header_wrapper
  .order_number_block
  .text {
  padding-left: 10px;
  font-size: 14px;
  color: #000;
}

.chargezen_upsell_setting_container
  .template_preview_container
  .sub_header_wrapper
  .order_number_block
  .paid_order_text {
  font-size: 22px;
  margin: 0 0 5px;
  color: #000;
}

.chargezen_upsell_setting_container
  .template_preview_container
  .sub_header_wrapper
  .order_number_block
  .text
  a {
  color: rgb(25, 123, 189);
  font-size: 14px;
  text-decoration: none;
}

.chargezen_upsell_setting_container
  .upsell_sub_body_wrapper
  .top_banner_section {
  display: flex;
  flex-direction: row;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  justify-content: space-between;
  max-width: 960px;
  margin: 0px auto;
  padding: 23px 17px 15px;
}

.chargezen_upsell_setting_container
  .upsell_sub_body_wrapper
  .top_banner_section
  button.upsell_primaryButton {
  width: 100%;
  text-align: center;
  border-radius: 0.4rem;
  padding: 20px 20px;
  font-size: 14px;
  font-weight: 700;
  color: rgb(255, 255, 255);
  background-color: rgb(25, 123, 189);
  outline: none;
  border: none;
  margin: 0px;
  opacity: 1;
}

.chargezen_upsell_setting_container
  .upsell_sub_body_wrapper
  .top_banner_section
  .banner_button_wrapper {
  width: 100%;
  max-width: 280px;
}

.chargezen_upsell_setting_container
  .upsell_sub_body_wrapper
  .top_banner_section
  .banner_header_text.sm {
  margin: 0px 0px 8px;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.3;
  color: #000;
}

.chargezen_upsell_setting_container
  .upsell_sub_body_wrapper
  .top_banner_section
  .banner_heading_wrapper
  .banner_header_text:last-child {
  margin: 0px;
  font-size: 28px;
  font-weight: 400;
  line-height: 1.3;
  color: #000;
  text-align: center;
}

.chargezen_upsell_setting_container .products_cardWrapper {
  display: grid;
  max-width: 960px;
  margin: 0px auto;
  padding: 15px 17px;
  grid-template-columns: repeat(3, 1fr);
  gap: 30px;
}

.chargezen_upsell_setting_container .products_cardWrapper .productCard img {
  width: 100%;
}

.chargezen_upsell_setting_container
  .products_cardWrapper
  .productCard
  .card_body_content {
  margin-top: 8px;
}

.chargezen_upsell_setting_container
  .products_cardWrapper
  .productCard
  .card_body_content
  h4 {
  margin: 0;
  font-weight: 400;
  font-size: 18px;
  line-height: 1.3;
  margin-bottom: 13px;
  color: #000;
}

.chargezen_upsell_setting_container
  .products_cardWrapper
  .productCard
  .card_body_content
  .price_wrapper {
  margin-bottom: 12px;
  font-weight: 500;
  font-size: 16px;
  line-height: 1.2;
  color: rgb(88, 88, 88);
}

.chargezen_upsell_setting_container
  .products_cardWrapper
  .productCard
  .card_body_content
  .product_desc {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  font-weight: 400;
  font-size: 14px;
  line-height: 1.3;
  color: rgb(119, 119, 119);
  margin-bottom: 20px;
}

.chargezen_upsell_setting_container button.upsell_primaryButton {
  width: 100%;
  text-align: center;
  border-radius: 0.4rem;
  padding: 17px 20px;
  font-size: 14px;
  font-weight: 700;
  color: rgb(255, 255, 255);
  background-color: rgb(25, 123, 189);
  outline: none;
  border: none;
  margin: 20px 0px 0px;
  opacity: 1;
}

.upsell_cart_right_wrapper {
  max-width: 960px;
  margin: 0px auto;
  padding: 23px 17px 15px;
  border-top: 1px solid rgb(230, 230, 230);
  margin-top: 15px;
}

.upsell_cart_right_wrapper > .upsell_row {
  max-width: 602px;
  margin: 0px 0px 0px auto;
  display: flex;
  align-items: center;
}

.upsell_cart_right_wrapper > .upsell_row .img_wrapper {
  width: 100%;
  max-width: 65px;
}

.upsell_cart_right_wrapper > .upsell_row .img_wrapper img {
  width: 100%;
}

.upsell_cart_right_wrapper > .upsell_row .row_content_wrapper:nth-child(2) {
  padding: 0px 10px;
  width: 100%;
  max-width: 43%;
  font-size: 14px;
  font-weight: 400;
  color: #000;
}

.upsell_cart_right_wrapper
  > .upsell_row
  .row_content_wrapper.cart_quantity_manager {
  width: 100%;
  max-width: 20%;
  padding: 12px 12px 0px;
  border: 1px solid rgb(217, 217, 217);
  border-radius: 0.4rem;
  width: 100%;
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 2rem;
  appearance: none;
  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTAiIGhlaWdodD0iNSIgdmlld0JveD0iMCAwIDEwIDUiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGZpbGwtcnVsZT0iZXZlbm9kZCIgY2xpcC1ydWxlPSJldmVub2RkIiBkPSJNMCAwTDUgNUwxMCAwSDBaIiBmaWxsPSIjOTE5MTkxIi8+Cjwvc3ZnPgo=);
  background-position: right 0.8rem center;
  background-repeat: no-repeat;
  position: relative;
}

.upsell_cart_right_wrapper > .upsell_row .row_content_wrapper:nth-child(4) {
  width: 100%;
  max-width: 12.4%;
  padding-left: 15px;
}

.upsell_cart_right_wrapper > .upsell_row .row_content_wrapper:last-child {
  width: 100%;
  max-width: 14%;
  text-align: right;
  padding-left: 5px;
  font-size: 14px;
  color: #000;
}

.upsell_cart_right_wrapper
  > .upsell_row
  .row_content_wrapper:nth-child(4)
  a.remove_button {
  font-size: 14px;
  color: #000;
}

.upsell_cart_right_wrapper
  > .upsell_row
  .row_content_wrapper.cart_quantity_manager
  label {
  font-size: 10px;
  color: #000;
  position: absolute;
  top: 8px;
  line-height: normal;
  left: 7px;
}

.upsell_cart_right_wrapper
  > .upsell_row
  .row_content_wrapper.cart_quantity_manager
  select {
  border: 0;
  background: transparent;
  width: 100%;
  appearance: none;
  color: #000;
  font-size: 10px;
}

.upsell_cart_right_wrapper
  > .upsell_row
  .row_content_wrapper.cart_quantity_manager:after {
  content: '';
  display: block;
  width: 1px;
  height: 20px;
  position: absolute;
  top: calc(50% - 10px);
  right: 28px;
  background-color: rgb(217, 217, 217);
}

.upsell_cart_right_wrapper .upsell_cart_totalWrapper {
  max-width: 602px;
  margin: 25px 0px 20px auto;
  width: 100%;
  border-top: 1px solid rgb(230, 230, 230);
  border-bottom: 1px solid rgb(230, 230, 230);
  padding: 18px 0px 12px;
}

.upsell_cart_right_wrapper .upsell_cart_totalWrapper .upsell_row {
  display: flex;
  justify-content: space-between;
}

.upsell_cart_right_wrapper
  .upsell_cart_totalWrapper
  .upsell_row:not(:last-child) {
  margin: 0 0 7px;
}

.upsell_cart_right_wrapper .upsell_cart_totalWrapper .upsell_row > div {
  font-size: 14px;
  color: #000;
}

.upsell_cart_right_wrapper .upsell_cart_totalWrapper + .upsell_row {
  justify-content: space-between;
  font-size: 14px;
  color: #000;
}

.upsell_cart_right_wrapper > .upsell_row:last-child {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 50px;
  margin-top: 15px;
}

.upsell_cart_right_wrapper
  > .upsell_row:last-child
  button.upsell_secondaryButton {
  padding: 20.5px 20px;
  background: transparent;
  width: 100%;
  text-align: center;
  border-radius: 0.4rem;
  font-size: 14px;
  font-weight: 700;
  color: rgb(25, 123, 189);
  border: 1px solid rgb(217, 217, 217);
  background: transparent;
}

.upsell_cart_right_wrapper
  > .upsell_row:last-child
  button.upsell_primaryButton {
  margin: 0;
  padding: 20.5px 20px;
}

.prodcut-hori-image-section {
  display: flex;
  gap: 50px;
}

.bottom-product-section {
  width: 100%;
  max-width: 960px;
  margin: 0px auto;
  padding: 23px 17px;
}

.bottom-product-section img {
  width: 100%;
}

.prodcut-hori-image-section > div {
  width: 100%;
  max-width: calc(100% / 2 - 25px);
}

.prodcut-hori-image-section.even {
  flex-flow: row-reverse;
}

.prodcut-hori-image-section.even {
  margin: 40px 0;
}

.prodcut-hori-image-section h2 {
  margin: 0 0;
  margin-bottom: 15px;
  font-size: 28px;
  font-weight: 400;
  line-height: 1.3;
  color: #000;
}

.prodcut-hori-image-section p {
  font-weight: 400;
  font-size: 14px;
  line-height: 1.3;
  color: rgb(119, 119, 119);
  margin-bottom: 40px;
  margin: 0;
}
</style>
 `;

  const mobileCss = `
    .chargezen_upsell_setting_container
      .upsell_sub_body_wrapper
      .top_banner_section {
      flex-flow: column;
      gap: 15px;
    }

    .chargezen_upsell_container {
      max-width: 390px;
      margin: 0 auto;
      border: 1px solid #ddd;
    }

    .chargezen_upsell_setting_container
      .upsell_sub_body_wrapper
      .top_banner_section
      .banner_button_wrapper {
      max-width: 100%;
    }

    .chargezen_upsell_setting_container .products_cardWrapper {
      grid-template-columns: repeat(1, 1fr);
    }

    .upsell_cart_right_wrapper > .upsell_row .img_wrapper {
      display: none;
    }

    .upsell_cart_right_wrapper > .upsell_row {
      flex-wrap: wrap;
      grid-row-gap: 20px;
    }

    .upsell_cart_right_wrapper > .upsell_row .row_content_wrapper:nth-child(2) {
      max-width: 65%;
      padding: 0;
    }

    .upsell_cart_right_wrapper
      > .upsell_row
      .row_content_wrapper.cart_quantity_manager {
      max-width: 35%;
    }

    .upsell_cart_right_wrapper > .upsell_row .row_content_wrapper:nth-child(4) {
      max-width: 50%;
      padding: 0;
    }

    .upsell_cart_right_wrapper > .upsell_row .row_content_wrapper:last-child {
      max-width: 50%;
    }

    .upsell_cart_right_wrapper > .upsell_row {
      padding: 10px 0;
    }

    .upsell_cart_right_wrapper > .upsell_row:not(:first-child) {
      border-top: 1px solid rgb(230, 230, 230);
    }

    .upsell_cart_right_wrapper > .upsell_row:last-child {
      grid-template-columns: repeat(1, 1fr);
      gap: 10px;
      border-top: 0;
      margin-top: 0;
    }

    .upsell_cart_right_wrapper
      .upsell_cart_totalWrapper
      + .upsell_row:nth-child(5) {
      margin: 0px 0 12px;
      padding-top: 0;
      border-top: 0;
    }


  .upsell_cart_right_wrapper
    .upsell_cart_totalWrapper
    + .upsell_row:nth-child(5) {
    font-size: 18px;
    margin-bottom: 0;
  }

  .prodcut-hori-image-section {
    flex-flow: column;
    gap: 30px;
  }

  .prodcut-hori-image-section.even {
    flex-flow: column;
  }

  .prodcut-hori-image-section {
    flex-flow: column;
    gap: 30px;
  }

  .bottom-product-section img {
    min-height: 230px;
    max-height: 230px;
  }
  .prodcut-hori-image-section > div {
    max-width: 100%;
  }
  button.changeDeskMobileView {
    background: transparent;
    border: 1px solid #ddd;
    border-radius: 4px;
    cursor: pointer;
    padding: 3px 10px;
  }
  `;
  return (
    <>
      <div
        className="preview_html_container"
        dangerouslySetInnerHTML={{ __html: htmlTemplate }}
      />
      {mobileView && <style dangerouslySetInnerHTML={{ __html: mobileCss }} />}
    </>
  );
};

const SingleProductHtml = ({
  mobileView,
  contentBlock,
  formData,
  allVariants,
  templateType,
}) => {
  let totalPrice = 0;
  allVariants.map((v) => {
    totalPrice += +v.price;
  });
  const htmlTemplate = `
  <div class="chargezen_upsell_setting_container">
  <div class="template_preview_container">
    <div class="chargezen_upsell_container">
      <div class="sub_header_wrapper">
        <div class="header_content">
          <div class="order_number_block">
            <div class="icon"></div>
            <div class="text">
              Order <span class="order_number">#1033</span>
              <div class="paid_order_text">You’ve paid for your order.</div>
              <div class="view_order_confirm">
                <a href="">View order confirmation ›</a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="upsell_sub_body_wrapper">
        <div class="top_banner_section discount-product">
          <div class="banner_heading_wrapper">
            <div class="banner_header_text sm hidden">
              ${
                formData?.offer_time == '5min' ? '04:59' : '09:59'
              } Limited Time Offer
            </div>
            <div class="banner_header_text">
              ${formData?.design_headline_text || ''}
            </div>
          </div>
        </div>

        <div class="products_cardWrapper discounted-product-descritption">
        ${
          allVariants.length > 0
            ? allVariants
                ?.map((variant) => {
                  return `
                              <div class='productCard'>
                                  <div class='image_wrapper'>
                                      <img
                                      src='${variant.image}'
                                      alt=''
                                      />
                                  </div>
                                  <div class="card_body_content">
                                  <h4>${variant.title}</h4>
                                  <div class="price_wrapper">$${
                                    variant.price
                                  }</div>
                                  <div class="product_desc">
                                    ${variant.descriptionHtml}
                                  </div>
                                  <div class="Quantity-wrapper">
                                    <select id="quant" class="product-quant"
                                      ><option disabled="" selected="">1</option
                                      ><option disabled="">2</option
                                      ><option disabled="">3</option
                                      ><option disabled="">4</option
                                      ><option disabled="">5</option></select
                                    >
                                    <label for="quant">Quantity</label>
                                  </div>
                                  <div class="item-total">
                                    <div class="Subtotal">
                                      <h3>Subtotal</h3>
                                      <span>$${
                                        totalPrice || '$85.95 USD'
                                      }</span>
                                    </div>
                                    <div class="Shipping">
                                      <h3>Shipping</h3>
                                      <span>Free</span>
                                    </div>
                                    <div class="taxes">
                                      <h3>Taxes</h3>
                                      <span>$0.00</span>
                                    </div>
                                    <div class="Total">
                                      <h3>Total</h3>
                                      <span>$${
                                        totalPrice || '$85.95 USD'
                                      }</span>
                                    </div>
                                  </div>
                                  <div class="actions">
                                    <button class="btn btn-primary">
                                      Pay now · <span>$${
                                        totalPrice || '$85.95 USD'
                                      }</span>
                                    </button>
                                    <button class="btn btn-secondary">
                                      Decline this offer
                                    </button>
                                  </div>
                                </div>
                              </div>
                              `;
                })
                .join('')
            : `
          <div class="productCard">
            <div class="image_wrapper">
              <img
                src="https://cdn.shopify.com/s/files/1/0724/7046/2766/files/snowboard.webp?v=1694073847"
                alt=""
              />
              <div class="product-thumb">
                <ul>
                  <li>
                    <img
                      src="https://cdn.shopify.com/s/files/1/0724/7046/2766/files/snowboard.webp?v=1694073847"
                      alt=""
                    />
                  </li>
                  <li>
                    <img
                      src="https://cdn.shopify.com/s/files/1/0724/7046/2766/files/snowboard.webp?v=1694073847"
                      alt=""
                    />
                  </li>
                  <li>
                    <img
                      src="https://cdn.shopify.com/s/files/1/0724/7046/2766/files/snowboard.webp?v=1694073847"
                      alt=""
                    />
                  </li>
                </ul>
              </div>
            </div>
            <div class="card_body_content">
              <h4>The Minimal Snowboard</h4>
              <div class="price_wrapper">$85.95</div>
              <div class="product_desc">
                <p data-mce-fragment="1">
                  Experience the power of the Minimal Snowboard. This board
                  has features designed to provide maximum control and
                  maneuverability on the slopes. Its lightweight design and
                  responsive construction make it an ideal choice for
                  experienced boarders.
                </p>
              </div>
              <div class="Quantity-wrapper">
                <select id="quant" class="product-quant"
                  ><option disabled="" selected="">1</option
                  ><option disabled="">2</option
                  ><option disabled="">3</option
                  ><option disabled="">4</option
                  ><option disabled="">5</option></select
                >
                <label for="quant">Quantity</label>
              </div>
              <div class="item-total">
                <div class="Subtotal">
                  <h3>Subtotal</h3>
                  <span>$44.00</span>
                </div>
                <div class="Shipping">
                  <h3>Shipping</h3>
                  <span>Free</span>
                </div>
                <div class="taxes">
                  <h3>Taxes</h3>
                  <span>$3.19</span>
                </div>
                <div class="Total">
                  <h3>Total</h3>
                  <span>$47.19</span>
                </div>
              </div>
              <div class="actions">
                <button class="btn btn-primary">
                  Pay now · <span>$47.19</span>
                </button>
                <button class="btn btn-secondary">
                  Decline this offer
                </button>
              </div>
            </div>
          </div>`
        }
        </div>
        ${
          formData.type == 'single_product_with_content'
            ? `
          <div class='bottom-product-section'>
              ${
                contentBlock.length > 0
                  ? contentBlock
                      ?.map((content, i) => {
                        return `
                          <div class='prodcut-hori-image-section ${
                            i % 2 === 0 ? 'even' : 'odd'
                          }'>
                            <div class='prouct-image-inner'>
                                <img
                                src='${
                                  content.image_url ||
                                  'https://cdn.shopify.com/s/files/1/0724/7046/2766/files/snowboard.webp?v=1694073847'
                                }'
                                />
                            </div>
                            <div class='product-info'>
                                <h2>${content.headline}</h2>
                                <p>
                                ${content.description}
                                </p>
                            </div>
                          </div>
                          `;
                      })
                      .join('')
                  : `
                <div class='prodcut-hori-image-section even'>
                  <div class='prouct-image-inner'>
                      <img
                      src='https://y4y4uny6m7x6zja4-72470462766.shopifypreview.com/cdn/shop/files/snowboard.webp?v=1694073847&width=823'
                      />
                  </div>
                  <div class='product-info'>
                      <h2>Default Content</h2>
                      <p>
                        There is no content yet. Please Enter your Content
                      </p>
                  </div>
                </div>
                `
              }
            
            
        </div>
          `
            : ``
        }
      </div>
    </div>
  </div>
</div>
<style>
body {
  padding: 0;
  margin: 0;
}

* {
  box-sizing: border-box;
}

.chargezen_upsell_setting_container {
  overflow: hidden;
}

.chargezen_upsell_setting_container .main_header_content {
  display: flex;
  justify-content: space-between;
  padding: 17px 20px;
  background: #fff;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.chargezen_upsell_setting_container button.back_button_pointer {
  font-weight: 400;
  position: relative;
  background: transparent;
  border: 0;
  padding: 0;
}

.chargezen_upsell_setting_container .template_preview_container {
  margin: 0px auto;
  width: 100%;
  max-height: calc(100vh - 181px);
  overflow-y: auto;
  max-width: 100%;
  box-shadow: none;
}

.chargezen_upsell_setting_container
  .template_preview_container
  .sub_header_wrapper {
  padding: 23px 17px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.chargezen_upsell_setting_container
  .template_preview_container
  .sub_header_wrapper
  .order_number_block {
  display: flex;
  width: 100%;
  max-width: 992px;
  margin: 0px auto;
}

.chargezen_upsell_setting_container
  .template_preview_container
  .sub_header_wrapper
  .order_number_block
  .text {
  padding-left: 10px;
  font-size: 14px;
  color: #000;
}

.chargezen_upsell_setting_container
  .template_preview_container
  .sub_header_wrapper
  .order_number_block
  .paid_order_text {
  font-size: 22px;
  margin: 0 0 5px;
  color: #000;
}

.chargezen_upsell_setting_container
  .template_preview_container
  .sub_header_wrapper
  .order_number_block
  .text
  a {
  color: rgb(25, 123, 189);
  font-size: 14px;
  text-decoration: none;
}

.chargezen_upsell_setting_container
  .upsell_sub_body_wrapper
  .top_banner_section {
  display: flex;
  flex-direction: row;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  justify-content: space-between;
  max-width: 960px;
  margin: 0px auto;
  padding: 23px 17px 15px;
}

.chargezen_upsell_setting_container
  .upsell_sub_body_wrapper
  .top_banner_section
  button.upsell_primaryButton {
  width: 100%;
  text-align: center;
  border-radius: 0.4rem;
  padding: 20px 20px;
  font-size: 14px;
  font-weight: 700;
  color: rgb(255, 255, 255);
  background-color: rgb(25, 123, 189);
  outline: none;
  border: none;
  margin: 0px;
  opacity: 1;
}

.chargezen_upsell_setting_container
  .upsell_sub_body_wrapper
  .top_banner_section
  .banner_button_wrapper {
  width: 100%;
  max-width: 280px;
}

.chargezen_upsell_setting_container
  .upsell_sub_body_wrapper
  .top_banner_section
  .banner_header_text.sm {
  margin: 0px 0px 8px;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.3;
  color: #000;
}

.chargezen_upsell_setting_container
  .upsell_sub_body_wrapper
  .top_banner_section
  .banner_heading_wrapper
  .banner_header_text:last-child {
  margin: 0px;
  font-size: 28px;
  font-weight: 400;
  line-height: 1.3;
  color: #000;
  text-align: center;
}

.chargezen_upsell_setting_container .products_cardWrapper {
  display: grid;
  max-width: 960px;
  margin: 0px auto;
  padding: 15px 17px;
  grid-template-columns: repeat(3, 1fr);
  gap: 30px;
}

.chargezen_upsell_setting_container .products_cardWrapper .productCard img {
  width: 100%;
}

.chargezen_upsell_setting_container
  .products_cardWrapper
  .productCard
  .card_body_content {
  margin-top: 8px;
}

.chargezen_upsell_setting_container
  .products_cardWrapper
  .productCard
  .card_body_content
  h4 {
  margin: 0;
  font-weight: 400;
  font-size: 18px;
  line-height: 1.3;
  margin-bottom: 13px;
  color: #000;
}

.chargezen_upsell_setting_container
  .products_cardWrapper
  .productCard
  .card_body_content
  .price_wrapper {
  margin-bottom: 12px;
  font-weight: 500;
  font-size: 16px;
  line-height: 1.2;
  color: rgb(88, 88, 88);
}

.chargezen_upsell_setting_container
  .products_cardWrapper
  .productCard
  .card_body_content
  .product_desc {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  font-weight: 400;
  font-size: 14px;
  line-height: 1.3;
  color: rgb(119, 119, 119);
  margin-bottom: 20px;
}

.chargezen_upsell_setting_container button.upsell_primaryButton {
  width: 100%;
  text-align: center;
  border-radius: 0.4rem;
  padding: 17px 20px;
  font-size: 14px;
  font-weight: 700;
  color: rgb(255, 255, 255);
  background-color: rgb(25, 123, 189);
  outline: none;
  border: none;
  margin: 20px 0px 0px;
  opacity: 1;
}

.upsell_cart_right_wrapper {
  max-width: 960px;
  margin: 0px auto;
  padding: 23px 17px 15px;
  border-top: 1px solid rgb(230, 230, 230);
  margin-top: 15px;
}

.upsell_cart_right_wrapper > .upsell_row {
  max-width: 602px;
  margin: 0px 0px 0px auto;
  display: flex;
  align-items: center;
}

.upsell_cart_right_wrapper > .upsell_row .img_wrapper {
  width: 100%;
  max-width: 65px;
}

.upsell_cart_right_wrapper > .upsell_row .img_wrapper img {
  width: 100%;
}

.upsell_cart_right_wrapper > .upsell_row .row_content_wrapper:nth-child(2) {
  padding: 0px 10px;
  width: 100%;
  max-width: 43%;
  font-size: 14px;
  font-weight: 400;
  color: #000;
}

.upsell_cart_right_wrapper
  > .upsell_row
  .row_content_wrapper.cart_quantity_manager {
  width: 100%;
  max-width: 20%;
  padding: 12px 12px 0px;
  border: 1px solid rgb(217, 217, 217);
  border-radius: 0.4rem;
  width: 100%;
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 2rem;
  appearance: none;
  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTAiIGhlaWdodD0iNSIgdmlld0JveD0iMCAwIDEwIDUiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGZpbGwtcnVsZT0iZXZlbm9kZCIgY2xpcC1ydWxlPSJldmVub2RkIiBkPSJNMCAwTDUgNUwxMCAwSDBaIiBmaWxsPSIjOTE5MTkxIi8+Cjwvc3ZnPgo=);
  background-position: right 0.8rem center;
  background-repeat: no-repeat;
  position: relative;
}

.upsell_cart_right_wrapper > .upsell_row .row_content_wrapper:nth-child(4) {
  width: 100%;
  max-width: 12.4%;
  padding-left: 15px;
}

.upsell_cart_right_wrapper > .upsell_row .row_content_wrapper:last-child {
  width: 100%;
  max-width: 14%;
  text-align: right;
  padding-left: 5px;
  font-size: 14px;
  color: #000;
}

.upsell_cart_right_wrapper
  > .upsell_row
  .row_content_wrapper:nth-child(4)
  a.remove_button {
  font-size: 14px;
  color: #000;
}

.upsell_cart_right_wrapper
  > .upsell_row
  .row_content_wrapper.cart_quantity_manager
  label {
  font-size: 10px;
  color: #000;
  position: absolute;
  top: 8px;
  line-height: normal;
  left: 7px;
}

.upsell_cart_right_wrapper
  > .upsell_row
  .row_content_wrapper.cart_quantity_manager
  select {
  border: 0;
  background: transparent;
  width: 100%;
  appearance: none;
  color: #000;
  font-size: 10px;
}

.upsell_cart_right_wrapper
  > .upsell_row
  .row_content_wrapper.cart_quantity_manager:after {
  content: '';
  display: block;
  width: 1px;
  height: 20px;
  position: absolute;
  top: calc(50% - 10px);
  right: 28px;
  background-color: rgb(217, 217, 217);
}

.upsell_cart_right_wrapper .upsell_cart_totalWrapper {
  max-width: 602px;
  margin: 25px 0px 20px auto;
  width: 100%;
  border-top: 1px solid rgb(230, 230, 230);
  border-bottom: 1px solid rgb(230, 230, 230);
  padding: 18px 0px 12px;
}

.upsell_cart_right_wrapper .upsell_cart_totalWrapper .upsell_row {
  display: flex;
  justify-content: space-between;
}

.upsell_cart_right_wrapper
  .upsell_cart_totalWrapper
  .upsell_row:not(:last-child) {
  margin: 0 0 7px;
}

.upsell_cart_right_wrapper .upsell_cart_totalWrapper .upsell_row > div {
  font-size: 14px;
  color: #000;
}

.upsell_cart_right_wrapper .upsell_cart_totalWrapper + .upsell_row {
  justify-content: space-between;
  font-size: 14px;
  color: #000;
}

.upsell_cart_right_wrapper > .upsell_row:last-child {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 50px;
  margin-top: 15px;
}

.upsell_cart_right_wrapper
  > .upsell_row:last-child
  button.upsell_secondaryButton {
  padding: 20.5px 20px;
  background: transparent;
  width: 100%;
  text-align: center;
  border-radius: 0.4rem;
  font-size: 14px;
  font-weight: 700;
  color: rgb(25, 123, 189);
  border: 1px solid rgb(217, 217, 217);
  background: transparent;
}

.upsell_cart_right_wrapper
  > .upsell_row:last-child
  button.upsell_primaryButton {
  margin: 0;
  padding: 20.5px 20px;
}

.prodcut-hori-image-section {
  display: flex;
  gap: 50px;
}

.bottom-product-section {
  width: 100%;
  max-width: 960px;
  margin: 0px auto;
  padding: 23px 17px;
}

.bottom-product-section img {
  width: 100%;
}

.prodcut-hori-image-section > div {
  width: 100%;
  max-width: calc(100% / 2 - 25px);
}

.prodcut-hori-image-section.even {
  flex-flow: row-reverse;
}

.prodcut-hori-image-section.even {
  margin: 40px 0;
}

.prodcut-hori-image-section h2 {
  margin: 0 0;
  margin-bottom: 15px;
  font-size: 28px;
  font-weight: 400;
  line-height: 1.3;
  color: #000;
}

.prodcut-hori-image-section p {
  font-weight: 400;
  font-size: 14px;
  line-height: 1.3;
  color: rgb(119, 119, 119);
  margin-bottom: 40px;
  margin: 0;
}

.top_banner_section.discount-product {
  text-align: center;
  background-color: rgb(250, 250, 250);
  padding: 25px 17px;
  text-align: center;
}

.top_banner_section.discount-product {
  background-color: rgb(250, 250, 250);
  padding: 25px 17px !important;
  text-align: center;
  justify-content: center !important;
}

.top_banner_section.discount-product .banner_header_text {
  font-size: 14px !important;
}

.products_cardWrapper.discounted-product-descritption {
  display: block;
}

.product-thumb ul {
  padding: 0;
  list-style: none;
  display: flex;
  margin: 0;
}

.product-thumb ul > li {
  width: 20%;
  padding: 5px;
}

.products_cardWrapper.discounted-product-descritption .productCard {
  display: flex;
  gap: 40px;
}

.products_cardWrapper.discounted-product-descritption
  .productCard
  .image_wrapper {
  width: 100%;
  max-width: calc(100% - 380px);
}

.products_cardWrapper.discounted-product-descritption
  .productCard
  .card_body_content {
  width: 100%;
  max-width: 340px;
}

.products_cardWrapper.discounted-product-descritption
  .productCard
  .card_body_content
  h4 {
  font-size: 28px;
}

.products_cardWrapper.discounted-product-descritption
  .productCard
  .card_body_content {
  margin: 0;
}

.products_cardWrapper.discounted-product-descritption
  .productCard
  .card_body_content
  p {
  margin: 0;
  font-size: 14px;
}

.products_cardWrapper.discounted-product-descritption
  .productCard
  .card_body_content
  .Quantity-wrapper {
  position: relative;
}

.products_cardWrapper.discounted-product-descritption
  .productCard
  .card_body_content
  .Quantity-wrapper
  select {
  outline: none;
  background-color: white;
  padding: 20px 12px 6px;
  border: 1px solid rgb(217, 217, 217);
  border-radius: 0.4rem;
  width: 100%;
  font-weight: 500;
  font-size: 1.4rem;
  appearance: none;
  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTAiIGhlaWdodD0iNSIgdmlld0JveD0iMCAwIDEwIDUiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGZpbGwtcnVsZT0iZXZlbm9kZCIgY2xpcC1ydWxlPSJldmVub2RkIiBkPSJNMCAwTDUgNUwxMCAwSDBaIiBmaWxsPSIjOTE5MTkxIi8+Cjwvc3ZnPgo=);
  background-position: right 1.2rem center;
  background-repeat: no-repeat;
  font-size: 12px;
  appearance: none !important;
}

.products_cardWrapper.discounted-product-descritption
  .productCard
  .card_body_content
  .Quantity-wrapper
  label {
  position: absolute;
  top: 0.3rem;
  left: 12px;
  font-size: 12px;
  color: rgb(115, 115, 115);
}

.products_cardWrapper.discounted-product-descritption
  .productCard
  .card_body_content
  .actions {
  display: flex;
  flex-flow: column;
  gap: 15px;
  margin: 15px 0 0;
}

.products_cardWrapper.discounted-product-descritption
  .productCard
  .card_body_content
  .actions
  button {
  padding: 20px 0;
  outline: none;
  border: none;
  text-align: center;
  border-radius: 0.4rem;
  font-size: 14px;
  font-weight: 700;
}

.products_cardWrapper.discounted-product-descritption
  .productCard
  .card_body_content
  .actions
  button.btn.btn-primary {
  background-color: rgb(25, 123, 189);
  color: #fff;
}

.products_cardWrapper.discounted-product-descritption
  .productCard
  .card_body_content
  .actions
  button.btn.btn-secondary {
  font-weight: 700;
  color: rgb(25, 123, 189);
  border: 1px solid rgb(217, 217, 217);
  background: transparent;
}

.products_cardWrapper.discounted-product-descritption
  .productCard
  .card_body_content
  .item-total {
  display: flex;
  flex-flow: column;
  gap: 7px;
  width: 100%;
  border-top: 1px solid rgb(230, 230, 230);
  border-bottom: 1px solid rgb(230, 230, 230);
  padding: 18px 0px 12px;
  margin: 25px 0px 20px;
}

.products_cardWrapper.discounted-product-descritption
  .productCard
  .card_body_content
  .item-total
  > div {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.products_cardWrapper.discounted-product-descritption
  .productCard
  .card_body_content
  .item-total
  > div
  h3,
.products_cardWrapper.discounted-product-descritption
  .productCard
  .card_body_content
  .item-total
  > div
  span {
  margin: 0;
  font-size: 14px;
  color: #202223;
}

.products_cardWrapper.discounted-product-descritption
  .productCard
  .image_wrapper
  > img {
  max-height: 488px;
  object-fit: contain;
  border: 1px solid #ddd;
}
.products_cardWrapper.discounted-product-descritption
  .productCard
  .card_body_content
  .item-total
  > div
  h3 {
  font-weight: 400;
}

.product-thumb ul > li img {
  border: 1px solid #ddd;
}
</style>
 `;

  const mobileCss = `
    .chargezen_upsell_setting_container
      .upsell_sub_body_wrapper
      .top_banner_section {
      flex-flow: column;
      gap: 15px;
    }
  
    .chargezen_upsell_container {
      max-width: 390px;
      margin: 0 auto;
      border: 1px solid #ddd;
    }
  
    .chargezen_upsell_setting_container
      .upsell_sub_body_wrapper
      .top_banner_section
      .banner_button_wrapper {
      max-width: 100%;
    }
  
    .chargezen_upsell_setting_container .products_cardWrapper {
      grid-template-columns: repeat(1, 1fr);
    }
  
    .upsell_cart_right_wrapper > .upsell_row .img_wrapper {
      display: none;
    }
  
    .upsell_cart_right_wrapper > .upsell_row {
      flex-wrap: wrap;
      grid-row-gap: 20px;
    }
  
    .upsell_cart_right_wrapper > .upsell_row .row_content_wrapper:nth-child(2) {
      max-width: 65%;
      padding: 0;
    }
  
    .upsell_cart_right_wrapper
      > .upsell_row
      .row_content_wrapper.cart_quantity_manager {
      max-width: 35%;
    }
  
    .upsell_cart_right_wrapper > .upsell_row .row_content_wrapper:nth-child(4) {
      max-width: 50%;
      padding: 0;
    }
  
    .upsell_cart_right_wrapper > .upsell_row .row_content_wrapper:last-child {
      max-width: 50%;
    }
  
    .upsell_cart_right_wrapper > .upsell_row {
      padding: 10px 0;
    }
  
    .upsell_cart_right_wrapper > .upsell_row:not(:first-child) {
      border-top: 1px solid rgb(230, 230, 230);
    }
  
    .upsell_cart_right_wrapper > .upsell_row:last-child {
      grid-template-columns: repeat(1, 1fr);
      gap: 10px;
      border-top: 0;
      margin-top: 0;
    }
  
    .upsell_cart_right_wrapper
      .upsell_cart_totalWrapper
      + .upsell_row:nth-child(5) {
      margin: 0px 0 12px;
      padding-top: 0;
      border-top: 0;
    }
  
    .upsell_cart_right_wrapper
      .upsell_cart_totalWrapper
      + .upsell_row:nth-child(5) {
      font-size: 18px;
      margin-bottom: 0;
    }
  
    .prodcut-hori-image-section {
      flex-flow: column;
      gap: 30px;
    }
  
    .prodcut-hori-image-section.even {
      flex-flow: column;
    }
  
    .prodcut-hori-image-section {
      flex-flow: column;
      gap: 30px;
    }
  
    .bottom-product-section img {
      min-height: 230px;
      max-height: 230px;
    }
    .prodcut-hori-image-section > div {
      max-width: 100%;
    }
    button.changeDeskMobileView {
      background: transparent;
      border: 1px solid #ddd;
      border-radius: 4px;
      cursor: pointer;
      padding: 3px 10px;
    }
  
    .products_cardWrapper.discounted-product-descritption .productCard {
      flex-flow: column;
      gap: 20px;
    }
    .products_cardWrapper.discounted-product-descritption
      .productCard
      .image_wrapper {
      max-width: 100%;
    }
  `;
  return (
    <>
      <div
        className="preview_html_container"
        dangerouslySetInnerHTML={{ __html: htmlTemplate }}
      />
      {mobileView && <style dangerouslySetInnerHTML={{ __html: mobileCss }} />}
    </>
  );
};
export { SingleProductHtml, MultipleProductHtml };
