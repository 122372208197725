import React, { useState, useCallback, useEffect } from 'react';
import { Card, Tabs, Frame, Page, Toast } from '@shopify/polaris';
import '@shopify/polaris/dist/styles.css';
import SlideItem from './slideItem';
import AlreadyCreatedGiftCards from './AlreadyCreatedGiftCards';
import GiftCard from '../index';
import { useParams, useHistory } from 'react-router-dom';

function Bundles(props) {
  const { domain, i18n } = props;
  const history = useHistory();
  const { giftCardId, templateid } = useParams();
  const [apiData, setApiData] = useState([]);
  const [selected, setSelected] = useState(0);
  const [templateId, setTemplateId] = useState();
  const [giftcardssettings, setGiftCardsSettings] = useState([]);
  const [editId, setEditId] = useState(giftCardId);
  const [filteredOffers, setFilteredOffers] = useState({});
  const [toastContent, setToastContent] = useState();
  const hideSaveSuccess = useCallback(() => setToastContent(), []);

  useEffect(() => {
    if (editId && editId !== giftCardId) {
      let toRedirect = window.location.pathname
        ?.replace(`/${giftCardId}`, '')
        ?.replace(domain?.replace('.myshopify.com', ''), '')
        ?.replace('//', '/');

      history.push(`${toRedirect}/${editId}`);
    }
    if (templateid) {
      setTemplateId(templateid);
    }
  }, [editId, templateid]);

  const initialValues = {
    title: 'Custom gift card setting',
    add_to_cart_label: '',
    add_to_cart_size: '',
    button_background_color: '',
    button_text_color: '',
    value_label: '',
    recipient_name_label: '',
    recipient_email_label: '',
    delivery_date_label: '',
    gift_note_label: '',
    popup_bg_color: '',
    border_color: '',
    title_font_color: '',
    subtitle_font_color: '',
    description_font_color: '',
    label_color: '',
    form_border_color: '',
    button_border_color: '',
    toggle_color: '',
    active_toggle_color: '',
    gift_card_position: '',
    spacer: '',
    headline: '',
    checkbox_text: '',
    paragraph: '',
    border_radius: '',
    font_family: '',
  };
  const [formData, setFormData] = useState(initialValues);
  const [contentBlocks, setContentBlocks] = useState([]);

  const handleTabChange = useCallback((selectedTabIndex) => {
    setSelected(selectedTabIndex);
  }, []);

  const tabs = [
    {
      id: 'all-customers-1',
      content: 'All',
      accessibilityLabel: 'All customers',
      panelID: 'all-customers-content-1',
    },
    {
      id: 'Food-&-Beverage',
      content: 'Food & Beverage',
      panelID: 'Food-&-Beverage',
    },
    {
      id: 'Health-&-Beauty',
      content: 'Health & Beauty',
      panelID: 'Health-&-Beauty',
    },
    {
      id: 'Gift-Guide',
      content: 'Gift Guide',
      panelID: 'Gift-Guide',
    },
    {
      id: 'Fashion',
      content: 'Fashion',
      panelID: 'Fashion',
    },
    {
      id: 'Furniture',
      content: 'Furniture',
      panelID: 'Furniture',
    },
    {
      id: 'Technology',
      content: 'Technology',
      panelID: 'Technology',
    },
  ];

  const fetchGiftCardTemplates = () => {
    fetch(`/gift_card/get_templates?shopify_domain=${domain}`, {
      method: 'GET',
    })
      .then((response) => response.json())
      .then((data) => {
        let _data = [...data.templates];
        _data.length = 1;
        setApiData([..._data]);
      });
  };

  const fetchGiftCardSettings = () => {
    fetch(`/gift_card/get_settings?shopify_domain=${domain}`, {
      method: 'GET',
    })
      .then((response) => response.json())
      .then((data) => {
        let _data = [...data.gift_card_settings];
        setGiftCardsSettings([..._data]);
      });
  };

  useEffect(() => {
    fetchGiftCardSettings();
    fetchGiftCardTemplates();
  }, []);

  useEffect(() => {
    let gift_cards = JSON.parse(
      JSON.stringify(
        giftcardssettings?.filter(
          (setting) => setting.title != 'chargezen_default'
        )
      )
    );
    let live_offers = [];
    let draft_offers = [];
    gift_cards?.map((offer) => {
      if (offer.status != 'draft') {
        live_offers.push(offer);
      } else {
        draft_offers.push(offer);
      }
    });
    let filtered_offers = {
      live_offers: live_offers,
      draft_offers: draft_offers,
    };
    setFilteredOffers({ ...filtered_offers });
  }, [giftcardssettings]);

  const handleSubmit = () => {
    const form_data = new FormData();

    form_data.append('gift_card_image', formData.gift_card_image || '');

    form_data.append('background_image', formData.background_image || '');

    form_data.append('gift_card_settings', JSON.stringify(formData));
    form_data.append('shopify_domain', domain);
    form_data.append('template_id', templateId);
    if (editId) {
      form_data.append('gift_card_setting_id', editId || '');
    }

    const content_blocks = contentBlocks?.map((c, i) => {
      delete c.temp_token;
      c.index = i;
      return c;
    });

    form_data.append('content_blocks', JSON.stringify(content_blocks));

    fetch(`/save_gift_card_setting?shopify_domain=${domain}`, {
      method: 'POST',
      body: form_data,
    })
      .then((r) => r.json())
      .then((data) => {
        if (data?.status) {
          setToastContent('Saved successfully');
          fetchGiftCardSettings();
          setTemplateId();
          setEditId();
        } else {
          setToastContent(data?.error);
        }
      });
  };

  const handleBack = () => {
    history.push(`/giftCard`);
    setTemplateId();
    setEditId();
    fetchGiftCardSettings();
    fetchGiftCardTemplates();
    let toRedirect = window.location.pathname
      ?.replace(`/${giftCardId}`, '')
      ?.replace(domain?.replace('.myshopify.com', ''), '')
      ?.replace('//', '/');
    history.push(toRedirect);
  };

  return (
    <Frame>
      {toastContent && (
        <Toast content={toastContent} onDismiss={hideSaveSuccess} />
      )}

      <div
        className={`rewards_template_wrapper zenn_portal_wrapper ${
          editId || templateId ? 'gift_card_main_container' : ''
        }`}
      >
        <Page
          title={
            <div className="flex_header">
              <div class="back-button">
                {(templateId || editId) && (
                  <nav role="navigation">
                    <button
                      data-polaris-unstyled="true"
                      class="Polaris-Breadcrumbs__Breadcrumb"
                      onClick={() => {
                        handleBack();
                      }}
                      style={{ paddingLeft: '6px' }}
                    >
                      <span class="Polaris-Breadcrumbs__Icon">
                        <span class="Polaris-Icon">
                          <span class="Polaris-VisuallyHidden"></span>
                          <svg
                            viewBox="0 0 20 20"
                            class="Polaris-Icon__Svg"
                            focusable="false"
                            aria-hidden="true"
                          >
                            <path d="M17 9h-11.586l3.293-3.293a.999.999 0 1 0-1.414-1.414l-5 5a.999.999 0 0 0 0 1.414l5 5a.997.997 0 0 0 1.414 0 .999.999 0 0 0 0-1.414l-3.293-3.293h11.586a1 1 0 1 0 0-2z"></path>
                          </svg>
                        </span>
                      </span>
                      <span class="Polaris-VisuallyHidden">Quizzes</span>
                    </button>
                  </nav>
                )}
                <div class="Polaris-Page-Header__TitleWrapper">
                  <div>
                    <div class="Polaris-Header-Title__TitleAndSubtitleWrapper">
                      <h1 class="Polaris-Header-Title">
                        {templateId
                          ? apiData?.find((data) => +data.id === +templateId)
                              ?.title
                          : editId
                          ? formData?.title
                          : 'Choose template'}
                      </h1>
                    </div>
                  </div>
                </div>
              </div>
              {(templateId || editId) && (
                <button
                  className="save-button gift_card_save_button"
                  onClick={() => handleSubmit()}
                >
                  Save
                </button>
              )}
            </div>
          }
        >
          {templateId || editId ? (
            <div className="bundle_with_page_wrapper">
              <GiftCard
                domain={domain}
                i18n={i18n}
                templateId={templateId}
                editId={editId}
                setEditId={setEditId}
                toastContent={toastContent}
                setToastContent={setToastContent}
                hideSaveSuccess={hideSaveSuccess}
                setTemplateId={setTemplateId}
                refetchGiftCardSettings={fetchGiftCardSettings}
                {...{
                  formData,
                  setFormData,
                  contentBlocks,
                  setContentBlocks,
                }}
              />
            </div>
          ) : (
            <div className="rewards_choose_template show_content_2">
              <Card>
                <Tabs
                  tabs={tabs}
                  selected={selected}
                  onSelect={handleTabChange}
                >
                  <Card.Section title={tabs[selected].content}>
                    {/* <p>Tab {selected} selected</p> */}
                    <div class="sort-by">
                      <div class="inner-wrap-sort">
                        <label>Sort by:</label>
                        <select
                          id="PolarisSelect1"
                          class="Polaris-Select__Input"
                          aria-invalid="false"
                        >
                          <option value="popular">Popularity</option>
                          <option value="recent">Most recent</option>
                        </select>
                        <svg
                          viewBox="0 0 20 20"
                          class="Polaris-Icon__Svg"
                          focusable="false"
                          aria-hidden="true"
                        >
                          <path d="M7.676 9h4.648c.563 0 .879-.603.53-1.014l-2.323-2.746a.708.708 0 0 0-1.062 0l-2.324 2.746c-.347.411-.032 1.014.531 1.014Zm4.648 2h-4.648c-.563 0-.878.603-.53 1.014l2.323 2.746c.27.32.792.32 1.062 0l2.323-2.746c.349-.411.033-1.014-.53-1.014Z"></path>
                        </svg>
                      </div>
                    </div>

                    {filteredOffers?.live_offers?.length > 0 && (
                      <div className="all_offers_container">
                        <h4 className="offer_header live_offer_header">
                          Live Gift cards settings
                        </h4>
                        {filteredOffers?.live_offers?.map((menu) => {
                          return (
                            <AlreadyCreatedGiftCards
                              menu={menu}
                              setEditId={setEditId}
                              setToastContent={setToastContent}
                              fetchGiftCardSettings={fetchGiftCardSettings}
                              apiData={apiData}
                            />
                          );
                        })}
                      </div>
                    )}
                    {filteredOffers?.draft_offers?.length > 0 && (
                      <div className="all_offers_container">
                        <h4 className="offer_header live_offer_header">
                          Draft Gift cards settings
                        </h4>
                        {filteredOffers?.draft_offers?.map((menu) => {
                          return (
                            <AlreadyCreatedGiftCards
                              menu={menu}
                              setEditId={setEditId}
                              setToastContent={setToastContent}
                              fetchGiftCardSettings={fetchGiftCardSettings}
                              apiData={apiData}
                            />
                          );
                        })}
                      </div>
                    )}

                    {apiData?.map((data) => {
                      return (
                        <SlideItem
                          data={data}
                          templateId={templateId}
                          setTemplateId={setTemplateId}
                        />
                      );
                    })}
                  </Card.Section>
                </Tabs>
              </Card>
            </div>
          )}
        </Page>
      </div>
    </Frame>
  );
}

export default Bundles;
