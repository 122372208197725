import React from 'react';

const HowToEarn = (props) => {
  const { contentBlocks, formData } = props;
  const [welcomeContentBlocks, setWelcomeContentBlocks] = useState();

  useEffect(() => {
    const welcomeBlocks = contentBlocks?.filter(
      (c) => c.section === 'howToEarn'
    );
    setWelcomeContentBlocks([...welcomeBlocks]);
  }, [contentBlocks]);
  return (
    <div className="section-content">
      <div></div>
      <div>
        {welcomeContentBlocks?.map((content) => {
          return (
            <>
              {content.content_type == 'Headline' ? (
                content.headline_size?.toLowerCase() == 'h1' ? (
                  <h1>{content.headline_text}</h1>
                ) : content.headline_size?.toLowerCase() == 'h2' ? (
                  <h2>{content.headline_text}</h2>
                ) : (
                  <h3>{content.headline_text}</h3>
                )
              ) : content.content_type == 'Paragraph' ? (
                <>
                  <div class="chargezen-custom_paragraph" tabindex="0">
                    <p className="ql-align-center">{content.paragraph}</p>
                  </div>
                </>
              ) : content.content_type == 'Button' ? (
                <div className="rewards_page button_wrapper">
                  <a
                    href={content.button_link}
                    target="_blank"
                    className="gift_card_custom_button"
                  >
                    {content.button_text}
                  </a>
                </div>
              ) : content.content_type == 'Spacer' ? (
                <div
                  className="spacer_wapper"
                  style={{ height: `${content.spacer_size}px` }}
                ></div>
              ) : (
                <></>
              )}
            </>
          );
        })}
      </div>
    </div>
  );
};

export default HowToEarn;
