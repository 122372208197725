import React, { useCallback, useEffect, useState } from 'react';
import {
  Badge,
  Button,
  Card,
  Frame,
  Icon,
  Page,
  Tabs,
  Toast,
} from '@shopify/polaris';
import { MobileBackArrowMajor } from '@shopify/polaris-icons';
import {
  AddDiscount,
  CreateBilling,
  EditFrequency,
  EditModel,
  EditSubsModel,
  EditSwapModel,
} from './Models';
import Tables from './tables';
import visaLogo from '../../images/visa_logo.png';
import masterLogo from '../../images/mastercard_logo.png';
import dayjs from 'dayjs';
import { useHistory } from 'react-router-dom';
import HeaderButtons from '../HeaderButtons/HeaderButtons';
import PixelIcon from '../../images/PixelIcon';

function SubscriptionView(props) {
  const { token, domain, setToken, i18n, contractToken, formatDate } = props;
  const history = useHistory();
  const [apiData, setApiData] = useState();
  const [paymentObj, setPaymentObj] = useState();
  const [saveSuccess, setSaveSuccess] = useState(false);
  const [toastContent, setToastContent] = useState('');
  const hideSaveSuccess = useCallback(() => setSaveSuccess(false), []);
  const [editRestartDate, setEditRestartDate] = useState(false);
  const [showAllLines, setShowAllLines] = useState(false);

  const headerButtons = [
    {
      val: 'Delivery-Schedule',
      name: 'Delivery Schedule',
    },
    {
      val: 'Payment History',
      name: 'Payment History',
    },
    {
      val: 'Weekly-Menu',
      name: 'Weekly-Menu',
    },
    {
      val: 'Billing-Attempts',
      name: 'Billing-Attempts',
    },
    {
      val: 'Contract-History',
      name: 'Contract-History',
    },
  ];

  const [headerButton, setHeaderButton] = useState(headerButtons?.[0]?.val);

  const getTotalPrice = () => {
    let price = 0;
    apiData?.contract?.api_data?.lines?.edges?.map((line) => {
      price += line?.node?.current_price?.amount * +line?.node?.quantity || 0;
    });
    return price?.toFixed(2);
  };


  const [editProductModel, setEditProductModel] = useState();
  const [show_edit_sub_model, setShowEditSubModel] = useState(false);
  const [show_edit_swap_model, setShowEditSwapModel] = useState(false);
  const [show_create_billing_model, setCreateBillingModel] = useState(false);
  const [show_add_discount_model, setAddDiscount] = useState(false);
  const [editNextBillingDate, setEditNextBillingDate] = useState(false);
  const [editFrequencyModel, setEditFrequencyModel] = useState();
  const [formData, setFormData] = useState({ next_billing_date: '' });
  const [originOrder, setOriginOrder] = useState();

  const getSubscription = () => {
    fetch(`/getSubscription?token=${token}&shopify_domain=${domain}`, {
      method: 'GET',
    })
      .then((response) => response.json())
      .then((data) => {
        var address =
          data?.payment_method?.data?.customerPaymentMethod?.instrument
            ?.billingAddress;
        address = address
          ? address
          : data?.customer?.api_data?.data?.defaultAddress;

        setPaymentObj({
          card: data?.payment_method?.data?.customerPaymentMethod?.instrument,
          billing_address: address,
        });
        console.log('testingfetch', data);
        setApiData(data);
      });
  };

  const cancelSubscription = (line_id = '') => {
    let id = apiData?.contract?.shopify_id;
    // let line_id = apiData?.subscription?.lines?.edges?.[0]?.node?.id;
    let variant_id = apiData?.subscription?.lines?.edges?.[0]?.node?.variant_id;
    let url =
      '/subscriptions/' +
      id +
      '/remove_line?line_id=' +
      line_id +
      '&lines_count=1&quantity=1&variant_id=' +
      variant_id +
      '&action_by=admin&token=#{params[:token]}';
    setToastContent('Updating...');
    setSaveSuccess(true);
    let myDate = new Date();
    myDate.setDate(myDate.getDate() + 1);
    // format a date
    var date =
      myDate.getDate() +
      '/' +
      ('0' + (myDate.getMonth() + 1)).slice(-2) +
      '/' +
      myDate.getFullYear();

    fetch(url, {
      method: 'POST',
      body: JSON.stringify({
        billing_date: date,
        action_by: 'admin',
        token: apiData?.contract?.token,
      }),
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setShowEditSubModel(false);
        if (data.success == 'true') {
          let successMessage = line_id
            ? 'Removed Successfully'
            : 'Subscription Cancelled Successfully';
          setToastContent(successMessage);
          setTimeout(() => {
            getSubscription();
          }, [3000]);
        } else {
          setToastContent('An error occured', data);
        }
        setSaveSuccess(true);
      });
  };

  console.log('setApiData', apiData);
  console.log('setPaymentObj', paymentObj);

  useEffect(() => {
    if (domain) {
      getSubscription();
    }
  }, [domain]);

  useEffect(() => {
    if (apiData) {
      const originorder = apiData?.contract?.api_data.orders?.edges?.sort(
        (a, b) => {
          if (a.node.created_at > b.node.created_at) {
            return 0;
          } else {
            return -1;
          }
        }
      )?.[0]?.node;
      setOriginOrder(originorder);
    }
  }, [apiData]);

  const restartSubscription = () => {
    fetch(`/subscriptions/${apiData?.contract?.shopify_id}/resume`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        billing_date: formData?.next_billing_date,
        action_by: 'admin',
        res_format: 'json',
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        setSaveSuccess(true);
        console.log('data', data, data?.status);
        if (data?.status) {
          setToastContent('Resarted Successfully!');
          getSubscription();
        } else {
          setToastContent(data?.error);
        }
        setEditRestartDate(false);
      });
  };

  const saveNextBillingDate = () => {
    fetch('/subscriptions/update_next_billing_date', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        local_id: apiData?.contract?.id,
        date: formData?.next_billing_date,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        setSaveSuccess(true);
        if (data?.status) {
          setToastContent('Updated billing date!');
          apiData.subscription.next_billing_date = data?.billing_date;
        } else {
          setToastContent(data?.error);
        }
        setEditNextBillingDate(false);
      });
  };

  const minDate = () => {
    const today = new Date().toISOString().split('T')[0];
    return today;
  };

  return (
    <Frame>
      {saveSuccess && (
        <Toast content={toastContent} onDismiss={hideSaveSuccess} />
      )}
      {apiData && (
        <>
          {editProductModel && (
            <EditModel
              setEditProductModel={setEditProductModel}
              editProductModel={editProductModel}
              domain={domain}
              setToastContent={setToastContent}
              setSaveSuccess={setSaveSuccess}
              getSubscription={getSubscription}
              formatDate={formatDate}
            />
          )}

          {show_edit_sub_model && (
            <EditSubsModel
              setShowEditSubModel={setShowEditSubModel}
              setShowEditSwapModel={setShowEditSwapModel}
              apiData={apiData}
              setToastContent={setToastContent}
              setSaveSuccess={setSaveSuccess}
              getSubscription={getSubscription}
              setEditFrequencyModel={setEditFrequencyModel}
              cancelSubscription={cancelSubscription}
              formatDate={formatDate}
              getTotalPrice={getTotalPrice}
            />
          )}
          {editFrequencyModel && (
            <EditFrequency
              editFrequencyModel={editFrequencyModel}
              setEditFrequencyModel={setEditFrequencyModel}
              domain={domain}
              setToastContent={setToastContent}
              getSubscription={getSubscription}
              setSaveSuccess={setSaveSuccess}
              apiData={apiData}
              formatDate={formatDate}
            />
          )}
          {show_edit_swap_model && (
            <EditSwapModel
              setShowEditSubModel={setShowEditSubModel}
              setShowEditSwapModel={setShowEditSwapModel}
              apiData={apiData}
              setToastContent={setToastContent}
              setSaveSuccess={setSaveSuccess}
              getSubscription={getSubscription}
              i18n={i18n}
              formatDate={formatDate}
            />
          )}
          {show_create_billing_model && (
            <CreateBilling
              setCreateBillingModel={setCreateBillingModel}
              apiData={apiData}
              setToastContent={setToastContent}
              setSaveSuccess={setSaveSuccess}
              getSubscription={getSubscription}
              formatDate={formatDate}
            />
          )}
          {show_add_discount_model && (
            <AddDiscount
              setAddDiscount={setAddDiscount}
              apiData={apiData}
              setToastContent={setToastContent}
              setSaveSuccess={setSaveSuccess}
              getSubscription={getSubscription}
              formatDate={formatDate}
            />
          )}

          <div className="container-chargen">
            <Frame>
              <Page
                title={
                  <div className="back_button_wrapper">
                    <div
                      className="back-button pointer"
                      style={{ marginTop: '15px' }}
                      onClick={() => {
                        if (contractToken) {
                          history.push('/customersTab/contracts');
                        } else {
                          setToken();
                        }
                      }}
                    >
                      <Icon source={MobileBackArrowMajor} color="base" />
                    </div>
                    <div class="text_wrapper">
                      {apiData?.customer?.first_name || ''}{' '}
                      {apiData?.customer?.last_name || ''}
                    </div>
                  </div>
                }
              >
                <div className="billing-info">
                  <div className="col-8 subscription-col bg-white padding-20 common-radius">
                    <div className="subscription-header flex">
                      <h2>
                        {apiData?.customer?.first_name || ''}{' '}
                        {apiData?.customer?.last_name || ''}
                      </h2>
                      <div>
                        <span> Shopify Subscription</span>
                      </div>
                      <div>
                        <b>{apiData?.subscription?.currency_code} {getTotalPrice()}</b>
                        <span>every {apiData?.contract?.api_data?.billing_policy?.interval_count} {apiData?.contract?.api_data?.billing_policy?.interval?.toLowerCase()}</span>
                      </div>
                    </div>
                    {apiData?.subscription?.lines?.edges?.map(
                      (line, lineIndex) => {
                        let sellingPlan = apiData?.all_selling_plans?.find(
                          (sp) => sp?.shopify_id == line?.node?.selling_plan_id
                        );
                        return (
                          <div
                            div
                            class={`meal-saver flex ${lineIndex > 0 && !showAllLines ? 'no-height' : ''
                              }`}
                          >
                            <div>
                              <p>
                                {line?.node?.quantity} x {line?.node?.title}
                              </p>
                              <p>{sellingPlan?.name}</p>
                            </div>
                            <div>
                              <div class="item_price">
                                {apiData?.subscription?.currency_code}{' '}
                                {line?.node?.current_price?.amount}
                              </div>
                              <button
                                class="edit-product-button"
                                type="button"
                                onClick={() =>
                                  setEditProductModel({
                                    line: line?.node,
                                    contract: apiData?.contract,
                                  })
                                }
                              >
                                Edit Product
                              </button>
                              {apiData?.subscription?.lines?.edges?.length >
                                1 && (
                                  <button
                                    class="edit-product-button"
                                    type="button"
                                    onClick={() =>
                                      cancelSubscription(line?.node?.id)
                                    }
                                    style={{ color: '#ff3b3b' }}
                                  >
                                    Remove product
                                  </button>
                                )}
                            </div>
                          </div>
                        );
                      }
                    )}
                    {apiData?.subscription?.lines?.edges?.length > 1 &&
                      !showAllLines && (
                        <div
                          className="show_more_line_items"
                          onClick={() => {
                            setShowAllLines(true);
                          }}
                        >
                          Show all
                        </div>
                      )}
                    <div className="product-option-button">
                      {apiData?.contract?.status?.toLowerCase() == 'active' ? (
                        <>
                          <button
                            onClick={() => setShowEditSubModel(true)}
                            className="btn green-button"
                            type="button"
                          >
                            Edit Subscription
                          </button>
                          <button
                            onClick={() => setCreateBillingModel(true)}
                            className="btn blue-button"
                            type="button"
                          >
                            Charge card
                          </button>
                        </>
                      ) : (
                        <>
                          <button
                            className="btn green-button"
                            type="button"
                            style={{ textTransform: 'capitalize' }}
                          >
                            {apiData?.contract?.status?.toLowerCase()}
                          </button>
                          <div className="restart_sub_wrapper">
                            <button
                              onClick={() => setEditRestartDate(true)}
                              className="btn green-button"
                              type="button"
                            >
                              Restart Subscription
                            </button>
                            {editRestartDate && (
                              <div className="edit_date_wrapper">
                                <label className="chooseDateLabel">
                                  Choose date to restart card on file
                                </label>
                                <input
                                  type="date"
                                  value={formData?.next_billing_date}
                                  onChange={(e) => {
                                    setFormData({
                                      ...formData,
                                      next_billing_date: e?.target?.value,
                                    });
                                  }}
                                  min={minDate()}
                                />
                                <button
                                  className="save_next_billing_button save_button"
                                  onClick={() => {
                                    restartSubscription();
                                  }}
                                >
                                  Apply
                                </button>
                              </div>
                            )}
                          </div>
                        </>
                      )}
                      <a
                        className="customer_portal"
                        href={`https://${domain}/pages/portal/${apiData?.customer?.uid}@-xyz~${apiData?.auth_token}`}
                        target="_blank"
                      >
                        <button class="btn blue-button" type="button">
                          Access Customer Portal
                        </button>
                      </a>
                      <a
                        href={`https://${domain}/admin/customers/${apiData?.customer?.shopify_id}?shop=${domain}`}
                        target="_blank"
                      >
                        <button className="btn blue-button" type="button">
                          Shopify Profile
                        </button>
                      </a>
                    </div>
                  </div>
                  <div className="col-4 billing-detaisl bg-white padding-20 common-radius contract_overview_container">
                    <div className="flex header-bill">
                      <h1><strong>Contract Overview</strong></h1>
                      <span className="edit">Edit</span>
                    </div>
                    <div className="contract_overview_content">
                      <div className="overview_row">
                        <p className="overview_label">
                          <strong>Status:</strong>
                        </p>
                        <div className="overview_val">
                          <Badge
                            status={
                              apiData?.contract?.status === 'ACTIVE'
                                ? `success`
                                :
                                apiData?.contract?.status === 'CANCELLED' ?
                                  'critical'
                                  : 'warning'
                            }
                          >
                            {apiData?.contract?.status}
                          </Badge>
                        </div>
                      </div>

                      <>
                        <div className="overview_row">
                          <p className="overview_label">
                            <strong>Created on:</strong>
                          </p>
                          <div className="overview_val">
                            {dayjs(apiData?.contract?.created_at).format('MMM DD, YYYY')}
                          </div>
                        </div>
                        <div className="overview_row">
                          <p className="overview_label">
                            <strong>Next order:</strong>
                          </p>
                          <div className="overview_val">
                            {dayjs(apiData?.contract?.api_data?.next_billing_date).format('MMM DD, YYYY')}
                          </div>
                        </div>
                      </>

                      <div className="overview_row">
                        <p className="overview_label">
                          <strong>Contract orders:</strong>
                        </p>
                        <div className="overview_val">
                          {apiData?.contract?.api_data?.orders?.edges?.length}
                        </div>
                      </div>
                    </div>
                    <div className="overview_container_footer">
                      <div className="overview_row">
                        <p className="overview_label">
                          <strong>Customer:</strong>
                        </p>
                        <a
                          className="overview_val"
                          href={`https://${domain}/admin/customers/${apiData?.contract.shopify_customer_id}`}
                          target="_blank"
                        >
                          {apiData?.customer?.first_name ||
                            apiData?.customer?.last_name
                            ? `${apiData?.customer?.first_name} ${apiData?.customer?.last_name}`
                            : apiData?.customer?.email}
                        </a>
                      </div>
                      <div className="overview_row">
                        <p className="overview_label">
                          <strong>Email:</strong>
                        </p>

                        <div className="overview_val">
                          {apiData?.contract?.email}
                        </div>
                      </div>
                      <div className="overview_row">
                        <p className="overview_label">
                          <strong>Phone:</strong>
                        </p>
                        <div className="overview_val">
                          {apiData?.customer?.api_data?.data?.phone ||
                            'No phone added'}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="order-info bg-white padding-20">
                  <div className="flex">
                    <div className="order-inner">
                      <div className="order-icon">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          height="35"
                          width="35"
                          version="1.1"
                          id="Layer_1"
                          x="0px"
                          y="0px"
                          viewBox="0 0 122.88 122.88"
                        >
                          <g>
                            <path
                              class="st0"
                              d="M61.44,0c33.93,0,61.44,27.51,61.44,61.44c0,33.93-27.51,61.44-61.44,61.44S0,95.37,0,61.44 C0,27.51,27.51,0,61.44,0L61.44,0z M70.66,34.43c3.91-8.48,12.48-3.51,9.45,2.07L52.18,88.01c-4.41,9.57-12.4,3.36-9.46-2.07 L70.66,34.43L70.66,34.43z M27.71,46.84c0-2.31,0.51-4.53,1.52-6.62c1.02-2.1,2.57-3.71,4.66-4.83c2.08-1.12,4.54-1.69,7.4-1.69 c4.19,0,7.5,1.19,9.91,3.58c2.41,2.39,3.62,5.58,3.62,9.56c0,4.02-1.2,7.22-3.62,9.61c-2.41,2.39-5.72,3.58-9.91,3.58 c-2.86,0-5.32-0.57-7.4-1.69c-2.08-1.13-3.64-2.73-4.66-4.83C28.21,51.42,27.71,49.19,27.71,46.84L27.71,46.84z M37.3,46.91 c0,2.81,0.46,4.85,1.37,6.12c0.6,0.84,1.47,1.26,2.61,1.26c1.13,0,1.98-0.43,2.57-1.26c0.89-1.27,1.37-3.3,1.37-6.12 c0-2.78-0.46-4.82-1.37-6.1c-0.57-0.81-1.44-1.23-2.57-1.23c-1.14,0-2.02,0.41-2.61,1.23C37.76,42.06,37.3,44.11,37.3,46.91 L37.3,46.91z M68.07,77.43c0-2.35,0.5-4.57,1.51-6.65c1-2.09,2.56-3.71,4.65-4.84c2.09-1.14,4.55-1.7,7.39-1.7 c4.21,0,7.53,1.2,9.95,3.61c2.42,2.4,3.62,5.6,3.62,9.59c0,3.99-1.2,7.19-3.62,9.59c-2.41,2.4-5.73,3.61-9.95,3.61 c-2.84,0-5.29-0.57-7.39-1.7c-2.09-1.13-3.64-2.76-4.65-4.84C68.58,81.99,68.07,79.77,68.07,77.43L68.07,77.43z M77.67,77.51 c0,2.82,0.46,4.88,1.37,6.15c0.57,0.81,1.44,1.23,2.58,1.23c1.13,0,2.01-0.41,2.6-1.23c0.91-1.27,1.37-3.33,1.37-6.15 c0-2.81-0.45-4.89-1.37-6.17c-0.58-0.82-1.47-1.23-2.6-1.23c-1.14,0-2,0.43-2.58,1.26C78.14,72.65,77.67,74.7,77.67,77.51 L77.67,77.51z"
                              style={{
                                fill: '#2c6ecb',
                                fillRule: 'evenodd',
                                clipRule: 'evenodd',
                              }}
                            />
                          </g>
                        </svg>
                      </div>
                      <div class="order-type">
                        <h2>Applied Discount</h2>
                        {apiData?.subscription?.discounts?.edges?.length > 0 ? (
                          apiData?.subscription?.discounts?.edges?.map(
                            (discount) => {
                              return (
                                <>
                                  <button class="edit-discount" type="button">
                                    {discount.node.title}
                                  </button>
                                  <span class="discounted_main">
                                    {discount?.node?.value.percentage
                                      ? `${discount?.node?.value?.percentage}% off`
                                      : `${discount?.node?.value?.amount} off`}
                                  </span>
                                </>
                              );
                            }
                          )
                        ) : (
                          <div className='edit_discount_wrapper'>
                            <span className='no_discount_text'>No Discount</span>
                            <button
                              class="edit-discount"
                              type="button"
                              onClick={() => setAddDiscount(true)}
                            >
                              Edit
                            </button>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="order-inner">
                      <div className="order-icon">
                        <svg
                          width="35"
                          height="35"
                          viewBox="0 0 35 35"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <circle
                            cx="17.5"
                            cy="17.5"
                            r="17.5"
                            fill="#2C6ECB"
                          ></circle>
                          <path
                            d="M24.9038 7H11.0964C10.7728 7 10.5105 7.26234 10.5105 7.58594V24.6881C10.5105 24.8435 10.5723 24.9925 10.6821 25.1024L12.408 26.8284C12.6369 27.0572 13.0078 27.0572 13.2367 26.8284L14.5483 25.5168L15.8598 26.8284C15.9697 26.9382 16.1187 27 16.2741 27C16.4295 27 16.5786 26.9383 16.6885 26.8284L18 25.5168L19.3116 26.8284C19.4215 26.9382 19.5706 27 19.7259 27C19.8813 27 20.0304 26.9383 20.1402 26.8284L21.4518 25.5168L22.7634 26.8284C22.8778 26.9428 23.0277 27 23.1777 27C23.3276 27 23.4776 26.9428 23.592 26.8284L25.3179 25.1024C25.4278 24.9925 25.4896 24.8435 25.4896 24.6881V7.58594C25.4897 7.26234 25.2273 7 24.9038 7ZM17.414 11.4012V10.724C17.414 10.4004 17.6764 10.138 18 10.138C18.3236 10.138 18.5859 10.4004 18.5859 10.724V11.3932H19.2552C19.5788 11.3932 19.8412 11.6556 19.8412 11.9792C19.8412 12.3028 19.5788 12.5651 19.2552 12.5651H17.5817C17.2126 12.5651 16.9124 12.8653 16.9124 13.2344C16.9124 13.6034 17.2126 13.9037 17.5817 13.9037H18.4185C19.4337 13.9037 20.2597 14.7296 20.2597 15.7448C20.2597 16.7035 19.5229 17.4931 18.5859 17.578V18.2553C18.5859 18.5788 18.3236 18.8412 18 18.8412C17.6764 18.8412 17.414 18.5788 17.414 18.2553V17.5859H16.7448C16.4212 17.5859 16.1588 17.3236 16.1588 17C16.1588 16.6764 16.4212 16.414 16.7448 16.414H18.4185C18.7875 16.414 19.0878 16.1138 19.0878 15.7448C19.0878 15.3758 18.7876 15.0755 18.4185 15.0755H17.5817C16.5664 15.0755 15.7405 14.2496 15.7405 13.2343C15.7406 12.2757 16.4772 11.4862 17.414 11.4012ZM21.1381 21.9792H14.8621C14.5385 21.9792 14.2761 21.7168 14.2761 21.3932C14.2761 21.0696 14.5385 20.8073 14.8621 20.8073H21.1381C21.4616 20.8073 21.724 21.0696 21.724 21.3932C21.724 21.7168 21.4616 21.9792 21.1381 21.9792Z"
                            fill="white"
                          ></path>
                        </svg>
                      </div>
                      <div className="order-type">
                        <h2>Delivery Price</h2>
                        {apiData?.subscription?.currency_code}{' '}
                        {apiData?.subscription?.delivery_price?.amount}{' '}
                      </div>
                    </div>

                    <div className="order-inner">
                      <div className="order-icon">
                        <svg
                          width="35"
                          height="35"
                          viewBox="0 0 35 35"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <circle
                            cx="17.5"
                            cy="17.5"
                            r="17.5"
                            fill="#2C6ECB"
                          ></circle>
                          <g clipPath="url(#clip0_4:1774)">
                            <path
                              d="M9.79688 18.7969H8.58594C8.26207 18.7969 8 19.0589 8 19.3828V26.4141C8 26.7379 8.26207 27 8.58594 27H9.79688C10.7662 27 11.5547 26.2115 11.5547 25.2422V20.5547C11.5547 19.5854 10.7662 18.7969 9.79688 18.7969Z"
                              fill="white"
                            ></path>
                            <path
                              d="M21.5547 7C18.0039 7 15.1094 9.89449 15.1094 13.4453C15.1094 14.9922 15.6601 16.4101 16.5742 17.5234C17.3125 17.7343 18.0039 18.1093 18.5898 18.625C18.7 18.7077 18.7622 18.7067 18.8828 18.7188H20.9688C21.7773 18.7188 22.4922 19.1171 22.9024 19.75C25.8086 19.1289 28 16.5391 28 13.4453C28 9.89449 25.1055 7 21.5547 7ZM22.1523 16.2695H22.1406V16.9609C22.1406 17.289 21.8829 17.5469 21.5547 17.5469C21.2265 17.5469 20.9688 17.289 20.9688 16.9609V16.2695C20.582 16.1406 20.2187 15.8945 19.9024 15.5078C19.6914 15.2617 19.7266 14.8984 19.9727 14.6875C20.2188 14.4766 20.5938 14.5117 20.793 14.7578C21.1094 15.1328 21.4609 15.2734 21.7539 15.1679C21.9883 15.0859 22.1406 14.8632 22.1406 14.6172C22.1406 14.289 21.8829 14.0312 21.5547 14.0312C20.582 14.0312 19.7969 13.2461 19.7969 12.2734C19.7969 11.5703 20.2188 10.9257 20.875 10.6562C20.8985 10.6445 20.9336 10.6445 20.9688 10.6328V9.92969C20.9688 9.60152 21.2265 9.34375 21.5547 9.34375C21.8829 9.34375 22.1406 9.60152 22.1406 9.92969V10.6328C22.4453 10.7383 22.7383 10.8906 23.0078 11.1484C23.2422 11.3711 23.2422 11.7461 23.0195 11.9805C22.7969 12.2149 22.4219 12.2148 22.1875 11.9922C21.9062 11.7226 21.5899 11.6172 21.332 11.7343C21.1094 11.8281 20.9688 12.0391 20.9688 12.2734C20.9688 12.6015 21.2265 12.8594 21.5547 12.8594C22.5273 12.8594 23.3125 13.6445 23.3125 14.6172C23.3125 15.3555 22.8437 16.0117 22.1523 16.2695Z"
                              fill="white"
                            ></path>
                            <path
                              d="M27.8242 19.9336C27.7539 19.8164 27.6602 19.7226 27.5547 19.6406C27.1232 19.2631 26.3963 19.2988 25.9727 19.7578L23.3125 22.8281L23.2305 22.9218C22.9024 23.2851 22.4336 23.4843 21.9414 23.4843H17.3555C17.0273 23.4843 16.7695 23.2265 16.7695 22.8984C16.7695 22.5702 17.0273 22.3125 17.3555 22.3125H20.9688C21.6133 22.3125 22.1406 21.7851 22.1406 21.1406V21.1289C22.1289 20.4843 21.6133 19.9687 20.9688 19.9687H18.8086C18.4572 19.9687 18.0806 19.8397 17.7773 19.582C16.3477 18.3281 14.2617 18.246 12.7266 19.3125V26.5195C13.8867 26.8359 15.082 27 16.289 27H21.5547C22.8437 27 24.0625 26.3906 24.8359 25.3593L27.6485 21.6093C28.0588 21.0707 28.0898 20.332 27.8242 19.9336Z"
                              fill="white"
                            ></path>
                          </g>
                          <defs>
                            <clipPath id="clip0_4:1774">
                              <rect
                                width="20"
                                height="20"
                                fill="white"
                                transform="translate(8 7)"
                              ></rect>
                            </clipPath>
                          </defs>
                        </svg>
                      </div>
                      <div className="order-type">
                        <h2>Next Charge Date</h2>
                        <span class="date">
                          {
                            apiData?.billing_dates?.length > 0 ?
                              dayjs(
                                apiData?.billing_dates[0]
                              )?.format('MMM DD, HH:mm a')
                              :
                              dayjs(
                                apiData?.subscription?.next_billing_date
                              )?.format('MMM DD, HH:mm a')}
                        </span>
                        <button
                          className="btn-transparent edit"
                          type="button"
                          onClick={() => {
                            setEditNextBillingDate(true);
                          }}
                        >
                          Edit
                        </button>
                        {editNextBillingDate && (
                          <div className="edit_date_wrapper">
                            <input
                              type="date"
                              value={formData?.next_billing_date}
                              onChange={(e) => {
                                setFormData({
                                  ...formData,
                                  next_billing_date: e?.target?.value,
                                });
                              }}
                              min={minDate()}
                            />
                            <button
                              className="save_next_billing_button save_button"
                              onClick={() => {
                                saveNextBillingDate();
                              }}
                            >
                              Apply
                            </button>
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="order-inner">
                      <div className="order-icon">
                        <svg width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <circle cx="17.5" cy="17.5" r="17.5" fill="#2C6ECB"></circle>
                          <path fillRule="evenodd" clipRule="evenodd" d="M22.2578 11.1803C23.9918 11.5827 24.6551 12.5966 25.4867 13.9922H22.2578V11.1803ZM12.1241 14.1094C12.4637 14.1094 12.7101 14.2733 12.7101 14.6562C12.7101 14.9793 12.4487 15.2413 12.1258 15.2422H8.58594C8.2623 15.2422 8 15.5046 8 15.8281C8 16.1518 8.2623 16.4141 8.58594 16.4141H13.8594C14.1861 16.4141 14.4476 16.6762 14.4476 17C14.4476 17.3236 14.1853 17.5859 13.8617 17.5859H8.58594C8.2623 17.5859 8 17.8482 8 18.1719C8 18.4955 8.2623 18.7578 8.58594 18.7578H9.875V20.5156C9.875 20.8393 10.1373 21.1016 10.4609 21.1016H11.6388C11.8487 22.1358 12.7626 22.8984 13.8398 22.8984C14.9171 22.8984 15.831 22.1358 16.0409 21.1016H21.9903C22.2003 22.1358 23.1141 22.8984 24.1914 22.8984C25.2687 22.8984 26.1825 22.1358 26.3925 21.1016H27.4141C27.7377 21.1016 28 20.8393 28 20.5156V17C28 15.2808 26.1866 15.1667 26.185 15.1641H21.6719C21.3482 15.1641 21.0859 14.9018 21.0859 14.5781V11.0625H10.4609C10.1373 11.0625 9.875 11.3248 9.875 11.6484V12.9375H9.17188C8.84824 12.9375 8.58594 13.1998 8.58594 13.5234C8.58594 13.8471 8.84824 14.1094 9.17188 14.1094H12.1241ZM24.951 19.8928C25.3705 20.3122 25.3705 20.9926 24.951 21.4121C24.2762 22.0868 23.1172 21.6068 23.1172 20.6523C23.1172 19.6981 24.2762 19.218 24.951 19.8928ZM14.5994 19.8928C15.0189 20.3122 15.0189 20.9926 14.5994 21.4121C13.9247 22.0868 12.7656 21.6068 12.7656 20.6523C12.7656 19.6981 13.9247 19.218 14.5994 19.8928Z" fill="white"></path>
                        </svg>
                      </div>
                      <div className="order-type">
                        <h2>Next Delivery Date</h2>
                        <span class="date">
                          {
                            apiData?.shipping_dates?.length > 0 ?
                              dayjs(
                                apiData?.shipping_dates[0]
                              )?.format('MMM DD, HH:mm a')
                              :
                              dayjs(
                                apiData?.subscription?.next_billing_date
                              )?.format('MMM DD, HH:mm a')}
                        </span>
                        <button
                          className="btn-transparent edit"
                          type="button"
                          onClick={() => {
                            setEditNextBillingDate(true);
                          }}
                        >
                          Edit
                        </button>
                        {editNextBillingDate && (
                          <div className="edit_date_wrapper">
                            <input
                              type="date"
                              value={formData?.next_billing_date}
                              onChange={(e) => {
                                setFormData({
                                  ...formData,
                                  next_billing_date: e?.target?.value,
                                });
                              }}
                              min={minDate()}
                            />
                            <button
                              className="save_next_billing_button save_button"
                              onClick={() => {
                                saveNextBillingDate();
                              }}
                            >
                              Apply
                            </button>
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="order-inner">
                      <div className="order-icon">
                        <svg
                          width="35"
                          height="35"
                          viewBox="0 0 35 35"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <circle
                            cx="17.5"
                            cy="17.5"
                            r="17.5"
                            fill="#2C6ECB"
                          ></circle>
                          <path
                            d="M24.9038 7H11.0964C10.7728 7 10.5105 7.26234 10.5105 7.58594V24.6881C10.5105 24.8435 10.5723 24.9925 10.6821 25.1024L12.408 26.8284C12.6369 27.0572 13.0078 27.0572 13.2367 26.8284L14.5483 25.5168L15.8598 26.8284C15.9697 26.9382 16.1187 27 16.2741 27C16.4295 27 16.5786 26.9383 16.6885 26.8284L18 25.5168L19.3116 26.8284C19.4215 26.9382 19.5706 27 19.7259 27C19.8813 27 20.0304 26.9383 20.1402 26.8284L21.4518 25.5168L22.7634 26.8284C22.8778 26.9428 23.0277 27 23.1777 27C23.3276 27 23.4776 26.9428 23.592 26.8284L25.3179 25.1024C25.4278 24.9925 25.4896 24.8435 25.4896 24.6881V7.58594C25.4897 7.26234 25.2273 7 24.9038 7ZM17.414 11.4012V10.724C17.414 10.4004 17.6764 10.138 18 10.138C18.3236 10.138 18.5859 10.4004 18.5859 10.724V11.3932H19.2552C19.5788 11.3932 19.8412 11.6556 19.8412 11.9792C19.8412 12.3028 19.5788 12.5651 19.2552 12.5651H17.5817C17.2126 12.5651 16.9124 12.8653 16.9124 13.2344C16.9124 13.6034 17.2126 13.9037 17.5817 13.9037H18.4185C19.4337 13.9037 20.2597 14.7296 20.2597 15.7448C20.2597 16.7035 19.5229 17.4931 18.5859 17.578V18.2553C18.5859 18.5788 18.3236 18.8412 18 18.8412C17.6764 18.8412 17.414 18.5788 17.414 18.2553V17.5859H16.7448C16.4212 17.5859 16.1588 17.3236 16.1588 17C16.1588 16.6764 16.4212 16.414 16.7448 16.414H18.4185C18.7875 16.414 19.0878 16.1138 19.0878 15.7448C19.0878 15.3758 18.7876 15.0755 18.4185 15.0755H17.5817C16.5664 15.0755 15.7405 14.2496 15.7405 13.2343C15.7406 12.2757 16.4772 11.4862 17.414 11.4012ZM21.1381 21.9792H14.8621C14.5385 21.9792 14.2761 21.7168 14.2761 21.3932C14.2761 21.0696 14.5385 20.8073 14.8621 20.8073H21.1381C21.4616 20.8073 21.724 21.0696 21.724 21.3932C21.724 21.7168 21.4616 21.9792 21.1381 21.9792Z"
                            fill="white"
                          ></path>
                        </svg>
                      </div>
                      <div className="order-type">
                        <h2>Delivery Day</h2>
                        <button className="btn-transparent edit" type="button">
                          Edit
                        </button>
                      </div>
                    </div>
                    <div className="order-inner">
                      <div className="order-icon">
                        <svg
                          width="35"
                          height="35"
                          viewBox="0 0 35 35"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <circle
                            cx="17.5"
                            cy="17.5"
                            r="17.5"
                            fill="#2C6ECB"
                          ></circle>
                          <path
                            d="M21.125 8.25H19.7675C19.51 7.5225 18.815 7 18 7C17.185 7 16.49 7.5225 16.2313 8.25H14.875C14.53 8.25 14.25 8.53 14.25 8.875V11.375C14.25 11.72 14.53 12 14.875 12H21.125C21.47 12 21.75 11.72 21.75 11.375V8.875C21.75 8.53 21.47 8.25 21.125 8.25Z"
                            fill="white"
                          ></path>
                          <path
                            d="M24.25 9.5H23V11.375C23 12.4088 22.1587 13.25 21.125 13.25H14.875C13.8412 13.25 13 12.4088 13 11.375V9.5H11.75C11.0612 9.5 10.5 10.0612 10.5 10.75V25.75C10.5 26.4513 11.0488 27 11.75 27H24.25C24.9513 27 25.5 26.4513 25.5 25.75V10.75C25.5 10.0488 24.9513 9.5 24.25 9.5ZM17.8175 20.5675L15.3175 23.0675C15.195 23.1888 15.035 23.25 14.875 23.25C14.715 23.25 14.555 23.1888 14.4325 23.0675L13.1825 21.8175C12.9387 21.5738 12.9387 21.1775 13.1825 20.9338C13.4262 20.69 13.8225 20.69 14.0663 20.9338L14.875 21.7413L16.9325 19.6838C17.1762 19.44 17.5725 19.44 17.8163 19.6838C18.06 19.9275 18.0613 20.3225 17.8175 20.5675ZM17.8175 15.5675L15.3175 18.0675C15.195 18.1888 15.035 18.25 14.875 18.25C14.715 18.25 14.555 18.1888 14.4325 18.0675L13.1825 16.8175C12.9387 16.5738 12.9387 16.1775 13.1825 15.9337C13.4262 15.69 13.8225 15.69 14.0663 15.9337L14.875 16.7413L16.9325 14.6837C17.1762 14.44 17.5725 14.44 17.8163 14.6837C18.06 14.9275 18.0613 15.3225 17.8175 15.5675ZM22.375 22H19.875C19.53 22 19.25 21.72 19.25 21.375C19.25 21.03 19.53 20.75 19.875 20.75H22.375C22.72 20.75 23 21.03 23 21.375C23 21.72 22.72 22 22.375 22ZM22.375 17H19.875C19.53 17 19.25 16.72 19.25 16.375C19.25 16.03 19.53 15.75 19.875 15.75H22.375C22.72 15.75 23 16.03 23 16.375C23 16.72 22.72 17 22.375 17Z"
                            fill="white"
                          ></path>
                        </svg>
                      </div>
                      <div className="order-type">
                        <h2>First Order</h2>
                        <span class="date">
                          {originOrder
                            ? formatDate(originOrder?.created_at)
                            : 'No orders yet'}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="tabbing-and-adresses">
                  <div className="flex">
                    <div className="col-left-tabbing">
                      <div className="payment-tabs-wrapper padding-20 common-radius bg-white">
                        <Card
                          sectioned
                          title={
                            <div className="heading_title">
                              <PixelIcon />
                              {`Contract Logs`}
                            </div>
                          }
                          actions={{
                            content: (
                              <div className="tabButtons">
                                <HeaderButtons
                                  headerButtons={headerButtons}
                                  setHeaderButton={setHeaderButton}
                                  headerButton={headerButton}
                                />
                              </div>
                            ),
                          }}
                        >
                          <Card.Section title={headerButton != "Delivery-Schedule" && headerButton}>
                            <Tables
                              headerButtons={headerButtons}
                              headerButton={headerButton}
                              apiData={apiData ? apiData : {}}
                              formatDate={formatDate}
                            />
                          </Card.Section>
                        </Card>
                      </div>
                    </div>
                    <div className="col-right-address">
                      <div className="col-4 billing-detaisl bg-white padding-20 common-radius">
                        <div className="flex header-bill">
                          <h1>Billing Information</h1>
                          <div className='change_update_button_group'>
                            <span className="edit_button">Change</span>
                            <span className="edit_button">Update</span>
                          </div>
                        </div>
                        {apiData?.payment_method?.data && paymentObj?.card && (
                          <>
                            <div class="visa-section">
                              {apiData?.payment_method?.data
                                ?.customerPaymentMethod?.instrument
                                ?.__typename ==
                                'CustomerPaypalBillingAgreement' ? (
                                <>
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="84"
                                    height="57"
                                    viewBox="0 0 204.49 190.5"
                                  >
                                    <g transform="matrix(.852554 0 0 .852554 680.18141 153.25756)">
                                      <path
                                        clip-path="none"
                                        d="M-788.334-20.913a1.42 1.42 0 0 0-1.399 1.197l-8.067 51.163a1.31 1.31 0 0 0 1.292 1.514h9.569a1.42 1.42 0 0 0 1.399-1.195l2.368-15.025a1.42 1.42 0 0 1 1.399-1.195h8.697c10.165 0 18.792-7.415 20.368-17.465 1.59-10.133-6.325-18.973-17.547-18.994zm9.301 11.422h6.96c5.73 0 7.594 3.38 7.004 7.119-.59 3.747-3.485 6.507-9.029 6.507h-7.084zm45.787 3.48c-2.416.009-5.197.503-8.318 1.803-7.158 2.983-10.597 9.151-12.056 13.647 0 0-4.647 13.717 5.852 21.254 0 0 9.736 7.253 20.698-.449l-.188 1.203a1.31 1.31 0 0 0 1.292 1.514h9.083c.696 0 1.288-.508 1.397-1.197l5.526-35.037a1.31 1.31 0 0 0-1.292-1.514h-9.083c-.698 0-1.29.508-1.399 1.195l-.296 1.885h-.002s-3.966-4.331-11.215-4.304zm.297 11.067c1.043 0 1.997.142 2.852.418 3.918 1.259 6.142 5.021 5.498 9.103-.793 5.026-4.914 8.728-10.198 8.728-1.043 0-1.999-.143-2.854-.418-3.918-1.259-6.155-5.023-5.51-9.105.793-5.024 4.928-8.726 10.212-8.726z"
                                        fill="#003087"
                                      />
                                      <path
                                        clip-path="none"
                                        d="M-657.536-20.913c-.696 0-1.288.508-1.397 1.197l-8.069 51.163a1.31 1.31 0 0 0 1.294 1.514h9.569c.696 0 1.288-.507 1.397-1.195l2.37-15.025a1.42 1.42 0 0 1 1.399-1.195h8.697c10.165 0 18.792-7.415 20.368-17.465 1.59-10.133-6.327-18.973-17.549-18.994zm9.301 11.422h6.96c5.73 0 7.596 3.38 7.006 7.119-.59 3.747-3.486 6.507-9.031 6.507h-7.084zm45.789 3.48c-2.416.009-5.197.503-8.318 1.803-7.158 2.983-10.599 9.151-12.058 13.647 0 0-4.646 13.717 5.854 21.254 0 0 9.736 7.253 20.698-.449l-.19 1.203a1.31 1.31 0 0 0 1.294 1.514h9.081a1.42 1.42 0 0 0 1.399-1.197l5.526-35.037a1.31 1.31 0 0 0-1.294-1.514h-9.081c-.698 0-1.29.508-1.399 1.195l-.297 1.885s-3.966-4.331-11.215-4.304zm.295 11.067c1.043 0 1.999.142 2.854.418 3.918 1.259 6.14 5.021 5.497 9.103-.793 5.026-4.914 8.728-10.198 8.728-1.043 0-1.997-.143-2.852-.418-3.918-1.259-6.155-5.023-5.51-9.105.792-5.024 4.926-8.726 10.21-8.726z"
                                        fill="#0070e0"
                                      />
                                      <path
                                        clip-path="none"
                                        d="M-705.651-4.787a1.06 1.06 0 0 0-1.01 1.387l9.956 30.9-9.004 14.564c-.436.707.071 1.618.903 1.618h10.639a1.77 1.77 0 0 0 1.515-.853l27.807-46.007c.428-.707-.081-1.611-.907-1.611h-10.639a1.77 1.77 0 0 0-1.523.867l-10.946 18.483-5.557-18.345c-.182-.597-.731-1.004-1.356-1.004z"
                                        fill="#003087"
                                      />
                                      <path
                                        clip-path="none"
                                        d="M-568.839-20.913c-.696 0-1.288.508-1.397 1.197l-8.069 51.163a1.31 1.31 0 0 0 1.294 1.514h9.567c.696 0 1.288-.507 1.397-1.195l8.071-51.165a1.31 1.31 0 0 0-1.296-1.514z"
                                        fill="#0070e0"
                                      />
                                      <path
                                        clip-path="none"
                                        d="M-687.026-152.855c-1.907 0-3.532 1.387-3.829 3.272l-6.365 40.358-5.848 37.085-.004.031.007-.031 5.848-37.085c.297-1.885 1.92-3.272 3.828-3.272h18.646c18.765 0 34.693-13.691 37.601-32.243a30.77 30.77 0 0 0 .367-4.183v-.002h-.002c-4.769-2.502-10.369-3.931-16.505-3.931z"
                                        fill="#001c64"
                                      />
                                      <path
                                        clip-path="none"
                                        d="M-636.776-148.925c-.026 1.378-.146 2.775-.367 4.185-2.909 18.552-18.836 32.243-37.601 32.243h-18.646c-1.907 0-3.53 1.387-3.828 3.272l-5.848 37.085-3.67 23.267a3.15 3.15 0 0 0 3.111 3.639h20.239c1.909 0 3.532-1.387 3.83-3.272l5.331-33.813a3.88 3.88 0 0 1 3.829-3.274h11.918c18.765 0 34.691-13.69 37.599-32.241 2.065-13.17-4.562-25.15-15.897-31.091z"
                                        fill="#0070e0"
                                      />
                                      <path
                                        clip-path="none"
                                        d="M-710.02-179.763a3.88 3.88 0 0 0-3.829 3.274l-15.882 100.708a3.15 3.15 0 0 0 3.111 3.641h23.552l5.848-37.085 6.365-40.358c.297-1.885 1.922-3.272 3.829-3.272h33.743c6.137 0 11.737 1.43 16.507 3.929.325-16.89-13.612-30.836-32.775-30.836z"
                                        fill="#003087"
                                      />
                                    </g>
                                  </svg>
                                  {paymentObj?.card && (
                                    <div className="Polaris-Stack__Item">
                                      <p>
                                        Email
                                        <span className="Polaris-TextStyle--variationStrong paypal_email">
                                          {' '}
                                          {
                                            paymentObj?.card?.paypalAccountEmail
                                          }{' '}
                                        </span>
                                      </p>
                                      <p>
                                        Status
                                        <span
                                          className={`paypal_status ${paymentObj?.card?.inactive
                                            ? 'inactive'
                                            : 'active'
                                            }`}
                                        >
                                          {paymentObj?.card?.inactive
                                            ? 'Inactive'
                                            : 'Active'}
                                        </span>
                                      </p>
                                    </div>
                                  )}
                                </>
                              ) : paymentObj?.card?.__typename ==
                                'CustomerCreditCard' ||
                                paymentObj?.__typename ==
                                'CustomerCreditCard' ? (
                                <div className='card_logo_wrapper'>
                                  <img
                                    class="card_logo"
                                    src={
                                      paymentObj?.card?.brand?.toLowerCase() ==
                                        'visa'
                                        ? visaLogo
                                        : masterLogo
                                    }
                                  />
                                  <p>{paymentObj?.card?.brand}</p>
                                </div>

                              ) : paymentObj?.card.__typename ==
                                'CustomerShopPayAgreement' ? (
                                <div
                                  className="shop_pay_agreement_main"
                                  style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: '20px',
                                  }}
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    version="1.0"
                                    width="84"
                                    height="47"
                                    viewBox="0 0 480.000000 302.000000"
                                    preserveAspectRatio="xMidYMid meet"
                                    style={{ fill: 'blue' }}
                                  >
                                    <g
                                      transform="translate(0.000000,302.000000) scale(0.100000,-0.100000)"
                                      fill="#6724f0"
                                      stroke="none"
                                    >
                                      <path
                                        style={{ fill: '#6724f0' }}
                                        d="M319 3006 c-101 -27 -174 -91 -221 -192 l-23 -49 0 -1225 c0 -958 3 -1234 13 -1267 19 -63 72 -134 126 -171 100 -67 -60 -62 2206 -60 l2055 3 56 28 c74 36 140 108 168 180 l21 58 0 1219 c0 1205 0 1220 -21 1274 -27 73 -96 148 -167 183 l-57 28 -2055 2 c-1607 1 -2065 -1 -2101 -11z m876 -761 c117 -25 207 -75 294 -163 188 -190 232 -461 115 -702 -187 -381 -764 -403 -945 -35 -33 67 -34 74 -34 185 0 110 2 118 34 187 23 51 51 89 89 125 l55 52 47 -52 c26 -29 53 -63 60 -77 12 -23 10 -28 -27 -68 -52 -56 -67 -102 -61 -188 4 -57 11 -75 39 -115 21 -28 56 -59 88 -77 47 -26 68 -32 140 -35 70 -3 97 0 142 17 78 30 132 71 173 133 108 163 78 392 -68 526 -154 140 -412 136 -596 -9 -27 -21 -56 -39 -64 -39 -18 0 -106 120 -106 144 0 24 95 95 185 137 139 65 295 85 440 54z m1306 -76 c234 -110 280 -450 86 -624 -76 -69 -135 -87 -309 -93 l-148 -5 0 -152 c0 -174 5 -165 -90 -165 -100 0 -90 -57 -90 534 0 288 3 526 7 530 5 4 116 6 248 4 235 -3 241 -4 296 -29z m764 -336 c51 -27 89 -67 112 -118 15 -34 18 -80 21 -307 l4 -267 -24 -6 c-13 -3 -51 -5 -83 -3 -51 3 -60 6 -63 23 -7 46 -11 47 -49 16 -47 -36 -99 -51 -184 -51 -90 0 -149 22 -198 75 -45 48 -56 87 -49 171 5 60 38 110 98 151 48 33 90 43 243 55 l137 12 0 28 c0 38 -25 84 -53 100 -12 6 -51 12 -87 12 -73 0 -121 -21 -139 -61 -10 -23 -15 -24 -93 -21 -82 3 -83 3 -80 28 6 42 51 109 93 137 62 41 121 53 244 50 89 -2 118 -7 150 -24z m397 4 c7 -8 53 -121 102 -253 91 -244 103 -271 117 -257 4 4 45 121 90 258 45 138 85 252 89 255 4 3 43 3 86 0 67 -4 79 -8 76 -22 -6 -35 -313 -842 -332 -874 -56 -90 -157 -144 -271 -144 -88 0 -89 1 -89 85 l0 73 74 4 c90 4 120 22 155 91 l23 47 -142 366 c-115 295 -140 368 -129 375 22 14 136 11 151 -4z"
                                      />
                                      <path
                                        style={{ fill: '#6724f0' }}
                                        d="M2132 1828 l3 -213 115 0 c137 0 158 5 206 48 88 76 99 208 26 306 -42 56 -89 71 -230 71 l-122 0 2 -212z"
                                      />
                                      <path
                                        style={{ fill: '#6724f0' }}
                                        d="M3032 1440 c-87 -13 -119 -46 -110 -115 8 -67 133 -94 223 -50 46 23 85 88 85 142 l0 33 -72 -1 c-40 -1 -97 -5 -126 -9z"
                                      />
                                    </g>
                                  </svg>
                                  <div className="shop_pay_name">
                                    Name
                                    <span className="Polaris-TextStyle--variationStrong paypal_email">
                                      {paymentObj?.card?.name}{' '}
                                    </span>
                                  </div>
                                </div>
                              ) : (
                                <svg
                                  class="card_logo"
                                  width="84"
                                  height="57"
                                  viewBox="0 0 84 57"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M73.3681 0.340088H10.6319C4.76952 0.340088 0 5.10961 0 10.9719V45.7789C0 51.6412 4.76952 56.4107 10.6319 56.4107H73.3681C79.2305 56.4107 84 51.6412 84 45.7789V10.9719C84 5.10961 79.2305 0.340088 73.3681 0.340088ZM10.6319 5.64582H73.3681C76.305 5.64582 78.6943 8.03506 78.6943 10.9719V13.0647H5.30573V10.9719C5.30573 8.03506 7.69497 5.64582 10.6319 5.64582ZM78.6943 24.9292H5.30573V18.3705H78.6943V24.9292ZM73.3681 51.105H10.6319C7.69497 51.105 5.30573 48.7157 5.30573 45.7789V30.2349H78.6943V45.7789C78.6943 48.7157 76.305 51.105 73.3681 51.105Z"
                                    fill="#000000"
                                  />
                                  <path
                                    d="M69.4492 44.4566C70.9949 44.4566 72.2479 43.2035 72.2479 41.6578C72.2479 40.1121 70.9949 38.859 69.4492 38.859C67.9034 38.859 66.6504 40.1121 66.6504 41.6578C66.6504 43.2035 67.9034 44.4566 69.4492 44.4566Z"
                                    fill="#212B36"
                                  />
                                  <path
                                    d="M61.6018 44.4566C63.1475 44.4566 64.4005 43.2035 64.4005 41.6578C64.4005 40.1121 63.1475 38.859 61.6018 38.859C60.056 38.859 58.803 40.1121 58.803 41.6578C58.803 43.2035 60.056 44.4566 61.6018 44.4566Z"
                                    fill="#212B36"
                                  />
                                </svg>
                              )}
                              {paymentObj?.card && (
                                <div class="visa-text">
                                  <p>
                                    {paymentObj?.card?.__typename ==
                                      'CustomerShopPayAgreement' ? (
                                      <span
                                        className="Polaris-TextStyle--variationStrong"
                                        style={{ marginLeft: '0.5rem' }}
                                      >
                                        .... .... .... {paymentObj?.card?.maskedNumber}
                                      </span>
                                    ) : (
                                      <>
                                        <span
                                          className="no Polaris-TextStyle--variationStrong"
                                          style={{ marginLeft: '0.5rem' }}
                                        >
                                          {
                                            paymentObj?.card?.lastDigits?.match(
                                              /\d+/
                                            )?.[0]
                                          }
                                        </span>
                                      </>
                                    )}
                                  </p>
                                  <p>
                                    Exp. Date
                                    <span class="expiry-date">
                                      <b>
                                        {paymentObj?.card?.expiryMonth}/
                                        {paymentObj?.card?.expiryYear}
                                      </b>
                                    </span>
                                  </p>
                                </div>
                              )}
                            </div>
                          </>
                        )}
                        {/* <div className="total-spend">
                          <p>
                            <i>TOTAL SPEND TO DATE:</i>
                            <br />
                            {apiData?.subscription?.currency_code}{' '}
                            {apiData?.total_spent}
                          </p>
                        </div> */}
                      </div>
                      <div className="card-charzen delivery-address padding-20 common-radius bg-white">
                        <div className="billing-address">
                          <h3>BILLING ADDRESS</h3>
                          <p>
                            {apiData?.origin_order
                              ? `${apiData?.origin_order?.billing_address
                                ?.address1 || ''
                              } ${apiData?.origin_order?.billing_address
                                ?.city || ''
                              } ${apiData?.origin_order?.billing_address
                                ?.province || ''
                              } ${apiData?.origin_order?.billing_address?.zip ||
                              ''
                              } ${apiData?.origin_order?.billing_address
                                ?.country || ''
                              }`
                              : `${apiData?.customer?.address1 || ''} ${apiData?.customer?.city || ''
                              } ${apiData?.customer?.state || ''} ${apiData?.customer?.zip_code || ''
                              } ${apiData?.customer?.country || ''}`}
                          </p>
                        </div>
                        <div className="delivery-address">
                          <h3>DELIVERY ADDRESS</h3>
                          <p>
                            {apiData?.origin_order
                              ? `${apiData?.origin_order?.shipping_address
                                ?.address1 || ''
                              } ${apiData?.origin_order?.shipping_address
                                ?.city || ''
                              } ${apiData?.origin_order?.shipping_address
                                ?.province || ''
                              } ${apiData?.origin_order?.shipping_address
                                ?.zip || ''
                              } ${apiData?.origin_order?.shipping_address
                                ?.country || ''
                              }`
                              : `${apiData?.customer?.address1 || ''} ${apiData?.customer?.city || ''
                              } ${apiData?.customer?.state || ''} ${apiData?.customer?.zip_code || ''
                              } ${apiData?.customer?.country || ''}`}
                          </p>
                        </div>
                      </div>
                      <div className="card-charzen delivery-shedule padding-20 common-radius bg-white cancelation-col">
                        {
                          apiData?.contract?.status === 'CANCELLED' ?
                            <>
                              <h2>
                                <span>Cancellation reason:</span>
                              </h2>
                              <div class="delivery-shedule-info">{apiData?.cancellation_reason?.title}</div>
                              <p className='remark-text'><strong>Remark</strong></p>
                              <p className="remark-sub" dangerouslySetInnerHTML={{ __html: apiData?.cancellation_reason?.custom_message_for_cancellation || '' }} />
                            </>
                            :
                            <>
                              <h2>
                                <span>Admin notes</span>
                                <span className="edit_button">Edit</span>
                              </h2>
                              <div class="delivery-shedule-info">No notes added</div>
                            </>
                        }
                      </div>
                    </div>
                  </div>
                </div>
              </Page>
            </Frame>
          </div>
        </>
      )}
    </Frame>
  );
}
export default SubscriptionView;
