import { TextField } from '@shopify/polaris';
import React, { useState } from 'react'

function DisconnectApi(props) {
    const {
        closeModal,
        item,
        setSaveSuccess,
        updateMutation,
        fetchIntegrations,
        mapCategory
    } = props
    const [loading, setLoading] = useState(false);
    const handleSubmit = () => {
        if (!loading) {
            setLoading(true)
            updateMutation({
                variables: {
                    input: {
                        params: {
                            id: item.id,
                            credentials: {}
                        }
                    }
                }
            }).then(res => {
                setLoading(false);
                if (!res.data.errors) {
                    fetchIntegrations({
                        variables: {
                            type: mapCategory,
                        },
                    });
                    setSaveSuccess(true);
                    closeModal();
                }
            })
        }
    }
    return (
        <div className="create_billing modal-popup-cstm">
            <div className="modal-dialouge">
                <div className="Modal-header">
                    <h2>Disconnect Integration</h2>
                    <button
                        class="close-btn"
                        onClick={closeModal}
                    >
                        <svg
                            aria-hidden="true"
                            class="Polaris-Icon__Svg"
                            focusable="false"
                            viewBox="0 0 20 20"
                        >
                            <path d="m11.414 10 6.293-6.293a1 1 0 1 0-1.414-1.414l-6.293 6.293-6.293-6.293a1 1 0 0 0-1.414 1.414l6.293 6.293-6.293 6.293a1 1 0 1 0 1.414 1.414l6.293-6.293 6.293 6.293a.998.998 0 0 0 1.707-.707.999.999 0 0 0-.293-.707l-6.293-6.293z"></path>
                        </svg>
                    </button>
                </div>
                <div className="modal-content">
                    <p>
                        Are you sure you want to disconnect the integration?
                    </p>
                </div>
                <div className="modal-footer">
                    <button
                        className="btn-red"
                        onClick={closeModal}
                    >
                        Cancel
                    </button>
                    <button
                        className="btn-green"
                        onClick={handleSubmit}
                    >
                        {loading ? <div class="chargezen_button_spinner"></div> : <>Disconnect</>}
                    </button>

                </div>
            </div>
            <div className="modal-cstm-backdrop"></div>
        </div>
    );
}
function UpdateApiKey(props) {
    const {
        setSaveSuccess,
        item,
        updateMutation,
        closeModal,
        updateApiModal,
        credentials,
        fetchIntegrations,
        mapCategory
    } = props;

    const [loading, setLoading] = useState(false);
    const fieldKey = updateApiModal?.fieldKey
    const [formData, setFormData] = useState({ ...credentials, [fieldKey]: updateApiModal?.fieldValue || '' })

    const handleSubmit = () => {
        if (!loading) {
            setLoading(true)
            updateMutation({
                variables: {
                    input: {
                        params: {
                            id: item.id,
                            credentials: {
                                ...formData
                            }
                        }
                    }
                }
            }).then(res => {
                setLoading(false);
                if (!res.data.errors) {
                    fetchIntegrations({
                        variables: {
                            type: mapCategory,
                        },
                    });
                    setSaveSuccess(true);
                    closeModal();
                }
            })
        }
    }
    return (
        <div className="create_billing modal-popup-cstm">
            <div className="modal-dialouge">
                <div className="Modal-header">
                    <h2>Update API Key</h2>
                    <button
                        class="close-btn"
                        onClick={closeModal}
                    >
                        <svg
                            aria-hidden="true"
                            class="Polaris-Icon__Svg"
                            focusable="false"
                            viewBox="0 0 20 20"
                        >
                            <path d="m11.414 10 6.293-6.293a1 1 0 1 0-1.414-1.414l-6.293 6.293-6.293-6.293a1 1 0 0 0-1.414 1.414l6.293 6.293-6.293 6.293a1 1 0 1 0 1.414 1.414l6.293-6.293 6.293 6.293a.998.998 0 0 0 1.707-.707.999.999 0 0 0-.293-.707l-6.293-6.293z"></path>
                        </svg>
                    </button>
                </div>
                <div className="modal-content">
                    <p>
                        <TextField
                            name={fieldKey}
                            label={_.startCase(fieldKey)}
                            value={formData[fieldKey]}
                            onChange={(value) => setFormData({ ...formData, [fieldKey]: value })}
                        />
                    </p>
                </div>
                <div className="modal-footer">
                    <button
                        className="btn-red"
                        onClick={closeModal}
                    >
                        Cancel
                    </button>
                    <button
                        className="btn-green"
                        onClick={handleSubmit}
                    >
                        {loading ? <div class="chargezen_button_spinner"></div> : <>Save</>}
                    </button>

                </div>
            </div>
            <div className="modal-cstm-backdrop"></div>
        </div>
    );
}
export { UpdateApiKey, DisconnectApi }