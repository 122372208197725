import {
  Button,
  ButtonGroup,
  Card,
  ProgressBar,
  Page,
  Layout,
  Icon,
} from '@shopify/polaris';
import React, { useCallback, useEffect, useState } from 'react';
import ContentSummaryGraph from './HomeComponents/ContentSummaryGraph';
import PieChart from './HomeComponents/ContentSummaryGraph/PieChart';
import MultiColorProgressBar from './HomeComponents/MultiColorProgressBar';
import {
  ThumbsUpMajor,
  StoreStatusMajor,
  SmileyJoyMajor,
} from '@shopify/polaris-icons';
import AppLayout from '../layout/Layout';
import './style.css';
import dashboardSubIcon from './../../images/dashboardSubIcon.png';
import RevenueHighlight from './RevenueHighlight';
import { gql, useLazyQuery } from '@apollo/client';
import dayjs from 'dayjs';
import { isEmpty } from 'lodash';
import PixelIcon from '../../images/PixelIcon';
import SubHeader from './SubHeader';
import SetupGuide from './SetupGuide';
import ChatWithUs from './ChatWithUs';

const NewDashboard = (props) => {
  var today = new Date();
  const { i18n, customNavTabs, floatingSetupGuide, setFloatingSetupGuide } =
    props;
  const [filters, setFilters] = useState({
    startDate: new Date(new Date().setDate(today.getDate() - 31)),
    endDate: new Date(new Date().setDate(today.getDate() - 1)),
  });

  const [fetchDashboardReport, setFetchDashboardReport] = useState({});
  const getGraphDataQuery = gql`
    query ($startDate: String!, $endDate: String!) {
      fetchDashboardReport(startDate: $startDate, endDate: $endDate) {
        todayOrders
        lastWeekOrders
        lastWeekOrderRevenue
        todayOrderRevenue
        upcomingSales
        currencySymbol
        currency
        mrr {
          value
          percent
          up
        }
        activeSubscriptionsCount {
          value
          percent
          up
        }
        churnRate {
          value
          percent
          up
        }
        customerLifetimeValue {
          value
          percent
          up
        }
        activeCustomers {
          data {
            value
          }
          date
        }
        revenueChurn {
          date
          data {
            value
          }
        }
        arrData {
          date
          data {
            value
          }
        }
        mrrData {
          date
          data {
            value
          }
        }
        refundData {
          date
          data {
            value
          }
        }
        salesData {
          date
          data {
            value
          }
        }
        renewalData {
          date
          data {
            value
          }
        }
      }
    }
  `;

  const [getReport, { loading, error, data }] = useLazyQuery(
    getGraphDataQuery,
    { fetchPolicy: 'network-only' }
  );

  const getReportData = useCallback(() => {
    getReport({
      variables: {
        startDate: filters.startDate,
        endDate: filters.endDate,
      },
    });
  }, [filters, getReport]);

  useEffect(() => {
    getReportData();
  }, [filters, dateFilters]);

  const handleFiltersDates = (dates, span) => {
    console.log('hahah');
    if (!isEmpty(dates)) {
      const { start, end } = dates;
      setFilters({
        startDate: dayjs(start).format('YYYY-MM-DD'),
        endDate: dayjs(end).format('YYYY-MM-DD'),
        span: span,
      });
    }
  };

  useEffect(() => {
    if (!isEmpty(data?.fetchDashboardReport)) {
      setFetchDashboardReport(data?.fetchDashboardReport);
    }
  }, [data]);

  const [dateFilters, setDateFilters] = useState({
    startDate: '',
    endDate: '',
  });

  const calculateValue = (arr) => {
    let value = 0;
    arr?.map((val) => {
      value += +val?.data?.value;
    });
    return +value;
  };
  const subHeaders = [
    { title: 'Orders', value: 0, desc: '2 last 7days' },
    { title: 'Sales', value: 'A$0 AUD', desc: 'A$270 AUD last 7 days' },
    { title: 'Upcoming Sales', value: 'A$0 AUD', desc: 'next 7 days' },
  ];

  return (
    <>
      <Page>
        <Layout>
          <Layout.Section></Layout.Section>
          <div className="dashboard_main_div">
            <div className="dashboard_left">
              <div className="dashboard_header">
                <div className="main_head">{i18n.t('dashboard')}</div>
                <div className="sub_head">
                  {i18n.t('welcome_back')},{' '}
                  {localStorage.getItem('currentuser_first_name') || '--'}!
                </div>
              </div>
              <div className="dashboard_sub_header">
                <SubHeader
                  title={'MRR'}
                  value={`${
                    fetchDashboardReport?.mrr?.value
                      ? `${fetchDashboardReport?.currencySymbol}${parseFloat(
                          fetchDashboardReport?.mrr?.value
                        )?.toFixed(2)}`
                      : '--'
                  }`}
                  desc={``}
                />
                <SubHeader
                  title={i18n.t('sales')}
                  value={
                    fetchDashboardReport?.todayOrderRevenue
                      ? `${fetchDashboardReport?.currencySymbol}${parseFloat(
                          fetchDashboardReport?.todayOrderRevenue
                        )?.toFixed(2)}`
                      : '--'
                  }
                  desc={i18n.t('content_last_seven_days', {
                    content: fetchDashboardReport?.lastWeekOrderRevenue
                      ? `${fetchDashboardReport?.currencySymbol}${parseFloat(
                          fetchDashboardReport?.lastWeekOrderRevenue
                        )?.toFixed(2)} ${fetchDashboardReport?.currency}`
                      : '--',
                  })}
                />
                <SubHeader
                  title={i18n.t('upcoming_sales')}
                  value={
                    fetchDashboardReport?.upcomingSales
                      ? `${fetchDashboardReport?.currencySymbol}${parseFloat(
                          fetchDashboardReport?.upcomingSales
                        )?.toFixed(2)}`
                      : '--'
                  }
                  desc={i18n.t('next_seven_days')}
                />
              </div>
              <div className="dashboard_content">
                <div className="schedule_content_main">
                  <div className="top_section">
                    <div className="revenue_overview_div">
                      {/* <Card
                                                title={
                                                    <div className="heading_title">
                                                        <PixelIcon />
                                                        {i18n.t("revenue_overview")}
                                                    </div>
                                                }
                                                actions={{
                                                    content:
                                                        <ButtonGroup>
                                                            <Button
                                                                onClick={() => {
                                                                    setDateFilters({
                                                                        ...dateFilters,
                                                                        startDate: dayjs(dayjs(dayjs(dayjs(new Date()).subtract(1, "days")).subtract(30, 'days'))).format(),
                                                                        endDate: dayjs(new Date()).subtract(1, "days").format()
                                                                    })
                                                                }}
                                                            >{i18n.t("subscriptions")}</Button>
                                                            <Button
                                                                onClick={() => {
                                                                    setDateFilters({
                                                                        ...dateFilters,
                                                                        startDate: new Date(Date.parse(dayjs(dayjs(dayjs(dayjs(new Date()).subtract(1, "days")).subtract(12, 'month'))).format())),
                                                                        endDate: dayjs(new Date()).subtract(1, "days").format()
                                                                    })
                                                                }}
                                                            >{i18n.t("replenish_me")}</Button>
                                                            <Button
                                                                onClick={() => {
                                                                    setDateFilters({
                                                                        ...dateFilters,
                                                                        startDate: dayjs(dayjs(dayjs(dayjs(new Date()).subtract(1, "days")).subtract(3, 'month'))).format(),
                                                                        endDate: dayjs(new Date()).subtract(1, "days").format()
                                                                    })
                                                                }}
                                                            >{i18n.t("preorders")}</Button>
                                                        </ButtonGroup>
                                                }}
                                            >
                                                <Card.Section>
                                                    <div className="dashboard_right">
                                                        <ContentSummaryGraph i18n={i18n} fetchDashboardReport={fetchDashboardReport} />

                                                        <div className="right_section">
                                                            <div className="right_section_sub">
                                                                <div className="mrr_div">
                                                                    <div className="mrr_first">${fetchDashboardReport?.mrr?.value ? parseFloat(fetchDashboardReport?.mrr?.value)?.toFixed(2) : "--"}</div>
                                                                    <div className="mrr_second">MRR</div>
                                                                </div>
                                                                <div className="mrr_div">
                                                                    <div className="mrr_first">{fetchDashboardReport?.activeSubscriptionsCount?.value || "--"}</div>
                                                                    <div className="mrr_second">
                                                                        <span className="subs_icon" >
                                                                            {i18n.t("active_subscriptions")}

                                                                        </span>
                                                                    </div>
                                                                </div>
                                                                <div className="mrr_div">
                                                                    <div className="mrr_first">{fetchDashboardReport?.activeSubscriptionsCount?.percent || "--"}%</div>
                                                                    <div className="mrr_second">
                                                                        <span className="subs_icon pink" >
                                                                            {i18n.t("churn_rate")}

                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="report">
                                                                <div className="report_icon">
                                                                    <Icon
                                                                        source={SmileyJoyMajor}
                                                                        color="base"
                                                                    />
                                                                </div>
                                                                <div className="report_text">
                                                                    <h2>{i18n.t("you_are_doing_good")}</h2>
                                                                    <h6>{i18n.t("you_reach_performance_last_year", { percent: 12 })}</h6>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Card.Section>
                                            </Card> */}
                      <SetupGuide
                        {...{
                          customNavTabs,
                          floatingSetupGuide,
                          setFloatingSetupGuide,
                        }}
                      />
                    </div>
                  </div>
                  <div className="bottom_section">
                    <ChatWithUs />
                    <div className="left_section hidden">
                      <div className="main_head">
                        {i18n.t('revenue_higlights')}
                      </div>
                      <div className="body">
                        <RevenueHighlight
                          title={i18n.t('customer_lifetime_value')}
                          value={
                            data?.fetchDashboardReport?.customerLifetimeValue
                              ?.value
                              ? `${fetchDashboardReport?.currencySymbol} ${
                                  data?.fetchDashboardReport
                                    ?.customerLifetimeValue?.value || '--'
                                }`
                              : '--'
                          }
                          percent={
                            data?.fetchDashboardReport?.customerLifetimeValue
                              ?.percent || '--'
                          }
                        />
                        <RevenueHighlight
                          title={i18n.t('sales')}
                          value={
                            data?.fetchDashboardReport?.salesData
                              ? `${
                                  fetchDashboardReport?.currencySymbol
                                }${parseFloat(
                                  calculateValue(
                                    data?.fetchDashboardReport?.salesData
                                  )?.toFixed(2)
                                )}`
                              : '--'
                          }
                          percent={null}
                        />
                        <RevenueHighlight
                          title={i18n.t('refunds')}
                          value={
                            data?.fetchDashboardReport?.refundData
                              ? `${
                                  fetchDashboardReport?.currencySymbol
                                }${parseFloat(
                                  calculateValue(
                                    data?.fetchDashboardReport?.refundData
                                  )?.toFixed(2)
                                )}`
                              : ''
                          }
                          percent={null}
                        />
                        <RevenueHighlight
                          title={i18n.t('renewal_rate')}
                          value={
                            calculateValue(
                              data?.fetchDashboardReport?.renewalData
                            ) || ''
                          }
                          percent={null}
                        />
                        <RevenueHighlight
                          title={'ARR'}
                          value={
                            data?.fetchDashboardReport?.arrData
                              ? `${
                                  fetchDashboardReport?.currencySymbol
                                }${parseFloat(
                                  calculateValue(
                                    data?.fetchDashboardReport?.arrData
                                  )?.toFixed(2)
                                )}`
                              : '--'
                          }
                          percent={null}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Layout>
      </Page>
    </>
  );
};

export default NewDashboard;
