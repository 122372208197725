import React, { useState, useEffect, useCallback } from 'react';
import ReactDOM from 'react-dom';
import { Link, useHistory, useLocation, useParams } from 'react-router-dom';
import AppLayout from '../layout/Layout';
import { gql, useLazyQuery, useMutation } from '@apollo/client';
import {
  Page,
  EmptyState,
  Frame,
  CalloutCard,
  Card,
  Icon,
  Stack,
  Badge,
  DisplayText,
  Button,
  Layout,
  Tabs,
  Heading,
  TextField,
  Toast,
  Select
} from '@shopify/polaris';

import getStart from 'images/get_start.svg';
import integrations from '../../lib/integrations';
import _ from 'lodash';

const IntegrationDetail = ({ id, title, keys, handleClose, i18n, credentials }) => {
  console.log("id   ---asdad: ", id, title)
  const updateQuery = gql`
  mutation ($input: UpdateIntegrationInput!) {
    updateIntegration(input: $input) {
        integration {
            id
            name
            integrationType
            serviceType
            default
            credentials{
              privateKey
              publicKey
            }
            status
        }
    }
}
  `;
  //   const getQuery=gql`
  //   query($type:String!){
  //     fetchIntegration(id: "9") {
  //       id
  //       name
  //       integrationType
  //       serviceType
  //       default
  //       credentials
  //       status
  //       keys
  //   }
  // }
  //   `;

  const history = useHistory();
  // let { id, title, keys } = useParams();



  let tabs = integrations.map((item, index) => ({
    content: item.title,
    id: index
  }))

  tabs = [
    // {content: 'All', id: 'all' }
  ].concat(tabs)

  let category = integrations.filter((item) => item.id == id)[0];
  // let item = category.data.filter((item) => item.title == title)[0];

  const [selected, setSelected] = useState(0);
  const [listOptions, setListOptions] = useState([]);
  const [formData, setFormData] = useState(/*fieldKeys.reduce((acc,curr)=> (acc[curr]='',acc),{})*/{ ...location?.state?.credentials })

  const getActiveCampaignLists = (apiKey, apiEndpoint) => {
    // Check that both the API key and endpoint have been entered
    console.log("formData?.activeCampaignApiEndpoint", apiEndpoint);

    fetch(`/activeCampaignFetchLists?api_key=${apiKey}&api_endpoint=${apiEndpoint}`, {
      method: 'Get',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    })
      .then(response => response.json())
      .then((data) => {
        let list_options = data?.data
        list_options.unshift({ label: "Select a list", value: '' })
        setListOptions(list_options);
      })
      .catch((error) => {
        // Handle any errors that may occur during the fetch request
        console.error('Error fetching ActiveCampaign lists:', error);
      });

  };

  useEffect(() => {
    if (formData?.activeCampaignApiKey, formData?.activeCampaignApiEndpoint) {
      console.log("formData?.activeCampaignApiKey, formData?.activeCampaignApiEndpoint", formData?.activeCampaignApiKey, formData?.activeCampaignApiEndpoint);
      getActiveCampaignLists(formData?.activeCampaignApiKey, formData?.activeCampaignApiEndpoint)
    }
  }, [formData?.activeCampaignApiKey, formData?.activeCampaignApiEndpoint])

  useEffect(() => {
    if (credentials['activeCampaignApiKey'], credentials['activeCampaignApiEndpoint']) {
      setFormData(credentials)
      console.log("credentials['activeCampaignApiKey'], credentials['activeCampaignApiEndpoint']", credentials['activeCampaignApiKey'], credentials['activeCampaignApiEndpoint']);
      getActiveCampaignLists(credentials['activeCampaignApiKey'], credentials['activeCampaignApiEndpoint'])
    }
  }, [])

  const handleTabChange = useCallback(
    (selectedTabIndex) => setSelected(selectedTabIndex),
    [],
  );
  const location = useLocation();
  let fieldKeys = keys ? keys.split(",").map(field => _.camelCase(field)) : [];

  const [updateMutation, { data, loading }] = useMutation(updateQuery);

  const [saveSuccess, setSaveSuccess] = useState(false);
  const hideSaveSuccess = useCallback(() => setSaveSuccess(false), []);


  const handleSubmit = () => {
    updateMutation({
      variables: {
        input: {
          params: {
            id: id,
            credentials: {
              ...formData
            }
          }
        }
      }
    }).then(res => {
      if (!res.data.errors) {
        setSaveSuccess(true);
      }
    })
  }

  return (
    <>
      <Button>
        <img src={getStart} width='20' />
        <span>Get Started</span>
      </Button>

      <Page fullWidth title={process.env.APP_TYPE == "public" ? i18n.t('integerate_with_chargezen') : i18n.t('integerations')} breadcrumbs={[{
        content: title, onAction: () => handleClose()
      }]}>
        <Layout>
          <Layout.Section>
            <Card>
              <Tabs tabs={tabs} selected={selected} onSelect={handleTabChange}>
              </Tabs>
            </Card>
          </Layout.Section>

          <Layout.Section>
            <Heading>{title}</Heading>
            <div className='roundedCard'>
              <Card sectioned>
                <Stack alignment="center">
                  <Stack.Item>
                    <img src={require(`images/${title?.split(" ").join("").toLowerCase()}`)} style={{ maxWidth: "80px" }} />
                  </Stack.Item>

                  <Stack.Item>
                    <DisplayText size="small">{title}</DisplayText>
                  </Stack.Item>

                  {/* <Stack.Item fill>
                      Ac volutpat faucibus aliquet magna. Venenatis lectus amet,
                      sit placerat in. Viverra urna, varius sed nunc amet. Arcu
                      ut enim, cursus mattis congue nunc. Viverra risus eget
                      metus ullamcorper odio non dictum sed adipiscing. Ut leo,
                      dignissim urna turpis diam. Enim, rhoncus, in lacus,
                      magna. Mollis magna convallis urna, morbi ultrices.{' '}
                    </Stack.Item> */}
                </Stack>
              </Card>
            </div>
          </Layout.Section>
          {
            fieldKeys.length > 0 && fieldKeys.map(field => (
              <>
                <Layout.Section>
                  {
                    (field == "activeCampaignListId") ? (
                      <Select name={field} label={_.startCase(field)} value={formData[field] || credentials[`${field}`]} options={listOptions} onChange={(value) => setFormData({ ...formData, [field]: value })} />
                    ) :
                      <TextField name={field} label={_.startCase(field)} value={formData[field] || credentials[`${field}`]} onChange={(value) => setFormData({ ...formData, [field]: value })} />
                  }
                </Layout.Section>
              </>
            ))
          }
          <Layout.Section>
            {
              fieldKeys.length > 0 &&
              <>
                <Stack>
                  <Stack.Item>
                    <div className="save-button">
                      <Button onClick={handleSubmit} primary loading={loading} >Save</Button>
                    </div>
                  </Stack.Item>
                </Stack>
              </>
            }
          </Layout.Section>
          <Frame>
            {saveSuccess && (
              <Toast
                content="Setting is saved"
                onDismiss={hideSaveSuccess}
              />
            )}
          </Frame>
        </Layout>
      </Page>
    </>
  );
};

export default IntegrationDetail;
