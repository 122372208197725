import React, { useCallback, useEffect, useRef, useState } from 'react';
import '../customerModal.css';
import { Badge, Button, Icon, TextField } from '@shopify/polaris';
import {
  ChevronDownMinor,
  MobilePlusMajor,
  DeleteMinor,
} from '@shopify/polaris-icons';
import AdjustPointsBalance from './AdjustPointsBalance';
import dayjs from 'dayjs';
import SpendPoints from './SpendPoints';

const LoyaltyTab = ({
  domain,
  customer,
  fetchCustomer,
  setToastContent,
  setSaveSuccess,
  referralLink,
  customerPointsActions,
  waysToRedeem,
  setWaysToRedeem,
  rewardActions,
  setRewardActions,
  usedReferrals,
}) => {
  const [subTab, setSubTab] = useState('points');

  const [pointBalance, setPointBalance] = useState(false);
  const [spendPoints, setSpendPoints] = useState(false);
  const [pointsFormData, setPointsFormData] = useState({
    points: 0,
    reason: '',
    note: '',
  });

  const deleteAdjustment = (id) => {
    fetch(
      `/customer/delete_manual_adjustment?id=${id}&shopify_domain=${domain}`,
      {
        method: 'DELETE',
      }
    )
      .then((response) => response.json())
      .then((data) => {
        setPointBalance(false);
        if (data?.success) {
          setToastContent('Deleted successfully');
          setTimeout(() => {
            fetchCustomer();
          }, [3000]);
        } else {
          setToastContent(data?.error);
        }
        setSaveSuccess(true);
      });
  };

  return (
    <div className="detail-customer-add-product cardBox">
      {pointBalance && (
        <AdjustPointsBalance
          setPointBalance={setPointBalance}
          pointBalance={pointBalance}
          setPointsFormData={setPointsFormData}
          pointsFormData={pointsFormData}
          customer={customer}
          domain={domain}
          fetchCustomer={fetchCustomer}
          setToastContent={setToastContent}
          setSaveSuccess={setSaveSuccess}
        />
      )}
      {spendPoints && (
        <SpendPoints
          setSpendPoints={setSpendPoints}
          spendPoints={spendPoints}
          customer={customer}
          domain={domain}
          fetchCustomer={fetchCustomer}
          waysToRedeem={waysToRedeem}
          setWaysToRedeem={setWaysToRedeem}
          setToastContent={setToastContent}
          setSaveSuccess={setSaveSuccess}
        />
      )}
      <div className="list-section-main">
        <ul>
          <li className="locationName">Loyalty & Referrals Activity</li>
          <li className="actionB sub_tab_wrapper">
            <div
              className="sub_tab"
              onClick={() => {
                setSubTab('points');
              }}
            >
              Points
            </div>
            <div
              className="sub_tab"
              onClick={() => {
                setSubTab('referrals');
              }}
            >
              Referrals
            </div>
            <div
              className="sub_tab"
              onClick={() => {
                setSubTab('rewards');
              }}
            >
              Rewards
            </div>
            {/* <div className='sub_tab' onClick={() => { setSubTab("vip") }}>VIP</div> */}
          </li>
        </ul>
      </div>
      <div className="payment-section">
        <span className="commanButton point_balance_wrapper">
          Points Balance: {customer?.loyalty_points || '0 points'}
        </span>
        <span className="commanButton referral_link">
          Referral Link: {referralLink || 'no referral link generated yet'}
        </span>
      </div>
      <div className="bottom-section-main">
        {subTab == 'points' && (
          <table>
            <thead>
              <tr>
                <th>Action </th>
                <th>Points</th>
                <th> Date</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {customerPointsActions.map((customerPointsAction) => {
                return (
                  <tr>
                    <td className="nextOrder">
                      {customerPointsAction?.action}
                    </td>
                    <td className="nextOrder">
                      {customerPointsAction?.points}{' '}
                    </td>
                    <td className="nextOrder">
                      {dayjs(customerPointsAction?.created_at)?.format(
                        'MMM DD, YYYY'
                      )}
                    </td>
                    <td className="nextOrder">
                      <button
                        className="delete_manual_adjustment_button"
                        onClick={() =>
                          deleteAdjustment(customerPointsAction?.id)
                        }
                      >
                        <Icon source={DeleteMinor} color="#d64949" />
                      </button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        )}
        {subTab == 'referrals' && (
          <table>
            <thead>
              <tr>
                <th>Referred friend </th>
                <th>Status</th>
                <th> Order total</th>
                <th>Date</th>
              </tr>
            </thead>
            <tbody>
              {usedReferrals?.map((usedReferral) => {
                return (
                  <tr>
                    <td className="nextOrder">{usedReferral?.action_text}</td>
                    <td className="nextOrder">
                      <Badge status="success">{usedReferral.status}</Badge>
                    </td>
                    <td className="nextOrder">{usedReferral?.order_total}</td>
                    <td className="nextOrder">
                      {dayjs(usedReferral?.created_at)?.format('MMM DD, YYYY')}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        )}
        {subTab == 'vip' && (
          <table>
            <thead>
              <tr>
                <th>Tier achieved</th>
                <th>Description</th>
                <th>Date</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="nextOrder">$5 off coupon</td>
                <td className="nextOrder">eff9d02d2b20</td>
                <td className="nextOrder">
                  <Badge status="success">Issued</Badge>
                </td>
                <td className="nextOrder">Jul 21, 2023</td>
              </tr>
            </tbody>
          </table>
        )}
        {subTab == 'rewards' && (
          <table>
            <thead>
              <tr>
                <th>Reward</th>
                <th>Code</th>
                <th>Status</th>
                <th>Date</th>
              </tr>
            </thead>
            <tbody>
              {rewardActions.map((customerPointsAction) => {
                let action = customerPointsActions?.find(
                  (redeem) =>
                    redeem?.ways_to_redeem_point_id ==
                    customerPointsAction?.ways_to_redeem_point_id
                );
                return (
                  <tr>
                    <td className="nextOrder">{action?.action}</td>
                    <td className="nextOrder">
                      {customerPointsAction?.discount_code}{' '}
                    </td>
                    <td className="nextOrder">
                      <Badge status="success">
                        {customerPointsAction?.redeemed ? 'Issued' : 'Pending'}
                      </Badge>{' '}
                    </td>
                    <td className="nextOrder">
                      {dayjs(customerPointsAction?.created_at)?.format(
                        'MMM DD, YYYY'
                      )}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        )}

        <div className="add-product-button">
          <button className="commanButton" onClick={() => setSpendPoints(true)}>
            {' '}
            <MobilePlusMajor />
            Spend points balance
          </button>
        </div>
        <div class="addAddress">
          <button
            className="commanButton"
            onClick={() => setPointBalance(true)}
          >
            {' '}
            <MobilePlusMajor />
            Adjust points balance
          </button>
        </div>
      </div>
    </div>
  );
};

export default LoyaltyTab;
