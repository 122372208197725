import React, { useCallback, useEffect, useState } from "react";
import {
    Icon,
    AppProvider,
    TextField,
    Select,
    Frame,
    Card,
    Checkbox,
    RadioButton,
    Page,
    Toast
} from "@shopify/polaris";
import { ChevronLeftMinor } from "@shopify/polaris-icons";
import "@shopify/polaris/dist/styles.css";
import "./Style.css";

import PixelIcon from "../../../images/PixelIcon";
import HeaderButtons from "../../HeaderButtons/HeaderButtons";
import SubscriptionWidgetHtml from "./SubscriptionWidgetHtml";
import ManageTranslations from "./ManageTranslations";
import ManageStyle from "./ManageStyle";

const style_object = [
    {
        category: "Purchase options label",
        class: "fieldset.chargezen-plan-picker .chargezen-purchase-options-label",
        fields: [
            {
                css_property: "color",
                sub_fields: [
                    {
                        type: "color",
                        value: ""
                    }
                ]
            },
            {
                css_property: "position",
                sub_fields: [
                    {
                        type: "text",
                        value: "absolute"
                    }
                ]
            },
            {
                css_property: "width",
                sub_fields: [
                    {
                        type: "number",
                        value: "100"
                    },
                    {
                        type: "select",
                        options: ["em", "px", "rem", "%"],
                        value: "%"
                    }
                ]
            },
            {
                css_property: "font-size",
                sub_fields: [
                    {
                        type: "number",
                        value: "15.5"
                    },
                    {
                        type: "select",
                        options: ["em", "px", "rem", "%"],
                        value: "px"
                    }
                ]
            },
            {
                css_property: "font-weight",
                sub_fields: [
                    {
                        type: "select",
                        options: ["none", "300", "400", "500", "600", "700", "800", "900"],
                        value: 500
                    }
                ]
            },
            {
                css_property: "font-style",
                sub_fields: [
                    {
                        type: "select",
                        options: ["unset", "oblique", "italic", "normal"],
                        value: "unset"
                    }
                ]
            },
            {
                css_property: "padding",
                sub_fields: [
                    {
                        type: "text",
                        value: "0"
                    },
                    {
                        type: "text",
                        value: "0"
                    },
                    {
                        type: "text",
                        value: "0"
                    },
                    {
                        type: "text",
                        value: "12.5"
                    },
                    {
                        type: "select",
                        value: "px",
                        options: ["em", "px", "rem", "%"]
                    }
                ]
            },
            {
                css_property: "text-transform",
                sub_fields: [
                    {
                        type: "select",
                        value: "none",
                        options: ["none", "capitalize", "lowercase", "uppercase"]
                    }
                ]
            },
            {
                css_property: "top",
                sub_fields: [
                    {
                        type: "text",
                        value: "-20",
                    },
                    {
                        type: "select",
                        value: "px",
                        options: ["em", "px", "rem", "%"]
                    }
                ]
            }
        ]
    },

    {
        category: "Subscription label",
        class: "fieldset.chargezen-plan-picker .chargezen-group-container label.chargezen-group-label",
        fields: [
            {
                css_property: "background",
                sub_fields: [
                    {
                        type: "color",
                        value: ""
                    }
                ]
            },

            {
                css_property: "border-width",
                sub_fields: [
                    {
                        type: "number",
                        value: "0"
                    },
                    {
                        type: "select",
                        options: ["em", "px", "rem", "%"],
                        value: "px"
                    }
                ]
            },
            {
                css_property: "border-color",
                sub_fields: [
                    {
                        type: "color",
                        value: "#c3cfd9"
                    }
                ]
            },
            {
                css_property: "border-radius",
                sub_fields: [
                    {
                        type: "number",
                        value: "0.5"
                    },
                    {
                        type: "select",
                        options: ["em", "px", "rem", "%"],
                        value: "em"
                    }
                ]
            },
            {
                css_property: "padding",
                sub_fields: [
                    {
                        type: "text",
                        value: "15"
                    },
                    {
                        type: "text",
                        value: "15"
                    },
                    {
                        type: "text",
                        value: "15"
                    },
                    {
                        type: "text",
                        value: "15"
                    },
                    {
                        type: "select",
                        value: "px",
                        options: ["em", "px", "rem", "%"]
                    }
                ]
            },
            {
                css_property: "display",
                sub_fields: [
                    {
                        type: "text",
                        value: "flex"
                    }
                ]
            },
            {
                css_property: "align-items",
                sub_fields: [
                    {
                        type: "text",
                        value: "center"
                    }
                ]
            },
            {
                css_property: "justify-content",
                sub_fields: [
                    {
                        type: "text",
                        value: "center"
                    }
                ]
            }
        ]
    },

    {
        category: "Subscription Widget container",
        class: "fieldset.chargezen-plan-picker",
        fields: [
            {
                css_property: "position",
                sub_fields: [
                    {
                        type: "text",
                        value: "relative"
                    },
                ]

            },
            {
                css_property: "background",
                sub_fields: [
                    {
                        type: "text",
                        value: "#fff"
                    },
                ]

            },
            {
                css_property: "border-radius",
                sub_fields: [
                    {
                        type: "number",
                        value: "28"
                    },
                    {
                        type: "select",
                        options: ["em", "px", "rem", "%"],
                        value: "px"
                    }
                ]
            },
            {
                css_property: "border",
                sub_fields: [
                    {
                        type: "text",
                        value: "0"
                    }
                ]
            },
            {
                css_property: "min-width",
                sub_fields: [
                    {
                        type: "text",
                        value: "342"
                    },
                    {
                        type: "select",
                        value: "px",
                        options: ["em", "px", "rem", "%"]
                    }
                ]
            },
            {
                css_property: "margin",
                sub_fields: [
                    {
                        type: "text",
                        value: "40"
                    },
                    {
                        type: "text",
                        value: "0"
                    },
                    {
                        type: "text",
                        value: "0"
                    },
                    {
                        type: "text",
                        value: "110"
                    },
                    {
                        type: "select",
                        value: "px",
                        options: ["em", "px", "rem", "%"]
                    }
                ]
            },
            {
                css_property: "padding",
                sub_fields: [
                    {
                        type: "text",
                        value: "0"
                    },
                    {
                        type: "text",
                        value: "0"
                    },
                    {
                        type: "text",
                        value: "0"
                    },
                    {
                        type: "text",
                        value: "0"
                    },
                    {
                        type: "select",
                        value: "px",
                        options: ["em", "px", "rem", "%"]
                    }
                ]
            }
        ]
    },

    {
        category: "Plan description",
        class: ".chargezen_description_wrapper",
        fields: [
            {
                css_property: "color",
                sub_fields: [
                    {
                        type: "color",
                        value: "#004876"
                    }
                ]
            },
            {
                css_property: "border-bottom-left-radius",
                sub_fields: [
                    {
                        type: "number",
                        value: "28"
                    },
                    {
                        type: "select",
                        options: ["em", "px", "rem", "%"],
                        value: "px"
                    }
                ]
            },
            {
                css_property: "border-bottom-right-radius",
                sub_fields: [
                    {
                        type: "number",
                        value: "28"
                    },
                    {
                        type: "select",
                        options: ["em", "px", "rem", "%"],
                        value: "px"
                    }
                ]
            },
            {
                css_property: "background",
                sub_fields: [
                    {
                        type: "color",
                        value: "#fff"
                    }
                ]
            },
            {
                css_property: "font-size",
                sub_fields: [
                    {
                        type: "number",
                        value: "14"
                    },
                    {
                        type: "select",
                        options: ["em", "px", "rem", "%"],
                        value: "px"
                    }
                ]
            },
            {
                css_property: "font-weight",
                sub_fields: [
                    {
                        type: "number",
                        value: "600"
                    }
                ]
            },
            {
                css_property: "line-height",
                sub_fields: [
                    {
                        type: "number",
                        value: "100"
                    },
                    {
                        type: "select",
                        options: ["em", "px", "rem", "%"],
                        value: "%"
                    }
                ]
            },
            {
                css_property: "padding",
                sub_fields: [
                    {
                        type: "text",
                        value: "15"
                    },
                    {
                        type: "text",
                        value: "15"
                    },
                    {
                        type: "text",
                        value: "15"
                    },
                    {
                        type: "text",
                        value: "15"
                    },
                    {
                        type: "select",
                        value: "px",
                        options: ["em", "px", "rem", "%"]
                    }
                ]
            }
        ]
    },
    {
        category: "Plan selector prefix",
        class: "fieldset.chargezen-plan-picker .chargezen-group-container .chargezen-group-content:before",
        fields: [
            {
                css_property: "color",
                sub_fields: [
                    {
                        type: "color",
                        value: "#00487580"
                    }
                ]
            },
            {
                css_property: "content",
                sub_fields: [
                    {
                        type: "text",
                        value: "'frequency'"
                    }
                ]
            },
            {
                css_property: "margin",
                sub_fields: [
                    {
                        type: "text",
                        value: "0"
                    },
                    {
                        type: "text",
                        value: "10"
                    },
                    {
                        type: "text",
                        value: "0"
                    },
                    {
                        type: "text",
                        value: "0"
                    },
                    {
                        type: "select",
                        value: "px",
                        options: ["em", "px", "rem", "%"]
                    }
                ]
            },
            {
                css_property: "font-size",
                sub_fields: [
                    {
                        type: "number",
                        value: "18"
                    },
                    {
                        type: "select",
                        options: ["em", "px", "rem", "%"],
                        value: "px"
                    }
                ]
            },
            {
                css_property: "font-weight",
                sub_fields: [
                    {
                        type: "number",
                        value: "900"
                    }
                ]
            },

        ]
    },
    {
        category: "Plan selector (dropdown)",
        class: "fieldset.chargezen-plan-picker .chargezen-group-container .chargezen-group-content",
        fields: [
            {
                css_property: "display",
                sub_fields: [
                    {
                        type: "text",
                        value: "flex"
                    }
                ]
            },
            {
                css_property: "position",
                sub_fields: [
                    {
                        type: "text",
                        value: "absolute"
                    }
                ]
            },
            {
                css_property: "align-items",
                sub_fields: [
                    {
                        type: "text",
                        value: "center"
                    }
                ]
            },
            {
                css_property: "color",
                sub_fields: [
                    {
                        type: "color",
                        value: "#fff"
                    }
                ]
            },
            {
                css_property: "background",
                sub_fields: [
                    {
                        type: "color",
                        value: "#fff"
                    }
                ]
            },
            {
                css_property: "margin",
                sub_fields: [
                    {
                        type: "text",
                        value: "0"
                    },
                    {
                        type: "text",
                        value: "0"
                    },
                    {
                        type: "text",
                        value: "0"
                    },
                    {
                        type: "text",
                        value: "0"
                    },
                    {
                        type: "select",
                        value: "px",
                        options: ["em", "px", "rem", "%"]
                    }
                ]
            },
            {
                css_property: "padding",
                sub_fields: [
                    {
                        type: "text",
                        value: "14"
                    },
                    {
                        type: "text",
                        value: "20"
                    },
                    {
                        type: "text",
                        value: "14"
                    },
                    {
                        type: "text",
                        value: "20"
                    },
                    {
                        type: "select",
                        value: "px",
                        options: ["em", "px", "rem", "%"]
                    }
                ]
            },
            {
                css_property: "border-radius",
                sub_fields: [
                    {
                        type: "number",
                        value: "30"
                    },
                    {
                        type: "select",
                        options: ["em", "px", "rem", "%"],
                        value: "rem"
                    }
                ]
            },
            {
                css_property: "align-items",
                sub_fields: [
                    {
                        type: "text",
                        value: "center"
                    },
                ]
            },
            {
                css_property: "bottom",
                sub_fields: [
                    {
                        type: "text",
                        value: "-74"
                    },
                    {
                        type: "select",
                        options: ["em", "px", "rem", "%"],
                        value: "px"
                    }
                ]
            },
            {
                css_property: "left",
                sub_fields: [
                    {
                        type: "text",
                        value: "0"
                    },
                    {
                        type: "select",
                        options: ["em", "px", "rem", "%"],
                        value: "px"
                    }
                ]
            },

            {
                css_property: "width",
                sub_fields: [
                    {
                        type: "number",
                        value: "100"
                    },
                    {
                        type: "select",
                        options: ["em", "px", "rem", "%"],
                        value: "%"
                    }
                ]
            }
        ]
    },

    {
        category: "Select plans",
        class: "fieldset.chargezen-plan-picker .chargezen-group-container .chargezen-group-content select.chargezen-frequency",
        fields: [
            {
                css_property: "color",
                sub_fields: [
                    {
                        type: "color",
                        value: "#004876"
                    }
                ]
            },
            {
                css_property: "border",
                sub_fields: [
                    {
                        type: "text",
                        value: "0"
                    }
                ]
            },
            {
                css_property: "background",
                sub_fields: [
                    {
                        type: "color",
                        value: "transparent"
                    }
                ]
            },
            {
                css_property: "font-size",
                sub_fields: [
                    {
                        type: "number",
                        value: "18"
                    },
                    {
                        type: "select",
                        options: ["em", "px", "rem", "%"],
                        value: "px"
                    }
                ]
            },
            {
                css_property: "font-weight",
                sub_fields: [
                    {
                        type: "select",
                        options: ["none", "300", "400", "500", "600", "700", "800", "900"],
                        value: "900"
                    }
                ]
            },
            {
                css_property: "width",
                sub_fields: [
                    {
                        type: "text",
                        value: "100"
                    },
                    {
                        type: "select",
                        options: ["em", "px", "rem", "%"],
                        value: "%"
                    }
                ]
            },
            {
                css_property: "margin",
                sub_fields: [
                    {
                        type: "text",
                        value: "0"
                    },
                    {
                        type: "text",
                        value: "0"
                    },
                    {
                        type: "text",
                        value: "0"
                    },
                    {
                        type: "text",
                        value: "0"
                    },
                    {
                        type: "select",
                        value: "px",
                        options: ["em", "px", "rem", "%"]
                    }
                ]
            },
            {
                css_property: "padding",
                sub_fields: [
                    {
                        type: "text",
                        value: "0"
                    },
                    {
                        type: "text",
                        value: "0"
                    },
                    {
                        type: "text",
                        value: "0"
                    },
                    {
                        type: "text",
                        value: "0"
                    },
                    {
                        type: "select",
                        value: "px",
                        options: ["em", "px", "rem", "%"]
                    }
                ]
            },
        ]
    },

    {
        category: "Discount badge",
        class: ".chargezen-subscription-group-discount-badge",
        fields: [
            {
                css_property: "color",
                sub_fields: [
                    {
                        type: "color",
                        value: "#000000"
                    }
                ]
            },
            {
                css_property: "background",
                sub_fields: [
                    {
                        type: "color",
                        value: "#f7c324"
                    }
                ]
            },
            {
                css_property: "border-radius",
                sub_fields: [
                    {
                        type: "number",
                        value: "100"
                    },
                    {
                        type: "select",
                        options: ["em", "px", "rem", "%"],
                        value: "px"
                    }
                ]
            },
            {
                css_property: "font-size",
                sub_fields: [
                    {
                        type: "number",
                        value: "0.9"
                    },
                    {
                        type: "select",
                        options: ["em", "px", "rem", "%"],
                        value: "em"
                    }
                ]
            },
            {
                css_property: "font-weight",
                sub_fields: [
                    {
                        type: "select",
                        options: ["none", "300", "400", "500", "600", "700", "800", "900"],
                        value: 900
                    }
                ]
            },
            {
                css_property: "padding",
                sub_fields: [
                    {
                        type: "text",
                        value: "2"
                    },
                    {
                        type: "text",
                        value: "15"
                    },
                    {
                        type: "text",
                        value: "2"
                    },
                    {
                        type: "text",
                        value: "15"
                    },
                    {
                        type: "select",
                        value: "px",
                        options: ["em", "px", "rem", "%"]
                    }
                ]
            },
        ]
    },

    {
        category: "Plan label wrapper",
        class: "fieldset.chargezen-plan-picker .chargezen_plans_options_wrapper",
        fields: [
            {
                css_property: "display",
                sub_fields: [
                    {
                        type: "text",
                        value: "flex"
                    },
                ]
            },
            {
                css_property: "flex-flow",
                sub_fields: [
                    {
                        type: "select",
                        options: ["row", "column"],
                        value: "row"
                    }
                ]
            },
            {
                css_property: "background",
                sub_fields: [
                    {
                        type: "color",
                        value: "#e7ecf1"
                    },
                ]
            },
            {
                css_property: "justify-content",
                sub_fields: [
                    {
                        type: "text",
                        value: "space-evenly"
                    },

                ]
            },
            {
                css_property: "border-radius",
                sub_fields: [
                    {
                        type: "value",
                        value: "30"
                    },
                    {
                        type: "select",
                        options: ["em", "px", "rem", "%"],
                        value: "px"
                    }
                ]
            },
            {
                css_property: "padding",
                sub_fields: [
                    {
                        type: "text",
                        value: "0"
                    },
                    {
                        type: "text",
                        value: "0"
                    },
                    {
                        type: "text",
                        value: "0"
                    },
                    {
                        type: "text",
                        value: "0"
                    },
                    {
                        type: "select",
                        value: "px",
                        options: ["em", "px", "rem", "%"]
                    }
                ]
            },
            {
                css_property: "margin",
                sub_fields: [
                    {
                        type: "text",
                        value: "0"
                    },
                    {
                        type: "text",
                        value: "0"
                    },
                    {
                        type: "text",
                        value: "0"
                    },
                    {
                        type: "text",
                        value: "0"
                    },
                    {
                        type: "select",
                        value: "px",
                        options: ["em", "px", "rem", "%"]
                    }
                ]
            },
            {
                css_property: "border",
                sub_fields: [
                    {
                        type: "text",
                        value: "none",
                    }
                ]
            },
            {
                css_property: "align-items",
                sub_fields: [
                    {
                        type: "text",
                        value: "unset",
                    }
                ]
            },
            {
                css_property: "gap",
                sub_fields: [
                    {
                        type: "text",
                        value: "0",
                    },
                    {
                        type: "select",
                        value: "px",
                        options: ["em", "px", "rem", "%"]
                    }
                ]
            }
        ]
    },

    {
        category: "Plan label",
        class: "fieldset.chargezen-plan-picker .chargezen-group-container",
        fields: [
            {
                css_property: "background",
                sub_fields: [
                    {
                        type: "color",
                        value: "transparent"
                    }
                ]
            },
            {
                css_property: "color",
                sub_fields: [
                    {
                        type: "color",
                        value: ""
                    }
                ]
            },
            {
                css_property: "width",
                sub_fields: [
                    {
                        type: "text",
                        value: "100"
                    },
                    {
                        type: "select",
                        options: ["em", "px", "rem", "%"],
                        value: "%"
                    }
                ]
            },
            {
                css_property: "border",
                sub_fields: [
                    {
                        type: "text",
                        value: "0"
                    },
                ]
            },
            {
                css_property: "font-size",
                sub_fields: [
                    {
                        type: "number",
                        value: "1"
                    },
                    {
                        type: "select",
                        options: ["em", "px", "rem", "%"],
                        value: "em"
                    }
                ]
            },
            {
                css_property: "font-weight",
                sub_fields: [
                    {
                        type: "select",
                        options: ["none", "300", "400", "500", "600", "700", "800", "900"],
                        value: "none"
                    }
                ]
            },
            {
                css_property: "font-style",
                sub_fields: [
                    {
                        type: "select",
                        options: ["unset", "oblique", "italic", "normal"],
                        value: "unset"
                    }
                ]
            },
            {
                css_property: "border-width",
                sub_fields: [
                    {
                        type: "number",
                        value: "1"
                    },
                    {
                        type: "select",
                        options: ["em", "px", "rem", "%"],
                        value: "px"
                    }
                ]
            },
            {
                css_property: "border-color",
                sub_fields: [
                    {
                        type: "color",
                        value: "#c3cfd9"
                    }
                ]
            },
            {
                css_property: "margin",
                sub_fields: [
                    {
                        type: "text",
                        value: "0"
                    },
                    {
                        type: "text",
                        value: "0"
                    },
                    {
                        type: "text",
                        value: "0"
                    },
                    {
                        type: "text",
                        value: "0"
                    },
                    {
                        type: "select",
                        value: "px",
                        options: ["em", "px", "rem", "%"]
                    }
                ]
            },
            {
                css_property: "padding",
                sub_fields: [
                    {
                        type: "text",
                        value: "0"
                    },
                    {
                        type: "text",
                        value: "0"
                    },
                    {
                        type: "text",
                        value: "0"
                    },
                    {
                        type: "text",
                        value: "0"
                    },
                    {
                        type: "select",
                        value: "px",
                        options: ["em", "px", "rem", "%"]
                    }
                ]
            },
            {
                css_property: "text-transform",
                sub_fields: [
                    {
                        type: "select",
                        value: "none",
                        options: ["none", "capitalize", "lowercase", "uppercase"]
                    }
                ]
            }
        ]
    },

    {
        category: "Price label",
        class: "fieldset.chargezen-plan-picker .chargezen-group-container label.chargezen-group-label .chargezen-group-topline .chargezen-price",
        fields: [
            {
                css_property: "display",
                sub_fields: [
                    {
                        type: "text",
                        value: "none"
                    }
                ]
            },
        ]
    },

    {
        category: "Selected Plan label wrapper",
        class: "fieldset.chargezen-plan-picker .chargezen-group-container.chargezen-group-container--selected>label.chargezen-group-label",
        fields: [
            {
                css_property: "color",
                sub_fields: [
                    {
                        type: "color",
                        value: "#fff"
                    }
                ]
            },
            {
                css_property: "background",
                sub_fields: [
                    {
                        type: "color",
                        value: "#004875"
                    }
                ]
            },
            {
                css_property: "border",
                sub_fields: [
                    {
                        type: "text",
                        value: "0"
                    }
                ]
            },
            {
                css_property: "padding",
                sub_fields: [
                    {
                        type: "text",
                        value: "15"
                    },
                    {
                        type: "text",
                        value: "15"
                    },
                    {
                        type: "text",
                        value: "15"
                    },
                    {
                        type: "text",
                        value: "15"
                    },
                    {
                        type: "select",
                        value: "px",
                        options: ["em", "px", "rem", "%"]
                    }
                ]
            },
            {
                css_property: "height",
                sub_fields: [
                    {
                        type: "number",
                        value: "100"
                    },
                    {
                        type: "select",
                        options: ["em", "px", "rem", "%"],
                        value: "%"
                    }
                ]
            },
            {
                css_property: "align-items",
                sub_fields: [
                    {
                        type: "text",
                        value: "center"
                    }
                ]
            },
            {
                css_property: "justify-content",
                sub_fields: [
                    {
                        type: "text",
                        value: "center"
                    }
                ]
            },
            {
                css_property: "border-radius",
                sub_fields: [
                    {
                        type: "text",
                        value: "30"
                    },
                    {
                        type: "select",
                        options: ["em", "px", "rem", "%"],
                        value: "px"
                    }
                ]
            },
        ]
    },

    {
        category: "Plan label text",
        class: "fieldset.chargezen-plan-picker .chargezen-group-container label.chargezen-group-label .chargezen-group-topline, fieldset.chargezen-plan-picker .chargezen-group-container label.chargezen-group-label .chargezen-group-topline .chargezen-group-title",
        fields: [
            {
                css_property: "color",
                sub_fields: [
                    {
                        type: "color",
                        value: "#212b36"
                    }
                ]
            },
            {
                css_property: "font-size",
                sub_fields: [
                    {
                        type: "number",
                        value: "15"
                    },
                    {
                        type: "select",
                        options: ["em", "px", "rem", "%"],
                        value: "px"
                    }
                ]
            },
            {
                css_property: "text-align",
                sub_fields: [
                    {
                        type: "text",
                        value: "left"
                    },
                ]
            },
            {
                css_property: "white-space",
                sub_fields: [
                    {
                        type: "text",
                        value: "unset"
                    },
                ]
            },
            {
                css_property: "word-break",
                sub_fields: [
                    {
                        type: "text",
                        value: "break-word"
                    },
                ]
            },
            {
                css_property: "font-weight",
                sub_fields: [
                    {
                        type: "select",
                        options: ["none", "300", "400", "500", "600", "700", "800", "900"],
                        value: "600"
                    }
                ]
            },
            {
                css_property: "letter-spacing",
                sub_fields: [
                    {
                        type: "text",
                        value: "-1"
                    },
                    {
                        type: "select",
                        options: ["em", "px", "rem", "%"],
                        value: "px"
                    }
                ]
            },
            {
                css_property: "padding",
                sub_fields: [
                    {
                        type: "text",
                        value: "0"
                    },
                    {
                        type: "text",
                        value: "0"
                    },
                    {
                        type: "text",
                        value: "0"
                    },
                    {
                        type: "text",
                        value: "0"
                    },
                    {
                        type: "select",
                        value: "px",
                        options: ["em", "px", "rem", "%"]
                    }
                ]
            },
            {
                css_property: "text-transform",
                sub_fields: [
                    {
                        type: "select",
                        value: "none",
                        options: ["none", "capitalize", "lowercase", "uppercase"]
                    }
                ]
            },
        ]
    },

    {
        category: "Selected Plan label text",
        class: "fieldset.chargezen-plan-picker .chargezen-group-container label.chargezen-group-label .chargezen-group-topline,  .chargezen-group-container.chargezen-group-container--available.chargezen-group-container--selected .chargezen-group-topline",
        fields: [
            {
                css_property: "color",
                sub_fields: [
                    {
                        type: "color",
                        value: "#fff"
                    }
                ]
            },
        ]
    },
    {
        category: "Chargezen tooltip wrapper",
        class: "fieldset.chargezen-plan-picker .chargezen-tooltip",
        fields: [
            {
                css_property: "position",
                sub_fields: [
                    {
                        type: "text",
                        value: "absolute"
                    },
                ]
            },
            {
                css_property: "bottom",
                sub_fields: [
                    {
                        type: "text",
                        value: "-110"
                    },
                    {
                        type: "select",
                        value: "px",
                        options: ["em", "px", "rem", "%"]
                    }
                ]
            },
            {
                css_property: "left",
                sub_fields: [
                    {
                        type: "text",
                        value: "20"
                    },
                    {
                        type: "select",
                        value: "px",
                        options: ["em", "px", "rem", "%"]
                    }
                ]
            },
            {
                css_property: "top",
                sub_fields: [
                    {
                        type: "text",
                        value: "unset"
                    },
                    {
                        type: "select",
                        value: "px",
                        options: ["em", "px", "rem", "%"]
                    }
                ]
            },
            {
                css_property: "right",
                sub_fields: [
                    {
                        type: "text",
                        value: "0"
                    },
                    {
                        type: "select",
                        value: "px",
                        options: ["em", "px", "rem", "%"]
                    }
                ]
            },
        ]
    },

    {
        category: "Subscription details label",
        class: ".chargezen-tooltip-label",
        fields: [
            {
                css_property: "color",
                sub_fields: [
                    {
                        type: "color",
                        value: ""
                    }
                ]
            },
            {
                css_property: "font-size",
                sub_fields: [
                    {
                        type: "number",
                        value: "1"
                    },
                    {
                        type: "select",
                        options: ["em", "px", "rem", "%"],
                        value: "em"
                    }
                ]
            },
            {
                css_property: "font-weight",
                sub_fields: [
                    {
                        type: "select",
                        options: ["none", "300", "400", "500", "600", "700", "800", "900"],
                        value: "none"
                    }
                ]
            },
            {
                css_property: "font-style",
                sub_fields: [
                    {
                        type: "select",
                        options: ["unset", "oblique", "italic", "normal"],
                        value: "unset"
                    }
                ]
            }
        ]
    },

    {
        category: "Subscription details popup",
        class: ".chargezen-tooltiptext, .chargezen-container-arrow.chargezen-container-arrow--tl",
        fields: [
            {
                css_property: "background",
                sub_fields: [
                    {
                        type: "color",
                        value: ""
                    }
                ]
            },
            {
                css_property: "color",
                sub_fields: [
                    {
                        type: "color",
                        value: ""
                    }
                ]
            }
        ]
    }
];

const CustomizeSubscriptionWidget = (props) => {
    const { domain, i18n } = props
    const initialValues = {
        default_selected_option: "one_time_purchase",
        purchase_options_order: "display_one_time_purchase",
        show_purchase: true,
        hide_each_label: false,
        show_discount_badge: true,
        show_subscription_detail_popup: true,
        hide_plan_one_plan: false,
        show_plan_selector_text: true,
        hide_widget_one_plan: false,
        hide_radio_button_one_plan: false

    };
    const [toastContent, setToasContent] = useState();
    const hideSaveSuccess = useCallback(() => setToasContent(), []);
    const [currentForm, setCurrentForm] = useState("");
    const [formData, setFormData] = useState(initialValues);
    const [headerButton, setHeaderButton] = useState("Settings");
    const [allStyles, setAllStyles] = useState([]);

    useEffect(() => {
        setAllStyles(style_object)
    }, [])

    const headerButtons = [
        {
            val: "Settings",
            name: "Settings"
        },
        {
            val: "Translations",
            name: "Translations"
        },
        {
            val: "Styling",
            name: "Styling"
        }
    ];

    const fetchCustomizeSubscriptionWidget = () => {
        fetch(`/points_program/fetch_rewards_launcher_settng?shopify_domain=${domain}`, {
            method: "GET"
        }).then((response) => response.json())
            .then((data) => {
                if (data?.response?.rewards_launcher_setting) {
                    setFormData(data?.response?.rewards_launcher_setting)
                }
            })
    }


    useEffect(() => {
        setCurrentForm(headerButton);
    }, [headerButton]);

    const saveRewardLauncherSetting = () => {
        fetch("/points_program/set_rewards_launcher_setting", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ rewards_launcher_setting: formData, shopify_domain: domain })
        }).then((response) => response.json())
            .then((data) => {
                if (data?.status) {
                    setToasContent("Saved Successfully...")
                } else {
                    setToasContent("Something went wrong...")
                }
            })
    }

    console.log("formData", formData)

    const defaultOptions = [
        { label: "One-time purchase", value: "one_time_purchase" },
        { label: "Subscription", value: "subscription" },
    ]

    const purchaseOrderOptions = [
        { label: "Display one-time purchase first", value: "display_one_time_purchase" },
        { label: "Display subscription first", value: "display_subscription_first" },
    ]


    //translations
    const [selectedLanguage, setSelectedLanguage] = useState("en");
    const [translationFormData, setTranslationFormData] = useState();
    const fetchDefaultLanguage = () => {
        fetch(`/settings/current_language?shopify_domain=${domain}&language=${selectedLanguage}`, {
            method: "GET"
        }).then((response) => response.json())
            .then((data) => {
                setSelectedLanguage(data?.front_end_language)
            })
    }

    const fetchTranslations = () => {
        fetch(`/fetch_subscription_widget_translations?shopify_domain=${domain}&language=${selectedLanguage}&cloudflarepage=rewards_launcher`, {
            method: "GET"
        }).then((response) => response.json())
            .then((data) => {
                setTranslationFormData(data?.translations)
            })
    }

    const saveTranslations = () => {
        fetch(`/cloudlfare/save_translations`, {
            method: "POST",
            body: JSON.stringify({ translations: translationFormData, shopify_domain: domain, language: selectedLanguage, cloudflarepage: "rewards_launcher" }),
            headers: {
                'Content-Type': 'application/json',
            },
        }).then((response) => response.json())
            .then((data) => {
                if (data.status) {
                    setToasContent("Translations updated")
                }
            })
    }

    useEffect(() => {
        fetchDefaultLanguage();
    }, [])
    useEffect(() => {
        fetchTranslations();
    }, [selectedLanguage])

    return (
        <Frame>
            {toastContent && (
                <Toast
                    content={toastContent}
                    onDismiss={hideSaveSuccess}
                />
            )}
            <Page>
                <Card
                    sectioned
                    title={
                        <div className="heading_title">
                            <PixelIcon />
                            {`Customize Subscription Widget`}
                        </div>
                    }
                    actions={{
                        content: (
                            <div className="tabButtons">
                                <HeaderButtons
                                    headerButtons={headerButtons}
                                    setHeaderButton={setHeaderButton}
                                    headerButton={headerButton}
                                />
                            </div>
                        )
                    }}

                >
                    <div className="rewards_launcher_settings_wrapper">
                        <div className="header_wraper">
                            <span className="left_section">
                                <h2>{headerButton}</h2>
                            </span>
                            <div className="heder-right-section">
                                <button className="save-button" onClick={() => saveRewardLauncherSetting()}>Save</button>
                            </div>
                        </div>
                        <div className="content-section">
                            <div className="left_sidebarr">
                                {headerButton === "Settings" ? (
                                    <Card>
                                        <Card.Section>
                                            <div className="card_body_content">
                                                <div className="customise_susbcription_wrapper">
                                                    <div className="fields_wrapper">
                                                        <Select
                                                            label={<h4>Option selected by default</h4>}
                                                            options={defaultOptions}
                                                            value={formData?.default_selected_option || "one_time_purchase"}
                                                            onChange={(e) => {
                                                                setFormData({ ...formData, default_selected_option: e });
                                                            }}
                                                        />
                                                    </div>
                                                    <div className="fields_wrapper">
                                                        <Select
                                                            label={<h4>Purchase options order</h4>}
                                                            options={purchaseOrderOptions}
                                                            value={formData?.purchase_options_order || "display_one_time_purchase"}
                                                            onChange={(e) => {
                                                                setFormData({ ...formData, purchase_options_order: e });
                                                            }}
                                                        />
                                                    </div>
                                                    <div className="fields_wrapper">
                                                        <Checkbox
                                                            label="Show purchase options label"
                                                            checked={formData?.show_purchase}
                                                            value={true}
                                                            onChange={(e) => {
                                                                setFormData({ ...formData, show_purchase: e });
                                                            }}
                                                        />
                                                    </div>
                                                    <div className="fields_wrapper">
                                                        <Checkbox
                                                            label='Hide the "each" label shown below the price amount'
                                                            checked={formData?.hide_each_label}
                                                            value={false}
                                                            onChange={(e) => {
                                                                setFormData({ ...formData, hide_each_label: e });
                                                            }}
                                                        />
                                                    </div>
                                                    <div className="fields_wrapper">
                                                        <Checkbox
                                                            label='Show the discount badge for subscription option'
                                                            value={true}
                                                            checked={formData?.show_discount_badge}
                                                            helpText="Maximum discount will be shown when there are different discount options set for each plan. If there is no discount set, the discount badge will be hidden."
                                                            onChange={(e) => {
                                                                setFormData({ ...formData, show_discount_badge: e });
                                                            }}
                                                        />
                                                    </div>
                                                    <div className="fields_wrapper">
                                                        <Checkbox
                                                            label='Show subscription details popup'
                                                            checked={formData?.show_subscription_detail_popup}
                                                            value={true}
                                                            onChange={(e) => {
                                                                setFormData({ ...formData, show_subscription_detail_popup: e });
                                                            }}
                                                        />
                                                    </div>
                                                    <div className="fields_wrapper">
                                                        <Checkbox
                                                            label='Hide plan selector if only one selling plan is available'
                                                            value={false}
                                                            checked={formData?.hide_plan_one_plan}
                                                            helpText="Activating this setting will hide the plan selector label & plan selector dropdown if only one delivery option is available. Make sure that the selling plan group name has details of delivery frequency."
                                                            onChange={(e) => {
                                                                setFormData({ ...formData, hide_plan_one_plan: e });
                                                            }}
                                                        />
                                                    </div>
                                                    <div className="fields_wrapper">
                                                        <Checkbox
                                                            label='Show the plan selector as a text if only one selling plan is available'
                                                            checked={formData?.show_plan_selector_text}
                                                            helpText="Activating this setting will replace the plan selector dropdown with a text when the only available option is a delivery option."
                                                            onChange={(e) => {
                                                                setFormData({ ...formData, show_plan_selector_text: e });
                                                            }}
                                                        />
                                                    </div>
                                                    <div className="fields_wrapper">
                                                        <h3>For subscription only products</h3>
                                                    </div>
                                                    <div className="fields_wrapper">
                                                        <Checkbox
                                                            label='Hide the subscription widget if only one sellling plan is available'
                                                            checked={formData?.hide_widget_one_plan}
                                                            helpText="Enabling this setting will hide the Chargezen Subscription widget if the product is subscription only and there is only one selling plan available for selection."
                                                            onChange={(e) => {
                                                                setFormData({ ...formData, hide_widget_one_plan: e });
                                                            }}
                                                        />
                                                    </div>
                                                    <div className="fields_wrapper">
                                                        <Checkbox
                                                            label='Hide radio button if only one selling plan group is available'
                                                            checked={formData?.hide_radio_button_one_plan}
                                                            helpText="Enabling this setting will hide the radio button if the product is subscription only and there is only one selling plan group available"
                                                            onChange={(e) => {
                                                                setFormData({ ...formData, hide_radio_button_one_plan: e });
                                                            }}
                                                        />
                                                    </div>

                                                </div>
                                            </div>
                                        </Card.Section>
                                    </Card>
                                ) :
                                    headerButton == "Translations" ? (
                                        <ManageTranslations
                                            i18n={i18n}
                                            domain={domain}
                                            setToasContent={setToasContent}
                                            setFormData={setTranslationFormData}
                                            formData={translationFormData}
                                            setSelectedLanguage={setSelectedLanguage}
                                            selectedLanguage={selectedLanguage} />
                                    ) :

                                        headerButton == "Styling" ?
                                            <ManageStyle allStyles={allStyles} setAllStyles={setAllStyles} style_object={style_object} />

                                            :
                                            <></>}
                            </div>
                            <div className="right-section">
                                <SubscriptionWidgetHtml formData={formData} translationFormData={translationFormData} allStyles={allStyles} />
                            </div>
                        </div>
                    </div>
                </Card>

            </Page>
        </Frame>
    );
};

export default CustomizeSubscriptionWidget;
