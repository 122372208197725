import {
  Banner,
  Button,
  ButtonGroup,
  Card,
  ContextualSaveBar,
  Form,
  FormLayout,
  Frame,
  Icon,
  Layout,
  Modal,
  Page,
  Select,
  Tabs,
  TextContainer,
  TextField,
  Toast,
} from '@shopify/polaris';
import {
  MobileBackArrowMajor,
  MobileChevronMajor,
  MobileMajor,
  DesktopMajor,
} from '@shopify/polaris-icons';

import React, { useCallback, useEffect, useState } from 'react';
import { MultipleProductHtml, SingleProductHtml } from './HtmlContent';
import '@shopify/polaris/dist/styles.css';
import './style.css';
import OfferSettings from './OfferSettings';
import DesignSettings from './DesignSettings';
import ScheduleOffer from './ScheduleOffer';
import { gql, useLazyQuery } from '@apollo/client';
import { useHistory } from 'react-router-dom';

const EditTemplate = ({
  i18n,
  domain,
  editTemplate,
  setEditTemplate,
  editOffer,
  setEditOffer,
  fetchAllOffers,
  toastContent,
  hideSaveSuccess,
  setToastContent,
}) => {
  const history = useHistory();
  const initContentBlock = {
    image:
      'https://presidio-up.s3.us-east-2.amazonaws.com/images/mock-block-1.jpeg',
    desktop_image_alignment: 'Left',
    desktop_image_width: 'Default',
    headline: 'The Scent: Melrose Place',
    description:
      'A one-way ticket to L.A., Melrose Place has a velvety-rich rose scent infused with a swoon-worthy blend of bergamot, lychee, white musk, champagne, and more.',
  };
  const initConditionBlock = {
    min_order_value: '',
    max_order_value: '',
    min_line_items_quantity: '',
    min_customer_order_count: '',
    has_selling_plan: false,
    order_currency: '',
    current_condition: 'min_order_value',
    variant_images: [],
  };

  const [selected, setSelected] = useState(0);
  const [subForm, setSubForm] = useState('');
  const [toggleOnButton, setToggleOnButton] = useState(false);
  const [contentBlock, setContentBlock] = useState([initContentBlock]);
  const [conditionBlock, setConditionBlock] = useState([initConditionBlock]);
  const [allVariants, setAllVariants] = useState([]);
  const [initialFormData, setInitialFormData] = useState({
    design_headline_text: 'Chargezen upsell',
    contents: [initContentBlock],
    conditions: [initConditionBlock],
    settings: {},
    variants: [],
  });
  const [updated, setUpdated] = useState(false);
  const [formData, setFormData] = useState({
    meet_all_conditions: false,
    offer_condition: false,
    design_content: false,
  });
  const [scheduleOffer, setScheduleOffer] = useState();
  const [showSchedule, setShowSchedule] = useState(false);
  const [renderBanner, setRenderBanner] = useState(false);
  const handleTabChange = useCallback(
    (selectedTabIndex) => setSelected(selectedTabIndex),
    []
  );
  const [saveSuccess, setSaveSuccess] = useState(false);

  const [leavePageModal, setLeavePageModal] = useState(false);
  const handleLeaveModalChange = useCallback(
    () => setLeavePageModal(!leavePageModal),
    [leavePageModal]
  );

  const tabs = [
    {
      id: 'offer',
      content: 'Offer',
      accessibilityLabel: 'All customers',
      panelID: 'all-customers-fitted-content-3',
    },
    // {
    //   id: 'design',
    //   content: 'Design',
    //   panelID: 'accepts-marketing-fitted-content-3',
    // },
  ];
  const [mobileView, setMobileView] = useState(true);

  const handleSubmit = async (whilePublish = false) => {
    let settings = formData;
    settings.variants = allVariants;

    let form_data = new FormData();
    form_data.append('templateid', editTemplate);
    form_data.append('shopify_domain', domain);
    form_data.append('settings', JSON.stringify(settings));

    if (formData?.offer_condition) {
      form_data.append('offer_conditions', JSON.stringify(conditionBlock));
    }

    if (formData?.design_content) {
      form_data.append('design_contents', JSON.stringify(contentBlock));
    }

    contentBlock?.map((content, i) => {
      if (content.image) {
        form_data.append(`content_image${i}`, content.image);
      }
    });

    const response = await fetch('/upsells/save_upsell_settings', {
      method: 'POST',
      body: form_data,
    });
    const data = await response.json();
    if (!whilePublish) {
      setToastContent('Offer Saved');
      fetchAllOffers();
    }
    setEditOffer(data?.offer_id);
    return data?.offer_id;
  };

  const fetchUpsell = () => {
    let url =
      '/upsells/fetch_upsells?shopify_domain=' +
      domain +
      '&templateid=' +
      editTemplate;
    if (editOffer) {
      url = `${url}&id=${editOffer}`;
    }
    fetch(url, {
      method: 'GET',
    })
      .then((response) => response.json())
      .then((data) => {
        let initial_formdata = initialFormData;
        if (data.settings) {
          initial_formdata.settings = data.settings;
          setFormData(data.settings);
        }
        if (data?.offer_conditions?.length > 0) {
          initial_formdata.conditions = data?.offer_conditions;
          setConditionBlock([...data?.offer_conditions]);
        }
        if (data?.upsell_design_content?.length > 0) {
          initial_formdata.contents = data.upsell_design_content;
          setContentBlock([...data.upsell_design_content]);
        }
        if (data?.settings?.variants?.length > 0) {
          initial_formdata.variants = data?.settings?.variants;
          setAllVariants(data?.settings?.variants);
        }

        setInitialFormData({ ...initial_formdata });
      });
  };

  useEffect(() => {
    let update = false;
    if (JSON.stringify(formData) != JSON.stringify(initialFormData?.settings)) {
      update = true;
    } else if (
      JSON.stringify(contentBlock) != JSON.stringify(initialFormData?.contents)
    ) {
      update = true;
    } else if (
      JSON.stringify(conditionBlock) !=
      JSON.stringify(initialFormData?.conditions)
    ) {
      update = true;
    } else if (
      JSON.stringify(allVariants) != JSON.stringify(initialFormData?.variants)
    ) {
      update = true;
    }
    setUpdated(update);
  }, [
    formData,
    allVariants,
    contentBlock,
    conditionBlock,
    allVariants,
    initialFormData,
  ]);

  useEffect(() => {
    fetchUpsell();
  }, [editOffer]);

  // useEffect(() => {
  //   if (formData.type != 'multiple_products' && allVariants.length > 1) {
  //     let last_index = allVariants.length - 1;
  //     let variant = [allVariants[last_index]];
  //     setAllVariants([...variant]);
  //   }
  // }, [allVariants]);

  const resetForm = () => {
    setFormData({ ...initialFormData?.settings });
    setConditionBlock([...initialFormData?.conditions]);
    setContentBlock([...initialFormData?.contents]);
    setAllVariants([...initialFormData?.variants]);
    setUpdated(false);
  };

  //add modal css
  let modalcss = `
        .Polaris-Modal-Footer button.Polaris-Button.Polaris-Button--primary {
            background: #e51c00 !important;
            border: 0;
            box-shadow: unset !important;
        }
        
        div.Polaris-Modal-Dialog__Container {
            justify-content: center !important;
        }
    `;

  const POST_PURCHASE = gql`
    query {
      app {
        title
        isPostPurchaseAppInUse
      }
    }
  `;

  const [CheckPostPurchase, { loading, data, errors }] = useLazyQuery(
    POST_PURCHASE,
    {
      fetchPolicy: 'no-cache',
      context: { clientName: 'shopify-link' },
    }
  );

  useEffect(() => {
    CheckPostPurchase();
  }, []);

  useEffect(() => {
    if (data?.app) {
      setRenderBanner(!data?.app?.isPostPurchaseAppInUse);
    }
  }, [data]);

  const handleFormVariantData = (value, index, key) => {
    let variants = formData?.variants;
    variants[index][key] = value;
    if (key == 'discount') {
      variants[index].discountType ||= 'percentage';
    } else if (key == 'localize' && !value) {
      delete variants[index].locale;
    }
    setFormData({ ...formData, variants: variants });
  };

  console.log('formdataaaaa', formData);

  return (
    <div className="upsell_setting_container">
      <Frame>
        {toastContent && (
          <Toast content={toastContent} onDismiss={hideSaveSuccess} />
        )}
        {updated && (
          <ContextualSaveBar
            message="Unsaved changes"
            saveAction={{
              onAction: () => {
                handleSubmit();
              },
              loading: false,
              disabled: false,
            }}
            discardAction={{
              onAction: () => resetForm(),
            }}
          />
        )}
        <Page>
          <Layout>
            <Layout.Section>
              {renderBanner && (
                <div className="action_needed banner_wrapper">
                  <Banner
                    title={
                      <>
                        {data?.app?.title} isn’t the selected app on the
                        checkout post-purchase page.
                      </>
                    }
                    status="warning"
                    onDismiss={() => {
                      setRenderBanner(false);
                    }}
                  >
                    <p>
                      To display this offer on your store’s post-purchase page,
                      select {data?.app?.title} for post-purchase upsells in the
                      checkout settings.
                    </p>
                    <a
                      className="enable_post_checkout_button"
                      href={`https://${domain}/admin/settings/checkout`}
                      target="_blank"
                    >
                      Enable
                    </a>
                  </Banner>
                </div>
              )}
            </Layout.Section>
          </Layout>
          <Layout>
            <Layout.Section>
              <div className="Polaris-Card">
                <div class="main_header_content">
                  <button
                    class="back_button_pointer"
                    onClick={() => {
                      history.push(`/chargezenUpsells/manage`);
                      // if (updated) {
                      //   setLeavePageModal(true);
                      // } else {
                      //   setEditTemplate();
                      //   setEditOffer();
                      // }
                    }}
                  >
                    <span class="icon">
                      <Icon source={MobileBackArrowMajor} color="base" />
                    </span>
                    <span>Back</span>
                  </button>
                  <div className="status_text_wrapper">
                    {formData?.status == 'live' ? 'Live' : 'Draft'}
                  </div>
                  <div className="toggleViewWrapper">
                    <button
                      className="changeDeskMobileView"
                      onClick={() => {
                        setMobileView(!mobileView);
                      }}
                    >
                      <Icon
                        source={mobileView ? DesktopMajor : MobileMajor}
                        color="base"
                      />
                    </button>
                  </div>
                </div>
                {formData.type == 'multiple_products' ? (
                  <MultipleProductHtml
                    mobileView={mobileView}
                    contentBlock={contentBlock}
                    formData={formData}
                    allVariants={allVariants}
                  />
                ) : (
                  <SingleProductHtml
                    mobileView={mobileView}
                    contentBlock={contentBlock}
                    formData={formData}
                    allVariants={allVariants}
                  />
                )}
              </div>
            </Layout.Section>
            {true && (
              <Layout.Section secondary>
                <div className="side_bar_container Polaris-Card">
                  <div className="header_container">
                    {showSchedule ? (
                      <ScheduleOffer
                        setShowSchedule={setShowSchedule}
                        domain={domain}
                        editOffer={editOffer}
                        rowData={formData}
                        fetchUpsell={fetchUpsell}
                        setToastContent={setToastContent}
                        fetchAllOffers={fetchAllOffers}
                        setEditTemplate={setEditTemplate}
                        setEditOffer={setEditOffer}
                        editTemplate={editTemplate}
                        handleSubmit={handleSubmit}
                      />
                    ) : subForm === '' ? (
                      <div className="card_content_wrapper">
                        <Tabs
                          tabs={tabs}
                          selected={selected}
                          onSelect={handleTabChange}
                        >
                          <div className="form_field_wrapper">
                            {tabs[selected].id === 'offer' ? (
                              <>
                                <div className="field_row Polaris-Card__Section">
                                  <div className="field_row_label">Name</div>
                                  <div className="field_row_content">
                                    <TextField
                                      label=""
                                      type="text"
                                      value={formData.name}
                                      onChange={(e) => {
                                        setFormData({ ...formData, name: e });
                                      }}
                                    />
                                  </div>
                                </div>
                                <div
                                  className="field_row navi Polaris-Card__Section"
                                  onClick={() => setSubForm('PRODUCT')}
                                >
                                  <div className="field_row_label">PRODUCT</div>
                                  <div className="field_row_content">
                                    Choose products for the offer.
                                  </div>
                                </div>
                                {formData?.type?.includes('subscription') && (
                                  <>
                                    <div
                                      className="field_row navi Polaris-Card__Section"
                                      onClick={() => setSubForm('SUBSCRIPTION')}
                                    >
                                      <div className="field_row_label">
                                        SUBSCRIPTION
                                      </div>
                                      <div className="field_row_content">
                                        Select selling plan for the selected
                                        products.
                                      </div>
                                    </div>
                                  </>
                                )}
                                <div
                                  className="field_row navi Polaris-Card__Section"
                                  onClick={() => setSubForm('SETTINGS')}
                                >
                                  <div className="field_row_label">
                                    SETTINGS
                                  </div>
                                  <div className="field_row_content">
                                    Manage purchase limit and priority settings.
                                  </div>
                                </div>
                                <div
                                  className="field_row navi Polaris-Card__Section"
                                  onClick={() => setSubForm('CONDITIONS')}
                                >
                                  <div className="field_row_label">
                                    CONDITIONS
                                  </div>
                                  <div className="field_row_content">
                                    Create conditions to trigger the offer.
                                  </div>
                                </div>
                                <div
                                  className="field_row navi Polaris-Card__Section"
                                  onClick={() => setSubForm('DISCOUNT')}
                                >
                                  <div className="field_row_label">
                                    DISCOUNT
                                  </div>
                                  <div className="field_row_content">
                                    Create discounts for the offer.
                                  </div>
                                </div>
                                <>
                                  <div
                                    className="field_row navi Polaris-Card__Section"
                                    onClick={() => {
                                      setSubForm('DESIGN_TEXT');
                                    }}
                                  >
                                    <div className="field_row_label">TEXT</div>
                                    <div className="field_row_content">
                                      Write a headline for the offer.
                                    </div>
                                  </div>
                                  <div
                                    className={`field_row navi Polaris-Card__Section ${
                                      formData?.type == 'single_product'
                                        ? 'hidden'
                                        : ''
                                    }`}
                                    onClick={() => {
                                      setSubForm('DESIGN_CONTENT');
                                    }}
                                  >
                                    <div className="field_row_label">
                                      CONTENT
                                    </div>
                                    <div className="field_row_content">
                                      Add content to support the product.
                                    </div>
                                  </div>
                                  <div
                                    className="field_row navi Polaris-Card__Section"
                                    onClick={() => {
                                      // setSubForm('DESIGN_SETTINGS');
                                    }}
                                    disabled={true}
                                  >
                                    <div className="field_row_label">
                                      DESIGN SETTINGS (comming soon)
                                    </div>
                                    <div className="field_row_content">
                                      Product settings.
                                    </div>
                                  </div>
                                  <div className="field_row Polaris-Card__Section">
                                    <div className="field_row_label">
                                      COLOR (comming soon)
                                    </div>
                                    <div className="field_row_content upsell_color_picker">
                                      <TextField
                                        label="SELECT"
                                        type="color"
                                        value={formData.design_color}
                                        disabled={true}
                                        onChange={(e) => {
                                          setFormData({
                                            ...formData,
                                            design_color: e,
                                          });
                                        }}
                                      />
                                      <TextField
                                        label="ENTER MANUALLY"
                                        type="text"
                                        value={formData?.design_color}
                                        disabled={true}
                                        onChange={(e) => {
                                          setFormData({
                                            ...formData,
                                            design_color: e,
                                          });
                                        }}
                                      />
                                    </div>
                                  </div>
                                  {/* <div className="field_row navi Polaris-Card__Section">
                                    <div className="flex justify-between sidebar_action_buttons">
                                      <Button
                                        onClick={() => {
                                          setShowSchedule(true);
                                        }}
                                      >
                                        Schedule
                                      </Button>
                                    </div>
                                  </div> */}
                                </>
                                <div className="field_row navi Polaris-Card__Section">
                                  <div className="flex justify-between sidebar_action_buttons">
                                    <Button
                                      onClick={() => {
                                        handleSubmit();
                                      }}
                                    >
                                      Save
                                    </Button>
                                    <Button
                                      onClick={() => {
                                        setShowSchedule(true);
                                      }}
                                    >
                                      Publish
                                    </Button>
                                  </div>
                                </div>
                              </>
                            ) : (
                              <></>
                            )}
                          </div>
                        </Tabs>
                      </div>
                    ) : tabs[selected].id === 'offer' ? (
                      <OfferSettings
                        toggleOnButton={toggleOnButton}
                        subForm={subForm}
                        setSubForm={setSubForm}
                        setToggleOnButton={setToggleOnButton}
                        allVariants={allVariants}
                        setAllVariants={setAllVariants}
                        i18n={i18n}
                        initConditionBlock={initConditionBlock}
                        conditionBlock={conditionBlock}
                        setConditionBlock={setConditionBlock}
                        setFormData={setFormData}
                        formData={formData}
                        handleSubmit={handleSubmit}
                        handleFormVariantData={handleFormVariantData}
                      />
                    ) : (
                      <></>
                    )}
                    <DesignSettings
                      toggleOnButton={toggleOnButton}
                      subForm={subForm}
                      setSubForm={setSubForm}
                      setToggleOnButton={setToggleOnButton}
                      contentBlock={contentBlock}
                      setContentBlock={setContentBlock}
                      initContentBlock={initContentBlock}
                      formData={formData}
                      setFormData={setFormData}
                      handleSubmit={handleSubmit}
                      handleFormVariantData={handleFormVariantData}
                    />
                  </div>
                </div>
              </Layout.Section>
            )}
          </Layout>
        </Page>
        <div className="unsavedChangesModal">
          <Modal
            open={leavePageModal}
            onClose={() => handleLeaveModalChange()}
            title="You have unsaved changes"
            primaryAction={{
              content: 'Leave page',
              onAction: () => {
                setEditTemplate();
                setEditOffer();
              },
            }}
            secondaryActions={[
              {
                content: 'cancel',
                onAction: () => {
                  setLeavePageModal(false);
                },
              },
            ]}
          >
            <Modal.Section>
              <TextContainer>
                <p>If you leave this page, all unsaved changes will be lost</p>
              </TextContainer>
            </Modal.Section>
          </Modal>
          <style dangerouslySetInnerHTML={{ __html: modalcss }} />
        </div>
      </Frame>
    </div>
  );
};

export default EditTemplate;
