import React, { useCallback, useEffect, useState } from 'react';
import { gql, useMutation } from '@apollo/client';
import {
  Card,
  Toast,
  ChoiceList,
  Page,
  PageActions,
  TextField,
  Layout,
  Banner,
  List,
  Frame,
  Select,
  Icon,
  Checkbox,
  RadioButton,
  DropZone,
  Stack,
  Thumbnail,
  Caption,
  TextContainer,
  Button,
  Popover,
  ActionList,
} from '@shopify/polaris';
import {
  ChevronRightMinor,
  MobileBackArrowMajor,
  DeleteMajor,
} from '@shopify/polaris-icons';
import { useHistory } from 'react-router-dom';
import './style.css';

const CreateQuickActions = (props) => {
  const {
    i18n,
    editId,
    setEdit,
    setCreateForm,
    detailView,
    setDetailView,
    domain,
    fetchWorkflows,
  } = props;

  const [currentAction, setCurrentAction] = useState();
  const [currentCondition, setCurrentCondition] = useState();

  const [fieldError, setFieldError] = useState({
    title: '',
    pointsAwarded: '',
  });

  const [formData, setFormData] = useState({
    condition: '',
    kiosk_code_block: '',
    action: '',
  });

  const urlBuilderOptions = [
    {
      value: 'view_customer_portal',
      label: 'View your customer portal',
      conditions: [
        {
          label: 'Subscription started on Chargezen',
          value: 'subscription_started',
        },
        {
          label: 'Upcoming order on Chargezen',
          value: 'upcoming_order',
        },
        {
          label: 'Upcoming (prepaid) order on Chargezen',
          value: 'upcoming_prepaid_order',
        },
        {
          label: 'Subscription cancelled on Chargezen',
          value: 'subscription_cancelled',
        },
        {
          label: 'Subscription expired on Chargezen',
          value: 'subscription_expired',
        },
        {
          label: 'Charge error on Chargezen',
          value: 'charge_error_Chargezen',
        },
        {
          label: 'Campaign',
          value: 'campaign',
        },
      ],
    },
    {
      value: 'subscription_paused',
      label: 'Ship next order now',
      conditions: [
        {
          label: 'Subscription started on Chargezen',
          value: 'subscription_started',
        },
        {
          label: 'Upcoming order on Chargezen',
          value: 'upcoming_order',
        },
      ],
    },
    {
      value: 'subscription_resumed',
      label: 'Skip next order',
      conditions: [
        {
          label: 'Subscription started on Chargezen',
          value: 'subscription_started',
        },
        {
          label: 'Upcoming order on Chargezen',
          value: 'upcoming_order',
        },
      ],
    },
    {
      value: 'subscription_cancelled',
      label: 'Add one-time product to the next order',
      conditions: [
        {
          label: 'Subscription started on Chargezen',
          value: 'subscription_started',
        },
        {
          label: 'Upcoming order on Chargezen',
          value: 'upcoming_order',
        },
        {
          label: 'Campaign',
          value: 'campaign',
        },
      ],
    },
    {
      value: 'subscription_expires',
      label: 'Add one-time product to the next order and apply a discount',
      conditions: [
        {
          label: 'Subscription started on Chargezen',
          value: 'subscription_started',
        },
        {
          label: 'Upcoming order on Chargezen',
          value: 'upcoming_order',
        },
        {
          label: 'Campaign',
          value: 'campaign',
        },
      ],
    },
    {
      value: 'subscription_payment_retrying',
      label: 'Swap current subscription',
      conditions: [
        {
          label: 'Subscription started on Chargezen',
          value: 'subscription_started',
        },
        {
          label: 'Upcoming order on Chargezen',
          value: 'upcoming_order',
        },
        {
          label: 'Campaign',
          value: 'campaign',
        },
      ],
    },
    {
      value: 'subscription_payment_last_attempt',
      label: 'Apply discount to next order',
      conditions: [
        {
          label: 'Subscription started on Chargezen',
          value: 'subscription_started',
        },
        {
          label: 'Upcoming order on Chargezen',
          value: 'upcoming_order',
        },
        {
          label: 'Campaign',
          value: 'campaign',
        },
      ],
    },
    {
      value: 'subscription_payment_succeeds',
      label: 'Reactivate cancelled subscription',
      conditions: [
        {
          label: 'Subscription started on Chargezen',
          value: 'subscription_started',
        },
        {
          label: 'Campaign',
          value: 'campaign',
        },
      ],
    },
    {
      value: 'subscription_payment_succeeds',
      label: 'Reactivate cancelled subscription & apply discount code',
      conditions: [
        {
          label: 'Subscription started on Chargezen',
          value: 'subscription_started',
        },
        {
          label: 'Campaign',
          value: 'campaign',
        },
      ],
    },
    {
      value: 'subscription_payment_succeeds',
      label: 'Reactivate cancelled subscription & add one-time product',
      conditions: [
        {
          label: 'Subscription started on Chargezen',
          value: 'subscription_started',
        },
        {
          label: 'Campaign',
          value: 'campaign',
        },
      ],
    },
  ];

  const [showCondtionOption, setShowCondtionOption] = useState(true);
  const [showAddAction, setShowAddAction] = useState(true);
  const [summaryText, setSummaryText] = useState('');
  const [allPlans, setAllPlans] = useState([]);
  const [allSelectedPlanGroups, setAllSelectedPlanGroups] = useState([]);
  const [toastContent, setToastContent] = useState();
  const hideSaveSuccess = useCallback(
    () => history.push(`/customersTab/quickActions`),
    []
  );
  const [allConditions, setAllConditions] = useState([]);
  const history = useHistory();

  const toggleActive = useCallback(
    () => setShowCondtionOption((showCondtionOption) => !showCondtionOption),
    []
  );
  const toggleAddActionAction = useCallback(
    () => setShowAddAction((showAddAction) => !showAddAction),
    []
  );

  const activator = (
    <Button onClick={toggleActive} disclosure>
      Add condition
    </Button>
  );

  const addActionActivator = (
    <Button onClick={toggleAddActionAction} disclosure>
      Add Action
    </Button>
  );

  const handleSubmit = () => {
    if (formData?.action) {
      fetch('/quick_action', {
        method: 'POST',
        body: JSON.stringify({
          quick_action: formData,
          shopify_domain: domain,
        }),
        headers: {
          'Content-Type': 'application/json',
        },
      })
        .then((r) => r.json())
        .then((data) => {
          if (data?.success) {
            setToastContent('Saved Successfully');
          }
        });
    }
  };

  console.log('formData', formData);

  const fetch_workflow = () => {
    fetch(`/fetch_workflow?id=${editId}&shopify_domain=${domain}`, {
      method: 'GET',
    })
      .then((r) => r.json())
      .then((data) => {
        let form_data = data?.subscription_workflows;
        setFormData({ ...form_data });
      });
  };

  const deleteWorkflow = () => {
    fetch(`/destroy_workflow?id=${editId}&shopify_domain=${domain}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((r) => r.json())
      .then((data) => {
        if (data?.status) {
          //   setEdit();
          setToastContent('Deleted Successfully');
        }
      });
  };

  return (
    <div className="create_quick_actions_wrapper">
      <Frame>
        {toastContent && (
          <Toast content={toastContent} onDismiss={hideSaveSuccess} />
        )}

        <Layout>
          <Layout.Section>
            <Card
              title={<div className="heading_title">URL Builder</div>}
              sectioned
            >
              <div className="text_flex_wrapper">
                <Select
                  label="What would you like to do?"
                  options={urlBuilderOptions?.map((option) => {
                    return { value: option?.value, label: option?.label };
                  })}
                  value={formData?.action}
                  onChange={(e) => {
                    setFormData({ ...formData, action: e });
                    let _currentAction = urlBuilderOptions?.find((opt) => {
                      return opt.value == e;
                    });
                    console.log('_currentAction', _currentAction, 'eeeee', e);
                    setCurrentAction(_currentAction);
                  }}
                />
              </div>
              <div className="text_flex_wrapper">
                <Select
                  label=" Will your quick actions be sent via metric trigger or via campaign?"
                  options={currentAction?.conditions?.map((condition) => {
                    return {
                      value: condition?.value,
                      label: condition?.label,
                    };
                  })}
                  value={formData?.condition}
                  onChange={(e) => {
                    setFormData({ ...formData, condition: e });
                    let _currentCondition = currentAction?.conditions?.find(
                      (con) => {
                        return con.value == e;
                      }
                    );
                    console.log(
                      '_currentCondition',
                      _currentCondition,
                      'eeee',
                      e
                    );
                    setCurrentCondition(_currentCondition);
                  }}
                />
              </div>
              <div className="text_flex_wrapper">
                <TextField
                  label=" Klaviyo quick action code block"
                  value={formData?.kiosk_code_block}
                  onChange={(e) => {
                    setFormData({ ...formData, kiosk_code_block: e });
                  }}
                  multiline={4}
                  autoComplete="off"
                />
                <button
                  className="copy_code_block"
                  onClick={() => {
                    () =>
                      navigator.clipboard.writeText(formData?.kiosk_code_block);
                  }}
                >
                  Copy code block
                </button>
              </div>
            </Card>
          </Layout.Section>

          <div className="defauleLayoutSection sdebar-right-cstm">
            <Layout.Section secondary>
              <Card title={i18n?.t('summary')}>
                <Card.Section>
                  <List type="bullet">
                    <List.Item>
                      {formData?.action
                        ? currentAction?.label
                        : 'No action selected'}
                    </List.Item>
                    <List.Item>
                      {formData?.condition
                        ? currentCondition?.label
                        : 'No condition selected'}
                    </List.Item>
                    <List.Item>
                      {formData?.kiosk_code_block
                        ? formData?.kiosk_code_block
                        : '--'}
                    </List.Item>
                  </List>
                </Card.Section>
              </Card>
            </Layout.Section>
          </div>
          <Layout.Section>
            <PageActions
              primaryAction={{
                content: i18n.t('save'),
                onClick: () => {
                  handleSubmit();
                },
              }}
              // secondaryActions={[
              //   editId
              //     ? {
              //         content: i18n.t('delete'),
              //         destructive: true,
              //         onClick: () => {
              //           deleteWorkflow();
              //         },
              //       }
              //     : {},
              // ]}
            />
          </Layout.Section>
        </Layout>
      </Frame>
    </div>
  );
};

export default CreateQuickActions;
