import { Icon, Layout, Stack, TextStyle } from '@shopify/polaris';
import React from 'react';
import { MobileBackArrowMajor } from '@shopify/polaris-icons';
const Legal = ({ handleBack, i18n }) => {
  return (
    <Layout>
      Legal Content
    </Layout>
  );
};

export default Legal;
