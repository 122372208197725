import React, { useState, useEffect, useCallback, useRef } from 'react';
import {
  Card,
  Select,
  TextField,
  ButtonGroup,
  Button,
  Stack,
  Heading,
  TextStyle,
  FormLayout,
  Layout,
  TextContainer,
  Icon,
  Banner,
  List,
  Form,
  ContextualSaveBar,
  Toast,
} from '@shopify/polaris';
import Switch from 'react-switch';
import { CircleLeftMajor } from '@shopify/polaris-icons';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { data } from 'jquery';
import { gql, useMutation } from '@apollo/client';
import EmailEditor from 'react-email-editor';
import PreviewEmail from '../../setting/PreviewEmail';
import PixelIcon from '../../../images/PixelIcon';
import { Formik } from 'formik';
import * as yup from 'yup';
import HideSideNav from '../../helpers/HideSideNav';
import { useParams, useHistory } from 'react-router-dom';

const EmailNotificationsDetails = (props) => {
  const {
    i18n,
    currentIndex,
    refetch,
    setSelectedIndex,
    filteredEmailNotifications,
    setSetting,
    setting,
    saveSuccess,
    setSaveSuccess,
    setDetailView,
    domain,
  } = props;
  const { edit_id } = useParams();
  const history = useHistory();
  const emailEditorRef = useRef(null);
  const [submitForm, setSubmitForm] = useState(false);
  const [formData, setFormData] = useState(null);
  const [formErrors, setFormErrors] = useState([]);
  const hideSaveSuccess = useCallback(() => {
    setSaveSuccess(false);
    if (edit_id) {
      history.push(`${window.location.pathname.replace(`/${edit_id}`, '')}`);
    }
  }, []);
  const validationSchema = yup.object().shape({
    // internalName: yup.string().required().label('Internal name'),
  });

  const initialValues = {
    emailNotifications: [
      {
        emailMessage: '',
        emailSubject: '',
        fromEmail: '',
        fromName: '',
        name: 'Subscription Activation',
        slug: 'customer',
        status: true,
        __typename: 'EmailNotification',
      },
      {
        emailMessage: '',
        emailSubject: '',
        fromEmail: '',
        fromName: '',
        name: 'Subscription Activation',
        slug: 'customer',
        status: true,
        __typename: 'EmailNotification',
      },
      {
        emailMessage: '',
        emailSubject: '',
        fromEmail: '',
        fromName: '',
        name: 'Subscription Activation',
        slug: 'store_owner',
        status: true,
        __typename: 'EmailNotification',
      },
      {
        emailMessage: '',
        emailSubject: '',
        fromEmail: '',
        fromName: '',
        name: 'Subscription Activation',
        slug: 'store_owner',
        status: true,
        __typename: 'EmailNotification',
      },
    ],
  };

  const [UPDATE_SETTING, setUpdateSetting] = useState(gql`
    mutation ($input: UpdateSettingInput!) {
      updateSetting(input: $input) {
        setting {
          paymentRetries
          paymentDelayRetries
          maxFailStrategy
          orderCancelOption
          dayOfProduction
          deliveryIntervalAfterProduction
          eligibleWeekdaysForDelivery
          accountPortalOption
          portalTheme
          activeSubscriptionBtnSeq

          styleAccountProfile
          styleSidebar
          styleSubscription
          styleUpsell
          styleSidebarPages
          navigationDelivery
          shipingAddress
          upcomingOderDate
          upcomingQuantity
          productToSubscription
          changeVariant
          swapProduct
          shipment
          frequency
          facingFrequencyOption
          oneTimePurchase
          availablePurchase
          discount
          subscriptionCancellation
          cancellationEmailContact
          allowCancelAfter
          reactiveSubscription
          emailService
          reasonsCancels {
            id
            title
            returnContent
            _destroy
          }
          additionalSendAccountAfterCheckout
          bbcStoreowner
          ccStoreowner
          sendShopifyReceipt
          sendFullfillment

          activateDunningForCards
          dunningPeriod
          retryFrequency
          happensToSubscriptions
          happensToInvoices
          dunningFutureTrialSubscriptions
          dunningOneTimeInvoice
          activateDunningDirectDebit
          directDebitSubscription
          directDebitInvoice
          dunningOfflineConfigure
          dunningOfflinePeiod
          dunningOfflineSubscription
          dunningOfflineInvoice
          dunningCardConfigure
          chooseAutomaticRetryMode

          storeName
          storeEmail
          storefrontPassword

          checkoutSubscriptionTerms
          emailSubscriptionTerms
          applePaySubscriptionTerms
          showPromoButton
          promoButtonContent
          promoButtonUrl
          contactBoxContent
          promoTagline1Content
          promoTagline2Content

          showSubscription
          showDeliverySchedule
          showOrderHistory
          showAddress
          showBilling
          showAccount
          pauseSubscription
          cancelLater
          pauseLater
          showReward
          showTrackOrder

          designType
        }
      }
    }
  `);

  const [updateSetting] = useMutation(UPDATE_SETTING);

  useEffect(() => {
    setDetailView(false);
    console.log(
      'filteredEmailNotifications',
      filteredEmailNotifications,
      'currentIndex',
      currentIndex
    );
    if (filteredEmailNotifications[currentIndex]?.id) {
      setFormData({ ...{ emailNotifications: filteredEmailNotifications } });
    }
    console.log(
      'filteredEmailNotifications',
      filteredEmailNotifications[currentIndex],
      currentIndex
    );
    console.log(
      '{ emailNotifications: filteredEmailNotifications[currentIndex] }',
      { emailNotifications: filteredEmailNotifications[currentIndex] }
    );
  }, [filteredEmailNotifications, currentIndex]);

  console.log('formData', formData);

  const onLoad = () => {
    // editor instance is created
    // you can load your template here;
    if (filteredEmailNotifications[currentIndex]?.designJson) {
      console.log(
        'filteredEmailNotifications[currentIndex]?.designJson',
        filteredEmailNotifications[currentIndex]?.designJson
      );
      try {
        const templateJson = JSON.parse(
          filteredEmailNotifications[currentIndex]?.designJson
        );
        console.log('template', templateJson);
        emailEditorRef?.current?.editor?.loadDesign(templateJson);
      } catch (e) {
        console.log('error', e);
      }
    }
  };

  useEffect(() => {
    onLoad();
  }, [filteredEmailNotifications]);

  const onReady = () => {
    // editor is ready
    console.log('onReady');
  };

  const codeTextArea = useRef(null);
  const [valueFromName, setValueFromName] = useState();
  const [showEditorCode, setShowEditorCode] = useState(false);
  const [previewHtml, setPreviewHtml] = useState('');
  const [previewActive, setPreviewActive] = useState(false);

  const [selectedSettingEnabled, setSelectedSettingEnabled] = useState(false);
  const [editorState, setEditorState] = React.useState(
    EditorState.createEmpty()
  );

  useEffect(() => {
    setUpdateSetting(gql`
      mutation ($input: UpdateSettingInput!) {
        updateSetting(input: $input) {
          setting {
            emailNotifications {
              name
              status
              fromName
              fromEmail
              emailSubject
              emailMessage
              slug
              description
              designJson
            }
          }
        }
      }
    `);
  }, []);

  useEffect(() => {
    const contentBlock = htmlToDraft(
      filteredEmailNotifications[currentIndex]?.emailMessage || ''
    );
    if (contentBlock) {
      const contentState = ContentState.createFromBlockArray(
        contentBlock.contentBlocks
      );
      const editorState = EditorState.createWithContent(contentState);
      setEditorState(editorState);
    }
  }, []);

  const triggerPreviewEmail = () => {
    document
      .querySelector('.email_editor')
      ?.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest' });
    emailEditorRef?.current?.editor?.showPreview('desktop');
  };

  return (
    <>
      {formErrors.length > 0 && (
        <>
          <Banner
            title={i18n.t('setting_could_not_be_saved')}
            status="critical"
          >
            <List type="bullet">
              {formErrors.map((message, index) => (
                <List.Item key={index}>{message.message}</List.Item>
              ))}
            </List>
          </Banner>
          <br />
        </>
      )}
      {formData && (
        <Formik
          validationSchema={validationSchema}
          initialValues={formData ? formData : initialValues}
          enableReinitialize
          onSubmit={(
            values,
            { setSubmitting, setDirty, resetForm, touched }
          ) => {
            let allSettings = setting;
            allSettings.emailNotifications = values?.emailNotifications;
            const newValues = {
              ...allSettings,
              navigationDelivery:
                values.navigationDelivery || 'storeowner_and_customer',
              reactiveSubscription:
                values.reactiveSubscription || 'storeowner_and_customer',
              shipingAddress:
                values.shipingAddress || 'storeowner_and_customer',
              shipment: values.shipment || 'storeowner_and_customer',
              subscriptionCancellation:
                values.subscriptionCancellation || 'storeowner_and_customer',
              swapProduct: values.swapProduct || 'storeowner_and_customer',
              upcomingQuantity:
                values.upcomingQuantity || 'storeowner_and_customer',
              delayOrder: values.delayOrder || 'storeowner_and_customer',
              pauseSubscription:
                values.pauseSubscription || 'storeowner_and_customer',
            };
            delete newValues?.recurringChargeStatus;
            delete newValues?.chargeConfirmationLink;
            delete newValues?.plan;
            updateSetting({ variables: { input: { params: newValues } } })
              .then((resp) => {
                const data = resp.data;
                const errors = data.errors;

                if (errors) {
                  setFormErrors(errors);
                } else {
                  setSaveSuccess(true);
                  console.log('oke');
                  refetch();
                  setSelectedIndex();
                  setDirty(false);
                  // resetForm({ });
                  console.log('kxjckxjck');
                }

                setSubmitting(false);
              })
              .catch((error) => {
                setSubmitting(false);
                setFormErrors(error);
              });
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            setFieldValue,
            resetForm,
            dirty,
            setDirty,
            formik,
            /* and other goodies */
          }) => (
            <Form onSubmit={handleSubmit}>
              {dirty && (
                <ContextualSaveBar
                  alignContentFlush={true}
                  message={i18n.t('unsaved_changes')}
                  saveAction={{
                    onAction: () => handleSubmit(),
                    loading: isSubmitting,
                    disabled: false,
                  }}
                  discardAction={{
                    onAction: resetForm,
                  }}
                />
              )}

              {saveSuccess && (
                <Toast
                  content={i18n.t('setting_is_saved')}
                  onDismiss={hideSaveSuccess}
                />
              )}
              <>
                {/* new settings bar */}
                <div className="settings-container">
                  <>
                    <Layout>
                      <Layout.Section>
                        <PreviewEmail
                          i18n={i18n}
                          previewActive={previewActive}
                          setPreviewActive={setPreviewActive}
                          previewHtml={previewHtml}
                        />
                        <Card>
                          <Card.Section>
                            <Stack vertical>
                              <Stack.Item>
                                <div
                                  className="back-btn-container"
                                  onClick={() => {
                                    setSelectedIndex(null);
                                    if (edit_id) {
                                      history.push(
                                        `${window.location.pathname
                                          .replace(`/${edit_id}`, '')
                                          .replace.replace(`/${domain}`, '')}`
                                      );
                                    }
                                  }}
                                >
                                  <Icon source={CircleLeftMajor} color="base" />
                                  <p>{i18n.t('go_back')}</p>
                                </div>
                              </Stack.Item>
                              <Stack.Item>
                                <TextStyle variation="subdued">
                                  {
                                    values.emailNotifications[currentIndex]
                                      ?.description
                                  }
                                </TextStyle>
                              </Stack.Item>
                              <Stack.Item>
                                <Heading h4>{i18n.t('email_content')}</Heading>
                              </Stack.Item>
                              <Stack.Item>
                                <FormLayout>
                                  <TextField
                                    label={i18n.t('from_name')}
                                    value={
                                      values?.emailNotifications[currentIndex]
                                        ?.fromName
                                        ? values.emailNotifications[
                                            currentIndex
                                          ]?.fromName
                                        : ''
                                    }
                                    onChange={(e) =>
                                      setFieldValue(
                                        `emailNotifications[${currentIndex}].fromName`,
                                        e
                                      )
                                    }
                                    name="from_name"
                                  />
                                  <TextField
                                    label="From Email"
                                    value={
                                      values.emailNotifications[currentIndex]
                                        ?.fromEmail
                                        ? values.emailNotifications[
                                            currentIndex
                                          ]?.fromEmail
                                        : null
                                    }
                                    onChange={(e) =>
                                      setFieldValue(
                                        `emailNotifications[${currentIndex}].fromEmail`,
                                        e
                                      )
                                    }
                                    inputMode="email"
                                    name="from_email"
                                  />
                                  <TextField
                                    label="Email Subject"
                                    value={
                                      values.emailNotifications[currentIndex]
                                        ?.emailSubject
                                        ? values.emailNotifications[
                                            currentIndex
                                          ]?.emailSubject
                                        : null
                                    }
                                    onChange={(e) =>
                                      setFieldValue(
                                        `emailNotifications[${currentIndex}].emailSubject`,
                                        e
                                      )
                                    }
                                    name="email_subject"
                                  />
                                </FormLayout>
                              </Stack.Item>
                            </Stack>
                          </Card.Section>
                        </Card>
                      </Layout.Section>
                      <Layout.Section secondary>
                        <Card>
                          <Card.Section>
                            <TextContainer>
                              <Heading h4>Status</Heading>
                              <Stack distribution="equalSpacing">
                                {/* <Button primary>Enabled</Button> */}
                                {values.emailNotifications[currentIndex]
                                  ?.status ? (
                                  <Button primary onClick={() => {}}>
                                    {i18n.t('points_programs.enabled')}
                                  </Button>
                                ) : (
                                  <Button onClick={() => {}}>
                                    {i18n.t('points_programs.disabled')}
                                  </Button>
                                )}
                                <Switch
                                  // onChange={setFieldValue(
                                  //   `emailNotifications[${filteredEmailNotifications[currentIndex]}].status`,
                                  //   !filteredEmailNotifications[currentIndex]?.status
                                  // )}
                                  checked={
                                    values.emailNotifications[currentIndex]
                                      ?.status
                                  }
                                  onColor="#86d3ff"
                                  onHandleColor="#2693e6"
                                  handleDiameter={30}
                                  uncheckedIcon={false}
                                  checkedIcon={false}
                                  boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                  activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                />
                              </Stack>
                              <br />
                              <TextStyle variation="subdued">
                                {i18n.t('condition_email_notification')}
                              </TextStyle>
                            </TextContainer>
                          </Card.Section>
                        </Card>

                        <Card>
                          <Card.Section>
                            <TextContainer>
                              <Heading h4>{i18n.t('actions')}</Heading>
                              <Button fullWidth>
                                {i18n.t('send_test_email')}
                              </Button>
                              <Button
                                fullWidth
                                onClick={() => {
                                  triggerPreviewEmail();
                                }}
                              >
                                {i18n.t('preview')}
                              </Button>
                            </TextContainer>
                          </Card.Section>
                        </Card>
                      </Layout.Section>
                      <div className="custom_email_notification_bottom_section">
                        <div className="email_message_wrapper">
                          <Card>
                            <Card.Section>
                              <label>{i18n.t('email_message')}</label>
                              <div
                                className="email_editor"
                                style={{ overflow: 'auto' }}
                              >
                                <EmailEditor
                                  ref={emailEditorRef}
                                  onLoad={onLoad}
                                  onReady={onReady}
                                />
                              </div>
                              <Stack>
                                <Stack.Item>
                                  <ButtonGroup>
                                    <Button
                                      primary
                                      onClick={() => {
                                        setSelectedIndex(null);
                                        if (edit_id) {
                                          history.push(
                                            `${window.location.pathname.replace(
                                              `/${edit_id}`,
                                              ''
                                            )}`
                                          );
                                        }
                                      }}
                                    >
                                      {i18n.t('cancel')}
                                    </Button>
                                    <Button
                                      onClick={() => {
                                        emailEditorRef.current.editor.exportHtml(
                                          (data) => {
                                            const { design, html } = data;
                                            setFieldValue(
                                              `emailNotifications[${currentIndex}].emailMessage`,
                                              html
                                            );
                                            setFieldValue(
                                              `emailNotifications[${currentIndex}].designJson`,
                                              JSON.stringify(design)
                                            );
                                            handleSubmit();
                                          }
                                        );
                                      }}
                                    >
                                      {i18n.t('save_changes')}{' '}
                                    </Button>
                                  </ButtonGroup>
                                </Stack.Item>
                              </Stack>
                            </Card.Section>
                          </Card>
                        </div>
                        <div className="email_variables_wrapper">
                          {process.env.APP_TYPE == 'public' && (
                            <Card>
                              <Card.Section>
                                <TextContainer>
                                  <Heading h4>
                                    {i18n.t('chargezen_variable_help')}
                                  </Heading>
                                  <br />
                                  <TextStyle variation="subdued">
                                    {i18n.t('email_compiled_message')}
                                  </TextStyle>
                                </TextContainer>
                              </Card.Section>
                            </Card>
                          )}
                        </div>
                      </div>
                    </Layout>
                  </>
                </div>
              </>
            </Form>
          )}
        </Formik>
      )}
      <HideSideNav />
    </>
  );
};
export default EmailNotificationsDetails;
