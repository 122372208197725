import React, { useCallback, useContext, useEffect, useState } from 'react';
import {
  Card,
  Select,
  Heading,
  FormLayout,
  Button,
  Icon,
  Modal,
  TextField,
  Stack,
  Toast,
  Frame,
  Checkbox,
  RadioButton,
} from '@shopify/polaris';
import '../loyalty-rewards/loyalty.css';
import { DomainContext } from '../domain-context';
import { GetStartedIcon } from '../layout/CustomIcon';
import dayjs from 'dayjs';
import SearchProduct from '../upsell/SearchProduct';
import Preview from '../plans/Preview';
import EditUpsells from './EditUpsells';
import ManageButtons from './ManageButtons';
import ManageTranslations from './ManageTranslations';
import ManageStyle from './ManageStyle';
import PixelIcon from '../../images/PixelIcon';
import OrderDetailModal from './OrderDetailModal';
import ManageCancelledSubscription from './ManageCancelledSubscription';
import { ProfileMajor, PaymentsMajor } from '@shopify/polaris-icons';
import { useParams, useHistory } from 'react-router-dom';
import CustomerPostalSettingSideBar from './CustomerPostalSettingSideBar';
import CancellationFlow from '../CancellationFlow';
import PasswordlessLogin from '../PasswordlessLogin';
import RewardsTimeLine from './RewardsTimeLine';
import Upsells from './Upsells';
import RewardsTimeLineCreate from './RewardsTimeLineCreate';
import CancellationSettings from '../CancellationFlow/CancellationSettings';

const Customize = (props) => {
  const { domain, i18n, selectedTemplateId, formatDate } = props;
  const history = useHistory();
  const { selectedTab, edit_id } = useParams();
  const [active, setActive] = useState(false);
  const [statusActive, setStatusActive] = useState(false);
  const [showEditUpsell, setShowEditUpsell] = useState(false);
  const toggleActive = useCallback(() => {
    setActive((active) => !active);
    setToasContent('');
  }, []);
  const [toastContent, setToasContent] = useState();
  const toggleStatusActive = useCallback(() => {
    setStatusActive((statusActive) => !statusActive);
    setToasContent('');
  }, []);
  const [updated, setUpdated] = useState(false);
  const [allProducts, setAllProducts] = useState([]);

  const [loyaltyId, setLoyaltyId] = useState(null);

  const [allImages, setAllImages] = useState([]);
  const [apiData, setApiData] = useState();
  const [selectedImages, setSelectedImages] = useState([]);
  const [showHeader, setShowHeader] = useState(true);
  const [allRewards, setAllRewards] = useState([]);
  const upsellInitValues = {
    title: '',
    subtitle: '',
    button_text: '',
    status: 'active',
    discount_type: 'percentage',
    upsell_type: 'pop_up',
    product_images: [],
    shopify_domain: domain,
    template_id: selectedTemplateId,
    variant_images: [],
    popup_translations: {}
  }
  const rewardsInitValues = {
    title: '',
    reward_trigger: 'consecutive_billing_cycles',
    reward_type: "free_product",
    trigger_value: 1,
    status: 'active',
    variant_images: [],
  }
  const [upsellFormData, setUpsellFormData] = useState(upsellInitValues);
  const [upsellRowData, setUpsellRowData] = useState(upsellInitValues);
  const [showEditRewards, setShowEditRewards] = useState(false);
  const [rewardsRowData, setRewardsRowData] = useState(rewardsInitValues);
  const [files, setFiles] = useState([]);
  const [faqFormData, setFaqFormData] = useState([]);
  const [tierBenefitsFormData, setTierBenefitsFormData] = useState([]);
  const [faqFieldErrors, setFaqFieldErrors] = useState([]);
  const [nameError, setNameError] = useState(false);
  const [selectedFooterImage, setSelectedFooterImage] = useState();
  const [footerFormData, setfooterFormData] = useState({
    title: '',
    paragraph: '',
    button_title: '',
    button_url: '',
    template_id: selectedTemplateId,
    shopify_domain: domain,
    footer_css: '',
  });
  const [worksFormData, setworksFormData] = useState([]);
  const [worksFieldErrors, setworksFieldErrors] = useState([]);

  const [purchase, setPurchase] = useState(0);
  const [account, setAccount] = useState(0);
  const [socialMediaLike, setSocialMediaLike] = useState(0);
  const [socialMediaFollow, setSocialMediaFollow] = useState(0);
  const [upsell, setUpsell] = useState(0);
  const [smartySms, setSmartySms] = useState(0);
  const [referFriend, setReferFriend] = useState(0);
  const [mailingList, setMailingList] = useState(0);
  const [customRule, setCustomRule] = useState(0);

  const [discount, setDiscount] = useState(0);
  const [point, setPoint] = useState(0);
  const [startingDate, setStartingDate] = useState(null);
  const [endingDate, setEndingDate] = useState(null);

  // refer_a_friend Campaign
  const [referCampaignStatus, setReferCampaignStatus] = useState(false);
  const [referCampaignDate, setReferCampaignDate] = useState(null);
  const [referrerReward, setReferrerReward] = useState(0);
  const [referrerPoints, setReferrerPoints] = useState(0);
  const [referrerFixedPoints, setReferrerFixedPoints] = useState(0);
  const [pointsToReferrer, setPointsToReferrer] = useState(0);
  const [pointsToReferred, setPointsToReferred] = useState(0);
  const [selectedReward, setSelectedReward] = useState('coupon');
  const [selectedCoupon, setSelectedCoupon] = useState('5');
  const [applyCoupon, setApplyCoupon] = useState(1);
  const [rewardText, setRewardText] = useState('');
  const [description, setDescription] = useState('');
  const [icon, setIcon] = useState('fa-user');
  const [callToActionText, setCallToActionText] = useState('');
  const [landingURL, setLandingURL] = useState('');
  const [landingURLQueryParams, setLandingURLQueryParams] = useState('');
  const [defaultTwitterMsg, setDefaultTwitterMsg] = useState('');
  const [headerForFbMsg, setHeaderForFbMsg] = useState('');
  const [descriptionForFbMsg, setDescriptionForFbMsg] = useState('');
  const [defaultSmsMsg, setDefaultSmsMsg] = useState('');
  const [tierBenefits, setTierBenefits] = useState({});
  const [customCss, setCustomCss] = useState({
    hero_css: '',
    ways_to_redeem_css: '',
    how_it_works: '',
    ways_to_earn_css: '',
    loyalty_tiers_css: '',
    faq: '',
    footer: '',
  });
  const [bulkStatus, setBulkStatus] = useState({
    hero_status: true,
    ways_to_redeem_status: true,
    how_it_works_status: true,
    ways_to_earn_status: true,
    loyalty_tiers_status: true,
    faq_status: true,
    footer_status: true,
  });
  const [zennportal_settings, setZennportalSetting] = useState({});

  const settingsSideBar = [
    {
      icon: <Icon source={ProfileMajor} tone="base" />,
      title: i18n.t('zennportal_form.style_setting'),
      tab: 'style_settings',
    },
    {
      icon: <Icon source={PaymentsMajor} tone="base" />,
      title: i18n.t('zennportal_form.side_bar_section'),
      tab: 'button_settings',
    },
    {
      icon: <Icon source={PaymentsMajor} tone="base" />,
      title: 'Authentication Page',
      tab: 'authentication_page',
    },
    {
      icon: <Icon source={PaymentsMajor} tone="base" />,
      title: 'Passwordless Login',
      tab: 'passwordless_login',
      noHeader: true,
    },
    {
      icon: <Icon source={PaymentsMajor} tone="base" />,
      title: 'Upsells',
      tab: 'portal_upsells',
    },
    {
      icon: <Icon source={PaymentsMajor} tone="base" />,
      title: 'Rewards Timeline',
      tab: 'rewards_timeline',
      noHeader: true,
    },
    {
      icon: <Icon source={PaymentsMajor} tone="base" />,
      title: 'Order detail Modal',
      tab: 'order_detail_modal',
    },
    {
      icon: <Icon source={PaymentsMajor} tone="base" />,
      title: 'Cancellation flow',
      tab: 'cancellation_flow',
      noHeader: true,
    },
    {
      icon: <Icon source={PaymentsMajor} tone="base" />,
      title: 'Cancellation setting',
      tab: 'cancellation_setting',
      noHeader: true,
    },
    {
      icon: <Icon source={PaymentsMajor} tone="base" />,
      title: 'Translation settings',
      tab: 'translation_settings',
    },



  ];

  const fetchCustomerPortalSettings = () => {
    fetch(
      '/customer_portal_settings_index?shopify_domain=' +
      domain +
      '&template_id=' +
      selectedTemplateId,
      {
        method: 'GET',
      }
    )
      .then((response) => response.json())
      .then((data) => {
        setApiData(data);
        setZennportalSetting(data?.customer_portal_setting);
      });
  };

  const deleteCustomerPortalUpsell = (upsell_id) => {
    fetch(
      '/delete_customer_portal_upsell?shopify_domain=' +
      domain +
      '&id=' +
      upsell_id,
      {
        method: 'DELETE',
      }
    )
      .then((response) => response.json())
      .then((data) => {
        setActive(true);
        setToasContent('Deleted successfully.');
        fetchCustomerPortalSettings();
      });
  };

  useEffect(() => {
    if (!selectedTab) {
      history.push(`/zenn/${edit_id}/${settingsSideBar[0]?.tab}`);
    }
    fetchCustomerPortalSettings();
  }, []);

  // create_account Campaign
  const [accountCampaignStatus, setAccountCampaignStatus] = useState(false);
  const [accountCampaignDate, setAccountCampaignDate] = useState(null);
  const [campaignName, setCampaignName] = useState('');
  const [descText, setDescText] = useState('10');
  const [completedMsg, setCompletedMsg] = useState('offer-completed');
  const [maxTimesCompletion, setMaxTimesCompletion] = useState('no-max');

  const [showAccountCampaign, setShowAccountCampaign] = useState(false);
  const accountActivator = (
    <Button primary outline size="slim" onClick={() => getAccountCampaign()}>
      Edit
    </Button>
  );

  const getAccountCampaign = () => {
    setShowAccountCampaign(true);
    fetch(`/loyalty_campaign?name=create_account&shopify_domain=${domain}`, {
      method: 'GET',
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status) {
          setAccountCampaignStatus(data.campaign?.status);
          setAccountCampaignDate(data.campaign?.updated_at);
          setSelectedReward(data.campaign?.selected_reward);
          setPointsToReferrer(data.campaign?.points_to_referrer.toString());
          setCampaignName(data.campaign?.campaign_name);
          setRewardText(data.campaign?.reward_text);
          setDescText(data.campaign?.description_text);
          setIcon(data.campaign?.icon);
          setCompletedMsg(data.campaign?.completed_msg);
          setMaxTimesCompletion(data.campaign?.max_times_completion);
        }
      });
  };

  // happy_birthday Campaign
  const [birthdayCampaignStatus, setBirthdayCampaignStatus] = useState(false);
  const [birthdayCampaignDate, setBirthdayCampaignDate] = useState(null);

  const [showBirthdayCampaign, setShowBirthdayCampaign] = useState(false);
  const birthdayActivator = (
    <Button primary outline size="slim" onClick={() => getBirthdayCampaign()}>
      Edit
    </Button>
  );

  const getBirthdayCampaign = () => {
    setShowBirthdayCampaign(true);
    fetch(`/loyalty_campaign?name=happy_birthday&shopify_domain=${domain}`, {
      method: 'GET',
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status) {
          setBirthdayCampaignStatus(data.campaign?.status);
          setBirthdayCampaignDate(data.campaign?.updated_at);
          setSelectedReward(data.campaign?.selected_reward);
          setPointsToReferrer(data.campaign?.points_to_referrer.toString());
          setCampaignName(data.campaign?.campaign_name);
          setRewardText(data.campaign?.reward_text);
          setDescText(data.campaign?.description_text);
          setDefaultSmsMsg(data.campaign?.default_sms_msg);
          setDescription(data.campaign?.description);
          setApplyCoupon(data.campaign?.apply_coupon.toString());
          setIcon(data.campaign?.icon);
          setCallToActionText(data.campaign?.call_to_action_text);
        }
      });
  };

  const saveBirthdayCampaign = () => {
    fetch('/loyalty_campaign', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        name: 'happy_birthday',
        selected_reward: selectedReward,
        points_to_referrer: pointsToReferrer,
        campaign_name: campaignName,
        reward_text: rewardText,
        description_text: descText,
        default_sms_msg: defaultSmsMsg,
        description: description,
        apply_coupon: applyCoupon,
        icon: icon,
        call_to_action_text: callToActionText,
        shopify_domain: domain,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status) {
          toggleActive();
        }
      });
  };

  // make_a_purchase Campaign
  const [purchaseCampaignStatus, setPurchaseCampaignStatus] = useState(false);
  const [purchaseCampaignDate, setPurchaseCampaignDate] = useState(null);
  const [customerFixedPoints, setCustomerFixedPoints] = useState(0);
  const [requiredProduct, setRequiredProduct] = useState('');
  const [requireProdReward, setRequireProdReward] = useState('entire-cart');
  const [customerTag, setCustomerTag] = useState('');
  const [customerTagList, setCustomerTagList] = useState('');
  const [orderTag, setOrderTag] = useState('');
  const [orderTagList, setOrderTagList] = useState('');
  const [limit, setLimit] = useState('exactly');
  const [orders, setOrders] = useState(0);
  const [maxTimesPerUser, setMaxTimesPerUser] = useState('no-max');
  const [timeBetween, setTimeBetween] = useState('5-days');
  const [countdownMessage, setCountdownMessage] = useState('24-hours');

  const [showPurchaseCampaign, setShowPurchaseCampaign] = useState(false);
  const purchaseActivator = (
    <Button primary outline size="slim" onClick={() => getPurchaseCampaign()}>
      Edit
    </Button>
  );
  const [percentPointsReferrer, setPercentPointsReferrer] = useState('');
  const [mapProductsPoints, setMapProductsPoints] = useState('');

  const getPurchaseCampaign = () => {
    setShowPurchaseCampaign(true);
    fetch(`/loyalty_campaign?name=make_a_purchase&shopify_domain=${domain}`, {
      method: 'GET',
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status) {
          setPurchaseCampaignStatus(data.campaign?.status);
          setPurchaseCampaignDate(data.campaign?.updated_at);
          setCustomerFixedPoints(
            data.campaign?.customer_fixed_points.toString()
          );
          setPointsToReferrer(data.campaign?.points_to_referrer.toString());
          setCampaignName(data.campaign?.campaign_name);
          setRewardText(data.campaign?.reward_text);
          setDescText(data.campaign?.description_text);
          setRequiredProduct(data.campaign?.required_product);
          setRequireProdReward(data.campaign?.required_product_reward);
          setCustomerTag(data.campaign?.customer_tag);
          setCustomerTagList(data.campaign?.customer_tag_list);
          setOrderTag(data.campaign?.order_tag);
          setOrderTagList(data.campaign?.order_tag_list);
          setLimit(data.campaign?.limit);
          setOrders(data.campaign?.orders.toString());
          setMaxTimesCompletion(data.campaign?.max_times_completion);
          setMaxTimesPerUser(data.campaign?.max_times_per_user);
          setTimeBetween(data.campaign?.time_between);
          setCountdownMessage(data.campaign?.countdown_message);
        }
      });
  };

  const savePurchaseCampaign = () => {
    fetch('/loyalty_campaign', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        shopify_domain: domain,
        name: 'make_a_purchase',
        customer_fixed_points: customerFixedPoints,
        points_to_referrer: pointsToReferrer,
        campaign_name: campaignName,
        reward_text: rewardText,
        description_text: descText,
        required_product: requiredProduct,
        required_product_reward: requireProdReward,
        customer_tag: customerTag,
        customer_tag_list: customerTagList,
        order_tag: orderTag,
        order_tag_list: orderTagList,
        limit: limit,
        orders: orders,
        max_times_completion: maxTimesCompletion,
        max_times_per_user: maxTimesPerUser,
        time_between: timeBetween,
        countdown_message: countdownMessage,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status) {
          toggleActive();
        }
      });
  };

  // Customize Coupon
  const [couponValue, setCouponValue] = useState('');
  const [rewardCustomers, setRewardCustomers] = useState('redeem-points');
  const [couponType, setCouponType] = useState('fixed-amount');
  const [discountAmount, setDiscountAmount] = useState(0);
  const [couponName, setCouponName] = useState('');
  const [couponPoints, setCouponPoints] = useState('');
  const [couponText, setCouponText] = useState('');
  const [applyDiscount, setApplyDiscount] = useState('any-product');
  const [cartAmount, setCartAmount] = useState(null);
  const [customerList, setCustomerList] = useState(null);
  const [applyDiscountPer, setApplyDiscountPer] = useState('cart');
  const [discountCodeUsage, setDiscountCodeUsage] = useState('1');
  const [couponCodeExpire, setCouponCodeExpire] = useState('never');
  const [couponRestriction, setCouponRestriction] = useState('0');
  const [codePrefix, setCodePrefix] = useState('');
  const [codeLength, setCodeLength] = useState(1);
  const [couponCodeIntro, setCouponCodeIntro] = useState('redeem');

  const [showCustomizeCoupon, setShowCustomizeCoupon] = useState(false);

  const getCustomizeCoupon = (value) => {
    setCouponValue(value);
    setShowCustomizeCoupon(true);
    fetch('/loyalty_coupon?name=' + value + '-off&shopify_domain=' + domain, {
      method: 'GET',
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status) {
          setRewardCustomers(data.coupon?.reward_customers);
          setCouponType(data.coupon?.coupon_type);
          setDiscountAmount(data.coupon?.discount_amount.toString());
          setCouponName(data.coupon?.coupon_name);
          setDescription(data.coupon?.description);
          setCouponPoints(data.coupon?.coupon_points);
          setCouponText(data.coupon?.coupon_text);
          setApplyDiscount(data.coupon?.apply_discount);
          setCartAmount(data.coupon?.cart_amount.toString());
          setCustomerList(data.coupon?.customer_list);
          setApplyDiscountPer(data.coupon?.apply_discount_per);
          setDiscountCodeUsage(data.coupon?.discount_code_usage);
          setCouponCodeExpire(data.coupon?.coupon_code_expire);
          setCouponRestriction(data.coupon?.coupon_restriction);
          setCodePrefix(data.coupon?.code_prefix);
          setCodeLength(data.coupon?.code_length.toString());
          setDefaultSmsMsg(data.coupon?.success_msg);
          setIcon(data.coupon?.icon);
          setCouponCodeIntro(data.coupon?.coupon_code_intro);
        }
      });
  };

  const saveUpsells = () => {
    if (upsellFormData?.title.replace(/\s/g, '') !== '') {
      const fomrdata = new FormData();
      const objectTypes = ["variant_images", "popup_translations"]
      Object.keys(upsellFormData)?.map((key) => {
        if (!objectTypes.includes(key)) {
          fomrdata.append(key, upsellFormData[key])
        }
      })
      fomrdata.append("hero_css", customCss.hero_css);
      fomrdata.append("hero_status", bulkStatus.hero_status)
      fomrdata.append("variant_images", JSON.stringify(upsellFormData.variant_images))
      fomrdata.append("popup_translations", JSON.stringify(upsellFormData.popup_translations || {}))
      if (files.length > 0) {
        fomrdata.append("image", files?.[0])
      }
      fetch('/customer_portal/create_upsells', {
        method: 'POST',
        body: fomrdata,
      })
        .then((response) => response.json())
        .then((data) => {
          if (data?.status) {
            fetchCustomerPortalSettings();
            toggleActive();
            setNameError(false);
            fetchCustomerPortalSettings();
            setShowEditUpsell(false);
            setUpsellRowData({ ...upsellInitValues })
            setUpsellFormData({ ...upsellInitValues })
            // fetchEarnPointsSettings();
          }
        });
    } else {
      setNameError(true);
    }
  };

  const saveCustomCss = (field_name, status_field_name) => {
    fetch('/points_program/update_custom_css', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        shopify_domain: domain,
        template_id: selectedTemplateId,
        update_css: { [`${field_name}`]: customCss[`${field_name}`] },
        update_status: {
          [`${status_field_name}`]: bulkStatus[`${status_field_name}`],
        },
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        toggleActive();
        setNameError(false);
        fetchCustomerPortalSettings();
        // fetchEarnPointsSettings();
      });
  };

  const toastMarkup = active ? (
    <Frame>
      <Toast
        content={toastContent || 'Data updated successfully.'}
        onDismiss={toggleActive}
        duration={3000}
      />
    </Frame>
  ) : statusActive ? (
    <Frame>
      <Toast
        content={toastContent || 'Status updated successfully.'}
        onDismiss={toggleStatusActive}
        duration={3000}
      />
    </Frame>
  ) : null;

  const handleAuthenticationPage = (b, newValue) => {
    setZennportalSetting({
      ...zennportal_settings,
      authentication_page: newValue,
    });

    //saveSetting
    fetch('/save_zennportal_settings', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        shopify_domain: domain,
        template_id: selectedTemplateId,
        zennportal_setting: { authentication_page: newValue },
      }),
    }).then((response) => response.json());
  };

  const handleButtonRedirection = (b, newValue) => {
    let buttonRedirections = {
      refferals_redirect: zennportal_settings?.refferals_redirect,
      how_to_redeem_redirect: zennportal_settings?.how_to_redeem_redirect,
      sidebar_refferals_redirect:
        zennportal_settings?.sidebar_refferals_redirect,
    };
    //saveSetting
    fetch('/save_zennportal_settings', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        shopify_domain: domain,
        template_id: selectedTemplateId,
        zennportal_setting: buttonRedirections,
      }),
    }).then((response) => response.json());
  };

  return (
    <FormLayout>
      <div className="affiliate_setting_main_container shopify_chargezen_card">
        <div className="affiliate_setting_wrapper">
          <CustomerPostalSettingSideBar {...{ settingsSideBar, domain }} />
          <div className="affiliate_setting_body_container">
            {(!settingsSideBar?.find((s) => s.tab === selectedTab)?.noHeader && showHeader) && (
              <h1 className="shopify_style_header">
                {settingsSideBar?.find((s) => s.tab === selectedTab)?.title}
              </h1>
            )}
            <div className="shopify_base_card">
              {selectedTab == 'style_settings' && (
                <div className="zennportal_style manage_style_wrapper color-picker">
                  <ManageStyle
                    i18n={i18n}
                    selectedTemplateId={selectedTemplateId}
                    domain={domain}
                    toggleActive={toggleActive}
                    setToasContent={setToasContent}
                    setActive={setActive}
                  />
                </div>
              )}
              {selectedTab == 'portal_upsells' && (
                showEditUpsell ?
                  <EditUpsells
                    upsellRowData={upsellRowData}
                    setUpsellRowData={setUpsellRowData}
                    i18n={i18n}
                    selectedTemplateId={selectedTemplateId}
                    domain={domain}
                    toggleActive={toggleActive}
                    setNameError={setNameError}
                    saveUpsells={saveUpsells}
                    files={files} setFiles={setFiles}
                    {...{ upsellInitValues, upsellFormData, setUpsellFormData, showEditUpsell, setShowEditUpsell, showHeader, setShowHeader }}
                  />
                  :
                  <Upsells {...{ upsellInitValues, showEditUpsell, setShowEditUpsell, formatDate, showHeader, setShowHeader, setUpsellRowData, setUpsellFormData }} allUpsells={apiData?.upsells || []} />
              )}

              {selectedTab == 'button_settings' && (
                <>
                  <div className="manage_butons_wrapper">
                    <ManageButtons
                      i18n={i18n}
                      selectedTemplateId={selectedTemplateId}
                      domain={domain}
                      toggleActive={toggleActive}
                    />
                    <Card
                      title={
                        <div className="heading_title">
                          {i18n.t('zennportal_form.manage_redirections')}
                        </div>
                      }
                      primaryFooterAction={{
                        content: i18n.t('save_and_continue'),
                        onAction: handleButtonRedirection,
                      }}
                      footerActionAlignment="left"
                    >
                      <Card.Section>
                        <TextField
                          label={i18n.t(
                            'zennportal_form.refferals_header_link'
                          )}
                          value={zennportal_settings?.refferals_redirect}
                          onChange={(e) =>
                            setZennportalSetting({
                              ...zennportal_settings,
                              refferals_redirect: e,
                            })
                          }
                        />
                        <TextField
                          label={i18n.t('zennportal_form.how_to_redeem_link')}
                          value={zennportal_settings?.how_to_redeem_redirect}
                          onChange={(e) =>
                            setZennportalSetting({
                              ...zennportal_settings,
                              how_to_redeem_redirect: e,
                            })
                          }
                        />
                        <TextField
                          label={i18n.t(
                            'zennportal_form.refferals_sidebar_link'
                          )}
                          value={
                            zennportal_settings?.sidebar_refferals_redirect
                          }
                          onChange={(e) =>
                            setZennportalSetting({
                              ...zennportal_settings,
                              sidebar_refferals_redirect: e,
                            })
                          }
                        />
                      </Card.Section>
                    </Card>
                    <ManageCancelledSubscription
                      i18n={i18n}
                      selectedTemplateId={selectedTemplateId}
                      domain={domain}
                      toggleActive={toggleActive}
                      setToasContent={setToasContent}
                      setActive={setActive}
                      zennportal_settings={zennportal_settings}
                      fetchCustomerPortalSettings={fetchCustomerPortalSettings}
                    />
                  </div>
                </>
              )}

              {selectedTab == 'authentication_page' && (
                <div className="manage_settings">
                  <div className="manage_button_settings_container">
                    <Card
                      title={
                        <div className="heading_title">
                          <PixelIcon />
                          {i18n.t('zennportal_form.authentication_page')}
                        </div>
                      }
                    >
                      <Card.Section>
                        <RadioButton
                          label={i18n.t('zennportal_form.shopify_account_page')}
                          helpText={i18n.t(
                            'zennportal_form.redirect_customer_to_shopify_account'
                          )}
                          checked={
                            zennportal_settings?.authentication_page ===
                            'shopify'
                          }
                          id="shopify"
                          name="authentication_page"
                          onChange={(_, newValue) =>
                            handleAuthenticationPage(_, newValue)
                          }
                        />
                        <RadioButton
                          label={i18n.t(
                            'zennportal_form.chargezen_passwordless'
                          )}
                          helpText={i18n.t(
                            'zennportal_form.redirect_customer_to_chargezen_passwordless'
                          )}
                          id="passwordless"
                          name="authentication_page"
                          checked={
                            zennportal_settings?.authentication_page ===
                            'passwordless'
                          }
                          onChange={(_, newValue) =>
                            handleAuthenticationPage(_, newValue)
                          }
                        />
                      </Card.Section>
                    </Card>
                  </div>
                </div>
              )}

              {selectedTab == 'order_detail_modal' && (
                <div className="zennportal_style manage_style_wrapper manage_order_wrapper">
                  <OrderDetailModal
                    i18n={i18n}
                    selectedTemplateId={selectedTemplateId}
                    domain={domain}
                    toggleActive={toggleActive}
                    setToasContent={setToasContent}
                    setActive={setActive}
                  />
                </div>
              )}

              {selectedTab == 'translation_settings' && (
                <div className="manage_translations">
                  <ManageTranslations
                    i18n={i18n}
                    selectedTemplateId={selectedTemplateId}
                    domain={domain}
                    toggleActive={toggleActive}
                  />
                </div>
              )}

              {selectedTab == 'cancellation_flow' && (
                <CancellationFlow
                  {...{ i18n, selectedTemplateId, domain, toggleActive }}
                />
              )}
              {selectedTab == 'cancellation_setting' && (
                <CancellationSettings
                  {...{ i18n, selectedTemplateId, domain, toggleActive }}
                />
              )}
              {selectedTab == 'passwordless_login' && (
                <PasswordlessLogin {...{ domain, i18n }} />
              )}
              {
                selectedTab == "rewards_timeline" && (
                  showEditRewards ?
                    <RewardsTimeLineCreate {...{ toggleActive, rewardsRowData, setRewardsRowData, i18n, domain, setShowEditRewards, setShowHeader, rewardsInitValues }} />
                    :
                    <RewardsTimeLine {...{ allRewards, setAllRewards, i18n, domain, setActive, setToasContent, setShowHeader, setShowEditRewards, setRewardsRowData }} />
                )}
            </div>
          </div>
        </div>
      </div>
      {toastMarkup}
    </FormLayout >
  );
};
export default Customize;
