import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import {
  ChevronRightMinor,
  MobileBackArrowMajor,
} from '@shopify/polaris-icons';
import { Button, Card, Frame, Icon, Page, TextField } from '@shopify/polaris';
import PointsProgram from './PointsProgram/PointsProgram';
import PointsProgramFAQs from './PointsProgram/PointsProgramFaqs';
import WaysToEarnPoint from '../WaysToEarnPoint';
import WaysToRedeemPoints from '../WaysToEarnPoint/WaysToRedeemPoints';
import TooglePointsProgram from './TooglePointsProgram';
import PixelIcon from '../../images/PixelIcon';
import ReferralRewards from '../ReferralRewards/ReferralRewards';
import ReferralCustomerRewards from '../ReferralRewards/ReferralCustomerRewards';
import PointsProgramTiers from './PointsProgram/PointsProgramTiers';
import PointsProgramEmail from './PointsProgram/PointsProgramEmail';
import SummaryComponent from './SummaryComponent';
import AppleWalletPasses from './PointsProgram/AppleWalletPasses';
import ChooseTemplate from '../ChooseTemplate';
import ReferralPage from '../ReferralPage';
import RewardsLauncherSettings from '../RewardsLauncher';
import Overview from '../Overview/Overview';
import PointsProgramOverview from './PointsProgramOverview';

const PointsProgramIndex = (props) => {
  const { selected_tab, edit_id, template_id } = useParams();
  const {
    domain,
    i18n,
    formatDate,
    floatingSetupGuide,
    setFloatingSetupGuide,
  } = props;
  const [detailView, setDetailView] = useState(false);
  const [selectedEmailIndex, setSelectedEmailIndex] = useState();
  const [summaryView, setSummaryView] = useState(selected_tab);
  const history = useHistory();

  useEffect(() => {
    if (selected_tab) {
      setSummaryView(selected_tab);
    }
  }, [selected_tab]);

  const headerObject = {
    tooglePoints: {
      title: i18n.t('points_programs.program_status'),
      desc: i18n.t('points_programs.points_summary_body'),
    },
    pointsProgram: {
      title: 'Rewards Page Hero',
      desc: 'Rewards Page Hero',
    },
    waysToEarn: {
      title: i18n.t('points_programs.ways_to_earn'),
      desc: i18n.t('points_programs.ways_to_earn_points_summary'),
    },
    waysToRedeem: {
      title: i18n.t('points_programs.ways_to_redeem_points'),
      desc: i18n.t('points_programs.ways_to_redeem_points_summary'),
    },
    referral_rewards: {
      title: i18n.t('points_programs.referred_friend_reward'),
      desc: i18n.t('points_programs.referrals_summary2'),
    },
    appleWalletPasses: {
      title: 'Apple Wallet passes',
      desc: 'Activate Apple wallet passes for loyalty points, referral rewards & push notifications.',
    },
    point_program_tiers: {
      title: i18n.t('points_programs.points_program_tiers'),
      desc: i18n.t('points_programs.points_program_tiers_summary'),
    },
    points_emails: {
      title: i18n.t('points_programs.points_program_email'),
      desc: i18n.t('points_programs.points_program_email_summary'),
    },
    rewardsPage: {
      title: 'Rewards Page',
      desc: 'Manage your rewards page.',
    },
    ReferralPage: {
      title: 'Referral Page',
      desc: 'Manage your referral page.',
    },
    RewardsLauncherSettings: {
      title: 'Rewards Launcher Settings',
      desc: 'Manage your rewards launcher settings.',
    },
    default: {
      title: i18n.t('points_program'),
      desc: 'All the tools you need to enable your customers earn points; redeem points; launch apple wallet passes; and scale revenue with customer loyalty.',
    },
  };

  //     const GET_POINTS_PROGRAM = gql`
  //     query($page: String) {
  //         fetchPointProgram {
  //             id
  //             title
  //             description
  //             images
  //         }
  //     }
  //   `;
  //     const { data, loading, error, refetch } = useQuery(GET_POINTS_PROGRAM, {
  //         fetchPolicy: 'no-cache',

  //     });

  //     useEffect(() => {
  //         refetch({
  //             variables: {
  //                 page: page.toString()
  //             }
  //         });
  //     }, [page]);

  // useEffect(() => {
  //   if (summaryView != selected_tab) {
  //     history.push(`/pointsProgram/${summaryView}`);
  //   }
  // }, [summaryView, selected_tab]);

  const RenderCustomHeader = () => {
    return (
      <div className="chargezen_custom_page_header">
        <div className="page_title_wrapper">
          <div className="back_button_wrapper">
            {summaryView ? (
              <div
                className="back-button pointer"
                onClick={() => {
                  let toRedirect = edit_id
                    ? `/pointsProgram/${selected_tab}`
                    : `/pointsProgram`;
                  history.push(toRedirect);
                }}
              >
                <Icon source={MobileBackArrowMajor} color="base" />
              </div>
            ) : (
              ''
            )}{' '}
            <div class="text_wrapper page_heading_title_wrapper">
              <div
                class="Polaris-Header-Title__TitleAndSubtitleWrapper"
                style={{
                  width: '100%',
                }}
              >
                <div class="Polaris-Header-Title">
                  <h1 class="Polaris-DisplayText Polaris-DisplayText--sizeLarge">
                    <span class="Polaris-TextStyle--variationStrong">
                      <div class="back_button_wrapper">
                        {' '}
                        <div
                          class="text_wrapper page_heading_title_wrapper"
                          style={{
                            width: '100%',
                          }}
                        >
                          <span>
                            {headerObject[selected_tab || 'default']?.title}
                          </span>
                          <div className="create_selling_plan_subscription_wrapper">
                            <Button
                              onClick={() =>
                                history.push('/pointsProgram/appleWalletPasses')
                              }
                            >
                              Wallet passes
                            </Button>
                            <Button
                              onClick={() =>
                                history.push('/pointsProgram/rewardsPage')
                              }
                            >
                              Rewards page
                            </Button>
                          </div>
                        </div>
                      </div>
                    </span>
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="sidebar_summary header_description">
          {headerObject[selected_tab || 'default']?.desc}
        </div>
      </div>
    );
  };

  return (
    <>
      <Frame>
        <Page>
          <RenderCustomHeader />
          <>
            <div className={`chargezen_full_width_main_container`}>
              {/* <div
                className={`${
                  detailView ? 'sidebar_summary_right_section' : 'hidden'
                }`}
              >
                <SummaryComponent
                  {...{
                    i18n,
                    summaryView,
                    setSummaryView,
                    detailView,
                    setDetailView,
                  }}
                />
              </div> */}
              <div className={`chargezen_full_width_container`}>
                <div className={`chargezen_full_width_wrapper`}>
                  {!summaryView ? (
                    <PointsProgramOverview
                      {...{
                        i18n,
                        floatingSetupGuide,
                        setFloatingSetupGuide,
                      }}
                      customNavTabs={{ loyalty_rewards: true }}
                    />
                  ) : summaryView == 'pointsProgram' ? (
                    <PointsProgram
                      domain={domain}
                      i18n={i18n}
                      detailView={detailView}
                      setDetailView={setDetailView}
                    />
                  ) : summaryView == 'waysToEarn' ? (
                    <WaysToEarnPoint
                      domain={domain}
                      i18n={i18n}
                      detailView={detailView}
                      setDetailView={setDetailView}
                    />
                  ) : summaryView == 'waysToRedeem' ? (
                    <WaysToRedeemPoints
                      domain={domain}
                      i18n={i18n}
                      detailView={detailView}
                      setDetailView={setDetailView}
                    />
                  ) : summaryView == 'tooglePoints' ? (
                    <TooglePointsProgram
                      domain={domain}
                      i18n={i18n}
                      detailView={detailView}
                      setDetailView={setDetailView}
                    />
                  ) : summaryView == 'referral_rewards' ? (
                    <div className="referrals_main_container">
                      <ReferralRewards
                        domain={domain}
                        i18n={i18n}
                        detailView={detailView}
                        setDetailView={setDetailView}
                      />
                      <ReferralCustomerRewards
                        domain={domain}
                        i18n={i18n}
                        detailView={detailView}
                        setDetailView={setDetailView}
                      />
                    </div>
                  ) : summaryView == 'appleWalletPasses' ? (
                    <div className="referrals_main_container">
                      <AppleWalletPasses
                        domain={domain}
                        i18n={i18n}
                        detailView={detailView}
                        setDetailView={setDetailView}
                      />
                    </div>
                  ) : summaryView == 'point_program_tiers' ? (
                    <PointsProgramTiers
                      domain={domain}
                      i18n={i18n}
                      detailView={detailView}
                      setDetailView={setDetailView}
                    />
                  ) : summaryView == 'points_emails' ? (
                    <PointsProgramEmail
                      domain={domain}
                      i18n={i18n}
                      detailView={detailView}
                      setDetailView={setDetailView}
                      selectedIndex={selectedEmailIndex}
                      setSelectedIndex={setSelectedEmailIndex}
                      formatDate={formatDate}
                    />
                  ) : summaryView == 'rewardsPage' ? (
                    <ChooseTemplate
                      domain={domain}
                      i18n={i18n}
                      detailView={detailView}
                      setDetailView={setDetailView}
                      selectedIndex={selectedEmailIndex}
                      setSelectedIndex={setSelectedEmailIndex}
                      formatDate={formatDate}
                    />
                  ) : summaryView == 'ReferralPage' ? (
                    <ReferralPage
                      domain={domain}
                      i18n={i18n}
                      detailView={detailView}
                      setDetailView={setDetailView}
                      selectedIndex={selectedEmailIndex}
                      setSelectedIndex={setSelectedEmailIndex}
                      formatDate={formatDate}
                    />
                  ) : summaryView == 'RewardsLauncherSettings' ? (
                    <RewardsLauncherSettings
                      domain={domain}
                      i18n={i18n}
                      detailView={detailView}
                      setDetailView={setDetailView}
                      selectedIndex={selectedEmailIndex}
                      setSelectedIndex={setSelectedEmailIndex}
                      formatDate={formatDate}
                    />
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </div>
          </>
        </Page>
      </Frame>
    </>
  );
};

export default PointsProgramIndex;
