import React, { useEffect, useState } from 'react';
import {
  Badge,
  Button,
  ButtonGroup,
  Card,
  DataTable,
  Frame,
  Icon,
  Layout,
  Page,
  Stack,
  TextField,
} from '@shopify/polaris';
import PixelIcon from '../../images/PixelIcon';
import CreateWorkflow from './CreateWorkflow';
import {
  SearchMajor
} from '@shopify/polaris-icons';
import './style.css';
import moment from 'moment';
import { Link, useHistory, useParams } from 'react-router-dom';
import HeaderButtons from '../HeaderButtons/HeaderButtons';

const Workflows = (props) => {
  const { i18n, domain, detailView, setDetailView, formatDate, customHeader, setCustomHeader } = props;
  const { edit_id } = useParams();
  const [editId, setEdit] = useState(edit_id);
  const [createForm, setCreateForm] = useState(false);
  const [workflows, setWorkflows] = useState([]);
  const history = useHistory();
  const [searchValue, setSearchValue] = useState();


  const headerButtons = [
    {
      val: 'all',
      name: 'All',
    },
    {
      val: 'active',
      name: 'Active',
    },
    {
      val: 'draft',
      name: 'Draft',
    },
  ];

  const [headerButton, setHeaderButton] = useState(headerButtons[0]?.val);

  useEffect(() => {
    if (editId !== edit_id) {
      history.push(`/customersTab/subscription_workflow/${editId}`);
    }
  }, [editId]);

  const fetchWorkflows = () => {
    fetch(`/fetch_subscription_workflows?shopify_domain=${domain}&status=${headerButton}&query=${searchValue || ''}`, {
      method: 'GET',
    })
      .then((r) => r.json())
      .then((data) => {
        if (data?.workflows?.length > 0) {
          setWorkflows([...data?.workflows]);
        } else {
          setWorkflows([]);
        }
      });
  };

  useEffect(() => {
    setCustomHeader()
    fetchWorkflows();
  }, [headerButton, searchValue]);

  //upper first letter
  const capitalize = (s) => {
    if (typeof s !== 'string') return '';
    return s.charAt(0).toUpperCase() + s.slice(1);
  };
  const formatRows = (rows) => {
    return rows?.map((row, i) => {
      return [
        <a
          onClick={() => {
            history.push(`/customersTab/subscription_workflow/${row.id}`);
          }}
        >{`${row?.name}`}</a>,
        <div className="worflow_summary_wrapper" onClick={() => {
          history.push(`/customersTab/subscription_workflow/${row.id}`);
        }}>
          <div className="workflow_summary">{row?.summary_events}</div>
          <div className="workflow_summary">{row?.summary_condition}</div>
        </div>,
        <div onClick={() => {
          history.push(`/customersTab/subscription_workflow/${row.id}`);
        }}>
          <Badge status={row?.status?.toLowerCase() == 'active' ? "success" : "complete"}>{capitalize(row.status)}</Badge>
        </div>,
        <div onClick={() => {
          history.push(`/customersTab/subscription_workflow/${row.id}`);
        }}>{formatDate(row.created_at)}</div>,
      ];
    });
  };

  useEffect(() => {
    if (edit_id === 'createNew') {
      setCreateForm(true);
    } else if (edit_id) {
      setEdit(edit_id);
    }
  });

  return (
    <div className="">
      {createForm || editId ? (
        <CreateWorkflow
          setEdit={setEdit}
          editId={editId}
          setCreateForm={setCreateForm}
          i18n={i18n}
          detailView={detailView}
          setDetailView={setDetailView}
          domain={domain}
          fetchWorkflows={fetchWorkflows}
          setCustomHeader={setCustomHeader}
        />
      ) : (
        <Layout>
          <Layout.Section>
            <div className="without_header_card">
              <Card
                actions={{
                  content: (
                    <div className="card_header_button_group">
                      <div className="card_header_buttons primary_button_group">
                        <HeaderButtons
                          headerButtons={headerButtons}
                          setHeaderButton={setHeaderButton}
                          headerButton={headerButton}
                        />
                      </div>
                    </div>
                  ),
                }}
              >
                <Card.Section>
                  <div
                    className={'table customer-subscription-tbl workflow_table'}
                  >
                    <div className="search_plan_div">
                      <TextField
                        value={searchValue}
                        onChange={(value) => setSearchValue(value)}
                        prefix={<Icon source={SearchMajor} color="inkLighter" />}
                        placeholder="Search by Name"
                      />
                    </div>
                    <DataTable
                      columnContentTypes={['text', 'text', 'text', 'text']}
                      headings={['Name', 'Description', 'Status', 'Created At']}
                      rows={workflows ? formatRows(workflows) : []}
                    />
                  </div>
                </Card.Section>
              </Card>
            </div>
          </Layout.Section>
        </Layout>
      )}
    </div>
  );
};

export default Workflows;
