import React, { useState, useCallback, useEffect } from 'react';
import { SearchMinor } from '@shopify/polaris-icons';
import { gql, useMutation, useQuery, useLazyQuery } from '@apollo/client';
import {
  Autocomplete,
  TextField,
  Icon,
  TextContainer,
  Spinner,
} from '@shopify/polaris';

const SearchProduct = (props) => {
  const { value, setFieldValue, fieldName, allProducts, error, setAllProducts, i18n, setFormData = null, formData = null, collectionField } = props;
  var currentProducts = allProducts[collectionField] ? allProducts[collectionField] : []
  // Search product to add
  const GET_PRODUCT = gql`
    query($query: String!) {
      products(first: 10, query: $query) {
        edges {
          node {
            id
            title
            images(first: 1) {
              edges {
                node {
                  originalSrc
                }
              }
            }
          }
        }
      }
    }
  `;

  const [productList, setProductList] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);

  const [GetProducts, { loading, data, errors }] = useLazyQuery(GET_PRODUCT, {
    fetchPolicy: 'no-cache',
    context: { clientName: 'shopify-link' },
  });

  useEffect(() => {
    if (data && data.products) {
      setProductList(() => {
        const result = [];
        data.products.edges.map((product) =>
          result.push({
            value: product.node.title,
            label: product.node.title,
            id: product.node.id,
            images: product.node.images.edges.map(img => {
              return img.node.originalSrc;
            })
          })
        );

        return result;
      });
    }
  }, [data]);

  const updateText = useCallback(
    (value) => {
      if (setFormData) {
        setFormData({ ...formData, [fieldName]: { title: value, productId: '' } })
      } else {
        setFieldValue(fieldName, { title: value, productId: '' });
      }

      if (value) {
        GetProducts({ variables: { query: `title:*${value}*` } });
      }
    },
    [value]
  );

  const updateSelection = useCallback(
    (selected) => {
      if (selected?.length > 0) {
        const product = productList.find((item) => item.value === selected[0]);

        if (setFormData) {
          setFormData({
            ...formData, [fieldName]: {
              title: product.value,
              productId: product.id,
              image: product.images[0]
            }
          })
        } else {
          setFieldValue(fieldName, {
            title: product.value,
            productId: product.id,
            image: product.images[0]
          });
        }

        setSelectedOptions(selected);

        let flag = true;

        for (let i = 0; currentProducts?.length > i; i++) {
          if (currentProducts[i]?.title == product.value) {
            flag = false;
            break;
          } else {
            flag = true;
          }
        }


        if (flag) {
          currentProducts ? currentProducts.push({
            title: product.value,
            productId: product.id,
            image: product.images[0]
          }) : currentProducts = [{
            title: product.value,
            productId: product.id,
            image: product.images[0]
          }];
          setAllProducts({ ...allProducts, [collectionField]: currentProducts })
        }

      }
    },
    [productList, value]
  );

  const textField = (
    <Autocomplete.TextField
      onChange={updateText}
      label=""
      type="text"
      value={value.title}
      prefix={<Icon source={SearchMinor} color="base" />}
      placeholder={i18n.t('search_product_to_add')}
      error={error}
      suffix={
        loading && (
          <Spinner accessibilityLabel="Small spinner example" size="small" />
        )
      }
    />
  );

  const emptyState = (
    <React.Fragment>
      <Icon source={SearchMinor} />
      <div style={{ textAlign: 'center' }}>
        <TextContainer>Could not find any results</TextContainer>
      </div>
    </React.Fragment>
  );

  return (
    <Autocomplete
      options={productList}
      selected={selectedOptions}
      onSelect={updateSelection}
      textField={textField}
      emptyState={emptyState}
    />
  );
};

export default SearchProduct;
