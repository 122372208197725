import React, { useState } from 'react';
import { Card, Icon } from '@shopify/polaris';
import { HorizontalDotsMinor } from '@shopify/polaris-icons';
import dayjs from 'dayjs';
import { useHistory } from 'react-router-dom';

const AlreadyCreatedOffers = ({
  offer,
  setEditOffer,
  setToastContent,
  fetchAllOffers,
  formatDate,
}) => {
  const [moreOptions, setMoreOptions] = useState(false);

  const history = useHistory();

  const deleteOffer = (offer_id) => {
    fetch(`/upsell/delete_offer/${offer_id}`, {
      method: 'DELETE',
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status) {
          fetchAllOffers();
          setToastContent('Offer Deleted');
        }
      })
      .catch((e) => {
        console.log('Somethind went wrong' + e);
      });
  };

  const duplicateOffer = (offer_id) => {
    fetch(`/upsell/duplicate_offer/${offer_id}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status) {
          fetchAllOffers();
          setToastContent('Offer Duplicated');
        }
      })
      .catch((e) => {
        console.log('Somethind went wrong' + e);
      });
  };

  return (
    <div className="upsell_offer_wrapper_container cart_funnel_wrapper_container">
      <Card>
        <div className="upsell_offer_wrapper">

          <div className="img_wrapper">
            <img src={offer.variants?.[0]?.image} />
          </div>
          <div className='content_block_wrapper'>
            <div className="content_block">
              <span
                className="offer_title"
                onClick={() => history.push(`/chargezenUpsells/cartFunnels/${offer.cart_funnel_template_id}/${offer.id}`)}
              >
                {offer.name}
              </span>
              <div className="date_wrapper">
                Last updated:{' '}
                {`${formatDate(offer.created_at)}, ${dayjs(
                  offer.created_at
                )?.format('hh-mm a')}`}
              </div>
            </div>
            <div className='content_block_footer'>
              <div
                className="more_options"
                onClick={() => {
                  setMoreOptions(!moreOptions);
                }}
              >
                <div className="more_options icon_wrapper">
                  <Icon source={HorizontalDotsMinor} color="base" />
                </div>
                {moreOptions && (
                  <div className="options_lists">
                    <div
                      className="option_wrapper"
                      onClick={() =>
                        history.push(`/chargezenUpsells/manage/${offer.id}`)
                      }
                    >
                      Edit
                    </div>
                    {offer.status == 'live' && (
                      <div className="option_wrapper">Analytics</div>
                    )}
                    <div
                      className="option_wrapper"
                      onClick={() => { }}
                    >
                      Duplicate
                    </div>
                    <div
                      className="option_wrapper delete_offer"
                      onClick={() => { }}
                    >
                      Delete
                    </div>
                  </div>
                )}
              </div>
              <button className='chargezen_secondary_button'>Duplicate</button>
              <button className='chargezen_primary_button'>Customize</button>
            </div>
          </div>
        </div>
      </Card>
    </div>
  );
};

export default AlreadyCreatedOffers;
