import React from 'react'
import SearchVariants from '../plans/SearchVariants'

const SearchUpsellVariants = (props) => {
    const {value,
    fieldName,
    allVariants,
    setAllVarients,
    i18n,
    setFormData,
    formData} = props;
  return (
    <>
    <SearchVariants
        value={value}
        fieldName={fieldName}
        allVariants={allVariants}
        setAllVarients={setAllVarients}
        i18n={i18n}
        setFormData={setFormData}
        formData={formData}
    />
    </>
  )
}

export default SearchUpsellVariants