import {
  Badge,
  Banner,
  Button,
  ButtonGroup,
  Card,
  DataTable,
  Frame,
  Icon,
  List,
  Page,
  ResourceItem,
  ResourceList,
  Stack,
  Toast,
} from '@shopify/polaris';
import React, { useCallback, useEffect, useState } from 'react';
import { gql, useQuery } from '@apollo/client';

import EditPointsProgramTiers from './EditPointsProgramTiers';
import LoadingScreen from '../../LoadingScreen';
import PixelIcon from '../../../images/PixelIcon';
import {
  ChevronRightMinor,
  MobileBackArrowMajor,
} from '@shopify/polaris-icons';
import { Link, useHistory, useParams } from 'react-router-dom';
import SummaryFullWidthStyle from '../../layout/SummaryFullWidthStyle';

const PointsProgramTiers = (props) => {
  const { i18n, domain, detailView, setDetailView } = props;
  const history = useHistory();
  const { edit_id } = useParams();
  const [editForm, setEditForm] = useState(
    edit_id == 'createNew' ? true : false
  );
  const [pointsProgramTiers, setPointsProgramTierss] = useState([]);
  const [rowData, setRowData] = useState([]);
  const [formErrors, setFormErrors] = useState([]);
  const [saveSuccess, setSaveSuccess] = useState(false);
  const hideSaveSuccess = useCallback(() => setSaveSuccess(false), []);
  const [titleOptions, setTitleOptions] = useState([]);
  const [allProducts, setAllProducts] = useState([]);
  const [formData, setFormData] = useState([
    {
      title: '',
      points_required: '',
    },
  ]);
  const [toastContent, setToastContent] = useState('');
  const [toastActive, setToastActive] = useState(false);
  const toggleToastActive = useCallback(
    () => setToastActive((toastActive) => !toastActive),
    []
  );
  const [pointProgramStatus, setPointProgramStatus] = useState(false);
  const [selectedCollections, setSelectedCollections] = useState([]);

  const GET_TIERS = gql`
    query {
      fetchPointsProgramTiers {
        id
        title
        pointsRequired
      }
    }
  `;

  const {
    data: redeemData,
    loading: redeemLoading,
    error: redeemError,
    refetch: redeemRefetch,
  } = useQuery(GET_TIERS, {
    fetchPolicy: 'no-cache',
  });

  useEffect(() => {
    redeemRefetch();
  }, []);

  useEffect(() => {
    if (redeemData?.fetchPointsProgramTiers?.length > 0) {
      setPointsProgramTierss(redeemData?.fetchPointsProgramTiers);
      let formdata = [];
      redeemData?.fetchPointsProgramTiers?.map((tier) => {
        formdata.push({
          id: tier?.id,
          title: tier?.title,
          points_required: tier?.pointsRequired,
        });
      });
      setFormData([...formdata]);
    }
  }, [redeemData]);

  useEffect(() => {
    if (pointsProgramTiers?.length > 0) {
      setRowData(pointsProgramTiers);
    }
  }, [pointsProgramTiers]);

  useEffect(() => {
    fetch('/togglePointsProgram', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        active: pointProgramStatus,
        shopify_domain: domain,
      }),
    });
  }, [pointProgramStatus]);

  const formatRedeemData = (rows) => {
    return rows?.map((row) => {
      return [
        row.title,
        row?.pointsRequired,
        <div className="primary_button_wrapper">
          <Button
            primary
            onClick={() => {
              history.push(`/pointsProgram/point_program_tiers/createNew`);
            }}
          >
            {i18n.t('edit')}
          </Button>
        </div>,
      ];
    });
  };

  return (
    <>
      {saveSuccess && (
        <Toast content={toastContent} onDismiss={toggleToastActive} />
      )}
      {editForm ? (
        <EditPointsProgramTiers
          domain={domain}
          setFormData={setFormData}
          formData={formData}
          refetch={redeemRefetch}
          setEditForm={setEditForm}
          formErrors={formErrors}
          setFormErrors={setFormErrors}
          saveSuccess={saveSuccess}
          setSaveSuccess={setSaveSuccess}
          toastContent={toastContent}
          setToastContent={setToastContent}
          toastActive={toastActive}
          setToastActive={setToastActive}
          i18n={i18n}
        />
      ) : redeemLoading ? (
        <LoadingScreen />
      ) : (
        <>
          <Card
            actions={{
              content: (
                <div className="card_header_button_group">
                  <div className="card_header_buttons secondary_button_group">
                    <Button
                      onClick={() => {
                        setEditForm(true);
                        history.push(
                          `/pointsProgram/point_program_tiers/createNew`
                        );
                      }}
                    >
                      {i18n.t('points_programs.add_points_program_tier')}
                    </Button>
                  </div>
                </div>
              ),
            }}
          >
            <Card.Section>
              <div className={'table customer-subscription-tbl' + ' '}>
                <DataTable
                  columnContentTypes={['text', 'text', 'text']}
                  headings={[
                    i18n.t('title'),
                    i18n.t('points_programs.points_required'),
                    i18n.t('action'),
                  ]}
                  rows={formatRedeemData(
                    pointsProgramTiers?.length > 0 ? pointsProgramTiers : []
                  )}
                />
              </div>
            </Card.Section>
          </Card>
        </>
      )}
    </>
  );
};

export default PointsProgramTiers;
