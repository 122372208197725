import { gql, useMutation, useQuery } from '@apollo/client';
import {
  Badge,
  Banner,
  Button,
  ButtonGroup,
  Caption,
  Card,
  Checkbox,
  ChoiceList,
  DataTable,
  DropZone,
  Filters,
  Frame,
  Icon,
  List,
  Modal,
  Page,
  Spinner,
  Stack,
  Tabs,
  Thumbnail,
  Toast,
} from '@shopify/polaris';
import {
  DeleteMajor,
  NoteMinor,
  ChevronRightMinor,
} from '@shopify/polaris-icons';
import Papa from 'papaparse';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { CSVLink } from 'react-csv';
import { useHistory } from 'react-router-dom';
import swapIcon from '../../../assets/images/icons/swap.svg';
import AppLayout from '../layout/Layout';
import moment from 'moment';
import { Pagination } from '@shopify/polaris';
import LoadingScreen from '../LoadingScreen';
import HeaderButtons from '../HeaderButtons/HeaderButtons';
import PixelIcon from '../../images/PixelIcon';
import { DomainContext } from '../domain-context';
import SubscriptionView from '../SubscriptionView';
import RangePicker from './RangePicker';
import dayjs from 'dayjs';

const {
  Parser,
  transforms: { unwind },
} = require('json2csv');

const SubscriptionOrders = (props) => {
  const { domain, shopifyDomain, i18n, detailView, setDetailView } = props;
  const history = useHistory();
  // Start Tabs
  const [selectedTab, setSelectedTab] = useState(0);

  // End tabs
  console.log(selectedTab);
  const [sortOrder, setSortOrder] = useState(0);

  const [moneySpent, setMoneySpent] = useState(null);
  const [taggedWith, setTaggedWith] = useState(null);
  const [queryValue, setQueryValue] = useState(null);
  const [loadingScreen, setLoadingScreen] = useState(false);
  const [filterConsignments, setFilterConsignments] = useState([]);
  const [token, setToken] = useState();
  const [selectedDates, setSelectedDates] = useState();
  const handleFilterByDate = () => {
    setFilterConsignments([]);
  };

  console.log(
    "`${dayjs(selectedDates?.start).format('DD/MM/YYYY')}`",
    selectedDates
  );
  const handleMoneySpentChange = useCallback(
    (value) => setMoneySpent(value),
    []
  );
  const handleTaggedWithChange = useCallback(
    (value) => setTaggedWith(value),
    []
  );

  const handleMoneySpentRemove = useCallback(() => setMoneySpent(null), []);
  const handleTaggedWithRemove = useCallback(() => setTaggedWith(null), []);
  const handleQueryValueRemove = useCallback(() => setQueryValue(null), []);
  const handleFiltersClearAll = useCallback(() => {
    handleMoneySpentRemove();
    handleTaggedWithRemove();
    handleQueryValueRemove();
  }, [handleMoneySpentRemove, handleQueryValueRemove, handleTaggedWithRemove]);

  const filters = [
    {
      key: 'taggedWith',
      label: 'Tagged with',
      filter: (
        <ChoiceList
          title="Tagged with"
          titleHidden
          choices={[
            { label: 'Cancelled', value: 'cancelled' },
            { label: 'Active', value: 'active' },
            { label: 'In Trial', value: 'inTrial' },
            { label: 'Future', value: 'future' },
          ]}
          selected={taggedWith || []}
          onChange={handleTaggedWithChange}
          // allowMultiple
        />
      ),
      shortcut: true,
    },
    {
      key: 'moneySpent',
      label: 'Money spent',
      // filter: (
      //   <RangeSlider
      //     label="Money spent is between"
      //     labelHidden
      //     value={moneySpent || [0, 500]}
      //     prefix="$"
      //     output
      //     min={0}
      //     max={2000}
      //     step={1}
      //     onChange={handleMoneySpentChange}
      //   />
      // ),
    },
  ];

  const appliedFilters = [];
  if (!isEmpty(moneySpent)) {
    const key = 'moneySpent';
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, moneySpent),
      onRemove: handleMoneySpentRemove,
    });
  }
  if (!isEmpty(taggedWith)) {
    const key = 'taggedWith';
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, taggedWith),
      onRemove: handleTaggedWithRemove,
    });
  }

  function disambiguateLabel(key, value) {
    switch (key) {
      case 'moneySpent':
        return `Money spent is between $${value[0]} and $${value[1]}`;
      case 'taggedWith':
        return `Tagged with ${value}`;
      default:
        return value;
    }
  }

  function isEmpty(value) {
    if (Array.isArray(value)) {
      return value.length === 0;
    } else {
      return value === '' || value == null;
    }
  }

  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [totalPages, setTotalPages] = useState(1);
  const [hasPrevious, setHasPrevious] = useState(false);
  const [status, setStatus] = useState('active');
  const [searchQuery, setSearchQuery] = useState();
  const [subOrdersheaderButton, setsubOrdersHeaderButton] =
    useState('processed');
  const [subscriptionOrders, setSubscriptionOrders] = useState([]);
  const [apiData, setApiData] = useState();
  const [tableheadings, setTableHeadings] = useState([]);
  const [formattedRows, setFormattedData] = useState([]);

  useEffect(() => {
    setPage(1);
    setTableHeadings([]);
    setStatus(subOrdersheaderButton);
  }, [subOrdersheaderButton]);

  const subOrdersheaderButtons = [
    {
      val: 'processed',
      name: 'Processed',
    },
    {
      val: 'upcoming',
      name: 'Upcoming',
    },
    {
      val: 'in_dunning',
      name: 'In Dunning',
    },
    {
      val: 'failed',
      name: 'Failed',
    },
  ];

  const fetchSubscriptionOrders = () => {
    fetch(
      `/fetch_subscription_orders?shopify_domain=${domain}&page=${page}&category=${subOrdersheaderButton}`,
      {
        method: 'GET',
      }
    )
      .then((response) => response.json())
      .then((data) => {
        formatRows(data?.subscription_orders);
        setSubscriptionOrders(data?.subscription_orders);
        setTotalPages(data?.total_pages);
        setApiData({ ...data });
      });
  };
  // -------------------

  useEffect(() => {
    fetchSubscriptionOrders();
  }, [page, subOrdersheaderButton]);

  const handleFiltersQueryChange = (value) => {
    console.log('fsdafdsafdfwe', value, value.length);
    setQueryValue(value);
    if (value.length > 1) {
      setSearchQuery(value);
    } else {
      setSearchQuery();
    }
  };

  useEffect(() => {
    if (detailView == 'summary') {
      //To prevent the pagination in summary view.
      setHasMore(false);
      setHasPrevious(false);
    } else {
      setTotalPages(apiData?.totalPages);
      if (+page < +apiData?.totalPages) {
        setHasMore(true);
      } else {
        setHasMore(false);
      }
      if (+page <= 1) {
        setHasPrevious(false);
      } else {
        setHasPrevious(true);
      }
    }
  }, [apiData, detailView]);

  const [formErrors, setFormErrors] = useState([]);
  const [saveSuccess, setSaveSuccess] = useState(false);
  const hideSaveSuccess = useCallback(() => setSaveSuccess(false), []);

  const [selectedCustomers, setselectedCustomers] = useState([]);
  const handleChangeCheckedCustomers = (newChecked, customerId) => {
    if (newChecked) {
      setselectedCustomers([...selectedCustomers, customerId]);
    } else {
      const index = selectedCustomers.indexOf(customerId);
      setselectedCustomers([
        ...selectedCustomers.slice(0, index),
        ...selectedCustomers.slice(index + 1),
      ]);
    }
  };
  //upper first letter
  const capitalize = (s) => {
    if (typeof s !== 'string') return '';
    return s.charAt(0).toUpperCase() + s.slice(1);
  };
  //each row in data table
  const formatRows = (rows) => {
    let rowData = [];
    if (subOrdersheaderButton == 'upcoming') {
      rowData = rows?.map((row, i) => {
        return [
          <Checkbox
            label={row?.contract_id}
            checked={selectedCustomers.indexOf(row?.contract_id) != -1}
            onChange={(newChecked) =>
              handleChangeCheckedCustomers(newChecked, row?.contract_id)
            }
          />,
          row?.next_billing_date,
          row?.total,
          <div className="subscription_orders_customer_name_wrapper">
            <span>{row?.customer_name}</span>
            <span>{row?.email}</span>
          </div>,
          <div className="subscription_orders_items_wrapper">
            {row?.items?.edges?.map((item) => {
              item = item.node;
              return (
                <div className="item">
                  {item.quantity} x ${item.title}
                </div>
              );
            })}
          </div>,
        ];
      });
      setTableHeadings([
        'Subscription ID',
        'Date',
        'Total',
        'Customer',
        'Items',
      ]);
    } else if (subOrdersheaderButton == 'processed') {
      rowData = rows?.map((row, i) => {
        return [
          <Checkbox
            label={row?.order?.order_id}
            checked={selectedCustomers.indexOf(row?.order?.order_id) != -1}
            onChange={(newChecked) =>
              handleChangeCheckedCustomers(newChecked, row?.order?.order_id)
            }
          />,
          row?.order?.created_at,
          <div className="subscription_orders_customer_name_wrapper">
            <span>{row?.order?.customer_name}</span>
            <span>{row?.order?.email}</span>
          </div>,
          row?.order?.status,
          'Recurring',
          row?.order?.current_total_price,
        ];
      });
      setTableHeadings([
        'Shopify order ID',
        'Date',
        'Customer',
        'Transaction Status',
        'Type',
        'Total',
      ]);
    } else {
      rowData = rows?.map((row, i) => {
        return row?.order !== null
          ? [
              <Checkbox
                label={row?.created_at}
                checked={selectedCustomers.indexOf(row?.order?.order_id) != -1}
                onChange={(newChecked) =>
                  handleChangeCheckedCustomers(newChecked, row?.order?.order_id)
                }
              />,
              row?.retries,
              <div className="next_try_wrapper">
                {row?.next_retry_date}
                {row?.status?.toLowerCase() != 'active' && (
                  <div className="status">Contract is {row?.status}</div>
                )}
              </div>,
              <div className="subscription_orders_customer_name_wrapper">
                <span>{row?.customer_name}</span>
                <span>{row?.email}</span>
              </div>,
              row?.total,
              row?.error,
            ]
          : [];
      });
      setTableHeadings([
        'Date',
        'Tries',
        'Next try',
        ,
        'Customer',
        'Total',
        'Error',
      ]);
    }
    console.log('rowDatarowData', rowData);
    setFormattedData([...rowData]);
  };

  useEffect(() => {
    // if (detailView == "summary") {
    //   formattedRows.length = 2
    // } else {
    if (formattedRows.length != subscriptionOrders.length) {
      formatRows(subscriptionOrders);
    }
    // }
  }, [detailView, formattedRows]);

  console.log('tableheadingsss', tableheadings);
  const [customers, setCustomers] = useState([]);
  const [filterCustomers, setFilterCustomers] = useState([]);
  const [summaryFilterCustomers, setSummaryFilterCustomers] = useState([]);

  const filterCustomersValue = () => {
    setFilterCustomers(customers);
  };

  useEffect(() => {
    var arrayCustomer = JSON.parse(JSON.stringify(filterCustomers));
    if (detailView == 'summary') {
      arrayCustomer.length = 2;
    }
    setSummaryFilterCustomers(arrayCustomer);
  }, [filterCustomers, detailView]);

  useEffect(() => {
    if (customers) {
      filterCustomersValue();
    }
    // console.log('searchvalue: ', queryValue);
  }, [queryValue, taggedWith, customers]);

  const fields = [
    'id',
    'firstName',
    'lastName',
    'email',
    'communication',
    'phone',
    'language',
    'autoCollection',
    'status',
    'subscription',

    'additionalContacts.id',
    'additionalContacts.email',
    'additionalContacts.firstName',
    'additionalContacts.lastName',
    'additionalContacts.companyName',
    'additionalContacts.phone',

    'billingAddress.firstName',
    'billingAddress.lastName',
    'billingAddress.email',
    'billingAddress.company',
    'billingAddress.phone',
    'billingAddress.address1',
    'billingAddress.address2',
    'billingAddress.city',
    'billingAddress.language',
    'billingAddress.zipCode',
  ];
  const transforms = [
    unwind({ paths: ['additionalContacts', 'billingAddress'] }),
  ];
  const json2csvParser = new Parser({ fields, transforms });
  let csv = json2csvParser.parse(dataSelected);

  //
  // const exportCSV = () => {
  const dataSelected = [...filterCustomers].filter((item) =>
    selectedCustomers.find((select) => select === item.id)
  );
  // };

  //import customer by csv:
  const [active, setActive] = useState(false);
  const [checked, setChecked] = useState(false);

  const toggleActive = useCallback(() => setActive((active) => !active), []);

  const handleCheckbox = useCallback((value) => setChecked(value), []);

  //upload file
  const [file, setFile] = useState();

  const handleDropZoneDrop = useCallback(
    (_dropFiles, acceptedFiles, _rejectedFiles) =>
      setFile((file) => acceptedFiles[0]),
    []
  );

  // const validImageTypes = ['image/gif', 'image/jpeg', 'image/png'];

  const fileUpload = !file && <DropZone.FileUpload />;
  const uploadedFile = file && (
    <Stack>
      <Thumbnail size="small" alt={file.name} source={NoteMinor} />
      <div>
        {file.name} <Caption>{file.size} bytes</Caption>
      </div>
    </Stack>
  );
  //config

  const csvData = [];

  return (
    <>
      {
        <>
          <Frame>
            <div className="without_header_card">
              <Card
                actions={{
                  content: (
                    <div className="card_header_button_group">
                      <div className="card_header_buttons primary_button_group">
                        <HeaderButtons
                          headerButtons={subOrdersheaderButtons}
                          setHeaderButton={setsubOrdersHeaderButton}
                          headerButton={subOrdersheaderButton}
                        />
                      </div>
                    </div>
                  ),
                }}
              >
                <Card.Section>
                  <div
                    className="filter custom_filter_main"
                    style={{
                      display: 'none',
                      width: '100%',
                    }}
                  >
                    <Filters
                      queryValue={queryValue}
                      filters={filters}
                      appliedFilters={appliedFilters}
                      onQueryChange={handleFiltersQueryChange}
                      onQueryClear={handleQueryValueRemove}
                      onClearAll={handleFiltersClearAll}
                    />
                    <span className="separate"></span>
                    <div className="btn-sort">
                      <Button
                        onClick={() => {
                          setFilterCustomers(() => {
                            if (sortOrder === 1) {
                              setSortOrder(0);
                              return [...filterCustomers].sort(
                                (a, b) =>
                                  new Date(b.updatedAt) - new Date(a.updatedAt)
                              );
                            } else {
                              setSortOrder(1);
                              return [...filterCustomers].sort(
                                (a, b) =>
                                  new Date(a.updatedAt) - new Date(b.updatedAt)
                              );
                            }
                          });
                        }}
                      >
                        <img src={swapIcon} />
                        Sort By last update (Newest First)
                      </Button>
                    </div>
                  </div>
                  <div
                    className={
                      'table customer-subscription-tbl' + ' ' + selectedTab
                    }
                  >
                    <DataTable
                      columnContentTypes={[
                        'text',
                        'text',
                        'text',
                        'text',
                        'text',
                        'text',
                      ]}
                      headings={tableheadings.length > 0 ? tableheadings : []}
                      rows={formattedRows?.length > 0 ? formattedRows : []}
                    />
                  </div>
                  <div
                    style={{
                      width: '100%',
                      justifyContent: 'center',
                      display: 'flex',
                    }}
                  >
                    <Pagination
                      hasPrevious={hasPrevious}
                      onPrevious={() => {
                        setPage((prev) => +prev - 1);
                      }}
                      hasNext={hasMore}
                      onNext={() => {
                        setPage((prev) => +prev + 1);
                      }}
                    />
                  </div>
                </Card.Section>
              </Card>
            </div>
          </Frame>
        </>
      }
    </>
  );
};

export default SubscriptionOrders;
