import React, { useEffect, useState } from 'react';
import { Card, Select } from '@shopify/polaris';
import PixelIcon from '../../images/PixelIcon';

const ManageCancelledSubscription = (props) => {
  const {
    i18n,
    domain,
    selectedTemplateId,
    toggleActive,
    setToasContent,
    zennportal_settings,
    fetchCustomerPortalSettings,
  } = props;
  const [formData, setFormData] = useState({
    reactivate_button_setting:
      zennportal_settings?.reactivate_button_setting || '0',
  });

  useEffect(() => {
    setFormData({
      reactivate_button_setting: zennportal_settings?.reactivate_button_setting,
    });
  }, [zennportal_settings?.reactivate_button_setting]);

  console.log(
    'zennportal_settings?.reactivate_button_setting',
    zennportal_settings?.reactivate_button_setting
  );

  const reactivateButtonOptions = [
    { label: 'Both', value: 'both' },
    { label: 'Instant Reactivate', value: 'instant_reactivate' },
    { label: 'Flexible date reactivate', value: 'flexible_date' },
  ];
  const saveSetting = () => {
    fetch('/save_zennportal_settings', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        shopify_domain: domain,
        template_id: selectedTemplateId,
        zennportal_setting: formData,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data?.success) {
          setToasContent('Saved Successfully');
          fetchCustomerPortalSettings();
        } else {
          console.log('error', data?.error);
          setToasContent('Something went wrong...');
        }
        toggleActive();
      });
  };

  return (
    <>
      <Card
        title={
          <div className="heading_title">
            {i18n.t('zennportal_form.manage_cancelled_sub_setting')}
          </div>
        }
        primaryFooterAction={{
          content: i18n.t('save_and_continue'),
          onAction: saveSetting,
        }}
        footerActionAlignment="left"
      >
        <Card.Section>
          <Select
            label={i18n.t('reactivate_button_setting')}
            options={reactivateButtonOptions}
            onChange={(e) => {
              setFormData({ ...formData, reactivate_button_setting: e });
            }}
            value={formData.reactivate_button_setting}
          />
        </Card.Section>
      </Card>
    </>
  );
};

export default ManageCancelledSubscription;
