import React, { useCallback, useEffect, useState } from 'react';
import { render } from 'react-dom';
import {
  Icon,
  AppProvider,
  TextField,
  Select,
  Frame,
  Card,
  Checkbox,
  RadioButton,
  Page,
  Toast,
  DropZone,
  Stack,
  Thumbnail,
  Caption,
} from '@shopify/polaris';
import { ChevronLeftMinor } from '@shopify/polaris-icons';
import '@shopify/polaris/dist/styles.css';
import GiftCardHtml from './GiftCardHtml';
import PixelIcon from '../../images/PixelIcon';
import HeaderButtons from '../HeaderButtons/HeaderButtons';
// import ManageTranslations from './ManageTranslations';
import {
  DeleteMajor,
  NoteMinor,
  ChevronRightMinor,
} from '@shopify/polaris-icons';
import HideSideNav from '../helpers/HideSideNav';
import CustomColorPicker from './CustomColorPicker';
import './Style.css';
import CustomImageDropzone from './CustomImageDropzone';
import ManageForm from './ManageForm';
import ManageAddToCartButton from './ManageAddToCartButton';
import ContentBlock from './ContentBlock';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

const GiftCard = (props) => {
  const {
    domain,
    i18n,
    detailView,
    setDetailView,
    templateId,
    editId,
    toastContent,
    setToastContent,
    setTemplateId,
    setEditId,
    refetchGiftCardSettings,
    handleSubmit,
    formData,
    setFormData,
    contentBlocks,
    setContentBlocks,
  } = props;

  const [currentForm, setCurrentForm] = useState();
  const [addContent, setAddContent] = useState();
  const [currentContentBlock, setCurrentContentBlock] = useState();
  const [headerButton, setHeaderButton] = useState('Body');

  const headerButtons = [
    {
      val: 'Body',
      name: 'Body',
    },
    {
      val: 'Theme',
      name: 'Theme',
    },
  ];

  const fetchGiftCardSettings = () => {
    fetch(
      `/gift_card_template_setting?shopify_domain=${domain}&template_id=${templateId}&gift_card_setting_id=${
        editId || ''
      }`,
      {
        method: 'GET',
      }
    )
      .then((r) => r.json())
      .then((data) => {
        if (data?.gift_card_template_setting) {
          if (data?.gift_card_template_setting?.title == 'chargezen_default') {
            data.gift_card_template_setting.title = 'custom template';
          }
          setFormData(data?.gift_card_template_setting);

          const custom_content_blocks = data?.content_blocks?.map(
            (block, i) => {
              block.temp_token = i + 1;
              return block;
            }
          );

          setContentBlocks([...custom_content_blocks]);
        }
      });
  };

  useEffect(() => {
    fetchGiftCardSettings();
  }, [templateId]);

  useEffect(() => {
    if (!!addContent && contentBlocks) {
      const content = contentBlocks.find((c) => +c.temp_token == +addContent);
      console.log('contentssss', addContent, content, contentBlocks);
      setCurrentContentBlock(content);
    } else {
      setCurrentContentBlock();
    }
  }, [addContent, contentBlocks]);

  console.log('toastContenttoastContent', toastContent);
  return (
    <div className="giftCard_settings_container">
      <Frame>
        <Card
          sectioned
          actions={{
            content: (
              <>
                {
                  // <div className="tabButtons">
                  //   <HeaderButtons
                  //     headerButtons={headerButtons}
                  //     setHeaderButton={setHeaderButton}
                  //     headerButton={headerButton}
                  //   />
                  //   {detailView == 'summary' ? (
                  //     <button
                  //       className="expand_view_button"
                  //       onClick={() => setDetailView('RewardsLauncherSettings')}
                  //     >
                  //       <span>Expand view</span>
                  //       <Icon source={ChevronRightMinor} color="base" />
                  //     </button>
                  //   ) : (
                  //     <button
                  //       className="expand_view_button"
                  //       onClick={() => setDetailView('summary')}
                  //     >
                  //       <span>Collapse View</span>
                  //       <Icon source={ChevronRightMinor} color="base" />
                  //     </button>
                  //   )}
                  // </div>
                }
              </>
            ),
          }}
        >
          <div className="rewards_launcher_settings_wrapper">
            <div className="header_wraper">
              {currentForm ? (
                <span className="left_section">
                  <div
                    onClick={() => {
                      if (currentContentBlock) {
                        setAddContent();
                      } else {
                        setCurrentForm();
                      }
                    }}
                  >
                    <Icon source={ChevronLeftMinor} color="base" />
                  </div>

                  <h2>
                    {currentContentBlock
                      ? currentContentBlock.content_type
                      : currentForm
                      ? i18n.t(currentForm)
                      : headerButton}
                  </h2>
                </span>
              ) : (
                <span className="left_section lancher_settings_toggle_header_buttons">
                  {headerButtons.map((button) => {
                    return (
                      <button
                        className={`toggle_header_button ${
                          button.val === headerButton ? 'active' : ''
                        }`}
                        onClick={() => {
                          setHeaderButton(button.val);
                        }}
                      >
                        {button?.name}
                      </button>
                    );
                  })}
                </span>
              )}

              <div className="heder-right-section"></div>
            </div>
            <div className="content-section">
              <div className="left_sidebarr">
                {currentForm === 'colorsTab' ? (
                  <Card>
                    <Card.Section>
                      <div className="card_body_content">
                        <div className="select_colors_container">
                          <CustomColorPicker
                            label="Popup Background color"
                            formData={formData}
                            setFormData={setFormData}
                            fieldName={'popup_bg_color'}
                            i18n={i18n}
                          />
                        </div>

                        <div className="select_colors_container">
                          <CustomColorPicker
                            label="Border color"
                            formData={formData}
                            setFormData={setFormData}
                            fieldName={'border_color'}
                            i18n={i18n}
                          />
                        </div>

                        <div className="select_colors_container">
                          <CustomColorPicker
                            label="Title font color"
                            formData={formData}
                            setFormData={setFormData}
                            fieldName={'title_font_color'}
                            i18n={i18n}
                          />
                        </div>

                        <div className="select_colors_container">
                          <CustomColorPicker
                            label="Subtitle font color"
                            formData={formData}
                            setFormData={setFormData}
                            fieldName={'subtitle_font_color'}
                            i18n={i18n}
                          />
                        </div>

                        <div className="select_colors_container">
                          <CustomColorPicker
                            label="Description font color"
                            formData={formData}
                            setFormData={setFormData}
                            fieldName={'description_font_color'}
                            i18n={i18n}
                          />
                        </div>

                        <div className="select_colors_container">
                          <CustomColorPicker
                            label="Label color"
                            formData={formData}
                            setFormData={setFormData}
                            fieldName={'label_color'}
                            i18n={i18n}
                          />
                        </div>

                        <div className="select_colors_container">
                          <CustomColorPicker
                            label="Form border color"
                            formData={formData}
                            setFormData={setFormData}
                            fieldName={'form_border_color'}
                            i18n={i18n}
                          />
                        </div>

                        <div className="select_colors_container">
                          <CustomColorPicker
                            label="Button border color"
                            formData={formData}
                            setFormData={setFormData}
                            fieldName={'button_border_color'}
                            i18n={i18n}
                          />
                        </div>

                        <div className="select_colors_container">
                          <CustomColorPicker
                            label="Button background color"
                            formData={formData}
                            setFormData={setFormData}
                            fieldName={'button_background_color'}
                            i18n={i18n}
                          />
                        </div>

                        <div className="select_colors_container">
                          <CustomColorPicker
                            label="Button text color"
                            formData={formData}
                            setFormData={setFormData}
                            fieldName={'button_text_color'}
                            i18n={i18n}
                          />
                        </div>

                        <div className="select_colors_container">
                          <CustomColorPicker
                            label="Toggle color"
                            formData={formData}
                            setFormData={setFormData}
                            fieldName={'toggle_color'}
                            i18n={i18n}
                          />
                        </div>
                        <div className="select_colors_container">
                          <CustomColorPicker
                            label="Active toggle color"
                            formData={formData}
                            setFormData={setFormData}
                            fieldName={'active_toggle_color'}
                            i18n={i18n}
                          />
                        </div>
                      </div>
                    </Card.Section>
                    <div class="colors_tab_help_text_container">
                      <p>For transparent color, set the color value to #.</p>
                    </div>
                  </Card>
                ) : currentForm === 'background_image' ? (
                  <>
                    <Card>
                      <Card.Section>
                        <div className="card_body_content">
                          <CustomImageDropzone
                            formData={formData}
                            setFormData={setFormData}
                            fieldName="background_image"
                          />
                        </div>
                      </Card.Section>
                    </Card>
                  </>
                ) : currentForm === 'heroTab' ? (
                  <Card>
                    <Card.Section>
                      <div className="card_body_content">
                        <div className="card_body_content">
                          <TextField
                            label="Title"
                            type="text"
                            value={`${formData?.title || ''}`}
                            onChange={(e) =>
                              setFormData({
                                ...formData,
                                title: e,
                              })
                            }
                          />
                        </div>
                        <div className="card_body_content">
                          <CustomImageDropzone
                            formData={formData}
                            setFormData={setFormData}
                            fieldName="gift_card_image"
                          />
                        </div>
                        <div className="card_body_content">
                          <Select
                            label="Position"
                            options={['Center', 'Left', 'Right']}
                            value={formData?.gift_card_position}
                            onChange={(e) => {
                              setFormData({
                                ...formData,
                                gift_card_position: e,
                              });
                            }}
                          />
                        </div>
                      </div>
                    </Card.Section>
                  </Card>
                ) : currentForm === 'contentTab' ? (
                  <>
                    <ContentBlock
                      domain={domain}
                      formData={formData}
                      setFormData={setFormData}
                      contentBlocks={contentBlocks}
                      setContentBlocks={setContentBlocks}
                      addContent={addContent}
                      setAddContent={setAddContent}
                      currentContentBlock={currentContentBlock}
                      setCurrentContentBlock={setCurrentContentBlock}
                      i18n={i18n}
                      templateId={templateId}
                      refetch={fetchGiftCardSettings}
                      editId={editId}
                    />

                    {/* <Card>
                    <Card.Section>
                      <div className="card_body_content">
                        <div className="card_body_content">
                          <TextField
                            label="Spacer"
                            type="number"
                            value={formData.spacer}
                            onChange={(e) => {
                              setFormData({ ...formData, spacer: e });
                            }}
                          />
                        </div>
                        <div className="card_body_content">
                          <TextField
                            label="Headline"
                            type="text"
                            value={formData.headline}
                            onChange={(e) => {
                              setFormData({ ...formData, headline: e });
                            }}
                          />
                        </div>
                        <div className="card_body_content">
                          <TextField
                            label="Checckbox text"
                            type="text"
                            value={formData.checkbox_text}
                            onChange={(e) => {
                              setFormData({ ...formData, checkbox_text: e });
                            }}
                          />
                        </div>
                        <div className="card_body_content">
                          <TextField
                            label="Paragraph"
                            type="text"
                            multiline={5}
                            value={formData.paragraph}
                            onChange={(e) => {
                              setFormData({ ...formData, paragraph: e });
                            }}
                          />
                        </div>
                      </div>
                    </Card.Section>
                  </Card> */}
                  </>
                ) : currentForm === 'formTab' ? (
                  <>
                    <ManageForm formData={formData} setFormData={setFormData} />
                  </>
                ) : currentForm === 'buttonTab' ? (
                  <>
                    <ManageAddToCartButton
                      formData={formData}
                      setFormData={setFormData}
                      i18n={i18n}
                    />
                  </>
                ) : (
                  <ul className="polaris-card">
                    {headerButton === 'Body' ? (
                      <>
                        <li
                          onClick={() => {
                            setCurrentForm('heroTab');
                          }}
                        >
                          <svg
                            width="26"
                            height="26"
                            viewBox="0 0 20 20"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M8.588 2.051c-2.056-.259-3.68.458-5 2.23-2.847 3.823-1.715 9.71 2.223 12.064 4.02 2.404 9.32.946 11.563-3.28.536-1.01.729-2.11.575-3.326-.146-1.153-1.173-1.957-2.284-1.812-.12.015-.237.041-.352.078l-.082.026a3.092 3.092 0 01-3.893-2.033 3.124 3.124 0 01-.142-.93c0-1.54-1.124-2.83-2.608-3.017zm.25-1.984c2.49.313 4.358 2.458 4.358 5.001 0 .114.017.226.051.335.182.584.797.908 1.374.724l.082-.027c.23-.073.465-.126.704-.157 2.216-.288 4.242 1.3 4.526 3.545.205 1.619-.06 3.134-.793 4.515-2.816 5.304-9.42 7.01-14.355 4.059-4.914-2.94-6.384-10.164-2.8-14.975C3.727.746 6.054-.283 8.837.067zM6.833 6.929a1.5 1.5 0 110-3 1.5 1.5 0 010 3zM4.5 11a1.5 1.5 0 110-3 1.5 1.5 0 010 3zm3 4a1.5 1.5 0 110-3 1.5 1.5 0 010 3zm6.25-1a1.75 1.75 0 110-3.5 1.75 1.75 0 010 3.5z"
                              fill="#637381"
                              fill-rule="nonzero"
                            ></path>
                          </svg>
                          <span className="left_section">Hero</span>
                        </li>
                        <li
                          onClick={() => {
                            setCurrentForm('contentTab');
                          }}
                        >
                          <svg
                            width="26"
                            height="26"
                            viewBox="0 0 20 20"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g fill="none" fill-rule="evenodd">
                              <path
                                d="M16.515 11H13a1 1 0 010-2h3.414L15.27 7.935l1.363-1.463 3.048 2.838a1 1 0 01.003 1.461l-3.029 2.839-1.367-1.46L16.515 11zm-12.98 0l1.19 1.114-1.368 1.46-3.029-2.838a1 1 0 01.003-1.462l3.048-2.838L4.742 7.9 3.56 9H7a1 1 0 110 2H3.535zM9 16.497V13a1 1 0 012 0v3.43l1.06-1.154 1.473 1.352-2.796 3.048a1 1 0 01-1.462.013l-2.88-3.029 1.45-1.378L9 16.497zm2-12.994V7a1 1 0 01-2 0V3.57L7.94 4.723 6.467 3.372 9.263.324a1 1 0 011.462-.013l2.88 3.029-1.45 1.378L11 3.503z"
                                fill="#637381"
                                fill-rule="nonzero"
                              ></path>
                            </g>
                          </svg>
                          <span className="left_section">Content</span>
                        </li>
                        <li
                          onClick={() => {
                            setCurrentForm('formTab');
                          }}
                        >
                          <svg
                            width="26"
                            height="26"
                            viewBox="0 0 20 20"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M10 17c-3.69 0-6.974-2.19-9.83-6.442a1 1 0 010-1.116C3.026 5.19 6.31 3 10 3c3.69 0 6.974 2.19 9.83 6.442a1 1 0 010 1.116C16.974 14.81 13.69 17 10 17zm0-2c2.788 0 5.376-1.63 7.784-5C15.376 6.63 12.788 5 10 5c-2.788 0-5.376 1.63-7.784 5 2.408 3.37 4.996 5 7.784 5zm0-2a3 3 0 110-6 3 3 0 010 6z"
                              fill="#637381"
                              fill-rule="nonzero"
                            ></path>
                          </svg>
                          <span className="left_section">Form</span>
                        </li>
                        <li
                          onClick={() => {
                            setCurrentForm('buttonTab');
                          }}
                        >
                          <svg
                            width="26"
                            height="26"
                            viewBox="0 0 20 20"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M10 17c-3.69 0-6.974-2.19-9.83-6.442a1 1 0 010-1.116C3.026 5.19 6.31 3 10 3c3.69 0 6.974 2.19 9.83 6.442a1 1 0 010 1.116C16.974 14.81 13.69 17 10 17zm0-2c2.788 0 5.376-1.63 7.784-5C15.376 6.63 12.788 5 10 5c-2.788 0-5.376 1.63-7.784 5 2.408 3.37 4.996 5 7.784 5zm0-2a3 3 0 110-6 3 3 0 010 6z"
                              fill="#637381"
                              fill-rule="nonzero"
                            ></path>
                          </svg>
                          <span className="left_section">Button</span>
                        </li>
                      </>
                    ) : (
                      <>
                        <li
                          onClick={() => {
                            setCurrentForm('background_image');
                          }}
                        >
                          <svg
                            width="26"
                            height="26"
                            viewBox="0 0 20 20"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M8.588 2.051c-2.056-.259-3.68.458-5 2.23-2.847 3.823-1.715 9.71 2.223 12.064 4.02 2.404 9.32.946 11.563-3.28.536-1.01.729-2.11.575-3.326-.146-1.153-1.173-1.957-2.284-1.812-.12.015-.237.041-.352.078l-.082.026a3.092 3.092 0 01-3.893-2.033 3.124 3.124 0 01-.142-.93c0-1.54-1.124-2.83-2.608-3.017zm.25-1.984c2.49.313 4.358 2.458 4.358 5.001 0 .114.017.226.051.335.182.584.797.908 1.374.724l.082-.027c.23-.073.465-.126.704-.157 2.216-.288 4.242 1.3 4.526 3.545.205 1.619-.06 3.134-.793 4.515-2.816 5.304-9.42 7.01-14.355 4.059-4.914-2.94-6.384-10.164-2.8-14.975C3.727.746 6.054-.283 8.837.067zM6.833 6.929a1.5 1.5 0 110-3 1.5 1.5 0 010 3zM4.5 11a1.5 1.5 0 110-3 1.5 1.5 0 010 3zm3 4a1.5 1.5 0 110-3 1.5 1.5 0 010 3zm6.25-1a1.75 1.75 0 110-3.5 1.75 1.75 0 010 3.5z"
                              fill="#637381"
                              fill-rule="nonzero"
                            ></path>
                          </svg>
                          <span className="left_section">Background Image</span>
                        </li>
                        <li
                          onClick={() => {
                            setCurrentForm('colorsTab');
                          }}
                        >
                          <svg
                            width="26"
                            height="26"
                            viewBox="0 0 20 20"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M8.588 2.051c-2.056-.259-3.68.458-5 2.23-2.847 3.823-1.715 9.71 2.223 12.064 4.02 2.404 9.32.946 11.563-3.28.536-1.01.729-2.11.575-3.326-.146-1.153-1.173-1.957-2.284-1.812-.12.015-.237.041-.352.078l-.082.026a3.092 3.092 0 01-3.893-2.033 3.124 3.124 0 01-.142-.93c0-1.54-1.124-2.83-2.608-3.017zm.25-1.984c2.49.313 4.358 2.458 4.358 5.001 0 .114.017.226.051.335.182.584.797.908 1.374.724l.082-.027c.23-.073.465-.126.704-.157 2.216-.288 4.242 1.3 4.526 3.545.205 1.619-.06 3.134-.793 4.515-2.816 5.304-9.42 7.01-14.355 4.059-4.914-2.94-6.384-10.164-2.8-14.975C3.727.746 6.054-.283 8.837.067zM6.833 6.929a1.5 1.5 0 110-3 1.5 1.5 0 010 3zM4.5 11a1.5 1.5 0 110-3 1.5 1.5 0 010 3zm3 4a1.5 1.5 0 110-3 1.5 1.5 0 010 3zm6.25-1a1.75 1.75 0 110-3.5 1.75 1.75 0 010 3.5z"
                              fill="#637381"
                              fill-rule="nonzero"
                            ></path>
                          </svg>
                          <span className="left_section">Colors</span>
                        </li>
                        <li className="opened_form_settng_list">
                          <div className="opened_form_settng_wrapper">
                            <Card>
                              <Card.Section>
                                <div className="card_body_content">
                                  <div className="select_colors_container">
                                    <TextField
                                      label="Border radius"
                                      type="number"
                                      suffix="px"
                                      value={`${formData?.border_radius}`}
                                      onChange={(e) =>
                                        setFormData({
                                          ...formData,
                                          border_radius: e,
                                        })
                                      }
                                    />
                                  </div>
                                </div>
                                <div className="card_body_content">
                                  <TextField
                                    label="Font family"
                                    value={formData?.font_family}
                                    onChange={(e) => {
                                      setFormData({
                                        ...formData,
                                        font_family: e,
                                      });
                                    }}
                                  />
                                </div>
                              </Card.Section>
                            </Card>
                          </div>
                        </li>
                      </>
                    )}
                  </ul>
                )}
                {!addContent && !currentForm && (
                  <div className="customize-card">
                    <p>
                      Customize your theme with branded images, text, icons and
                      more, when you upgrade.
                    </p>
                  </div>
                )}
              </div>
              <div className="right-section">
                {
                  <GiftCardHtml
                    formData={formData}
                    currentForm={currentForm}
                    contentBlocks={contentBlocks}
                  />
                }
              </div>
            </div>
          </div>
        </Card>
        <div className="translation_settings_wrapper">
          {/* <ManageTranslations
          i18n={i18n}
          domain={domain}
          setToastContent={setToastContent}
        /> */}
        </div>
        <HideSideNav />
      </Frame>
    </div>
  );
};

export default GiftCard;
