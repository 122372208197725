import { Icon } from '@shopify/polaris';
import { BalanceMajor } from '@shopify/polaris-icons';
import { PointOfSaleMajor } from '@shopify/polaris-icons';
import { EmailNewsletterMajor } from '@shopify/polaris-icons';
import { ReferralMajor } from '@shopify/polaris-icons';
import { CashDollarMajor } from '@shopify/polaris-icons';
import {
  ImageMajor,
  MobileBackArrowMajor,
  StoreStatusMajor,
} from '@shopify/polaris-icons';
import React from 'react';

const SummaryComponent = (props) => {
  const { i18n, summaryView, setSummaryView } = props;
  return (
    <div className="summary_side_nav_container">
      <div className="summary_list_wrapper">
        <ul className="summary_list">
          <li
            className={`list_item ${
              summaryView == 'tooglePoints' ? 'active' : ''
            }`}
            onClick={() => {
              setSummaryView('tooglePoints');
            }}
          >
            <div className="icon_wrapper">
              <Icon source={StoreStatusMajor} color="base" />
            </div>
            <div className="list_title">
              {i18n.t('points_programs.program_status')}
            </div>
          </li>
          <li
            className={`list_item ${
              summaryView == 'pointsProgram' ? 'active' : ''
            }`}
            onClick={() => {
              setSummaryView('pointsProgram');
            }}
          >
            <div className="icon_wrapper">
              <Icon source={ImageMajor} tone="base" />
            </div>
            <div className="list_title">
              {i18n.t('points_programs.hero_image')}
            </div>
          </li>
          <li
            className={`list_item ${
              summaryView == 'waysToEarn' ? 'active' : ''
            }`}
            onClick={() => {
              setSummaryView('waysToEarn');
            }}
          >
            <div className="icon_wrapper">
              <Icon source={CashDollarMajor} color="base" />
            </div>
            <div className="list_title">
              {i18n.t('points_programs.ways_to_earn_points')}
            </div>
          </li>
          <li
            className={`list_item ${
              summaryView == 'waysToRedeem' ? 'active' : ''
            }`}
            onClick={() => {
              setSummaryView('waysToRedeem');
            }}
          >
            <div className="icon_wrapper">
              <Icon source={BalanceMajor} color="base" />
            </div>
            <div className="list_title">
              {i18n.t('points_programs.ways_to_redeem_points')}
            </div>
          </li>
          <li
            className={`list_item ${
              summaryView == 'referral_rewards' ? 'active' : ''
            }`}
            onClick={() => {
              setSummaryView('referral_rewards');
            }}
          >
            <div className="icon_wrapper">
              <Icon source={ReferralMajor} color="base" />
            </div>
            <div className="list_title">
              {i18n.t('points_programs.referrals')}
            </div>
          </li>
          <li
            className={`list_item ${
              summaryView == 'appleWalletPasses' ? 'active' : ''
            }`}
            onClick={() => {
              setSummaryView('appleWalletPasses');
            }}
          >
            <div className="icon_wrapper">
              <Icon source={ReferralMajor} color="base" />
            </div>
            <div className="list_title">Apple Wallet Passess</div>
          </li>
          <li
            className={`list_item ${
              summaryView == 'point_program_tiers' ? 'active' : ''
            }`}
            onClick={() => {
              setSummaryView('point_program_tiers');
            }}
          >
            <div className="icon_wrapper">
              <Icon source={PointOfSaleMajor} color="base" />
            </div>
            <div className="list_title">
              {i18n.t('points_programs.points_program_tiers')}
            </div>
          </li>
          <li
            className={`list_item ${
              summaryView == 'points_emails' ? 'active' : ''
            }`}
            onClick={() => {
              setSummaryView('points_emails');
            }}
          >
            <div className="icon_wrapper">
              <Icon source={EmailNewsletterMajor} color="base" />
            </div>
            <div className="list_title">
              {i18n.t('points_programs.points_program_email')}
            </div>
          </li>
          <li
            className={`list_item ${
              summaryView == 'rewardsPage' ? 'active' : ''
            }`}
            onClick={() => {
              setSummaryView('rewardsPage');
            }}
          >
            <div className="icon_wrapper">
              <Icon source={EmailNewsletterMajor} color="base" />
            </div>
            <div className="list_title">{i18n.t('rewards_page')}</div>
          </li>
          <li
            className={`list_item ${
              summaryView == 'ReferralPage' ? 'active' : ''
            }`}
            onClick={() => {
              setSummaryView('ReferralPage');
            }}
          >
            <div className="icon_wrapper">
              <Icon source={EmailNewsletterMajor} color="base" />
            </div>
            <div className="list_title">Referral Page</div>
          </li>
          <li
            className={`list_item ${
              summaryView == 'RewardsLauncherSettings' ? 'active' : ''
            }`}
            onClick={() => {
              setSummaryView('RewardsLauncherSettings');
            }}
          >
            <div className="icon_wrapper">
              <Icon source={EmailNewsletterMajor} color="base" />
            </div>
            <div className="list_title">Rewards Launcher</div>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default SummaryComponent;
