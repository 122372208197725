import React, { useEffect, useState } from 'react'
import ExportModal from './ExportModal';
import { gql, useQuery } from '@apollo/client';
import { Pagination } from '@shopify/polaris';

const SubscriptionCustomers = (props) => {
    const { domain, dateFilter, setDateFilter, showExport, setShowExport, toastContent, setToastContent, selectedDates, customMainButtons, setCustomMainButtons, setHeaderButtons, setHeaderButton, headerButton } = props;

    const [subCustomers, setSubCustomers] = useState([]);
    const [page, setPage] = useState(1);
    const [hasPrevious, setHasPrevious] = useState(false);
    const [hasMore, setHasMore] = useState(false);

    const initHeaderButtons = [
        {
            val: 'active',
            name: 'Active contracts',
        },
        {
            val: 'paused',
            name: 'Paused contracts',
        },
        {
            val: 'expired',
            name: 'Cancelled contracts',
        },
        {
            val: 'pre_paid',
            name: 'Prepaid contracts',
        },
    ];

    const GET_CUSTOMERS = gql`
        query (
            $page: String
            $status: String
            ) {
            fetchCustomers(
                page: $page
                status: $status
            ) {
                customerSubscriptions {
                    id
                    indexId
                    shopifyId
                    shopifyCustomerId
                    formattedProducts
                    email
                    status
                }
                totalCount
                totalPages
                pageNumber
            }
        }
  `;

    const { data, loading, error, refetch } = useQuery(GET_CUSTOMERS, {
        fetchPolicy: 'no-cache',
        variables: {
            page: page.toString(),
            status: headerButton,
        },
    });

    useEffect(() => {
        refetch({
            variables: {
                page: page.toString(),
                status: headerButton,
            },
        });
        // setTotalPages(data?.fetchCustomers?.totalPages);
    }, [page, headerButton]);

    useEffect(() => {
        setSubCustomers([])
        setSubCustomers(data?.fetchCustomers?.customerSubscriptions || []);
        if (+page < +data?.fetchCustomers?.totalPages) {
            setHasMore(true);
        }
        else {
            setHasMore(false)
        }
        if (+page <= 1) {
            setHasPrevious(false)
        }
        else {
            setHasPrevious(true)
        }
    }, [data])

    useEffect(() => {
        setHeaderButtons(initHeaderButtons);
        setHeaderButton("active")
    }, [])

    useEffect(() => {
        setCustomMainButtons([{
            name: "Export",
            onClick: () => { }
        }])
    }, [dateFilter, selectedDates])
    return (
        <div className='forecastInventory_container'>
            {
                showExport && (
                    <ExportModal setShowExport={setShowExport} showExport={showExport} selectedDates={selectedDates} domain={domain} apiUrl='/export_forecast_inventory' {...{ toastContent, setToastContent }} dateFilter={dateFilter} />
                )
            }
            <table className='forecastInventory_table'>
                <thead>
                    <tr>
                        <th>Shopify customer ID</th>
                        <th>Email</th>
                        <th>Subscription ID</th>
                        <th>Subscription status</th>
                        <th>Product Id</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        subCustomers?.map((sub) => {
                            return (
                                <tr>
                                    <td>{sub?.shopifyCustomerId}</td>
                                    <td>{sub?.email}</td>
                                    <td>{sub?.shopifyId}</td>
                                    <td>{sub?.status}</td>
                                    <td>{sub?.formattedProducts}</td>
                                </tr>
                            )
                        })
                    }

                </tbody>
            </table>
            <div style={{ width: '100%', justifyContent: 'center', display: 'flex' }}>
                <Pagination
                    hasPrevious={hasPrevious}
                    onPrevious={() => {
                        setPage((prev) => +prev - 1)
                    }}
                    hasNext={hasMore}
                    onNext={() => {
                        setPage((prev) => +prev + 1)
                    }}
                />
            </div>
        </div>
    )
}

export default SubscriptionCustomers
