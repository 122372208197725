import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import {
  ChevronRightMinor,
  MobileBackArrowMajor,
} from '@shopify/polaris-icons';
import { Button, Card, Frame, Icon, Page, TextField } from '@shopify/polaris';
import SummaryComponent from './SummaryComponent';
import AffiliateStatus from './AffiliateStatus';
import AffiliateOrders from './AffiliateOrders';
import AffiliatesPayouts from './AffiliatesPayouts';
import AffiliatePage from './AffiliatePage';
import AffiliatesHome from './AffiliatesHome';
import Analytics from './Analytics';
import AffiliateProgramSetting from './AffiliateProgramSetting';
import Affiliates from './Affiliates';
import AffiliateCommission from './AffiliateCommission';
import AffiliateOverview from './AffiliateOverview';
import InviteAffiliateForm from './InviteAffiliateForm';

const AffiliatesIndex = (props) => {
  const { selected_tab, edit_id } = useParams();
  const {
    domain,
    i18n,
    formatDate,
    floatingSetupGuide,
    setFloatingSetupGuide,
  } = props;
  const [detailView, setDetailView] = useState(false);
  const [selectedEmailIndex, setSelectedEmailIndex] = useState();
  const [summaryView, setSummaryView] = useState(selected_tab);
  const PageName = 'Affiliates Home';
  const [headerTitle, setHeaderTitle] = useState(PageName);
  const history = useHistory();
  const [customHeader, setCustomHeader] = useState();

  useEffect(() => {
    if (selected_tab) {
      setSummaryView(selected_tab);
    }
  }, [selected_tab]);

  const headerObject = {
    affiliatesStatus: {
      title: 'Affiliate Program status',
      desc: 'View details & manage affiliate status',
    },
    affiliates: {
      title: 'Affiliates',
      desc: 'View, approve & manage affiliates & influencers who applied to your affiliate marketing program.',
    },
    affiliate_commission: {
      title: 'Commission profiles',
      desc: 'Commission profiles define how affiliates earn commission as well as the discount for their affiliate link and code',
    },
    affiliate_commission_createNew: {
      title: 'New Commission profile',
      desc: 'Commission profiles define how affiliates earn commission as well as the discount for their affiliate link and code',
    },
    affiliateOrders: {
      title: 'Affiliate Orders',
      desc: 'View orders attributed to your affiliate tracking url(s), earned commision, order status etc',
    },
    default: {
      title: 'Affiliate Program',
      desc: 'Launch & manage an affiliates & influencer marketing program; and scale revenue with word-of-mouth marketing.',
    },
  };
  //     const GET_POINTS_PROGRAM = gql`
  //     query($page: String) {
  //         fetchPointProgram {
  //             id
  //             title
  //             description
  //             images
  //         }
  //     }
  //   `;
  //     const { data, loading, error, refetch } = useQuery(GET_POINTS_PROGRAM, {
  //         fetchPolicy: 'no-cache',

  //     });

  //     useEffect(() => {
  //         refetch({
  //             variables: {
  //                 page: page.toString()
  //             }
  //         });
  //     }, [page]);

  const RenderCustomHeader = () => {
    return (
      <div className="chargezen_custom_page_header">
        <div className="page_title_wrapper">
          <div className="back_button_wrapper">
            {summaryView ? (
              <div
                className="back-button pointer"
                onClick={() => {
                  let toRedirect = edit_id
                    ? `/affiliates/${selected_tab}`
                    : `/affiliates`;
                  history.push(toRedirect);
                }}
              >
                <Icon source={MobileBackArrowMajor} color="base" />
              </div>
            ) : (
              ''
            )}{' '}
            <div class="text_wrapper page_heading_title_wrapper">
              <div
                class="Polaris-Header-Title__TitleAndSubtitleWrapper"
                style={{
                  width: '100%',
                }}
              >
                <div class="Polaris-Header-Title">
                  <h1 class="Polaris-DisplayText Polaris-DisplayText--sizeLarge">
                    <span class="Polaris-TextStyle--variationStrong">
                      <div class="back_button_wrapper">
                        {' '}
                        <div
                          class="text_wrapper page_heading_title_wrapper"
                          style={{
                            width: '100%',
                          }}
                        >
                          <span>
                            {customHeader ||
                              headerObject?.[selected_tab || 'default']?.title}
                          </span>
                          {window.location.pathname.includes(
                            'affiliates/affiliates'
                          ) && (
                            <div className="create_selling_plan_subscription_wrapper">
                              <Button
                                onClick={() => {
                                  history.push(`/affiliates/inviteAffiliate`);
                                }}
                              >
                                Invite affiliate
                              </Button>
                            </div>
                          )}
                        </div>
                      </div>
                    </span>
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="sidebar_summary header_description">
          {headerObject?.[selected_tab || 'default']?.desc}
        </div>
      </div>
    );
  };

  return (
    <>
      <Frame>
        <Page>
          <RenderCustomHeader />
          <div className={`chargezen_full_width_main_container`}>
            <div className={`chargezen_full_width_container`}>
              <div className={`chargezen_full_width_wrapper`}>
                {!summaryView ? (
                  <AffiliateOverview
                    {...{
                      i18n,
                      floatingSetupGuide,
                      setFloatingSetupGuide,
                      domain,
                    }}
                    customNavTabs={{ affiliate_influencer: true }}
                  />
                ) : summaryView == 'inviteAffiliate' ? (
                  <InviteAffiliateForm
                    detailView={detailView}
                    domain={domain}
                    i18n={i18n}
                    setDetailView={setDetailView}
                    setHeaderTitle={setHeaderTitle}
                    {...{ customHeader, setCustomHeader }}
                  />
                ) : summaryView == 'affiliates' ? (
                  <Affiliates
                    detailView={detailView}
                    domain={domain}
                    i18n={i18n}
                    setDetailView={setDetailView}
                    setHeaderTitle={setHeaderTitle}
                  />
                ) : summaryView == 'affiliate_commission' ? (
                  <AffiliateCommission
                    detailView={detailView}
                    domain={domain}
                    i18n={i18n}
                    setDetailView={setDetailView}
                    setHeaderTitle={setHeaderTitle}
                    {...{ customHeader, setCustomHeader }}
                  />
                ) : summaryView == 'affiliateOrders' ? (
                  <AffiliateOrders
                    domain={domain}
                    i18n={i18n}
                    detailView={detailView}
                    setDetailView={setDetailView}
                    setHeaderTitle={setHeaderTitle}
                    formatDate={formatDate}
                  />
                ) : summaryView == 'affiliatesPayouts' ? (
                  <AffiliatesPayouts
                    domain={domain}
                    i18n={i18n}
                    detailView={detailView}
                    setDetailView={setDetailView}
                    setHeaderTitle={setHeaderTitle}
                  />
                ) : summaryView == 'affiliatesStatus' ? (
                  <AffiliateStatus
                    domain={domain}
                    i18n={i18n}
                    detailView={detailView}
                    setDetailView={setDetailView}
                    setHeaderTitle={setHeaderTitle}
                  />
                ) : summaryView == 'affiliatePage' ? (
                  <div className="referrals_main_container">
                    <AffiliatePage
                      domain={domain}
                      i18n={i18n}
                      detailView={detailView}
                      setDetailView={setDetailView}
                      setHeaderTitle={setHeaderTitle}
                    />
                  </div>
                ) : summaryView == 'affiliatesHome' ? (
                  <div className="referrals_main_container">
                    <AffiliatesHome
                      domain={domain}
                      i18n={i18n}
                      detailView={detailView}
                      setDetailView={setDetailView}
                      setHeaderTitle={setHeaderTitle}
                    />
                  </div>
                ) : summaryView == 'analytics' ? (
                  <Analytics
                    domain={domain}
                    i18n={i18n}
                    detailView={detailView}
                    setDetailView={setDetailView}
                    setHeaderTitle={setHeaderTitle}
                  />
                ) : summaryView == 'affiliateProgramSetting' ? (
                  <AffiliateProgramSetting
                    domain={domain}
                    i18n={i18n}
                    detailView={detailView}
                    setDetailView={setDetailView}
                    setHeaderTitle={setHeaderTitle}
                  />
                ) : (
                  ''
                )}
              </div>
            </div>
          </div>
        </Page>
      </Frame>
    </>
  );
};

export default AffiliatesIndex;
