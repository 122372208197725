import React, { useEffect, useState } from 'react';
import { FormLayout, TextField } from '@shopify/polaris';

const ManageRoutes = (props) => {
    const {routeFormData, setRouteFormData, i18n, formData} = props
    const handleChange = (value, key)=>{
        setRouteFormData({...routeFormData, [key]: value})
    }

    const [sideNav, setSideNav] = useState();

    const setSideNavTranslations = ()=>{
        let _translations = {}
        formData?.filter((t) => t.category == "Side Nav")?.map((formField)=>{
            _translations[formField?.key] = formField.value;
        })
        setSideNav({..._translations})
    }
    useEffect(()=>{
        setSideNavTranslations();
    },[formData])
    return (
        <FormLayout>
            <FormLayout.Group>
                <TextField
                    label={i18n.t("zennportal_form.default_route")}
                    value={routeFormData?.default_route}
                    onChange={(e) => {
                        handleChange(e, "default_route");
                    }}
                    autoComplete="off"
                />
            </FormLayout.Group>
            <FormLayout.Group>
                <TextField
                    label={sideNav?.active}
                    value={routeFormData?.active_sub}
                    prefix={`/pages/${routeFormData?.default_route}`}
                    onChange={(e) => {
                        handleChange(e, "active_sub");
                    }}
                    autoComplete="off"
                />
            </FormLayout.Group>
            <FormLayout.Group>
                <TextField
                    label={sideNav?.paused}
                    prefix={`/pages/${routeFormData?.default_route}`}
                    value={routeFormData?.paused_sub}
                    onChange={(e) => {
                        handleChange(e, "paused_sub");
                    }}
                    autoComplete="off"
                />
            </FormLayout.Group>
            <FormLayout.Group>
                <TextField
                    label={sideNav?.cancelled}
                    prefix={`/pages/${routeFormData?.default_route}`}
                    value={routeFormData?.cancelled_sub}
                    onChange={(e) => {
                        handleChange(e, "cancelled_sub");
                    }}
                    autoComplete="off"
                />
            </FormLayout.Group>
            <FormLayout.Group>
                <TextField
                    label={sideNav?.delivery_schedule}
                    prefix={`/pages/${routeFormData?.default_route}`}
                    value={routeFormData?.delivery_schedule}
                    onChange={(e) => {
                        handleChange(e, "delivery_schedule");
                    }}
                    autoComplete="off"
                />
            </FormLayout.Group>
            <FormLayout.Group>
                <TextField
                    label={sideNav?.account}
                    prefix={`/pages/${routeFormData?.default_route}`}
                    value={routeFormData?.account}
                    onChange={(e) => {
                        handleChange(e, "account");
                    }}
                    autoComplete="off"
                />
            </FormLayout.Group>
            <FormLayout.Group>
                <TextField
                    label={sideNav?.order_history}
                    prefix={`/pages/${routeFormData?.default_route}`}
                    value={routeFormData?.order_history}
                    onChange={(e) => {
                        handleChange(e, "order_history");
                    }}
                    autoComplete="off"
                />
            </FormLayout.Group>
            <FormLayout.Group>
                <TextField
                    label={sideNav?.billing}
                    prefix={`/pages/${routeFormData?.default_route}`}
                    value={routeFormData?.billing}
                    onChange={(e) => {
                        handleChange(e, "billing");
                    }}
                    autoComplete="off"
                />
            </FormLayout.Group>
            <FormLayout.Group>
                <TextField
                    label={sideNav?.addresses}
                    prefix={`/pages/${routeFormData?.default_route}`}
                    value={routeFormData?.your_address}
                    onChange={(e) => {
                        handleChange(e, "your_address");
                    }}
                    autoComplete="off"
                />
            </FormLayout.Group>
        </FormLayout>
    )
}

export default ManageRoutes