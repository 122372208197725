import { gql, useMutation, useQuery, useLazyQuery } from '@apollo/client';
import {
  Banner,
  Card,
  ContextualSaveBar,
  Form,
  Frame,
  List,
  Page,
  Spinner,
  Tabs,
  Toast,
  Layout,
  FormLayout,
  TextField,
  Button,
  Badge,
  Icon,
} from '@shopify/polaris';
import { Formik } from 'formik';
import { isEmpty } from 'lodash';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import * as yup from 'yup';

import Billing from './Billing';

//SubscriptionBilling Images
import LoadingScreen from '../LoadingScreen';
import { ChevronRightMinor } from '@shopify/polaris-icons';
import PixelIcon from '../../images/PixelIcon';

const SubscriptionBilling = (props) => {
  const { domain, i18n, detailView, setDetailView, passwordProtected } = props;
  // form data ########################################################
  const [formData, setFormData] = useState(null);
  const [formErrors, setFormErrors] = useState([]);
  const [saveSuccess, setSaveSuccess] = useState(false);
  const hideSaveSuccess = useCallback(() => setSaveSuccess(false), []);
  const validationSchema = yup.object().shape({
    // internalName: yup.string().required().label('Internal name'),
  });

  const confirmPasswordQuery = gql`
    query ($password: String!) {
      confirmPassword(password: $password) {
        success
      }
    }
  `;
  const GET_DATA = gql`
    query {
      fetchSetting {
        paymentRetries
        paymentDelayRetries
        maxFailStrategy
        orderCancelOption
      }
    }
  `;
  let { id, tabname } = useParams();
  console.log('tabnametabnametabname', tabname);
  const [getData, { data, loading, error, refetch }] = useLazyQuery(GET_DATA, {
    fetchPolicy: 'network-only',
  });

  const [UPDATE_SETTING, setUpdateSetting] = useState(gql`
    mutation ($input: UpdateSettingInput!) {
      updateSetting(input: $input) {
        setting {
          paymentRetries
          paymentDelayRetries
          maxFailStrategy
          orderCancelOption
        }
      }
    }
  `);

  const [updateSetting] = useMutation(UPDATE_SETTING);

  useEffect(() => {
    if (data) {
      setFormData({ ...data.fetchSetting });
    }
  }, [data]);

  const initialValues = {
    paymentDelayRetries: '',
    paymentRetries: '',
  };
  // form data #####################################################

  const [selectedTitleTab, setSelectedTitleTab] = useState(0);
  const [selectedSetting, setSelectedSetting] = useState(
    tabname ? tabname : ''
  );
  const handleBackSetting = useCallback(() => {
    setSelectedSetting('');
  }, [setSelectedSetting]);

  // Password confirmation
  const [passwordConfirmed, setPasswordConfirmed] = useState(
    !passwordProtected
  );
  const [password, setPassword] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [
    confirmPassword,
    { data: confirmPasswordRes, loading: passwordLoading },
  ] = useLazyQuery(confirmPasswordQuery, { fetchPolicy: 'network-only' });

  const verifyPassword = () => {
    if (!isEmpty(password)) {
      confirmPassword({
        variables: {
          password: password,
        },
      });
    }
  };

  useEffect(() => {
    if (passwordConfirmed) {
      getData();
    }
  }, [passwordConfirmed]);

  useEffect(() => {
    if (confirmPasswordRes?.confirmPassword?.success) {
      setPasswordConfirmed(true);
    } else {
      setPasswordError(confirmPasswordRes?.errors[0]?.message);
    }
  }, [confirmPasswordRes]);

  const accessSettings = JSON.parse(localStorage.getItem('accessSettings'));

  useEffect(() => {
    if (saveSuccess) {
      setSaveSuccess(false);
    }
  }, [selectedTitleTab]);

  const emailEditorRef = useRef(null);

  return (
    <>
      <Frame>
        {passwordConfirmed ? (
          <>
            {loading && (
              <Card>
                <LoadingScreen />
              </Card>
            )}
            {formErrors.length > 0 && (
              <>
                <Banner
                  title={i18n.t('setting_could_not_be_saved')}
                  status="critical"
                >
                  <List type="bullet">
                    {formErrors.map((message, index) => (
                      <List.Item key={index}>{message.message}</List.Item>
                    ))}
                  </List>
                </Banner>
                <br />
              </>
            )}
            <Card>
              <div class="summary_width_trans full_width">
                <Formik
                  validationSchema={validationSchema}
                  initialValues={formData ? formData : initialValues}
                  enableReinitialize
                  onSubmit={(
                    values,
                    { setSubmitting, setDirty, resetForm, touched }
                  ) => {
                    console.log('values', values);
                    const newValues = {
                      ...values,
                      navigationDelivery:
                        values.navigationDelivery || 'storeowner_and_customer',
                      reactiveSubscription:
                        values.reactiveSubscription ||
                        'storeowner_and_customer',
                      shipingAddress:
                        values.shipingAddress || 'storeowner_and_customer',
                      shipment: values.shipment || 'storeowner_and_customer',
                      subscriptionCancellation:
                        values.subscriptionCancellation ||
                        'storeowner_and_customer',
                      swapProduct:
                        values.swapProduct || 'storeowner_and_customer',
                      upcomingQuantity:
                        values.upcomingQuantity || 'storeowner_and_customer',
                      delayOrder:
                        values.delayOrder || 'storeowner_and_customer',
                      pauseSubscription:
                        values.pauseSubscription || 'storeowner_and_customer',
                    };
                    delete newValues?.recurringChargeStatus;
                    delete newValues?.chargeConfirmationLink;
                    delete newValues?.plan;
                    console.log('newValues', newValues);

                    updateSetting({
                      variables: { input: { params: newValues } },
                    })
                      .then((resp) => {
                        const data = resp.data;
                        const errors = data.errors;

                        if (errors) {
                          setFormErrors(errors);
                        } else {
                          setSaveSuccess(true);
                          console.log('oke');
                          refetch();
                          setDirty(false);
                          // resetForm({});
                          console.log('kxjckxjck');
                        }

                        setSubmitting(false);
                      })
                      .catch((error) => {
                        setSubmitting(false);
                        setFormErrors(error);
                      });
                  }}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                    setFieldValue,
                    resetForm,
                    dirty,
                    setDirty,
                    formik,
                    /* and other goodies */
                  }) => (
                    <Form onSubmit={handleSubmit}>
                      {dirty && (
                        <ContextualSaveBar
                          alignContentFlush={true}
                          message={i18n.t('unsaved_changes')}
                          saveAction={{
                            onAction: () => handleSubmit(),
                            loading: isSubmitting,
                            disabled: false,
                          }}
                          discardAction={{
                            onAction: resetForm,
                          }}
                        />
                      )}

                      {saveSuccess && (
                        <Toast
                          content={i18n.t('setting_is_saved')}
                          onDismiss={hideSaveSuccess}
                        />
                      )}
                      <>
                        {/* new settings bar */}
                        <div className="settings-container">
                          {/* settings layout */}
                          <Billing
                            i18n={i18n}
                            values={values}
                            touched={touched}
                            errors={errors}
                            setFieldValue={setFieldValue}
                            handleBack={handleBackSetting}
                          />
                        </div>
                      </>
                    </Form>
                  )}
                </Formik>
              </div>
            </Card>
          </>
        ) : (
          <Page title="Password protected">
            <Layout>
              <Layout.Section>
                <Card sectioned>
                  <FormLayout>
                    <TextField
                      value={password}
                      onChange={(value) => setPassword(value)}
                      label={i18n.t('password')}
                      type="password"
                      error={passwordError && passwordError}
                    />
                    <Button
                      primary
                      loading={passwordLoading}
                      onClick={verifyPassword}
                    >
                      {i18n.t('confirm')}
                    </Button>
                  </FormLayout>
                </Card>
              </Layout.Section>
            </Layout>
          </Page>
        )}
      </Frame>
    </>
  );
};

export default SubscriptionBilling;
