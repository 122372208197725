import React, { useEffect, useState } from "react";
import { Card, Frame, Page, Select, Stack, TextField } from "@shopify/polaris";
import { GetStartedIcon } from "../layout/CustomIcon";

function RenderSubCategory({fields, index,changeFor, setFieldValue}){
    return(
        <>
            {
                fields?.map((f,i)=>{
                    return(
                        <>
                        {
                            f?.category ?
                            <div className="sub_category_field_container">
                                <h3>{f?.name}</h3>
                                <RenderSubCategory fields={f?.fields} index={`${index},${i}`} changeFor={changeFor} {...{ setFieldValue}}/>
                            </div>
                            :
                            <>
                                <div className={`category_field_wrapper ${f.type == "color" ? "color-field" : ""}`}>
                                    {
                                        f?.type == "select" ? 
                                        <Select
                                            label={f.key}
                                            options={f.options}
                                            onChange={(e) => { setFieldValue(`${index},${i}`,e, changeFor) }}
                                            value={f.value}
                                        />
                                        :
                                        <TextField
                                            type={f?.type}
                                            label={f.key}
                                            placeholder=''
                                            value={f.value}
                                            onChange={(e) => { setFieldValue(`${index},${i}`,e, changeFor) }}
                                            autoComplete="off"
                                        />
                                    }
                                     
                                    {
                                        f?.type == "color" &&
                                        <TextField
                                            type={'text'}
                                            label={f.key}
                                            placeholder=''
                                            value={f.value}
                                            onChange={(e) => { setFieldValue(`${index},${i}`,e, changeFor) }}
                                            autoComplete="off"
                                        />
                                    }
                                </div>
                            </>
                        }
                        </>
                    )
                })
            }
        </>
    )
}

export default RenderSubCategory