import React, { useCallback, useContext, useEffect, useState } from 'react';
import {
  Card,
  Select,
  Heading,
  FormLayout,
  Button,
  Icon,
  Modal,
  TextField,
  Stack,
  Toast,
  Frame,
  Checkbox,
  RadioButton
} from '@shopify/polaris';
import "../loyalty-rewards/loyalty.css";
import ManageTranslations from './ManageTranslations';
import ManageStyle from './ManageStyle';

const Customize = (props) => {
  const { domain, i18n, selectedTemplateId } = props;
  const [active, setActive] = useState(false);
  const [statusActive, setStatusActive] = useState(false);
  const toggleActive = useCallback(() => { setActive((active) => !active); setToasContent("") }, []);
  const [toastContent, setToasContent] = useState();
  const toggleStatusActive = useCallback(() => { setStatusActive((statusActive) => !statusActive); setToasContent("") }, []);
  const [allProducts, setAllProducts] = useState([]);

  const [apiData, setApiData] = useState();
  const [upsellFormData, setUpsellFormData] = useState({ title: "", subtitle: "", variant_images: [], product_images: [], shopify_domain: domain, template_id: selectedTemplateId })
  const [nameError, setNameError] = useState(false);

  // refer_a_friend Campaign
  const [pointsToReferrer, setPointsToReferrer] = useState(0);
  const [pointsToReferred, setPointsToReferred] = useState(0);
  const [selectedReward, setSelectedReward] = useState('coupon');

  const [applyCoupon, setApplyCoupon] = useState(1);
  const [rewardText, setRewardText] = useState('');
  const [description, setDescription] = useState('');
  const [icon, setIcon] = useState('fa-user');
  const [callToActionText, setCallToActionText] = useState('');
  const [defaultSmsMsg, setDefaultSmsMsg] = useState('');
  const [customCss, setCustomCss] = useState({ hero_css: "", ways_to_redeem_css: "", how_it_works: "", ways_to_earn_css: "", loyalty_tiers_css: "", faq: "", footer: "" });
  const [bulkStatus, setBulkStatus] = useState({ hero_status: true, ways_to_redeem_status: true, how_it_works_status: true, ways_to_earn_status: true, loyalty_tiers_status: true, faq_status: true, footer_status: true });
  const [zennportal_settings,setZennportalSetting] = useState({});


  // create_account Campaign
  const [accountCampaignStatus, setAccountCampaignStatus] = useState(false);
  const [accountCampaignDate, setAccountCampaignDate] = useState(null);
  const [campaignName, setCampaignName] = useState('');
  const [descText, setDescText] = useState('10');
  const [completedMsg, setCompletedMsg] = useState('offer-completed');
  const [maxTimesCompletion, setMaxTimesCompletion] = useState('no-max');

  // happy_birthday Campaign
  const [birthdayCampaignStatus, setBirthdayCampaignStatus] = useState(false);
  const [birthdayCampaignDate, setBirthdayCampaignDate] = useState(null);

  const [showBirthdayCampaign, setShowBirthdayCampaign] = useState(false);

  // make_a_purchase Campaign
  const [purchaseCampaignStatus, setPurchaseCampaignStatus] = useState(false);
  const [purchaseCampaignDate, setPurchaseCampaignDate] = useState(null);
  const [customerFixedPoints, setCustomerFixedPoints] = useState(0);
  const [requiredProduct, setRequiredProduct] = useState('');
  const [requireProdReward, setRequireProdReward] = useState('entire-cart');
  const [customerTag, setCustomerTag] = useState('');
  const [customerTagList, setCustomerTagList] = useState('');
  const [orderTag, setOrderTag] = useState('');
  const [orderTagList, setOrderTagList] = useState('');
  const [limit, setLimit] = useState('exactly');
  const [orders, setOrders] = useState(0);
  const [maxTimesPerUser, setMaxTimesPerUser] = useState('no-max');
  const [timeBetween, setTimeBetween] = useState('5-days');
  const [countdownMessage, setCountdownMessage] = useState('24-hours');

  const [showPurchaseCampaign, setShowPurchaseCampaign] = useState(false);



  const toastMarkup = active ? (
    <Frame>
      <Toast content={toastContent || "Data updated successfully."} onDismiss={toggleActive} duration={3000} />
    </Frame>
  ) : statusActive ? (
    <Frame>
      <Toast content={toastContent || "Status updated successfully."} onDismiss={toggleStatusActive} duration={3000} />
    </Frame>
  ) : null;

  return (
    <FormLayout>
      <div className='zennportal_style manage_style_wrapper color-picker'>
        <ManageStyle
          i18n={i18n}
          selectedTemplateId={selectedTemplateId}
          domain={domain}
          toggleActive={toggleActive}
          setToasContent={setToasContent}
          setActive={setActive}
        />
      </div>

      <div className='manage_translations'>
        <ManageTranslations
          i18n={i18n}
          selectedTemplateId={selectedTemplateId}
          domain={domain}
          toggleActive={toggleActive}
        />
      </div>

      {toastMarkup}
    </FormLayout >
  );
}
export default Customize;
