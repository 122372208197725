import {
  Badge,
  Banner,
  Button,
  ButtonGroup,
  Card,
  DataTable,
  Frame,
  Icon,
  List,
  Page,
  ResourceItem,
  ResourceList,
  Stack,
  Toast,
} from '@shopify/polaris';
import React, { useCallback, useEffect, useState } from 'react';
import EditWaysToEarn from './EditWaysToEarn';
// import {
//     StoreMinor
// } from '@shopify/polaris-icons';
import { gql, useQuery } from '@apollo/client';
import LoadingScreen from '../LoadingScreen';
import { values } from 'lodash';
import AppLayout from '../layout/Layout';
import PixelIcon from '../../images/PixelIcon';
import {
  ChevronRightMinor,
  MobileBackArrowMajor,
} from '@shopify/polaris-icons';
import { useParams, useHistory } from 'react-router-dom';

const WaysToEarnPoint = (props) => {
  const { i18n, domain, detailView, setDetailView } = props;
  const { edit_id } = useParams();
  const history = useHistory();
  const [editForm, setEditForm] = useState(false);
  const [waysToEarn, setWaysToEarn] = useState([]);
  const [waysToRedeem, setWaysToRedeem] = useState([]);
  const [rowData, setRowData] = useState([]);
  const [formErrors, setFormErrors] = useState([]);
  const [saveSuccess, setSaveSuccess] = useState(false);
  const hideSaveSuccess = useCallback(() => setSaveSuccess(false), []);
  const [titleOptions, setTitleOptions] = useState([]);
  const [allProducts, setAllProducts] = useState([]);
  const [formData, setFormData] = useState({
    id: '',
    title: titleOptions[0]?.value || 'Place an Order',
    pointsAwarded: '1',
    summary: '',
    socialLink: '',
    pointsDiscount: true,
    discountType: 'Fixed amount',
    discountAmount: '',
    codePrefix: '',
    goalAmount: '1',
    numOfPurchases: '0',
    minSpendPerPuchase: '1',
    codePrefix: '',
  });
  const [pointProgramStatus, setPointProgramStatus] = useState(false);
  const GET_DATA = gql`
    query {
      fetchWaysToEarnPoint {
        waysToEarn {
          title
          id
          pointsAwarded
          status
          summary
          products {
            productId
            image
            title
          }
          allProducts
          socialLink
          iconUrl
          pointsDiscount
          discountType
          discountAmount
          codePrefix
          goalAmount
          numOfPurchases
          minSpendPerPuchase
        }
        allTitles
        pointProgramStatus
      }
    }
  `;

  const { data, loading, error, refetch } = useQuery(GET_DATA, {
    fetchPolicy: 'no-cache',
  });

  useEffect(() => {
    refetch();
  }, []);

  useEffect(() => {
    if (data?.fetchWaysToEarnPoint?.waysToEarn?.length > 0) {
      setWaysToEarn(data?.fetchWaysToEarnPoint?.waysToEarn);
    }
    if (data?.fetchWaysToEarnPoint?.allTitles) {
      let allTitles = JSON.parse(data?.fetchWaysToEarnPoint?.allTitles);
      let allTitleArr = [];
      Object.keys(allTitles)?.map((k, v) => {
        allTitleArr.push({
          label: i18n.t(`ways_to_earn.ways.${k}`),
          value: `${k}`,
        });
      });
      console.log(
        'allTitle',
        allTitleArr,
        data?.fetchWaysToEarnPoint?.allTitles
      );
      setTitleOptions(allTitleArr);
    }
    if (data?.fetchWaysToEarnPoint?.pointProgramStatus) {
      setPointProgramStatus(data?.fetchWaysToEarnPoint?.pointProgramStatus);
    }
  }, [data]);

  useEffect(() => {
    if (waysToEarn?.length > 0) {
      setRowData(waysToEarn);
    }
  }, [waysToEarn]);

  useEffect(() => {
    if (editForm) {
      setDetailView(false);
    }
  }, [waysToEarn]);

  console.log('waysToRedeem', waysToRedeem);

  useEffect(() => {
    fetch('/togglePointsProgram', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        active: pointProgramStatus,
        shopify_domain: domain,
      }),
    });
  }, [pointProgramStatus]);

  useEffect(() => {
    console.log('edit_id_edit_id_edit_id', edit_id);
    if (edit_id === 'createNew') {
      setFormData({
        ...formData,
        id: '',
        title: titleOptions[0]?.value || 'Place an Order',
        pointsAwarded: '1',
        socialLink: '',
        pointsDiscount: true,
        discountType: 'Fixed amount',
        discountAmount: '0',
        codePrefix: '',
        goalAmount: '1',
        numOfPurchases: '0',
        minSpendPerPuchase: '1',
      });
      setEditForm(true);
      setDetailView(false);
    }
    else if (edit_id && rowData.length > 0) {
      const row = rowData?.find((e) => e.id === edit_id);
      setFormData({
        id: row?.id,
        title: row?.title,
        pointsAwarded: row?.pointsAwarded,
        summary: row?.summary,
        status: row?.status,
        products: row?.products,
        allProducts: row?.allProducts,
        socialLink: row?.socialLink,
        iconUrl: row?.iconUrl,
        pointsDiscount: row?.pointsDiscount,
        discountType: row?.discountType,
        discountAmount: row?.discountAmount,
        codePrefix: row?.codePrefix,
        goalAmount: row?.goalAmount,
        numOfPurchases: row?.numOfPurchases,
        minSpendPerPuchase: row?.minSpendPerPuchase,
      });
      setAllProducts(row?.products);
      setEditForm(true);
      setDetailView(false);
    }
  }, [edit_id, rowData]);

  const formatRows = (rows) => {
    return rows?.map((row) => {
      return row?.id !== null
        ? [
          <ul style={{ listStyle: 'none' }}>
            <li>{row.title}</li>
          </ul>,
          <ul style={{ listStyle: 'none' }}>
            <li>
              {row?.status ? (
                <Badge status="success">{i18n.t('active')}</Badge>
              ) : (
                <Badge>{i18n.t('disabled')}</Badge>
              )}
            </li>
          </ul>,
          <ul style={{ listStyle: 'none' }}>
            <li>{row?.pointsAwarded}</li>
          </ul>,
          <ul style={{ listStyle: 'none' }}>
            <li>{row?.summary}</li>
          </ul>,
          <div className="primary_button_wrapper">
            <Button
              primary
              onClick={() => {
                history.push(`/pointsProgram/waysToEarn/${row.id}`);
                // setFormData({
                //   id: row?.id,
                //   title: row?.title,
                //   pointsAwarded: row?.pointsAwarded,
                //   summary: row?.summary,
                //   status: row?.status,
                //   products: row?.products,
                //   allProducts: row?.allProducts,
                //   socialLink: row?.socialLink,
                //   iconUrl: row?.iconUrl,
                //   pointsDiscount: row?.pointsDiscount,
                //   discountType: row?.discountType,
                //   discountAmount: row?.discountAmount,
                //   codePrefix: row?.codePrefix,
                //   goalAmount: row?.goalAmount,
                //   numOfPurchases: row?.numOfPurchases,
                //   minSpendPerPuchase: row?.minSpendPerPuchase,
                // });
                // setAllProducts(row?.products);
                // setEditForm(true);
                // setDetailView(false);
              }}
            >
              {i18n.t('edit')}
            </Button>
          </div>,
        ]
        : [];
    });
  };

  const formatRedeemData = (rows) => {
    return rows?.map((row) => {
      return [
        row.title,
        row?.status ? (
          <Badge status="success">{i18n.t('active')}</Badge>
        ) : (
          <Badge>{i18n.t('active')}</Badge>
        ),
        row?.pointsCost,
        <ul style={{ listStyle: 'none' }}>
          <li>{row?.summary}</li>
        </ul>,
        <div className="primary_button_wrapper">
          <Button primary>{i18n.t('edit')}</Button>
        </div>,
      ];
    });
  };

  console.log('editFormeditFormeditForm', editForm);

  return (
    <>
      {saveSuccess && (
        <Toast
          content={i18n.t('points_programs.ways_to_earn_points_saved')}
          onDismiss={hideSaveSuccess}
        />
      )}
      {formErrors.length > 0 && (
        <>
          <Banner
            title={i18n.t('points_programs.ways_to_earn_points_not_saved')}
            status="critical"
          >
            <List type="bullet">
              {formErrors.map((message, index) => (
                <List.Item key={index}>{message.message}</List.Item>
              ))}
            </List>
          </Banner>
          <br />
        </>
      )}
      {editForm ? (
        <EditWaysToEarn
          setFormData={setFormData}
          formData={formData}
          refetch={refetch}
          setEditForm={setEditForm}
          formErrors={formErrors}
          setFormErrors={setFormErrors}
          saveSuccess={saveSuccess}
          setSaveSuccess={setSaveSuccess}
          titleOptions={titleOptions}
          i18n={i18n}
          allProducts={allProducts}
          setAllProducts={setAllProducts}
          setDetailView={setDetailView}
        />
      ) : loading ? (
        <LoadingScreen />
      ) : (
        <div className="without_header_card">
          <Card
            actions={{
              content: (
                <div className="card_header_button_group">
                  <div className="card_header_buttons secondary_button_group">
                    <Button
                      onClick={() => {
                        history.push(`/pointsProgram/waysToEarn/createNew`);
                      }}
                    >
                      {i18n.t('points_programs.add_ways_to_earn')}
                    </Button>
                  </div>
                </div>
              ),
            }}
          >
            <Card.Section>
              <div className={'table customer-subscription-tbl' + ' '}>
                <DataTable
                  columnContentTypes={[
                    'text',
                    'text',
                    'text',
                    'text',
                    'text',
                    'text',
                  ]}
                  headings={[]}
                  rows={formatRows(rowData)}
                />
              </div>
            </Card.Section>
          </Card>
        </div>
      )}
    </>
  );
};

export default WaysToEarnPoint;
