import React, { useEffect, useState } from 'react';

const CustomImageDropzone = (props) => {
  const {
    formData,
    setFormData,
    fieldName,
    handleStepValueChange,
    stepIndex,
    step,
  } = props;
  const getUploadImageUrl = () => {
    const file = step?.[stepIndex]?.[fieldName] || formData?.[fieldName];
    try {
      return window.URL.createObjectURL(file);
    } catch (e) {
      return file;
    }
  };
  const [imageUrl, setImageUrl] = useState(getUploadImageUrl());

  useEffect(() => {
    setImageUrl(getUploadImageUrl());
  }, [formData?.[fieldName], step?.[stepIndex]?.[fieldName]]);

  const handleChange = (value) => {
    if (stepIndex) {
      handleStepValueChange(value, stepIndex, fieldName);
    } else {
      setFormData({
        ...formData,
        value,
      });
    }
  };

  return (
    <>
      <div className="chargezen_image_dropzone">
        {formData?.[fieldName] ? (
          <div className="image_preview_wrapper">
            <div className="preview_image">
              <img src={imageUrl} className="chargezen_dropzone_preview" />
            </div>
            <div className="change_remove_button_wrapper">
              <label className="chage_remove_button">
                Change
                <input
                  className="hidden"
                  type="file"
                  onChange={(e) => {
                    handleChange(e?.target?.files?.[0]);
                  }}
                />
              </label>
              <button
                className="chage_remove_button"
                onClick={() => {
                  handleChange('');
                }}
              >
                Remove
              </button>
            </div>
          </div>
        ) : (
          <div className="chargezen_upload_image">
            <label className="select_upload_file_label">
              Select
              <input
                type="file"
                className="hidden"
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    [fieldName]: e?.target?.files?.[0],
                  });
                }}
              />
            </label>
          </div>
        )}
      </div>
    </>
  );
};

export default CustomImageDropzone;
