import React, { useCallback, useEffect, useState } from 'react';
import {
  LocationsMinor,
  MobileBackArrowMajor,
  ChevronDownMinor,
  CreditCardMajor,
  ShipmentMajor,
  MobilePlusMajor,
  ChevronRightMinor,
  MobileHorizontalDotsMajor,
  EditMajor,
} from '@shopify/polaris-icons';
import PaypalLogo from '../../../images/paypal.svg';
import Papyal from '../../../images/paypal.svg';
import { Badge, Card, Frame, Icon, Page, Toast } from '@shopify/polaris';
import dayjs from 'dayjs';
import HeaderButtons from '../../HeaderButtons/HeaderButtons';
import { useHistory, useParams } from 'react-router-dom';
import ToggleButton from 'react-toggle-button';
import './style.css';
import EditAffiliateModal from './EditAffiliateModal';
const AffiliateDetail = (props) => {
  const { uid, setuid, domain } = props;
  const { id } = useParams();
  const [headerButton, setHeaderButton] = useState('commissionprofiles');
  const [apiData, setApiData] = useState();
  const [promotionalToggle, setPromotionalToggler] = useState({});
  const history = useHistory();
  const headerButtons = [
    {
      val: 'commissionprofiles',
      name: 'Commission profiles',
    },
    {
      val: 'analytics',
      name: 'Analytics',
    },
  ];

  const [showAccordion, setShowAccordion] = useState({ orders: false });
  const [saveSuccess, setSaveSuccess] = useState(false);
  const [toastContent, setToastContent] = useState('');
  const hideSaveSuccess = useCallback(() => setSaveSuccess(false), []);

  const [waysToRedeem, setWaysToRedeem] = useState([]);

  const [rewardActions, setRewardActions] = useState([]);

  const [usedReferrals, setUsedReferrals] = useState([]);
  const [editAffiliate, setEditAffiliate] = useState();
  const [toggleCommission, setToggleCommission] = useState(false);

  const fetchAffiliate = () => {
    fetch(`/getAffiliateDetail/${id}?shopify_domain=${domain}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setApiData(data);
      });
  };
  console.log('apidata', apiData);

  useEffect(() => {
    if (id) {
      fetchAffiliate();
    }
  }, [id]);

  const resendVerificationEmail = () => {
    fetch(`/resendAffiliateEmail`, {
      method: 'POST',
      body: JSON.stringify({ id: id, shopify_domain: domain }),
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setToastContent('Verification email sent!');
      });
  };

  const approveAffiliate = () => {
    fetch(`/approve_affiliate/${id}`, {
      method: 'POST',
      body: JSON.stringify({ shopify_domain: domain }),
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (!data?.error) {
          setToastContent('Affiliate Approved');
        }
      });
  };

  return (
    <div className="chargezen_customer_detail_wrapper affiliate_detail_wrapper">
      <Frame>
        {toastContent && (
          <Toast content={toastContent} onDismiss={hideSaveSuccess} />
        )}
        <Page
          title={
            <div className="back_button_wrapper">
              <div
                className="back-button pointer"
                style={{ marginTop: '15px' }}
                onClick={() => {
                  history.push(`/affiliates/affiliates`);
                }}
              >
                <Icon source={MobileBackArrowMajor} color="base" />
              </div>
              <div class="text_wrapper">
                {apiData?.user_affiliate_info?.first_name ||
                apiData?.user_affiliate_info?.last_name
                  ? `${apiData?.user_affiliate_info?.first_name || ''} ${
                      apiData?.user_affiliate_info?.last_name || ''
                    }`
                  : apiData?.user_affiliate_info?.email}
              </div>
            </div>
          }
        >
          {editAffiliate && (
            <EditAffiliateModal
              {...{
                editAffiliate,
                setEditAffiliate,
                apiData,
                domain,
                setToastContent,
                fetchAffiliate,
              }}
            />
          )}
          <div className="mainContent">
            <div className="block_section">
              <div className="detail-customer-overview-main affiliate-overview-container">
                <div className="without_header_card">
                  <Card
                    actions={{
                      content: (
                        <div className="card_header_button_group">
                          <div className="card_header_buttons primary_button_group">
                            <HeaderButtons
                              headerButtons={headerButtons}
                              setHeaderButton={setHeaderButton}
                              headerButton={headerButton}
                            />
                          </div>
                        </div>
                      ),
                    }}
                  >
                    <div className="setup_guide_content_wrapper">
                      <div className="contentsTab_container">
                        <div className="contents_tab_wrapper">
                          <span>Ketuousa.com</span>
                          <button
                            className={`toggle_content_tab_button icon_wrapper opened`}
                          ></button>
                        </div>
                        <div className={`contents_wrapper_container`}>
                          {apiData?.commission_titles?.map((tile) => {
                            return (
                              <div className="affiliate_content_section">
                                <div className="left_section">
                                  <ul>
                                    <li>
                                      <strong>Details :</strong>{' '}
                                      <span>{tile.title}</span>
                                    </li>

                                    <li>
                                      <strong>Affiliate url :</strong>{' '}
                                      <span>{tile.affiliate_url}</span>
                                    </li>
                                    <li>
                                      <strong>Destination url :</strong>{' '}
                                      <span>{tile.redirection_path}</span>
                                    </li>
                                  </ul>
                                </div>
                                <div className="right_section">
                                  <div className="toggleButtonWrapper">
                                    <ToggleButton
                                      inactiveLabel={''}
                                      activeLabel={''}
                                      value={toggleCommission}
                                      onToggle={(e) => {
                                        setToggleCommission(e);
                                      }}
                                    />
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  </Card>
                </div>
                <div className="accordian">
                  <div className="accordion_container">
                    <span
                      class={`cardBox ${
                        showAccordion?.affiliate_profile ? 'active' : ''
                      }`}
                      onClick={() => {
                        setShowAccordion({
                          ...showAccordion,
                          affiliate_profile: !showAccordion?.affiliate_profile,
                        });
                      }}
                    >
                      <ChevronRightMinor /> <h4>Affiliate Profile</h4>
                    </span>
                    <div
                      className={`accordion_wrapper ${
                        showAccordion?.affiliate_profile ? '' : 'hidden'
                      }`}
                    >
                      <div className="setup_guide_content_wrapper affiliate_profile_wrapper">
                        <div className="contentsTab_container">
                          <div className="contents_tab_wrapper">
                            <span>Company Profile</span>
                            <button
                              className={`toggle_content_tab_button icon_wrapper opened`}
                              onClick={() => setEditAffiliate(true)}
                            >
                              <Icon source={EditMajor} tone="base" />
                            </button>
                          </div>
                          <div className={`contents_wrapper_container`}>
                            <div className="profile_row">
                              <h6>Company name</h6>
                              <p>
                                {apiData?.user_affiliate_info?.company_name}
                              </p>
                            </div>
                            <div className="profile_row">
                              <h6>Tax residence</h6>
                              <p>
                                {apiData?.user_affiliate_info?.tax_residency}
                              </p>
                            </div>
                            <div className="profile_row">
                              <h6>Email</h6>
                              <p>{apiData?.user_affiliate_info?.email}</p>
                            </div>
                          </div>
                        </div>
                        <div className="contentsTab_container">
                          <div className="contents_tab_wrapper">
                            <span>Primary Residence</span>
                            <button
                              className={`toggle_content_tab_button icon_wrapper opened`}
                              onClick={() => setEditAffiliate(true)}
                            >
                              <Icon source={EditMajor} tone="base" />
                            </button>
                          </div>
                          <div className={`contents_wrapper_container`}>
                            <div className="profile_row">
                              <h6>Address</h6>
                              <p>
                                {apiData?.user_affiliate_info?.primary_country}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="accordion_container">
                    <span
                      class={`cardBox ${
                        showAccordion?.promotional_type ? 'active' : ''
                      }`}
                      onClick={() => {
                        setShowAccordion({
                          ...showAccordion,
                          promotional_type: !showAccordion?.promotional_type,
                        });
                      }}
                    >
                      <ChevronRightMinor /> <h4>Promotional type</h4>
                    </span>
                    <div
                      className={`accordion_wrapper ${
                        showAccordion?.promotional_type ? '' : 'hidden'
                      }`}
                    >
                      <div className="setup_guide_content_wrapper affiliate_profile_wrapper">
                        <div
                          className={`chargezen_shopify_style_settings_section`}
                        >
                          <div className="contentsTab_container">
                            <div className="contents_tab_wrapper">
                              <span>Promotional Type</span>
                            </div>
                            <div className={`contents_wrapper_container`}>
                              <div className="profile_row">
                                <h6>Primary Region</h6>
                                <p>
                                  {apiData?.user_affiliate_info?.primary_region}
                                </p>
                              </div>
                              <div className="profile_row">
                                <h6>Promotional Type</h6>
                                <div className="promotional_types_card">
                                  {apiData?.promotional_types?.map((pt, i) => {
                                    return (
                                      <div className="promotional_types_container">
                                        <div
                                          className={`promotion_type_toggle ${
                                            promotionalToggle[i] ? 'opened' : ''
                                          }`}
                                          onClick={() => {
                                            setPromotionalToggler({
                                              ...promotionalToggle,
                                              [i]: !promotionalToggle[i],
                                            });
                                          }}
                                        >
                                          <span className="icon_wrapper">
                                            <ChevronRightMinor />
                                          </span>
                                          <span>{pt?.title}</span>
                                        </div>
                                        {pt?.questions?.map((question) => {
                                          return (
                                            <div
                                              className={`promotional_types_wrapper ${
                                                promotionalToggle[i]
                                                  ? ''
                                                  : 'hidden'
                                              }`}
                                            >
                                              <div className="promotional_types_title">
                                                <div className="promitional_type_question">
                                                  <input
                                                    type="checkbox"
                                                    checked={question.value}
                                                  />
                                                  <span className="question_title">
                                                    {question?.title}
                                                  </span>
                                                </div>

                                                <div className="primary_promotional_type">
                                                  <input
                                                    type="radio"
                                                    checked={
                                                      question?.primary_promotional_type
                                                    }
                                                  />
                                                  <span>
                                                    Primary promotional type
                                                  </span>
                                                </div>
                                              </div>
                                              <div className="promotional_types_helptext">
                                                {question?.helptext}
                                              </div>
                                            </div>
                                          );
                                        })}
                                      </div>
                                    );
                                  })}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <span class="cardBox">
                    <ChevronRightMinor /> <h4>Promotional space</h4>
                  </span>
                </div>
              </div>

              <div className="button-accordian-payment-main ">
                <div className="customer-overview cardBox commanBoxS">
                  <div className="heading_">
                    <h2>Affiliate Overview</h2>
                    <MobileHorizontalDotsMajor />
                  </div>
                  <p>{`${apiData?.user_affiliate_info?.first_name} ${apiData?.user_affiliate_info?.last_name}`}</p>
                  <p>{apiData?.user_affiliate_info?.email}</p>
                  <span
                    onClick={() => {
                      approveAffiliate();
                    }}
                  >
                    Approve Affiliate
                  </span>
                  <span
                    onClick={() => {
                      resendVerificationEmail();
                    }}
                  >
                    Send verification email
                  </span>
                  <span
                    onClick={() => {
                      setEditAffiliate(true);
                    }}
                  >
                    Edit Affiliate
                  </span>
                </div>
                <div className="payment-methods cardBox commanBoxS">
                  <div className="heading_">
                    <h2>Payout methods</h2>
                  </div>
                  <div className="payMShow">
                    <div className="no_payout_methods">
                      No payout method found!
                    </div>
                  </div>
                  <span>Manage payment methods </span>
                </div>
              </div>
            </div>
          </div>
        </Page>
      </Frame>
    </div>
  );
};

export default AffiliateDetail;
