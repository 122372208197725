import React from 'react';
import "./Integration.css";
import { useParams, useHistory } from 'react-router-dom';
const IntegrationCard = (props) => {
  const { childItem,
    setEditId,
    setEditTitle,
    setEditKeys,
    setCredentitals,
    setDetail,
    integType } = props;

  const { edit_id, selectedTab } = useParams();
  const history = useHistory();

  const getImgSrc = (name) => {
    try {
      return require(`images/${name
        ?.split(' ')
        .join('')
        .toLowerCase()}`)
    } catch (e) {
      return ''
    }
  }
  const credentials = childItem?.credentials || {}
  const connected = !!Object.keys(credentials)?.find((key) => !!credentials[key] && key != "__typename")

  return (
    <div className='integration_card'>
      <div className='integration_card_wrapper' >
        <div className='integ_img'>
          <img src={getImgSrc(childItem.name)} />
        </div>
        <div className='integ_body_content'>
          <h6>{childItem.name}</h6>
          <span className='integ_type'>{integType}</span>
          {
            childItem?.status == "coming_soon" ?
              <button className='integ_connected coming_soon_button'>Coming soon</button>
              :
              <button className='integ_connected' onClick={() => {
                history.push(`/toolbox/integerationsMain/${edit_id}/${childItem?.id}`)

              }}
              >
                {connected ? "Manage" : "Connect"}
              </button>

          }

        </div>
      </div>
    </div>
  )
}

export default IntegrationCard;
