import React from "react";
import { Button, ButtonGroup, Card, ProgressBar, Page, Layout, Icon } from "@shopify/polaris";
import "./style.css";
const SubHeader = ({ title, value, desc }) => {
    return (
        <Card title={<div className="subheader_heading">
            <div className="left_heading">{title}</div>
        </div>}>
            <Card.Section>
                <div className="result_main">
                    {value || "--"}
                </div>
                <div className="footer_main">
                    {desc || "--"}
                </div>
            </Card.Section>
        </Card>
    )
}
export default SubHeader;