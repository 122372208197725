import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Badge, Select, TextField } from '@shopify/polaris';
import { ChevronDownMinor, MobilePlusMajor } from '@shopify/polaris-icons';
const SpendPoints = (props) => {
  const {
    spendPoints,
    setSpendPoints,
    customer,
    domain,
    fetchCustomer,
    setToastContent,
    setSaveSuccess,
    waysToRedeem,
    setWaysToRedeem,
  } = props;

  const [rewardOptions, setRewardOptions] = useState([]);
  const [selectedReward, setSelectedReward] = useState();
  const [discountCode, setDiscountCode] = useState();

  let currentLoyaltyPoints = +customer?.loyalty_points || 0;

  const redeemReward = () => {
    let reward = waysToRedeem?.find((w) => +w.id == +selectedReward);

    setToastContent('Adjusting points balance …');
    if (selectedReward) {
      let formData = {};
      formData.shopify_domain = domain;
      formData.uid = customer.uid;
      formData.customer = customer.uid;
      formData.ways_to_redeem_id = +selectedReward;
      console.log('formdata', formData);
      let url = `/customer/spend_points?shopify_domain=${domain}`;
      if (reward?.title == 'Free product') {
        url = `/customer/claim_free_product?shopify_domain=${domain}`;
      }
      fetch(url, {
        method: 'POST',
        body: JSON.stringify(formData),
        headers: {
          'Content-Type': 'application/json',
        },
      })
        .then((response) => response.json())
        .then((data) => {
          console.log('dataaa,', data);
          fetchCustomer();
          if (data?.discount_code) {
            setDiscountCode(data?.discount_code);
          } else {
            setSpendPoints(false);
          }
          setSaveSuccess(true);
        });
    }
  };
  const handleSelectChange = (value) => {
    console.log('valueeee', value);
    setSelectedReward(value);
  };

  useEffect(() => {
    let reward_options = [];
    waysToRedeem?.map((redeem) => {
      reward_options.push({ label: redeem?.title, value: `${redeem?.id}` });
    });
    setRewardOptions([...reward_options]);
  }, [waysToRedeem]);
  return (
    <div className="create_billing modal-popup-cstm">
      <div className="modal-dialouge">
        <div className="Modal-header">
          <h2>Spend points</h2>
          <button
            class="close-btn"
            onClick={() => {
              setSpendPoints(false);
            }}
          >
            <svg
              aria-hidden="true"
              class="Polaris-Icon__Svg"
              focusable="false"
              viewBox="0 0 20 20"
            >
              <path d="m11.414 10 6.293-6.293a1 1 0 1 0-1.414-1.414l-6.293 6.293-6.293-6.293a1 1 0 0 0-1.414 1.414l6.293 6.293-6.293 6.293a1 1 0 1 0 1.414 1.414l6.293-6.293 6.293 6.293a.998.998 0 0 0 1.707-.707.999.999 0 0 0-.293-.707l-6.293-6.293z"></path>
            </svg>
          </button>
        </div>
        <div className="modal-content">
          <h4>
            {`${customer?.first_name} ${customer.last_name}`}{' '}
            <p>{currentLoyaltyPoints} points</p>
          </h4>
          {discountCode ? (
            <div className="discount_code_copy_wrapper">
              <TextField type="text" disabled value={discountCode} />
              <button
                className="copy_code_button"
                onClick={() => navigator.clipboard.writeText(discountCode)}
              >
                Copy code
              </button>
            </div>
          ) : (
            <div className="points_adjustment_form">
              <Select
                label="Reward"
                options={rewardOptions}
                onChange={(e) => handleSelectChange(e)}
                value={selectedReward}
                placeholder="Select a reward"
              />
            </div>
          )}
        </div>
        <div className="modal-footer">
          {discountCode ? (
            <button
              className="btn-green"
              onClick={() => {
                setSpendPoints(false);
              }}
            >
              Done
            </button>
          ) : (
            <>
              <button className="btn-green" onClick={() => redeemReward()}>
                Redeem reward
              </button>
              <button
                className="btn-red"
                onClick={() => {
                  setSpendPoints(false);
                }}
              >
                Cancel
              </button>
            </>
          )}
        </div>
      </div>
      <div className="modal-cstm-backdrop"></div>
    </div>
  );
};

export default SpendPoints;
