import { gql, useMutation } from '@apollo/client';
import {
  Card,
  Toast,
  ChoiceList,
  Page,
  PageActions,
  TextField,
  Layout,
  Banner,
  List,
  Frame,
  Select,
  Icon,
  Checkbox,
  Form,
  DropZone,
  Thumbnail,
  Stack,
  FormLayout,
  Button,
  TextContainer,
} from '@shopify/polaris';
import React, { useCallback, useEffect, useState } from 'react';
import PixelIcon from '../../../images/PixelIcon';
import {
  MobileBackArrowMajor,
  ChevronRightMinor,
} from '@shopify/polaris-icons';

const PointsProgram = (props) => {
  const { domain, i18n, detailView, setDetailView } = props;
  const [formData, setFormData] = useState({ title: '', desc: '' });
  const [fieldError, setFieldError] = useState({ title: '', desc: '' });
  const validImageTypes = ['image/gif', 'image/jpeg', 'image/png'];
  const [addImage, setAddImage] = useState(false);
  const [toastContent, setToastContent] = useState('');
  const [toastActive, setToastActive] = useState(false);
  const toggleToastActive = useCallback(
    () => setToastActive((toastActive) => !toastActive),
    []
  );

  const [files, setFiles] = useState([]);

  const handleDropZoneDrop = useCallback(
    (_dropFiles, acceptedFiles, _rejectedFiles) => {
      console.log('LegacyStack', acceptedFiles, _dropFiles, _rejectedFiles);
      setFiles((files) => [...files, ...acceptedFiles]);
    },
    []
  );
  console.log('files', files, validImageTypes);

  const fileUpload = !files.length && <DropZone.FileUpload />;
  const uploadedFiles = files.length > 0 && (
    <div style={{ padding: '0' }}>
      <Stack vertical spacing="5">
        {files.map((file, index) => (
          <Stack alignment="center" key={index}>
            <Thumbnail
              size="small"
              alt={file.name}
              source={
                validImageTypes.includes(file.type)
                  ? window.URL.createObjectURL(file)
                  : NoteMinor
              }
            />
            <div>
              {file.name}{' '}
              <TextContainer>
                <p>{file.size} bytes</p>
              </TextContainer>
            </div>
          </Stack>
        ))}
      </Stack>
    </div>
  );

  const handleSubmit = () => {
    const formData = new FormData();
    formData.append('shopify_domain', domain);

    formData.append('files_count', files.length);
    files?.map((file, i) => {
      formData.append(`image${i}`, file);
    });

    fetch('/create_points_program', {
      method: 'POST',
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        setToastContent('Saved Successfully');
        if (!data?.error) {
          setTimeout(() => {
            window.location.reload();
          }, 3000);
        }
        setToastActive(true);
      });
  };

  return (
    <>
      {toastActive && (
        <Toast content={toastContent} onDismiss={toggleToastActive} />
      )}
      <>
        <Layout>
          <Layout.Section>
            <div className="without_header_card">
              <Card>
                <Card.Section>
                  <Form>
                    <FormLayout>
                      {/* <FormLayout.Group>
                                        <TextField
                                            label={i18n.t("title")}
                                            type="text"
                                            value={`${formData?.title}`}
                                            onChange={(e) => {
                                                setFormData({ ...formData, title: e })
                                            }}
                                            error={fieldError?.title}
                                            autoComplete="off"
                                        />
                                    </FormLayout.Group>

                                    <FormLayout.Group>
                                        <TextField
                                            label={"Description"}
                                            type="text"
                                            multiline={true}
                                            value={`${formData?.desc}`}
                                            onChange={(e) => {
                                                setFormData({ ...formData, desc: e })
                                            }}
                                            error={fieldError?.desc}
                                            autoComplete="off"
                                        />
                                    </FormLayout.Group> */}
                      <DropZone
                        onDrop={handleDropZoneDrop}
                        label={i18n.t('points_programs.hero_image')}
                        actionHint={i18n.t(
                          'points_programs.upload_images_hero'
                        )}
                      >
                        {uploadedFiles}
                        {fileUpload}
                      </DropZone>
                    </FormLayout>
                    <FormLayout>
                      <FormLayout.Group>
                        <Button primary onClick={() => handleSubmit()}>
                          {i18n.t('save')}
                        </Button>
                      </FormLayout.Group>
                    </FormLayout>
                  </Form>
                </Card.Section>
              </Card>
            </div>
          </Layout.Section>
        </Layout>
      </>
    </>
  );
};

export default PointsProgram;
