import { gql, useLazyQuery } from '@apollo/client';
import { Button, Checkbox, TextField } from '@shopify/polaris';
import React, { useCallback, useEffect, useState } from 'react';

const SellingPlanModal = (props) => {
  const {
    setShowPlanModal,
    query,
    setQuery,
    setFieldValue,
    values,
    formData,
    setFormData,
    fieldName,
    singleLine
  } = props;
  const [allPlans, setAllPlans] = useState([]);
  const [selectedPlans, setSelectedPlans] = useState([]);


  const closeModal = () => {
    setShowPlanModal();
  };

  // Search plan to add
  const GET_SELLING_PLAN = gql`
    query($name: String!) {
      fetchSellingPlanByName(name: $name) {
        id
        name
        shopifyId
      }
    }
  `;

  const [
    GetPlans,
    { loading: productloading, data: productData, errors: productErrors },
  ] = useLazyQuery(GET_SELLING_PLAN, {
    fetchPolicy: 'no-cache',
  });

  console.log("values[fieldName]values[fieldName]", values[fieldName], "fieldNamefieldName", fieldName)
  useEffect(() => {
    if (values[fieldName].length > 0) {
      setSelectedPlans([...values[fieldName]]);
    }
    GetPlans({ variables: { name: `` } });
  }, []);

  const handleChange = (e) => {
    setQuery(e);
    GetPlans({ variables: { name: e } });
  };

  useEffect(() => {
    var result = [];
    if (productData?.fetchSellingPlanByName?.length > 0) {
      productData.fetchSellingPlanByName.map((plan) => {
        result.push({
          sellingPlanName: plan.name,
          sellingPlanId: plan.shopifyId,
          selected: false
        });
      });
    }
    setAllPlans([...result]);
  }, [productData]);

  const selectAllPlans = (e, prod) => {
    let index = selectedPlans.findIndex((p) => p.sellingPlanId === prod.sellingPlanId);
    const currentPlans = selectedPlans;
    if (index > -1) {
      currentPlans.splice(index, 1);
    } else {
      prod.selected = true
      currentPlans.push(prod);
    }
    console.log("prodprod", prod, "currentPlanscurrentPlans", currentPlans)
    if (singleLine) {
      let plans = currentPlans?.filter((p) => p.sellingPlanId)
      let plan = plans[plans.length - 1]
      plan.selected = true;
      setSelectedPlans([plan]);
    } else {
      setSelectedPlans([...currentPlans]);
    }
  };
  console.log("sekectedPlans", selectedPlans)

  const handleSubmit = () => {
    if (formData) {
      setFormData({ ...formData, [fieldName]: [...selectedPlans] })
    } else {
      console.log("fieldvalue fieldName", fieldName)
      setFieldValue(fieldName, [...selectedPlans]);
    }
    closeModal();
  };

  return (
    <div className="chargezen_search_model product_variant_picker">
      <div className="modal-content-wrapper">
        <div className="chargezen_search_model_header_container">
          <h2>Select products</h2>
        </div>
        <div className="chargezen_search_model_body">
          <TextField
            value={query}
            label=""
            placeholder="Search  for a plan"
            onChange={(e) => {
              handleChange(e);
            }}
          />
          <div class="segments_list_wrapper_container">
            <ul class="segments_lists">
              {allPlans?.map((pv) => {
                return (
                  <li>
                    <Checkbox
                      label={
                        <div className="productWrapper">
                          {/* <span className="imgwrapper">
                            <img src={pv.image} />
                          </span> */}
                          <span>{pv.sellingPlanName}</span>
                        </div>
                      }
                      checked={selectedPlans.find((s) => s.sellingPlanId == pv.sellingPlanId)?.selected}
                      onChange={(e) => {
                        selectAllPlans(e, pv);
                      }}
                    />
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
        <div className="chargezen_search_model_footer justify-between">
          <div className="chargezen_addToCart">
            {selectedPlans.length === 1
              ? '1 plan selected'
              : selectedPlans.length > 1
                ? `${selectedPlans.length} Plans selected`
                : ''}
          </div>
          <div className="action_buttons">
            <Button onClick={() => closeModal()}>Cancel</Button>
            <Button
              onClick={() => {
                handleSubmit();
              }}
            >
              Select
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SellingPlanModal;
