import { Button, ButtonGroup, Checkbox, FormLayout, Icon } from '@shopify/polaris'
import { AddMajor, ArrowDownMinor, ArrowUpMinor, DeleteMajor } from '@shopify/polaris-icons'
import React, { useEffect, useState } from 'react'
import EditCancellation from './EditCancellation';

const CancellationUpsells = (props) => {
    const {
        i18n,
        selectedTemplateId,
        domain,
        toggleActive,
        edit,
        showUpsellEdit,
        cancellationUpsells,
        setCancellationUpsells,
        setToastContent,
        fetch_cancellation_flow
    } = props;

    const initCancellationReason = {
        title1: "",
        title2: "",
        image: "",
        cta_button_text: "",
        upsell_terms: "",
        upsell_type: "free_product"
    }

    const [formData, setFormData] = useState(initCancellationReason);

    const changeIndex = (indexToChange, position) => {
        let cancellation_upsells = [...cancellationUpsells]
        let swapIndex = indexToChange
        if (position == "up") {
            swapIndex -= 1;
        } else {
            swapIndex += 1;
        }
        cancellation_upsells[swapIndex] = cancellation_upsells[indexToChange]
        cancellation_upsells[indexToChange] = cancellationUpsells[swapIndex]
        setCancellationUpsells([...cancellation_upsells])
        reorderUpsells(cancellation_upsells)
    }

    const reorderUpsells = (cancellation_upsells) => {
        let reordered = cancellation_upsells?.map((c, i) => {
            return ({ id: c.id, position: i + 1 })
        })

        fetch('/customer_portal/reorder_cancellation_upsells', {
            method: 'POST',
            body: JSON.stringify({ reordered: reordered, shopify_domain: domain }),
            headers: {
                'Content-Type': 'application/json',
            },
        }).then(r => r.json())
        .then((data)=>{
            if(data?.success){
                
            }
        })
    }

    const deleteCancellationUpsell = (id) => {
        fetch('/customer_portal/delete_cancellation_upsell/'+id+"?shopify_domain="+domain, {
            method: 'DELETE',
        }).then(r => r.json())
        .then((data)=>{
            if(data?.success){
                setToastContent("Deleted Successfully");
                fetch_cancellation_flow();
            }
        })
    }

    const addCancellationUpsell = () => {
        showUpsellEdit(initCancellationReason)
    }

    return (
        <>
            <div className="show_cancellation_instructions_container cancellation_flow_row_wrapper">
                <h6 className="description">
                    Create customized cancellation options to reduce subscription cancellations by incentivizing customers to continue their subscription.
                </h6>
                <FormLayout.Group>
                    <div className='cancellation_reason_header'>
                        <div className="enable_disable_button_wrapper">
                            <span className="text">
                                Cancellation upsell flow is currently {formData?.cancellation_upsell_options ? "enabled" : "disabled"}
                            </span>
                            <span className="button_wrapper">
                                <button
                                    className={`enable_disable_button ${formData?.cancellation_upsell_options ? "" : "enabled"}`}
                                    onClick={() => { setFormData({ ...formData, cancellation_upsell_options: !formData?.cancellation_upsell_options }) }}
                                >
                                    {formData?.cancellation_upsell_options ? "Disable" : "Enable"}
                                </button>
                            </span>
                        </div>
                    </div>
                </FormLayout.Group>

                <div className="show_cancellation_instructions_wrapper cancellation_reasons_container">
                    <h5>Cancellation Upsells</h5>
                    <div className="cancellation_reasons_wrapper">
                        {
                            cancellationUpsells?.map((cancellationUpsell, i) => {
                                return (
                                    <>
                                        <div className="chargezen_row">
                                            <div className="left">{cancellationUpsell?.title1}</div>
                                            <div className="right">
                                                <ButtonGroup>
                                                    <Button
                                                        onClick={() => { showUpsellEdit(cancellationUpsell) }}
                                                    >
                                                        Edit
                                                    </Button>
                                                    <Button
                                                        disabled={i == 0}
                                                        onClick={() => { changeIndex(i, "up") }}
                                                    >
                                                        <Icon
                                                            source={ArrowUpMinor}
                                                            tone="base"
                                                        />
                                                    </Button>
                                                    <Button
                                                        disabled={i == (cancellationUpsells.length - 1)}
                                                        onClick={() => { changeIndex(i, "down") }}
                                                    >
                                                        <Icon
                                                            source={ArrowDownMinor}
                                                            tone="base"
                                                        />
                                                    </Button>
                                                    <Button
                                                        onClick={()=>{deleteCancellationUpsell(cancellationUpsell?.id)}}
                                                    >
                                                        <Icon
                                                            source={DeleteMajor}
                                                            tone="base"
                                                        />
                                                    </Button>
                                                </ButtonGroup>
                                            </div>
                                        </div>
                                    </>
                                )
                            })
                        }
                        <div className='add_cancellation_button_wrapper'>
                            <button className='add_cancellation_button' onClick={() => { addCancellationUpsell() }}>
                                <span>
                                    <svg viewBox="0 0 20 20" class="Polaris-Icon__Svg" focusable="false" aria-hidden="true"><path d="M0 10c0 5.514 4.486 10 10 10s10-4.486 10-10-4.486-10-10-10-10 4.486-10 10zm5 0a1 1 0 0 1 1-1h3v-3a1 1 0 1 1 2 0v3h3a1 1 0 1 1 0 2h-3v3a1 1 0 1 1-2 0v-3h-3a1 1 0 0 1-1-1z"></path></svg>
                                </span>
                                <span>Add cancellation upsell</span>
                            </button>
                        </div>
                    </div>
                </div>

            </div>
        </>

    )
}

export default CancellationUpsells