import React, { useCallback, useRef, useState } from 'react';
import { Card } from '@shopify/polaris';
import { useHistory } from 'react-router-dom';

export default function SlideItem({ data, setSelectedTemplateId }) {
  console.log('here', data);
  const [selectedimg, seSelectedImg] = useState(data.images[0]);
  const history = useHistory();
  return (
    <Card>
      <div className="top_image">
        <img alt="" width="100%" height="100%" src={selectedimg} />
      </div>
      <div className="thumbnail_image">
        {data.images?.map((image) => {
          return (
            <img
              alt=""
              width="100%"
              height="100%"
              class={image == selectedimg ? 'active' : ''}
              src={image}
              onClick={() => {
                seSelectedImg(image);
              }}
            />
          );
        })}
      </div>

      <div className="title_heading">{data?.name}</div>
      <div className="categories_data">
        <span>{data?.categories_data} </span>
      </div>
      <div className="action_button">
        <button
          className="use_temp"
          onClick={() =>
            history.push(`/pointsProgram/ReferralPage/${data?.id || true}`)
          }
        >
          Use Template
        </button>
        <button className="prev" onClick={() => window.open(`https://chargezendemo.myshopify.com/pages/referrals/`, "_blank")}>Preview </button>
      </div>
    </Card>
  );
}
