import React, { useState } from 'react';
import Nav from './Nav';
import AppHeader from '../layout/AppHeader';
import Footer from '../layout/Footer';
import FloatingSetupGuide from '../home/FloatingSetupGuide';

const AffiliatePageLayout = (props) => {
  const {
    customNavTabs,
    setCustomNavTabs,
    floatingSetupGuide,
    setFloatingSetupGuide,
    fetchNavBarTabs,
  } = props;
  const [navCollapse, setNavCollapse] = useState(false);
  return (
    <>
      <AppHeader domain={props?.domain} i18n={props?.i18n} />
      <div
        className={`module-navbar dashboardArea ${
          navCollapse ? 'collapsedNav' : ''
        }`}
      >
        <Nav
          domain={props?.domain}
          setNavCollapse={setNavCollapse}
          navCollapse={navCollapse}
          i18n={props?.i18n}
          mixpanelId={props?.mixpanelId}
        />
        <div
          className={`app_content ${
            floatingSetupGuide ? 'with_floating_setup_guide' : ''
          }`}
        >
          {props.children}
          {process.env.APP_TYPE == 'public' && <Footer i18n={props?.i18n} />}

          <br />
        </div>
        {floatingSetupGuide && (
          <div className="setup_guide_float_right_container">
            <FloatingSetupGuide
              {...{
                customNavTabs,
                floatingSetupGuide,
                setFloatingSetupGuide,
              }}
            />
          </div>
        )}
      </div>
    </>
  );
};

export default AffiliatePageLayout;
