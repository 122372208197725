import {
  Button,
  ButtonGroup,
  Card,
  ProgressBar,
  Page,
  Layout,
  Icon,
} from '@shopify/polaris';
import React, { useCallback, useEffect, useState } from 'react';
import ContentSummaryGraph from '../home/HomeComponents/ContentSummaryGraph';
import PieChart from '../home/HomeComponents/ContentSummaryGraph/PieChart';
import MultiColorProgressBar from '../home/HomeComponents/MultiColorProgressBar';
import {
  ThumbsUpMajor,
  StoreStatusMajor,
  SmileyJoyMajor,
  ChevronRightMinor,
} from '@shopify/polaris-icons';
import AppLayout from '../layout/Layout';
import '../home/style.css';
import RevenueHighlight from '../home/RevenueHighlight';
import { gql, useLazyQuery } from '@apollo/client';
import dayjs from 'dayjs';
import { isEmpty } from 'lodash';
import PixelIcon from '../../images/PixelIcon';
import SubHeader from '../home/SubHeader';
import ChatWithUs from '../home/ChatWithUs';
import SetupGuide from '../home/SetupGuide';
import HeaderButtons from '../HeaderButtons/HeaderButtons';
import ToggleButton from 'react-toggle-button';
import './style.css';
import { useParams, useHistory } from 'react-router-dom';

const AffiliateDashboard = (props) => {
  const { user_token } = useParams();
  var today = new Date();
  const {
    i18n,
    customNavTabs,
    floatingSetupGuide,
    setFloatingSetupGuide,
    domain,
    userToken,
  } = props;
  const [filters, setFilters] = useState({
    startDate: new Date(new Date().setDate(today.getDate() - 31)),
    endDate: new Date(new Date().setDate(today.getDate() - 1)),
  });

  const [fetchDashboardReport, setFetchDashboardReport] = useState({});
  const [toggleCommission, setToggleCommission] = useState(true);
  const { headerButton, setHeaderButton } = useState('active');
  const [apiData, setApiData] = useState({});
  const getGraphDataQuery = gql`
    query ($startDate: String!, $endDate: String!) {
      fetchDashboardReport(startDate: $startDate, endDate: $endDate) {
        todayOrders
        lastWeekOrders
        lastWeekOrderRevenue
        todayOrderRevenue
        upcomingSales
        currencySymbol
        currency
        mrr {
          value
          percent
          up
        }
        activeSubscriptionsCount {
          value
          percent
          up
        }
        churnRate {
          value
          percent
          up
        }
        customerLifetimeValue {
          value
          percent
          up
        }
        activeCustomers {
          data {
            value
          }
          date
        }
        revenueChurn {
          date
          data {
            value
          }
        }
        arrData {
          date
          data {
            value
          }
        }
        mrrData {
          date
          data {
            value
          }
        }
        refundData {
          date
          data {
            value
          }
        }
        salesData {
          date
          data {
            value
          }
        }
        renewalData {
          date
          data {
            value
          }
        }
      }
    }
  `;

  const history = useHistory();

  const fetchAffiliate = () => {
    fetch(`/getAffiliate/${`current_user`}?shopify_domain=${domain}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setApiData(data);
      });
  };

  const [getReport, { loading, error, data }] = useLazyQuery(
    getGraphDataQuery,
    { fetchPolicy: 'network-only' }
  );

  const getReportData = useCallback(() => {
    getReport({
      variables: {
        startDate: filters.startDate,
        endDate: filters.endDate,
      },
    });
  }, [filters, getReport]);

  const handleFiltersDates = (dates, span) => {
    console.log('hahah');
    if (!isEmpty(dates)) {
      const { start, end } = dates;
      setFilters({
        startDate: dayjs(start).format('YYYY-MM-DD'),
        endDate: dayjs(end).format('YYYY-MM-DD'),
        span: span,
      });
    }
  };

  useEffect(() => {
    if (userToken !== user_token) {
      history.push(`/${userToken}`);
    }
    fetchAffiliate();
  }, []);

  const [dateFilters, setDateFilters] = useState({
    startDate: '',
    endDate: '',
  });

  const calculateValue = (arr) => {
    let value = 0;
    arr?.map((val) => {
      value += +val?.data?.value;
    });
    return +value;
  };
  const subHeaders = [
    {
      title: 'Sales',
      value: `${apiData?.sales || '--'}`,
      desc: '',
    },
    {
      title: 'Commission',
      value: `${apiData?.earned_commission || '--'}`,
      desc: '',
    },
    {
      title: 'Unique views',
      value: `${apiData?.views || '--'} `,
      desc: '',
    },
  ];

  const headerButtons = [
    {
      val: 'active',
      name: 'Active',
    },
    {
      val: 'pending',
      name: 'Pending',
    },
    {
      val: 'inactive',
      name: 'Inactive',
    },
  ];

  return (
    <>
      <Page>
        <Layout>
          <Layout.Section></Layout.Section>
          <div className="dashboard_main_div">
            <div className="dashboard_left">
              <div className="dashboard_header">
                <div className="main_head">{i18n.t('dashboard')}</div>
                <div className="sub_head">
                  {i18n.t('welcome_back')},{' '}
                  {localStorage.getItem('currentuser_first_name') || '--'}!
                </div>
              </div>
              <div className="top_section">
                <div className="dashboard_sub_header">
                  {subHeaders?.map((subheader) => {
                    return (
                      <SubHeader
                        title={subheader?.title}
                        value={subheader?.value || '--'}
                        desc={i18n.t('content_last_seven_days', {
                          content: subheader?.desc || '--',
                        })}
                      />
                    );
                  })}
                </div>
                <div className="commission_profile_container">
                  <h1>
                    View affiliate commission details of brands you work with
                    and applied to
                  </h1>
                  <div className="without_header_card">
                    <Card
                      actions={{
                        content: (
                          <div className="card_header_button_group">
                            <div className="card_header_buttons primary_button_group">
                              <HeaderButtons
                                headerButtons={headerButtons}
                                setHeaderButton={setHeaderButton}
                                headerButton={headerButton}
                              />
                            </div>
                            <div className="card_header_buttons secondary_button_group">
                              <Button onClick={() => {}}>
                                Discover new brands
                              </Button>
                              <Icon source={ChevronRightMinor} tone="base" />
                            </div>
                          </div>
                        ),
                      }}
                    >
                      <Card.Section>
                        <div className="setup_guide_content_wrapper">
                          <div className="contentsTab_container">
                            <div className="contents_tab_wrapper">
                              <span>Ketuousa.com</span>
                              <button
                                className={`toggle_content_tab_button icon_wrapper opened`}
                              ></button>
                            </div>
                            <div className={`contents_wrapper_container`}>
                              {apiData?.commission_titles?.map((tile) => {
                                return (
                                  <div className="affiliate_content_section">
                                    <div className="left_section">
                                      <ul>
                                        <li>
                                          <strong>Commission tier:</strong>{' '}
                                          <span>{tile.title}</span>
                                        </li>
                                        <li>
                                          <strong>Affiliate commission:</strong>{' '}
                                          <span>{tile.commission_rate}%</span>
                                        </li>
                                        <li>
                                          <strong>Affiliate discount:</strong>{' '}
                                          <span>{tile.discount_value}%</span>
                                        </li>
                                        <li>
                                          <strong>Affiliate url:</strong>{' '}
                                          <span>{tile.affiliate_url}</span>
                                        </li>
                                        <li>
                                          <strong>Destination url:</strong>{' '}
                                          <span>{tile.redirection_path}</span>
                                        </li>
                                      </ul>
                                    </div>
                                    <div className="right_section">
                                      <div className="toggleButtonWrapper">
                                        <ToggleButton
                                          inactiveLabel={''}
                                          activeLabel={''}
                                          value={toggleCommission}
                                          onToggle={(e) => {
                                            setToggleCommission(
                                              !toggleCommission
                                            );
                                          }}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        </div>
                      </Card.Section>
                    </Card>
                  </div>
                </div>
                <h2 className="next_thing_heading">Things to do next</h2>
                <SetupGuide
                  {...{
                    customNavTabs,
                    floatingSetupGuide,
                    setFloatingSetupGuide,
                  }}
                  forAffiliateUser={true}
                />
              </div>
              <div className="bottom_section">
                <ChatWithUs />
              </div>
            </div>
          </div>
        </Layout>
      </Page>
    </>
  );
};

export default AffiliateDashboard;
