import React, { useEffect, useState } from 'react';
import { Select, TextField } from '@shopify/polaris';

const CheckProductTitle = (props) => {
  const { formData, setFormData, index, labelText = 'Product title' } = props;
  const [selected, setSelected] = useState('is equal to');
  const [productTitle, setProductTitle] = useState('');

  useEffect(() => {
    if (formData?.conditions[index]?.product_title) {
      setProductTitle(formData?.conditions[index]?.product_title);
      setSelected(formData?.conditions[index]?.title_condition);
    }
  }, []);

  useEffect(() => {
    let form_data_conditions = formData?.conditions;
    form_data_conditions[index]['product_title'] = productTitle;
    form_data_conditions[index]['title_condition'] = selected;
    setFormData({ ...formData, conditions: form_data_conditions });
  }, [productTitle, selected]);

  return (
    <div className="check_product_title_wrapper">
      <label>{labelText}</label>
      <Select
        options={[
          'is equal to',
          'is not equal to',
          'contains',
          'does not contain',
          'starts with',
          'ends with',
        ]}
        value={selected}
        onChange={(e) => {
          setSelected(e);
        }}
      />
      <TextField
        type="text"
        value={productTitle}
        onChange={(e) => setProductTitle(e)}
      />
    </div>
  );
};

export default CheckProductTitle;
