import React, { useCallback, useEffect, useState } from 'react';
import { gql, useMutation } from '@apollo/client';
import { Text, Card, Toast, ChoiceList, Page, PageActions, TextField, Layout, Banner, List, Frame, Select, Icon, Checkbox, Form, DropZone, Thumbnail, Stack, FormLayout, Button } from '@shopify/polaris';
import PixelIcon from '../../../images/PixelIcon';
import { MobileBackArrowMajor } from '@shopify/polaris-icons';
import SearchProduct from '../../upsell/SearchProduct';
import Preview from '../../plans/Preview';

const CreateTierBenefits = (props) => {
    const { domain, tierFormData, setTierFormData, index, i18n } = props;
    const [toastContent, setToastContent] = useState("");
    const [toastActive, setToastActive] = useState(false);
    const toggleToastActive = useCallback(() => setToastActive((toastActive) => !toastActive), []);

    const validImageTypes = ['image/gif', 'image/jpeg', 'image/png'];

    const handleChange = (val, key) => {
        let data = tierFormData;
        data[index][key] = val;
        setTierFormData([...data]);

    }



    return (
        <Layout>
            <Layout.Section>
                <Card sectioned>
                    <Form>
                        <FormLayout>
                            <FormLayout.Group>
                                <TextField
                                    label={i18n.t("title")}
                                    type="text"
                                    value={`${tierFormData?.[index]?.title}`}
                                    onChange={(e) => {
                                        handleChange(e, "title")
                                    }}

                                    autoComplete="off"
                                />
                            </FormLayout.Group>

                            <FormLayout.Group>
                                <TextField
                                    label={i18n.t("desc")}
                                    type="text"
                                    multiline={2}
                                    value={`${tierFormData?.[index]?.desc}`}
                                    onChange={(e) => {
                                        handleChange(e, "desc")
                                    }}

                                    autoComplete="off"
                                />
                            </FormLayout.Group>
                        </FormLayout>
                    </Form>
                </Card>
            </Layout.Section>
        </Layout>

    )
}

export default CreateTierBenefits;