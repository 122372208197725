import { Icon } from '@shopify/polaris';
import { SearchMajor } from '@shopify/polaris-icons';
import React, { useState } from 'react';
import ProductVariantModal from './ProductVariantModal';
import { ChevronDownMinor, MobileCancelMajor } from '@shopify/polaris-icons';

const ProductVariantPicker = (props) => {
  const { setFormData, formData, field, subField, index, selectType, singleSelection, previewVariants } = props;
  const [showProductModal, setShowProductModal] = useState(false);
  const [query, setQuery] = useState();

  const removeItem = (productIndex) => {
    const products = formData?.[field];
    products?.[index]?.[subField]?.splice(productIndex, 1);
    setFormData({ ...formData, [field]: products });
  };

  return (
    <div className="product-variant-picker-container">
      {showProductModal && (
        <ProductVariantModal
          {...{
            showProductModal,
            setShowProductModal,
            query,
            setQuery,
            setFormData, formData, field, subField, index, selectType, singleSelection
          }}
        />
      )}
      <div className="product_searchProduct">
        <div className="product_search_input">
          <Icon source={SearchMajor} tone="base" />
          <input
            type="text"
            placeholder={`Search ${selectType == "products" ? "products" : "products or variants"}`}
            id="search_product_query"
            onChange={(e) => {
              setQuery(e?.target?.value || '');
              setShowProductModal(true);
            }}
            value={query}
          />
        </div>
        <button
          type="button"
          className="browse_product_button"
          onClick={() => setShowProductModal(true)}
        >
          Browse
        </button>
      </div>
      <div className="product_preview_row">
        {formData?.[field]?.[index]?.[subField]?.map((p, i) => {
          return (
            <div className='product_variant_preview_content'>
              <div className="product_variant_preview_wrapper">
                <div className="imgwrapper">
                  <img src={p.image} />
                </div>
                <div className="pv_desc_container">
                  <div className="product_variant_desc">
                    <span>{p.title}</span>
                    {
                      selectType != "product" &&
                      <span >
                        ({p?.variants?.filter((v) => v.selected)?.length} of{' '}
                        {p.variants?.length}) variants selected
                      </span>
                    }
                  </div>
                  <div className="edit_remove_wrapper">
                    <button
                      type="button"
                      className="editbutton"
                      onClick={() => setShowProductModal(true)}
                    >
                      Edit
                    </button>
                    <button
                      type="button"
                      className="close"
                      onClick={() => removeItem(i)}
                    >
                      <Icon source={MobileCancelMajor} tone="base" />
                    </button>
                  </div>
                </div>
              </div>
              {
                previewVariants && (
                  <div className='variants_tags_wrapper'>
                    {
                      p?.variants?.filter((v) => v.selected)?.map((variant) => {
                        return (
                          <div class="selectedPlan">{variant?.title}</div>
                        )
                      })
                    }
                  </div>
                )
              }
            </div>
          );
        })}
      </div>
    </div >
  );
};

export default ProductVariantPicker;
