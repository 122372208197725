import React, { useState } from 'react';
import { Card, Icon } from '@shopify/polaris';
import { HorizontalDotsMinor } from '@shopify/polaris-icons';
import { useHistory } from 'react-router-dom';

const AlreadyCreatedBundles = ({
  menu,
  setEditId,
  setToastContent,
  fetchDishmenus,
}) => {
  const [moreOptions, setMoreOptions] = useState(false);
  const history = useHistory();

  const deleteOffer = (menu_id) => {
    fetch(`/dishmenu/delete_menu/${menu_id}`, {
      method: 'DELETE',
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status) {
          fetchDishmenus();
          setToastContent('Menu Deleted');
        }
      })
      .catch((e) => {
        console.log('Somethind went wrong' + e);
      });
  };

  const duplicateOffer = (menu_id) => {
    fetch(`/dishmenu/duplicate_menu/${menu_id}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status) {
          fetchDishmenus();
          setToastContent('Menu Duplicated');
        }
      })
      .catch((e) => {
        console.log('Somethind went wrong' + e);
      });
  };

  return (
    <div className="upsell_offer_wrapper_container">
      <Card>
        <div className="upsell_offer_wrapper">
          <div
            className="more_options"
            onClick={() => {
              setMoreOptions(!moreOptions);
            }}
          >
            <div className="more_options icon_wrapper">
              <Icon source={HorizontalDotsMinor} color="base" />
            </div>
            {moreOptions && (
              <div className="options_lists">
                <div
                  className="option_wrapper"
                  onClick={() => history.push(`/bundle/${menu.token}`)}
                >
                  Edit
                </div>
                {menu.status == 'live' && (
                  <div className="option_wrapper">Analytics</div>
                )}
                <div
                  className="option_wrapper"
                  onClick={() => duplicateOffer(menu.token)}
                >
                  Duplicate
                </div>
                <div
                  className="option_wrapper delete_offer"
                  onClick={() => deleteOffer(menu.token)}
                >
                  Delete
                </div>
              </div>
            )}
          </div>
          <div className="img_wrapper">
            <img src={menu?.image} />
          </div>
          <div className="content_block">
            <span className="offer_title" onClick={() => setEditId(menu.token)}>
              {menu.title}
            </span>
            <div className="date_wrapper">Created at: {menu.created_at}</div>
          </div>
        </div>
      </Card>
    </div>
  );
};

export default AlreadyCreatedBundles;
