import React, { useCallback, useEffect, useState } from 'react';
import Overview from '../Overview/Overview';

import { gql, useLazyQuery } from '@apollo/client';
import dayjs from 'dayjs';
import { isEmpty } from 'lodash';

const AffiliateOverview = (props) => {
  var today = new Date();
  const {
    i18n,
    customNavTabs,
    floatingSetupGuide,
    setFloatingSetupGuide,
    domain,
  } = props;
  const [subHeaderData, setSubHeaderData] = useState([]);

  const [fetchDashboardReport, setFetchDashboardReport] = useState({});

  const fetchOverviewReport = () => {
    fetch(`/getAffiliateOverview?shop=${domain}`, {
      method: 'GET',
    })
      .then((r) => r.json())
      .then((response) => {
        if (response?.report) {
          setFetchDashboardReport({ ...response?.report });
        }
        console.log('response.affiliates', response, response.report);
      });
  };

  useEffect(() => {
    fetchOverviewReport();
  }, []);

  useEffect(() => {
    let subheaderdata = [
      {
        title: 'Sales',
        value: fetchDashboardReport?.sales
          ? `${fetchDashboardReport?.currencySymbol}${parseFloat(
              fetchDashboardReport?.sales
            )?.toFixed(2)}`
          : '--',
        desc: ``,
      },
      {
        title: `Earned commission`,
        value: fetchDashboardReport?.earned_commission
          ? `${fetchDashboardReport?.currencySymbol}${parseFloat(
              fetchDashboardReport?.earned_commission
            )?.toFixed(2)}`
          : '--',
        desc: ``,
      },
      {
        title: 'Active affiliates',
        value: fetchDashboardReport?.active_affiliates,
        desc: '',
      },
    ];
    setSubHeaderData([...subheaderdata]);
  }, [fetchDashboardReport]);

  return (
    <>
      <Overview
        {...{
          i18n,
          floatingSetupGuide,
          setFloatingSetupGuide,
          subHeaderData,
        }}
        customNavTabs={{ affiliate_influencer: true }}
      />
    </>
  );
};

export default AffiliateOverview;
