const setupcontents = {
  subscription_home: [
    {
      title: 'Set up subscriptions',
      contents: [
        {
          completed: false,
          val: 'first_product',
          heading: 'Create selling plans',
          description:
            'Configure the billing & delivery frequency as well discounts and or trial options: & assign to a variant(s) or product(s).',
          button_group: [
            {
              button_text: 'create selling plan',
              button_redirection: '/fixed-subscription-plans',
            },
          ],
        },
        {
          completed: false,
          val: 'custom_online_store',
          heading: 'Configure subscription products',
          description:
            'Configure products eligible for subscriptions on your store. Your customers will be able to swap between these products',
          button_group: [
            {
              button_text: 'Configure subscription products',
              button_redirection: '/customersTab/subscription_products',
            },
          ],
        },
        {
          completed: false,
          val: 'manage_sub_emails',
          heading: 'Manage subscription Emails',
          description: 'Manage subscription emails of your store',
          button_group: [
            {
              button_text: 'Manage Emails',
              button_redirection: '/customersTab/subscription_email',
            },
          ],
        },
        {
          completed: false,
          val: 'manage_contracts',
          heading: 'Manage subscription contracts',
          description: 'Manage subscription contracts of your store.',
          button_group: [
            {
              button_text: 'Manage contracts',
              button_redirection: '/customersTab/contracts',
            },
          ],
        },
      ],
    },
    {
      title: 'Subscriptions settings',
      contents: [
        {
          completed: false,
          val: 'shopify_payments',
          heading: 'Configure subscription automations',
          description:
            'Shopify Payments is almost ready to go. You just need to provide a few details to start getting paid for your sales',
          button_group: [
            {
              button_text: 'Go to Subscription flows',
              button_redirection: '/customersTab/subscription_workflow',
            },
          ],
        },
        {
          completed: false,
          val: 'shipping_rates',
          heading: 'Review subscription notifications emails & SMS',
          description:
            'Choose where you ship and how much you charge so your customers can see their shipping costs at checkout.',
          button_group: [
            {
              button_text: 'Review email/SMS notifications',
              button_redirection: '/customersTab/subscription_email',
            },
          ],
        },
      ],
    },
    {
      title: 'Launch your subscription program',
      contents: [
        {
          completed: false,
          val: 'pick_plan',
          heading: 'Activate subscriptions program',
          description:
            'Choose a Chargezen plan with the right features for your new business. ',
          button_group: [
            {
              button_text: 'Pick a plan',
              button_redirection: '/choosePlan',
            },
          ],
        },
        {
          completed: false,
          val: 'remove_your_store_password',
          heading: 'Publish customer portal pages',
          description:
            'To launch your online store, you need to publish the customer portal and passwordless login pages.',
          button_group: [
            {
              button_text: 'Manage customer portal',
              button_redirection: '/zenn',
            },
          ],
        },
      ],
    },
  ],
  upsells: [
    {
      title: 'Set up upsell offers',
      contents: [
        {
          completed: false,
          val: 'first_product',
          heading: 'Choose upsell type',
          description:
            'You can choose between one-time upsell offers or subscription offers; or a combination of both.',
          button_group: [
            {
              button_text: 'Choose upsell type',
              button_redirection: '/chargezenUpsells/manage',
            },
          ],
        },
        {
          completed: false,
          val: 'custom_online_store',
          heading: 'Configure one-time upsell offers',
          description:
            'Each upsell offer can be a single variant or multiple; choose variants, configure the discount and copy for each variant.',
          button_group: [
            {
              button_text: 'Create one-time offers',
              button_redirection: '/upsells/manage/create/2',
            },
          ],
        },
        {
          completed: false,
          val: 'manage_sub_emails',
          heading: 'Configure subscription upsell offers',
          description:
            'Each upsell offer can be a single variant or multiple; choose variants, configure the discount, selling plan and copy for each variant.',
          button_group: [
            {
              button_text: 'Create subscription offers',
              button_redirection: '/chargezenUpsells/manage/create/5',
            },
          ],
        },
        {
          completed: false,
          val: 'manage_contracts',
          heading: 'Save your upsell offer',
          description:
            'Once you have confirmed the variants, content and discount are setup properly; save your offer',
          button_group: [
            {
              button_text: 'Save upsell offer',
              button_redirection: '/chargezenUpsells/manage',
            },
          ],
        },
      ],
    },
    {
      title: 'Enable Chargezen RevenueOS in checkout settings',
      contents: [
        {
          completed: false,
          val: 'shopify_payments',
          heading: 'Enable Chargezen for upsells',
          description:
            'If you see a notice in the upsells form, you have to set Chargezen RevenueOS as the app app to add features at checkout.',
          button_group: [
            {
              button_text: 'Verify checkout upsells settings',
              button_redirection: '/chargezenUpsells/manage/create/2',
            },
          ],
        },
      ],
    },
    {
      title: 'Launch your post-purchase upsell offers',
      contents: [
        {
          completed: false,
          val: 'pick_plan',
          heading: 'View saved upsell offers',
          description:
            'If you have created upsell offers in the previous steps, they will be in draft state. Click on the dots at the top right and select edit.',
          button_group: [
            {
              button_text: 'View draft offers',
              button_redirection: '/chargezenUpsells/manage',
            },
          ],
        },
        {
          completed: false,
          val: 'remove_your_store_password',
          heading: 'Publish upsell offer',
          description:
            'The draft upsell offer is now open; now click on the Publish button on the bottom right and choose publish immediately. This sets your offer live.',
          button_group: [
            {
              button_text: 'Publish draft offer',
              button_redirection: '/chargezenUpsells/manage',
            },
          ],
        },
      ],
    },
  ],
  affiliate_influencer: [
    {
      title: 'Set up Affiliate Influencer',
      contents: [
        {
          completed: false,
          val: 'first_product',
          heading: 'Add your first product',
          description:
            'Write a description, add photos, and set pricing for the products you plan to sell.',
          button_group: [
            {
              button_text: 'Add product',
              button_redirection: '/products',
            },
          ],
        },
        {
          completed: false,
          val: 'custom_online_store',
          heading: 'Customize your online store',
          description:
            'Choose a theme and add your logo, colors, and images to reflect your brand.',
          button_group: [
            {
              button_text: 'Customize theme',
              button_redirection: '/themes',
            },
          ],
        },
        {
          completed: false,
          val: 'custom_domain',
          heading: 'Add a custom domain',
          description:
            'Your current domain is ecomtarget-demo.myshopify.com but you can add a custom domain to help customers find your online store.',
          button_group: [
            {
              button_text: 'Add domain',
              button_redirection: '/domains',
            },
          ],
        },
        {
          val: 'sharing_details',
          completed: false,
          heading: 'Add sharing details',
          description:
            'Add a social sharing image, title, and description to showcase your online store on social media and search listings.',
          button_group: [
            {
              button_text: 'Add sharing details',
              button_redirection: '/sharingDetails',
            },
          ],
        },
      ],
    },
  ],
};

const headingTitle = {
  subscription_home: { subHeading: 'Use this personalized guide to setup Chargezen subscriptions on your store.' },
  upsells: { subHeading: 'Use this personalized guide to launch one-click post-purchase upsells with Chargezen.' },
  kiosk_pos: { subHeading: 'Use this personalized guide to launch your membership focused meal delivery business or restaurant' },
  sms_smarty: { subHeading: 'Use this personalized guide to launch upsells, subscription & replenishment flows via SMS.' },
  loyalty_rewards: { subHeading: 'Use this personalized guide to launch a loyalty program & reward your loyal customers.' },
  checkout_extensions: { subHeading: 'Use this personalized guide to setup checkout extensions or customize your checkout.' },
  replenish_me: { subHeading: 'Use this personalized guide to setup automated replenishment flows via email, SMS & QR codes.' },
  affiliate_influencer: { subHeading: 'Use this personalized guide to launch a cookieless & coupon-free affiliates marketing program.',
},
};

export { setupcontents, headingTitle };
