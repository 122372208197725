import React, { useState, useCallback, useEffect } from 'react';
import AppLayout from '../layout/Layout';
import { Page, Card, Layout, Icon } from '@shopify/polaris';
import SettingImage from '../../../assets/images/settings/setting.svg';
import EmailImage from '../../../assets/images/settings/email.svg';
import InformationImage from '../../../assets/images/settings/information.svg';
import Integrations from '../integration/Index';
import IntegrationDetail from '../integration/Detail';
import ShippingSuit from '../shippingSuit/index';
import WeeklyMealsList from '../weeklyMeals/index';
import WeeklyMenuList from '../weeklyMenu/index';
import WeeklyMenu from '../weeklyMenu/weeklyMenuForm';
import CustomerMigration from '../CustomerMigration';
import StripeContractsList from '../StripeContracts/StripeContractsList';
import SubscriptionProducts from '../SubscriptionProducts';
import './toolbox.css';
import { MobileBackArrowMajor } from '@shopify/polaris-icons';
import { useParams } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
const Index = (props) => {
  const { selected_tab, edit_id, selectedTab } = useParams();
  const { i18n, domain } = props;
  const history = useHistory();
  const [renderAsMain, setRenderAsMain] = useState();

  const [selectedSetting, setSelectedSetting] = useState('');

  const [editId, setEditId] = useState('');
  const [showEditPage, setShowEditPage] = useState(false);

  const handleBackSetting = useCallback(() => {
    setSelectedSetting('');
    let urlDomain = domain?.replace(".myshopify.com", '')
    if (selectedTab) {
      history.push(
        window.location.pathname.replace(`/${urlDomain}`, '').replace(selectedTab, '')
      );
    }
    else if (edit_id) {
      history.push(
        window.location.pathname.replace(`/${urlDomain}`, '').replace(edit_id, '')
      );
    } else {
      history.push('/toolbox');
    }
  }, [setSelectedSetting]);

  const handleCloseEditPage = useCallback(() => {
    setShowEditPage(false);
    setEditId('');
  }, [setShowEditPage, editId]);

  const handleEditPage = useCallback(
    (ID) => {
      setEditId(ID);
      setShowEditPage(true);
    },
    [setEditId, setShowEditPage]
  );

  useEffect(() => {
    setShowEditPage(false);
    setEditId('');
  }, [selectedSetting]);

  const settings = [
    {
      key: 'toolbox',
      title: 'Shipping Suite',
      img: SettingImage,
      desc: 'create shipping labels and track shipments',
    },
    {
      key: 'integerations',
      title: 'Integrations',
      img: SettingImage,
      desc: 'Connect to and manage third-party app integrations',
    },
    {
      key: 'integerationsMain',
      title: 'Integrations',
      img: SettingImage,
      desc: 'Connect to and manage third-party app integrations',
    },
    {
      key: 'meals',
      title: 'Build-a-Box choices',
      img: EmailImage,
      desc: 'View product choices made by your customers with build-a-box subscriptions.',
    },
    {
      key: 'menu',
      title: 'Build-a-Box Menu',
      img: SettingImage,
      desc: 'Create & manage custom menus by week for build-a-box subscriptions',
    },
    {
      key: 'migrate',
      title: 'Customer Migrations',
      img: SettingImage,
      desc: 'Create subscription contracts for your customers or migrate existing contracts',
    },
    {
      key: 'stripeContract',
      title: 'Stripe Contract',
      img: SettingImage,
      desc: 'Create Stripe contracts for your customers.',
    },
    {
      key: 'subscriptionProducts',
      title: 'Subscription Products',
      img: SettingImage,
      desc: 'Select Eligible Products for swaps or upgrades on subscriptions.',
    },
    // {
    //     key: "",
    //     title: "Products",
    //     img: InformationImage,
    //     desc: "Manage the loyalty and referrals settings for your customers."
    // }
  ];

  useEffect(() => {
    if (selected_tab) {
      if (selected_tab === 'integerationsMain') {
        setRenderAsMain('Integrations');
      }
      setSelectedSetting(selected_tab);
    }
  }, [selected_tab]);

  return (
    <>
      <Page
        title={
          <div className="back_button_wrapper">
            {((selectedSetting && !renderAsMain) || selectedTab) ? (
              <div className="back-button pointer" onClick={handleBackSetting}>
                <Icon source={MobileBackArrowMajor} color="base" />
              </div>
            ) : (
              ''
            )}
            <div class="text_wrapper">
              {renderAsMain ? renderAsMain : i18n.t('toolbox')}
            </div>
          </div>
        }
      >
        <div className="settings-container">
          {!selectedSetting && (
            <div>
              <Card>
                <Card.Section>
                  <div className="setting-grid">
                    {settings.map((setting) => (
                      <div
                        key={Math.random()}
                        className="tabs-parents pointer"
                        onClick={() => history.push(`/toolbox/${setting.key}`)}
                      >
                        <div className="icon-sec">
                          <img src={setting.img} />
                        </div>
                        <div className="tab-info">
                          <h4>{setting.title}</h4>
                          <p>{setting.desc}</p>
                        </div>
                      </div>
                    ))}
                  </div>
                </Card.Section>
              </Card>
            </div>
          )}
          {/* settings layout */}
          {selectedSetting && (
            <Layout>
              <Layout.Section>
                {selectedSetting === 'integerations' ||
                  selectedSetting === 'integerationsMain' ? (
                  <>
                    {!showEditPage ? (
                      <Integrations
                        i18n={i18n}
                        handleBack={handleBackSetting}
                        handleForm={handleEditPage}
                      />
                    ) : (
                      <IntegrationDetail
                        i18n={i18n}
                        id={editId}
                        handleClose={handleCloseEditPage}
                      />
                    )}
                  </>
                ) : selectedSetting === 'toolbox' ? (
                  <ShippingSuit i18n={i18n} handleBack={handleBackSetting} />
                ) : selectedSetting === 'meals' ? (
                  <WeeklyMealsList i18n={i18n} handleBack={handleBackSetting} />
                ) : selectedSetting === 'menu' ? (
                  <>
                    {!showEditPage ? (
                      <WeeklyMenuList
                        i18n={i18n}
                        handleBack={handleBackSetting}
                        handleForm={handleEditPage}
                      />
                    ) : (
                      <WeeklyMenu
                        i18n={i18n}
                        id={editId}
                        handleClose={handleCloseEditPage}
                      />
                    )}
                  </>
                ) : selectedSetting === 'migrate' ? (
                  <>
                    {
                      <CustomerMigration
                        i18n={i18n}
                        handleBack={handleBackSetting}
                        handleForm={handleEditPage}
                      />
                    }
                  </>
                ) : selectedSetting === 'stripeContract' ? (
                  <>
                    {
                      <StripeContractsList
                        i18n={i18n}
                        handleBack={handleBackSetting}
                        handleForm={handleEditPage}
                      />
                    }
                  </>
                ) : selectedSetting === 'subscriptionProducts' ? (
                  <>
                    {
                      <SubscriptionProducts
                        i18n={i18n}
                        handleBack={handleBackSetting}
                        handleForm={handleEditPage}
                      />
                    }
                  </>
                ) : (
                  ''
                )}
              </Layout.Section>
            </Layout>
          )}
        </div>
      </Page>
    </>
  );
};

export default Index;
