import React, { useCallback, useState, useEffect, useContext } from 'react';
import { MobileBackArrowMajor } from '@shopify/polaris-icons';
import {
  Card,
  Layout,
  Toast,
  List,
  Button,
  TextField,
  Icon,
  FormLayout,
  DisplayText,
  TextStyle,
  Select,
  ResourceItem,
  ResourceList,
  SettingToggle
} from '@shopify/polaris';
import { post } from 'jquery';
import { DomainContext } from '../domain-context';
import PixelIcon from '../../images/PixelIcon';

const EnableDebug = ({ handleBack, i18n }) => {
  const { domain } = useContext(DomainContext);
  const [active, setActive] = useState(false);
  const handleToggle = () => {
    fetch('/debug_mode?status=' + (!active) + "&shopify_domain=" + domain, {
      method: 'POST',
    })
      .then((response) => response.json())
      .then((data) => {
        setActive(!active);
      })
  }

  useEffect(() => {
    fetch(`/debug_mode?shopify_domain=${domain}`, {
      method: 'GET',
    })
      .then((response) => response.json())
      .then((data) => {
        setActive(data.debug_mode);
      })
  }, [])

  return (
    <>
      <div>
        <div>
          <Card title=
            {
              <div className="heading_title">
                <PixelIcon />
                {i18n.t("enable_debug_mode")}
              </div>
            }

          >
            <Card.Section>
              <div className="billing">
                <FormLayout>
                  <DisplayText size="small" element="h6">
                    <TextStyle variation="subdued">Debug Mode Status</TextStyle>
                  </DisplayText>
                  <SettingToggle
                    action={{
                      content: active ? 'Deactivate' : 'Activate',
                      onAction: handleToggle,
                    }}
                    enabled={active}
                  >
                    Debugging mode is <TextStyle variation="strong">{active ? 'activated' : 'deactivated'}</TextStyle>.
                  </SettingToggle>
                </FormLayout>
              </div>
            </Card.Section>
          </Card>
        </div>
      </div>
    </>
  );
};

export default EnableDebug;
