import React, { useState } from 'react';
import { ColorPicker, TextField, hsbToHex } from '@shopify/polaris';

const CustomColorPicker = (props) => {
  const [toggleColorPicker, setToggleColorPicker] = useState(false);
  const { formData, setFormData, fieldName, label, i18n } = props;

  function rgbToHsb(rgb) {
    if (rgb) {
      const r = rgb.red / 255;
      const g = rgb.green / 255;
      const b = rgb.blue / 255;

      const max = Math.max(r, g, b);
      const min = Math.min(r, g, b);
      const delta = max - min;

      let hue = 0;
      if (delta !== 0) {
        if (max === r) {
          hue = (g - b) / delta;
        } else if (max === g) {
          hue = 2 + (b - r) / delta;
        } else if (max === b) {
          hue = 4 + (r - g) / delta;
        }
        hue *= 60;
        if (hue < 0) {
          hue += 360;
        }
      }

      const saturation = max === 0 ? 0 : delta / max;
      const brightness = max;

      return { hue, saturation, brightness };
    }
  }

  function hexToRgb(hex) {
    if (hex) {
      const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
      hex = hex.replace(
        shorthandRegex,
        (m, r, g, b) => `${r}${r}${g}${g}${b}${b}`
      );

      const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
      return result
        ? {
            red: parseInt(result[1], 16),
            green: parseInt(result[2], 16),
            blue: parseInt(result[3], 16),
          }
        : null;
    }
  }

  const hexToHsb = (hexColor) => {
    if (hexColor) {
      const rgbColor = hexToRgb(hexColor); // Convert hex to RGB
      const hsbColor = rgbToHsb(rgbColor); // Convert RGB to HSB
      return hsbColor;
    }
  };

  return (
    <>
      <div className="chargezen_color_picker_wrapper">
        <div
          className="color_picker_container_button"
          style={{ backgroundColor: formData?.[fieldName] || '#000' }}
          onClick={() => {
            setToggleColorPicker(!toggleColorPicker);
          }}
        ></div>
        <div>{label}</div>
        {toggleColorPicker && (
          <div className="custom_color_picker_form">
            <div className="color_picker_component">
              <ColorPicker
                onChange={(e) => {
                  setFormData({ ...formData, [fieldName]: hsbToHex(e) });
                }}
                color={hexToHsb(formData?.[fieldName]) || {}}
              />
            </div>
            <div className="color_picker_text_component">
              <TextField
                value={formData?.[fieldName] || '#000'}
                onChange={(e) => {
                  setFormData({ ...formData, [fieldName]: e });
                }}
              />
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default CustomColorPicker;
