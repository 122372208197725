import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import '../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import React, { useRef, useState } from 'react'

const HtmlEditor = (props) => {
    const { formData, setFormData, fieldName } = props;

    const codeTextArea = useRef(null);
    const [showEditorCode, setShowEditorCode] = useState(false);
    const [editorState, setEditorState] = React.useState(
        EditorState.createEmpty()
    );

    const ShowEditorCode = () => (
        <div className="rdw-option-wrapper" onClick={() => toggleEditorCode()}>
            {'</>'}
        </div>
    );

    const toggleEditorCode = () => {
        if (showEditorCode) {
            const contentBlock = htmlToDraft(
                formData?.[fieldName] || ''
            );
            if (contentBlock) {
                const contentState = ContentState.createFromBlockArray(
                    contentBlock.contentBlocks
                );
                const editorState = EditorState.createWithContent(contentState);
                setEditorState(editorState);
            }
        }
        setShowEditorCode(!showEditorCode);
    };

    return (
        <div className='chargezen_html_editor'>
            <Editor
                editorState={editorState}
                defaultContentState={htmlToDraft(formData?.[fieldName] || '')}
                toolbarClassName="toolbarClassName"
                wrapperClassName="wrapperClassName"
                editorClassName="draftEditorWrapper"
                onEditorStateChange={(e) => {
                    setEditorState(e);
                    setFormData({ ...formData, [fieldName]: draftToHtml(convertToRaw(e.getCurrentContent())) });
                }}
                toolbarCustomButtons={[<ShowEditorCode />]}
                multiline={15}
            />
            {showEditorCode && (
                <textarea
                    ref={codeTextArea}
                    value={formData?.[fieldName]}
                    style={{
                        width: '100%',
                        border: 'none',
                        height: '10rem',
                    }}
                    onChange={(e) => {
                        setFormData({ ...formData, [fieldName]: e.target.value });
                    }}
                />
            )}
        </div>
    )
}

export default HtmlEditor