import React, { useEffect, useState } from 'react';

import './giftCardsHtml.css';
const GiftCardHtml = (props) => {
  const { formData, currentForm, contentBlocks } = props;
  const [dynamicStyle, setdynamicStyle] = useState();

  const defaultGiftCard =
    'https://cdn.shopify.com/s/files/1/0654/8042/5686/products/gift-card.png?v=1668795781';

  const getImageUrl = (fieldName) => {
    const file = formData?.[fieldName];
    try {
      return window.URL.createObjectURL(file);
    } catch (e) {
      return file;
    }
  };
  useEffect(() => {
    setdynamicStyle(
      `
      #chargezen_gift_card_container #chargezen-modal{
        background-color: ${formData?.popup_bg_color} !important;
        border-radius: ${formData?.border_radius} !important;
        border-color: ${formData?.border_color} !important;
      }

      .chargezen-heading {
        color: ${formData?.title_font_color} !important;
      }

      #chargezen_gift_card_container .chargezen-form-content .chargezen-toggle label{
        color: ${formData?.subtitle_font_color} !important;
      }
    
      #chargezen_gift_card_container .chargezen-form-content .chargezen-toggle-disclaimer span, form.chargezen-form .chargezen-field:not(:nth-child(2))>label{
        color: ${formData?.description_font_color} !important;
      }

      #chargezen_gift_card_container .chargezen-form-content .chargezen-toggle-disclaimer span, form.chargezen-form .chargezen-field:not(:nth-child(2))>label{
        color: ${formData?.label_color} !important;
      }
      #chargezen_gift_card_container form.chargezen-form {
        border: 1px solid ${formData?.form_border_color} !important; !important;
      }

      form.chargezen-form button.chargezen-button{
        background: ${formData?.button_background_color} !important;
        border-color: ${formData?.button_border_color} !important;
        color:  ${formData?.button_text_color} !important;
      }

      #chargezen_gift_card_container #chargezen-modal {
        background-image: url(${getImageUrl('background_image')}) !important;
      }
      `
    );
  }, [formData, currentForm]);

  return (
    <div className="rewards_html_container">
      <div id="chargezen_gift_card_container">
        <div id="chargezen-modal" class="">
          <div class="close-btn">
            <svg
              height="15"
              width="15"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
            >
              <line
                x1="1"
                y1="11"
                x2="11"
                y2="1"
                stroke="black"
                stroke-width="2"
              ></line>
              <line
                x1="1"
                y1="1"
                x2="11"
                y2="11"
                stroke="black"
                stroke-width="2"
              ></line>
            </svg>
          </div>
          <form class="chargezen-form">
            <div class="chargezen-gallery">
              <div class="chargezen-carousel-container">
                <div class="image-container">
                  <div style={{ right: '0%' }} class="chargezen-img-container">
                    <img
                      id="currentImage"
                      src={getImageUrl('gift_card_image') || defaultGiftCard}
                      alt="giftcard-image-1"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="chargezen-form-content">
              <div className="content_blocks_wrapper">
                {contentBlocks?.map((content) => {
                  return (
                    <>
                      {content.content_type == 'Headline' ? (
                        content.headline_size?.toLowerCase() == 'h1' ? (
                          <h1 class="chargezen-heading">
                            {content.headline_text}
                          </h1>
                        ) : content.headline_size?.toLowerCase() == 'h2' ? (
                          <h2 class="chargezen-heading">
                            {content.headline_text}
                          </h2>
                        ) : (
                          <h3 class="chargezen-heading">
                            {content.headline_text}
                          </h3>
                        )
                      ) : content.content_type == 'Checkbox' ? (
                        <div class="chargezen-field chargezen-toggle chargezen_gift_card_label">
                          <label for="" class="chargezen_gift_card_label">
                            {content.checkbox_text}
                          </label>
                          <div class="chargezen-toggle-disclaimer" tabindex="0">
                            <span>{content.checkbox_description}</span>
                            <input
                              type="checkbox"
                              style={{ display: 'none' }}
                            />
                            <button role="switch"></button>
                          </div>
                        </div>
                      ) : content.content_type == 'Paragraph' ? (
                        <>
                          <div class="chargezen-custom_paragraph" tabindex="0">
                            <p>{content.paragraph}</p>
                          </div>
                        </>
                      ) : content.content_type == 'Button' ? (
                        <>
                          <a
                            href={content.button_link}
                            target="_blank"
                            className="gift_card_custom_button"
                          >
                            {content.button_text}
                          </a>
                        </>
                      ) : content.content_type == 'Spacer' ? (
                        <div
                          className="spacer_wapper"
                          style={{ height: `${content.spacer_size}px` }}
                        ></div>
                      ) : (
                        <></>
                      )}
                    </>
                  );
                })}
              </div>
              <div class="chargezen-field chargezen-recipient-name chargezen_gift_card_label">
                <label for="Recipient Name" class="chargezen_gift_card_label">
                  {formData?.recipient_name_label}
                </label>
                <input
                  id="Recipient Name"
                  label="Recipient’s Name"
                  name="Recipient Name"
                  type="text"
                  required=""
                  placeholder=""
                  class="chargezen_gift_card_label"
                />
              </div>
              <div class="chargezen-field chargezen-recipient-email chargezen_gift_card_label">
                <label for="Recipient Email" class="chargezen_gift_card_label">
                  {formData?.recipient_email_label}
                </label>
                <input
                  id="Recipient Email"
                  label="Recipient’s Email"
                  name="Recipient Email"
                  type="email"
                  required=""
                  placeholder=""
                  class="chargezen_gift_card_label"
                />
              </div>
              <div class="chargezen-field chargezen-delivery-date chargezen_gift_card_label">
                <label for="Delivery Date" class="chargezen_gift_card_label">
                  {formData?.delivery_date_label}
                </label>
                <input
                  id="Recipient Date"
                  label="Recipient’s Date"
                  name="Recipient Date"
                  type="text"
                  required=""
                  placeholder=""
                  class="chargezen_gift_card_label"
                />
              </div>
              <div class="chargezen-field chargezen-gift-note chargezen_gift_card_label">
                <label for="Gift Note" class="chargezen_gift_card_label">
                  {formData?.gift_note_label}
                </label>
                <textarea
                  id="Gift Note"
                  label="Gift Note (Optional)"
                  name="Gift Note"
                  type="textarea"
                  placeholder="Enjoy this gift card!"
                  rows="3"
                  class="chargezen_gift_card_label"
                ></textarea>
              </div>
              <button
                type="button"
                class="chargezen-button btn product__submit btn--action btn--full btn--magnetic-hover-disabled"
              >
                {formData?.add_to_cart_label}
              </button>
            </div>
          </form>
        </div>
      </div>

      <style dangerouslySetInnerHTML={{ __html: dynamicStyle }} />
    </div>
  );
};
export default GiftCardHtml;
