import React, { useEffect, useState } from 'react';
import {
  MobileBackArrowMajor,
  MobileChevronMajor,
  MobileMajor,
  DesktopMajor,
} from '@shopify/polaris-icons';

import { Icon, Modal } from '@shopify/polaris';

const PreviewUpsell = ({ domain, previewTemplate }) => {
  const [mobileView, setMobileView] = useState(false);
  const [contentBlock, setcontentBlock] = useState([]);
  const [formData, setFormData] = useState({});
  const [allVariants, setAllVariants] = useState([]);

  const fetchUpsell = () => {
    fetch(
      '/upsells/fetch_upsells?shopify_domain=' +
        domain +
        '&templateid=' +
        previewTemplate,
      {
        method: 'GET',
      }
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.settings) {
          setFormData(data.settings);
          setcontentBlock([...data.upsell_design_content]);
          setAllVariants([...data?.settings?.variants]);
        }
      });
  };

  useEffect(() => {
    fetchUpsell();
  }, []);

  return (
    <div className="Polaris-Card">
      <div class="main_header_content">
        <button class="back_button_pointer">
          <span class="icon">
            <Icon source={MobileBackArrowMajor} color="base" />
          </span>
          <span>Back</span>
        </button>
        <button
          className="changeDeskMobileView"
          onClick={() => {
            setMobileView(!mobileView);
          }}
        >
          <Icon source={mobileView ? DesktopMajor : MobileMajor} color="base" />
        </button>
      </div>
      <style
        dangerouslySetInnerHTML={{
          __html: `.Polaris-Modal-Dialog__Modal{max-width: 80vw!important}`,
        }}
      />
    </div>
  );
};
export default PreviewUpsell;
