import { gql, useLazyQuery } from '@apollo/client';
import { Button, Checkbox, TextField } from '@shopify/polaris';
import React, { useEffect, useState } from 'react';
import './style.css';

const AssignAffiliatesLink = (props) => {
  const {
    affiliateLink,
    setAffiliateLink,
    formData,
    setFormData,
    selectedAffiliate,
    setSelectedAffiliate,
  } = props;
  const [affiliate, setAffiliate] = useState();

  useEffect(() => {
    if (affiliateLink || affiliateLink === 0) {
      setAffiliate(formData?.assoc_affiliates[affiliateLink]);
    }
  }, [affiliateLink]);

  const closeModal = () => {
    setAffiliateLink();
  };

  const handleAffiliateLink = (e) => {
    let allAffiliates = formData?.assoc_affiliates?.map((a) => a);
    allAffiliates[affiliateLink].affiliate_link = e;
    setFormData({ ...formData, assoc_affiliates: [...allAffiliates] });
  };

  return (
    <div className="chargezen_search_model">
      <div className="modal-content-wrapper">
        <div className="chargezen_search_model_header_container">
          <h2>Destination url of {affiliate?.email} affiliate link</h2>
        </div>
        <div className="chargezen_search_model_body">
          <TextField
            value={affiliate?.affiliate_link}
            label=""
            placeholder="Enter link"
            onChange={(e) => {
              handleAffiliateLink(e);
            }}
          />
        </div>
        <div className="chargezen_search_model_footer">
          <Button onClick={() => closeModal()}>Cancel</Button>
          <Button
            onClick={() => {
              closeModal();
            }}
          >
            Set Link
          </Button>
        </div>
      </div>
    </div>
  );
};

export default AssignAffiliatesLink;
