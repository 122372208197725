import { Card } from '@shopify/polaris';
import React, { useState } from 'react';
import ContentBlock from './ContentBlock';

const FirstLevelContentManager = (props) => {
  const {
    domain,
    formData,
    setFormData,
    contentBlocks,
    setContentBlocks,
    currentForm,
    setCurrentForm,
    currentContentBlock,
    addContent,
    setAddContent,
    setCurrentContentBlock,
    i18n,
    headerButton,
  } = props;
  return (
    <>
      {currentForm === 'Welcome' ? (
        <ContentBlock
          domain={domain}
          formData={formData}
          setFormData={setFormData}
          contentBlocks={contentBlocks}
          setContentBlocks={setContentBlocks}
          addContent={addContent}
          setAddContent={setAddContent}
          currentContentBlock={currentContentBlock}
          setCurrentContentBlock={setCurrentContentBlock}
          i18n={i18n}
          section={'welcome'}
        />
      ) : currentForm === 'Welcome' ? (
        <ContentBlock
          domain={domain}
          formData={formData}
          setFormData={setFormData}
          contentBlocks={contentBlocks}
          setContentBlocks={setContentBlocks}
          addContent={addContent}
          setAddContent={setAddContent}
          currentContentBlock={currentContentBlock}
          setCurrentContentBlock={setCurrentContentBlock}
          i18n={i18n}
          section={'howToEarn'}
        />
      ) : (
        <ul className="polaris-card">
          {headerButton === 'Body' ? (
            <>
              <li
                onClick={() => {
                  setCurrentForm('Welcome');
                }}
              >
                <svg
                  width="26"
                  height="26"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M8.588 2.051c-2.056-.259-3.68.458-5 2.23-2.847 3.823-1.715 9.71 2.223 12.064 4.02 2.404 9.32.946 11.563-3.28.536-1.01.729-2.11.575-3.326-.146-1.153-1.173-1.957-2.284-1.812-.12.015-.237.041-.352.078l-.082.026a3.092 3.092 0 01-3.893-2.033 3.124 3.124 0 01-.142-.93c0-1.54-1.124-2.83-2.608-3.017zm.25-1.984c2.49.313 4.358 2.458 4.358 5.001 0 .114.017.226.051.335.182.584.797.908 1.374.724l.082-.027c.23-.073.465-.126.704-.157 2.216-.288 4.242 1.3 4.526 3.545.205 1.619-.06 3.134-.793 4.515-2.816 5.304-9.42 7.01-14.355 4.059-4.914-2.94-6.384-10.164-2.8-14.975C3.727.746 6.054-.283 8.837.067zM6.833 6.929a1.5 1.5 0 110-3 1.5 1.5 0 010 3zM4.5 11a1.5 1.5 0 110-3 1.5 1.5 0 010 3zm3 4a1.5 1.5 0 110-3 1.5 1.5 0 010 3zm6.25-1a1.75 1.75 0 110-3.5 1.75 1.75 0 010 3.5z"
                    fill="#637381"
                    fill-rule="nonzero"
                  ></path>
                </svg>
                <span className="left_section">Hero</span>
              </li>
              <li
                onClick={() => {
                  setCurrentForm('contentTab');
                }}
              >
                <svg
                  width="26"
                  height="26"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g fill="none" fill-rule="evenodd">
                    <path
                      d="M16.515 11H13a1 1 0 010-2h3.414L15.27 7.935l1.363-1.463 3.048 2.838a1 1 0 01.003 1.461l-3.029 2.839-1.367-1.46L16.515 11zm-12.98 0l1.19 1.114-1.368 1.46-3.029-2.838a1 1 0 01.003-1.462l3.048-2.838L4.742 7.9 3.56 9H7a1 1 0 110 2H3.535zM9 16.497V13a1 1 0 012 0v3.43l1.06-1.154 1.473 1.352-2.796 3.048a1 1 0 01-1.462.013l-2.88-3.029 1.45-1.378L9 16.497zm2-12.994V7a1 1 0 01-2 0V3.57L7.94 4.723 6.467 3.372 9.263.324a1 1 0 011.462-.013l2.88 3.029-1.45 1.378L11 3.503z"
                      fill="#637381"
                      fill-rule="nonzero"
                    ></path>
                  </g>
                </svg>
                <span className="left_section">Content</span>
              </li>
              <li
                onClick={() => {
                  setCurrentForm('formTab');
                }}
              >
                <svg
                  width="26"
                  height="26"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10 17c-3.69 0-6.974-2.19-9.83-6.442a1 1 0 010-1.116C3.026 5.19 6.31 3 10 3c3.69 0 6.974 2.19 9.83 6.442a1 1 0 010 1.116C16.974 14.81 13.69 17 10 17zm0-2c2.788 0 5.376-1.63 7.784-5C15.376 6.63 12.788 5 10 5c-2.788 0-5.376 1.63-7.784 5 2.408 3.37 4.996 5 7.784 5zm0-2a3 3 0 110-6 3 3 0 010 6z"
                    fill="#637381"
                    fill-rule="nonzero"
                  ></path>
                </svg>
                <span className="left_section">Form</span>
              </li>
              <li
                onClick={() => {
                  setCurrentForm('buttonTab');
                }}
              >
                <svg
                  width="26"
                  height="26"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10 17c-3.69 0-6.974-2.19-9.83-6.442a1 1 0 010-1.116C3.026 5.19 6.31 3 10 3c3.69 0 6.974 2.19 9.83 6.442a1 1 0 010 1.116C16.974 14.81 13.69 17 10 17zm0-2c2.788 0 5.376-1.63 7.784-5C15.376 6.63 12.788 5 10 5c-2.788 0-5.376 1.63-7.784 5 2.408 3.37 4.996 5 7.784 5zm0-2a3 3 0 110-6 3 3 0 010 6z"
                    fill="#637381"
                    fill-rule="nonzero"
                  ></path>
                </svg>
                <span className="left_section">Button</span>
              </li>
            </>
          ) : (
            <></>
          )}
        </ul>
      )}
    </>
  );
};

export default FirstLevelContentManager;
