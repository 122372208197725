import { gql, useLazyQuery } from '@apollo/client';
import { Button, Checkbox, TextField } from '@shopify/polaris';
import React, { useCallback, useEffect, useState } from 'react';

const SearchCollectionModal = (props) => {
  const {
    showCollectionModal,
    setShowCollectionModal,
    query,
    setQuery,
    setFormData,
    formData,
    fieldName,
  } = props;
  const [allCollections, setAllCollections] = useState([]);
  const [selectedCollection, setSelectedCollection] = useState([]);

  const closeModal = () => {
    setShowCollectionModal();
  };

  const GET_COLLECTIONS = gql`
    query ($query: String!) {
      collections(first: 20, query: $query) {
        edges {
          node {
            id
            title
            image {
              url
            }
            metafields(first: 30) {
              edges {
                node {
                  type
                  key
                  value
                  type
                }
              }
            }
          }
        }
      }
    }
  `;

  const [
    AllCollections,
    { loading: productloading, data: collectionData, errors: collectionErrors },
  ] = useLazyQuery(GET_COLLECTIONS, {
    fetchPolicy: 'no-cache',
    context: { clientName: 'shopify-link' },
  });

  useEffect(() => {
    // if (values.productVariant.length > 0) {
    //   setSelectedCollection([...values.productVariant]);
    // }
    AllCollections({ variables: { query: `` } });
  }, []);

  const handleChange = (e) => {
    setQuery(e);
    let toQuery = e ? `title:*${e}*` : '';
    AllCollections({ variables: { query: toQuery } });
  };

  const checkIfAllVariantsSelected = (pd) => {
    let product = selectedCollection.find((p) => p.id === pd.id);
    if (product) {
      let selectedVariants = product.variants.filter((v) => v.selected);
      return +pd.variants.length === +selectedVariants.length;
    } else {
      return false;
    }
  };

  useEffect(() => {
    var result = [];
    if (collectionData?.collections?.edges?.length > 0) {
      collectionData.collections.edges.map((collection) => {
        collection = collection.node;
        result.push({
          title: collection.title,
          id: collection.id,
          image: collection.image?.url,
          metafields: collection.metafields,
        });
      });
    }
    setAllCollections([...result]);
  }, [collectionData]);

  const selectCollection = (e, prod) => {
    setSelectedCollection([...[prod]]);
  };

  const checkCollection = (prod) => {
    return selectedCollection[0]?.id === prod.id;
  };

  const handleSubmit = () => {
    setFormData({ ...formData, [fieldName]: selectedCollection[0] });
    closeModal();
  };

  return (
    <div className="chargezen_search_model product_variant_picker">
      <div className="modal-content-wrapper">
        <div className="chargezen_search_model_header_container">
          <h2>Select collection</h2>
        </div>
        <div className="chargezen_search_model_body">
          <TextField
            value={query}
            label=""
            placeholder="Search  for a collection"
            onChange={(e) => {
              handleChange(e);
            }}
          />
          <div class="segments_list_wrapper_container">
            <ul class="segments_lists">
              {allCollections?.map((coll) => {
                return (
                  <li>
                    <Checkbox
                      label={
                        <div className="productWrapper">
                          <span className="imgwrapper">
                            <img src={coll.image} />
                          </span>
                          <span>{coll.title}</span>
                        </div>
                      }
                      checked={checkCollection(coll)}
                      onChange={(e) => {
                        selectCollection(e, coll);
                      }}
                    />
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
        <div className="chargezen_search_model_footer justify-between">
          <div className="chargezen_addToCart">
            {selectedCollection.length === 1
              ? '1 collection selected'
              : selectedCollection.length > 1
              ? `${selectedCollection.length} collections selected`
              : ''}
          </div>
          <div className="action_buttons">
            <Button onClick={() => closeModal()}>Cancel</Button>
            <Button
              onClick={() => {
                handleSubmit();
              }}
            >
              Select
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SearchCollectionModal;
