import React, { useEffect } from 'react';
import { useState, useCallback } from 'react';
import { Page, Card, DataTable, Spinner } from '@shopify/polaris';
import { SearchMinor } from '@shopify/polaris-icons';

import { gql, useMutation, useQuery } from '@apollo/client';

function SellingPlans({ setSelectedSellingPlan, selectedSellingPlan, i18n }) {
  const GET_SELLING_PLANS = gql`
    query {
      fetchPlanGroups {
        id
        name
        subscriptionModel
        price
        trialPeriod
        billingPeriod
        active
        planType
        shopifyId
        sellingPlans {
          id
          name
          shopifyId
          intervalType
          intervalCount
          trialIntervalType
          trialIntervalCount
        }
      }
    }
  `;
  const { data, loading, error } = useQuery(GET_SELLING_PLANS, {
    fetchPolicy: 'no-cache',
  });
  const rows = [];

  const handleRowClick = (shopify_id) => {
    setSelectedSellingPlan(shopify_id);
  };

  data?.fetchPlanGroups?.map((planGroup) => {
    planGroup?.sellingPlans?.map((sellingPlan) => {
      rows.push([
        <div
          className={
            selectedSellingPlan == sellingPlan?.shopifyId ? 'selected_row' : ''
          }
          onClick={() => handleRowClick(sellingPlan?.shopifyId)}
        >
          {sellingPlan.name}
        </div>,
        <div
          className={
            selectedSellingPlan == sellingPlan?.shopifyId ? 'selected_row' : ''
          }
          onClick={() => handleRowClick(sellingPlan?.shopifyId)}
        >
          {planGroup.billingPeriod}
        </div>,
        <div
          className={
            selectedSellingPlan == sellingPlan?.shopifyId ? 'selected_row' : ''
          }
          onClick={() => handleRowClick(sellingPlan?.shopifyId)}
        >
          {planGroup.price}
        </div>,
        <div
          className={
            selectedSellingPlan == sellingPlan?.shopifyId ? 'selected_row' : ''
          }
          onClick={() => handleRowClick(sellingPlan?.shopifyId)}
        >
          {planGroup.subscriptionModel}
        </div>,
        <div
          className={
            selectedSellingPlan == sellingPlan?.shopifyId ? 'selected_row' : ''
          }
          onClick={() => handleRowClick(sellingPlan?.shopifyId)}
        >
          {planGroup.trialPeriod}
        </div>,
      ]);
    });
  });

  return (
    <>
      <div
        class="Polaris-Page-Header Polaris-Page-Header--isSingleRow Polaris-Page-Header--noBreadcrumbs Polaris-Page-Header--mediumTitle"
        style={{ marginBottom: '20px' }}
      >
        <div class="Polaris-Page-Header__MainContent">
          <div class="Polaris-Page-Header__TitleActionMenuWrapper">
            <div class="Polaris-Page-Header__TitleWrapper">
              <div>
                <div class="Polaris-Header-Title__TitleAndSubtitleWrapper">
                  <div class="Polaris-Header-Title">
                    <h1 class="Polaris-DisplayText Polaris-DisplayText--sizeLarge">
                      <span class="Polaris-TextStyle--variationStrong">
                        {i18n.t('select_selling_plans')}
                      </span>
                    </h1>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Card>
        <DataTable
          columnContentTypes={['text', 'text', 'text', 'text', 'text']}
          headings={[
            'Plans',
            'Billing Period',
            'Price',
            'Subscription  Model',
            'Trial Period',
          ]}
          rows={rows}
          sortable={[false, false, true, false, false, false]}
          defaultSortDirection="descending"
          initialSortColumnIndex={1}
        />
        {loading && (
          <Spinner
            accessibilityLabel="Spinner example"
            size="large"
            color="teal"
          />
        )}
      </Card>
    </>
  );
}

export default SellingPlans;
