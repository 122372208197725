import React, { useEffect, useState } from 'react';
import { gql, useQuery } from '@apollo/client';
import { Button, ButtonGroup, Card, Icon } from '@shopify/polaris';
import {
  ChevronRightMinor,
  MobileBackArrowMajor,
} from '@shopify/polaris-icons';
import PixelIcon from '../../images/PixelIcon';

const AffiliateStatus = (props) => {
  const { domain, i18n, detailView, setDetailView, setHeaderTitle } = props;
  const [affiliateStatus, setAffiliateStatus] = useState(false);

  const GET_DATA = gql`
    query {
      fetchAffilate {
        affiliateStatus
      }
    }
  `;

  const { data, loading, error, refetch } = useQuery(GET_DATA, {
    fetchPolicy: 'no-cache',
  });

  useEffect(() => {
    refetch();
  }, []);

  useEffect(() => {
    setHeaderTitle('Affiliate Program status');
  }, []);

  useEffect(() => {
    if (data?.fetchAffilate?.affiliateStatus) {
      setAffiliateStatus(data?.fetchAffilate?.affiliateStatus);
    }
  }, [data]);

  console.log('affiliateStatus', data, affiliateStatus);

  useEffect(() => {
    fetch('/toggleAffiliateStatus', {
      method: 'POST',
      body: JSON.stringify({
        active: affiliateStatus,
        shopify_domain: domain,
      }),
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }, [affiliateStatus]);

  return (
    <div className="without_header_card">
      <Card>
        <Card.Section>
          <div className="card_body_wrapper">
            <div className="content_wrapper">
              <div className="title">
                {!affiliateStatus
                  ? 'Affiliate Program is deactivated'
                  : 'Affiliate Program is activated'}
              </div>
              <div
                className={`programStatusButtonWrapper ${
                  affiliateStatus ? 'deactivate' : 'activate'
                }`}
              >
                <Button
                  onClick={() => {
                    setAffiliateStatus(!affiliateStatus);
                  }}
                >
                  {affiliateStatus
                    ? i18n.t('points_programs.deactivate_program')
                    : i18n.t('points_programs.activate_program')}
                </Button>
              </div>
            </div>
          </div>
        </Card.Section>
      </Card>
    </div>
  );
};

export default AffiliateStatus;
