import React, { useCallback, useContext, useEffect, useState } from 'react';
import { gql, useMutation, useQuery } from '@apollo/client';
import {
  Badge,
  Banner,
  Button,
  ButtonGroup,
  Caption,
  Card,
  Checkbox,
  ChoiceList,
  DataTable,
  DatePicker,
  DropZone,
  Filters,
  Frame,
  Icon,
  List,
  Modal,
  Page,
  RadioButton,
  Select,
  Spinner,
  Stack,
  Tabs,
  TextField,
  Thumbnail,
  Toast,
} from '@shopify/polaris';
import {
  DeleteMajor,
  NoteMinor,
  ChevronRightMinor,
  MobileBackArrowMajor,
} from '@shopify/polaris-icons';
import Papa from 'papaparse';
import { CSVLink } from 'react-csv';
import swapIcon from '../../../assets/images/icons/swap.svg';

import { Pagination } from '@shopify/polaris';
import HeaderButtons from '../HeaderButtons/HeaderButtons';
import PixelIcon from '../../images/PixelIcon';

const RenderContracts = (props) => {
  const {
    domain,
    shopifyDomain,
    customers,
    i18n,
    detailView,
    setDetailView,
    saveSuccess,
    hideSaveSuccess,
    headerButtons,
    setHeaderButton,
    headerButton,
    dataSelected,
    filterCustomers,
    json2csvParser,
    selectedTab,
    summaryFilterCustomers,
    formatRows,
    loading,
    hasPrevious,
    hasMore,
    setPage,
    queryValue,
    filters,
    appliedFilters,
    handleFiltersQueryChange,
    handleQueryValueRemove,
    handleFiltersClearAll,
    sortOrder,
    setSortOrder,
    setFilterCustomers,
  } = props;

  console.log('filterCustomersfilterCustomers', filterCustomers);
  console.log(
    'summaryFilterCustomerssummaryFilterCustomers',
    summaryFilterCustomers
  );

  const handleSort = () => {
    let contractsArr = customers?.map((c) => c);
    if (sortOrder === 1) {
      setSortOrder(0);
      contractsArr = contractsArr.sort(
        (a, b) => new Date(b.updatedAt) - new Date(a.updatedAt)
      );
    } else {
      setSortOrder(1);
      contractsArr = contractsArr.sort(
        (a, b) => new Date(a.updatedAt) - new Date(b.updatedAt)
      );
    }
    setFilterCustomers([...contractsArr]);
  };

  useEffect(() => {
    if (customers.length > 0) {
      handleSort();
    }
  }, [customers]);

  return (
    <>
      <Frame>
        {saveSuccess && (
          <Toast
            content={i18n.t('saved_successfully')}
            onDismiss={hideSaveSuccess}
          />
        )}
        <div className="without_header_card">
          <Card
            // title={
            //   <div className="heading_title">
            //     <PixelIcon />
            //     Subscriptions Contracts
            //     <div class="card_title_desc">
            //       view details & manage subscription contracts for each customer
            //       on your brand.
            //     </div>
            //   </div>
            // }
            actions={{
              content: (
                <div className="card_header_button_group">
                  <div className="card_header_buttons primary_button_group">
                    <HeaderButtons
                      headerButtons={headerButtons}
                      setHeaderButton={setHeaderButton}
                      headerButton={headerButton}
                    />
                  </div>
                  {/* <div className="card_header_buttons secondary_button_group">
                    <Button
                      onClick={() => {
                        handleSort();
                      }}
                    >
                      {dataSelected.length > 0 || filterCustomers.length > 0 ? (
                        <CSVLink
                          data={
                            dataSelected.length > 0
                              ? json2csvParser.parse(dataSelected)
                              : json2csvParser.parse(filterCustomers)
                          }
                          filename={'customers.csv'}
                        >
                          Export
                        </CSVLink>
                      ) : (
                        'Export'
                      )}
                    </Button>
                    <Button
                      onClick={() => {
                        return;
                      }}
                    >
                      Import
                    </Button>
                  </div> */}
                </div>
              ),
            }}
          >
            <Card.Section>
              <div
                className="filter custom_filter_main"
                style={{
                  display: 'flex',
                  width: '100%',
                }}
              >
                <Filters
                  queryValue={queryValue}
                  filters={filters}
                  appliedFilters={appliedFilters}
                  onQueryChange={handleFiltersQueryChange}
                  onQueryClear={handleQueryValueRemove}
                  onClearAll={handleFiltersClearAll}
                />
                <span className="separate"></span>
                <div className="btn-sort">
                  <Button onClick={() => { }}>
                    <img src={swapIcon} />
                    Sort By last update (Newest First)
                  </Button>
                </div>
              </div>
              <div
                className={
                  'table customer-subscription-tbl' + ' ' + selectedTab
                }
              >
                <DataTable
                  columnContentTypes={[
                    'text',
                    'text',
                    'text',
                    'text',
                    'text',
                    'text',
                    'text',
                    'text',
                    'text',
                  ]}
                  headings={[
                    'Id',
                    'Name',
                    'Product',
                    'Plan',
                    'Status',
                    'Next Billing Date',
                    'Date Created',
                    'Source',
                    'Price',
                  ]}
                  rows={
                    filterCustomers?.length > 0
                      ? formatRows(filterCustomers)
                      : []
                  }
                />
              </div>
              {loading && <Spinner />}
              <div
                style={{
                  width: '100%',
                  justifyContent: 'center',
                  display: 'flex',
                }}
              >
                <Pagination
                  hasPrevious={hasPrevious}
                  onPrevious={() => {
                    setPage((prev) => +prev - 1);
                  }}
                  hasNext={hasMore}
                  onNext={() => {
                    setPage((prev) => +prev + 1);
                  }}
                />
              </div>
            </Card.Section>
          </Card>
        </div>
      </Frame>
    </>
  );
};

export default RenderContracts;
