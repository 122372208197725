import { Icon } from '@shopify/polaris';
import { BalanceMajor } from '@shopify/polaris-icons';
import { ReferralMajor } from '@shopify/polaris-icons';
import { CashDollarMajor } from '@shopify/polaris-icons';
import { ImageMajor, MobileBackArrowMajor,StoreStatusMajor } from '@shopify/polaris-icons';
import React from 'react'

const SummaryComponent = (props) => {
    const {i18n, summaryView, setSummaryView} = props;
    return (
        <div className='summary_side_nav_container'>
            <div className='summary_list_wrapper'>
                <ul className='summary_list'>
                    <li className={`list_item ${summaryView == "contracts" ? 'active' : ""}`} onClick={()=>{setSummaryView("contracts")}}>
                        <div className='icon_wrapper'>
                            <Icon
                                source={StoreStatusMajor}
                                color="base" 
                            />
                        </div>
                        <div className='list_title'>
                            Subscription Contracts
                        </div>
                    </li>
                    <li className={`list_item ${summaryView == "subscription_orders" ? 'active' : ""}`} onClick={()=>{setSummaryView("subscription_orders")}}>
                        <div className='icon_wrapper'>
                            <Icon
                                source={ImageMajor}
                                tone="base"
                            />
                        </div>
                        <div className='list_title'>
                            Subscription Orders
                        </div>
                    </li>
                    <li className={`list_item ${summaryView == "subscription_plans" ? 'active' : ""}`} onClick={()=>{setSummaryView("subscription_plans")}}>
                        <div className='icon_wrapper'>
                            <Icon
                                source={ReferralMajor}
                                color="base" 
                            />
                        </div>
                        <div className='list_title'>
                           Subscription Plans
                        </div>
                    </li>
                    <li className={`list_item ${summaryView == "subscription_products" ? 'active' : ""}`} onClick={()=>{setSummaryView("subscription_products")}}>
                        <div className='icon_wrapper'>
                            <Icon
                                source={CashDollarMajor}
                                color="base" 
                            />
                        </div>
                        <div className='list_title'>
                            Subscription Products
                        </div>
                    </li>
                    <li className={`list_item ${summaryView == "customerMigration" ? 'active' : ""}`} onClick={()=>{setSummaryView("customerMigration")}}>
                        <div className='icon_wrapper'>
                            <Icon
                                source={ReferralMajor}
                                color="base" 
                            />
                        </div>
                        <div className='list_title'>
                            Import/create subscriptions
                        </div>
                    </li>
                    <li className={`list_item ${summaryView == "subscription_billing" ? 'active' : ""}`} onClick={()=>{setSummaryView("subscription_billing")}}>
                        <div className='icon_wrapper'>
                            <Icon
                                source={ReferralMajor}
                                color="base" 
                            />
                        </div>
                        <div className='list_title'>
                            Dunning
                        </div>
                    </li>
                    <li className={`list_item ${summaryView == "subscription_email" ? 'active' : ""}`} onClick={()=>{setSummaryView("subscription_email")}}>
                        <div className='icon_wrapper'>
                            <Icon
                                source={BalanceMajor}
                                color="base" 
                            />
                        </div>
                        <div className='list_title'>
                            Subscription email
                        </div>
                    </li>
                    <li className={`list_item ${summaryView == "subscription_workflow" ? 'active' : ""}`} onClick={()=>{setSummaryView("subscription_workflow")}}>
                        <div className='icon_wrapper'>
                            <Icon
                                source={CashDollarMajor}
                                color="base" 
                            />
                        </div>
                        <div className='list_title'>
                            Workflow Automations
                        </div>
                    </li>
                    <li className={`list_item ${summaryView == "bulk_action" ? 'active' : ""}`} onClick={()=>{setSummaryView("bulk_action")}}>
                        <div className='icon_wrapper'>
                            <Icon
                                source={ReferralMajor}
                                color="base" 
                            />
                        </div>
                        <div className='list_title'>
                          Bulk Action
                        </div>
                    </li>
                    
                    <li className={`list_item ${summaryView == "global_billing_hour" ? 'active' : ""}`} onClick={()=>{setSummaryView("global_billing_hour")}}>
                        <div className='icon_wrapper'>
                            <Icon
                                source={ReferralMajor}
                                color="base" 
                            />
                        </div>
                        <div className='list_title'>
                           Global Settings
                        </div>
                    </li>
                    
                    
                    
                </ul>
            </div>
        </div>
    )
}

export default SummaryComponent;