import React from "react";
import ReactDOM from "react-dom";
import Container from "./Container.jsx";

import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

const DragAndDrop = (props) => {
    const { cards, setCards, i18n } = props
    return (
        <div className="drag_and_drop">

            <Container cards={cards} setCards={setCards} i18n={i18n} />
        </div>
    );
}
export default DragAndDrop
