import {
  Button,
  Frame,
  Icon,
  Navigation,
  Select,
  Tabs,
} from '@shopify/polaris';
import React, { useCallback, useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import './nav_style.scss';
import {
  HomeMajor,
  SettingsMinor,
  AnalyticsMajor,
  HintMajor,
  ProfileMajor,
  InstallMinor,
  CustomersMajor,
  GiftCardMajor,
  ChevronLeftMinor,
  LogOutMinor,
  BalanceMajor,
  ImageMajor,
  PointOfSaleMajor,
  EmailNewsletterMajor,
  ReferralMajor,
  CashDollarMajor,
  StoreStatusMajor,
} from '@shopify/polaris-icons';
import ToggleButton from 'react-toggle-button';
import {
  DashboardIcon,
  MangeIcon,
  SubscriptionIcon,
  ToolboxIcon,
  CrystalBall,
  GetStartedIcon,
  ZenBrainIcon,
  SubscriptionsIcon,
  ReplenishIcon,
  MoreIcon,
  SubscriptionContractsIcon,
  CustomersIcon,
  AnalyticsIcon,
  ZennPortalIcon,
  ThemeIcon,
  HelpdeskIcon,
  TiazenIcon,
  SmartyMessageIcon,
} from './CustomIcon';
import ChargeZenLogo from './../../images/ChargeZen-Logo2.png';
import CircleChevronMinor from './../../images/VectorIcon';
import useRouter from '../useRouterHook';
import mixpanel from 'mixpanel-browser';
import './Nav.css';

const Nav = (props) => {
  const {
    navCollapse,
    setNavCollapse,
    selected,
    i18n,
    mixpanelId,
    shopVersion,
    customNavTabs,
    setCustomNavTabs,
  } = props;
  const history = useHistory();
  const router = useRouter();
  const [navigations, setNavigations] = useState([]);
  const [activeTab, setActiveTab] = useState();
  const [loyality, setLoyality] = useState(false);
  const [toogleMenu, setToogleMenu] = useState({ revenueChannels: false });
  const [toggleNav, setToggleNav] = useState(false);

  useEffect(() => {
    setNavigation();
  }, [window.location.href, customNavTabs, activeTab]);

  const urlDomain = `/${props?.domain?.replace('.myshopify.com', '')}`;
  const [revenueMoreCond, setrevenueMoreCond] = useState(true);
  const [dynamicStyle, setDynamicStyle] = useState(``);

  useEffect(() => {
    let lineHeight = location.search?.includes('_subitems') ? 229 : 233;
    let style = `
      .navBar .sidBartabs nav.Polaris-Navigation .Polaris-Navigation__PrimaryNavigation ul.Polaris-Navigation__Section>li>div>div>ul>li.Polaris-Navigation__ListItem > .Polaris-Navigation__ItemWrapper + .Polaris-Navigation__SecondaryNavigation.Polaris-Navigation--isExpanded {
        padding-left: 40px;
      }
      
      .navBar .sidBartabs nav.Polaris-Navigation .Polaris-Navigation__PrimaryNavigation ul.Polaris-Navigation__Section>li>div>div>ul>li.Polaris-Navigation__ListItem > .Polaris-Navigation__ItemWrapper + .Polaris-Navigation__SecondaryNavigation.Polaris-Navigation--isExpanded ul.Polaris-Navigation__List li a.Polaris-Navigation--subNavigationActive:after {
          content: '';
          position: absolute;
          width: 2px;
          left: 9px;
          background: #b5b5b5;
          top: -227.5px;
          height: ${lineHeight}px;
          opacity: 1;
          visibility: visible;
          z-index: 99999;
          color: #000;
          opacity: 0.7;
      }

      .navBar .sidBartabs nav.Polaris-Navigation .Polaris-Navigation__PrimaryNavigation ul.Polaris-Navigation__Section>li>.Polaris-Navigation__SecondaryNavigation>div>ul.Polaris-Navigation__List>li.Polaris-Navigation__ListItem>.Polaris-Navigation__ItemWrapper>a.Polaris-Navigation__Item.Polaris-Navigation--subNavigationActive {
        background: #fafafa!important;
      }
      
      
      .navBar .sidBartabs nav.Polaris-Navigation .Polaris-Navigation__PrimaryNavigation ul.Polaris-Navigation__Section>li>div>div>ul>li.Polaris-Navigation__ListItem > .Polaris-Navigation__ItemWrapper + .Polaris-Navigation__SecondaryNavigation.Polaris-Navigation--isExpanded ul.Polaris-Navigation__List li a.Polaris-Navigation--subNavigationActive:before {
          content: '';
          position: absolute;
          width: 12px;
          height: 2px;
          background: #fff;
          left: -15px;
          top: 45%;
      }

      .module-navbar.dashboardArea .navBar nav.Polaris-Navigation ul.Polaris-Navigation__Section>li.Polaris-Navigation__ListItem>.Polaris-Navigation__SecondaryNavigation>div>ul.Polaris-Navigation__List>li.Polaris-Navigation__ListItem>.Polaris-Navigation__SecondaryNavigation>div>ul.Polaris-Navigation__List>li>div>a:before 
        {
          width: 20.99px;
          height: 25px;
          display: block;
          opacity: 1;
          visibility: 
          visible;
          z-index: 1;
          position: absolute;
          left: 0;
          background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='21' height='28' fill='none'%3E%3Cpath fill='%23B5B5B5' d='M19 14.25h.75v1.5H19v-1.5Zm-8.923-.888.668-.34-.668.34Zm1.311 1.311.34-.668-.34.668ZM10.5 0v10.2H9V0h1.5Zm4.05 14.25H19v1.5h-4.45v-1.5ZM10.5 10.2c0 .853 0 1.447.038 1.91.037.453.107.714.207.912l-1.336.68c-.227-.444-.321-.924-.366-1.47C9 11.694 9 11.027 9 10.2h1.5Zm4.05 5.55c-.828 0-1.494 0-2.032-.043-.546-.045-1.026-.14-1.47-.366l.68-1.336c.198.1.459.17.913.207.462.037 1.056.038 1.909.038v1.5Zm-3.805-2.729c.216.424.56.768.984.984l-.681 1.336a3.75 3.75 0 0 1-1.64-1.638l1.337-.681Z'/%3E%3Cpath stroke='%23B5B5B5' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='m17 12 3 3-3 3'/%3E%3C/svg%3E");
          content: "";
          transition: all 0.2s ease;
          opacity: 0;
          top: 0;
          border-radius: 5px 0 0 5px;
        }

      .module-navbar.dashboardArea .navBar nav.Polaris-Navigation ul.Polaris-Navigation__Section>li.Polaris-Navigation__ListItem>.Polaris-Navigation__SecondaryNavigation>div>ul.Polaris-Navigation__List>li.Polaris-Navigation__ListItem>.Polaris-Navigation__SecondaryNavigation>div>ul.Polaris-Navigation__List>li>div>a.Polaris-Navigation__Item {
          padding-left: 15px !important;
      }


      .module-navbar.dashboardArea .navBar nav.Polaris-Navigation ul.Polaris-Navigation__Section>li.Polaris-Navigation__ListItem>.Polaris-Navigation__SecondaryNavigation>div>ul.Polaris-Navigation__List>li.Polaris-Navigation__ListItem>.Polaris-Navigation__SecondaryNavigation>div>ul.Polaris-Navigation__List>li>div>a:hover:before {opacity: 1 !important;display: block !important;}

      .module-navbar.dashboardArea .navBar nav.Polaris-Navigation ul.Polaris-Navigation__Section>li.Polaris-Navigation__ListItem>.Polaris-Navigation__SecondaryNavigation>div>ul.Polaris-Navigation__List>li.Polaris-Navigation__ListItem>.Polaris-Navigation__SecondaryNavigation>div>ul.Polaris-Navigation__List>li>div>a.Polaris-Navigation__Item.Polaris-Navigation--subNavigationActive::before {
          opacity: 1;
      }

    `;
    setDynamicStyle(style);
  }, [location.search]);

  const ConditionalCss = () => {
    //May need to change the nth value if new nav tabs is added before more
    const style = `
    .navBar .sidBartabs nav.Polaris-Navigation .Polaris-Navigation__PrimaryNavigation ul.Polaris-Navigation__Section>li:nth-child(2)>.Polaris-Navigation__SecondaryNavigation>div#PolarisSecondaryNavigation7>ul.Polaris-Navigation__List>li.Polaris-Navigation__ListItem:last-child>.Polaris-Navigation__SecondaryNavigation>div#PolarisSecondaryNavigation12 {
      opacity: 0 !important;
      max-height: 0 !important;
      overflow: hidden !important;
    }
    .navBar .sidBartabs nav.Polaris-Navigation .Polaris-Navigation__PrimaryNavigation ul.Polaris-Navigation__Section>li:nth-child(2)>.Polaris-Navigation__SecondaryNavigation>div#PolarisSecondaryNavigation7>ul.Polaris-Navigation__List>li.Polaris-Navigation__ListItem:last-child>.Polaris-Navigation__ItemWrapper a {
      background: transparent !important;
        }
  `;

    return (
      <style
        dangerouslySetInnerHTML={{
          __html: style,
        }}
      />
    );
  };
  useEffect(() => {
    if (new URLSearchParams(location.search).get('revenue_more') == 'true') {
      setrevenueMoreCond(false);
    } else {
      setrevenueMoreCond(true);
    }
  }, [location.search]);

  const setNavigation = () => {
    const accessTabs = JSON.parse(localStorage.getItem('accessSettings'));
    let navigation = [];
    let subNavItems;
    let dashSub = [];
    if (accessTabs?.dashboard_access) {
      if (accessTabs?.dashboard_access) {
        dashSub.push({
          icon: DashboardIcon,
          label: i18n.t('dashboard'),
          url: '/',
          onClick: () => setActiveTab('dash'),
          selected: window.location.pathname == `${urlDomain}/` ? true : false,
        });
      }
      if (shopVersion != 'chargezen_lite') {
        dashSub.push(
          {
            icon: GetStartedIcon,
            label: 'Revenue Recipes',
            url: '/getStarted',
            onClick: () => setActiveTab('dash'),
            selected:
              window.location.pathname == `${urlDomain}/getStarted`
                ? true
                : false,
          },
          {
            url: `${window.location.pathname.replace(
              urlDomain,
              ''
            )}?zenbrain=true`,
            label: i18n.t('zenbrain'),
            icon: ZenBrainIcon,
            selected:
              new URLSearchParams(location.search).get('zenbrain') == 'true'
                ? true
                : false,
            subNavigationItems: [
              {
                // icon: CrystalBall,
                label: i18n.t('yoda_revenue_copilot'),
                url: '/YodaRevenueCopilot',
                onClick: () => setActiveTab('dash'),
                selected:
                  window.location.pathname == `${urlDomain}/YodaRevenueCopilot`
                    ? true
                    : false,
              },
              {
                // icon: AnalyticsMajor,
                label: i18n.t('customer_segments'),
                url: `/MLAlgorithms?corhorts_subitems=true`,
                onClick: () => setActiveTab('dash'),
                selected:
                  new URLSearchParams(location.search).get(
                    'corhorts_subitems'
                  ) == 'true'
                    ? true
                    : false,
                subNavigationItems: [
                  {
                    icon: MangeIcon,
                    label: i18n.t('sentiment_analysis'),
                    url: '/sentiment-analysis',
                    selected:
                      window.location.pathname ==
                        `${urlDomain}/sentiment-analysis`
                        ? true
                        : false,
                  },
                  {
                    label: i18n.t('k_means_clusters'),
                    icon: () => <MangeIcon />,
                    url: '/kMeansClusters',
                    onClick: () => setActiveTab('kMeansClusters'),
                    selected:
                      window.location.pathname == `${urlDomain}/kMeansClusters`
                        ? true
                        : false,
                  },
                  {
                    icon: MangeIcon,
                    label: i18n.t('anomaly_detection'),
                    url: '/anomaly-detection',
                    selected:
                      window.location.pathname ==
                        `${urlDomain}/anomaly-detection`
                        ? true
                        : false,
                  },
                  {
                    icon: MangeIcon,
                    label: i18n.t('market_basket_analysis'),
                    url: '/market-basket-analysis',
                    selected:
                      window.location.pathname ==
                        `${urlDomain}/market-basket-analysis`
                        ? true
                        : false,
                  },
                ],
              },
            ],
          }
        );
      }

      navigation.push({
        url: '',
        label: i18n.t('revenue'),
        disabled: true,
        selected: true,
        subNavigationItems: dashSub,
      });
    }
    if (activeTab === 'subscriptions') {
      subNavItems = [
        {
          icon: () => <SubscriptionContractsIcon />,
          label: 'Overview',
          url: '/customers',
          selected: window.location.pathname == `${urlDomain}/customers`,
        },
        {
          icon: StoreStatusMajor,
          label: 'Contracts',
          url: '/customersTab/contracts',
          selected:
            window.location.pathname == `${urlDomain}/customersTab/contracts`,
        },
        // {
        //   icon: ImageMajor,
        //   label: 'Orders',
        //   url: '/customersTab/subscription_orders',
        //   selected:
        //     window.location.pathname ==
        //     `${urlDomain}/customersTab/subscription_orders`,
        // },
      ];
      if (accessTabs?.manage_plan_access) {
        subNavItems.push({
          icon: ReferralMajor,
          label: i18n.t('subscription_plans'),
          url: '/customersTab/subscription_plans',
          selected:
            window.location.pathname.includes(
              `${urlDomain}/customersTab/subscription_plans`
            ) ||
              window.location.pathname.includes(
                `${urlDomain}/fixed-subscription-plans/`
              )
              ? true
              : false,
        });
      }
      subNavItems.push(
        {
          icon: CashDollarMajor,
          label: 'Products',
          url: '/customersTab/subscription_products',
          disabled: true,
          selected:
            window.location.pathname ==
            `${urlDomain}/customersTab/subscription_products`,
        },
        {
          icon: ImageMajor,
          label: 'Bundles',
          url: '/customersTab/subscriptionBundles',
          selected:
            window.location.pathname ==
            `${urlDomain}/customersTab/subscriptionBundles`,
        },
        {
          icon: ReferralMajor,
          label: 'Create & import subscriptions',
          url: '/customersTab/customerMigration',
          disabled: true,
          selected:
            window.location.pathname ==
              `${urlDomain}/customersTab/customerMigration`
              ? true
              : false,
        },
        {
          icon: BalanceMajor,
          label: 'Emails & SMS',
          url: '/customersTab/subscription_email',
          disabled: true,
          selected:
            window.location.pathname ==
            `${urlDomain}/customersTab/subscription_email`,
        },
        {
          icon: () => <SubscriptionContractsIcon />,
          label: 'Quick actions ',
          url: '/customersTab/quickActions',
          selected: window.location.pathname.includes(
            '/customersTab/quickActions'
          ),
        },
        {
          icon: CashDollarMajor,
          label: 'Workflow automations',
          url: '/customersTab/subscription_workflow',
          selected:
            window.location.pathname ==
              `${urlDomain}/customersTab/subscription_workflow`
              ? true
              : false,
        },
        {
          icon: ReferralMajor,
          label: 'Bulk actions',
          url: '/customersTab/bulk_action',
          selected:
            window.location.pathname == `${urlDomain}/customersTab/bulk_action`
              ? true
              : false,
        },
        {
          icon: ReferralMajor,
          label: 'Dunning',
          url: '/customersTab/subscription_billing',
          disabled: true,
          selected:
            window.location.pathname ==
              `${urlDomain}/customersTab/subscription_billing`
              ? true
              : false,
        },

        {
          icon: ReferralMajor,
          label: 'Settings',
          url: '/customersTab/global_billing_hour',
          selected:
            window.location.pathname ==
              `${urlDomain}/customersTab/global_billing_hour`
              ? true
              : false,
        },
        {
          icon: ReferralMajor,
          label: 'Subscription widget',
          url: '/customersTab/subscription_widget',
          selected:
            window.location.pathname ==
            `${urlDomain}/customersTab/subscription_widget`
        },
      );

      navigation.push({
        url: '',
        label: (
          <div className="sub_nav_label">
            <Icon source={ChevronLeftMinor} tone="base" />
            <span>{i18n.t('subscriptions_home')}</span>
          </div>
        ),
        disabled: true,
        selected: true,
        subNavigationItems: subNavItems,
      });
    } else if (activeTab === 'pointsProgram') {
      if (customNavTabs?.loyalty_rewards) {
        subNavItems = [
          {
            icon: () => <SubscriptionContractsIcon />,
            label: 'Overview',
            url: '/pointsProgram',
            selected: window.location.pathname == `${urlDomain}/pointsProgram`,
          },
          {
            icon: StoreStatusMajor,
            url: '/pointsProgram/tooglePoints',
            disabled: false,
            selected:
              window.location.pathname ==
                `${urlDomain}/pointsProgram/tooglePoints`
                ? true
                : false,
            label: i18n.t('points_programs.program_status'),
          },
          {
            icon: ImageMajor,
            url: '/pointsProgram/pointsProgram',
            disabled: false,
            selected:
              window.location.pathname ==
                `${urlDomain}/pointsProgram/pointsProgram`
                ? true
                : false,
            label: i18n.t('points_programs.hero_image'),
          },
          {
            icon: CashDollarMajor,
            url: '/pointsProgram/waysToEarn',
            disabled: false,
            selected:
              window.location.pathname ==
                `${urlDomain}/pointsProgram/waysToEarn`
                ? true
                : false,
            label: i18n.t('points_programs.ways_to_earn_points'),
          },
          {
            icon: BalanceMajor,
            url: '/pointsProgram/waysToRedeem',
            disabled: false,
            selected:
              window.location.pathname ==
                `${urlDomain}/pointsProgram/waysToRedeem`
                ? true
                : false,
            label: i18n.t('points_programs.ways_to_redeem_points'),
          },
          {
            icon: ReferralMajor,
            url: '/pointsProgram/referral_rewards',
            disabled: false,
            selected:
              window.location.pathname ==
                `${urlDomain}/pointsProgram/referral_rewards`
                ? true
                : false,
            label: i18n.t('points_programs.referrals'),
          },
          customNavTabs?.ios_wallet_passes
            ? {
              icon: ReferralMajor,
              url: '/pointsProgram/appleWalletPasses',
              disabled: false,
              selected:
                window.location.pathname ==
                  `${urlDomain}/pointsProgram/appleWalletPasses`
                  ? true
                  : false,
              label: 'Apple Wallet Passess',
            }
            : {},
          {
            icon: PointOfSaleMajor,
            url: '/pointsProgram/point_program_tiers',
            disabled: false,
            selected:
              window.location.pathname ==
                `${urlDomain}/pointsProgram/point_program_tiers`
                ? true
                : false,
            label: i18n.t('points_programs.points_program_tiers'),
          },
          {
            icon: EmailNewsletterMajor,
            url: '/pointsProgram/points_emails',
            disabled: false,
            selected:
              window.location.pathname ==
                `${urlDomain}/pointsProgram/points_emails`
                ? true
                : false,
            label: i18n.t('points_programs.points_program_email'),
          },
          {
            icon: EmailNewsletterMajor,
            url: '/pointsProgram/rewardsPage?loyalty=true',
            disabled: false,
            label: i18n.t('rewards_page'),
            selected:
              window.location.pathname ==
                `${urlDomain}/pointsProgram/rewardsPage`
                ? true
                : false,
          },
          {
            icon: EmailNewsletterMajor,
            url: '/pointsProgram/ReferralPage?loyalty=true',
            disabled: false,
            label: 'Referral Page',
            selected:
              window.location.pathname ==
                `${urlDomain}/pointsProgram/ReferralPage`
                ? true
                : false,
          },
          {
            icon: EmailNewsletterMajor,
            url: '/pointsProgram/RewardsLauncherSettings?loyalty=true',
            disabled: false,
            label: 'Rewards Launcher',
            selected:
              window.location.pathname ==
                `${urlDomain}/pointsProgram/RewardsLauncherSettings`
                ? true
                : false,
          },
        ];
        navigation.push({
          label: (
            <div className="sub_nav_label">
              <Icon source={ChevronLeftMinor} tone="base" />
              <span>{i18n.t('loyalty_rewards')}</span>
            </div>
          ),
          url: '',
          selected: true,
          disabled: true,
          subNavigationItems: subNavItems,
        });
      }
    } else if (activeTab === 'affiliates') {
      subNavItems = [
        {
          icon: () => <SubscriptionContractsIcon />,
          label: 'Overview',
          url: '/affiliates',
          selected: window.location.pathname == `${urlDomain}/affiliates`,
        },
        // {
        //   icon: StoreStatusMajor,
        //   label: 'Affiliates Status',
        //   url: '/affiliates/affiliatesStatus',
        //   selected:
        //     window.location.pathname ===
        //     `${urlDomain}/affiliates/affiliatesStatus`,
        // },
        {
          icon: ImageMajor,
          label: 'Affiliates',
          url: '/affiliates/affiliates',
          selected:
            window.location.pathname === `${urlDomain}/affiliates/affiliates`,
        },
        {
          icon: ImageMajor,
          label: 'Commission Profiles',
          url: '/affiliates/affiliate_commission',
          selected:
            window.location.pathname ===
            `${urlDomain}/affiliates/affiliate_commission`,
        },
        {
          icon: ImageMajor,
          label: 'Affiliate Orders',
          url: '/affiliates/affiliateOrders',
          selected:
            window.location.pathname ===
            `${urlDomain}/affiliates/affiliateOrders`,
        },
        // {
        //   icon: MangeIcon,
        //   label: 'Affiliates Payouts',
        //   url: '/affiliates/affiliatesPayouts',
        //   selected:
        //     window.location.pathname ===
        //     `${urlDomain}/affiliates/affiliatesPayouts`,
        // },
        // {
        //   // icon: MangeIcon,
        //   label: 'Affiliate Page',
        //   url: '/affiliates/affiliatePage',
        //   selected:
        //     window.location.pathname ===
        //     `${urlDomain}/affiliates/affiliatePage`,
        // },
        // {
        //   // icon: MangeIcon,
        //   label: 'Affiliates Home',
        //   url: '/affiliates/affiliatesHome',
        //   selected:
        //     window.location.pathname ===
        //     `${urlDomain}/affiliates/affiliatesHome`,
        // },
        // {
        //   // icon: MangeIcon,
        //   label: 'Analytics',
        //   url: '/affiliates/analytics',
        //   selected:
        //     window.location.pathname === `${urlDomain}/affiliates/analytics`,
        // },
        // {
        //   // icon: MangeIcon,
        //   label: 'Affiliate Program Setting',
        //   url: '/affiliates/affiliateProgramSetting',
        //   selected:
        //     window.location.pathname ===
        //     `${urlDomain}/affiliates/affiliateProgramSetting`,
        // },
      ];
      navigation.push({
        url: '',
        label: (
          <div className="sub_nav_label">
            <Icon source={ChevronLeftMinor} tone="base" />
            <span>Affiliates & Influencers</span>
          </div>
        ),

        disabled: true,
        selected: true,
        subNavigationItems: subNavItems,
      });
    } else if (activeTab === 'upsells') {
      subNavItems = [
        {
          icon: () => <SubscriptionContractsIcon />,
          label: 'Overview',
          url: '/chargezenUpsells',
          selected: window.location.pathname == `${urlDomain}/chargezenUpsells`,
        },
        {
          icon: StoreStatusMajor,
          label: 'Upsell Funnels',
          url: '/chargezenUpsells/manage',
          selected:
            window.location.pathname === `${urlDomain}/chargezenUpsells/manage`,
        },
        {
          icon: StoreStatusMajor,
          label: 'Cart Funnels',
          url: '/chargezenUpsells/cartFunnels',
          selected:
            window.location.pathname === `${urlDomain}/chargezenUpsells/cartFunnels`,
        },
      ];
      navigation.push({
        url: '',
        label: (
          <div className="sub_nav_label">
            <Icon source={ChevronLeftMinor} tone="base" />
            <span>Upsells</span>
          </div>
        ),
        disabled: true,
        selected: true,
        subNavigationItems: subNavItems,
      });
    } else if (activeTab === 'checkout') {
      subNavItems = [
        {
          icon: () => <ThemeIcon />,
          label: 'Overview',
          url: '/checkout',
          selected: window.location.pathname == `${urlDomain}/checkout`,
        },
        {
          icon: StoreStatusMajor,
          label: 'Checkout extensions',
          url: '/checkoutStyle',
          selected: window.location.pathname === `${urlDomain}/checkoutStyle`,
        },
      ];
      navigation.push({
        url: '',
        label: (
          <div className="sub_nav_label">
            <Icon source={ChevronLeftMinor} tone="base" />
            <span>Checkout</span>
          </div>
        ),
        disabled: true,
        selected: true,
        subNavigationItems: subNavItems,
      });
    } else if (activeTab === 'smarty') {
      subNavItems = [
        {
          icon: () => <SmartyMessageIcon />,
          label: 'Overview',
          url: '/smartyoverview',
          selected: window.location.pathname == `${urlDomain}/smartyoverview`,
        },
        {
          icon: StoreStatusMajor,
          label: 'SMS messages',
          url: '/smarty',
          selected: window.location.pathname === `${urlDomain}/smarty`,
        },
        {
          icon: StoreStatusMajor,
          label: 'Whatsapp messages',
          url: '/whatsapp_messages',
          selected: window.location.pathname === `${urlDomain}/whatsapp_messages`,
        },

        {
          icon: StoreStatusMajor,
          label: 'Collect subscribers',
          url: '/collect_subscribers',
          selected: window.location.pathname === `${urlDomain}/collect_subscribers`,
        },

        {
          icon: StoreStatusMajor,
          label: 'Flows',
          url: '/Sms_flows',
          selected: window.location.pathname === `${urlDomain}/smartysettings`,
        },

        {
          icon: StoreStatusMajor,
          label: 'Integrations',
          url: '/sms_integrations',
          selected: window.location.pathname === `${urlDomain}/sms_integrations`,
        },

        {
          icon: StoreStatusMajor,
          label: 'SMS settings',
          url: '/sms_settings',
          selected: window.location.pathname === `${urlDomain}/sms_settings`,
        },
      ];
      navigation.push({
        url: '',
        label: (
          <div className="sub_nav_label">
            <Icon source={ChevronLeftMinor} tone="base" />
            <span>SmartySMS™</span>
          </div>
        ),
        disabled: true,
        selected: true,
        subNavigationItems: subNavItems,
      });
    } else if (activeTab === 'kioskpos') {
      subNavItems = [
        {
          icon: () => <ThemeIcon />,
          label: 'Overview',
          url: '/kioskpos',
          selected: window.location.pathname == `${urlDomain}/kioskpos`,
        },
        {
          icon: StoreStatusMajor,
          label: 'Kiosk orders',
          url: '/kioskposOrders',
          selected: window.location.pathname === `${urlDomain}/kioskposOrders`,
        },
        {
          icon: StoreStatusMajor,
          label: 'Menu templates',
          url: '/kioskposmenuTemplates',
          selected:
            window.location.pathname === `${urlDomain}/kioskposmenuTemplates`,
        },
        {
          icon: StoreStatusMajor,
          label: 'Reservations',
          url: '/kioskposreservations',
          selected:
            window.location.pathname === `${urlDomain}/kioskposreservations`,
        },
        {
          icon: StoreStatusMajor,
          label: 'Zip code',
          url: '/kioskposzipcode',
          selected: window.location.pathname === `${urlDomain}/kioskposzipcode`,
        },
        {
          icon: StoreStatusMajor,
          label: 'Settings',
          url: '/kioskpossettings',
          selected:
            window.location.pathname === `${urlDomain}/kioskpossettings`,
        },
      ];
      navigation.push({
        url: '',
        label: (
          <div className="sub_nav_label">
            <Icon source={ChevronLeftMinor} tone="base" />
            <span>Kiosk</span>
          </div>
        ),
        disabled: true,
        selected: true,
        subNavigationItems: subNavItems,
      });
    } else if (activeTab === 'replenishme') {
      subNavItems = [
        {
          icon: () => <ThemeIcon />,
          label: 'Overview',
          url: '/replenishme',
          selected: window.location.pathname == `${urlDomain}/replenishme`,
        },
        {
          icon: StoreStatusMajor,
          label: 'Replenishment flows',
          url: '/replenishmentflows',
          selected:
            window.location.pathname === `${urlDomain}/replenishmentflows`,
        },
        {
          icon: StoreStatusMajor,
          label: 'Replenishment orders',
          url: '/replenishmentorders',
          selected:
            window.location.pathname === `${urlDomain}/replenishmentorders`,
        },
      ];
      navigation.push({
        url: '',
        label: (
          <div className="sub_nav_label">
            <Icon source={ChevronLeftMinor} tone="base" />
            <span>Replenishme</span>
          </div>
        ),
        disabled: true,
        selected: true,
        subNavigationItems: subNavItems,
      });
    } else {
      let subscriptionSub = [];
      if (accessTabs?.manage_plan_access) {
        //Manage tabs settings
        if (customNavTabs?.subscription_home) {
          subscriptionSub.push({
            url: '/customers?subscription_home=true',
            label: i18n.t('subscriptions_home'),
            icon: () => <SubscriptionContractsIcon />,
            selected:
              new URLSearchParams(location.search).get('subscription_home') ==
                'true'
                ? true
                : false,
          });
        }

        if (customNavTabs?.gift_card_store_credit) {
          subscriptionSub.push({
            url: '/giftCard',
            label: 'Gift cards & store credit',
            selected: window.location.href.includes('giftCard'),
            icon: () => <SubscriptionContractsIcon />,
            subNavigationItems: [
              {
                url: '/giftCard',
                disabled: false,
                selected: window.location.pathname == `${urlDomain}/giftCard`,
                label: 'Gift card popup template',
              },
              {
                url: '/giftCard/giftCardProduct',
                disabled: false,
                selected:
                  window.location.pathname == `${urlDomain}/giftCard/giftCard`,
                label: 'Gift card product',
              },
              {
                url: '/giftCard/storeCredit',
                disabled: false,
                selected:
                  window.location.pathname ==
                  `${urlDomain}/giftCard/storeCredit`,
                label: 'Store credit',
              },
              {
                url: '/giftCard/analytics',
                disabled: false,
                selected:
                  window.location.pathname == `${urlDomain}/giftCard/analytics`,
                label: 'Analytics',
              },
            ],
          });
        }
        if (customNavTabs?.loyalty_rewards) {
          subscriptionSub.push({
            label: i18n.t('loyalty_rewards'),
            icon: GiftCardMajor,
            url: `/pointsProgram`,
            selected: window.location.pathname.includes('/pointsProgram'),
          });
        }
        if (customNavTabs?.replenish_me) {
          subscriptionSub.push({
            icon: ReplenishIcon,
            label: i18n.t('rebuy_cart'),
            url: '/rebuy',
            onClick: () => setActiveTab('dash'),
            selected:
              window.location.pathname == `${urlDomain}/rebuy` ? true : false,
          });
        }
        if (shopVersion != 'chargezen_lite') {
          subscriptionSub.push({
            url: `${window.location.pathname.replace(
              urlDomain,
              ''
            )}?revenue_more=true`,
            label: 'More',
            icon: MoreIcon,
            selected:
              new URLSearchParams(location.search).get('revenue_more') == 'true'
                ? true
                : false,
            subNavigationItems: [
              {
                // icon: MangeIcon,
                label: i18n.t('memberships'),
                url: '/memberships?revenue_more=true',
                selected:
                  window.location.pathname == `${urlDomain}/memberships`
                    ? true
                    : false,
              },
              customNavTabs?.pre_orders
                ? {
                  // icon: MangeIcon,
                  label: i18n.t('pre_orders'),
                  url: '/preOrders?revenue_more=true',
                  selected:
                    window.location.pathname == `${urlDomain}/preOrders`
                      ? true
                      : false,
                }
                : {},
              customNavTabs?.try_before_you_buy
                ? {
                  // icon: MangeIcon,
                  label: i18n.t('try_before_you_buy'),
                  url: '/tryBeforeYouBuy?revenue_more=true',
                  selected:
                    window.location.pathname == `${urlDomain}/tryBeforeYouBuy`
                      ? true
                      : false,
                }
                : {},
            ],
          });
        }

        if (customNavTabs?.upsells) {
          subscriptionSub.push({
            icon: MangeIcon,
            label: 'Upsells',
            url: '/chargezenUpsells',
            selected:
              window.location.pathname == `${urlDomain}/chargezenUpsells`
                ? true
                : false,
          });
        }

        if (customNavTabs?.checkout_extensions) {
          subscriptionSub.push({
            icon: ThemeIcon,
            label: 'Checkout',
            url: '/checkout',
            onClick: () => setActiveTab('checkout'),
            selected:
              window.location.pathname == `${urlDomain}/checkout`
                ? true
                : false,
          });
        }

        if (customNavTabs?.sms_smarty) {
          subscriptionSub.push({
            icon: SmartyMessageIcon,
            label: 'SmartySMS™',
            url: '/smartyoverview',
            onClick: () => setActiveTab('smarty'),
            selected:
              window.location.pathname == `${urlDomain}/smartyoverview`
                ? true
                : false,
          });
        }

        if (customNavTabs?.kiosk_pos) {
          subscriptionSub.push({
            icon: SmartyMessageIcon,
            label: 'Kiosk',
            url: '/kioskpos',
            onClick: () => setActiveTab('kioskpos'),
            selected: window.location.pathname == `${urlDomain}/kioskpos`,
          });
        }
        if (customNavTabs?.replenish_me) {
          subscriptionSub.push({
            icon: SmartyMessageIcon,
            label: 'Replenishme',
            url: '/replenishme',
            onClick: () => setActiveTab('replenishme'),
            selected: window.location.pathname == `${urlDomain}/replenishme`,
          });
        }

        //AffiliateInfluencer
        if (customNavTabs?.affiliate_influencer) {
          subscriptionSub.push({
            url: '/affiliates',
            label: 'Affiliates & Influencers',
            icon: () => <SubscriptionContractsIcon />,
            selected: window.location.pathname.includes('affiliates'),
          });
        }
      }

      navigation.push({
        url: '',
        label: i18n.t('revenue_channels'),
        disabled: true,
        selected: true,
        onClick: () =>
          setToogleMenu({
            ...toogleMenu,
            revenueChannels: !toogleMenu?.revenueChannels,
          }),
        subNavigationItems: subscriptionSub,
      });

      if (
        accessTabs?.subscription_orders_access ||
        accessTabs?.analytics_access
      ) {
        navigation.push({
          url: '',
          label: i18n.t('workspace'),
          disabled: true,
          selected: true,
          subNavigationItems: [
            accessTabs?.customer_modal
              ? {
                label: i18n.t('subscription_customers'),
                icon: CustomersIcon,
                url: '/customer-model',
                onClick: () => setActiveTab('customerModal'),
                selected:
                  window.location.pathname == `${urlDomain}/customer-model`
                    ? true
                    : false,
              }
              : {},
            accessTabs?.analytics_access
              ? {
                label: i18n.t('analytics'),
                icon: AnalyticsIcon,
                url: '/analytics',
                onClick: () => setActiveTab('analytics'),
                selected:
                  window.location.pathname == `${urlDomain}/analytics`
                    ? true
                    : false,
              }
              : {},
            accessTabs?.tiazen_access && shopVersion != 'chargezen_lite'
              ? {
                label: i18n.t('tiazen'),
                icon: TiazenIcon,
                url: '/tiazen',
                onClick: () => setActiveTab('tiazen'),
                selected:
                  window.location.pathname == `${urlDomain}/tiazen`
                    ? true
                    : false,
              }
              : {},
          ],
        });
      }

      navigation.push({
        url: '',
        label: 'STOREFRONT',
        disabled: true,
        selected: true,
        subNavigationItems: [
          {
            icon: ZennPortalIcon,
            label: 'Customer Portal',
            url: '/zenn',
            onClick: () => setActiveTab('zenn'),
            selected: window.location.pathname.includes(`${urlDomain}/zenn`)
              ? true
              : false,
            // subNavigationItems: [
            //   {
            //     url: '/zenn/cancellationflow',
            //     disabled: false,
            //     selected:
            //       window.location.pathname ==
            //       `${urlDomain}/zenn/cancellationflow`
            //         ? true
            //         : false,
            //     label: 'Cancellation flow',
            //   },
            //   {
            //     url: '/zenn/passwordlessLogin',
            //     disabled: false,
            //     selected:
            //       window.location.pathname ==
            //       `${urlDomain}/zenn/passwordlessLogin`
            //         ? true
            //         : false,
            //     label: 'Passwordless login',
            //   },
            // ],
          },
          // {
          //   icon: ThemeIcon,
          //   label: 'Theme Extensions',
          //   url: '/theme',
          //   onClick: () => setActiveTab('theme'),
          //   selected:
          //     window.location.pathname == `${urlDomain}/theme` ? true : false,
          // },
          {
            icon: MangeIcon,
            label: 'Bundles ',
            url: '/bundle',
            selected:
              window.location.pathname == `${urlDomain}/bundles` ? true : false,
          },
          shopVersion != 'chargezen_lite'
            ? {
              icon: MangeIcon,
              label: i18n.t('quizes'),
              url: '/quizes',
              selected:
                window.location.pathname == `${urlDomain}/quizes`
                  ? true
                  : false,
            }
            : {},
        ],
      });

      if (
        accessTabs?.installation_access ||
        accessTabs?.tiazen_access ||
        accessTabs?.toolbox_access
      ) {
        navigation.push({
          url: '',
          label: i18n.t('general'),
          selected: true,
          disabled: true,
          subNavigationItems: [
            accessTabs?.installation_access
              ? {
                label: i18n.t('installation'),
                icon: InstallMinor,
                url: '/installation',
                onClick: () => setActiveTab('inst'),
                selected:
                  window.location.pathname == `${urlDomain}/installation`
                    ? true
                    : false,
              }
              : {},

            accessTabs?.toolbox_access
              ? {
                label: 'Integrations',
                icon: () => <ToolboxIcon />,
                url: '/toolbox/integerationsMain',
                onClick: () => setActiveTab('tool'),
                selected:
                  window.location.pathname ==
                    `${urlDomain}/toolbox/integerationsMain`
                    ? true
                    : false,
              }
              : {},
            shopVersion != 'chargezen_lite'
              ? {
                label: 'Help Desk',
                icon: HelpdeskIcon,
                url: '/helpdesk',
                onClick: () => setActiveTab('settings'),
                selected:
                  window.location.pathname == `${urlDomain}/helpdesk`
                    ? true
                    : false,
              }
              : {},
          ],
        });
      }
    }
    if (accessTabs?.settings_access) {
      navigation.push({
        url: '',
        label: '',
        selected: true,
        disabled: true,
        subNavigationItems: [
          accessTabs?.settings_access
            ? {
              label: i18n.t('settings'),
              icon: SettingsMinor,
              url: '/app-settings',
              onClick: () => setActiveTab('settings'),
              selected: window.location.pathname.includes(
                `${urlDomain}/app-settings`
              )
                ? true
                : false,
            }
            : {},
        ],
      });
      setNavigations(navigation);
    }
  };

  console.log('activeTab', activeTab);
  //Mixpanel
  const [currentMixpanel, setCurrentMixpanel] = useState();
  //Initialise Mixpanel
  mixpanel.init(mixpanelId, {
    debug: true,
    loaded: function () {
      setCurrentMixpanel(mixpanel.get_distinct_id());
    },
  });

  const getMixpanelUser = () => {
    fetch(`/getMixpanelUser/${currentMixpanel}`, {
      method: 'Get',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    })
      .then((response) => response.json())
      .then((data) => {
        let event = window.location.pathname.split('/').pop();
        event = event ? event : 'Admin Dashboard';
        if (data?.user) {
          mixpanel.track(`Admin ${event}`, {
            shop: `${props?.domain}`,
            userEmail: `${data?.user}`,
          });
        } else {
          mixpanel.track(`Admin ${event}`, {
            shop: `${props?.domain}`,
            userEmail: `${localStorage.getItem('currentuser')}`,
          });
        }
      });
  };
  const setActiveTabByPath = () => {
    if (window.location.pathname.includes('/customers')) {
      setActiveTab('subscriptions');
    } else if (window.location.pathname.includes('/pointsProgram')) {
      setActiveTab('pointsProgram');
    } else if (window.location.pathname.includes('affiliates')) {
      setActiveTab('affiliates');
    } else if (
      window.location.pathname?.toLowerCase().includes('/chargezenupsells')
    ) {
      setActiveTab('upsells');
    } else if (window.location.pathname.includes('checkout')) {
      setActiveTab('checkout');
    } else if (window.location.pathname.includes('smarty')) {
      setActiveTab('smarty');
    } else if (window.location.pathname.includes('/kioskpos')) {
      setActiveTab('kioskpos');
    } else if (window.location.pathname.includes('replenishme')) {
      setActiveTab('replenishme');
    } else {
      setActiveTab();
    }
  };
  useEffect(() => {
    setActiveTabByPath();
    if (currentMixpanel) {
      getMixpanelUser();
    }
  }, [window.location.pathname]);

  return (
    <div className={`navBar ${toggleNav ? "opened" : ""}`}>
      <ToggleButton
        inactiveLabel={''}
        activeLabel={''}
        colors={{
          activeThumb: {
            active: {
              base: '#001219',
              hover: '#001219',
            },
          }
        }}
        value={toggleNav}
        onToggle={() => {
          setToggleNav(!toggleNav);
        }}
        onClick={() => {
          setToggleNav(!toggleNav)
        }}
      />
      <style dangerouslySetInnerHTML={{ __html: dynamicStyle }} />
      {revenueMoreCond && <ConditionalCss />}
      <div className="logo_main">
        <div className="collapsible_button">
          <Button
            onClick={() => {
              setNavCollapse(!navCollapse);
            }}
          >
            {navCollapse ? (
              <div className="arrow-icon-right">
                <svg
                  width="11"
                  height="16"
                  viewBox="0 0 11 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M7.93891 0.938995L0.878906 8L7.93891 15.061L10.0609 12.939L5.12091 8L10.0609 3.061L7.93891 0.938995Z"
                    fill="#445BFF"
                  />
                </svg>
              </div>
            ) : (
              <div className="arrow-icon-left">
                <svg
                  width="11"
                  height="16"
                  viewBox="0 0 11 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M7.93891 0.938995L0.878906 8L7.93891 15.061L10.0609 12.939L5.12091 8L10.0609 3.061L7.93891 0.938995Z"
                    fill="#445BFF"
                  />
                </svg>
              </div>
            )}
          </Button>
        </div>
      </div>
      {navigations.length > 0 ? (
        <div className="sidBartabs">
          <Navigation location={router.pathname}>
            <Navigation.Section
              items={navigations ? navigations : []}
              selected={selected}
            />
          </Navigation>
        </div>
      ) : (
        <div />
      )}
      <div className="logout_link">
        {localStorage.getItem('currentuser') && (
          <>
            <a
              href={`${window?.location?.origin}/users/sign_out`}
              className="adminLogoutLink"
              data-method="delete"
            >
              <span className="logout_icon">
                <Icon source={LogOutMinor} tone="base" />
              </span>
              <span>{i18n.t('log_out')}</span>
            </a>
          </>
        )}
      </div>
    </div>
  );
};

export default Nav;
