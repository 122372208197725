import React, { useCallback, useContext, useEffect, useState } from 'react';
import {
  Card,
  Select,
  Heading,
  FormLayout,
  Button,
  Icon,
  Modal,
  TextField,
  Stack,
  Toast,
  Frame,
  Checkbox,
} from '@shopify/polaris';
import './loyalty.css';
import { DomainContext } from '../domain-context';
import { GetStartedIcon } from '../layout/CustomIcon';
import PointsProgramFAQs from './PointsProgram/PointsProgramFaqs';
import CreatePointsProgramFaq from './PointsProgram/CreatePointsProgramFaq';
import dayjs from 'dayjs';
import CreateHowItWorks from './PointsProgram/CreateHowItWorks';
import CreateTierBenefits from './PointsProgram/CreateTierBenefits';
import ManageTranslations from './ManageTranslations';
import CreateWaysToRedeemContent from './PointsProgram/CreateWaysToRedeemContent';
import { useParams, useHistory } from 'react-router-dom';

const Customize = (props) => {
  const { domain } = useContext(DomainContext);
  const history = useHistory();
  const { selectedTemplateId, i18n } = props;
  const { edit_id, template_id } = useParams();
  const [active, setActive] = useState(false);
  const [statusActive, setStatusActive] = useState(false);
  const toggleActive = useCallback(() => setActive((active) => !active), []);
  const toggleStatusActive = useCallback(
    () => setStatusActive((statusActive) => !statusActive),
    []
  );

  const [loyaltyId, setLoyaltyId] = useState(null);

  const [rewardsPageId, setRewardsPageId] = useState('');

  useEffect(() => {
    if (edit_id && !`${edit_id}`.includes('create')) {
      setRewardsPageId(edit_id);
    }
  }, [edit_id]);

  const [allImages, setAllImages] = useState([]);
  const [apiData, setApiData] = useState();
  const [selectedImages, setSelectedImages] = useState([]);
  const [waysToRedeemImages, setWaysToRedeemImages] = useState();
  const [waysToEarnImages, setWaysToEarnImages] = useState();
  const [howItWorksImage, setHowItWorksImage] = useState();
  const [heroFormData, setHeroFormData] = useState({
    title: '',
    subtitle: '',
    primary_theme_color: '',
    secondary_theme_color: '',
    primary_text_color: '',
    secondary_text_color: '',
    shopify_domain: domain,
    template_id: template_id,
    images: selectedImages,
    hero_css: '',
    status: 'draft',
  });
  const [faqFormData, setFaqFormData] = useState([]);
  const [wrContentFormData, setWrContentFormData] = useState([]);
  const [wrContentFieldErrors, setWrContentFieldErrors] = useState([]);
  const [tierBenefitsFormData, setTierBenefitsFormData] = useState([]);
  const [faqFieldErrors, setFaqFieldErrors] = useState([]);
  const [nameError, setNameError] = useState(false);
  const [selectedFooterImage, setSelectedFooterImage] = useState();
  const [footerFormData, setfooterFormData] = useState({
    title: '',
    paragraph: '',
    button_title: '',
    button_url: '',
    template_id: template_id,
    shopify_domain: domain,
    footer_css: '',
  });
  const [worksFormData, setworksFormData] = useState([]);
  const [worksFieldErrors, setworksFieldErrors] = useState([]);

  const [purchase, setPurchase] = useState(0);
  const [account, setAccount] = useState(0);
  const [socialMediaLike, setSocialMediaLike] = useState(0);
  const [socialMediaFollow, setSocialMediaFollow] = useState(0);
  const [upsell, setUpsell] = useState(0);
  const [smartySms, setSmartySms] = useState(0);
  const [referFriend, setReferFriend] = useState(0);
  const [mailingList, setMailingList] = useState(0);
  const [customRule, setCustomRule] = useState(0);

  const [discount, setDiscount] = useState(0);
  const [point, setPoint] = useState(0);
  const [startingDate, setStartingDate] = useState(null);
  const [endingDate, setEndingDate] = useState(null);

  const couponList = [
    { label: '$5.00 OFF', value: '5' },
    { label: '$10.00 OFF', value: '10' },
    { label: '$15.00 OFF', value: '15' },
  ];

  const descTexts = [
    { label: 'Earn 10 points when you create an account', value: '10' },
    { label: 'Earn 50 points when you create an account', value: '50' },
    { label: 'Earn 100 points when you create an account', value: '100' },
  ];

  const completedMsgList = [
    {
      label: 'Looks like you have already compeleted this offer!',
      value: 'offer-completed',
    },
    { label: 'You have already availed this offer!', value: 'offer-availed' },
    {
      label: 'Looks like this offer is not valid anymore!',
      value: 'offer-invalid',
    },
  ];

  const maxCompletionLimits = [
    { label: 'No Maximum', value: 'no-max' },
    { label: '5 Times', value: '5-times' },
    { label: '10 Times', value: '10-times' },
  ];

  const rewardOptions = [
    { label: 'Coupon', value: 'coupon' },
    { label: 'Discount', value: 'discount' },
    { label: 'Points', value: 'points' },
  ];

  const loactionOptions = [
    { label: 'Customer Portal', value: 'customer-portal' },
    { label: 'Website Frontend', value: 'website-frontend' },
  ];

  const iconsList = [
    { label: 'fa-user', value: 'fa-user' },
    { label: 'fa-heart', value: 'fa-heart' },
    { label: 'fa-person', value: 'fa-person' },
    { label: 'fa-dollar', value: 'fa-dollar' },
  ];
  const rewardReferrerOptions = [
    { label: 'a coupon', value: 'coupon' },
    { label: 'points', value: 'points' },
    { label: 'nothing', value: 'nothing' },
  ];
  const pointsReferrerEarnsOptions = [
    { label: 'a fixed amount', value: 'fixed_amount' },
    {
      label: 'based on the amount spent by the referred customer',
      value: 'percentage_spend',
    },
    {
      label: 'based on the product purchased by the referred customer',
      value: 'product_map',
    },
  ];

  // refer_a_friend Campaign
  const [referCampaignStatus, setReferCampaignStatus] = useState(false);
  const [referCampaignDate, setReferCampaignDate] = useState(null);
  const [referrerReward, setReferrerReward] = useState(0);
  const [referrerPoints, setReferrerPoints] = useState(0);
  const [referrerFixedPoints, setReferrerFixedPoints] = useState(0);
  const [pointsToReferrer, setPointsToReferrer] = useState(0);
  const [pointsToReferred, setPointsToReferred] = useState(0);
  const [selectedReward, setSelectedReward] = useState('coupon');
  const [selectedCoupon, setSelectedCoupon] = useState('5');
  const [applyCoupon, setApplyCoupon] = useState(1);
  const [rewardText, setRewardText] = useState('');
  const [description, setDescription] = useState('');
  const [icon, setIcon] = useState('fa-user');
  const [callToActionText, setCallToActionText] = useState('');
  const [landingURL, setLandingURL] = useState('');
  const [landingURLQueryParams, setLandingURLQueryParams] = useState('');
  const [defaultTwitterMsg, setDefaultTwitterMsg] = useState('');
  const [headerForFbMsg, setHeaderForFbMsg] = useState('');
  const [descriptionForFbMsg, setDescriptionForFbMsg] = useState('');
  const [defaultSmsMsg, setDefaultSmsMsg] = useState('');
  const [tierBenefits, setTierBenefits] = useState({});
  const [customCss, setCustomCss] = useState({
    hero_css: '',
    ways_to_redeem_css: '',
    how_it_works: '',
    ways_to_earn_css: '',
    loyalty_tiers_css: '',
    faq: '',
    footer: '',
  });
  const [bulkStatus, setBulkStatus] = useState({
    hero_status: true,
    ways_to_redeem_status: true,
    how_it_works_status: true,
    ways_to_earn_status: true,
    loyalty_tiers_status: true,
    faq_status: true,
    footer_status: true,
    status: 'draft',
  });
  const [showReferCampaign, setShowReferCampaign] = useState(false);
  const referActivator = (
    <Button primary outline size="slim" onClick={() => getReferCampaign()}>
      {i18n.t('edit')}
    </Button>
  );

  const [rewardReferrer, setRewardReferrer] = useState('nothing');
  const [pointsReferrerEarns, setPointsReferrerEarns] =
    useState('fixed_amount');

  const [couponReferrerGet, setCouponReferrerGet] = useState('5');
  console.log('pointsToReferred', pointsToReferred);

  const fetchRewardsPage = () => {
    fetch(
      '/customize_rewards_template?shopify_domain=' +
        domain +
        '&template_id=' +
        template_id +
        '&rewards_page_id=' +
        (rewardsPageId || ''),
      {
        method: 'GET',
      }
    )
      .then((response) => response.json())
      .then((data) => {
        setApiData(data);
        if (data?.images) {
          setAllImages(data?.images);

          setCustomCss({
            hero_css: data?.rewards_page?.hero_css || '',
            ways_to_redeem_css: data?.rewards_page?.ways_to_redeem_css || '',
            how_it_works: data?.rewards_page?.ways_to_redeem_css || '',
            ways_to_earn_css: data?.rewards_page?.ways_to_earn_css || '',
            loyalty_tiers_css: data?.rewards_page?.loyalty_tiers_css || '',
            faq: data?.rewards_page?.faq_css || '',
            footer: data?.rewards_page?.footer_css || '',
          });
          setBulkStatus({
            status: data?.rewards_page?.status,
            hero_status: data?.rewards_page?.hero_status,
            ways_to_redeem_status: data?.rewards_page?.ways_to_redeem_status,
            how_it_works_status: data?.rewards_page?.how_it_works_status,
            ways_to_earn_status: data?.rewards_page?.ways_to_earn_status,
            loyalty_tiers_status: data?.rewards_page?.loyalty_tiers_status,
            faq_status: data?.rewards_page?.faq_status,
            footer_status: data?.rewards_page?.footer_status,
          });

          setTierBenefits({ ...data?.tier_benefits });

          //setBenefits
          let benefits = [];
          data?.program_benefits?.map((benefit) => {
            benefits.push({
              id: benefit.id,
              title: benefit.title,
              desc: benefit.desc,
            });
          });
          setTierBenefitsFormData([...benefits]);

          //setFooterImage
          let footerImage = data?.images?.find(
            (img) => +img.blob_id == +data?.selected_images?.footer
          )?.id;
          setSelectedFooterImage(footerImage);

          //setHeroImage
          let selected_images = [];
          data?.selected_images?.rewards_page?.map((blob_id) => {
            selected_images.push(
              data?.images?.find((img) => +img.blob_id == +blob_id)?.id
            );
          });

          setSelectedImages(selected_images);
        }

        //setFAQs
        let faqs = [];
        if (data?.faqs?.length > 0) {
          data?.faqs?.map((faq) => {
            faqs.unshift({
              id: faq?.id,
              title: faq?.title,
              bodyHtml: faq?.body_title,
            });
          });
        } else {
          faqs.push({ title: '', bodyHtml: '' });
        }
        setFaqFormData([...faqs]);

        //setWaysToRedeemContent
        let wrContents = [];
        if (data?.wrContents?.length > 0) {
          data?.wrContents?.map((wr_content) => {
            wrContents.unshift({
              id: wr_content?.id,
              title: wr_content?.title,
              body: wr_content?.body,
            });
          });
        } else {
          wrContents.push({ title: '', body: '' });
        }
        setWrContentFormData([...wrContents]);

        //setHowitWorks
        let works = [];
        if (data?.works?.length > 0) {
          data?.works?.map((work) => {
            works.unshift({
              id: work?.id,
              body: work?.body,
              icon_url: work?.icon,
              title: work.title,
            });
          });
        } else {
          works.push({ body: '', title: '' });
        }
        setworksFormData([...works]);

        //setHeroForm
        let heroData = heroFormData;
        heroData.title = data?.rewards_page?.title;
        heroData.title2 = data?.rewards_page?.title2;
        heroData.subtitle = data?.rewards_page?.subtitle;
        heroData.primary_theme_color = data?.rewards_page?.primary_theme_color;
        heroData.secondary_theme_color =
          data?.rewards_page?.secondary_theme_color;
        heroData.primary_text_color = data?.rewards_page?.primary_text_color;
        heroData.secondary_text_color =
          data?.rewards_page?.secondary_text_color;
        heroData.status = data?.rewards_page?.status;
        heroData.page_handle = data?.rewards_page?.page_handle;
        setHeroFormData({ ...heroData });

        //setFooterForm
        let footerData = footerFormData;
        footerData.title = data?.footer?.title;
        footerData.paragraph = data?.footer?.paragraph;
        footerData.button_title = data?.footer?.button_tittle;
        footerData.button_url = data?.footer?.button_url;
        setfooterFormData({ ...footerData });
      });
  };

  console.log('allImages', allImages);
  console.log('apiData', apiData);
  console.log('faqFormData', faqFormData);

  const getReferCampaign = () => {
    setShowReferCampaign(true);
    fetch(`/loyalty_campaign?name=refer_a_friend&shopify_domain=${domain}`, {
      method: 'GET',
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status) {
          setReferCampaignStatus(data.campaign?.status);
          setReferCampaignDate(data.campaign?.updated_at);
          setReferrerReward(data.campaign?.referrer_reward.toString());
          setReferrerPoints(data.campaign?.referrer_points.toString());
          setReferrerFixedPoints(
            data.campaign?.referrer_fixed_points.toString()
          );
          setPointsToReferrer(data.campaign?.points_to_referrer.toString());
          setSelectedReward(data.campaign?.selected_reward);
          setSelectedCoupon(data.campaign?.selected_coupon);
          setApplyCoupon(data.campaign?.apply_coupon.toString());
          setCampaignName(data.campaign?.campaign_name);
          setRewardText(data.campaign?.reward_text);
          setDescription(data.campaign?.description);
          setIcon(data.campaign?.icon);
          setCallToActionText(data.campaign?.call_to_action_text);
          setLandingURL(data.campaign?.landing_url);
          setLandingURLQueryParams(data.campaign?.landing_url_query_params);
          setDefaultTwitterMsg(data.campaign?.default_twitter_msg);
          setHeaderForFbMsg(data.campaign?.header_for_fb_msg);
          setDescriptionForFbMsg(data.campaign?.description_for_fb_msg);
          setDefaultSmsMsg(data.campaign?.default_sms_msg);
          setRewardReferrer(data.campaign?.reward_referrer);
          setCouponReferrerGet(data.campaign?.coupon_referrer_get);
          setPointsReferrerEarns(data.campaign?.points_referrer_earns);
          setPercentPointsReferrer(data.campaign?.percent_points_referrer);
          setMapProductsPoints(data.campaign?.map_products_points);
          setPointsToReferred(data.campaign?.points_to_referred);
        }
      });
  };

  const saveReferCampaign = () => {
    fetch('/loyalty_campaign', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        shopify_domain: domain,
        name: 'refer_a_friend',
        referrer_reward: referrerReward,
        referrer_points: referrerPoints,
        referrer_fixed_points: referrerFixedPoints,
        points_to_referrer: pointsToReferrer,
        selected_reward: selectedReward,
        selected_coupon: selectedCoupon,
        apply_coupon: applyCoupon,
        campaign_name: campaignName,
        reward_text: rewardText,
        description: description,
        icon: icon,
        call_to_action_text: callToActionText,
        landing_url: landingURL,
        landing_url_query_params: landingURLQueryParams,
        default_twitter_msg: defaultTwitterMsg,
        header_for_fb_msg: headerForFbMsg,
        description_for_fb_msg: descriptionForFbMsg,
        default_sms_msg: defaultSmsMsg,
        reward_referrer: rewardReferrer,
        coupon_referrer_get: couponReferrerGet,
        points_referrer_earns: pointsReferrerEarns,
        percent_points_referrer: percentPointsReferrer,
        map_products_points: mapProductsPoints,
        points_to_referred: pointsToReferred,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status) {
          toggleActive();
        }
      });
  };

  const changeBenefitState = (tier_id, benefit_id) => {
    let tierbenifts = tierBenefits;
    console.log('tierbenifts', tierbenifts);
    let benifts = tierbenifts[tier_id];
    let available;
    benifts = benifts?.map((b) => {
      if (b.id == benefit_id) {
        b.available = !b?.available;
        available = b.available;
        return b;
      } else {
        return b;
      }
    });
    tierbenifts[tier_id] = benifts;
    setTierBenefits({ ...tierbenifts });

    fetch('/save_tier_benefits', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        tier_id: tier_id,
        benefit_id: benefit_id,
        available: available,
        shopify_domain: domain,
      }),
    })
      .then((response) => response.json())
      .then((data) => {});
  };

  // create_account Campaign
  const [accountCampaignStatus, setAccountCampaignStatus] = useState(false);
  const [accountCampaignDate, setAccountCampaignDate] = useState(null);
  const [campaignName, setCampaignName] = useState('');
  const [descText, setDescText] = useState('10');
  const [completedMsg, setCompletedMsg] = useState('offer-completed');
  const [maxTimesCompletion, setMaxTimesCompletion] = useState('no-max');

  const [showAccountCampaign, setShowAccountCampaign] = useState(false);
  const accountActivator = (
    <Button primary outline size="slim" onClick={() => getAccountCampaign()}>
      {i18n.t('edit')}
    </Button>
  );

  const getAccountCampaign = () => {
    setShowAccountCampaign(true);
    fetch(`/loyalty_campaign?name=create_account&shopify_domain=${domain}`, {
      method: 'GET',
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status) {
          setAccountCampaignStatus(data.campaign?.status);
          setAccountCampaignDate(data.campaign?.updated_at);
          setSelectedReward(data.campaign?.selected_reward);
          setPointsToReferrer(data.campaign?.points_to_referrer.toString());
          setCampaignName(data.campaign?.campaign_name);
          setRewardText(data.campaign?.reward_text);
          setDescText(data.campaign?.description_text);
          setIcon(data.campaign?.icon);
          setCompletedMsg(data.campaign?.completed_msg);
          setMaxTimesCompletion(data.campaign?.max_times_completion);
        }
      });
  };

  const saveAccountCampaign = () => {
    fetch('/loyalty_campaign', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        name: 'create_account',
        selected_reward: selectedReward,
        points_to_referrer: pointsToReferrer,
        campaign_name: campaignName,
        reward_text: rewardText,
        description_text: descText,
        icon: icon,
        completed_msg: completedMsg,
        max_times_completion: maxTimesCompletion,
        shopify_domain: domain,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status) {
          toggleActive();
        }
      });
  };

  // happy_birthday Campaign
  const [birthdayCampaignStatus, setBirthdayCampaignStatus] = useState(false);
  const [birthdayCampaignDate, setBirthdayCampaignDate] = useState(null);

  const [showBirthdayCampaign, setShowBirthdayCampaign] = useState(false);
  const birthdayActivator = (
    <Button primary outline size="slim" onClick={() => getBirthdayCampaign()}>
      {i18n.t('edit')}
    </Button>
  );

  const getBirthdayCampaign = () => {
    setShowBirthdayCampaign(true);
    fetch(`/loyalty_campaign?name=happy_birthday&shopify_domain=${domain}`, {
      method: 'GET',
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status) {
          setBirthdayCampaignStatus(data.campaign?.status);
          setBirthdayCampaignDate(data.campaign?.updated_at);
          setSelectedReward(data.campaign?.selected_reward);
          setPointsToReferrer(data.campaign?.points_to_referrer.toString());
          setCampaignName(data.campaign?.campaign_name);
          setRewardText(data.campaign?.reward_text);
          setDescText(data.campaign?.description_text);
          setDefaultSmsMsg(data.campaign?.default_sms_msg);
          setDescription(data.campaign?.description);
          setApplyCoupon(data.campaign?.apply_coupon.toString());
          setIcon(data.campaign?.icon);
          setCallToActionText(data.campaign?.call_to_action_text);
        }
      });
  };

  const saveBirthdayCampaign = () => {
    fetch('/loyalty_campaign', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        name: 'happy_birthday',
        selected_reward: selectedReward,
        points_to_referrer: pointsToReferrer,
        campaign_name: campaignName,
        reward_text: rewardText,
        description_text: descText,
        default_sms_msg: defaultSmsMsg,
        description: description,
        apply_coupon: applyCoupon,
        icon: icon,
        call_to_action_text: callToActionText,
        shopify_domain: domain,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status) {
          toggleActive();
        }
      });
  };

  // make_a_purchase Campaign
  const [purchaseCampaignStatus, setPurchaseCampaignStatus] = useState(false);
  const [purchaseCampaignDate, setPurchaseCampaignDate] = useState(null);
  const [customerFixedPoints, setCustomerFixedPoints] = useState(0);
  const [requiredProduct, setRequiredProduct] = useState('');
  const [requireProdReward, setRequireProdReward] = useState('entire-cart');
  const [customerTag, setCustomerTag] = useState('');
  const [customerTagList, setCustomerTagList] = useState('');
  const [orderTag, setOrderTag] = useState('');
  const [orderTagList, setOrderTagList] = useState('');
  const [limit, setLimit] = useState('exactly');
  const [orders, setOrders] = useState(0);
  const [maxTimesPerUser, setMaxTimesPerUser] = useState('no-max');
  const [timeBetween, setTimeBetween] = useState('5-days');
  const [countdownMessage, setCountdownMessage] = useState('24-hours');

  const [showPurchaseCampaign, setShowPurchaseCampaign] = useState(false);
  const purchaseActivator = (
    <Button primary outline size="slim" onClick={() => getPurchaseCampaign()}>
      {i18n.t('edit')}
    </Button>
  );
  const [percentPointsReferrer, setPercentPointsReferrer] = useState('');
  const [mapProductsPoints, setMapProductsPoints] = useState('');

  const getPurchaseCampaign = () => {
    setShowPurchaseCampaign(true);
    fetch(`/loyalty_campaign?name=make_a_purchase&shopify_domain=${domain}`, {
      method: 'GET',
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status) {
          setPurchaseCampaignStatus(data.campaign?.status);
          setPurchaseCampaignDate(data.campaign?.updated_at);
          setCustomerFixedPoints(
            data.campaign?.customer_fixed_points.toString()
          );
          setPointsToReferrer(data.campaign?.points_to_referrer.toString());
          setCampaignName(data.campaign?.campaign_name);
          setRewardText(data.campaign?.reward_text);
          setDescText(data.campaign?.description_text);
          setRequiredProduct(data.campaign?.required_product);
          setRequireProdReward(data.campaign?.required_product_reward);
          setCustomerTag(data.campaign?.customer_tag);
          setCustomerTagList(data.campaign?.customer_tag_list);
          setOrderTag(data.campaign?.order_tag);
          setOrderTagList(data.campaign?.order_tag_list);
          setLimit(data.campaign?.limit);
          setOrders(data.campaign?.orders.toString());
          setMaxTimesCompletion(data.campaign?.max_times_completion);
          setMaxTimesPerUser(data.campaign?.max_times_per_user);
          setTimeBetween(data.campaign?.time_between);
          setCountdownMessage(data.campaign?.countdown_message);
        }
      });
  };

  const savePurchaseCampaign = () => {
    fetch('/loyalty_campaign', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        shopify_domain: domain,
        name: 'make_a_purchase',
        customer_fixed_points: customerFixedPoints,
        points_to_referrer: pointsToReferrer,
        campaign_name: campaignName,
        reward_text: rewardText,
        description_text: descText,
        required_product: requiredProduct,
        required_product_reward: requireProdReward,
        customer_tag: customerTag,
        customer_tag_list: customerTagList,
        order_tag: orderTag,
        order_tag_list: orderTagList,
        limit: limit,
        orders: orders,
        max_times_completion: maxTimesCompletion,
        max_times_per_user: maxTimesPerUser,
        time_between: timeBetween,
        countdown_message: countdownMessage,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status) {
          toggleActive();
        }
      });
  };

  // Customize Coupon
  const [couponValue, setCouponValue] = useState('');
  const [rewardCustomers, setRewardCustomers] = useState('redeem-points');
  const [couponType, setCouponType] = useState('fixed-amount');
  const [discountAmount, setDiscountAmount] = useState(0);
  const [couponName, setCouponName] = useState('');
  const [couponPoints, setCouponPoints] = useState('');
  const [couponText, setCouponText] = useState('');
  const [applyDiscount, setApplyDiscount] = useState('any-product');
  const [cartAmount, setCartAmount] = useState(null);
  const [customerList, setCustomerList] = useState(null);
  const [applyDiscountPer, setApplyDiscountPer] = useState('cart');
  const [discountCodeUsage, setDiscountCodeUsage] = useState('1');
  const [couponCodeExpire, setCouponCodeExpire] = useState('never');
  const [couponRestriction, setCouponRestriction] = useState('0');
  const [codePrefix, setCodePrefix] = useState('');
  const [codeLength, setCodeLength] = useState(1);
  const [couponCodeIntro, setCouponCodeIntro] = useState('redeem');

  const [showCustomizeCoupon, setShowCustomizeCoupon] = useState(false);
  const coupon7Activator = (
    <Button
      primary
      outline
      size="slim"
      onClick={() => getCustomizeCoupon('7.5')}
    >
      Edit
    </Button>
  );
  const coupon15Activator = (
    <Button
      primary
      outline
      size="slim"
      onClick={() => getCustomizeCoupon('15')}
    >
      Edit
    </Button>
  );
  const coupon30Activator = (
    <Button
      primary
      outline
      size="slim"
      onClick={() => getCustomizeCoupon('30')}
    >
      Edit
    </Button>
  );

  const getCustomizeCoupon = (value) => {
    setCouponValue(value);
    setShowCustomizeCoupon(true);
    fetch('/loyalty_coupon?name=' + value + '-off&shopify_domain=' + domain, {
      method: 'GET',
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status) {
          setRewardCustomers(data.coupon?.reward_customers);
          setCouponType(data.coupon?.coupon_type);
          setDiscountAmount(data.coupon?.discount_amount.toString());
          setCouponName(data.coupon?.coupon_name);
          setDescription(data.coupon?.description);
          setCouponPoints(data.coupon?.coupon_points);
          setCouponText(data.coupon?.coupon_text);
          setApplyDiscount(data.coupon?.apply_discount);
          setCartAmount(data.coupon?.cart_amount.toString());
          setCustomerList(data.coupon?.customer_list);
          setApplyDiscountPer(data.coupon?.apply_discount_per);
          setDiscountCodeUsage(data.coupon?.discount_code_usage);
          setCouponCodeExpire(data.coupon?.coupon_code_expire);
          setCouponRestriction(data.coupon?.coupon_restriction);
          setCodePrefix(data.coupon?.code_prefix);
          setCodeLength(data.coupon?.code_length.toString());
          setDefaultSmsMsg(data.coupon?.success_msg);
          setIcon(data.coupon?.icon);
          setCouponCodeIntro(data.coupon?.coupon_code_intro);
        }
      });
  };

  const saveCustomizeCoupon = () => {
    fetch('/loyalty_coupon', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        shopify_domain: domain,
        name: couponValue + '-off',
        reward_customers: rewardCustomers,
        coupon_type: couponType,
        discount_amount: discountAmount,
        coupon_name: couponName,
        description: description,
        coupon_points: couponPoints,
        coupon_text: couponText,
        apply_discount: applyDiscount,
        cart_amount: cartAmount,
        customer_list: customerList,
        apply_discount_per: applyDiscountPer,
        discount_code_usage: discountCodeUsage,
        coupon_code_expire: couponCodeExpire,
        coupon_restriction: couponRestriction,
        code_prefix: codePrefix,
        code_length: codeLength,
        success_msg: defaultSmsMsg,
        icon: icon,
        coupon_code_intro: couponCodeIntro,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status) {
          toggleActive();
        }
      });
  };

  // Loyalty Settings
  useEffect(() => {
    fetchRewardsPage();

    // fetch(`/loyalty?shopify_domain=${domain}`, {
    //   method: 'GET',
    // })
    //   .then((response) => response.json())
    //   .then((data) => {
    //     if (data.status) {
    //       setHeroFormData({ ...heroFormData, title: data.loyalty?.name });
    //       setHeroFormData({ ...heroFormData, primary_theme_color: data.loyalty?.primary_theme_color });
    //       setHeroFormData({ ...heroFormData, secondary_theme_color: data.loyalty?.secondary_theme_color });
    //       setHeroFormData({ ...heroFormData, primary_text_color: data.loyalty?.primary_text_color });
    //       setHeroFormData({ ...heroFormData, secondary_text_color: data.loyalty?.secondary_text_color });
    //     }
    //   })

    // fetch(`/loyalty_campaign/campaign_statuses?shopify_domain=${domain}`, {
    //   method: 'GET',
    // })
    //   .then((response) => response.json())
    //   .then((data) => {
    //     if (data.status) {
    //       data.campaigns.map(function (campaign) {
    //         if (campaign.name == 'refer_a_friend') {
    //           setReferCampaignStatus(campaign.status)
    //           setReferCampaignDate(campaign.updated_at)
    //         }
    //         else if (campaign.name == 'create_account') {
    //           setAccountCampaignStatus(campaign.status)
    //           setAccountCampaignDate(campaign.updated_at)
    //         }
    //         else if (campaign.name == 'happy_birthday') {
    //           setBirthdayCampaignStatus(campaign.status)
    //           setBirthdayCampaignDate(campaign.updated_at)
    //         }
    //         else if (campaign.name == 'make_a_purchase') {
    //           setPurchaseCampaignStatus(campaign.status)
    //           setPurchaseCampaignDate(campaign.updated_at)
    //         }
    //       })
    //     }
    //   })
  }, [rewardsPageId]);

  const saveHeroSettings = () => {
    if (heroFormData?.title.replace(/\s/g, '') !== '') {
      let fomrdata = heroFormData;
      fomrdata.images = selectedImages;
      fomrdata.hero_css = customCss.hero_css;
      fomrdata.hero_status = bulkStatus.hero_status;
      if (rewardsPageId) {
        fomrdata.rewards_page_id = rewardsPageId;
      }
      fetch('/rewardsPage', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(fomrdata),
      })
        .then((response) => response.json())
        .then((data) => {
          toggleActive();
          setNameError(false);
          if (data?.reward_page_id) {
            history.push(
              `/pointsProgram/rewardsPage/${data?.reward_page_id}/${template_id}`
            );
          } else {
            history.push(`/pointsProgram/rewardsPage`);
          }
          // fetchEarnPointsSettings();
        });
    } else {
      setNameError(true);
    }
  };

  const saveCustomCss = (
    field_name,
    status_field_name,
    image_field_name = null,
    image_id = null
  ) => {
    let _formData = {
      shopify_domain: domain,
      template_id: template_id,
      update_css: { [`${field_name}`]: customCss[`${field_name}`] },
      update_status: {
        [`${status_field_name}`]: bulkStatus[`${status_field_name}`],
      },
    };
    if (image_field_name && image_id) {
      _formData['image_field'] = image_field_name;
      _formData['image_id'] = image_id;
    }
    if (rewardsPageId) {
      _formData.rewards_page_id = rewardsPageId;
    }

    if (field_name == 'ways_to_redeem_css') {
      _formData.ways_to_redeem_content = wrContentFormData;
    }
    fetch('/points_program/update_custom_css', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(_formData),
    })
      .then((response) => response.json())
      .then((data) => {
        toggleActive();
        setNameError(false);
        fetchRewardsPage();
        if (data?.reward_page_id) {
          history.push(
            `/pointsProgram/rewardsPage/${data?.reward_page_id}/${template_id}`
          );
        } else {
          history.push(`/pointsProgram/rewardsPage`);
        }
        // fetchEarnPointsSettings();
      });
  };

  const saveFaqs = () => {
    let noError = true;
    let errors = faqFieldErrors;
    faqFormData?.map((faq, i) => {
      if (!faq?.title || faq?.title == '') {
        noError = false;
        errors[i] = {};
        errors[i].title = 'Please enter Title';
      }
    });

    setFaqFieldErrors([...errors]);

    if (noError) {
      let _form_data = {
        faqs: faqFormData,
        shopify_domain: domain,
        template_id: template_id,
        faq_css: customCss?.faq,
        faq_status: bulkStatus.faq_status,
      };
      if (rewardsPageId) {
        _form_data.rewards_page_id = rewardsPageId;
      }
      fetch('/rewardsFaq', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(_form_data),
      })
        .then((response) => response.json())
        .then((data) => {
          toggleActive();
          setNameError(false);
          fetchRewardsPage();
          if (data?.reward_page_id) {
            history.push(
              `/pointsProgram/rewardsPage/${data?.reward_page_id}/${template_id}`
            );
          } else {
            history.push(`/pointsProgram/rewardsPage`);
          }
          // fetchEarnPointsSettings();
        });
    }
  };

  const saveWorks = () => {
    let noError = true;
    let errors = worksFieldErrors;
    var formdata = new FormData();

    worksFormData?.map((work, i) => {
      if (!work?.body || work?.body == '') {
        noError = false;
        errors[i] = {};
        errors[i].body = 'Please enter Title';
      }
      formdata.append(`work${i}_id`, work?.id ? work?.id : '');
      formdata.append(`work${i}_body`, work?.body);
      formdata.append(`work${i}_title`, work?.title);
      formdata.append(`work${i}_icon`, work?.icon ? work.icon : '');
    });

    setworksFieldErrors([...errors]);

    if (noError) {
      formdata.append(`works_count`, worksFormData.length);
      formdata.append(`shopify_domain`, domain);
      formdata.append(`template_id`, template_id);
      formdata.append(`how_it_works_css`, customCss?.how_it_works);
      formdata.append(`how_it_works_status`, bulkStatus.how_it_works_status);
      if (howItWorksImage) {
        formdata.append(`image_id`, howItWorksImage);
      }

      if (rewardsPageId) {
        formdata.append('rewards_page_id', rewardsPageId);
      }

      fetch('/create_how_it_works', {
        method: 'POST',
        body: formdata,
      })
        .then((response) => response.json())
        .then((data) => {
          toggleActive();
          setNameError(false);
          fetchRewardsPage();
          if (data?.reward_page_id) {
            history.push(
              `/pointsProgram/rewardsPage/${data?.reward_page_id}/${template_id}`
            );
          } else {
            history.push(`/pointsProgram/rewardsPage`);
          }
          // fetchEarnPointsSettings();
        });
    }
  };

  const saveFooterSettings = () => {
    if (footerFormData?.title.replace(/\s/g, '') !== '') {
      let fomrdata = footerFormData;
      fomrdata.image = selectedFooterImage;
      fomrdata.footer_css = customCss.footer;
      fomrdata.footer_status = bulkStatus.footer_status;
      fomrdata.rewards_page_id = rewardsPageId || '';
      fetch('/rewardsFooterPage', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(fomrdata),
      })
        .then((response) => response.json())
        .then((data) => {
          toggleActive();
          setNameError(false);
          if (data?.reward_page_id) {
            history.push(
              `/pointsProgram/rewardsPage/${data?.reward_page_id}/${template_id}`
            );
          } else {
            history.push(`/pointsProgram/rewardsPage`);
          }
          // fetchEarnPointsSettings();
        });
    } else {
      setNameError(true);
    }
  };

  const addMoreFaqs = () => {
    setFaqFormData([...faqFormData, { title: '', bodyHtml: '' }]);
  };
  const addMorewrContent = () => {
    setWrContentFormData([...wrContentFormData, { title: '', body: '' }]);
  };

  const addMoreBenefits = () => {
    setTierBenefitsFormData([...tierBenefitsFormData, { title: '', desc: '' }]);
  };

  const addMoreWorks = () => {
    setworksFormData([...worksFormData, { body: '', icon: '' }]);
  };

  const saveTierBenefits = () => {
    fetch('/create_tier_benefits', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        benefits_count: tierBenefitsFormData.length,
        benefits: tierBenefitsFormData,
        shopify_domain: domain,
        template_id: template_id,
        loyalty_tiers_css: customCss?.loyalty_tiers_css,
        loyalty_tiers_status: bulkStatus.loyalty_tiers_status,
        rewards_page_id: rewardsPageId || '',
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        toggleActive();
        setNameError(false);
        fetchRewardsPage();
        if (data?.reward_page_id) {
          history.push(
            `/pointsProgram/rewardsPage/${data?.reward_page_id}/${template_id}`
          );
        } else {
          history.push(`/pointsProgram/rewardsPage`);
        }
        s;
        // fetchEarnPointsSettings();
      });
  };

  // Loyalty Points Settings
  const fetchEarnPointsSettings = () => {
    fetch(`/loyalty_rules?shopify_domain=${domain}`, {
      method: 'GET',
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status) {
          setPurchase(data.settings?.purchase.toString());
          setAccount(data.settings?.account.toString());
          setSocialMediaLike(data.settings?.social_media_like.toString());
          setSocialMediaFollow(data.settings?.social_media_follow.toString());
          setUpsell(data.settings?.buy_upsell.toString());
          setSmartySms(data.settings?.signup_smarty_sms.toString());
          setReferFriend(data.settings?.refer_friend.toString());
          setMailingList(data.settings?.signup_mailing_list.toString());
          setCustomRule(data.settings?.custom_rule.toString());
        }
      });
  };

  const saveEarnPointsSettings = () => {
    fetch('/loyalty_rules', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        loyalty_id: loyaltyId,
        purchase: purchase,
        account: account,
        social_media_like: socialMediaLike,
        social_media_follow: socialMediaFollow,
        buy_upsell: upsell,
        signup_smarty_sms: smartySms,
        refer_friend: referFriend,
        signup_mailing_list: mailingList,
        custom_rule: customRule,
        shopify_domain: domain,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status) {
          if (data?.reward_page_id) {
            history.push(
              `/pointsProgram/rewardsPage/${data?.reward_page_id}/${template_id}`
            );
          } else {
            history.push(`/pointsProgram/rewardsPage`);
          }
          // setShowRedeemForm(true);
          // fetchRedeemPointsSettings();
        }
      });
  };

  // Loyalty Redeem Points Settings
  const fetchRedeemPointsSettings = () => {
    fetch(`/loyalty_redeem_points?shopify_domain=${domain}`, {
      method: 'GET',
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status) {
          setDiscount(data.settings?.discount.toString());
          setPoint(data.settings?.point.toString());
          setStartingDate(data.settings?.starting_date.toString());
          setEndingDate(data.settings?.ending_date.toString());
        }
      });
  };

  const saveRedeemPointsSettings = () => {
    fetch('/loyalty_redeem_points', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        loyalty_id: loyaltyId,
        discount: discount,
        point: point,
        starting_date: startingDate,
        ending_date: endingDate,
        shopify_domain: domain,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data?.reward_page_id) {
          history.push(
            `/pointsProgram/rewardsPage/${data?.reward_page_id}/${template_id}`
          );
        } else {
          history.push(`/pointsProgram/rewardsPage`);
        }
      });
  };

  // Update Campaign Status
  const updateCampaignStatus = (name, status) => {
    fetch('/loyalty_campaign/update_status', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        name: name,
        status: !status,
        shopify_domain: domain,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status) {
          toggleStatusActive();
          if (data.campaign_name == 'refer_a_friend')
            setReferCampaignStatus(data.campaign_status);
          else if (data.campaign_name == 'create_account')
            setAccountCampaignStatus(data.campaign_status);
          else if (data.campaign_name == 'happy_birthday')
            setBirthdayCampaignStatus(data.campaign_status);
          else if (data.campaign_name == 'make_a_purchase')
            setPurchaseCampaignStatus(data.campaign_status);
        }
      });
  };

  const toastMarkup = active ? (
    <Frame>
      <Toast
        content={i18n.t('points_programs.data_updated')}
        onDismiss={toggleActive}
        duration={3000}
      />
    </Frame>
  ) : statusActive ? (
    <Frame>
      <Toast
        content={i18n.t('points_programs.data_updated')}
        onDismiss={toggleStatusActive}
        duration={3000}
      />
    </Frame>
  ) : null;

  const toggleElement = (arr, val) => {
    let newArr = arr.includes(val)
      ? arr.filter((el) => el !== val)
      : [...arr, val];
    console.log('testing', arr, val, newArr);
    return newArr;
  };

  return (
    <>
      <FormLayout>
        <Card
          sectioned
          primaryFooterAction={{
            content: i18n.t('points_programs.save_and_continue'),
            onAction: saveHeroSettings,
          }}
          footerActionAlignment="left"
        >
          <div className="card-box">
            <div className="rewards_settings main-title">
              <span className="heading_icons">
                <GetStartedIcon />
              </span>
              <span className="title_wrapper">
                {i18n.t('points_programs.hero')}
              </span>
            </div>
            <Stack distribution="fill">
              <TextField
                type="text"
                label={i18n.t('points_programs.hero_title')}
                placeholder="Welcome to STAIT Rewards"
                value={heroFormData?.title}
                onChange={useCallback((newValue) =>
                  setHeroFormData({ ...heroFormData, title: newValue })
                )}
                error={nameError}
                autoComplete="off"
                helpText={
                  <span>
                    {i18n.t('points_programs.internal_name_of_group')}
                  </span>
                }
              />
              <TextField
                type="text"
                label={i18n.t('points_programs.hero_title2')}
                placeholder="Welcome to STAIT Rewards"
                value={heroFormData?.title2}
                onChange={useCallback((newValue) =>
                  setHeroFormData({ ...heroFormData, title2: newValue })
                )}
                error={nameError}
                autoComplete="off"
                helpText={
                  <span>{i18n.t('points_programs.hero_title2_help_text')}</span>
                }
              />
              <TextField
                type="text"
                label={i18n.t('points_programs.sub_title')}
                placeholder={i18n.t('points_programs.customer_portal')}
                value={heroFormData?.subtitle}
                onChange={useCallback((newValue) =>
                  setHeroFormData({ ...heroFormData, subtitle: newValue })
                )}
                error={nameError}
                autoComplete="off"
                helpText={
                  <span>
                    {i18n.t('points_programs.internal_name_of_group')}
                  </span>
                }
              />
              <TextField
                type="text"
                label={i18n.t('points_programs.page_handle')}
                placeholder={i18n.t('points_programs.page_handle')}
                value={heroFormData?.page_handle}
                onChange={useCallback((newValue) =>
                  setHeroFormData({ ...heroFormData, page_handle: newValue })
                )}
                error={nameError}
                autoComplete="off"
                helpText={
                  <span>{i18n.t('points_programs.page_handle_desc')}</span>
                }
              />
            </Stack>
            <div className="image_selector_main">
              <div className="thumbnail_image">
                {allImages?.map((image) => {
                  return (
                    <img
                      alt=""
                      className={
                        selectedImages?.includes(image.id) ? `active` : ''
                      }
                      width="100%"
                      height="100%"
                      src={image?.url}
                      onClick={() => {
                        let imagesArr = [];
                        imagesArr = toggleElement(selectedImages, image.id);
                        setSelectedImages(imagesArr);
                        console.log('imagesArr', imagesArr);
                      }}
                    />
                  );
                })}
              </div>
            </div>
            <div className="holder color-holder">
              <h3>{i18n.t('colors')}</h3>
              <Stack>
                <TextField
                  label={i18n.t('pick_color')}
                  type="color"
                  placeholder={i18n.t('none')}
                  value={heroFormData?.primary_theme_color}
                  onChange={useCallback((newValue) =>
                    setHeroFormData({
                      ...heroFormData,
                      primary_theme_color: newValue,
                    })
                  )}
                  autoComplete="off"
                />
                <TextField
                  label={i18n.t('primary_theme_color')}
                  type="text"
                  placeholder={i18n.t('none')}
                  value={heroFormData?.primary_theme_color}
                  onChange={useCallback((newValue) =>
                    setHeroFormData({
                      ...heroFormData,
                      primary_theme_color: newValue,
                    })
                  )}
                  autoComplete="off"
                />
                <TextField
                  label={i18n.t('pick_color')}
                  type="color"
                  placeholder={i18n.t('none')}
                  value={heroFormData?.secondary_theme_color}
                  onChange={useCallback((newValue) =>
                    setHeroFormData({
                      ...heroFormData,
                      secondary_theme_color: newValue,
                    })
                  )}
                  autoComplete="off"
                />
                <TextField
                  label={i18n.t('secondary_theme_color')}
                  type="text"
                  placeholder={i18n.t('none')}
                  value={heroFormData?.secondary_theme_color}
                  onChange={useCallback((newValue) =>
                    setHeroFormData({
                      ...heroFormData,
                      secondary_theme_color: newValue,
                    })
                  )}
                  autoComplete="off"
                />
              </Stack>
            </div>
            <div className="mt-3 color-holder">
              <Stack>
                <TextField
                  label={i18n.t('pick_color')}
                  type="color"
                  placeholder={i18n.t('none')}
                  value={heroFormData?.primary_text_color}
                  onChange={useCallback((newValue) =>
                    setHeroFormData({
                      ...heroFormData,
                      primary_text_color: newValue,
                    })
                  )}
                  autoComplete="off"
                />
                <TextField
                  label={i18n.t('primary_text_color')}
                  type="text"
                  placeholder={i18n.t('none')}
                  value={heroFormData?.primary_text_color}
                  onChange={useCallback((newValue) =>
                    setHeroFormData({
                      ...heroFormData,
                      primary_text_color: newValue,
                    })
                  )}
                  autoComplete="off"
                />
                <TextField
                  label={i18n.t('pick_color')}
                  type="color"
                  placeholder={i18n.t('none')}
                  value={heroFormData?.secondary_text_color}
                  onChange={useCallback((newValue) =>
                    setHeroFormData({
                      ...heroFormData,
                      secondary_text_color: newValue,
                    })
                  )}
                  autoComplete="off"
                />
                <TextField
                  label={i18n.t('secondary_text_color')}
                  type="text"
                  placeholder={i18n.t('none')}
                  value={heroFormData?.secondary_text_color}
                  onChange={useCallback((newValue) =>
                    setHeroFormData({
                      ...heroFormData,
                      secondary_text_color: newValue,
                    })
                  )}
                  autoComplete="off"
                />
              </Stack>
            </div>

            <div className="holder color-holder">
              <h3>{i18n.t('points_programs.customize_styles')}</h3>

              <TextField
                label={i18n.t('points_programs.customize_hero_section')}
                type="color"
                placeholder={i18n.t('none')}
                multiline={5}
                value={customCss?.hero_css}
                onChange={useCallback((newValue) =>
                  setCustomCss({ ...customCss, hero_css: newValue })
                )}
                autoComplete="off"
              />
            </div>
            <div className="holder color-holder">
              <h3>{i18n.t('status')}</h3>
              <Checkbox
                label={i18n.t('status')}
                checked={bulkStatus?.hero_status}
                onChange={(newValue) =>
                  setBulkStatus({ ...bulkStatus, hero_status: newValue })
                }
              />
            </div>
          </div>
        </Card>

        <Card
          sectioned
          primaryFooterAction={{
            content: i18n.t('points_programs.save_and_continue'),
            onAction: () => {
              saveCustomCss(
                'ways_to_redeem_css',
                'ways_to_redeem_status',
                'ways_to_redeem_image',
                waysToRedeemImages
              );
            },
          }}
          footerActionAlignment="left"
        >
          <div className="card-box">
            <div className="rewards_settings main-title">
              <span className="heading_icons">
                <GetStartedIcon />
              </span>
              <span className="title_wrapper">
                {i18n.t('points_programs.ways_to_redeem_points')}
              </span>
            </div>
            <h3 className="m-0">{i18n.t('points_programs.reward_rules')}</h3>
            <p>{i18n.t('points_programs.choose_rewards_to_redeem')}</p>
            <div className="holder">
              <div className="cards-list">
                {apiData?.ways_to_redeem?.map((redeem) => {
                  let title =
                    redeem?.title == 'Percentage off' ||
                    redeem?.title == 'POS percentage off'
                      ? i18n.t('points_programs.percentage_off_summary', {
                          discount_value: redeem?.discount_value,
                        })
                      : redeem?.title == 'Free Shipping'
                      ? i18n.t(
                          'points_programs.free_shipping_entire_order_summary'
                        )
                      : redeem?.title == 'Amount discount'
                      ? redeem?.discount_value != 0
                        ? i18n.t('points_programs.discount_value_summary', {
                            discount_value: redeem?.discount_value,
                          })
                        : i18n.t(
                            'points_programs.get_discount_entire_order_summary',
                            {
                              customer_gets: redeem?.customer_gets,
                              customer_redeems_increment:
                                redeem?.customer_redeems_increment,
                            }
                          )
                      : redeem?.title == 'POS amount discount'
                      ? i18n.t('points_programs.discount_value_summary', {
                          discount_value: redeem?.discount_value,
                        })
                      : '';
                  return (
                    <Card sectioned>
                      <div
                        className="card-header"
                        id={referCampaignStatus ? 'activated' : 'not-activated'}
                      >
                        <Heading element="h2">{title}</Heading>
                        <div className="action_button_wrapper">
                          <button className="action_button sucess_button">
                            {i18n.t('points_programs.enable')}
                          </button>
                          <button className="action_button warning_button">
                            {i18n.t('points_programs.inactive')}
                          </button>
                        </div>
                      </div>
                      <div className="card-body"></div>
                      <div className="card-footer">
                        <Stack>
                          <Stack.Item fill>
                            <p>
                              {i18n.t('points_programs.zero_coupon_redeemed')}
                            </p>
                          </Stack.Item>
                        </Stack>
                      </div>
                    </Card>
                  );
                })}
              </div>
            </div>
            <div className="image_selector_main">
              <div className="thumbnail_image_with_label">
                <h5 className="custom_image_label">
                  {i18n.t('points_programs.ways_to_redeem_image_label')}
                </h5>
                <div className="thumbnail_image">
                  {allImages?.map((image) => {
                    return (
                      <img
                        alt=""
                        className={
                          waysToRedeemImages == image.id ? `active` : ''
                        }
                        width="100%"
                        height="100%"
                        src={image?.url}
                        onClick={() => {
                          setWaysToRedeemImages(image.id);
                        }}
                      />
                    );
                  })}
                </div>
                <div className="way_to_redeem_content_wrapper">
                  <h3 className="m-0">
                    {i18n.t('points_programs.ways_to_redeem_content_block')}
                  </h3>
                  {/* <p>{i18n.t("points_programs.create_faq_appear_on_reward_page")}</p> */}
                  <div className="holder">
                    {wrContentFormData?.map((faq, index) => {
                      return (
                        <CreateWaysToRedeemContent
                          i18n={i18n}
                          domain={domain}
                          wrContentFormData={wrContentFormData}
                          setWrContentFormData={setWrContentFormData}
                          index={index}
                          wrContentFieldErrors={wrContentFieldErrors}
                          setWrContentFieldErrors={setWrContentFieldErrors}
                        />
                      );
                    })}
                    <button
                      className="add_more_wr_content"
                      onClick={() => {
                        addMorewrContent();
                      }}
                    >
                      {i18n.t('add_more_wr_content')}
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="holder color-holder">
              <h3>{i18n.t('points_programs.customize_styles')}</h3>

              <TextField
                label={i18n.t('points_programs.customize_hero_css')}
                type="color"
                placeholder={i18n.t('none')}
                multiline={5}
                value={customCss?.ways_to_redeem_css}
                onChange={useCallback((newValue) =>
                  setCustomCss({ ...customCss, ways_to_redeem_css: newValue })
                )}
                autoComplete="off"
              />
            </div>
            <div className="holder color-holder">
              <h3>{i18n.t('status')}</h3>
              <Checkbox
                label={i18n.t('status')}
                checked={bulkStatus?.ways_to_redeem_status}
                onChange={(newValue) =>
                  setBulkStatus({
                    ...bulkStatus,
                    ways_to_redeem_status: newValue,
                  })
                }
              />
            </div>
          </div>
        </Card>

        <Card
          sectioned
          primaryFooterAction={{
            content: i18n.t('points_programs.add_more_highlights'),
            onAction: addMoreWorks,
          }}
          footerActionAlignment="left"
        >
          <div className="card-box">
            <div className="rewards_settings main-title">
              <span className="heading_icons">
                <GetStartedIcon />
              </span>
              <span className="title_wrapper">
                {i18n.t('points_programs.how_it_works')}
              </span>
            </div>
            <h3 className="m-0">
              {i18n.t('points_programs.how_it_works_section')}
            </h3>
            <p>
              {i18n.t(
                'points_programs.highlight_explain_details_points_program'
              )}
            </p>
            <div className="image_selector_main">
              <div className="thumbnail_image_with_label">
                <h5 className="custom_image_label">
                  {i18n.t('points_programs.how_it_works_image_label')}
                </h5>
                <div className="thumbnail_image">
                  {allImages?.map((image) => {
                    return (
                      <img
                        alt=""
                        className={howItWorksImage == image.id ? `active` : ''}
                        width="100%"
                        height="100%"
                        src={image?.url}
                        onClick={() => {
                          setHowItWorksImage(image.id);
                        }}
                      />
                    );
                  })}
                </div>
              </div>
            </div>
            <div className="holder">
              {worksFormData?.map((work, index) => {
                return (
                  <CreateHowItWorks
                    i18n={i18n}
                    domain={domain}
                    worksFormData={worksFormData}
                    setworksFormData={setworksFormData}
                    index={index}
                    worksFieldErrors={worksFieldErrors}
                    setworksFieldErrors={setworksFieldErrors}
                  />
                );
              })}
            </div>
            <div className="holder color-holder">
              <h3>{i18n.t('points_programs.customize_styles')}</h3>

              <TextField
                label={i18n.t('points_programs.customize_hero_section')}
                type="color"
                placeholder={i18n.t('none')}
                multiline={5}
                value={customCss?.how_it_works}
                onChange={useCallback((newValue) =>
                  setCustomCss({ ...customCss, how_it_works: newValue })
                )}
                autoComplete="off"
              />
            </div>
            <div className="holder color-holder">
              <h3>{i18n.t('status')}</h3>
              <Checkbox
                label={i18n.t('status')}
                checked={bulkStatus?.how_it_works_status}
                onChange={(newValue) =>
                  setBulkStatus({
                    ...bulkStatus,
                    how_it_works_status: newValue,
                  })
                }
              />
            </div>
          </div>
          <div className="button_wrapper">
            <Button onClick={() => saveWorks()}>
              {i18n.t('points_programs.save_highlight')}
            </Button>
          </div>
        </Card>

        <Card
          sectioned
          primaryFooterAction={{
            content: 'Save and Continue',
            onAction: () => {
              saveCustomCss(
                'ways_to_earn_css',
                'ways_to_earn_status',
                'ways_to_earn_image',
                waysToEarnImages
              );
            },
          }}
          footerActionAlignment="left"
        >
          <div className="card-box">
            <div className="rewards_settings main-title">
              <span className="heading_icons">
                <GetStartedIcon />
              </span>
              <span className="title_wrapper">
                {i18n.t('points_programs.how_to_earn_points')}
              </span>
            </div>
            <h3 className="m-0">{i18n.t('points_programs.points_rules')}</h3>
            <p>{i18n.t('points_programs.create_ways_to_earn_share_engage')}</p>
            <div className="holder">
              <div className="cards-list">
                {apiData?.ways_to_earn?.map((earn) => {
                  return (
                    <Card sectioned>
                      <div
                        className="card-header"
                        id={referCampaignStatus ? 'activated' : 'not-activated'}
                      >
                        <Heading element="h2">
                          {earn.title} <br />
                          <small>{earn.title}</small>
                        </Heading>
                      </div>
                      <div className="card-body">
                        <p>{earn?.summary}</p>
                      </div>
                      <div className="card-footer">
                        <Stack>
                          <Stack.Item fill>
                            <p>
                              {i18n.t('points_programs.started_on')}{' '}
                              {dayjs(earn.created_at).format('DD/MM/YYYY')}
                            </p>
                          </Stack.Item>
                          <Stack.Item>
                            <p>
                              {i18n.t(
                                'points_programs.zero_referrals_completed'
                              )}
                            </p>
                          </Stack.Item>
                        </Stack>
                      </div>
                    </Card>
                  );
                })}

                <Card sectioned>
                  <div
                    className="card-header"
                    id={accountCampaignStatus ? 'activated' : 'not-activated'}
                  >
                    <Heading element="h2">
                      {i18n.t('points_programs.create_account')} <br />
                      <small>{i18n.t('points_programs.create_account')}</small>
                    </Heading>
                  </div>
                  <div className="card-body">
                    <p>
                      {i18n.t('points_programs.earn_ten_points_create_account')}
                    </p>
                  </div>
                  <div className="card-footer">
                    <Stack>
                      <Stack.Item fill>
                        <p>{i18n.t('points_programs.started_on')} 10/25/21</p>
                      </Stack.Item>
                      <Stack.Item>
                        <p>
                          {i18n.t('points_programs.zero_referrals_completed')}
                        </p>
                      </Stack.Item>
                    </Stack>
                  </div>
                </Card>
                <Card sectioned>
                  <div
                    className="card-header"
                    id={birthdayCampaignStatus ? 'activated' : 'not-activated'}
                  >
                    <Heading element="h2">
                      {i18n.t('points_programs.happy_birthday')} <br />
                      <small>{i18n.t('points_programs.happy_birthday')}</small>
                    </Heading>
                  </div>
                  <div className="card-body">
                    <p>{i18n.t('points_programs.earn_five_points_birthday')}</p>
                    <p>$5.00</p>
                  </div>
                  <div className="card-footer">
                    <Stack>
                      <Stack.Item fill>
                        <p>{i18n.t('points_programs.started_on')} 10/25/21</p>
                      </Stack.Item>
                      <Stack.Item>
                        <p>
                          {i18n.t('points_programs.zero_referrals_completed')}
                        </p>
                      </Stack.Item>
                    </Stack>
                  </div>
                </Card>
                <Card sectioned>
                  <div
                    className="card-header"
                    id={purchaseCampaignStatus ? 'activated' : 'not-activated'}
                  >
                    <Heading element="h2">
                      {i18n.t('points_programs.points_for_purchase')} <br />
                      <small>{i18n.t('points_programs.make_a_purchase')}</small>
                    </Heading>
                  </div>
                  <div className="card-body">
                    <p>Earn 1 points for every $1.00 you spent in our store</p>
                  </div>
                  <div className="card-footer">
                    <Stack>
                      <Stack.Item fill>
                        <p>Started on 10/25/21</p>
                      </Stack.Item>
                      <Stack.Item>
                        <p>0 Accounts Created</p>
                      </Stack.Item>
                    </Stack>
                  </div>
                </Card>
              </div>
            </div>
            <div className="image_selector_main">
              <div className="thumbnail_image_with_label">
                <h5 className="custom_image_label">
                  {i18n.t('points_programs.ways_to_earn_image_label')}
                </h5>
                <div className="thumbnail_image">
                  {allImages?.map((image) => {
                    return (
                      <img
                        alt=""
                        className={waysToEarnImages == image.id ? `active` : ''}
                        width="100%"
                        height="100%"
                        src={image?.url}
                        onClick={() => {
                          setWaysToEarnImages(image.id);
                        }}
                      />
                    );
                  })}
                </div>
              </div>
            </div>
            <div className="holder color-holder">
              <h3>{i18n.t('points_programs.customize_styles')}</h3>

              <TextField
                label="Customise Hero Section Css"
                type="color"
                placeholder={i18n.t('none')}
                multiline={5}
                value={customCss?.ways_to_earn_css}
                onChange={useCallback((newValue) =>
                  setCustomCss({ ...customCss, ways_to_earn_css: newValue })
                )}
                autoComplete="off"
              />
            </div>
            <div className="holder color-holder">
              <h3>{i18n.t('status')}</h3>
              <Checkbox
                label={i18n.t('status')}
                checked={bulkStatus?.ways_to_earn_status}
                onChange={(newValue) =>
                  setBulkStatus({
                    ...bulkStatus,
                    ways_to_earn_status: newValue,
                  })
                }
              />
            </div>
          </div>
        </Card>

        <Card
          sectioned
          primaryFooterAction={{
            content: i18n.t('points_programs.add_more_benefits'),
            onAction: addMoreBenefits,
          }}
          footerActionAlignment="left"
        >
          <div className="card-box">
            <div className="rewards_settings main-title">
              <span className="heading_icons">
                <GetStartedIcon />
              </span>
              <span className="title_wrapper">
                {i18n.t('points_programs.points_program_tiers')}
              </span>
            </div>
            <h3 className="m-0">{i18n.t('points_programs.tiers_block')}</h3>
            <div className="tiers_wrapper_main">
              {apiData?.points_program_tiers?.map((tier, i) => {
                return (
                  <div className="tier_wrapper">
                    {tier?.title}
                    <div className="manage_benefits_wrapper">
                      {apiData?.program_benefits?.map((benefit) => {
                        return (
                          <Checkbox
                            label={benefit?.title}
                            checked={
                              tierBenefits[tier.id]?.find(
                                (b) => +b.id == +benefit.id
                              )?.available
                            }
                            onChange={() => {
                              changeBenefitState(tier.id, benefit.id);
                            }}
                          />
                        );
                      })}
                    </div>
                  </div>
                );
              })}
            </div>
            <h3>{i18n.t('points_programs.create_tier_benefits')}</h3>
            <div className="holder">
              {tierBenefitsFormData?.map((tier, index) => {
                return (
                  <CreateTierBenefits
                    domain={domain}
                    tierFormData={tierBenefitsFormData}
                    setTierFormData={setTierBenefitsFormData}
                    index={index}
                    i18n={i18n}
                  />
                );
              })}
            </div>
            <div className="holder color-holder">
              <h3>{i18n.t('points_programs.customize_styles')}</h3>

              <TextField
                label={i18n.t('points_programs.customize_hero_section')}
                type="color"
                placeholder={i18n.t('none')}
                multiline={5}
                value={customCss?.loyalty_tiers_css}
                onChange={useCallback((newValue) =>
                  setCustomCss({ ...customCss, loyalty_tiers_css: newValue })
                )}
                autoComplete="off"
              />
            </div>
            <div className="holder color-holder">
              <h3>{i18n.t('status')}</h3>
              <Checkbox
                label={i18n.t('status')}
                checked={bulkStatus?.loyalty_tiers_status}
                onChange={(newValue) =>
                  setBulkStatus({
                    ...bulkStatus,
                    loyalty_tiers_status: newValue,
                  })
                }
              />
            </div>
          </div>
          <div className="button_wrapper">
            <Button onClick={() => saveTierBenefits()}>
              {i18n.t('points_programs.save_tiers')}
            </Button>
          </div>
        </Card>

        <Card
          sectioned
          primaryFooterAction={{
            content: i18n.t('points_programs.add_more_faqs'),
            onAction: addMoreFaqs,
          }}
          footerActionAlignment="left"
        >
          <div className="card-box">
            <div className="rewards_settings main-title">
              <span className="heading_icons">
                <GetStartedIcon />
              </span>
              <span className="title_wrapper">
                {i18n.t('points_programs.faq')}
              </span>
            </div>
            <h3 className="m-0">{i18n.t('points_programs.faq_blocks')}</h3>
            <p>{i18n.t('points_programs.create_faq_appear_on_reward_page')}</p>
            <div className="holder">
              {faqFormData?.map((faq, index) => {
                return (
                  <CreatePointsProgramFaq
                    i18n={i18n}
                    domain={domain}
                    faqFormData={faqFormData}
                    setFaqFormData={setFaqFormData}
                    index={index}
                    faqFieldErrors={faqFieldErrors}
                    setFaqFieldErrors={setFaqFieldErrors}
                  />
                );
              })}
            </div>
            <div className="holder color-holder">
              <h3>{i18n.t('points_programs.customize_styles')}</h3>

              <TextField
                label={i18n.t('points_programs.customize_hero_section')}
                type="color"
                placeholder={i18n.t('none')}
                multiline={5}
                value={customCss?.faq}
                onChange={useCallback((newValue) =>
                  setCustomCss({ ...customCss, faq: newValue })
                )}
                autoComplete="off"
              />
            </div>
            <div className="holder color-holder">
              <h3>{i18n.t('status')}</h3>
              <Checkbox
                label={i18n.t('status')}
                checked={bulkStatus?.faq_status}
                onChange={(newValue) =>
                  setBulkStatus({ ...bulkStatus, faq_status: newValue })
                }
              />
            </div>
          </div>
          <div className="button_wrapper">
            <Button onClick={() => saveFaqs()}>
              {i18n.t('points_programs.save_faqs')}
            </Button>
          </div>
        </Card>

        <Card
          sectioned
          primaryFooterAction={{
            content: i18n.t('points_programs.save_and_continue'),
            onAction: saveFooterSettings,
          }}
          footerActionAlignment="left"
        >
          <div className="card-box">
            <div className="rewards_settings main-title">
              <span className="heading_icons">
                <GetStartedIcon />
              </span>
              <span className="title_wrapper">
                {i18n.t('points_programs.footer')}
              </span>
            </div>
            <Stack distribution="fill">
              <TextField
                type="text"
                label={i18n.t('title')}
                placeholder=""
                value={footerFormData?.title}
                onChange={useCallback((newValue) =>
                  setfooterFormData({ ...footerFormData, title: newValue })
                )}
                error={nameError}
                autoComplete="off"
                helpText={<span>{i18n.t('points_programs.footer_title')}</span>}
              />
              <TextField
                type="text"
                label={i18n.t('points_programs.paragraph')}
                multiline={5}
                placeholder=""
                value={footerFormData?.paragraph}
                onChange={useCallback((newValue) =>
                  setfooterFormData({ ...footerFormData, paragraph: newValue })
                )}
                error={nameError}
                autoComplete="off"
                helpText={<span></span>}
              />
            </Stack>
            <div className="image_selector_main">
              <div className="thumbnail_image">
                {allImages?.map((image) => {
                  return (
                    <img
                      alt=""
                      className={
                        selectedFooterImage == image.id ? `active` : ''
                      }
                      width="100%"
                      height="100%"
                      src={image?.url}
                      onClick={() => {
                        setSelectedFooterImage(image.id);
                      }}
                    />
                  );
                })}
              </div>
            </div>
            <Stack distribution="fill">
              <TextField
                type="text"
                label={i18n.t('points_programs.button_title')}
                placeholder=""
                value={footerFormData?.button_title}
                onChange={useCallback((newValue) =>
                  setfooterFormData({
                    ...footerFormData,
                    button_title: newValue,
                  })
                )}
                error={nameError}
                autoComplete="off"
                helpText={<span>{i18n.t('points_programs.button_title')}</span>}
              />
              <TextField
                type="text"
                label={i18n.t('points_programs.button_url')}
                placeholder=""
                value={footerFormData?.button_url}
                onChange={useCallback((newValue) =>
                  setfooterFormData({ ...footerFormData, button_url: newValue })
                )}
                error={nameError}
                autoComplete="off"
                helpText={<span></span>}
              />
            </Stack>
            <div className="holder color-holder">
              <h3>{i18n.t('points_programs.customize_styles')}</h3>

              <TextField
                label={i18n.t('points_programs.customize_hero_section')}
                type="color"
                placeholder={i18n.t('none')}
                multiline={5}
                value={customCss?.footer}
                onChange={useCallback((newValue) =>
                  setCustomCss({ ...customCss, footer: newValue })
                )}
                autoComplete="off"
              />
            </div>
            <div className="holder color-holder">
              <h3>{i18n.t('status')}</h3>
              <Checkbox
                label={i18n.t('status')}
                checked={bulkStatus?.footer_status}
                onChange={(newValue) =>
                  setBulkStatus({ ...bulkStatus, footer_status: newValue })
                }
              />
            </div>
          </div>
        </Card>

        {toastMarkup}
      </FormLayout>
      <ManageTranslations i18n={i18n} domain={domain} setActive={setActive} />
    </>
  );
};
export default Customize;
