import { Icon } from '@shopify/polaris';
import React from 'react';

function CircleChevronMinor() {
    return (
        <svg width="11" height="16" viewBox="0 0 11 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7.93891 0.938995L0.878906 8L7.93891 15.061L10.0609 12.939L5.12091 8L10.0609 3.061L7.93891 0.938995Z" fill="#445BFF" />
        </svg>
    );
}

export default CircleChevronMinor;
