import React, { useState } from 'react'
import { MobileCancelMajor } from '@shopify/polaris-icons';
import { Button, Icon, TextField } from '@shopify/polaris';

const ExportModal = (props) => {
    const { domain, apiUrl, showExport, setShowExport, toastContent, setToastContent, ownerEmail, dateFilter, selectedDates } = props;
    const [formData, setFormData] = useState(ownerEmail || '')

    const closeModal = () => {
        setShowExport(false)
    }

    const handleSubmit = () => {
        fetch(`${apiUrl}?shop=${domain}&email=${formData}&billing_range=${dateFilter || ''}&start_date=${selectedDates?.start || ''}&end_date=${selectedDates?.end || ''}`, {
            method: "GET"
        }).then((response) => response.json())
            .then((data) => {
                setToastContent("Export process started successfully...");
                closeModal();
            })
    }


    return (
        <div className="chargezen_search_model chargezen_export_modal product_variant_picker">
            <div className="modal-content-wrapper">
                <div className="chargezen_search_model_header_container">
                    <h2>Export Inventory forecast Report</h2>
                    <div className='closeModal' onClick={() => closeModal()}>
                        <Icon source={MobileCancelMajor} />
                    </div>
                </div>
                <div className="chargezen_search_model_body">
                    <div className='desc_wrapper'>Report will be exported as an CSV file & emailed to '{formData}'</div>
                    <TextField
                        label="Your email:"
                        value={formData}
                        onChange={(e) => setFormData(e)}
                    />
                </div>
                <div className="chargezen_search_model_footer justify-between">
                    <div className="action_buttons">
                        <Button onClick={() => closeModal()}>Cancel</Button>
                        <Button
                            primary
                            onClick={() => {
                                handleSubmit();
                            }}
                            disabled={!formData}
                        >
                            Export
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ExportModal
