import { TextField } from "@shopify/polaris";
import React, { useEffect, useState } from "react";

const ProductTag = (props) => {
    const { formData, setFormData, index } = props;
    const [tag, setTag] = useState("")

    useEffect(() => {
        if (formData?.actions[index]?.tag) {
            setTag(formData?.actions[index]?.tag)
        }
    }, [])

    useEffect(() => {
        let form_data_conditions = formData?.actions;
        form_data_conditions[index]["tag"] = tag;
        setFormData({ ...formData, actions: form_data_conditions })
    }, [tag])

    return (
        <TextField
            type="text"
            value={tag}
            onChange={(e) => {
                setTag(e)
            }}
        />
    )
}

export default ProductTag;