import React from 'react';
const SummaryFullWidthStyle = () => {
  const stylecss = `
    .sidebar_summary_main_container>.sidebar_summary_right_section {
      display: none !important;
    }
  
    .sidebar_summary_main_container>.sidebar_summary_main_left_section {
        padding-left: 0% !important;
    }
    `;
  return <style dangerouslySetInnerHTML={{ __html: stylecss }} />;
};

export default SummaryFullWidthStyle;
