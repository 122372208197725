import React, { useEffect, useState } from 'react';
import {
  Badge,
  Button,
  ButtonGroup,
  Card,
  DataTable,
  Frame,
  Icon,
  Layout,
  Page,
  Stack,
} from '@shopify/polaris';
import {
  MobilePlusMajor,
  MobileBackArrowMajor,
  ChevronRightMinor,
} from '@shopify/polaris-icons';
import { Link, useHistory, useParams } from 'react-router-dom';
import CreateQuickActions from './CreateQuickActions';

const RenderQuickActions = (props) => {
  const { i18n, domain, detailView, setDetailView, formatDate } = props;
  const { edit_id, selected_tab } = useParams();
  const [editId, setEdit] = useState(edit_id);
  const [createForm, setCreateForm] = useState(false);
  const [quickActions, setQuickActions] = useState([]);
  const history = useHistory();

  useEffect(() => {
    if (editId !== edit_id) {
      history.push(`/customersTab/quickActions/${editId}`);
    }
  }, [editId]);

  const fetchWorkflows = () => {
    fetch(`/fetch_subscription_workflows?shopify_domain=${domain}`, {
      method: 'GET',
    })
      .then((r) => r.json())
      .then((data) => {
        if (data?.quickActions?.length > 0) {
          setQuickActions([...data?.quickActions]);
        } else {
          setQuickActions([]);
        }
      });
  };

  useEffect(() => {
    fetchWorkflows();
  }, []);

  //upper first letter
  const capitalize = (s) => {
    if (typeof s !== 'string') return '';
    return s.charAt(0).toUpperCase() + s.slice(1);
  };
  const formatRows = (rows) => {
    return rows?.map((row, i) => {
      return [
        <a
          onClick={() => {
            history.push(`/customersTab/quickActions/${row.id}`);
          }}
        >{`${row?.name}`}</a>,
        <div className="worflow_summary_wrapper">
          <div className="workflow_summary">{row?.summary_events}</div>
          <div className="workflow_summary">{row?.summary_condition}</div>
        </div>,
        <div className={row.status == 'active' ? 'active' : 'disabled'}>
          <Badge>{capitalize(row.status)}</Badge>
        </div>,
        formatDate(row.created_at),
      ];
    });
  };

  useEffect(() => {
    if (edit_id === 'createNew') {
      setCreateForm(true);
    } else if (edit_id) {
      setEdit(edit_id);
    }
  });

  return (
    <div className="">
      {createForm || edit_id ? (
        <CreateQuickActions
          setEdit={setEdit}
          editId={editId}
          setCreateForm={setCreateForm}
          i18n={i18n}
          detailView={detailView}
          setDetailView={setDetailView}
          domain={domain}
        />
      ) : (
        <Layout>
          <Layout.Section>
            <div className="without_header_card">
              <Card
                actions={{
                  content: (
                    <>
                      <div className="card_header_button_group">
                        <div className="card_header_buttons secondary_button_group">
                          <Button
                            primary
                            onClick={() => {
                              history.push(
                                `/customersTab/quickActions/createNew`
                              );
                            }}
                          >
                            Create Quick Action
                          </Button>
                        </div>
                      </div>
                    </>
                  ),
                }}
              >
                <Card.Section>
                  <div
                    className={'table customer-subscription-tbl workflow_table'}
                  >
                    <DataTable
                      columnContentTypes={['text', 'text', 'text']}
                      headings={['Action', 'Condition', 'Klaviyo code block']}
                      rows={quickActions ? formatRows(quickActions) : []}
                    />
                  </div>
                </Card.Section>
              </Card>
            </div>
          </Layout.Section>
        </Layout>
      )}
    </div>
  );
};

export default RenderQuickActions;
