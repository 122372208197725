import React, { useEffect, useState } from "react";
import {
    Card,
    Frame,
    Page,
    Tabs,
    AppProvider,
    Form,
    FormLayout,
    TextField,
    Select,
    Button
} from "@shopify/polaris";
import "@shopify/polaris/dist/styles.css";
import { useCallback } from "react";

const ManageTranslations = (props) => {
    const {
        i18n,
        domain,
        setToasContent,
        setFormData,
        formData,
        setSelectedLanguage,
        selectedLanguage
    } = props;
    const [selected, setSelected] = useState(0);
    const handleTabChange = useCallback(
        (selectedTabIndex) => setSelected(selectedTabIndex),
        []
    );





    const supportedLanguages = [
        { label: "Arabic", value: "ar" },
        { label: "Danish", value: "da" },
        { label: "Dutch", value: "nl" },
        { label: "English", value: "en" },
        { label: "Finnish", value: "fi" },
        { label: "French", value: "fr" },
        { label: "German", value: "de" },
        { label: "Italian", value: "it" },
        { label: "Japanese", value: "ja" },
        { label: "Korean", value: "ko" },
        { label: "Norwegian Bokmål", value: "nb" },
        { label: "Portuguese - Brazil", value: "pt-BR" },
        { label: "Spanish", value: "es" },
        { label: "Swedish", value: "sv" },
        { label: "Turkish", value: "tr" }
    ];




    const handleChange = (e, key) => {
        let form_data = formData;
        let new_formData = form_data.map((f) => {
            if (f.key == key) {
                f.value = e;
                return f;
            } else {
                return f;
            }
        });
        setFormData(new_formData);
    };
    return (
        <Card>
            <Card.Section>
                <div className="translations_form_wrapper">
                    <div className="content_wrapper_container">
                        <div className="form_wrapper">
                            <Form>
                                <FormLayout>
                                    <Select
                                        label={i18n.t("zennportal_form.language")}
                                        options={supportedLanguages}
                                        onChange={(e) => {
                                            setSelectedLanguage(e);
                                        }}
                                        value={selectedLanguage}
                                    />
                                    {formData?.map((formField) => {
                                        return (
                                            <FormLayout.Group>
                                                {
                                                    formField?.label?.toLowerCase()?.includes("html") ?
                                                        <TextField
                                                            label={formField?.label}
                                                            value={formField?.value}
                                                            onChange={(e) => {
                                                                handleChange(e, formField?.key);
                                                            }}
                                                            autoComplete="off"
                                                            multiline={5}
                                                        />
                                                        :
                                                        <TextField
                                                            label={formField?.label}
                                                            value={formField?.value}
                                                            onChange={(e) => {
                                                                handleChange(e, formField?.key);
                                                            }}
                                                            autoComplete="off"
                                                        />
                                                }


                                            </FormLayout.Group>
                                        );
                                    })}
                                </FormLayout>
                            </Form>
                        </div>
                    </div>

                    <div className="submit_button_wrapper">
                        <Button onClick={() => { }}>{i18n.t("save")}</Button>
                    </div>
                </div>
            </Card.Section>
        </Card>
    );
};

export default ManageTranslations
