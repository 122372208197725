import React, { useCallback, useRef, useState } from "react";
import { Card } from "@shopify/polaris";

export default function SlideItem({ data }) {
    console.log("here", data);
    const [selectedimg, seSelectedImg] = useState(data.images[0]);
    return (
        <Card>
            <div className="top_image">
                <img alt="" width="100%" height="100%" src={selectedimg} />
            </div>
            <div className="thumbnail_image">
                {data.images?.map((image) => {
                    return (
                        <img
                            alt=""
                            width="100%"
                            height="100%"
                            class={image == selectedimg ? "active" : ""}
                            src={image}
                            onClick={() => {
                                seSelectedImg(image);
                            }}
                        />
                    );
                })}
            </div>

            <div className="title_heading">{data?.name}</div>
            <div className="categories_data">
                <span>{data?.categories_data} </span>
            </div>
            <div className="action_button">
                <button className="use_temp">Use Template</button>
                <button className="prev">Previews </button>
            </div>
        </Card>
    );
}