import React from 'react';
import { Card, TextField, Select } from '@shopify/polaris';
import CustomColorPicker from './CustomColorPicker';

const ManageAddToCartButton = (props) => {
  const { formData, setFormData, i18n } = props;
  return (
    <>
      <Card>
        <Card.Section>
          <div className="card_body_content">
            <TextField
              label="Button text"
              value={formData.add_to_cart_label}
              onChange={(e) =>
                setFormData({ ...formData, add_to_cart_label: e })
              }
            />
          </div>
          <div className="card_body_content">
            <Select
              label="Size"
              options={['Small', 'Medium', 'Large']}
              value={formData.add_to_cart_size}
              onChange={(e) =>
                setFormData({ ...formData, add_to_cart_size: e })
              }
            />
          </div>
          <div className="select_colors_container">
            <CustomColorPicker
              label="Button text color"
              formData={formData}
              setFormData={setFormData}
              fieldName={'button_text_color'}
              i18n={i18n}
            />
          </div>
          <div className="select_colors_container">
            <CustomColorPicker
              label="Button background color"
              formData={formData}
              setFormData={setFormData}
              fieldName={'button_background_color'}
              i18n={i18n}
            />
          </div>
        </Card.Section>
      </Card>
    </>
  );
};

export default ManageAddToCartButton;
