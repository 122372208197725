import React, { useEffect, useState } from 'react';
import { Card, Stack, TextField } from '@shopify/polaris';
import { GetStartedIcon } from '../layout/CustomIcon';

const style_objects = [
  {
    category: 'Zennportal',
    name: 'Primary Theme color',
    key: 'primary_theme_color',
    fields: {
      css_property: 'color',
      type: 'color',
      value: '#f0ebe3',
    },
  },
  {
    category: 'Zennportal',
    name: 'Secondary Theme color',
    key: 'secondary_theme_color',
    fields: {
      css_property: 'color',
      type: 'color',
      value: '#f8f5f2',
    },
  },
  {
    category: 'Zennportal',
    name: 'Main font color',
    key: 'main_font_color',
    fields: {
      css_property: 'color',
      type: 'color',
      value: '#000',
    },
  },
  {
    category: 'Zennportal',
    name: 'Secondary font color',
    key: 'secondary_font_color',
    fields: {
      css_property: 'color',
      type: 'color',
      value: '#909090',
    },
  },
  {
    category: 'Zennportal',
    name: 'Sections border color',
    key: 'sections_border_color',
    fields: {
      css_property: 'color',
      type: 'color',
      value: '#000',
    },
  },
  {
    category: 'Zennportal',
    name: 'Sections background color',
    key: 'sections_backgound_color',
    fields: {
      css_property: 'color',
      type: 'color',
      value: '#fff',
    },
  },
  {
    category: 'Zennportal',
    name: 'Offset shadow color',
    key: 'offset_shadow_color',
    fields: {
      css_property: 'color',
      type: 'color',
      value: '#000',
    },
  },
  {
    category: 'Zennportal',
    name: 'Button background color',
    key: 'button_background_color',
    fields: {
      css_property: 'color',
      type: 'color',
      value: '#000000',
    },
  },
  {
    category: 'Zennportal',
    name: 'Button font color',
    key: 'button_font_color',
    fields: {
      css_property: 'color',
      type: 'color',
      value: '#ffffff',
    },
  },
  {
    category: 'Zennportal',
    name: 'Contract box buttons border color',
    key: 'contract_button_border_color',
    fields: {
      css_property: 'color',
      type: 'color',
      value: '#000000',
    },
  },
  {
    category: 'Zennportal',
    name: 'Contract box buttons background color',
    key: 'contract_button_background_color',
    fields: {
      css_property: 'color',
      type: 'color',
      value: '#000000',
    },
  },
  {
    category: 'Zennportal',
    name: 'Contract box buttons text color',
    key: 'contract_button_text_color',
    fields: {
      css_property: 'color',
      type: 'color',
      value: '#000000',
    },
  },
];
const ManageStyle = (props) => {
  const { i18n, domain, setActive, setToasContent, selectedTemplateId } = props;
  const [styleObject, setStyleObject] = useState([...style_objects]);
  const [formData, setFormData] = useState({
    font_family: 'Avenir',
    custom_css: [...style_objects],
  });
  const hangelStyleChange = (value, index) => {
    let new_styles = [...formData?.custom_css];
    new_styles[index].fields.value = value;
    setFormData({ ...formData, custom_css: [...new_styles] });
  };

  const fetchZennportalCssSettings = () => {
    fetch(
      `/fetch_zennportal_css_settings?shopify_domain=${domain}&template_id=${selectedTemplateId}`,
      { method: 'GET' }
    )
      .then((r) => r.json())
      .then((data) => {
        if (data?.zennportal_custom_css) {
          const zennportalcss = data?.zennportal_custom_css;
          zennportalcss.font_family ||= 'Avenir';
          setFormData({ ...zennportalcss });
        }
      });
  };

  useEffect(() => {
    fetchZennportalCssSettings();
  }, []);

  const handleSubmit = () => {
    fetch('/zennportal_css_settings', {
      method: 'POST',
      body: JSON.stringify({
        data: formData,
        shopify_domain: domain,
        template_id: selectedTemplateId,
      }),
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((r) => r.json())
      .then((data) => {
        if (data?.success) {
          setActive(true);
          setToasContent('Saved successfully.');
        }
      });
  };

  return (
    <>
      <Card
        title={
          <div className="heading_title">
            {i18n.t('zennportal_form.style_setting')}
          </div>
        }
        sectioned
        primaryFooterAction={{
          content: 'Save and continue',
          onAction: () => {
            handleSubmit();
          },
        }}
        footerActionAlignment="left"
      >
        <div className="card-box">
          <div className="zennportal_style_form_wrapper">
            <div></div>
            <div className="manage_colors">
              <div className="sub_manage_color_row">
                <div
                  className="cstm-font"
                  style={{ maxWidth: '100% !important' }}
                >
                  {' '}
                  <TextField
                    type={'text'}
                    label={'Font family'}
                    placeholder=""
                    value={formData?.font_family}
                    onChange={(e) => {
                      setFormData({ ...formData, font_family: e });
                    }}
                    autoComplete="off"
                  />
                </div>
              </div>
              <div className="sub_manage_color_row"></div>
              {formData?.custom_css?.map((style, i) => {
                return (
                  <div className="sub_manage_color_row">
                    <TextField
                      type={style?.fields?.css_property}
                      label={style?.name}
                      placeholder=""
                      value={style.fields?.value || '#000'}
                      onChange={(e) => {
                        hangelStyleChange(e, i);
                      }}
                      autoComplete="off"
                    />
                    {style?.fields?.css_property == 'color' ? (
                      <TextField
                        type={'text'}
                        label=""
                        placeholder=""
                        value={style.fields?.value || '#000'}
                        onChange={(e) => {
                          hangelStyleChange(e, i);
                        }}
                        autoComplete="off"
                      />
                    ) : (
                      ''
                    )}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </Card>
    </>
  );
};

export default ManageStyle;
