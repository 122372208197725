import React, { useCallback, useEffect, useState } from 'react';
import {
  LocationsMinor,
  MobileBackArrowMajor,
  ChevronDownMinor,
  CreditCardMajor,
  ShipmentMajor,
  MobilePlusMajor,
  ChevronRightMinor,
  MobileHorizontalDotsMajor,
} from '@shopify/polaris-icons';
import PaypalLogo from '../../../images/paypal.svg';
import Papyal from '../../../images/paypal.svg';
import { Badge, Card, Frame, Icon, Page, Toast } from '@shopify/polaris';
import dayjs from 'dayjs';
import PixelIcon from '../../../images/PixelIcon';
import HeaderButtons from '../../HeaderButtons/HeaderButtons';
import LoyaltyTab from './LoyaltyDetail';
const AffiliateUserDetail = (props) => {
  const { uid, setuid, domain } = props;
  const [headerButton, setHeaderButton] = useState('subscriptions');
  const [apiData, setApiData] = useState();
  const headerButtons = [
    {
      val: 'subscriptions',
      name: 'Subscriptions',
    },
    {
      val: 'loyalty',
      name: 'Loyalty',
    },
    {
      val: 'rebuy_carts',
      name: 'Rebuy Carts',
    },
    {
      val: 'pos_orders',
      name: 'POS Orders',
    },
    {
      val: 'quiz',
      name: 'Quiz',
    },
  ];

  const [showAccordion, setShowAccordion] = useState({ orders: false });
  const [saveSuccess, setSaveSuccess] = useState(false);
  const [toastContent, setToastContent] = useState('');
  const hideSaveSuccess = useCallback(() => setSaveSuccess(false), []);

  const [waysToRedeem, setWaysToRedeem] = useState([]);

  const [rewardActions, setRewardActions] = useState([]);

  const [usedReferrals, setUsedReferrals] = useState([]);

  const fetchCustomer = () => {
    fetch(`/getAffiliateUser/${uid}&shopify_domain=${domain}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setApiData(data?.data);
      });
  };

  useEffect(() => {
    fetchCustomer();
  }, []);

  console.log('apidata', apiData);

  return (
    <div className="chargezen_customer_detail_wrapper">
      <Frame>
        {saveSuccess && (
          <Toast content={toastContent} onDismiss={hideSaveSuccess} />
        )}
        <Page
          title={
            <div className="back_button_wrapper">
              <div
                className="back-button pointer"
                style={{ marginTop: '15px' }}
                onClick={() => {
                  setuid();
                }}
              >
                <Icon source={MobileBackArrowMajor} color="base" />
              </div>
              <div class="text_wrapper">
                {apiData?.affiliate_user?.first_name ||
                apiData?.affiliate_user?.last_name
                  ? `${apiData?.affiliate_user?.first_name || ''} ${
                      apiData?.affiliate_user?.last_name || ''
                    }`
                  : apiData?.affiliate_user.email}
              </div>
            </div>
          }
        >
          <div className="mainContent">
            <div className="block_section">
              <div className="detail-customer-overview-main ">
                <Card
                  sectioned
                  title={
                    <div className="heading_title">
                      <PixelIcon />
                      {`${apiData?.affiliate_user?.first_name || ''} ${
                        apiData?.affiliate_user?.last_name || ''
                      }`}
                    </div>
                  }
                  actions={{
                    content: (
                      <div className="tabButtons">
                        {/* <HeaderButtons
                          headerButtons={headerButtons}
                          setHeaderButton={setHeaderButton}
                          headerButton={headerButton}
                        /> */}
                      </div>
                    ),
                  }}
                >
                  {headerButton == 'subscriptions' &&
                    apiData?.contracts?.map((contract) => {
                      let cardType =
                        contract?.api_data?.customer_payment_method.instrument
                          ?.card?.__typename ||
                        contract?.api_data?.customer_payment_method.instrument
                          ?.__typename;
                      return (
                        <div className="detail-customer-add-product cardBox">
                          <div className="list-section-main">
                            <ul>
                              <li className="locationName">
                                <LocationsMinor />
                                {contract?.api_data?.delivery_method?.address
                                  ?.address1 || 'No delivery address'}
                              </li>
                              <li className="actionB">
                                {' '}
                                Actions <ChevronDownMinor />
                              </li>
                            </ul>
                          </div>
                          <div className="payment-section">
                            <button className="commanButton">
                              <CreditCardMajor />
                              Payment method:
                              {cardType == 'CustomerPaypalBillingAgreement'
                                ? 'Paypal'
                                : cardType == 'CustomerCreditCard'
                                ? `${contract?.api_data?.customer_payment_method.instrument?.brand} - ${contract?.api_data?.customer_payment_method.instrument?.last_digits}`
                                : cardType == 'CustomerShopPayAgreement'
                                ? 'ShopPay'
                                : ''}
                            </button>
                            <span className="commanButton">
                              <CreditCardMajor />
                              {contract?.api_data?.discounts?.edges?.length > 0
                                ? contract?.api_data?.discounts?.edges?.map(
                                    (discount) => {
                                      return (
                                        <>
                                          <span class="discount_title">
                                            {discount.node.title}
                                          </span>
                                          <span class="discounted_main">
                                            {discount?.node?.value.percentage
                                              ? `${discount?.node?.value?.percentage}% off`
                                              : `${discount?.node?.value?.amount} off`}
                                          </span>
                                        </>
                                      );
                                    }
                                  )
                                : 'No Discount'}
                            </span>
                          </div>
                          <div className="bottom-section-main">
                            <table>
                              <thead>
                                <tr>
                                  <th>
                                    <input type="checkbox" />
                                  </th>
                                  <th>Product</th>
                                  <th> Next Order</th>
                                  <th>Frequency</th>
                                  <th></th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td>
                                    <input type="checkbox" />
                                  </td>
                                  <td className="product_wrapper ">
                                    {contract?.api_data?.lines?.edges?.map(
                                      (line) => {
                                        return (
                                          <div className="product_">
                                            <img
                                              src={
                                                line?.node?.variant_image?.url
                                              }
                                            />
                                            <div className="productInfo">
                                              {line?.node?.title}
                                            </div>
                                            <div className="productPrice">{`${line?.node?.quantity} * $${line?.node?.current_price?.amount}`}</div>
                                          </div>
                                        );
                                      }
                                    )}
                                  </td>
                                  <td className="nextOrder">
                                    {dayjs(
                                      contract?.api_data?.next_billing_date
                                    )?.format('MMM DD, YYYY')}
                                  </td>
                                  <td className="frequency">{`${contract?.api_data?.delivery_policy?.interval_count} ${contract?.api_data?.delivery_policy?.interval}`}</td>
                                  <td className="">
                                    <div className="actionB">
                                      Actions <ChevronDownMinor />
                                    </div>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                            <div className="add-product-button">
                              <button className="commanButton">
                                {' '}
                                <MobilePlusMajor />
                                Add product
                              </button>
                            </div>
                            <div class="addAddress">
                              <button className="commanButton">
                                {' '}
                                <MobilePlusMajor /> Add an address
                              </button>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  {headerButton == 'loyalty' && (
                    <LoyaltyTab
                      domain={domain}
                      customer={apiData?.customer}
                      referralLink={apiData?.referralLink}
                      customerPointsActions={apiData?.customer_points_actions}
                      fetchCustomer={fetchCustomer}
                      setToastContent={setToastContent}
                      setSaveSuccess={setSaveSuccess}
                      waysToRedeem={waysToRedeem}
                      setWaysToRedeem={setWaysToRedeem}
                      rewardActions={rewardActions}
                      setRewardActions={setRewardActions}
                      usedReferrals={usedReferrals}
                    />
                  )}
                </Card>
                <div className="accordian">
                  {headerButton == 'loyalty' && (
                    <div
                      className="accordion_container"
                      onClick={() => {
                        setShowAccordion({
                          ...showAccordion,
                          orders: !showAccordion?.orders,
                        });
                      }}
                    >
                      <span
                        class={`cardBox ${
                          showAccordion?.orders ? 'active' : ''
                        }`}
                      >
                        <ChevronRightMinor /> <h4>Orders</h4>
                      </span>
                      <div
                        className={`accordion_wrapper ${
                          showAccordion?.orders ? '' : 'hidden'
                        }`}
                      >
                        <table>
                          <thead>
                            <tr>
                              <th>Order</th>
                              <th>Total</th>
                              <th>Rewardable total</th>
                              <th>Payment</th>
                              <th>Date</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td className="nextOrder">1010</td>
                              <td className="nextOrder">$28.90</td>
                              <td className="nextOrder">$24</td>
                              <td className="nextOrder">
                                <Badge status="success">Paid</Badge>
                              </td>
                              <td className="nextOrder">May 31, 2023</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  )}

                  <span class="cardBox">
                    <ChevronRightMinor /> <h4>Upcoming orders</h4>
                  </span>
                  <span class="cardBox">
                    <ChevronRightMinor /> <h4>Recent orders</h4>
                  </span>
                  <span class="cardBox">
                    <ChevronRightMinor />
                    <h4>Upcoming orders</h4>
                  </span>
                </div>
              </div>

              <div className="button-accordian-payment-main ">
                <div className="customer-overview cardBox commanBoxS">
                  <div className="heading_">
                    <h2>Customer Overview</h2>
                    <MobileHorizontalDotsMajor />
                  </div>
                  <p>{`${apiData?.customer?.first_name} ${apiData?.customer?.last_name}`}</p>
                  <p>{apiData?.customer?.email}</p>
                  <p>{apiData?.customer?.phone || ''}</p>
                  <span>Edit Customer</span>
                  <span>
                    Customer Portal Links <ChevronDownMinor />
                  </span>
                  <span>View In Shopify </span>
                </div>
                <div className="payment-methods cardBox commanBoxS">
                  <div className="heading_">
                    <h2>Payment </h2>
                  </div>
                  <div className="payMShow">
                    <div class="iconPay">
                      <icon>
                        <img src={PaypalLogo} alt="Paypal SVG" />
                      </icon>
                      <icon>
                        <img src={Papyal} alt="Paypal SVG" />
                      </icon>
                    </div>
                    <div class="">
                      <p>Paypal</p>
                      <a href="">hello@curis.ai</a>
                    </div>
                  </div>
                  <span>Manage payment methods </span>
                </div>
              </div>
            </div>
          </div>
        </Page>
      </Frame>
    </div>
  );
};

export default AffiliateUserDetail;
