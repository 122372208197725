import React, { useCallback, useEffect, useState } from 'react';
import { gql, useMutation } from '@apollo/client';
import {
  Card,
  Toast,
  ChoiceList,
  Page,
  PageActions,
  TextField,
  Layout,
  Banner,
  List,
  Frame,
  Select,
  Icon,
  Checkbox,
  RadioButton,
  DropZone,
  Stack,
  Thumbnail,
  Caption,
  TextContainer,
} from '@shopify/polaris';
import PixelIcon from '../../images/PixelIcon';
import { MobileBackArrowMajor, NoteMinor } from '@shopify/polaris-icons';

import Preview from '../plans/Preview';

import './waysToEarnPoint.css';
import SearchCollection from '../plans/SearchCollection';
import SearchProduct from '../upsell/SearchProduct';
import SearchVariants from '../plans/SearchVariants';
import SummaryFullWidthStyle from '../layout/SummaryFullWidthStyle';
import { useHistory } from 'react-router-dom';

const EditWaysToRedeem = ({
  selectedCollections,
  setSelectedCollections,
  formData,
  setFormData,
  setEditForm,
  refetch,
  formErrors,
  setFormErrors,
  saveSuccess,
  setSaveSuccess,
  titleOptions,
  i18n,
  allVariants,
  setAllVariants,
}) => {
  const history = useHistory();
  const [icon, setIcon] = useState();
  const [defaultIcon, setDefaultIcon] = useState('default');
  const [fieldError, setFieldError] = useState({
    title: '',
    pointsAwarded: '',
  });
  const [defaultIcons, setDefaultIcons] = useState([]);
  const [redemptionStyle, setRedemptionStyle] = useState('fixed');
  const [base64Icon, setBase64Icon] = useState();
  const [discountPrefix, setDiscountPrefix] = useState(false);
  const [minPointsRequired, setMinPointsRequired] = useState(false);
  const [maxPointsRequired, setMaxPointsRequired] = useState(false);
  const [maxShippingAmount, setmaxShippingAmount] = useState(false);
  const [minimumPurchaseAmount, setMinimumPurchaseAmount] = useState('none');
  const [selectedCollectionOptions, setSelectedCollectionOptions] = useState(
    []
  );
  const [fieldValue, setFieldValue] = useState({});
  const [file, setFile] = useState();

  const handleDropZoneDrop = useCallback(
    (_dropFiles, acceptedFiles, _rejectedFiles) => setFile(acceptedFiles[0]),
    []
  );

  const validImageTypes = ['image/gif', 'image/jpeg', 'image/png'];

  const fileUpload = !file && <DropZone.FileUpload />;
  const uploadedFile = file && (
    <div style={{ padding: '0' }}>
      <Stack vertical spacing="5">
        <Stack alignment="center" key={1}>
          <Thumbnail
            size="small"
            alt={file.name}
            source={
              validImageTypes.includes(file.type)
                ? window.URL.createObjectURL(file)
                : NoteMinor
            }
          />
          <div>
            {file.name}{' '}
            <TextContainer>
              <p>{file.size} bytes</p>
            </TextContainer>
          </div>
        </Stack>
      </Stack>
    </div>
  );

  useState(() => {
    if (formData?.prefixDiscountCode) {
      setDiscountPrefix(true);
    }
  }, [formData?.prefixDiscountCode]);

  useEffect(() => {
    if (formData?.iconUrl) {
      setDefaultIcon('upload');
    }
  }, [formData?.iconUrl]);

  const fetchDefaultIcons = () => {
    fetch('/fetch_redeem_default_icons', {
      method: 'GET',
    })
      .then((response) => response.json())
      .then((data) => {
        if (data?.icons?.length > 0) {
          setDefaultIcons([...data?.icons]);
        }
      });
  };

  useEffect(() => {
    fetchDefaultIcons();
  }, []);

  console.log('defaultIcons', defaultIcons);

  const ADD_WAYS_TO_REDEEM = gql`
    mutation ($input: AddWaysToRedeemPointsInput!) {
      addWaysToRedeemPoints(input: $input) {
        waysToRedeemPoints {
          id
        }
      }
    }
  `;
  const [addWaysToRedeem] = useMutation(ADD_WAYS_TO_REDEEM);

  const [updated, setUpdated] = useState(false);

  const convertToBase64 = () => {
    if (file) {
      const reader = new FileReader();

      reader.readAsDataURL(file);

      reader.onload = () => {
        console.log('called: ', reader);
        setBase64Icon(reader.result);
      };
    }
  };

  useEffect(() => {
    convertToBase64();
  }, file);
  const handleSubmit = () => {
    let formdata = formData;
    formdata.icon = base64Icon;
    formdata.collectionImages = selectedCollections;
    formdata.maxShippingAmount = +formData?.maxShippingAmount;
    formdata.minimumPurchaseAmount = +formData?.minimumPurchaseAmount;
    formdata.pointsCost = +formData?.pointsCost;
    formdata.discountValue = +formData?.discountValue;
    formdata.customerRedeemsIncrement = +formData?.customerRedeemsIncrement;
    formdata.customerGets = +formData?.customerGets;
    formdata.minPointsRequired = +formData?.minPointsRequired;
    formdata.maxPointsRequired = +formData?.maxPointsRequired;
    formdata.variantImages = allVariants || [];

    addWaysToRedeem({
      variables: {
        input: { params: formdata },
      },
    })
      .then((resp) => {
        const data = resp.data;
        const errors = data.errors;

        if (errors) {
          setFormErrors(errors);
        } else {
          setSaveSuccess(true);
          refetch();
          history.push('/pointsProgram/waysToRedeem');
        }
      })
      .catch((error) => {
        setFormErrors(error);
      });
  };
  const [summaryText, setSummaryText] = useState([]);
  useEffect(() => {
    let summary = [];

    summary =
      formData?.title == 'Free Shipping'
        ? i18n.t('ways_to_redeem.free_shipping_summary_with_max_shipment', {
          maxShippingAmount: formData?.maxShippingAmount || 0,
        })
        : formData?.title == 'Amount discount'
          ? formData?.discountValue
            ? i18n.t('ways_to_redeem.amount_discount_not_zero_summary', {
              discount_value: formData?.discountValue,
            })
            : i18n.t('ways_to_redeem.amount_discount_summary', {
              customer_gets: formData?.customerGets,
              customer_redeems_increment: formData?.customerRedeemsIncrement,
            })
          : formData?.title == 'POS amount discount'
            ? i18n.t('ways_to_redeem.amount_discount_not_zero_summary', {
              discount_value: formData?.discountValue,
            })
            : formData?.title == 'Free product'
              ? i18n.t('ways_to_redeem.free_product_summary')
              : i18n.t('ways_to_redeem.percentage_off_summary', {
                discount_value: formData?.discountValue,
              });

    setSummaryText(summary);
    setFormData({ ...formData, summary: summary });
  }, [
    formData?.title,
    formData?.pointsAwarded,
    formData?.discountValue,
    formData.customerRedeemsIncrement,
    formData?.customerGets,
    formData?.maxShippingAmount,
  ]);

  const handleChange = useCallback((_, newValue) => {
    setDefaultIcon(newValue);
    setFile();
  }, []);

  const handleRedmptionChange = useCallback((_, newValue) => {
    setRedemptionStyle(newValue);
  }, []);

  useEffect(() => {
    if (allVariants?.length > 1) {
      let allVariant = [allVariants[allVariants?.length - 1]];
      setAllVariants([...allVariant]);
    }
  }, [allVariants]);

  useEffect(() => {
    if (!formData?.title) {
      setFormData({ ...formData, title: titleOptions[0]?.value })
    }
  }, [titleOptions])

  return (
    <Frame>
      {/* <Page
        title={
          <div className="back_button_wrapper">
            <div
              className="back-button pointer"
              style={{ marginTop: '15px' }}
              onClick={() => history.push('/pointsProgram/waysToRedeem')}
            >
              <Icon source={MobileBackArrowMajor} color="base" />
            </div>
            <div class="text_wrapper">
              {formData?.id ? i18n.t('edit') : 'Add'}
            </div>
          </div>
        }
      > */}
      <Layout>
        <Layout.Section>
          {formData?.title == 'Amount discount' && (
            <Card
              title={
                <div className="heading_title">
                  <PixelIcon />
                  {i18n.t('ways_to_redeem.points_redemption_style')}
                </div>
              }
              sectioned
            >
              <div
                className="radioWrapper"
                style={{ display: 'flex', gap: '10px', flexFlow: 'column' }}
              >
                <RadioButton
                  label={i18n.t('ways_to_redeem.fixed_amount_of_points')}
                  checked={redemptionStyle === 'fixed'}
                  id="fixed"
                  name="redemptionStyle"
                  onChange={handleRedmptionChange}
                />
                <RadioButton
                  label={i18n.t('ways_to_redeem.increments_of_points')}
                  checked={redemptionStyle === 'increment'}
                  id="increment"
                  name="redemptionStyle"
                  onChange={handleRedmptionChange}
                />
              </div>
            </Card>
          )}
          <Card
            title={
              <div className="heading_title">
                <PixelIcon />
                {i18n.t('reward_value')}
              </div>
            }
            sectioned
          >
            <div className="earningValues">
              <Select
                label={i18n.t('title')}
                options={titleOptions}
                onChange={(e) => {
                  setFormData({ ...formData, title: e });
                }}
                value={formData?.title}
              />
              {redemptionStyle == 'fixed' ||
                formData?.title != 'Amount discount' ? (
                <>
                  <div className="text_flex_wrapper">
                    <TextField
                      label={
                        <div className="order_summary">
                          {i18n.t('ways_to_redeem.points_cost')}
                        </div>
                      }
                      type="number"
                      value={formData?.pointsCost}
                      onChange={(e) => {
                        setFormData({ ...formData, pointsCost: e });
                      }}
                      error={fieldError?.pointsCost}
                      autoComplete="off"
                    />

                    <div
                      className={
                        formData?.title != 'Free Shipping' &&
                          formData?.title != 'Free product'
                          ? ''
                          : 'hidden'
                      }
                    >
                      <TextField
                        label={
                          <div className="order_summary">
                            {i18n.t('ways_to_redeem.discount_value')}
                          </div>
                        }
                        type="number"
                        value={formData?.discountValue}
                        onChange={(e) => {
                          setFormData({ ...formData, discountValue: e });
                        }}
                        error={fieldError?.discountValue}
                        autoComplete="off"
                      />
                    </div>
                  </div>

                  <div
                    className={`block_wrapper ${formData?.title == 'Free Shipping' ? '' : 'hidden'
                      }`}
                  >
                    <Checkbox
                      label={i18n.t(
                        'points_programs.max_number_shipping_amount_for_reward'
                      )}
                      checked={maxShippingAmount}
                      onChange={(newChecked) => {
                        setmaxShippingAmount(newChecked);
                      }}
                    />
                    {maxShippingAmount && (
                      <TextField
                        label=""
                        type="number"
                        prefix="$"
                        value={formData?.maxShippingAmount}
                        onChange={(e) => {
                          setFormData({ ...formData, maxShippingAmount: e });
                        }}
                        error={fieldError?.maxShippingAmount}
                        autoComplete="off"
                      />
                    )}
                  </div>
                </>
              ) : (
                <div className="text_flex_wrapper">
                  <TextField
                    label={
                      <div className="order_summary">
                        {i18n.t('ways_to_redeem.customer_redeems_increment_of')}
                      </div>
                    }
                    suffix="points"
                    type="number"
                    value={formData?.customerRedeemsIncrement}
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        customerRedeemsIncrement: e,
                      });
                    }}
                    error={fieldError?.customerRedeemsIncrement}
                    autoComplete="off"
                  />
                  <TextField
                    label={
                      <div className="order_summary">
                        {i18n.t('ways_to_redeem.customer_gets')}
                      </div>
                    }
                    prefix="$"
                    type="number"
                    value={formData?.customerGets}
                    onChange={(e) => {
                      setFormData({ ...formData, customerGets: e });
                    }}
                    error={fieldError?.customerGets}
                    autoComplete="off"
                  />

                  <Checkbox
                    label={i18n.t(
                      'points_programs.minimum_amount__of_points_for_reward'
                    )}
                    checked={minPointsRequired}
                    onChange={(newChecked) => {
                      setMinPointsRequired(newChecked);
                    }}
                  />
                  {minPointsRequired && (
                    <TextField
                      label=""
                      type="number"
                      suffix="points"
                      value={formData?.minPointsRequired}
                      onChange={(e) => {
                        setFormData({ ...formData, minPointsRequired: e });
                      }}
                      error={fieldError?.minPointsRequired}
                      autoComplete="off"
                    />
                  )}
                  <Checkbox
                    label={i18n.t(
                      'points_programs.max_amount_of_points_customer_spend'
                    )}
                    checked={maxPointsRequired}
                    onChange={(newChecked) => {
                      setMaxPointsRequired(newChecked);
                    }}
                  />

                  {maxPointsRequired && (
                    <TextField
                      label=""
                      type="number"
                      suffix="points"
                      value={formData?.maxPointsRequired}
                      onChange={(e) => {
                        setFormData({ ...formData, maxPointsRequired: e });
                      }}
                      error={fieldError?.maxPointsRequired}
                      autoComplete="off"
                    />
                  )}
                </div>
              )}
            </div>
          </Card>

          <div
            className={
              formData?.title == 'Amount discount' ||
                formData?.title == 'Percentage off'
                ? ''
                : 'hidden'
            }
          >
            <Card
              title={
                <div className="heading_title">
                  <PixelIcon />
                  {i18n.t('ways_to_redeem.applies_to')}
                </div>
              }
              sectioned
            >
              <div
                className="radioWrapper"
                style={{ display: 'flex', gap: '10px', flexFlow: 'column' }}
              >
                <RadioButton
                  label={i18n.t('points_programs.entire_order')}
                  checked={formData?.appliesTo === 'entire_order'}
                  id="entire_order"
                  name="appliesTo"
                  onChange={useCallback((_, newValue) => {
                    setFormData({ ...formData, appliesTo: newValue });
                  }, [])}
                />
                <RadioButton
                  label={i18n.t('points_programs.specific_collection')}
                  checked={formData?.appliesTo === 'specific_collection'}
                  id="specific_collection"
                  name="appliesTo"
                  onChange={useCallback((_, newValue) => {
                    setFormData({ ...formData, appliesTo: newValue });
                  }, [])}
                />

                {formData?.appliesTo === 'specific_collection' && (
                  <div className="box-subscription-search">
                    <SearchCollection
                      selectedOptions={selectedCollectionOptions}
                      setSelectedOptions={setSelectedCollectionOptions}
                      selectedCollections={selectedCollections}
                      setSelectedCollections={setSelectedCollections}
                      i18n={i18n}
                    />
                  </div>
                )}
              </div>
            </Card>

            <Card
              title={
                <div className="heading_title">
                  <PixelIcon />
                  {i18n.t('ways_to_redeem.minimum_requirement')}
                </div>
              }
              sectioned
            >
              <div
                className="radioWrapper"
                style={{ display: 'flex', gap: '10px', flexFlow: 'column' }}
              >
                <RadioButton
                  label={i18n.t('none')}
                  checked={minimumPurchaseAmount == 'none'}
                  id="none"
                  name="minimumPurchaseAmount"
                  onChange={useCallback((_, newChecked) => {
                    setMinimumPurchaseAmount(newChecked);
                  }, [])}
                />
                <RadioButton
                  label={i18n.t('points_programs.minimum_purchase_amount')}
                  checked={minimumPurchaseAmount == 'minimumPurchaseAmount'}
                  id="minimumPurchaseAmount"
                  name="minimumPurchaseAmount"
                  onChange={useCallback((_, newChecked) => {
                    setMinimumPurchaseAmount(newChecked);
                  }, [])}
                />

                {minimumPurchaseAmount == 'minimumPurchaseAmount' && (
                  <TextField
                    label=""
                    type="number"
                    value={formData?.minimumPurchaseAmount}
                    prefix="$"
                    onChange={(e) => {
                      setFormData({ ...formData, minimumPurchaseAmount: e });
                    }}
                    error={fieldError?.minimumPurchaseAmount}
                    autoComplete="off"
                  />
                )}
              </div>
            </Card>
          </div>

          {formData?.title == 'Free product' && (
            <Card title={i18n.t('select_free_products')} sectioned>
              <div className="product-search">
                <SearchVariants
                  value={formData.variantImages || [[]]}
                  setFieldValue={setFormData}
                  fieldName={`variantImages`}
                  allVariants={allVariants || [[]]}
                  setAllVarients={setAllVariants}
                  i18n={i18n}
                  setFormData={setFormData}
                  formData={formData}
                />
              </div>
              <Preview
                isUpdate={false}
                allProducts={allVariants || [[]]}
                setAllProducts={setAllVariants}
                setUpdated={setUpdated}
                i18n={i18n}
              />
            </Card>
          )}

          {
            <div
              className={
                formData?.title != 'POS amount discount' &&
                  formData.title != 'POS percentage off' &&
                  formData?.title != 'Free product'
                  ? ``
                  : `hidden`
              }
            >
              <Card
                title={
                  <div className="heading_title">
                    <PixelIcon />
                    {i18n.t('ways_to_redeem.discount_code')}
                  </div>
                }
                sectioned
              >
                <div
                  className="radioWrapper"
                  style={{ display: 'flex', gap: '10px', flexFlow: 'column' }}
                >
                  <Checkbox
                    label={i18n.t(
                      'points_programs.add_prefix_to_discount_codes'
                    )}
                    checked={discountPrefix}
                    onChange={useCallback((newChecked) => {
                      setDiscountPrefix(newChecked);
                    }, [])}
                  />
                  {discountPrefix && (
                    <TextField
                      label=""
                      type="text"
                      value={`${formData?.prefixDiscountCode} `}
                      placeholder="e.g. $10OFF-"
                      helpText="Avoid using spaces and special characters in the prefix to prevent errors when customers copy and paste the entire discount code."
                      onChange={(e) => {
                        setFormData({ ...formData, prefixDiscountCode: e });
                      }}
                      error={fieldError?.prefixDiscountCode}
                      autoComplete="off"
                    />
                  )}
                </div>
              </Card>
            </div>
          }
        </Layout.Section>
        <div className="defauleLayoutSection">
          <Layout.Section secondary>
            <Card title={i18n.t('summary')}>
              <Card.Section>
                {/* <TextField
                                        multiline={2}
                                        value={formData?.summary}
                                        onChange={(e) => setFormData({ ...formData, summary: e })}
                                        autoComplete="off"
                                    /> */}
                <List type="bullet">
                  <List.Item>{summaryText}</List.Item>
                </List>
              </Card.Section>
              {/* <Card.Section subdued title="STATUS">
                                    <ChoiceList
                                        title=""
                                        choices={[
                                            { label: 'Active', value: true },
                                            { label: 'Disabled', value: false },
                                        ]}
                                        selected={[formData?.status]}
                                        onChange={(e) => setFormData({ ...formData, status: e[0] })}
                                    />
                                </Card.Section> */}
            </Card>
            <Card title={i18n.t('icon')} sectioned>
              <div
                className="radioWrapper"
                style={{ display: 'flex', gap: '10px', flexFlow: 'column' }}
              >
                <RadioButton
                  label={i18n.t('default')}
                  checked={defaultIcon === 'default'}
                  id="default"
                  name="icon"
                  onChange={handleChange}
                />
                {defaultIcon == 'default' && (
                  <div className="icon_wrapper">
                    <img
                      src={
                        defaultIcons[0]?.find(
                          (val) => val?.for == formData?.title
                        )?.icon
                      }
                      style={{ width: '40px' }}
                    />
                  </div>
                )}
                <RadioButton
                  label={i18n.t('upload_own_icon')}
                  checked={defaultIcon === 'upload'}
                  id="upload"
                  name="icon"
                  onChange={handleChange}
                />
                {formData?.iconUrl && (
                  <div className="icon_wrapper">
                    <img src={formData?.iconUrl} style={{ width: '40px' }} />
                  </div>
                )}
                {defaultIcon == 'upload' && (
                  <DropZone
                    allowMultiple={false}
                    onDrop={handleDropZoneDrop}
                    type="image"
                  >
                    {uploadedFile}
                    {fileUpload}
                  </DropZone>
                )}
              </div>
            </Card>
          </Layout.Section>
        </div>
        <Layout.Section>
          <PageActions
            primaryAction={{
              content: i18n.t('save'),
              onClick: () => handleSubmit(),
            }}
            secondaryActions={[
              {
                content: i18n.t('delete'),
                destructive: true,
              },
            ]}
          />
        </Layout.Section>
      </Layout>
      {/* </Page> */}
    </Frame>
  );
};

export default EditWaysToRedeem;
