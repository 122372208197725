import React, { useEffect, useState } from 'react';
import { Button, ButtonGroup } from '@shopify/polaris';
import './HeaderButtons.css';

const HeaderButtons = ({ headerButtons, setHeaderButton, headerButton }) => {
  return (
    <ButtonGroup>
      {headerButtons?.map((obj, index) => {

        return (
          <Button
            onClick={() => {
              setHeaderButton(obj?.val);
            }}
            primary={headerButton === obj?.val ? true : false}
          >
            {obj?.name}
          </Button>
        );
      })}
    </ButtonGroup>
  );
};

export default HeaderButtons;
