import React from 'react';

//to make the component full-width by hidding the sidnav
const HideSideNav = () => {
  const style = `
        .module-navbar .app_content {
            max-width: 100% !important;
            z-index: 999 !important;
        }
        .rewards_template_wrapper .Polaris-Page {
          max-width: 100%!important;
        }
    `;
  return <style dangerouslySetInnerHTML={{ __html: style }} />;
};

export default HideSideNav;
