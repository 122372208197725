import React, { useState } from "react";
import { useDrop } from "react-dnd";
import { Card } from "./Card";
import update from "immutability-helper";
import { ItemTypes } from "./ItemTypes";
const style = {
    width: 400
};
const Container = (props) => {
    const { cards, setCards, i18n } = props
    const moveCard = (id, atIndex) => {
        const { card, index } = findCard(id);
        setCards(
            update(cards, {
                $splice: [
                    [index, 1],
                    [atIndex, 0, card]
                ]
            })
        );
    };
    const findCard = (id) => {
        const card = cards.filter((c) => `${c.id}` === id)[0];
        return {
            card,
            index: cards.indexOf(card)
        };
    };
    const [, drop] = useDrop({ accept: ItemTypes.CARD });

    const nonDraggableButtons = ["Delivery address field", "Discount field"]
    return (
        <>
            <div ref={drop} style={style}>
                {cards?.map((card) => {
                    if (!nonDraggableButtons?.includes(card?.button_name)) {
                        return <Card
                            key={card.id}
                            id={`${card.id}`}
                            text={i18n.t(`zennportal_form.button_name.${card?.button_name}`)}
                            moveCard={moveCard}
                            findCard={findCard}
                        />
                    }
                })}
            </div>
        </>
    );
};
export default Container;
