import React, { useState, useCallback, useEffect } from 'react';
import { Card, Tabs, Frame, Page } from '@shopify/polaris';
import '@shopify/polaris/dist/styles.css';
import SlideItem from './slideItem';
import Customize from './customize';
import { useHistory } from 'react-router-dom';

function PasswordlessLogin(props) {
  const { domain, i18n } = props;
  const [selected, setSelected] = useState(0);
  const [apiData, setApiData] = useState([]);
  const [templateId, setTemplateId] = useState();
  const history = useHistory();

  const handleTabChange = useCallback((selectedTabIndex) => {
    setSelected(selectedTabIndex);
  }, []);

  const tabs = [
    {
      id: 'all-customers-1',
      content: 'All',
      accessibilityLabel: 'All customers',
      panelID: 'all-customers-content-1',
    },
    {
      id: 'Food-&-Beverage',
      content: 'Food & Beverage',
      panelID: 'Food-&-Beverage',
    },
    {
      id: 'Health-&-Beauty',
      content: 'Health & Beauty',
      panelID: 'Health-&-Beauty',
    },
    {
      id: 'Gift-Guide',
      content: 'Gift Guide',
      panelID: 'Gift-Guide',
    },
    {
      id: 'Fashion',
      content: 'Fashion',
      panelID: 'Fashion',
    },
    {
      id: 'Furniture',
      content: 'Furniture',
      panelID: 'Furniture',
    },
    {
      id: 'Technology',
      content: 'Technology',
      panelID: 'Technology',
    },
  ];

  useState(() => {
    fetch('/customer_portal/get_passwordless_templates', {
      method: 'GET',
    })
      .then((response) => response.json())
      .then((data) => {
        setApiData([...data.templates]);
      });
  }, []);

  useEffect(() => {}, [templateId]);

  return (
    <Frame>
      <div className="rewards_template_wrapper zenn_portal_wrapper">
        <Page
          title={
            templateId ? (
              <div class="back-button">
                <nav role="navigation">
                  <button
                    data-polaris-unstyled="true"
                    class="Polaris-Breadcrumbs__Breadcrumb"
                    onClick={() => setTemplateId()}
                    style={{ paddingLeft: '6px' }}
                  >
                    <span class="Polaris-Breadcrumbs__Icon">
                      <span class="Polaris-Icon">
                        <span class="Polaris-VisuallyHidden"></span>
                        <svg
                          viewBox="0 0 20 20"
                          class="Polaris-Icon__Svg"
                          focusable="false"
                          aria-hidden="true"
                        >
                          <path d="M17 9h-11.586l3.293-3.293a.999.999 0 1 0-1.414-1.414l-5 5a.999.999 0 0 0 0 1.414l5 5a.997.997 0 0 0 1.414 0 .999.999 0 0 0 0-1.414l-3.293-3.293h11.586a1 1 0 1 0 0-2z"></path>
                        </svg>
                      </span>
                    </span>
                    <span class="Polaris-VisuallyHidden">Quizzes</span>
                  </button>
                </nav>
                <div class="Polaris-Page-Header__TitleWrapper">
                  <div>
                    <div class="Polaris-Header-Title__TitleAndSubtitleWrapper">
                      <h1 class="shopify_style_header">
                        {i18n.t('passwordless_theme_heading')}
                      </h1>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div class="back-button">
                <div class="Polaris-Page-Header__TitleWrapper">
                  <div>
                    <div class="Polaris-Header-Title__TitleAndSubtitleWrapper">
                      <h1 class="shopify_style_header">
                        {i18n.t('passwordless_theme_heading')}
                      </h1>
                    </div>
                  </div>
                </div>
              </div>
            )
          }
        >
          {templateId ? (
            <Customize
              domain={domain}
              i18n={i18n}
              selectedTemplateId={templateId}
            />
          ) : (
            <>
              <div className="rewards_choose_template show_content_2">
                <Card>
                  <Tabs
                    tabs={tabs}
                    selected={selected}
                    onSelect={handleTabChange}
                  >
                    <Card.Section title={tabs[selected].content}>
                      {/* <p>Tab {selected} selected</p> */}
                      <div class="sort-by">
                        <div class="inner-wrap-sort">
                          <label>Sort by:</label>
                          <select
                            id="PolarisSelect1"
                            class="Polaris-Select__Input"
                            aria-invalid="false"
                          >
                            <option value="popular">Popularity</option>
                            <option value="recent">Most recent</option>
                          </select>
                          <svg
                            viewBox="0 0 20 20"
                            class="Polaris-Icon__Svg"
                            focusable="false"
                            aria-hidden="true"
                          >
                            <path d="M7.676 9h4.648c.563 0 .879-.603.53-1.014l-2.323-2.746a.708.708 0 0 0-1.062 0l-2.324 2.746c-.347.411-.032 1.014.531 1.014Zm4.648 2h-4.648c-.563 0-.878.603-.53 1.014l2.323 2.746c.27.32.792.32 1.062 0l2.323-2.746c.349-.411.033-1.014-.53-1.014Z"></path>
                          </svg>
                        </div>
                      </div>
                      {apiData?.map((data) => {
                        return (
                          <SlideItem
                            data={data}
                            templateId={templateId}
                            setTemplateId={setTemplateId}
                            i18n={i18n}
                          />
                        );
                      })}
                    </Card.Section>
                  </Tabs>
                </Card>
              </div>
            </>
          )}
        </Page>
      </div>
    </Frame>
  );
}

export default PasswordlessLogin;
