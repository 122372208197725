import React, { useEffect, useState } from 'react';
import Overview from '../Overview/Overview';
import { Frame, Page } from '@shopify/polaris';

const CheckoutOverview = (props) => {
  const { i18n, floatingSetupGuide, setFloatingSetupGuide, domain } = props;
  const [subHeaderData, setSubHeaderData] = useState();
  const fetchReports = () => {
    fetch(`/upsell/fetch_analytics?shopify_domain=${domain}`, {
      method: 'GET',
    })
      .then((r) => r.json())
      .then((data) => {
        let subheaderdata = [
          {
            title: 'Revenue',
            value: data?.total_revenue || '0',
            desc: ` `,
          },
          {
            title: 'Views',
            value: data?.views || '0',
            desc: ` `,
          },
          {
            title: 'Conversion Rate',
            value: data?.conversion_rate || '0',
            desc: ` `,
          },
        ];
        setSubHeaderData([...subheaderdata]);
      });
  };
  useEffect(() => {
    fetchReports();
  }, []);

  return (
    <Frame>
      <Page>
        <div className="chargezen_custom_page_header">
          <div className="page_title_wrapper">
            <div className="back_button_wrapper">
              <div class="text_wrapper page_heading_title_wrapper">
                <div
                  class="Polaris-Header-Title__TitleAndSubtitleWrapper"
                  style={{
                    width: '100%',
                  }}
                >
                  <div class="Polaris-Header-Title">
                    <h1 class="Polaris-DisplayText Polaris-DisplayText--sizeLarge">
                      <span class="Polaris-TextStyle--variationStrong">
                        <div class="back_button_wrapper">
                          {' '}
                          <div
                            class="text_wrapper page_heading_title_wrapper"
                            style={{
                              width: '100%',
                            }}
                          >
                            <span>CheckoutOS Overview</span>
                          </div>
                        </div>
                      </span>
                    </h1>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="sidebar_summary header_description">
            Boost average order value with high-intent pre-checkout upsells trust badges; gift with purchase, tiered rewards etc; save 1st party data with surveys;
& custom fields; customize your checkout with our branding suite; & more. Some features require a Shopify plus plan.
          </div>
        </div>
        <>
          <div className={`chargezen_full_width_main_container`}>
            <div className={`chargezen_full_width_container`}>
              <div className={`chargezen_full_width_wrapper`}>
                <Overview
                  {...{
                    i18n,
                    floatingSetupGuide,
                    setFloatingSetupGuide,
                    subHeaderData,
                  }}
                  customNavTabs={{ checkout_extensions: true }}
                />
              </div>
            </div>
          </div>
        </>
      </Page>
    </Frame>
  );
};

export default CheckoutOverview;
