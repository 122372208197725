import React, { useCallback, useRef, useState } from 'react';
import { Card } from '@shopify/polaris';
import { useHistory } from 'react-router-dom';

export default function SlideItem({
  id,
  data,
  setEditTemplate,
  setpreviewTemplate,
}) {
  console.log('here', data);
  const [selectedimg, seSelectedImg] = useState(data.images[0]);
  const history = useHistory();
  return (
    <Card>
      <div className="top_image">
        <img alt="" width="100%" height="100%" src={selectedimg} />
      </div>
      <div className="thumbnail_image">
        {data.images?.map((image) => {
          return (
            <img
              alt=""
              width="100%"
              height="100%"
              class={image == selectedimg ? 'active' : ''}
              src={image}
              onClick={() => {
                seSelectedImg(image);
              }}
            />
          );
        })}
      </div>

      <div className="title_heading">{data?.title}</div>
      {/* <div className="categories_data">
        <span>{data?.categories_data} </span>
      </div> */}
      <div className="action_button">
        {data?.launched ? (
          <button
            className="use_temp"
            onClick={() => {
              history.push(`/chargezenUpsells/cartFunnels/${id}`);
            }}
          >
            Create Funnel
          </button>
        ) : (
          <button className="coming_soon_button">Coming soon</button>
        )}

        <button
          className="prev"
          onClick={() => {
            if (data?.launched) {
              setpreviewTemplate(id);
            }
          }}
        >
          Preview{' '}
        </button>
      </div>
    </Card>
  );
}
