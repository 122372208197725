import React, { useEffect, useState } from 'react';
import {
  MobileBackArrowMajor,
  MobileChevronMajor,
  MobileMajor,
  DesktopMajor,
  ChevronUpMinor,
} from '@shopify/polaris-icons';
import { Button, Checkbox, Icon, Select, TextField } from '@shopify/polaris';
import SearchVariants from './SearchVariants';
import Preview from '../../plans/Preview';
import AddCondtionProduct from './AddCondtionProduct';
import SearchPlan from './SearchPlan';

const OfferSettings = ({
  toggleOnButton,
  subForm,
  setSubForm,
  setToggleOnButton,
  allVariants,
  setAllVariants,
  i18n,
  setFormData,
  conditionBlock,
  setConditionBlock,
  initConditionBlock,
  handleSubmit,
  formData,
  handleFormVariantData,
}) => {
  const [updated, setUpdated] = useState(false);
  const handleFormDataChange = (value, key) => {
    let form_data = formData;
    form_data[key] = value;
    setFormData({ ...form_data });
  };
  const [toggleContentBlock, setToogleContentBlock] = useState({});

  const handleCondtionChange = (value, key, index) => {
    let content_block = conditionBlock;
    content_block[index][key] = value;
    setConditionBlock([...content_block]);
  };

  console.log('conditionBlock', conditionBlock);

  const handleConditionRemove = (index) => {
    let condtion_block = conditionBlock;
    condtion_block.splice(index, 1);
    setConditionBlock([...condtion_block]);
  };

  const condtionBlockOptions = [
    { label: 'Min order value', value: 'min_order_value' },
    { label: 'Max order value', value: 'max_order_value' },
    { label: 'Min line items quantity', value: 'min_line_items_quantity' },
    { label: 'Min customer order count', value: 'min_customer_order_count' },
    { label: 'Has selling plan', value: 'has_selling_plan' },
    { label: 'Order currency', value: 'order_currency' },
    { label: 'Current condition', value: 'current_condition' },
    { label: 'Variant images', value: 'variant_images' },
  ];
  return (
    <div className="card_content_wrapper">
      <div class="main_header_content">
        <button
          class="back_button_pointer"
          onClick={() => {
            setSubForm('');
          }}
        >
          <span class="icon">
            <Icon source={MobileChevronMajor} color="base" />
          </span>
        </button>
        <span class="button_text">
          {subForm === 'CONDITIONS' ? `${subForm} (coming soon)` : subForm}
        </span>
      </div>
      <div className="form_field_wrapper">
        {subForm === 'PRODUCT' ? (
          <>
            <div className="field_row Polaris-Card__Section">
              <div className="field_row_label">Name</div>
              <div className="field_row_content">
                <SearchVariants
                  setFieldValue={setFormData}
                  fieldName={`variants`}
                  allVariants={allVariants || [[]]}
                  setAllVarients={setAllVariants}
                  i18n={i18n}
                  setFormData={setFormData}
                  formData={formData}
                />
                <div className="preview_variants_wrapper">
                  <Preview
                    isUpdate={false}
                    allProducts={allVariants || [[]]}
                    setAllProducts={setAllVariants}
                    setUpdated={setUpdated}
                    i18n={i18n}
                  />
                </div>
              </div>
            </div>
            <div className="field_row navi Polaris-Card__Section">
              <div className="flex justify-between sidebar_action_buttons">
                <Button
                  onClick={() => {
                    handleSubmit();
                  }}
                >
                  Save
                </Button>
              </div>
            </div>
          </>
        ) : subForm === 'SUBSCRIPTION' ? (
          <>
            <div className="field_row Polaris-Card__Section">
              {formData?.variants?.length <= 0 && (
                <div className="no_products_selected">
                  No products selected.
                </div>
              )}
              {formData?.variants?.map((v, index) => {
                return (
                  <div className="variants_setting_card">
                    <div className="field_row_label">Selling Plan</div>
                    <div className="field_row_content">
                      <SearchPlan {...{ i18n, setFormData, formData, index }} />
                    </div>
                    <div className="field_row_label mt-16">Title</div>
                    <div className="field_row_content">
                      <TextField
                        label=""
                        type="text"
                        value={v?.subTitle || ''}
                        onChange={(e) => {
                          handleFormVariantData(e, index, 'subTitle');
                        }}
                      />
                    </div>
                    <div className="for_variant_container mt-16">
                      <img src={v?.image} />
                      <p className="title">{v.title}</p>
                    </div>
                  </div>
                );
              })}
            </div>
            <div className="field_row navi Polaris-Card__Section">
              <div className="flex justify-between sidebar_action_buttons">
                <Button
                  onClick={() => {
                    handleSubmit();
                  }}
                >
                  Save
                </Button>
              </div>
            </div>
          </>
        ) : subForm === 'SETTINGS' ? (
          <>
            {/* <div className="field_row Polaris-Card__Section">
              <div className="field_row_label">DISCOUNT</div>
              <div className="discount_field_wrapper">
                <TextField
                  label=""
                  type="number"
                  value={`${formData?.offer_discount}`}
                  onChange={(e) => {
                    handleFormDataChange(e, 'offer_discount');
                  }}
                  helpText="Apply a discount on the products."
                />
                <div className="select_discount_type">
                  <Select
                    label=""
                    options={[
                      { label: '%', value: 'percentage' },
                      { label: '$', value: 'amount' },
                    ]}
                    value={formData?.offer_discount_type}
                    onChange={(e) => {
                      handleFormDataChange(e, 'offer_discount_type');
                    }}
                  />
                </div>
              </div>
            </div> */}
            {/* <div className="field_row Polaris-Card__Section">
              <div className="field_row_label">Time Left</div>
              <Select
                label=""
                options={['5min', '10min', '15min', '20min', 'Disabled']}
                helpText="Set a time limit on the offer."
                value={formData.offer_time}
                onChange={(e) => {
                  handleFormDataChange(e, 'offer_time');
                }}
              />
            </div> */}
            <div className="field_row Polaris-Card__Section">
              <div className="field_row_label">PURCHASE LIMIT</div>
              <Select
                label=""
                options={['1', '2']}
                helpText="Limit the quantity a customer can purchase."
                value={formData.offer_purchase_limit}
                onChange={(e) => {
                  handleFormDataChange(e, 'offer_purchase_limit');
                }}
              />
            </div>
            <div className="field_row Polaris-Card__Section">
              <div className="field_row_label">PRIORITY</div>
              <TextField
                label=""
                type="number"
                helpText="Offer with bigger priority will be shown. Enter value from 1 to 100."
                value={`${formData?.offer_priority}`}
                onChange={(e) => {
                  handleFormDataChange(e, 'offer_priority');
                }}
              />
            </div>
            <div className="field_row navi Polaris-Card__Section">
              <div className="flex justify-between sidebar_action_buttons">
                <Button
                  onClick={() => {
                    handleSubmit();
                  }}
                >
                  Save
                </Button>
              </div>
            </div>
          </>
        ) : subForm === 'CONDITIONS' ? (
          <>
            <div className="field_row Polaris-Card__Section">
              <div className="button_group toggleButtons">
                <Button
                  onClick={() => {
                    setFormData({ ...formData, offer_condition: true });
                  }}
                  primary={formData?.offer_condition}
                  disabled={true}
                >
                  On
                </Button>
                <Button
                  onClick={() => {
                    setFormData({ ...formData, offer_condition: false });
                  }}
                  primary={!formData?.offer_condition}
                  disabled={true}
                >
                  Off
                </Button>
              </div>
              <div class="order_condition_help_text">
                Order
                {formData?.meet_all_conditions
                  ? ' must meet all '
                  : ' does not need to meet any '}
                conditions to trigger the offer.
              </div>
              {formData?.offer_condition && (
                <>
                  <div class="add_new_content_container">
                    <div className="flex justify-between">
                      <div className="field_row_label">CONDITIONS</div>
                      <button
                        className="add_new_content_button"
                        onClick={() => {
                          setConditionBlock([
                            ...conditionBlock,
                            initConditionBlock,
                          ]);
                        }}
                      >
                        +
                      </button>
                    </div>
                  </div>

                  <div className="content_block_wrapper">
                    {conditionBlock?.map((content, i) => {
                      return (
                        <div className="sub_contentBlock">
                          <div className="header_content toggleAccordian">
                            <div className="text">Condition {i + 1}</div>
                            <button
                              className="icon_wrapper"
                              onClick={() => {
                                setToogleContentBlock({
                                  ...toggleContentBlock,
                                  [`block${i}`]:
                                    !toggleContentBlock[`block${i}`],
                                });
                              }}
                            >
                              <Icon source={ChevronUpMinor} color="base" />
                            </button>
                          </div>
                          <div
                            className={`content_block_form_wrapper ${
                              toggleContentBlock[`block${i}`] ? '' : 'hidden'
                            }`}
                          >
                            <div className="row_wrapper">
                              <Select
                                label="Order type"
                                options={condtionBlockOptions}
                                value={content?.current_condition}
                                onChange={(e) => {
                                  handleCondtionChange(
                                    e,
                                    'current_condition',
                                    i
                                  );
                                }}
                              />
                            </div>
                            {content?.current_condition === 'variant_images' ? (
                              <div className="row_wrapper">
                                <AddCondtionProduct
                                  setConditionBlock={setConditionBlock}
                                  conditionBlock={conditionBlock}
                                  index={i}
                                  i18n={i18n}
                                />
                              </div>
                            ) : content?.current_condition ===
                              'min_order_value' ? (
                              <div className="row_wrapper">
                                <TextField
                                  label="Min order value"
                                  type="number"
                                  prefix="$"
                                  value={`${content?.min_order_value}`}
                                  onChange={(e) => {
                                    handleCondtionChange(
                                      e,
                                      'min_order_value',
                                      i
                                    );
                                  }}
                                />
                              </div>
                            ) : content?.current_condition ===
                              'max_order_value' ? (
                              <div className="row_wrapper">
                                <TextField
                                  label="Max order value"
                                  type="number"
                                  prefix="$"
                                  value={`${content?.max_order_value}`}
                                  onChange={(e) => {
                                    handleCondtionChange(
                                      e,
                                      'max_order_value',
                                      i
                                    );
                                  }}
                                />
                              </div>
                            ) : content?.current_condition ==
                              'min_line_items_quantity' ? (
                              <div className="row_wrapper">
                                <TextField
                                  label="Min line items quantity"
                                  type="number"
                                  value={`${content?.min_line_items_quantity}`}
                                  onChange={(e) => {
                                    handleCondtionChange(
                                      e,
                                      'min_line_items_quantity',
                                      i
                                    );
                                  }}
                                />
                              </div>
                            ) : content?.current_condition ==
                              'min_customer_order_count' ? (
                              <div className="row_wrapper">
                                <TextField
                                  label="Min number of orders"
                                  type="number"
                                  value={`${content?.min_customer_order_count}`}
                                  onChange={(e) => {
                                    handleCondtionChange(
                                      e,
                                      'min_customer_order_count',
                                      i
                                    );
                                  }}
                                />
                              </div>
                            ) : content?.current_condition ==
                              'has_selling_plan' ? (
                              <div className="row_wrapper">
                                <Checkbox
                                  label="Has selling plan"
                                  checked={content?.has_selling_plan}
                                  onChange={(e) => {
                                    handleCondtionChange(
                                      e,
                                      'has_selling_plan',
                                      i
                                    );
                                  }}
                                />
                              </div>
                            ) : content?.current_condition ==
                              'order_currency' ? (
                              <div className="row_wrapper">
                                <TextField
                                  label="Order currency"
                                  type="text"
                                  value={content?.order_currency}
                                  onChange={(e) => {
                                    handleCondtionChange(
                                      e,
                                      'order_currency',
                                      i
                                    );
                                  }}
                                />
                              </div>
                            ) : (
                              <></>
                            )}
                            <div className="removeButtonWrapper">
                              <button
                                className="remove_upsell_block"
                                onClick={() => {
                                  handleConditionRemove(i);
                                }}
                              >
                                Remove
                              </button>
                            </div>
                          </div>
                          {conditionBlock[i + 1] && (
                            <div className="or_and_wrapper">
                              {formData?.meet_all_conditions ? 'AND' : 'OR'}
                            </div>
                          )}
                        </div>
                      );
                    })}
                    <Checkbox
                      label="Order must meet all requirements,"
                      checked={formData?.meet_all_conditions}
                      onChange={(e) => {
                        setFormData({ ...formData, meet_all_conditions: e });
                      }}
                    />
                  </div>
                </>
              )}
            </div>
          </>
        ) : subForm === 'DISCOUNT' ? (
          <>
            <div className="field_row Polaris-Card__Section">
              {formData?.variants?.length <= 0 && (
                <div className="no_products_selected">
                  No products selected.
                </div>
              )}
              {formData?.variants?.map((v, i) => {
                return (
                  <div className="variants_setting_card">
                    <div className="field_row_label mt-16">DISCOUNT</div>
                    <div className="discount_field_wrapper">
                      <TextField
                        label=""
                        type="number"
                        value={`${v?.discount || 0}`}
                        onChange={(e) => {
                          handleFormVariantData(e, i, 'discount');
                        }}
                      />
                      <div className="select_discount_type">
                        <Select
                          label=""
                          options={[
                            { label: '%', value: 'percentage' },
                            { label: '$', value: 'amount' },
                          ]}
                          value={v?.discountType}
                          onChange={(e) => {
                            handleFormVariantData(e, i, 'discountType');
                          }}
                        />
                      </div>
                    </div>
                    <div className="field_row_label mt-16">DISCOUNT TITLE</div>
                    <div className="field_row_content">
                      <TextField
                        label=""
                        type="text"
                        value={v?.discountTitle || ''}
                        onChange={(e) => {
                          handleFormVariantData(e, i, 'discountTitle');
                        }}
                      />
                    </div>
                    <div className="for_variant_container">
                      <img src={v?.image} />
                      <p className="title">{v.title}</p>
                    </div>
                  </div>
                );
              })}
            </div>
          </>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};
export default OfferSettings;
