import React from 'react'

const integrationDetailsData = {
    "klaviyo": {
        image: "klaviyo",
        title: "Klaviyo",
        desc: `Klaviyo is a powerful third-party app that Chargezen directly integrates with, allowing you to take your customer notifications to the next level.
            Klaviyo can be used to create transactional emails, create win-back campaigns for customers who cancel, or target customers who abandon their initial order.`,
        key_features: [
            `Pools data from subscription events generated in Chargezen and triggers transactional email flows based on events like subscription purchased, upcoming order, payment failed, order skipped, etc.`,
            `Segment your customers based on the Chargezen custom attributes like active subscriptions count, products subscribed, next order date, etc.`,
            `Create beautiful personalized emails using pre-designed templates with drag & drop design tools`,
            `Measures opens, clicks, revenue generated, breakdown of generated revenue based on custom attributes, and provides trend reports, cohort analysis, and subscriber growth.`
        ],
        buttons: [
            <button className='integ_footer_button'>Klaviyo Website</button>,
            <button className='integ_footer_button'>Learn More</button>
        ],
        status: "connected"
    },
    "gorgias": {
        image: "",
        title: "Gorgias",
        desc: `With Chargezen Subscriptions and Gorgias working together, you can provide the best customer experience to your loyal subscribers by having the subscriptions data synced directly into your Gorgias account. Your customer support team can solve for subscription queries including the next order date, subscription status, subscribed products, total completed orders without needing to switch between tools. For taking more actions, you can directly navigate to the Chargezen Susbcriptions portal without needing to search for subscription or customer details.`,
        key_features: [
            `Add Chargezen Subscription widget inside your Gorgias ticket portal`,
            `See all the subscription details of the customer without switching tools`,
            `Navigate directly to subscriptions page inside Chargezen and take actions quickly.`,
        ]
    },
    "zapiet": {
        image: "",
        title: "Zapiet",
        desc: `By integrating Chargezen Subscriptions with Zapiet, you can control what dates should be available on the calendar when a customer or admin tries to create a new subscription or reschedule an existing subscription via Chargezen customer and admin portal..`,
        key_features: [
            `Fetch real-time available delivery slots when a customer tries to create or reschedule a subscription in Chargezen.`,
            `Manage your recurring deliveries schedule by preventing customers from selecting non-deliverable dates.`,
        ]
    },
    "google analytics": {
        title: "Google Analytics",
        image: "googleanalytics",
        desc: "Chargezen subscriptions’s Google Analytics 4 integration allows you to track the recurring order transactions of subscriptions along with your non-subscription transactions and thus providing a complete picture of your store revenues in your main GA4 account.",
        key_features: [
            `Server-side integration avoiding the need to add Google tag manager or any tracking scripts separately for the Chargezen app.`,
            `For every event sent on GA4, we add the shopify customer id as the user id which helps in linking different subscription order events to a common customer.`,
            `We only send transaction data for recurring orders and not the origin order generated from Shopify checkout preventing duplication of the same order.`
        ]
    },
    "attentive": {
        title: "Attentive",
        image: "",
        desc: `By integrating Chargezen Subscriptions with Attentive, you can set up transactional journeys to send SMS messages to your subscribers, and you can send one-click password-less links to allow subscribers to modify their subscriptions.`,
        key_features: [
            `Set up transactional journeys based on subscription events like Subscription Started, Upcoming Order, Order Skipped, Payment Failed, Subscription Paused, Subscription Resumed, Subscription Cancelled, and Subscription Reactivated.`,
            `Target subscribers by creating segments based on active subscriptions, products subscribed, cancelled subscriptions, and completed orders.`,
        ]
    },
    "postscript": {
        title: "Postscript",
        image: "",
        desc: `By integrating Chargezen Subscriptions with Post Script, you can set up transactional journeys to send SMS messages to your subscribers, and you can send one-click password-less links to allow subscribers to modify their subscriptions.`,
        key_features: [
            `Set up transactional journeys based on subscription events like Subscription Started, Upcoming Order, Order Skipped, Payment Failed, Subscription Paused, Subscription Resumed, Subscription Cancelled, and Subscription Reactivated.`,
            `Target subscribers by creating segments based on active subscriptions, products subscribed, cancelled subscriptions, and completed orders.s`,
        ]
    },
    "recart": {
        title: "Recart",
        image: "",
        desc: `We offer a direct integration with Recart to help you grow your subscriber lists, engage with subscribers through SMS marketing campaigns, and increase customer lifetime value.`,
        key_features: [
            `Convert more subscribers than your competition by leveraging custom popups for desktop & mobile with two-tap capture technology.`,
            `Recart’s best-in-class flow editor lets you power personalised SMS marketing campaigns for your subscribers and drive revenue on autopilot.`,
            `Built-in compliance, real-time analytics, and 7-day-click attribution for sales.`,
            `Campaign templates for weekly or daily texts: promotions, events & holidays helps you to get started in minutes.`,
        ],
        buttons: [
            <button className='integ_footer_button' >Recart Website</button>,
        ]
    },
    "omnisend": {
        title: "Omnisend",
        image: "",
        desc: `We offer a direct integration with Omnisend as they have everything you need to engage, convert and reactivate your subscribers with personalised and relevant messaging.`,
        key_features: [
            `Grow your subscriber lists using popups, landing pages, sign up boxes or a Wheel of Fortune.`,
            `Sell while sleeping with cart abandonment, welcome and more marketing automation workflows.`,
            `Personalise your messages based on subscribers data, try new channels, and A/B test to find the winning message variations.
            `,
            `Engage & convert your customers with beautiful, shoppable email campaigns.
            `,
        ],
        buttons: [
            <button className='integ_footer_button' >Omnisend Website</button>,
            <button className='integ_footer_button' >Learn more</button>,
        ]
    },
    "sendlane": {
        title: "Sendlane",
        desc: `By integrating Chargezen Subscriptions with Sendlane, you can set up transactional journeys to send email & sms to your subscribers, and you can send one-click password-less links to allow subscribers to modify their subscriptions.`,
        key_features: [
            `Pools data from subscription events generated in Chargezen and triggers transactional email flows based on events like subscription purchased, upcoming order, payment failed, order skipped, etc.`,
            `Segment your customers based on the Chargezen custom attributes like active subscriptions count, products subscribed, next order date, etc.`,
            `Create beautiful personalized emails using pre-designed templates with drag & drop design tools`,
            `Measures opens, clicks, revenue generated, breakdown of generated revenue based on custom attributes, and provides trend reports, cohort analysis, and subscriber growth.`
        ]
    },
    "twilio": {
        title: "Twilio",
        desc: `By integrating Chargezen Subscriptions with Twilio, you can set up transactional journeys to send email & sms to your subscribers, and you can send one-click password-less links to allow subscribers to modify their subscriptions.`,
        key_features: [
            `Pools data from subscription events generated in Chargezen and triggers transactional email flows based on events like subscription purchased, upcoming order, payment failed, order skipped, etc.`,
            `Segment your customers based on the Chargezen custom attributes like active subscriptions count, products subscribed, next order date, etc.`,
            `Create beautiful personalized emails using pre-designed templates with drag & drop design tools`,
        ]
    },
    "referralcandy": {
        title: "Referralcandy",
        desc: `Referral Candy can serve as the best platform to run a customer referral program that works with your store's subscription program, acquiring new customers while retaining existing ones.`,
        key_features: [
            `Set up a referral or affiliate program that incentivizes subscription purchases`,
            `Enable post-purchase popups & emails to enroll customers in your referral program`,
            `Automate customer rewards, you can choose from discount coupons or cash`,
            `Customize your referral program with your logo, images, and brand colors`
        ]
    }
}

export default integrationDetailsData
