import React, { useEffect, useState, } from "react";
import { Card, Frame, Icon, Layout, Page, Pagination } from "@shopify/polaris";
import AppLayout from '../layout/Layout';
import {
    ThumbsUpMajor, CaretUpMinor, SmileyJoyMajor
} from '@shopify/polaris-icons';
import PixelIcon from "../../images/PixelIcon";
import dayjs from "dayjs";

const Notifications = (props) => {
    const { i18n, domain } = props;

    const [page, setPage] = useState(1);
    const [hasMore, setHasMore] = useState(true);
    const [totalPages, setTotalPages] = useState(1);
    const [hasPrevious, setHasPrevious] = useState(false);

    const [pageInfo, setPageInfo] = useState({});
    const [notifications, setNotifications] = useState([]);

    const fetchNotifications = () => {
        fetch("/fetch_all_subscription_logs?shopify_domain=" + domain + "&page=" + page)
            .then(r => r.json())
            .then((data) => {
                let allNotifications = [...data?.subscription_logs] || []
                setNotifications([...allNotifications])
                let page_info = data?.page_info || {}
                setPageInfo({ ...page_info })
            })
    }

    useEffect(() => {
        fetchNotifications();
    }, [page])

    useEffect(() => {

        setTotalPages(pageInfo?.totalPages)
        if (+page < +pageInfo?.totalPages) {
            setHasMore(true);
        }
        else {
            setHasMore(false)
        }
        if (+page <= 1) {
            setHasPrevious(false)
        }
        else {
            setHasPrevious(true)
        }

    }, [pageInfo]);

    console.log("notificationssssssss", notifications)
    return (
        <>
            <Frame>
                <Page>
                    <Layout>
                        <Layout.Section>

                            <div className="notifications_main_div">
                                <div className="customer_activity_main_div">
                                    <Card title={<div className="heading_title"><PixelIcon /> {i18n.t('notifications')}</div>} >
                                        <Card.Section>
                                            {
                                                notifications?.map((notification) => {
                                                    return (
                                                        <div className="customer_activity_div">
                                                            <span class="chargezen_round_text_icon">
                                                                {notification.contract.first_name?.[0]?.toUpperCase() || ""}{notification.contract.last_name?.[0]?.toUpperCase() || ""}
                                                            </span>
                                                            <div className="customer_activity_right">
                                                                <div className="heading">
                                                                    <h2>{notification.contract.first_name || ""} {notification.contract.last_name || ""}</h2>
                                                                    <h3>{notification.contract.subscription}</h3>
                                                                </div>
                                                                <div className="body">
                                                                    <h6>{notification.description}</h6>
                                                                    <h6>{dayjs(notification.created_at).format('DD MMM, YYYY')}</h6>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                            }
                                            <div style={{ width: '100%', justifyContent: 'center', display: 'flex' }}>
                                                <Pagination
                                                    hasPrevious={hasPrevious}
                                                    onPrevious={() => {
                                                        setPage((prev) => +prev - 1)
                                                    }}
                                                    hasNext={hasMore}
                                                    onNext={() => {
                                                        setPage((prev) => +prev + 1)
                                                    }}
                                                />
                                            </div>
                                        </Card.Section>
                                    </Card>
                                </div>
                            </div>
                        </Layout.Section>
                    </Layout>
                </Page>
            </Frame>
        </>
    )
}

export default Notifications;