import React, { useCallback, useEffect, useState } from 'react';
import { gql, useMutation } from '@apollo/client';
import { Text, Card, Toast, ChoiceList, Page, PageActions, TextField, Layout, Banner, List, Frame, Select, Icon, Checkbox, Form, DropZone, Thumbnail, Stack, FormLayout, Button, TextContainer } from '@shopify/polaris';

const CreateHowItWorks = (props) => {
    const { domain, worksFormData, setworksFormData, index, worksFieldErrors, setworksFieldErrors, i18n } = props;
    const [toastContent, setToastContent] = useState("");
    const [toastActive, setToastActive] = useState(false);
    const toggleToastActive = useCallback(() => setToastActive((toastActive) => !toastActive), []);

    const [file, setFile] = useState();

    const handleDropZoneDrop = useCallback(
        (_dropFiles, acceptedFiles, _rejectedFiles) =>
            setFile(acceptedFiles[0]),
        []
    );

    const validImageTypes = ['image/gif', 'image/jpeg', 'image/png'];

    const fileUpload = !file && <DropZone.FileUpload />;
    const uploadedFile = file && (
        <div style={{ padding: '0' }}>
            <Stack vertical spacing="5">
                <Stack alignment="center" key={index}>
                    <Thumbnail
                        size="small"
                        alt={file.name}
                        source={
                            validImageTypes.includes(file.type)
                                ? window.URL.createObjectURL(file)
                                : NoteMinor
                        }
                    />
                    <div>
                        {file.name}{' '}
                        <TextContainer>
                            <p>
                                {file.size} bytes
                            </p>
                        </TextContainer>
                    </div>
                </Stack>
            </Stack>
        </div>
    );

    const handleChange = (val, key) => {
        let data = worksFormData;
        data[index][key] = val;
        setworksFormData([...data]);
        if (val) {
            let errors = worksFieldErrors;
            errors.splice(index, 1)
            setworksFieldErrors([...errors])
        }
    }


    console.log("worksFieldErrors", worksFieldErrors)

    useEffect(() => {
        let data = worksFormData;
        data[index]["icon"] = file;
        setworksFormData([...data]);
    }, [file])

    return (
        <Layout>
            <Layout.Section>
                <div className='how_it_works'>
                    <Card sectioned>
                        <Form>
                            <FormLayout>
                                <FormLayout.Group>
                                    <TextField
                                        label={i18n.t('points_programs.title')}
                                        type="text"
                                        value={`${worksFormData?.[index]?.title}`}
                                        onChange={(e) => {
                                            handleChange(e, "title")
                                        }}
                                        error={worksFieldErrors[index]?.title}
                                        autoComplete="off"
                                    />
                                </FormLayout.Group>
                                <FormLayout.Group>
                                    <TextField
                                        label={i18n.t('points_programs.body')}
                                        type="text"
                                        multiline={5}
                                        value={`${worksFormData?.[index]?.body}`}
                                        onChange={(e) => {
                                            handleChange(e, "body")
                                        }}
                                        error={worksFieldErrors[index]?.body}
                                        autoComplete="off"
                                    />
                                </FormLayout.Group>

                                <FormLayout.Group>
                                    {worksFormData?.[index]?.icon_url ?
                                        <div className='work_preview_image'>
                                            <img src={worksFormData?.[index]?.icon_url} />
                                            <button className='change_image' onClick={() => { handleChange(null, "icon_url") }}>{i18n.t('change')}</button>
                                        </div>
                                        :
                                        <DropZone onDrop={handleDropZoneDrop}>
                                            {uploadedFile}
                                            {fileUpload}
                                        </DropZone>
                                    }
                                </FormLayout.Group>
                            </FormLayout>
                        </Form>
                    </Card>
                </div>
            </Layout.Section>
        </Layout>

    )
}

export default CreateHowItWorks;