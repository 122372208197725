import React, { useState, useCallback, useEffect } from 'react';
import { SearchMinor } from '@shopify/polaris-icons';
import { gql, useMutation, useQuery, useLazyQuery } from '@apollo/client';
import {
  Autocomplete,
  TextField,
  Icon,
  TextContainer,
  Spinner,
} from '@shopify/polaris';

const SearchVariants = (props) => {
  const { i18n } = props;
  const {
    setFieldValue,
    fieldName,
    allVariants,
    error,
    setAllVarients,
    setFormData = null,
    formData = null,
  } = props;
  const [value, setValue] = useState({});
  // Search variant to add
  const GET_VARIANTS = gql`
    query ($query: String!) {
      productVariants(first: 10, query: $query) {
        edges {
          node {
            id
            title
            price
            product {
              id
              title
              handle
              images(first: 5) {
                edges {
                  node {
                    originalSrc
                  }
                }
              }
              tags
              descriptionHtml
            }
          }
        }
      }
    }
  `;

  const GET_PRODUCTS = gql`
    query ($query: String!) {
      products(first: 10, query: $query) {
        edges {
          node {
            title
            images(first: 1) {
              edges {
                node {
                  originalSrc
                }
              }
            }
            handle
            tags
            descriptionHtml
            variants(first: 20) {
              edges {
                node {
                  id
                  title
                  price
                }
              }
            }
          }
        }
      }
    }
  `;

  const [variantList, setvariantList] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);

  const [GetProducts, { loading, data, errors }] = useLazyQuery(GET_VARIANTS, {
    fetchPolicy: 'no-cache',
    context: { clientName: 'shopify-link' },
  });
  const [
    AllProducts,
    { loading: productloading, data: productData, errors: productErrors },
  ] = useLazyQuery(GET_PRODUCTS, {
    fetchPolicy: 'no-cache',
    context: { clientName: 'shopify-link' },
  });

  useEffect(() => {
    var result = [];
    if (data && data.productVariants) {
      setvariantList(() => {
        data.productVariants.edges.map((variant) =>
          result.push({
            value: `${variant.node.product.title} ${
              variant.node.title != 'Default Title'
                ? `- ${variant.node.title}`
                : ''
            }`,
            label: `${variant.node.product.title} ${
              variant.node.title != 'Default Title'
                ? `- ${variant.node.title}`
                : ''
            }`,
            id: variant.node.id,
            images: variant.node.product.images.edges.map((img) => {
              return img.node.originalSrc;
            }),
            productId: variant.node.product.id,
            descriptionHtml: variant.node.product.descriptionHtml,
            price: variant.node.price,
          })
        );
        return result;
      });
    }
    if (productData?.products?.edges?.length > 0) {
      productData.products.edges.map((product) => {
        console.log('product', product);
        product = product.node;
        console.log('productnode', product);
        product?.variants?.edges?.map((variant) => {
          result.push({
            value: `${product.title} ${
              variant.node.title != 'Default Title'
                ? `- ${variant.node.title}`
                : ''
            }`,
            label: `${product.title} ${
              variant.node.title != 'Default Title'
                ? `- ${variant.node.title}`
                : ''
            }`,
            id: variant.node.id,
            images: product.images.edges.map((img) => {
              return img.node.originalSrc;
            }),
            price: variant.node.price,
            productId: product.id,
            descriptionHtml: product.descriptionHtml,
          });
        });
      });
    }
    result = result.filter(
      (v, i, a) => a.findIndex((v2) => v2.id === v.id) === i
    );

    console.log('result', result);
    setvariantList([...result]);
  }, [data, productData]);

  const updateText = useCallback((_value) => {
    setValue({ title: _value, variantId: '' });
    if (setFormData) {
      // setFormData({ ...formData, [fieldName]: { title: value, variantId: '' } })
    } else {
      setFieldValue(fieldName, { title: _value, variantId: '' });
    }
    if (_value) {
      GetProducts({ variables: { query: `title:*${_value}*` } });
      AllProducts({ variables: { query: `title:*${_value}*` } });
    }
  }, []);

  useEffect(() => {
    setFormData({ ...formData, [fieldName]: allVariants });
  }, [allVariants]);

  const updateSelection = useCallback(
    (selected) => {
      if (selected?.length > 0) {
        const variant = variantList.find((item) => item.value === selected[0]);
        if (setFormData) {
          setFormData({
            ...formData,
            [fieldName]: {
              title: variant.value,
              variantId: variant.id,
              image: variant.images[0],
              price: variant.price,
              productId: variant.productId,
              descriptionHtml: variant.descriptionHtml,
            },
          });
        } else {
          setFieldValue(fieldName, {
            title: variant.value,
            variantId: variant.id,
            image: variant.images[0],
            price: variant.price,
            productId: variant.productId,
            descriptionHtml: variant.descriptionHtml,
          });
        }
        setSelectedOptions(selected);

        let flag = true;

        // for (let i = 0; allVariants?.length > i; i++) {
        //   if (allVariants[i]?.title == variant.value) {
        //     flag = false;
        //     break;
        //   } else {
        //     flag = true;
        //   }
        // }

        if (flag) {
          allVariants
            ? allVariants.push({
                title: variant.value,
                variantId: variant.id,
                image: variant.images[0],
                price: variant.price,
                descriptionHtml: variant.descriptionHtml,
                productId: variant.productId,
              })
            : (allVariants = [
                {
                  title: variant.value,
                  variantId: variant.id,
                  image: variant.images[0],
                  price: variant.price,
                  descriptionHtml: variant.descriptionHtml,
                  productId: variant.productId,
                },
              ]);
        }
        setAllVarients([...allVariants]);
      }
    },
    [variantList, value]
  );

  const textField = (
    <Autocomplete.TextField
      onChange={updateText}
      label=""
      type="text"
      value={value.title}
      prefix={<Icon source={SearchMinor} color="base" />}
      placeholder={i18n.t('search_variant_to_add')}
      error={error}
      suffix={
        loading && (
          <Spinner accessibilityLabel="Small spinner example" size="small" />
        )
      }
    />
  );

  const emptyState = (
    <React.Fragment>
      <Icon source={SearchMinor} />
      <div style={{ textAlign: 'center' }}>
        <TextContainer>Could not find any results</TextContainer>
      </div>
    </React.Fragment>
  );
  return (
    <Autocomplete
      options={variantList ?? variantList}
      selected={selectedOptions}
      onSelect={updateSelection}
      textField={textField}
      emptyState={emptyState}
      willLoadMoreResults={true}
    />
  );
};

export default SearchVariants;
