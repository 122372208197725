import React, { useState, useCallback, useEffect } from 'react';
import { Card, Tabs, Frame, Page } from '@shopify/polaris';
import '@shopify/polaris/dist/styles.css';
import SlideItem from './slideItem';
import './style.css';
import CustomizeSubscriptionWidget from './CustomizeSubscriptionWidget';
import { useParams } from 'react-router-dom';

function ThemeExtension(props) {
  const { i18n, domain } = props;
  const { edit_id } = useParams();
  const [selected, setSelected] = useState(0);

  const handleTabChange = useCallback((selectedTabIndex) => {
    setSelected(selectedTabIndex);
  }, []);

  const [customiseSubscription, setCustomiseSubscription] = useState(false);

  const tabs = [
    {
      id: 'all-customers-1',
      content: 'All',
      accessibilityLabel: 'All customers',
      panelID: 'all-customers-content-1',
    },
    {
      id: 'Food-&-Beverage',
      content: 'Food & Beverage',
      panelID: 'Food-&-Beverage',
    },
    {
      id: 'Health-&-Beauty',
      content: 'Health & Beauty',
      panelID: 'Health-&-Beauty',
    },
    {
      id: 'Gift-Guide',
      content: 'Gift Guide',
      panelID: 'Gift-Guide',
    },
    {
      id: 'Fashion',
      content: 'Fashion',
      panelID: 'Fashion',
    },
    {
      id: 'Furniture',
      content: 'Furniture',
      panelID: 'Furniture',
    },
    {
      id: 'Technology',
      content: 'Technology',
      panelID: 'Technology',
    },
  ];
  const [tabsData, setTabsData] = useState([]);

  const fetchTemplates = () => {
    fetch('/fetch_subscription_widget_templates', {
      method: 'GET',
    })
      .then((r) => r.json())
      .then((data) => {
        if (data?.templates.length > 0) {
          setTabsData([...data?.templates]);
        }
      });
  };

  useEffect(() => {
    fetchTemplates();
    if (edit_id) {
      setCustomiseSubscription(edit_id);
    }
  }, []);

  return customiseSubscription ? (
    <CustomizeSubscriptionWidget i18n={i18n} domain={domain} />
  ) : (
    <Frame>
      <div className="rewards_template_wrapper theme_extension_wrapper">
        <Page
          title={
            <div class="back-button">
              <nav role="navigation">
                <button
                  data-polaris-unstyled="true"
                  class="Polaris-Breadcrumbs__Breadcrumb"
                  onClick={() => history.push('/')}
                  style={{ paddingLeft: '6px' }}
                >
                  <span class="Polaris-Breadcrumbs__Icon">
                    <span class="Polaris-Icon">
                      <span class="Polaris-VisuallyHidden"></span>
                      <svg
                        viewBox="0 0 20 20"
                        class="Polaris-Icon__Svg"
                        focusable="false"
                        aria-hidden="true"
                      >
                        <path d="M17 9h-11.586l3.293-3.293a.999.999 0 1 0-1.414-1.414l-5 5a.999.999 0 0 0 0 1.414l5 5a.997.997 0 0 0 1.414 0 .999.999 0 0 0 0-1.414l-3.293-3.293h11.586a1 1 0 1 0 0-2z"></path>
                      </svg>
                    </span>
                  </span>
                  <span class="Polaris-VisuallyHidden">Quizzes</span>
                </button>
              </nav>
              <div class="Polaris-Page-Header__TitleWrapper">
                <div>
                  <div class="Polaris-Header-Title__TitleAndSubtitleWrapper">
                    <h1 class="Polaris-Header-Title">Theme extensions</h1>
                  </div>
                </div>
              </div>
            </div>
          }
        >
          <div className="rewards_choose_template show_content_3">
            <Card>
              <Tabs tabs={tabs} selected={selected} onSelect={handleTabChange}>
                <Card.Section title={tabs[selected].content}>
                  {/* <p>Tab {selected} selected</p> */}
                  <div class="sort-by">
                    <div class="inner-wrap-sort">
                      <label>Sort by:</label>
                      <select
                        id="PolarisSelect1"
                        class="Polaris-Select__Input"
                        aria-invalid="false"
                      >
                        <option value="popular">Popularity</option>
                        <option value="recent">Most recent</option>
                      </select>
                      <svg
                        viewBox="0 0 20 20"
                        class="Polaris-Icon__Svg"
                        focusable="false"
                        aria-hidden="true"
                      >
                        <path d="M7.676 9h4.648c.563 0 .879-.603.53-1.014l-2.323-2.746a.708.708 0 0 0-1.062 0l-2.324 2.746c-.347.411-.032 1.014.531 1.014Zm4.648 2h-4.648c-.563 0-.878.603-.53 1.014l2.323 2.746c.27.32.792.32 1.062 0l2.323-2.746c.349-.411.033-1.014-.53-1.014Z"></path>
                      </svg>
                    </div>
                  </div>
                  {tabsData?.map((data) => {
                    return (
                      <SlideItem
                        data={data}
                        setCustomiseSubscription={setCustomiseSubscription}
                      />
                    );
                  })}
                </Card.Section>
              </Tabs>
            </Card>
          </div>
        </Page>
      </div>
    </Frame>
  );
}

export default ThemeExtension;
