import React from 'react';
import "./style.css";

const LoadingScreen = () => {
    return (
        <div class="chargezen_loading_animation_container">
            <div class="ball"></div>
            <div class="ball"></div>
            <div class="ball"></div>
            <div class="ball"></div>
            <div class="ball"></div>
            <div class="ball"></div>
            <div class="ball"></div>
        </div>
        // <div className="preloader">
        //     <div className="preloader_sub_div">
        //         <div className="loader_main">
        //             <span className="loader"></span>
        //         </div>
        //         <div className="loadingText">Loading</div>
        //     </div>
        // </div>
    )
}

export default LoadingScreen;