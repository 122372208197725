import React, { useEffect, useState } from "react";
import { Card, Checkbox, Icon, Stack, TextField } from "@shopify/polaris";
import {
    DragHandleMinor, TextMajor, PinMajor, ImageWithTextMajor, MobileBackArrowMajor
} from '@shopify/polaris-icons';

const RewardsTimeLine = (props) => {
    const { i18n, domain, setActive, setToasContent, setShowHeader, setShowEditRewards, setRewardsRowData, allRewards, setAllRewards } = props;
    const [rewardTimeline, setRewardTimeLine] = useState(false);

    const fetchRewardsTimeline = () => {
        fetch(`/fetch_rewards_timeline?shopify_domain=${domain}`,
            { method: "GET" }
        ).then(r => r.json())
            .then((data) => {
                setRewardTimeLine(data?.rewards_time_line)
                setAllRewards([]);
                if (data?.rewards?.length) {
                    setAllRewards(data?.rewards)
                }
            })
    }

    useEffect(() => {
        fetchRewardsTimeline();
    }, [])

    const handleSubmit = (timelineStatus) => {
        fetch("/toggle_rewards_timeline", {
            method: "POST",
            body: JSON.stringify({ data: { rewards_time_line: timelineStatus }, shopify_domain: domain }),
            headers: {
                'Content-Type': 'application/json',
            },
        }).then(r => r.json())
            .then((data) => {
                if (data?.success) {
                    setActive(true);
                    setToasContent("Saved successfully.")
                }
            })
    }


    useEffect(() => {
        setShowHeader(false);
    }, [])

    const editReward = (reward) => {
        setShowEditRewards(true);
        setRewardsRowData(reward);
    }

    return (
        <>
            <div className='upsell_index_container'>
                <div className='custom_sub_header_wrapper'>
                    <h1 className="shopify_style_header">
                        Rewards Timeline
                    </h1>
                    <div className='chargezen_button_group'>
                        <button className='chargezen_primary' onClick={() => { setShowEditRewards(true); setRewardsRowData() }}>Add reward</button>
                    </div>
                </div>
                <Card
                    title={<div className="heading_title">Rewards Timeline</div>}
                    sectioned
                >
                    <div className="card-box upsell_form_wrapper">
                        <div className="rewards_toggle_status_wrapper">
                            <Checkbox
                                label={`${rewardTimeline ? "Disable" : "Enable"} rewards timeline`}
                                checked={rewardTimeline}
                                onChange={() => {
                                    setRewardTimeLine(!rewardTimeline);
                                    handleSubmit(!rewardTimeline);
                                }}
                            />
                        </div>
                        <ul className='upsell_listings'>
                            {
                                allRewards?.map((reward) => {
                                    return (
                                        <li style={{ padding: "16px" }} onClick={() => editReward(reward)}>
                                            <div className='upsell_listing_content'>
                                                <div className='left_section'>
                                                    <div className='drg_svg_wrapper'>
                                                        <Icon
                                                            source={DragHandleMinor}
                                                            tone="base"
                                                        />
                                                    </div>
                                                    <div className='upsell_desc_wrapper'>
                                                        <h5>{reward?.title}</h5>
                                                    </div>
                                                </div>
                                                <div className='right_section'>
                                                    <div className=' upsell_sec'>
                                                        <span>{reward?.trigger_value} {reward.reward_trigger === "consecutive_billing_cycles" ? "cycles" : ""}</span>
                                                    </div>
                                                    <div className=' upsell_sec'>
                                                        {reward?.status}
                                                    </div>
                                                    <div className=' upsell_sec'>
                                                        <div className='drg_svg_wrapper pin_icon'>
                                                            <Icon
                                                                source={PinMajor}
                                                                tone="base"
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                    )
                                })
                            }
                        </ul>
                    </div>
                </Card>
            </div>
        </>
    )
}

export default RewardsTimeLine


