import { Icon } from '@shopify/polaris';
import { BalanceMajor } from '@shopify/polaris-icons';
import { PointOfSaleMajor } from '@shopify/polaris-icons';
import { EmailNewsletterMajor } from '@shopify/polaris-icons';
import { ReferralMajor } from '@shopify/polaris-icons';
import { CashDollarMajor } from '@shopify/polaris-icons';
import {
  ImageMajor,
  MobileBackArrowMajor,
  StoreStatusMajor,
} from '@shopify/polaris-icons';
import React from 'react';

const SummaryComponent = (props) => {
  const { i18n, summaryView, setSummaryView } = props;
  return (
    <div className="summary_side_nav_container">
      <div className="summary_list_wrapper">
        <ul className="summary_list">
          <li
            className={`list_item ${
              summaryView == 'affiliatesStatus' ? 'active' : ''
            }`}
            onClick={() => {
              setSummaryView('affiliatesStatus');
            }}
          >
            <div className="icon_wrapper">
              <Icon source={StoreStatusMajor} color="base" />
            </div>
            <div className="list_title">Affiliates program status</div>
          </li>
          <li
            className={`list_item ${
              summaryView == 'affiliates' ? 'active' : ''
            }`}
            onClick={() => {
              setSummaryView('affiliates');
            }}
          >
            <div className="icon_wrapper">
              <Icon source={ImageMajor} tone="base" />
            </div>
            <div className="list_title">Affiliates</div>
          </li>
          <li
            className={`list_item ${
              summaryView == 'affiliate_commission' ? 'active' : ''
            }`}
            onClick={() => {
              setSummaryView('affiliate_commission');
            }}
          >
            <div className="icon_wrapper">
              <Icon source={ImageMajor} tone="base" />
            </div>
            <div className="list_title">Commission profiles</div>
          </li>
          {/* <li
            className={`list_item ${
              summaryView == 'affiliateOrders' ? 'active' : ''
            }`}
            onClick={() => {
              setSummaryView('affiliateOrders');
            }}
          >
            <div className="icon_wrapper">
              <Icon source={CashDollarMajor} color="base" />
            </div>
            <div className="list_title">Affiliate orders</div>
          </li>
          <li
            className={`list_item ${
              summaryView == 'affiliatesPayouts' ? 'active' : ''
            }`}
            onClick={() => {
              setSummaryView('affiliatesPayouts');
            }}
          >
            <div className="icon_wrapper">
              <Icon source={BalanceMajor} color="base" />
            </div>
            <div className="list_title">Affiliates payouts</div>
          </li>
          <li
            className={`list_item ${
              summaryView == 'affiliatePage' ? 'active' : ''
            }`}
            onClick={() => {
              setSummaryView('affiliatePage');
            }}
          >
            <div className="icon_wrapper">
              <Icon source={ReferralMajor} color="base" />
            </div>
            <div className="list_title">Affiliate page</div>
          </li>
          <li
            className={`list_item ${
              summaryView == 'affiliatesHome' ? 'active' : ''
            }`}
            onClick={() => {
              setSummaryView('affiliatesHome');
            }}
          >
            <div className="icon_wrapper">
              <Icon source={ReferralMajor} color="base" />
            </div>
            <div className="list_title">Affiliates home</div>
          </li>
          <li
            className={`list_item ${
              summaryView == 'analytics' ? 'active' : ''
            }`}
            onClick={() => {
              setSummaryView('analytics');
            }}
          >
            <div className="icon_wrapper">
              <Icon source={PointOfSaleMajor} color="base" />
            </div>
            <div className="list_title">Analytics</div>
          </li>
          <li
            className={`list_item ${
              summaryView == 'affiliateProgramSetting' ? 'active' : ''
            }`}
            onClick={() => {
              setSummaryView('affiliateProgramSetting');
            }}
          >
            <div className="icon_wrapper">
              <Icon source={EmailNewsletterMajor} color="base" />
            </div>
            <div className="list_title">Affiliate Program setting</div>
          </li> */}
        </ul>
      </div>
    </div>
  );
};

export default SummaryComponent;
