import { Icon } from '@shopify/polaris';
import { SearchMajor } from '@shopify/polaris-icons';
import React, { useState } from 'react';
import ProductVariantModal from './ProductVariantModal';
import { ChevronDownMinor, MobileCancelMajor } from '@shopify/polaris-icons';

const ProductVariantPicker = (props) => {
  const { setFieldValue, formData, setFormData, disabled } = props;
  let { values, fieldName } = props
  const [showProductModal, setShowProductModal] = useState(false);
  const [query, setQuery] = useState();

  fieldName ||= "productVariant"
  values ||= formData;
  values[fieldName] ||= []


  const removeItem = (productIndex) => {
    const products = values[fieldName];
    products.splice(productIndex, 1);
    if (setFormData) {
      setFormData({ ...formData, [fieldName]: [...products] })
    } else {
      setFieldValue(fieldName, [...products]);
    }
  };

  return (
    <div className="product-variant-picker-container">
      {showProductModal && (
        <ProductVariantModal
          {...{
            showProductModal,
            setShowProductModal,
            query,
            setQuery,
            setFieldValue,
            values,
            formData,
            setFormData,
            fieldName
          }}
        />
      )}
      <div className="product_searchProduct">
        <div className="product_search_input">
          <Icon source={SearchMajor} tone="base" />
          <input
            type="text"
            placeholder="Search products or variants"
            id="search_product_query"
            onChange={(e) => {
              setQuery(e?.target?.value || '');
              setShowProductModal(true);
            }}
            value={query}
            disabled={disabled}
          />
        </div>
        <button
          type="button"
          className="browse_product_button"
          onClick={() => setShowProductModal(true)}
          disabled={disabled}
        >
          Browse
        </button>
      </div>
      <div className="product_preview_row">
        {values[fieldName]?.map((p, i) => {
          return (
            <div className="product_variant_preview_wrapper">
              <div className="imgwrapper">
                <img src={p.image} />
              </div>
              <div className="pv_desc_container">
                <div className="product_variant_desc">
                  <span>{p.title}</span>
                  <span>
                    ({p.variants.filter((v) => v.selected).length} of{' '}
                    {p.variants?.length}) variants selected
                  </span>
                </div>
                <div className="edit_remove_wrapper">
                  <button
                    type="button"
                    className="editbutton"
                    onClick={() => setShowProductModal(true)}
                    disabled={disabled}
                  >
                    Edit
                  </button>
                  <button
                    type="button"
                    className="close"
                    onClick={() => removeItem(i)}
                    disabled={disabled}
                  >
                    <Icon source={MobileCancelMajor} tone="base" />
                  </button>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default ProductVariantPicker;
