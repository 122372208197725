import React from 'react';
import { ProfileMajor, PaymentsMajor } from '@shopify/polaris-icons';
import { Icon } from '@shopify/polaris';

const AffiliateSettingSideBar = (props) => {
  const { settingsSideBar, changeTab } = props;
  function domainToAcronym(domain) {
    try {
      if (domain.includes('-')) {
        return domain
          .split('-')
          .map((d) => d[0]?.toUpperCase())
          .join('');
      } else if (domain.includes(' ')) {
        return domain
          .split(' ')
          .map((d) => d[0]?.toUpperCase())
          .join('');
      } else {
        return `${domain[0]?.toUpperCase()}${domain[1]?.toUpperCase()}`;
      }
    } catch (error) {
      return '';
    }
  }
  return (
    <div className="affiliate_setting_side_container">
      <div className="affiliate_setting_side_wrapper">
        <div className="affiliate_setting_sidebar_header">
          <button className="header_dropdown_button">
            <span className="icon-text">
              {domainToAcronym(localStorage.getItem('currentuser'))}
            </span>
            <span>
              <div className="affiliate_user_name_wrapper">
                {localStorage.getItem('currentuser')}
              </div>
              <div className="affiliate_email_wrapper">
                {localStorage.getItem('currentuser_email')}
              </div>
            </span>
          </button>
        </div>
        <div className="affiliate_setting_sidebar_body">
          <ul className="setting_item_list">
            {settingsSideBar?.map((sidebar) => {
              return (
                <li className="active" onClick={() => changeTab(sidebar?.tab)}>
                  <div class="setting_list_item">
                    <a className="">
                      <div class="setting_list_item_wrapper">
                        <div>
                          <span class="icon_wrapper">{sidebar?.icon}</span>
                        </div>
                        <span class="tab_text">
                          <span>{sidebar?.title}</span>
                        </span>
                        <div></div>
                      </div>
                    </a>
                  </div>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default AffiliateSettingSideBar;
