import React, { useState } from 'react';
import { Card, Icon } from '@shopify/polaris';
import { HorizontalDotsMinor } from '@shopify/polaris-icons';

const AlreadyCreatedGiftCards = ({
  menu,
  setEditId,
  setToastContent,
  fetchGiftCardSettings,
  apiData,
}) => {
  const [moreOptions, setMoreOptions] = useState(false);

  const deleteOffer = (gift_card_setting_id) => {
    fetch(`/gift_card/delete_card_setting/${gift_card_setting_id}`, {
      method: 'DELETE',
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status) {
          fetchGiftCardSettings();
          setToastContent('Gift Card Settings Deleted');
        }
      })
      .catch((e) => {
        console.log('Somethind went wrong' + e);
      });
  };

  const duplicateOffer = (gift_card_setting_id) => {
    fetch(`/gift_card/duplicate_card_setting/${gift_card_setting_id}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status) {
          fetchGiftCardSettings();
          setToastContent('Gift Card Settings Duplicated');
        }
      })
      .catch((e) => {
        console.log('Somethind went wrong' + e);
      });
  };

  const templateImage = (template_id) => {
    let currentTemplate = apiData?.find(
      (template) => +template.id === +template_id
    );
    console.log('currentTemplate', currentTemplate, template_id);
    return currentTemplate?.images?.[0];
  };

  const toggleStatus = (setting_id, status) => {
    fetch(`/gift_card/toggle_status/${setting_id}`, {
      method: 'POST',
      body: JSON.stringify({ status: status }),
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status) {
          fetchGiftCardSettings();
        }
      })
      .catch((e) => {
        console.log('Somethind went wrong' + e);
      });
  };

  return (
    <div className="upsell_offer_wrapper_container">
      <Card>
        <div className="upsell_offer_wrapper">
          <div
            className="more_options"
            onClick={() => {
              setMoreOptions(!moreOptions);
            }}
          >
            <div className="more_options icon_wrapper">
              <Icon source={HorizontalDotsMinor} color="base" />
            </div>
            {moreOptions && (
              <div className="options_lists">
                <div
                  className="option_wrapper"
                  onClick={() => setEditId(menu.id)}
                >
                  Edit
                </div>
                <div
                  className="option_wrapper"
                  onClick={() => {
                    toggleStatus(
                      menu?.id,
                      `${menu.status == 'live' ? 'draft' : 'live'}`
                    );
                  }}
                >
                  {menu.status == 'live' ? 'Unpublish' : 'Publish'}
                </div>
                <div
                  className="option_wrapper"
                  onClick={() => duplicateOffer(menu.id)}
                >
                  Duplicate
                </div>
                <div
                  className="option_wrapper delete_offer"
                  onClick={() => deleteOffer(menu.id)}
                >
                  Delete
                </div>
              </div>
            )}
          </div>
          <div className="img_wrapper">
            <img src={templateImage(menu.gift_card_template_id)} />
          </div>
          <div className="content_block">
            <span className="offer_title" onClick={() => setEditId(menu.id)}>
              {menu.title}
            </span>
            <div className="date_wrapper">Created at: {menu.created_at}</div>
          </div>
        </div>
      </Card>
    </div>
  );
};

export default AlreadyCreatedGiftCards;
