import React, { useState, useCallback, useEffect } from 'react';
import { SearchMinor } from '@shopify/polaris-icons';
import { gql, useMutation, useQuery, useLazyQuery } from '@apollo/client';
import {
    Autocomplete,
    TextField,
    Icon,
    TextContainer,
    Spinner,
} from '@shopify/polaris';

const SearchVariants = (props) => {
    const { i18n, error, formData, setFormData, index } = props;

    const [value, setValue] = useState("");
    // Search variant to add
    const [allVariants, setAllVarients] = useState([]);
    const GET_VARIANTS = gql`
        query ($query: String!) {
            productVariants(first: 10, query: $query) {
                edges {
                    node {
                            id
                            title
                            price
                            product {
                                title
                                images(first: 1) {
                                    edges {
                                        node {
                                            originalSrc
                                        }
                                    }
                                }
                            }
                    }
                }
            }
            }
    `;

    const [variantList, setvariantList] = useState([]);
    const [selectedOptions, setSelectedOptions] = useState([]);

    const [GetProducts, { loading, data, errors }] = useLazyQuery(GET_VARIANTS, {
        fetchPolicy: 'no-cache',
        context: { clientName: 'shopify-link' },
    });

    useEffect(() => {
        if (data && data.productVariants) {
            setvariantList(() => {
                const result = [];
                data.productVariants.edges.map((variant) =>
                    result.push({
                        value: `${variant.node.product.title} ${variant.node.title != "Default Title" ? `- ${variant.node.title}` : ""}`,
                        label: `${variant.node.product.title} ${variant.node.title != "Default Title" ? `- ${variant.node.title}` : ""}`,
                        id: variant.node.id,
                        images: variant.node.product.images.edges.map(img => {
                            return img.node.originalSrc;
                        }),
                        price: variant.node.price
                    })
                );
                return result;
            });
        }
    }, [data]);

    const updateText = useCallback(
        (value) => {
            if (value) {
                GetProducts({ variables: { query: `title:*${value}*` } });
            }
        },
        [value]
    );

    const updateSelection = useCallback(
        (selected) => {
            if (selected?.length > 0) {
                const variant = variantList.find((item) => item.value === selected[0]);
                setSelectedOptions(selected);



                let flag = true;

                for (let i = 0; allVariants?.length > i; i++) {
                    if (allVariants[i]?.title == variant.value) {
                        flag = false;
                        break;
                    } else {
                        flag = true;
                    }
                }

                if (flag) {
                    allVariants ? allVariants.push({
                        title: variant.value,
                        variantId: variant.id,
                        image: variant.images[0],
                        price: variant.price
                    }) : allVariants = [{
                        title: variant.value,
                        variantId: variant.id,
                        image: variant.images[0],
                        price: variant.price
                    }];
                }
                setAllVarients([...allVariants])
            }
        },
        [variantList, value]
    );

    const textField = (
        <Autocomplete.TextField
            onChange={updateText}
            label=""
            type="text"
            value={value.title}
            prefix={<Icon source={SearchMinor} color="base" />}
            placeholder={i18n.t('search_variant_to_add')}
            error={error}
            suffix={
                loading && (
                    <Spinner accessibilityLabel="Small spinner example" size="small" />
                )
            }
        />
    );

    const emptyState = (
        <React.Fragment>
            <Icon source={SearchMinor} />
            <div style={{ textAlign: 'center' }}>
                <TextContainer>Could not find any results</TextContainer>
            </div>
        </React.Fragment>
    );

    useEffect(() => {
        if (formData?.conditions[index]?.variants) {
            setAllVarients(formData?.conditions[index]?.variants)
        }
    }, [])

    useEffect(() => {
        let form_data_conditions = formData?.conditions;
        form_data_conditions[index]["variants"] = allVariants;
        setFormData({ ...formData, conditions: form_data_conditions })
    }, [allVariants])

    return (
        <div className=''>
            <div className='selected_plan_wrapper'>
                {
                    allVariants?.map((variant, i) => {
                        return (
                            <div className='selectedPlan'>
                                <div className='removePlan' onClick={() => {
                                    let all_variants = [...allVariants];
                                    all_variants?.splice(i, 1)
                                    setAllVarients([...all_variants])
                                }}
                                >
                                    x

                                </div>
                                {variant?.title}
                            </div>
                        )
                    })
                }
            </div>
            <Autocomplete
                options={variantList ?? variantList}
                selected={selectedOptions}
                onSelect={updateSelection}
                textField={textField}
                emptyState={emptyState}
                willLoadMoreResults={true}
            />
        </div>
    );
};

export default SearchVariants;
