import { Button, Card, DropZone, Form, FormLayout, Frame, Icon, Layout, Page, Stack, TextContainer, TextField, Thumbnail, Toast } from "@shopify/polaris";
import React, { useCallback, useState } from "react";
import PixelIcon from "../../images/PixelIcon";

const CreateTemplate = (props) => {
    const { domain } = props

    const [formData, setFormData] = useState({ title: "", tags: "", active: false })
    const [toastContent, setToastContent] = useState("");
    const [toastActive, setToastActive] = useState(false);
    const validImageTypes = ['image/gif', 'image/jpeg', 'image/png'];
    const [files, setFiles] = useState([]);
    const toggleToastActive = useCallback(() => setToastActive((toastActive) => !toastActive), []);


    const handleDropZoneDrop = useCallback(
        (_dropFiles, acceptedFiles, _rejectedFiles) => {
            console.log("LegacyStack", acceptedFiles, _dropFiles, _rejectedFiles)
            setFiles((files) => [...files, ...acceptedFiles])
        },
        [],
    );
    console.log("files", files, validImageTypes)

    const fileUpload = !files.length && <DropZone.FileUpload />;
    const uploadedFiles = files.length > 0 && (
        <div style={{ padding: '0' }}>
            <Stack vertical spacing="5">
                {files.map((file, index) => (
                    <Stack alignment="center" key={index}>
                        <Thumbnail
                            size="small"
                            alt={file.name}
                            source={
                                validImageTypes.includes(file.type)
                                    ? window.URL.createObjectURL(file)
                                    : NoteMinor
                            }
                        />
                        <div>
                            {file.name}{' '}
                            <TextContainer>
                                <p>
                                    {file.size} bytes
                                </p>
                            </TextContainer>
                        </div>
                    </Stack>
                ))}
            </Stack>
        </div>
    );


    const handleSubmit = () => {
        const formdata = new FormData();
        formdata.append("title", formData?.title)
        formdata.append("tags", formData?.tags)
        formdata.append("shopify_domain", domain);
        formdata.append("files_count", files.length)
        files?.map((file, i) => {
            formdata.append(`template_images${i}`, file);
        })
        fetch('/create_rewards_template', {
            method: "POST",
            body: formdata
        }).then(response => response.json())
            .then((data) => {
                setToastContent("Saved Successfully")
                if (!data?.error) {
                    setTimeout(() => {
                        window.location.reload();
                    }, 3000)
                }
                setToastActive(true)
            });
    }


    return (
        <>
            <Frame>
                {
                    toastActive && (
                        <Toast content={toastContent} onDismiss={toggleToastActive} />
                    )
                }
                <Page
                    title={
                        <div className='back_button_wrapper'>
                            <div class="text_wrapper">
                                Create Rewards Template
                            </div>
                        </div>
                    }
                >
                    <Layout>
                        <Layout.Section>
                            <Card title={
                                <div className="heading_title">
                                    <PixelIcon />
                                    Create Rewards template
                                </div>
                            } sectioned>
                                <Form>
                                    <FormLayout>
                                        <FormLayout.Group>
                                            <TextField
                                                label={"Title"}
                                                type="text"
                                                value={`${formData?.title}`}
                                                onChange={(e) => {
                                                    setFormData({ ...formData, title: e })
                                                }}

                                                autoComplete="off"
                                            />
                                        </FormLayout.Group>

                                        <FormLayout.Group>
                                            <TextField
                                                label={"Tags"}
                                                type="text"
                                                multiline={4}
                                                helpText="Sperate each tag with ','"
                                                value={`${formData?.tags}`}
                                                onChange={(e) => {
                                                    setFormData({ ...formData, tags: e })
                                                }}

                                                autoComplete="off"
                                            />
                                        </FormLayout.Group>
                                        <FormLayout.Group>
                                            <DropZone onDrop={handleDropZoneDrop} label="Hero Image" actionHint="Upload images that can be used on the rewards page hero" allowMultiple>
                                                {uploadedFiles}
                                                {fileUpload}
                                            </DropZone>
                                        </FormLayout.Group>
                                    </FormLayout>
                                    <FormLayout>
                                        <FormLayout.Group>
                                            <Button primary onClick={handleSubmit}>Save</Button>
                                        </FormLayout.Group>
                                    </FormLayout>
                                </Form>
                            </Card>
                        </Layout.Section>
                    </Layout>
                </Page>
            </Frame >
        </>
    )
}

export default CreateTemplate;