import React, { useCallback, useEffect, useState } from 'react';
import { render } from 'react-dom';
import {
  Icon,
  AppProvider,
  TextField,
  Select,
  Frame,
  Card,
  Checkbox,
  RadioButton,
  Page,
  Toast,
  DropZone,
  Stack,
  Thumbnail,
  Caption,
} from '@shopify/polaris';
import { ChevronLeftMinor } from '@shopify/polaris-icons';
import '@shopify/polaris/dist/styles.css';
import RewardsPageHtml from './RewardsPageHtml';
import HideSideNav from '../helpers/HideSideNav';
import './Style.css';
import FirstLevelContentManager from './ManageForm/FirstLevelContentManager';

const RewardsPageCustomizer = (props) => {
  const { domain, i18n } = props;

  const [formData, setFormData] = useState({});
  const [contentBlocks, setContentBlocks] = useState([]);

  const [toastContent, setToastContent] = useState();
  const hideSaveSuccess = useCallback(() => setToastContent(), []);

  const [currentForm, setCurrentForm] = useState();
  const [addContent, setAddContent] = useState();
  const [currentContentBlock, setCurrentContentBlock] = useState();
  const [headerButton, setHeaderButton] = useState('Body');

  const headerButtons = [
    {
      val: 'Body',
      name: 'Body',
    },
    {
      val: 'Theme',
      name: 'Theme',
    },
  ];

  useEffect(() => {
    if (!!addContent && contentBlocks) {
      const content = contentBlocks.find((c) => +c.temp_token == +addContent);
      console.log('contentssss', addContent, content, contentBlocks);
      setCurrentContentBlock(content);
    } else {
      setCurrentContentBlock();
    }
  }, [addContent, contentBlocks]);

  console.log('toastContenttoastContent', toastContent);
  return (
    <div className="giftCard_settings_container">
      <Frame>
        <Card sectioned>
          <div className="rewards_launcher_settings_wrapper">
            <div className="header_wraper">
              {currentForm ? (
                <span className="left_section">
                  <div
                    onClick={() => {
                      if (currentContentBlock) {
                        setAddContent();
                      } else {
                        setCurrentForm();
                      }
                    }}
                  >
                    <Icon source={ChevronLeftMinor} color="base" />
                  </div>

                  <h2>
                    {currentContentBlock
                      ? currentContentBlock?.content_type
                      : currentForm
                      ? i18n.t(currentForm)
                      : headerButton}
                  </h2>
                </span>
              ) : (
                <span className="left_section lancher_settings_toggle_header_buttons">
                  {headerButtons.map((button) => {
                    return (
                      <button
                        className={`toggle_header_button ${
                          button.val === headerButton ? 'active' : ''
                        }`}
                        onClick={() => {
                          setHeaderButton(button.val);
                        }}
                      >
                        {button?.name}
                      </button>
                    );
                  })}
                </span>
              )}

              <div className="heder-right-section"></div>
            </div>
            <div className="content-section">
              <div className="left_sidebarr">
                <FirstLevelContentManager
                  {...{
                    domain,
                    formData,
                    setFormData,
                    contentBlocks,
                    setContentBlocks,
                    currentForm,
                    setCurrentForm,
                    currentContentBlock,
                    addContent,
                    setAddContent,
                    setCurrentContentBlock,
                    i18n,
                    headerButton,
                  }}
                />
                {!addContent && !currentForm && (
                  <div className="customize-card">
                    <p>
                      Customize your theme with branded images, text, icons and
                      more, when you upgrade.
                    </p>
                  </div>
                )}
              </div>
              <div className="right-section">
                {
                  <RewardsPageHtml
                    formData={formData}
                    currentForm={currentForm}
                    contentBlocks={contentBlocks}
                  />
                }
              </div>
            </div>
          </div>
        </Card>
        <HideSideNav />
      </Frame>
    </div>
  );
};

export default RewardsPageCustomizer;
