import React, { useCallback, useEffect, useState } from 'react';
import { gql, useMutation } from '@apollo/client';
import {
  Text,
  Card,
  Toast,
  ChoiceList,
  Page,
  PageActions,
  TextField,
  Layout,
  Banner,
  List,
  Frame,
  Select,
  Icon,
  Checkbox,
  Form,
  DropZone,
  Thumbnail,
  Stack,
  FormLayout,
  Button,
  TextContainer,
} from '@shopify/polaris';

const AddApplePassBackFields = (props) => {
  const { domain, backFieldsData, setBackFieldsData, index, i18n } = props;
  const [toastContent, setToastContent] = useState('');
  const [toastActive, setToastActive] = useState(false);
  const toggleToastActive = useCallback(
    () => setToastActive((toastActive) => !toastActive),
    []
  );

  const handleChange = (val, key) => {
    let data = backFieldsData;
    data[index][key] = val;
    setBackFieldsData([...data]);
  };

  return (
    <div className="apple_pass_back_fields">
      <Form>
        <FormLayout>
          <FormLayout.Group>
            <TextField
              label="Label"
              type="text"
              value={`${backFieldsData?.[index]?.label}`}
              onChange={(e) => {
                handleChange(e, 'label');
              }}
              autoComplete="off"
            />
          </FormLayout.Group>
          <FormLayout.Group>
            <TextField
              label="Value"
              type="text"
              value={`${backFieldsData?.[index]?.value}`}
              onChange={(e) => {
                handleChange(e, 'value');
              }}
              autoComplete="off"
            />
          </FormLayout.Group>

          <FormLayout.Group>
            <TextField
              label="Attributed value"
              type="text"
              helpText="you can add link with this, just pass the anchor tag (<a>)"
              multiline={5}
              value={`${backFieldsData?.[index]?.attributed_value}`}
              onChange={(e) => {
                handleChange(e, 'attributed_value');
              }}
              autoComplete="off"
            />
          </FormLayout.Group>
        </FormLayout>
      </Form>
    </div>
  );
};

export default AddApplePassBackFields;
