import { gql, useLazyQuery } from '@apollo/client';
import { Button, Checkbox, TextField } from '@shopify/polaris';
import React, { useCallback, useEffect, useState } from 'react';
import './ProductVariantPicker.css';

const ProductVariantModal = (props) => {
  const {
    showProductModal,
    setShowProductModal,
    query,
    setQuery,
    setFieldValue,
    values,
    formData,
    setFormData,
    fieldName
  } = props;
  const [allProducts, setAllProducts] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [product, setProduct] = useState();


  const closeModal = () => {
    setShowProductModal();
  };

  const GET_PRODUCTS = gql`
    query ($query: String!) {
      products(first: 20, query: $query) {
        edges {
          node {
            id
            title
            images(first: 1) {
              edges {
                node {
                  originalSrc
                }
              }
            }
            variants(first: 20) {
              edges {
                node {
                  id
                  title
                  price
                }
              }
            }
          }
        }
      }
    }
  `;

  const [
    AllProducts,
    { loading: productloading, data: productData, errors: productErrors },
  ] = useLazyQuery(GET_PRODUCTS, {
    fetchPolicy: 'no-cache',
    context: { clientName: 'shopify-link' },
  });

  useEffect(() => {
    if (values[fieldName].length > 0) {
      setSelectedProducts([...values[fieldName]]);
    }
    AllProducts({ variables: { query: `` } });
  }, []);

  const handleChange = (e) => {
    setQuery(e);
    let toQuery = e ? `title:*${e}*` : '';
    AllProducts({ variables: { query: toQuery } });
  };

  const checkIfAllVariantsSelected = (pd) => {
    let product = selectedProducts.find((p) => p.id === pd.id);
    if (product) {
      let selectedVariants = product.variants.filter((v) => v.selected);
      return +pd.variants.length === +selectedVariants.length;
    } else {
      return false;
    }
  };

  useEffect(() => {
    var result = [];
    if (productData?.products?.edges?.length > 0) {
      productData.products.edges.map((product) => {
        product = product.node;
        result.push({
          title: product.title,
          id: product.id,
          image: product.images.edges?.[0]?.node?.originalSrc,
          variants: product?.variants?.edges?.map((variant) => {
            return {
              id: variant.node.id,
              title: variant.node.title,
              price: variant.node.price,
              selected: false,
            };
          }),
        });
      });
    }
    setAllProducts([...result]);
  }, [productData]);

  const selectAllVariants = (e, prod) => {
    let index = selectedProducts.findIndex((p) => p.id === prod.id);
    const currentProducts = selectedProducts;
    if (index > -1) {
      currentProducts.splice(index, 1);
    } else {
      prod.variants = prod.variants?.map((v) => {
        v.selected = true;
        return v;
      });
      currentProducts.push(prod);
    }
    setSelectedProducts([...currentProducts]);
  };

  const selectVariant = (prod, variantid) => {
    const currentProducts = selectedProducts;
    let index = selectedProducts.findIndex((p) => p.id === prod.id);
    prod.variants = prod.variants?.map((v) => {
      if (v.id === variantid) {
        v.selected = !v.selected;
      }
      return v;
    });
    if (index <= -1) {
      currentProducts.push(prod);
    } else {
      currentProducts[index] = prod;
    }

    setSelectedProducts([...currentProducts]);
  };

  const handleSubmit = () => {
    if (formData) {
      setFormData({ ...formData, [fieldName]: [...selectedProducts] })
    } else {
      setFieldValue(fieldName, [...selectedProducts]);
    }
    closeModal();
  };

  const checkVariantSelection = (pid, vid) => {
    let product = selectedProducts?.find((p) => p.id === pid);
    if (!product) {
      return false;
    } else {
      let variant = product.variants?.find((v) => v.id === vid);
      return variant.selected;
    }
  };

  return (
    <div className="chargezen_search_model product_variant_picker">
      <div className="modal-content-wrapper">
        <div className="chargezen_search_model_header_container">
          <h2>Select products</h2>
        </div>
        <div className="chargezen_search_model_body">
          <TextField
            value={query}
            label=""
            placeholder="Search  for a product"
            onChange={(e) => {
              handleChange(e);
            }}
          />
          <div class="segments_list_wrapper_container">
            <ul class="segments_lists">
              {allProducts?.map((pv) => {
                return (
                  <li>
                    <Checkbox
                      label={
                        <div className="productWrapper">
                          <span className="imgwrapper">
                            <img src={pv.image} />
                          </span>
                          <span>{pv.title}</span>
                        </div>
                      }
                      checked={checkIfAllVariantsSelected(pv)}
                      onChange={(e) => {
                        selectAllVariants(e, pv);
                      }}
                    />
                    <div className="variantsContainer">
                      {pv.variants?.map((variant) => {
                        return (
                          <div className="variantRow">
                            <Checkbox
                              label={
                                <div className="productWrapper">
                                  <span>{variant.title}</span>
                                  <span>${variant.price}</span>
                                </div>
                              }
                              checked={checkVariantSelection(pv.id, variant.id)}
                              onChange={(e) => {
                                selectVariant(pv, variant.id);
                              }}
                            />
                          </div>
                        );
                      })}
                    </div>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
        <div className="chargezen_search_model_footer justify-between">
          <div className="chargezen_addToCart">
            {selectedProducts.length === 1
              ? '1 product selected'
              : selectedProducts.length > 1
                ? `${selectedProducts.length} products selected`
                : ''}
          </div>
          <div className="action_buttons">
            <Button onClick={() => closeModal()}>Cancel</Button>
            <Button
              onClick={() => {
                handleSubmit();
              }}
            >
              Select
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductVariantModal;
