import React, { useState, useEffect, useCallback } from 'react';
import AppLayout from '../layout/Layout';
import { Page, Card, Layout, Icon } from '@shopify/polaris';
import SettingImage from '../../../assets/images/settings/setting.svg';
import EmailImage from '../../../assets/images/settings/email.svg';
import InformationImage from '../../../assets/images/settings/information.svg';
import Smarty from '../smarty/Index';
import Upsell from '../upsell/Index';
import CreateUpsell from '../upsell/New';
import BuildBox from '../build-a-box/BuildBox';
import CreateBuildBox from '../build-a-box/CreateBuildBox';
import Bundles from '../bundles';
import BundleForm from '../bundles/BundleForm';
import CreateBundleMenu from '../BundleMenu/CreateBundleMenu';
import WaysToEarnPoint from '../WaysToEarnPoint';
import BundleMenus from '../BundleMenu/Index';
import { MobileBackArrowMajor } from '@shopify/polaris-icons';
import LoisaWeeklyMenu from '../LoisaWeeklyMenu';
import CreateLoisaWeeklyMenu from '../LoisaWeeklyMenu/CreateLoisaWeeklyMenu';
import { Link, useHistory, useParams } from 'react-router-dom';

const Index = (props) => {
  const { i18n, domain, formatDate } = props;
  const { tabname } = useParams();
  const [selectedSetting, setSelectedSetting] = useState(tabname || '');
  const history = useHistory();

  const [editId, setEditId] = useState('');
  const [editMode, setEditMode] = useState();

  const [showEditPage, setShowEditPage] = useState(false);

  const handleBackSetting = useCallback(() => {
    let toRedirect = window.location.pathname
      ?.replace(`/${tabname}`, '')
      ?.replace(domain?.replace('.myshopify.com', ''), '')
      ?.replace('//', '/');
    history.push(toRedirect);
  }, []);

  const handleCloseEditPage = useCallback(() => {
    setShowEditPage(false);
    setEditId('');
  }, [setShowEditPage, editId]);

  const handleEditPage = useCallback(
    (ID) => {
      setEditId(ID);
      setShowEditPage(true);
    },
    [setEditId, setShowEditPage]
  );

  useEffect(() => {
    setShowEditPage(false);
    if (selectedSetting != 'createLoisaWeeklyMenu') {
      setEditId('');
    }
  }, [selectedSetting]);

  useEffect(() => {
    if (selectedSetting && selectedSetting !== tabname) {
      let toRedirect = window.location.pathname
        ?.replace(`/${tabname}`, '')
        ?.replace(domain?.replace('.myshopify.com', ''), '')
        ?.replace('//', '/');

      history.push(`${toRedirect}/${selectedSetting}`);
    }
  }, [selectedSetting]);

  const settings = [
    {
      key: 'smarty_sms',
      title: 'SmartySMS',
      img: SettingImage,
      desc: 'View and update your SMS messages for your store.',
    },
    {
      key: 'upsells',
      title: 'Upsells',
      img: SettingImage,
      desc: 'Create or view upsells for replenishMe campaigns & the customer portal',
    },
    {
      key: 'build-box',
      title: 'Build-a-box Campaign',
      img: SettingImage,
      desc: 'Create or view build-a-box bundles for the storefront',
    },
    {
      key: 'bundles',
      title: 'Bundles',
      img: SettingImage,
      desc: 'Create or view bundles for the storefront.',
    },
    {
      key: 'bundleMenu',
      title: 'Bundle Menu',
      img: SettingImage,
      desc: 'Create or view custom bundles for the storefront',
    },
    {
      key: 'loisaWeeklyMenu',
      title: 'Subscription Bundles',
      img: SettingImage,
      desc: 'Create or view Subscription Bundles for the storefront',
    },
    // {
    //   key: 'waysToEarnPoint',
    //   title: 'Ways To Earn Points ',
    //   img: InformationImage,
    //   desc: 'Create or update ways to earn points.',
    // }
  ];

  return (
    <>
      <Page
        title={
          <div className="back_button_wrapper">
            {selectedSetting ? (
              <div className="back-button pointer" onClick={handleBackSetting}>
                <Icon source={MobileBackArrowMajor} color="base" />
              </div>
            ) : (
              ''
            )}{' '}
            <div class="text_wrapper">{i18n.t('tiazen')}</div>
          </div>
        }
      >
        <div className="settings-container">
          {!selectedSetting && (
            <>
              <div>
                <Card>
                  <Card.Section>
                    <div class="setting-grid">
                      {settings.map((setting) => (
                        <div
                          key={Math.random()}
                          class="tabs-parents pointer"
                          onClick={() => setSelectedSetting(setting.key)}
                        >
                          <div class="icon-sec">
                            <img src={setting.img} />
                          </div>
                          <div class="tab-info">
                            <h4>{setting.title}</h4>
                            <p>{setting.desc}</p>
                          </div>
                        </div>
                      ))}
                    </div>
                  </Card.Section>
                </Card>
              </div>
            </>
          )}
          {/* settings layout */}
          {selectedSetting && (
            <>
              <Layout>
                <Layout.Section>
                  {selectedSetting === 'smarty_sms' ? (
                    <>
                      <Smarty
                        handleBack={handleBackSetting}
                        handleForm={handleEditPage}
                        i18n={i18n}
                        formatDate={formatDate}
                        domain={domain}
                      />
                    </>
                  ) : selectedSetting === 'upsells' ? (
                    <>
                      {showEditPage ? (
                        <CreateUpsell
                          id={editId}
                          handleClose={handleCloseEditPage}
                          i18n={i18n}
                        />
                      ) : (
                        <Upsell
                          handleBack={handleBackSetting}
                          handleForm={handleEditPage}
                          i18n={i18n}
                        />
                      )}
                    </>
                  ) : selectedSetting === 'build-box' ? (
                    <>
                      {showEditPage ? (
                        <CreateBuildBox
                          id={editId}
                          handleClose={handleCloseEditPage}
                          i18n={i18n}
                        />
                      ) : (
                        <BuildBox
                          handleBack={handleBackSetting}
                          handleForm={handleEditPage}
                          i18n={i18n}
                        />
                      )}
                    </>
                  ) : selectedSetting === 'bundles' ? (
                    <>
                      {showEditPage ? (
                        <BundleForm
                          id={editId}
                          handleClose={handleCloseEditPage}
                          i18n={i18n}
                        />
                      ) : (
                        <Bundles
                          handleBack={handleBackSetting}
                          handleForm={handleEditPage}
                          i18n={i18n}
                        />
                      )}
                    </>
                  ) : selectedSetting === 'bundleMenu' ? (
                    <>
                      <BundleMenus
                        handleBack={handleBackSetting}
                        handleForm={handleEditPage}
                        i18n={i18n}
                      />
                    </>
                  ) : selectedSetting === 'loisaWeeklyMenu' ? (
                    <>
                      <LoisaWeeklyMenu
                        handleBack={handleBackSetting}
                        handleForm={handleEditPage}
                        i18n={i18n}
                        setSelectedSetting={setSelectedSetting}
                        editId={editId}
                        setEditId={setEditId}
                        editMode={editMode}
                        setEditMode={setEditMode}
                      />
                    </>
                  ) : selectedSetting === 'createLoisaWeeklyMenu' ? (
                    <>
                      <CreateLoisaWeeklyMenu
                        setSelectedSetting={setSelectedSetting}
                        handleBack={handleBackSetting}
                        handleForm={handleEditPage}
                        i18n={i18n}
                        editId={editId}
                        setEditId={setEditId}
                        domain={domain}
                        editMode={editMode}
                        setEditMode={setEditMode}
                      />
                    </>
                  ) : (
                    // selectedSetting == "waysToEarnPoint" ? (
                    //   <>
                    //     <WaysToEarnPoint />
                    //   </>
                    // ) :
                    ''
                  )}
                </Layout.Section>
              </Layout>
            </>
          )}
        </div>
      </Page>
    </>
  );
};

export default Index;
