import React, { useState, useCallback, useEffect } from 'react';
import { Card, Frame, Icon, Page, Tabs, Toast } from '@shopify/polaris';
import '@shopify/polaris/dist/styles.css';
import SlideItem from './slideItem';
import { gql, useQuery } from '@apollo/client';
import LoyaltyRewards from '../loyalty-rewards/Index';
import { Link, useHistory, useParams } from 'react-router-dom';
import AlreadyCreatedOffers from './AlreadyCreatedOffers';
import {
  ChevronRightMinor,
  MobileBackArrowMajor,
} from '@shopify/polaris-icons';

function ChooseTemplate(props) {
  const { i18n, domain, formatDate, detailView, setDetailView } = props;
  const { edit_id, template_id } = useParams();
  const urlParams = new URLSearchParams(window.location.search);
  const history = useHistory();
  const [selected, setSelected] = useState(0);
  const [toastContent, setToastContent] = useState('');
  const hideSaveSuccess = useCallback(() => setToastContent(), []);

  const [tabsData, setTabsData] = useState([]);

  const [selectedTemplateId, setSelectedTemplateId] = useState();
  const [filteredOffers, setFilteredOffers] = useState({});
  const [allRewards, setAllRewards] = useState([]);
  const [editReward, setEditReward] = useState();

  const GET_TEMPLATES = gql`
    query {
      fetchRewardsTemplates {
        id
        title
        tags
        images
      }
    }
  `;
  const { data, loading, error, refetch } = useQuery(GET_TEMPLATES, {
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    refetch();
    setSelectedTemplateId(template_id);
  }, [template_id]);

  useEffect(() => {
    console.log('data', data);
    let tabdata = [];
    data?.fetchRewardsTemplates?.map((val) => {
      tabdata.push({
        id: val?.id,
        images: val?.images,
        name: val?.title,
        categories_data: val?.tags,
      });
    });
    setTabsData(tabdata);
  }, [data]);

  const handleTabChange = useCallback((selectedTabIndex) => {
    setSelected(selectedTabIndex);
  }, []);

  const tabs = [
    {
      id: 'all-customers-1',
      content: 'All',
      accessibilityLabel: 'All customers',
      panelID: 'all-customers-content-1',
    },
    {
      id: 'Food-&-Beverage',
      content: 'Food & Beverage',
      panelID: 'Food-&-Beverage',
    },
    {
      id: 'Health-&-Beauty',
      content: 'Health & Beauty',
      panelID: 'Health-&-Beauty',
    },
    {
      id: 'Gift-Guide',
      content: 'Gift Guide',
      panelID: 'Gift-Guide',
    },
    {
      id: 'Fashion',
      content: 'Fashion',
      panelID: 'Fashion',
    },
    {
      id: 'Furniture',
      content: 'Furniture',
      panelID: 'Furniture',
    },
    {
      id: 'Technology',
      content: 'Technology',
      panelID: 'Technology',
    },
  ];

  const fetchAllRewards = () => {
    fetch('/fetch_all_rewards?shopify_domain=' + domain, {
      method: 'GET',
    })
      .then((response) => response.json())
      .then((data) => {
        setAllRewards(data?.rewards);
      });
  };

  useEffect(() => {
    fetchAllRewards();
  }, []);

  useEffect(() => {
    let all_rewards = JSON.parse(JSON.stringify(allRewards));
    let live_rewards = [];
    let draft_rewards = [];
    all_rewards?.map((reward) => {
      if (reward.status == 'live') {
        live_rewards.push(reward);
      } else {
        draft_rewards.push(reward);
      }
    });
    let filtered_rewards = {
      live_rewards: live_rewards,
      draft_rewards: draft_rewards,
    };
    setFilteredOffers({ ...filtered_rewards });
  }, [allRewards]);

  return template_id ? (
    <LoyaltyRewards
      i18n={i18n}
      domain={domain}
      setSelectedTemplateId={setSelectedTemplateId}
      selectedTemplateId={template_id}
      rewardsPageId={editReward}
      setRewardsPageId={setEditReward}
      setDetailView={setDetailView}
    />
  ) : (
    <Frame>
      {toastContent && (
        <Toast content={toastContent} onDismiss={hideSaveSuccess} />
      )}
      <div className="chooseTemplateWrapper">
        <div className="rewards_template_wrapper zenn_portal_wrapper">
          <>
            <div className="rewards_choose_template show_content_2">
              <Card>
                <Tabs
                  tabs={tabs}
                  selected={selected}
                  onSelect={handleTabChange}
                >
                  <Card.Section title={tabs[selected].content}>
                    {/* <p>Tab {selected} selected</p> */}
                    <div class="sort-by">
                      <div class="inner-wrap-sort">
                        <label>Sort by:</label>
                        <select
                          id="PolarisSelect1"
                          class="Polaris-Select__Input"
                          aria-invalid="false"
                        >
                          <option value="popular">Popularity</option>
                          <option value="recent">Most recent</option>
                        </select>
                        <svg
                          viewBox="0 0 20 20"
                          class="Polaris-Icon__Svg"
                          focusable="false"
                          aria-hidden="true"
                        >
                          <path d="M7.676 9h4.648c.563 0 .879-.603.53-1.014l-2.323-2.746a.708.708 0 0 0-1.062 0l-2.324 2.746c-.347.411-.032 1.014.531 1.014Zm4.648 2h-4.648c-.563 0-.878.603-.53 1.014l2.323 2.746c.27.32.792.32 1.062 0l2.323-2.746c.349-.411.033-1.014-.53-1.014Z"></path>
                        </svg>
                      </div>
                    </div>
                    {filteredOffers?.live_rewards?.length > 0 && (
                      <div className="all_offers_container">
                        <h4 className="offer_header live_offer_header">
                          Live rewards
                        </h4>
                        {filteredOffers?.live_rewards?.map((reward) => {
                          let templateImage = tabsData?.find(
                            (t) => t.id == reward.rewards_template_id
                          )?.images?.[0];
                          return (
                            <AlreadyCreatedOffers
                              reward={reward}
                              setEditReward={setEditReward}
                              setToastContent={setToastContent}
                              fetchAllRewards={fetchAllRewards}
                              templateImage={templateImage}
                              formatDate={formatDate}
                            />
                          );
                        })}
                      </div>
                    )}

                    {filteredOffers?.draft_rewards?.length > 0 && (
                      <div className="all_offers_container draft_offer_container">
                        <h4 className="offer_header draft_offer_header">
                          Draft rewards
                        </h4>
                        {filteredOffers?.draft_rewards?.map((reward) => {
                          let templateImage = tabsData?.find(
                            (t) => t.id == reward.rewards_template_id
                          )?.images?.[0];
                          return (
                            <AlreadyCreatedOffers
                              reward={reward}
                              setEditReward={setEditReward}
                              setToastContent={setToastContent}
                              fetchAllRewards={fetchAllRewards}
                              templateImage={templateImage}
                              formatDate={formatDate}
                            />
                          );
                        })}
                      </div>
                    )}

                    {tabsData?.map((data) => {
                      return (
                        <SlideItem
                          data={data}
                          setSelectedTemplateId={setSelectedTemplateId}
                          formatDate={formatDate}
                          edit_id={edit_id}
                        />
                      );
                    })}
                  </Card.Section>
                </Tabs>
              </Card>
            </div>
          </>
        </div>
      </div>
    </Frame>
  );
}

export default ChooseTemplate;
