import React, { useCallback, useEffect, useState } from 'react';
import { gql, useMutation } from '@apollo/client';
import {
  Card,
  Toast,
  ChoiceList,
  Page,
  PageActions,
  TextField,
  Layout,
  Banner,
  List,
  Frame,
  Select,
  Icon,
  Checkbox,
  RadioButton,
  DropZone,
  Stack,
  Thumbnail,
  Caption,
  TextContainer,
  Button,
  Popover,
  ActionList,
} from '@shopify/polaris';
import {
  ChevronRightMinor,
  MobileBackArrowMajor,
  DeleteMajor,
} from '@shopify/polaris-icons';
import { useHistory } from 'react-router-dom';
import './style.css';
import CreateQuickActions from './CreateQuickActions';
import { useParams } from 'react-router-dom';
import RenderQuickActions from './RenderQuickActions';

const QuickActions = (props) => {
  const { i18n, detailView, setDetailView, domain } = props;
  const { selected_tab, edit_id } = useParams();

  const [currentAction, setCurrentAction] = useState();
  const [currentCondition, setCurrentCondition] = useState();

  const [fieldError, setFieldError] = useState({
    title: '',
    pointsAwarded: '',
  });

  const [formData, setFormData] = useState({
    conditions: [],
    events: [],
    actions: [],
  });

  const urlBuilderOptions = [
    {
      value: 'view_customer_portal',
      label: 'View your customer portal',
      conditions: [
        {
          label: 'Subscription started on Chargezen',
          value: 'subscription_started',
        },
        {
          label: 'Upcoming order on Chargezen',
          value: 'upcoming_order',
        },
        {
          label: 'Upcoming (prepaid) order on Chargezen',
          value: 'upcoming_prepaid_order',
        },
        {
          label: 'Subscription cancelled on Chargezen',
          value: 'subscription_cancelled',
        },
        {
          label: 'Subscription expired on Chargezen',
          value: 'subscription_expired',
        },
        {
          label: 'Charge error on Chargezen',
          value: 'charge_error_Chargezen',
        },
        {
          label: 'Campaign',
          value: 'campaign',
        },
      ],
    },
    {
      value: 'subscription_paused',
      label: 'Ship next order now',
      conditions: [
        {
          label: 'Subscription started on Chargezen',
          value: 'subscription_started',
        },
        {
          label: 'Upcoming order on Chargezen',
          value: 'upcoming_order',
        },
      ],
    },
    {
      value: 'subscription_resumed',
      label: 'Skip next order',
      conditions: [
        {
          label: 'Subscription started on Chargezen',
          value: 'subscription_started',
        },
        {
          label: 'Upcoming order on Chargezen',
          value: 'upcoming_order',
        },
      ],
    },
    {
      value: 'subscription_cancelled',
      label: 'Add one-time product to the next order',
      conditions: [
        {
          label: 'Subscription started on Chargezen',
          value: 'subscription_started',
        },
        {
          label: 'Upcoming order on Chargezen',
          value: 'upcoming_order',
        },
        {
          label: 'Campaign',
          value: 'campaign',
        },
      ],
    },
    {
      value: 'subscription_expires',
      label: 'Add one-time product to the next order and apply a discount',
      conditions: [
        {
          label: 'Subscription started on Chargezen',
          value: 'subscription_started',
        },
        {
          label: 'Upcoming order on Chargezen',
          value: 'upcoming_order',
        },
        {
          label: 'Campaign',
          value: 'campaign',
        },
      ],
    },
    {
      value: 'subscription_payment_retrying',
      label: 'Swap current subscription',
      conditions: [
        {
          label: 'Subscription started on Chargezen',
          value: 'subscription_started',
        },
        {
          label: 'Upcoming order on Chargezen',
          value: 'upcoming_order',
        },
        {
          label: 'Campaign',
          value: 'campaign',
        },
      ],
    },
    {
      value: 'subscription_payment_last_attempt',
      label: 'Apply discount to next order',
      conditions: [
        {
          label: 'Subscription started on Chargezen',
          value: 'subscription_started',
        },
        {
          label: 'Upcoming order on Chargezen',
          value: 'upcoming_order',
        },
        {
          label: 'Campaign',
          value: 'campaign',
        },
      ],
    },
    {
      value: 'subscription_payment_succeeds',
      label: 'Reactivate cancelled subscription',
      conditions: [
        {
          label: 'Subscription started on Chargezen',
          value: 'subscription_started',
        },
        {
          label: 'Campaign',
          value: 'campaign',
        },
      ],
    },
    {
      value: 'subscription_payment_succeeds',
      label: 'Reactivate cancelled subscription & apply discount code',
      conditions: [
        {
          label: 'Subscription started on Chargezen',
          value: 'subscription_started',
        },
        {
          label: 'Campaign',
          value: 'campaign',
        },
      ],
    },
    {
      value: 'subscription_payment_succeeds',
      label: 'Reactivate cancelled subscription & add one-time product',
      conditions: [
        {
          label: 'Subscription started on Chargezen',
          value: 'subscription_started',
        },
        {
          label: 'Campaign',
          value: 'campaign',
        },
      ],
    },
  ];

  const [showCondtionOption, setShowCondtionOption] = useState(true);
  const [showAddAction, setShowAddAction] = useState(true);
  const [summaryText, setSummaryText] = useState('');
  const [allPlans, setAllPlans] = useState([]);
  const [allSelectedPlanGroups, setAllSelectedPlanGroups] = useState([]);
  const [toastContent, setToastContent] = useState();
  const hideSaveSuccess = useCallback(
    () => history.push(`/customersTab/subscription_workflow`),
    []
  );
  const [allConditions, setAllConditions] = useState([]);
  const history = useHistory();

  return (
    <div className="create_workflow_wrapper">
      <Frame>
        {toastContent && (
          <Toast content={toastContent} onDismiss={hideSaveSuccess} />
        )}
        <RenderQuickActions {...{ i18n, domain }} />
      </Frame>
    </div>
  );
};

export default QuickActions;
