import React, { useEffect, useState } from "react";
import { render } from "react-dom";
import {
    Card,
    Frame,
    Page,
    Tabs,
    AppProvider,
    Form,
    FormLayout,
    TextField,
    Select,
    Button,
    RadioButton,
    ButtonGroup,
    Icon
} from "@shopify/polaris";
import "@shopify/polaris/dist/styles.css";
import { useCallback } from "react";
import PixelIcon from "../../images/PixelIcon";
import { ArrowDownMinor, ArrowUpMinor, DeleteMajor } from "@shopify/polaris-icons";
import CancellationReasons from "./CancellationReasons";
import "./style.css"
import HtmlEditor from "./HtmlEditor";

const EditCancellation = (props) => {
    const {
        i18n,
        selectedTemplateId,
        domain,
        toggleActive,
        edit,
        showEdit,
        setToastContent,
        fetch_cancellation_flow
    } = props;

    const [formData, setFormData] = useState();


    const handleSubmit = () => {
        fetch('/customer_portal/cancellation_reasons', {
            method: 'POST',
            body: JSON.stringify({ data: formData, shopify_domain: domain }),
            headers: {
                'Content-Type': 'application/json',
            },
        }).then(r => r.json()).then((data) => {
            if (data?.success) {
                console.log("submited", data)
                showEdit();
                setToastContent("Saved Successfully");
                fetch_cancellation_flow();
            }
        })
    }

    useEffect(() => {
        if (edit) {
            setFormData(edit)
        }
    }, [edit])

    return (
        <div className="translations_form_wrapper">
            <Card title=
                {
                    <div className="heading_title">
                        <PixelIcon />
                        Edit Cancellation Reason
                    </div>
                }>
                <Card.Section>
                    <div className="content_wrapper_container">
                        <div className="form_wrapper">
                            <Form>
                                <FormLayout>
                                    <div className="show_cancellation_instructions_container cancellation_flow_row_wrapper">
                                        <FormLayout.Group>
                                            <TextField
                                                label="Subscription Cancellation Reason"
                                                name=""
                                                value={formData?.title}
                                                multiline={5}
                                                onChange={(e) => { setFormData({ ...formData, title: e }) }}
                                            />
                                        </FormLayout.Group>
                                    </div>
                                    <div className="show_cancellation_instructions_container cancellation_flow_row_wrapper">
                                        {/* <h2 className="main_title">
                                            Choose which option(s) to show on the customer portal when customer clicks on cancel subscription
                                        </h2>
                                        <FormLayout.Group>
                                            <RadioButton
                                                label="Show a comment field to write why they are cancelling"
                                                checked={formData?.show_other_options === false}
                                                id="show_other_options_false"
                                                name="show_other_options"
                                                onChange={(e) => { setFormData({ ...formData, show_other_options: false }) }}
                                            />
                                        </FormLayout.Group>
                                        <FormLayout.Group>
                                            <RadioButton
                                                label="Show other options to continue their subscription (maximum 3 options can be selected)"
                                                checked={formData?.show_other_options === true}
                                                name="show_other_options"
                                                id="show_other_options_true"
                                                onChange={(e) => { setFormData({ ...formData, show_other_options: true }) }}
                                            />
                                        </FormLayout.Group> */}
                                        <FormLayout.Group>
                                            <div className="html_editor_container">
                                                <label className="custom_label">
                                                    Custom message for cancellation options (HTML supported)
                                                </label>
                                                <HtmlEditor formData={formData} setFormData={setFormData} fieldName={"custom_message_for_cancellation"} />
                                            </div>
                                        </FormLayout.Group>
                                        <FormLayout.Group>
                                            <div className="html_editor_container">
                                                <label className="custom_label">
                                                    Custom message for cancellation confirmation (HTML supported)
                                                </label>
                                                <HtmlEditor formData={formData} setFormData={setFormData} fieldName={"custom_message_for_cancellation_confirmation"} />
                                            </div>
                                        </FormLayout.Group>
                                    </div>
                                </FormLayout>
                            </Form>
                        </div>
                    </div>
                    <div className="submit_button_wrapper">
                        <Button onClick={() => { handleSubmit() }}>Save</Button>
                    </div>
                </Card.Section>
            </Card>

        </div>
    );
};

export default EditCancellation
