import { Button, Card, Checkbox, DropZone, Icon, Select, Stack, TextContainer, TextField, Thumbnail } from "@shopify/polaris";
import React, { useCallback, useEffect, useState } from "react";
import SearchProduct from "../upsell/SearchProduct";
import Preview from "../plans/Preview";
import ProductVariantPicker from "../plans/ProductVariantPicker";
import {
    MobileBackArrowMajor
} from '@shopify/polaris-icons';
import dayjs from "dayjs";

const EditUpsells = (props) => {
    const { upsellInitValues, saveUpsells, upsellRowData, setUpsellRowData, i18n, selectedTemplateId, domain, toggleActive, setNameError, fetchCustomerPortalSettings, files, setFiles, upsellFormData, setUpsellFormData, setShowEditUpsell, setShowHeader } = props
    const [allProducts, setAllProducts] = useState([]);
    const [updated, setUpdated] = useState(false);
    const [endDate, setEndDate] = useState(!!upsellRowData?.end_date);

    const validImageTypes = ['image/gif', 'image/jpeg', 'image/png'];

    const discountOptions = [
        { label: "PERCENTAGE", value: "percentage" },
        { label: "FIXED AMOUNT", value: "fixed_amount" },
        { label: "MANUAL", value: "manual" }
    ]

    const upsellTypeOptions = [
        { label: "Pop up", value: "pop_up" },
        { label: "Carousel", value: "carousel" },
    ]

    const statusOptions = [
        { label: "Active", value: "active" },
        { label: "Draft", value: "draft" },
    ]

    useEffect(() => {
        if (upsellRowData) {
            setUpsellFormData({ ...upsellRowData });
        }
    }, [upsellRowData])

    useEffect(() => {
        setShowHeader(false);
    }, [])

    const handleDropZoneDrop = useCallback(
        (_dropFiles, acceptedFiles, _rejectedFiles) => {
            setFiles((files) => [...files, ...acceptedFiles])
        },
        [],
    );



    const fileUpload = !files.length && <DropZone.FileUpload />;

    const uploadedFiles = files.length > 0 || upsellFormData?.popup_image && (
        <div style={{ padding: '0' }}>
            <Stack vertical spacing="5">
                {
                    files.length > 0 ?
                        files.map((file, index) => (
                            <Stack alignment="center" key={index}>
                                <Thumbnail
                                    size="small"
                                    alt={file.name}
                                    source={
                                        validImageTypes.includes(file.type)
                                            ? window.URL.createObjectURL(file)
                                            : NoteMinor
                                    }
                                />
                                <div>
                                    {file.name}{' '}
                                    <TextContainer>
                                        <p>
                                            {file.size} bytes
                                        </p>
                                    </TextContainer>
                                </div>
                            </Stack>
                        ))
                        :
                        upsellFormData?.popup_image && (
                            <Stack alignment="center">
                                <Thumbnail
                                    size="small"
                                    alt={upsellFormData?.popup_image?.filename}
                                    source={upsellFormData?.popup_image?.url}
                                />
                                <div>
                                    {upsellFormData?.popup_image?.filename}{' '}
                                    <TextContainer>
                                        <p>
                                            {upsellFormData?.popup_image?.size} bytes
                                        </p>
                                    </TextContainer>
                                </div>
                            </Stack>
                        )
                }
            </Stack>
        </div>
    );

    const handlePopupChange = (val, key) => {
        let popup_translations = upsellFormData?.popup_translations
        popup_translations[key] = val;
        setUpsellFormData({ ...upsellFormData, popup_translations: popup_translations })

    }


    return (
        <div className='upsell_index_container'>
            <div className='custom_sub_header_wrapper'>
                <div className="back_button_wrapper">
                    <div
                        className="back-button pointer"
                        onClick={() => {
                            setShowEditUpsell(false);
                            setUpsellRowData(upsellInitValues);
                            setUpsellFormData(upsellInitValues);
                        }}
                    >
                        <Icon source={MobileBackArrowMajor} color="base" />
                    </div>

                    <h1 className="shopify_style_header">
                        {upsellRowData?.id ? "Edit upsell" : "Create a new upsell"}
                    </h1>
                </div>
            </div>
            <Card
                title={<div className="heading_title">Customer portal upsells</div>}
                sectioned
                primaryFooterAction={{
                    content: i18n.t('save_and_continue'),
                    onAction: saveUpsells,
                }}
                footerActionAlignment="left"
            >
                <div className="card-box upsell_form_wrapper">
                    <div className="upsell_form_row">
                        <TextField
                            type="text"
                            label={"Title"}
                            placeholder=''
                            value={upsellFormData?.title}
                            onChange={useCallback((newValue) => setUpsellFormData({ ...upsellFormData, title: newValue }))}
                            autoComplete="off"
                        />
                    </div>
                    <div className="upsell_form_row">
                        <TextField
                            type="text"
                            label={"Subtitle"}
                            placeholder='Customer portal'
                            value={upsellFormData?.subtitle}
                            onChange={useCallback((newValue) => setUpsellFormData({ ...upsellFormData, subtitle: newValue }))}
                            autoComplete="off"
                        />
                    </div>
                    <div className="upsell_form_row multirow_form_control">
                        <div className="seperate_multirow_form">
                            <div style={{ marginTop: "-20px" }}>
                                <Select
                                    label={"Discount Value"}
                                    value={upsellFormData?.discount_type}
                                    onChange={useCallback((newValue) => setUpsellFormData({ ...upsellFormData, discount_type: newValue }))}
                                    options={discountOptions}
                                />
                            </div>
                            <TextField
                                type="number"
                                label={<>&nbsp;</>}
                                value={upsellFormData?.discount}
                                onChange={useCallback((newValue) => setUpsellFormData({ ...upsellFormData, discount: newValue }))}
                                suffix="%"
                                autoComplete="off"
                            />
                        </div>
                        <div className="seperate_multirow_form">
                            <Select
                                label="Upsell Type"
                                value={upsellFormData?.upsell_type}
                                onChange={useCallback((newValue) => setUpsellFormData({ ...upsellFormData, upsell_type: newValue }))}
                                options={upsellTypeOptions}
                            />
                        </div>
                        <div className="seperate_multirow_form">
                            <Select
                                label="Status"
                                value={upsellFormData?.status}
                                onChange={useCallback((newValue) => setUpsellFormData({ ...upsellFormData, status: newValue }))}
                                options={statusOptions}
                            />
                        </div>
                    </div >
                    <div className="upsell_form_row">
                        <TextField
                            type="text"
                            label={"Button text"}
                            value={upsellFormData?.button_text}
                            onChange={useCallback((newValue) => setUpsellFormData({ ...upsellFormData, button_text: newValue }))}
                            autoComplete="off"
                        />
                    </div>
                    <div className="upsell_form_row">
                        <div className='holder color-holder'>
                            <h3>Products</h3>
                            <div className="product-search">
                                <ProductVariantPicker
                                    i18n={i18n}
                                    setFormData={setUpsellFormData}
                                    formData={upsellFormData}
                                    fieldName="variant_images"
                                    selectType="variant"
                                    previewVariants={true}
                                />
                            </div>
                        </div>
                    </div>

                    {
                        upsellFormData.upsell_type == "pop_up" && (
                            <>
                                <div className="upsell_form_row">
                                    <DropZone onDrop={handleDropZoneDrop} label="Popup background image" allowMultiple={false}>
                                        {uploadedFiles}
                                        {fileUpload}
                                    </DropZone>
                                </div>
                                <div className="upsell_form_row">
                                    <TextField
                                        label="Popup heading"
                                        value={upsellFormData.popup_translations?.heading}
                                        onChange={(e) => {
                                            handlePopupChange(e, 'heading')
                                        }}
                                    />
                                </div>
                                <div className="upsell_form_row">
                                    <TextField
                                        label="Popup sub heading"
                                        value={upsellFormData.popup_translations?.sub_heading}
                                        onChange={(e) => {
                                            handlePopupChange(e, 'sub_heading')
                                        }}
                                    />
                                </div>
                                <div className="upsell_form_row">
                                    <TextField
                                        label="Popup confirm button text"
                                        value={upsellFormData.popup_translations?.confirm_button}
                                        onChange={(e) => {
                                            handlePopupChange(e, 'confirm_button')
                                        }}
                                    />
                                </div>
                                <div className="upsell_form_row">
                                    <TextField
                                        label="Popup cancel button text"
                                        value={upsellFormData.popup_translations?.cancel_button}
                                        onChange={(e) => {
                                            handlePopupChange(e, 'cancel_button')
                                        }}
                                    />
                                </div>
                            </>
                        )
                    }


                    <div className="upsell_form_row">
                        <div className="start_end_date_wrapper">
                            <TextField
                                label="Start date"
                                type="date"
                                value={upsellFormData.active_date}
                                onChange={(value) => setUpsellFormData({ ...upsellFormData, active_date: value })}
                            />
                            <TextField
                                label="Start time (EST)"
                                type="time"
                                value={upsellFormData.active_time}
                                onChange={(value) => setUpsellFormData({ ...upsellFormData, active_time: value })}
                            />
                        </div>
                    </div>
                    <div className="upsell_form_row">
                        <Checkbox
                            label="Set end date"
                            checked={endDate}
                            onChange={() => setEndDate(!endDate)}
                        />
                    </div>
                    {
                        endDate && (
                            <div className="start_end_date_wrapper">
                                <TextField
                                    label="End date"
                                    type="date"
                                    value={upsellFormData.end_date}
                                    onChange={(value) => setUpsellFormData({ ...upsellFormData, end_date: value })}
                                />
                                <TextField
                                    label="End time (EST)"
                                    type="time"
                                    value={upsellFormData.end_time}
                                    onChange={(value) => setUpsellFormData({ ...upsellFormData, end_time: value })}
                                />
                            </div>
                        )
                    }
                </div>
            </Card>
        </div>
    )
}

export default EditUpsells;