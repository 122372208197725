import React, { useEffect, useState } from 'react';
import { Card, Checkbox } from '@shopify/polaris';
import { GetStartedIcon } from '../layout/CustomIcon';

const OrderDetailModal = (props) => {
  const { i18n, domain, setActive, setToasContent, selectedTemplateId } = props;

  const [formData, setFormData] = useState({});

  const fetchOrderDetailModalSetting = () => {
    fetch(
      `/fetch_order_detail_modal_setting?shopify_domain=${domain}&template_id=${selectedTemplateId}`,
      { method: 'GET' }
    )
      .then((r) => r.json())
      .then((data) => {
        if (data?.order_view_settings) {
          setFormData({ ...data?.order_view_settings });
        }
      });
  };

  useEffect(() => {
    fetchOrderDetailModalSetting();
  }, []);

  const handleSubmit = () => {
    fetch('/order_detail_modal_setting', {
      method: 'POST',
      body: JSON.stringify({
        data: formData,
        shopify_domain: domain,
        template_id: selectedTemplateId,
      }),
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((r) => r.json())
      .then((data) => {
        if (data?.success) {
          setActive(true);
          setToasContent('Saved successfully.');
        }
      });
  };
  return (
    <>
      <Card
        title={
          <div class="heading_title">
            {i18n.t('zennportal_form.order_detail_modal')}
          </div>
        }
        sectioned
        primaryFooterAction={{
          content: 'Save and continue',
          onAction: () => {
            handleSubmit();
          },
        }}
        footerActionAlignment="left"
      >
        <div className="card-box">
          <div className="zennportal_style_form_wrapper">
            <div className="manage_colors">
              <div className="sub_manage_color_row">
                <Checkbox
                  label={i18n.t('zennportal_form.show_order_number')}
                  checked={formData.show_order_number}
                  onChange={(e) => {
                    setFormData({ ...formData, show_order_number: e });
                  }}
                />
              </div>
              <div className="sub_manage_color_row">
                <Checkbox
                  label={i18n.t('zennportal_form.show_processed_date')}
                  checked={formData.show_processed_date}
                  onChange={(e) => {
                    setFormData({ ...formData, show_processed_date: e });
                  }}
                />
              </div>
              <div className="sub_manage_color_row">
                <Checkbox
                  label={i18n.t('zennportal_form.show_status')}
                  checked={formData.show_status}
                  onChange={(e) => {
                    setFormData({ ...formData, show_status: e });
                  }}
                />
              </div>
              <div className="sub_manage_color_row">
                <Checkbox
                  label={i18n.t('zennportal_form.show_type')}
                  checked={formData.show_type}
                  onChange={(e) => {
                    setFormData({ ...formData, show_type: e });
                  }}
                />
              </div>
              <div className="sub_manage_color_row">
                <Checkbox
                  label={i18n.t('zennportal_form.show_product_image')}
                  checked={formData.show_product_image}
                  onChange={(e) => {
                    setFormData({ ...formData, show_product_image: e });
                  }}
                />
              </div>
              <div className="sub_manage_color_row">
                <Checkbox
                  label={i18n.t('zennportal_form.show_product_title')}
                  checked={formData.show_product_title}
                  onChange={(e) => {
                    setFormData({ ...formData, show_product_title: e });
                  }}
                />
              </div>
              <div className="sub_manage_color_row">
                <Checkbox
                  label={i18n.t('zennportal_form.show_product_price')}
                  checked={formData.show_product_price}
                  onChange={(e) => {
                    setFormData({ ...formData, show_product_price: e });
                  }}
                />
              </div>
              <div className="sub_manage_color_row">
                <Checkbox
                  label={i18n.t('zennportal_form.show_shipping_address')}
                  checked={formData.show_shipping_address}
                  onChange={(e) => {
                    setFormData({ ...formData, show_shipping_address: e });
                  }}
                />
              </div>
              <div className="sub_manage_color_row">
                <Checkbox
                  label={i18n.t('zennportal_form.show_billing_address')}
                  checked={formData.show_billing_address}
                  onChange={(e) => {
                    setFormData({ ...formData, show_billing_address: e });
                  }}
                />
              </div>
              <div className="sub_manage_color_row">
                <Checkbox
                  label={i18n.t('zennportal_form.show_tax')}
                  checked={formData.show_tax}
                  onChange={(e) => {
                    setFormData({ ...formData, show_tax: e });
                  }}
                />
              </div>
              <div className="sub_manage_color_row">
                <Checkbox
                  label={i18n.t('zennportal_form.show_free_shipping')}
                  checked={formData.show_free_shipping}
                  onChange={(e) => {
                    setFormData({ ...formData, show_free_shipping: e });
                  }}
                />
              </div>
              <div className="sub_manage_color_row">
                <Checkbox
                  label={i18n.t('zennportal_form.show_total')}
                  checked={formData.show_total}
                  onChange={(e) => {
                    setFormData({ ...formData, show_total: e });
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </Card>
    </>
  );
};

export default OrderDetailModal;
