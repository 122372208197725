import React, { useEffect, useState } from "react";

import "./rewardsHtml.css";
const RewardsLauncherHtml = (props) => {
  const { formData, currentForm } = props;
  const [rewardsHtml, setRewardsHtml] = useState();

  useEffect(() => {
    setRewardsHtml(
      `<div class="chargezen_main_container">
      <div class="chargezen_loyalt_branding">
      <div class="chargezen_panel_container panel_container">
        <div class="loading_spinner_cover content_loading loading_spinner_hide"></div>
        <div class="main_panel_container"><div class="panel_header_container expanded_header compact_banner chargezen_rewards_launcher_primary_color">
        <div class="panel_fixed_header">
          <div class="header_content">
            <div class="sub_header_content">
              <div class="text">${formData?.program_name}</div>
            </div>
          </div>
          <button class="close_btn panel_header_icon">
            <i class="fa fa-times" aria-hidden="true"></i>
          </button>
        </div>
  
        <div class="panel_expanded_header">
          <div class="banner_content">
            <div class="header_secondary heading">
              Welcome to
            </div>
            <div class="header_primary">${formData?.program_name}</div>
          </div>
        </div>
      </div>
      <div class="panel_container with_chargezen_footer">
        <div class="home_view_container">
          <div class="panel_card_container join_program_card_container">
            <div class="join_program_card">
              <div class="heading">Become a member</div>
              <div class="card_description">
                With more ways to unlock exciting perks, this is your all
                access pass to exclusive rewards.
              </div>
              <a class="branding_primary_button chargezen_rewards_launcher_primary_color">Join now</a>
              <div class="body_text_wrapper">
                Already have an account?&nbsp;<a class="chargezen_rewards_launcher_secondary_color">Sign in</a>
              </div>
            </div>
          </div>
          <div class="panel_card_container points_explainer_card_container">
            <div>
              <div class="heading">Points</div>
              <div class="card_description">
                Earn more Points for different actions, and turn those
                Points into awesome rewards!
              </div>
              <div class="card_list_container">
                <div class="card_list_item_container">
                  <button class="card_list_item">
                    <div class="card_list_item_content" onclick="renderWaysToEarnPoints()">
                        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30">
                            <g fill="none" fill-rule="nonzero">
                                <path fill="${formData?.color}" d="M15.5 12a3.5 3.5 0 1 1 0-7 3.5 3.5 0 0 1 0 7zM4 16h2a1 1 0 0 1 1 1v8.5a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V17a1 1 0 0 1 1-1z"/>
                                <path fill="#4D4D4D" d="M13.055 17.817l-4.524.803a.75.75 0 0 1-.262-1.477l4.589-.815.131-.011h4.336c2.261 0 3.787 1.425 3.107 4.142a.75.75 0 0 1-.727.568H15.04a.75.75 0 0 1 0-1.5h4.04c.127-1.223-.545-1.71-1.755-1.71h-4.27zm7.822 1.615l-.627.4a.75.75 0 0 1-.807-1.264l.794-.507a.75.75 0 0 1 .404-.118c.25 0 .493-.085.686-.24l2.509-2.007.088-.06a2.785 2.785 0 0 1 3.574.641l.095.118a2.489 2.489 0 0 1-.19 3.35l-5.24 5.343a7.219 7.219 0 0 1-4.612 1.662h-4.577c-.756 0-1.505-.14-2.206-.411L8.13 25.317a.75.75 0 0 1 .542-1.399l2.639 1.022c.528.205 1.093.31 1.664.31h4.577c1.338 0 2.633-.466 3.598-1.263l5.196-5.305a.994.994 0 0 0 .082-1.344l-.095-.117a1.285 1.285 0 0 0-1.606-.316l-2.462 1.97a2.597 2.597 0 0 1-1.387.557z"/>
                            </g>
                        </svg>
                      <div class="list_item_text_wrapper">
                        <div class="list_item_title">Ways to earn</div>
                      </div>
                    </div>
                    <i class="fa fa-chevron-right" aria-hidden="true"></i>
                  </button>
                </div>
                <div class="card_list_item_container" onclick="renderWaysToRedeemPoints()">
                  <button class="card_list_item">
                    <div class="card_list_item_content">
                    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30">
                        <g fill="none" fill-rule="evenodd">
                            <path fill="${formData?.color}" d="M14 8.989a5.193 5.193 0 0 1-2.13 1.509c-1.148.435-2.504.435-4.058.024a.75.75 0 0 1 .383-1.45c1.274.336 2.317.336 3.142.023.471-.178.888-.442 1.255-.796h-2.167a3.007 3.007 0 0 1-3.007-3.006c0-1.677 1.13-3.043 2.688-3.043 1.359 0 2.683.899 3.554 2.252l1.335 1.969 1.334-1.969c.871-1.353 2.196-2.252 3.555-2.252 1.557 0 2.687 1.366 2.687 3.043a3.007 3.007 0 0 1-3.007 3.006h-2.216c.367.354.784.618 1.255.796.825.313 1.868.313 3.142-.024a.75.75 0 0 1 .383 1.45c-1.554.412-2.91.412-4.057-.023A5.182 5.182 0 0 1 16 9.058V25.55a.45.45 0 0 1-.45.45h-1.1a.45.45 0 0 1-.45-.45V8.989zm5.564-2.19c.833 0 1.507-.674 1.507-1.506 0-.905-.527-1.543-1.187-1.543-.786 0-1.675.603-2.303 1.579l-.997 1.47h2.98zm-6.158 0l-.997-1.47c-.628-.976-1.517-1.579-2.303-1.579-.66 0-1.188.638-1.188 1.543 0 .832.675 1.506 1.507 1.506h2.98z"/>
                            <path fill="#4D4D4D" fill-rule="nonzero" d="M25.185 14.737l-.967 10.425a1.75 1.75 0 0 1-1.742 1.588H7.524a1.75 1.75 0 0 1-1.742-1.588l-.967-10.425C3.66 14.615 2.75 13.667 2.75 12.5v-2c0-1.25 1.045-2.25 2.318-2.25h.541a.75.75 0 0 1 0 1.5h-.54c-.46 0-.819.343-.819.75v2c0 .407.359.75.818.75h19.864c.459 0 .818-.343.818-.75v-2c0-.407-.359-.75-.818-.75h-.504a.75.75 0 0 1 0-1.5h.504c1.273 0 2.318 1 2.318 2.25v2c0 1.166-.91 2.115-2.065 2.237zm-18.862.013l.952 10.273a.25.25 0 0 0 .25.227h14.95a.25.25 0 0 0 .25-.227l.952-10.273H6.323z"/>
                        </g>
                        <style xmlns="http://www.w3.org/1999/xhtml" type="text/css"></style>
                    </svg>
                      <div class="list_item_text_wrapper">
                        <div class="list_item_title">Ways to redeem</div>
                      </div>
                    </div>
                    <i class="fa fa-chevron-right" aria-hidden="true"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="panel_card_container referrals_explainer_card_container">
            <div class="referrals_explainer_card">
              <div class="heading">Referrals</div>
              <div class="card_description">
                Give your friends a reward and claim your own when they make
                a purchase.
              </div>
              <div class="card_list_container">
                
                    <div class="card_list_item no_hover">
                      <div class="card_list_item_content">
                        <img src="https://cdn.sweettooth.io/v1/images/rewards/percentage-coupon.svg?color=%236568FE&amp;theme=light" class="list_item_image">
                        <div class="list_item_text_wrapper">
                          <div class="list_item_title">Referred friend reward</div>
                          <div class="list_item_subtitle">
                            17% off entire order
                          </div>
                        </div>
                      </div>
                    </div>
                    
                    <div class="card_list_item no_hover">
                      <div class="card_list_item_content">
                        <img src="https://cdn.sweettooth.io/v1/images/rewards/percentage-coupon.svg?color=%236568FE&amp;theme=light" class="list_item_image">
                        <div class="list_item_text_wrapper">
                          <div class="list_item_title">Referred friend reward</div>
                          <div class="list_item_subtitle">
                            $14 off entire order
                          </div>
                        </div>
                      </div>
                    </div>
                    
              </div>
            </div>
          </div>
        </div>
      </div>
  
      <div class="panel_footer">
        <div class="powered_by_container">
          <div class="powered_by_chargezen_icon"></div>
          <div class="powered_by_text">We reward with Chargezen</div>
        </div>
      </div>
        </div>
      </div>
      <div class="laucher_buton_container">
        <button class="launcher_button chargezen_rewards_launcher_primary_color" onclick="showChargezenRewads()">
            ${formData?.button_content?.toLowerCase()?.includes("icon")
        ? `
            <span class="icon_container button_icon_container">
                ${formData?.button_icon
        }
            </span>
            `
        : ``
      }
            ${formData?.button_content?.toLowerCase()?.includes("text")
        ? `
                <span class="text">
                ${formData?.button_text}
            </span>
                `
        : ``
      }
        
        </button>
      </div>
    </div>
      </div>

      <style>
        .chargezen_rewards_launcher_primary_color{
          background: ${formData?.color} !important;
          
        }
        a.chargezen_rewards_launcher_secondary_color{
          color: ${formData?.color} !important;
        }

        .chargezen_rewards_launcher_primary_color *, a.branding_primary_button.chargezen_rewards_launcher_primary_color {
          color: ${formData?.font_color} !important;
        }

        .icon_container.button_icon_container svg path{
          fill: ${formData?.font_color || "#fff"} !important;
        }
        
      </style>
      `
    );

  }, [formData, currentForm]);

  return (
    <div
      className="rewards_html_container"
      dangerouslySetInnerHTML={{ __html: rewardsHtml }}
    />
  );
};
export default RewardsLauncherHtml;
