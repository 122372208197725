import React, { useCallback, useState } from 'react';
import {
  Button,
  Checkbox,
  Icon,
  RadioButton,
  TextField,
} from '@shopify/polaris';
import {
  MobileBackArrowMajor,
  MobileChevronMajor,
  MobileMajor,
  DesktopMajor,
  ChevronUpMinor,
} from '@shopify/polaris-icons';

const ScheduleOffer = ({
  setShowSchedule,
  editOffer,
  domain,
  rowData,
  fetchUpsell,
  setToastContent,
  fetchAllOffers,
  handleSubmit,
  setEditTemplate,
  setEditOffer,
}) => {
  const [value, setValue] = useState('immediately');
  const [formData, setFormData] = useState({});
  const [showEndTime, setShowEndTime] = useState(false);

  console.log('rowData', rowData);

  const handleChange = useCallback((_, newValue) => setValue(newValue), []);

  const handlePublish = async () => {
    let editofferid = await handleSubmit(true);
    if (editofferid && (value == 'immediately' || formData?.start_date)) {
      let action = rowData?.status == 'live' ? 'draft' : 'live';
      fetch('/upsell/publish', {
        method: 'POST',
        body: JSON.stringify({
          offer_id: editofferid,
          status: action,
          perform_at: value,
          formData: formData,
          shopify_domain: domain,
        }),
        headers: {
          'Content-Type': 'application/json',
        },
      })
        .then((response) => response.json())
        .then((data) => {
          if (data?.status) {
            fetchUpsell();
            setToastContent(
              `Offer ${action == 'live' ? 'Published' : 'Unpublished'}`
            );
            fetchAllOffers();
            setEditTemplate();
            setEditOffer();
          }
        });
    }
  };

  return (
    <div className="card_content_wrapper">
      <div className="main_header_content">
        <button
          className="back_button_pointer"
          onClick={() => {
            setShowSchedule(false);
          }}
        >
          <span className="icon">
            <Icon source={MobileChevronMajor} color="base" />
          </span>
        </button>
        <span className="button_text">Scheduling</span>
      </div>
      <div className="form_field_wrapper schedule_form">
        <div className="radio_button_wrapper">
          <RadioButton
            label="Publish now"
            checked={value === 'immediately'}
            id="immediately"
            name="schedule"
            onChange={handleChange}
          />
          <RadioButton
            label="Publish later"
            id="date"
            name="schedules"
            checked={value === 'date'}
            onChange={handleChange}
          />
        </div>
        {value == 'date' && (
          <div className="publish_date_wrapper">
            <div className="column_wrapper">
              <TextField
                type="date"
                label="Start Date"
                value={formData?.start_date}
                onChange={(e) => {
                  setFormData({ ...formData, start_date: e });
                }}
                autoComplete="off"
              />
              <TextField
                type="time"
                label="Start Time"
                value={formData?.start_time}
                onChange={(e) => {
                  setFormData({ ...formData, start_time: e });
                }}
                autoComplete="off"
              />
            </div>
            <div className="set_end_date_check">
              <Checkbox
                label="Set end date"
                checked={showEndTime}
                onChange={(e) => {
                  setShowEndTime(e);
                }}
              />
            </div>
            {showEndTime && (
              <div className="column_wrapper">
                <TextField
                  type="date"
                  label="End Date"
                  value={formData?.end_date}
                  onChange={(e) => {
                    setFormData({ ...formData, end_date: e });
                  }}
                  autoComplete="off"
                />
                <TextField
                  type="time"
                  label="End Time"
                  value={formData?.end_time}
                  onChange={(e) => {
                    setFormData({ ...formData, end_time: e });
                  }}
                  autoComplete="off"
                />
              </div>
            )}
          </div>
        )}
      </div>
      <div className="field_row navi Polaris-Card__Section">
        <div className="flex justify-between sidebar_action_buttons">
          <Button
            onClick={() => {
              handlePublish();
            }}
          >
            {rowData?.status == 'live' ? 'Unpublish' : 'Publish'}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ScheduleOffer;
