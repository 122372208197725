import {
  ApolloClient,
  ApolloLink,
  ApolloProvider,
  HttpLink,
  InMemoryCache,
} from '@apollo/client';
import { authenticatedFetch } from '@shopify/app-bridge-utils';
import { AppProvider } from '@shopify/polaris';
import enTranslations from '@shopify/polaris/locales/en.json';
import React, { useEffect, useState } from 'react';
import { BrowserRouter, Link, Route, Switch } from 'react-router-dom';
import Analytics from './analytics/Index';
import BuildBox from './build-a-box/BuildBox';
import CreateBuildBox from './build-a-box/CreateBuildBox';
import Customers from './customer/Index';
import CreateCustomer from './customer/New';
// pages ####
import Dashboard from './home/Index';
import Installation from './installation/Index';
import IntegrationDetail from './integration/Detail';
import Integrations from './integration/Index';
import BuildABoxPlan from './plans/BuildABoxPlan';
import FixedPlan from './plans/FixedPlan';
import SellingPlans from './plans/Index';
import MysteryBoxPlan from './plans/MysteryBoxPlan';
import TrialPlan from './plans/TrialPlan';
import Settings from './setting/Index';
import Smarty from './smarty/Index';
import EditSmartyMessage from './smarty/SmartyMessage/EditSmartyMessage';
import Upsell from './upsell/Index';
import CreateUpsell from './upsell/New';
import Tiazen from './Tiazen/Index';
import Toolbox from './Toolbox/Index';
import PowerView from './plans/PowerView';
import CustomerModal from './CustomerModal/Index';

import InstallShop from './InstallShop';
import ManageStaff from './ManageStaff';
import DisabledNav from './layout/DisabledNav';
import NewDashboard from './home/NewDashboard';
import Notifications from './layout/Notifications';
import WaysToEarnPoint from './WaysToEarnPoint';
import LoyaltyRewards from './loyalty-rewards/Index';
import Referrals from './Referrals';
import AppLayout from './layout/Layout';
import StripeContract from './StripeContracts';
import { DomainContext } from './domain-context';
import StripeContractsList from './StripeContracts/StripeContractsList';
import CreateBundleMenu from './BundleMenu/CreateBundleMenu';
import BundleMenus from './BundleMenu/Index';
import Rebuy from './Rebuy/Index';
import CreateRebuy from './Rebuy/CreateRebuy';
import PreOrders from './PreOrder';
import TryBeforeYouBuy from './TryBeforeYouBuy';
import CreatePreorder from './PreOrder/CreatePreorder';
import CreateTryBeforeYouBuy from './TryBeforeYouBuy/CreateTryBeforeYouBuy';
import CreateMembership from './Memberships/CreateMembership';
import Memberships from './Memberships';
import CreateSubscriptionProduct from './SubscriptionProducts/CreateSubscriptionProduct';
import SubscriptionProducts from './SubscriptionProducts';
import { I18n } from 'i18n-js';
import YodaRevenue from './YodaRevenue';
import MLAlgorithms from './MLAlgorithms/Index';
import Quizes from './Quizes';
import CreateQuiz from './Quizes/CreateQuiz/CreateQuiz';
import CreateLoisaWeeklyMenu from './LoisaWeeklyMenu/CreateLoisaWeeklyMenu';
import ChooseTemplate from './ChooseTemplate';
import SubscriptionView from './SubscriptionView';

import PointsProgramFAQs from './loyalty-rewards/PointsProgram/PointsProgramFaqs';
import PointsProgramTiers from './loyalty-rewards/PointsProgram/PointsProgramTiers';
import PointsProgramTiersPerks from './loyalty-rewards/PointsProgram/PointsProgramTiersPerks';
import PointsProgram from './loyalty-rewards/PointsProgram/PointsProgram';
import PointsProgramIndex from './loyalty-rewards/PointsProgramIndex';
import CreateTemplate from './ChooseTemplate/CreateTemplate';
import CustomerDetail from './CustomerModal/CustomerDetail/CustomerDetail';
import ZennPortal from './ZennPortal';
import GetStarted from './GetStarted';
import ReferralPage from './ReferralPage';
import RewardsLauncherSettings from './RewardsLauncher';
import Quizzes from './Quizzes';
import Upsells from './Upsells';
import Bundles from './BundlesTemplates';
import Workflows from './Workflows';
import CancellationFlow from './CancellationFlow';
import PasswordlessLogin from './PasswordlessLogin';
import Checkout from './Checkout';
import dayjs from 'dayjs';
import GiftCard from './GiftCard';
import GiftCardTemplates from './GiftCard/GiftCardTemplates';
import AffiliatePageLayout from './ChargezenAffiliate/AffiliatePageLayout';
import AffiliateDashboard from './ChargezenAffiliate/AffiliateDashboard';
import AffiliateOrders from './ChargezenAffiliate/AffiliateOrders';
import AffiliateSettings from './ChargezenAffiliate/AffiliateSettings';
import AffiliatesIndex from './Affiliates/AffiliatesIndex';
import PlanBilling from './PlanBilling';
import UpsellOverview from './Upsells/UpsellOverview';
import AffiliateDetail from './Affiliates/AffiliateDetail/AffiliateDetail';
import { PickProduct } from './Welcome/PickProduct';
import CheckoutOverview from './Checkout/CheckoutOverview';
import SmartyOverview from './smarty/SmartyOverview';
import ReplenishmeOverview from './Replenishme/ReplenishmeOverview';
import KioskOverview from './Kiosk/KioskOverview';
import QuickActions from './QuickActions/Index';
import CartFunnels from './Upsells/CartFunnels/CartFunnels';

export default function App(props) {
  const {
    shopVersion,
    domain,
    allShops,
    userRole,
    userToken,
    shopBillingStatus,
    selectedApps,
    shopApiData,
    currency
  } = props;
  const [customNavTabs, setCustomNavTabs] = useState({});
  const [floatingSetupGuide, setFloatingSetupGuide] = useState(false);
  const [currentPlan, setCurrentPlan] = useState({});

  const i18n = new I18n(JSON.parse(props?.translations));
  const mixpanelId = '467d5df251a711e7b0ae20d18c8fb2e1';
  i18n.defaultLocale = props?.locale;
  i18n.locale = props?.locale;

  function AdapterLink({ url, ...rest }) {
    return <Link to={url} {...rest} />;
  }
  const fetchNavBarTabs = () => {
    fetch(`/get_nav_bar_setting?shopify_domain=${props?.domain}`, {
      method: 'GET',
    })
      .then((response) => response.json())
      .then((data) => {
        if (data?.nav_bar_tabs) {
          setCustomNavTabs({ ...data?.nav_bar_tabs });
        }
      });
  };

  function formatDate(dateString) {
    try {
      let formatTo = 'DD/MM/YYYY';
      let currentCountry = props?.country;
      if (
        currentCountry === 'Canada' ||
        currentCountry === 'United States' ||
        currentCountry === 'Philippines'
      ) {
        formatTo = 'MM/DD/YYYY';
      }
      const formattedDate = dayjs(dateString).format(formatTo);
      return formattedDate;
    } catch (e) {
      return "";
    }
  }

  useEffect(() => {
    fetchNavBarTabs();
  }, []);

  const fetchShopPlan = () => {
    fetch('/getShopPlan?shopify_domain=' + domain, {
      method: 'GET',
    })
      .then((response) => response.json())
      .then((data) => {
        if (data?.currentPlan) {
          setCurrentPlan({ ...data?.currentPlan });
        }
      });
  };

  useEffect(() => {
    if (domain) {
      fetchShopPlan();
    }
  }, [domain]);

  if (userRole === 'affiliate') {
    return (
      <BrowserRouter basename="/chargezenAffiliate">
        <AppProvider
          i18n={enTranslations}
          theme={{}}
          linkComponent={AdapterLink}
        >
          <Switch>
            <AffiliatePageLayout
              {...{
                i18n,
                mixpanelId,
                customNavTabs,
                setCustomNavTabs,
                floatingSetupGuide,
                setFloatingSetupGuide,
                fetchNavBarTabs,
              }}
            >
              <Route
                exact
                path="/"
                component={() => (
                  <AffiliateDashboard
                    {...{
                      i18n,
                      mixpanelId,
                      customNavTabs,
                      setCustomNavTabs,
                      floatingSetupGuide,
                      setFloatingSetupGuide,
                      fetchNavBarTabs,
                      userToken,
                      formatDate,
                    }}
                  />
                )}
              />
              <Route
                exact
                path="/:user_token"
                component={() => (
                  <AffiliateDashboard
                    {...{
                      i18n,
                      mixpanelId,
                      customNavTabs,
                      setCustomNavTabs,
                      floatingSetupGuide,
                      setFloatingSetupGuide,
                      fetchNavBarTabs,
                      userToken,
                      domain,
                      formatDate,
                    }}
                  />
                )}
              />
              <Route
                exact
                path="/affiliateOrders"
                component={() => (
                  <AffiliateOrders
                    {...{ i18n, userToken, domain, formatDate }}
                  />
                )}
              />
              <Route
                exact
                path="/affiliateOrders/:user_token"
                component={() => (
                  <AffiliateOrders
                    {...{ i18n, userToken, domain, formatDate }}
                  />
                )}
              />
              <Route
                exact
                path="/settings"
                component={() => (
                  <AffiliateSettings
                    {...{ i18n, userToken, domain, formatDate }}
                  />
                )}
              />
              <Route
                exact
                path="/settings/:user_token"
                component={() => (
                  <AffiliateSettings {...{ i18n, userToken, domain }} />
                )}
              />
              <Route
                exact
                path="/settings/:selectedTab/:user_token"
                component={() => (
                  <AffiliateSettings
                    {...{ i18n, userToken, domain, formatDate }}
                  />
                )}
              />
            </AffiliatePageLayout>
          </Switch>
        </AppProvider>
      </BrowserRouter>
    );
  } else if (domain && allShops?.length > 0 && allShops?.includes(domain)) {
    let shopifyLink;
    let mainLink;
    if (window.top == window.self) {
      // Not in an iframe
      origin = window.location.host;
      mainLink = new HttpLink({
        uri: `/${domain?.replace('.myshopify.com', '')}/graphql`,
        credentials: 'include',
        headers: {
          'X-CSRF-Token': document
            .querySelector('meta[name=csrf-token]')
            .getAttribute('content'),
        },
      });
      shopifyLink = new HttpLink({
        credentials: 'include',
        headers: {
          'X-CSRF-Token': document
            .querySelector('meta[name=csrf-token]')
            .getAttribute('content'),
        },
        uri: `/${domain?.replace('.myshopify.com', '')}/graphql-shopify`,
      });
    } else {
      // Inside the iframe
      mainLink = new HttpLink({
        credentials: 'same-origin',
        fetch: authenticatedFetch(window.app),
        uri: `/${domain?.replace('.myshopify.com', '')}/graphql`,
        origin: domain,
      });
      shopifyLink = new HttpLink({
        credentials: 'same-origin',
        fetch: authenticatedFetch(window.app),
        uri: `/${domain?.replace('.myshopify.com', '')}/graphql-shopify`,
        origin: domain,
      });
    }

    const client = new ApolloClient({
      link: ApolloLink.split(
        (operation) => operation.getContext().clientName === 'shopify-link',
        shopifyLink,
        mainLink
      ),
      cache: new InMemoryCache(),
    });

    const [passwordProtected, setPasswordProtected] = useState(
      props.enablePassword
    );

    // const client = new ApolloClient({
    //   link: new HttpLink({
    //     credentials: 'same-origin',
    //     fetch: authenticatedFetch(window.app),
    //     uri: '/graphql',
    //   }),
    //   cache: new InMemoryCache(),
    // });

    useEffect(() => {
      fetch(`/user_shops/authorize_user_shop?shopify_domain=${domain}`, {
        method: 'POST',
      })
        .then((response) => response.json())
        .then((data) => {
          console.log('data', data);
          localStorage.removeItem('accessSettings');
          localStorage.setItem('accessSettings', data?.accessSettings);
        });
      localStorage.setItem('allShops', JSON.stringify(props?.allShops));
    }, []);

    const withLocalization = (Component, specificProp = {}) => {
      return (props) => {
        return (
          <Component
            {...props}
            shopifyDomain={domain}
            {...{
              specificProp,
              currency,
              i18n,
              mixpanelId,
              domain,
              allShops,
              shopVersion,
              formatDate,
              customNavTabs,
              setCustomNavTabs,
              floatingSetupGuide,
              currentPlan,
              setCurrentPlan,
              setFloatingSetupGuide
            }}
          />
        );
      };
    };

    return (
      <BrowserRouter basename={`/${domain?.replace('.myshopify.com', '')}`}>
        <DomainContext.Provider value={{ domain }}>
          <AppProvider
            i18n={enTranslations}
            theme={{}}
            linkComponent={AdapterLink}
          >
            <ApolloProvider client={client}>
              <Switch>
                <AppLayout
                  typePage="createCustomer"
                  tabIndex="2"
                  domain={props?.domain}
                  i18n={i18n}
                  mixpanelId={mixpanelId}
                  allShops={allShops}
                  shopVersion={shopVersion}
                  {...{
                    selectedApps,
                    customNavTabs,
                    setCustomNavTabs,
                    floatingSetupGuide,
                    setFloatingSetupGuide,
                    fetchNavBarTabs,
                    fetchNavBarTabs,
                    shopBillingStatus,
                    shopApiData,
                    currentPlan,
                    setCurrentPlan,
                  }}
                >
                  <Route
                    exact
                    path="/pickProduct"
                    component={() => (
                      <PickProduct
                        {...{ i18n, userToken, domain, selectedApps }}
                      />
                    )}
                  />
                  <Route
                    exact
                    path="/affiliates"
                    component={withLocalization(AffiliatesIndex)}
                  />
                  <Route
                    exact
                    path="/affiliates/:selected_tab"
                    component={withLocalization(AffiliatesIndex)}
                  />
                  <Route
                    exact
                    path="/affiliatesViewAffiliate/:id"
                    component={withLocalization(AffiliateDetail)}
                  />
                  <Route
                    exact
                    path="/affiliates/:selected_tab/:edit_id"
                    component={withLocalization(AffiliatesIndex)}
                  />
                  <Route
                    exact
                    path="/giftCard"
                    component={withLocalization(GiftCardTemplates)}
                  />
                  <Route
                    exact
                    path="/giftCard/:giftCardId"
                    component={withLocalization(GiftCardTemplates)}
                  />
                  <Route
                    exact
                    path="/giftCard/create/:templateid"
                    component={withLocalization(GiftCardTemplates)}
                  />
                  <Route
                    exact
                    path="/checkout"
                    component={withLocalization(CheckoutOverview)}
                  />
                  <Route
                    exact
                    path="/checkoutStyle"
                    component={withLocalization(Checkout)}
                  />
                  {/* <Route
                    exact
                    path="/zenn/cancellationflow"
                    component={withLocalization(CancellationFlow)}
                  />
                  <Route
                    exact
                    path="/zenn/passwordlessLogin"
                    component={withLocalization(PasswordlessLogin)}
                  /> */}
                  <Route
                    exact
                    path="/workflow"
                    component={withLocalization(RewardsLauncherSettings)}
                  />
                  <Route
                    exact
                    path="/RewardsLauncherSettings"
                    component={withLocalization(RewardsLauncherSettings)}
                  />
                  <Route
                    exact
                    path="/ReferralPage"
                    component={withLocalization(ReferralPage)}
                  />
                  <Route
                    exact
                    path="/getStarted"
                    component={withLocalization(GetStarted)}
                  />
                  <Route
                    exact
                    path="/zenn"
                    component={withLocalization(ZennPortal)}
                  />
                  <Route
                    exact
                    path="/zenn/:edit_id"
                    component={withLocalization(ZennPortal)}
                  />
                  <Route
                    exact
                    path="/zenn/:edit_id/:selectedTab"
                    component={withLocalization(ZennPortal)}
                  />
                  <Route
                    exact
                    path="/CreateTemplate"
                    component={withLocalization(CreateTemplate)}
                  />
                  <Route
                    exact
                    path="/PointsProgram"
                    component={withLocalization(PointsProgramIndex)}
                  />
                  <Route
                    exact
                    path="/PointsProgramCreate"
                    component={withLocalization(PointsProgram)}
                  />
                  <Route
                    exact
                    path="/PointsProgramFAQs"
                    component={withLocalization(PointsProgramFAQs)}
                  />
                  <Route
                    exact
                    path="/PointsProgramTiers"
                    component={withLocalization(PointsProgramTiers)}
                  />
                  <Route
                    exact
                    path="/PointsProgramTiersPerks"
                    component={withLocalization(PointsProgramTiersPerks)}
                  />

                  <Route
                    exact
                    path="/subscriptionView"
                    component={withLocalization(SubscriptionView)}
                  />
                  <Route
                    exact
                    path="/rewardsPage"
                    component={withLocalization(ChooseTemplate)}
                  />
                  <Route
                    exact
                    path="/rewardsPage/:edit_id/:template_id"
                    component={withLocalization(ChooseTemplate)}
                  />

                  <Route
                    exact
                    path="/pointsProgram/:selected_tab"
                    component={() => (
                      <PointsProgramIndex
                        i18n={i18n}
                        mixpanelId={mixpanelId}
                        domain={props.domain}
                        formatDate={formatDate}
                      />
                    )}
                  />
                  <Route
                    exact
                    path="/pointsProgram/:selected_tab/:edit_id"
                    component={() => (
                      <PointsProgramIndex
                        i18n={i18n}
                        mixpanelId={mixpanelId}
                        domain={props.domain}
                        formatDate={formatDate}
                      />
                    )}
                  />
                  <Route
                    exact
                    path="/pointsProgram/:selected_tab/:edit_id/:template_id"
                    component={() => (
                      <PointsProgramIndex
                        i18n={i18n}
                        mixpanelId={mixpanelId}
                        domain={props.domain}
                        formatDate={formatDate}
                      />
                    )}
                  />

                  <Route
                    exact
                    path="/createQuiz"
                    component={withLocalization(CreateQuiz)}
                  />
                  <Route
                    exact
                    path="/quizes"
                    component={withLocalization(Quizzes)}
                  />
                  <Route
                    exact
                    path="/chargezenUpsells"
                    component={withLocalization(UpsellOverview)}
                  />
                  <Route
                    exact
                    path="/chargezenUpsells/manage"
                    component={withLocalization(Upsells)}
                  />
                  <Route
                    exact
                    path="/chargezenUpsells/cartFunnels"
                    component={withLocalization(CartFunnels)}
                  />
                  <Route
                    exact
                    path="/chargezenUpsells/cartFunnels/:template_id"
                    component={withLocalization(CartFunnels)}
                  />
                  <Route
                    exact
                    path="/chargezenUpsells/cartFunnels/:template_id/:edit_id"
                    component={withLocalization(CartFunnels)}
                  />

                  <Route
                    exact
                    path="/chargezenUpsells/manage/:edit_id"
                    component={withLocalization(Upsells)}
                  />
                  <Route
                    exact
                    path="/chargezenUpsells/manage/create/:template_id"
                    component={withLocalization(Upsells)}
                  />
                  <Route
                    exact
                    path="/bundle"
                    component={withLocalization(Bundles)}
                  />
                  <Route
                    exact
                    path="/bundle/:edit_id"
                    component={withLocalization(Bundles)}
                  />
                  <Route
                    exact
                    path="/bundle/create/:template_id"
                    component={withLocalization(Bundles)}
                  />

                  <Route
                    exact
                    path="/CreateLoisaWeeklyMenu"
                    component={withLocalization(CreateLoisaWeeklyMenu)}
                  />
                  <Route
                    exact
                    path="/CustomerDetail"
                    component={withLocalization(CustomerDetail)}
                  />
                  <Route
                    exact
                    path="/MLAlgorithms"
                    component={withLocalization(MLAlgorithms)}
                  />
                  <Route
                    exact
                    path="/kMeansClusters"
                    component={withLocalization(MLAlgorithms)}
                  />
                  <Route
                    exact
                    path="/sentiment-analysis"
                    component={withLocalization(MLAlgorithms)}
                  />
                  <Route
                    exact
                    path="/anomaly-detection"
                    component={withLocalization(MLAlgorithms)}
                  />
                  <Route
                    exact
                    path="/market-basket-analysis"
                    component={withLocalization(MLAlgorithms)}
                  />

                  <Route
                    exact
                    path="/YodaRevenueCopilot"
                    component={withLocalization(YodaRevenue)}
                  />
                  <Route
                    exact
                    path="/SubscriptionProducts"
                    component={withLocalization(SubscriptionProducts)}
                  />
                  <Route
                    exact
                    path="/CreateSubscriptionProduct"
                    component={withLocalization(CreateSubscriptionProduct)}
                  />
                  <Route
                    exact
                    path="/memberships"
                    component={withLocalization(Memberships)}
                  />
                  <Route
                    exact
                    path="/createMemberships"
                    component={withLocalization(CreateMembership)}
                  />
                  <Route
                    exact
                    path="/preOrders"
                    component={withLocalization(PreOrders)}
                  />
                  <Route
                    exact
                    path="/createTryBeforeYouBuy"
                    component={withLocalization(CreateTryBeforeYouBuy)}
                  />
                  <Route
                    exact
                    path="/createTryBeforeYouBuy/:id"
                    component={withLocalization(CreateTryBeforeYouBuy)}
                  />
                  <Route
                    exact
                    path="/createPreorder"
                    component={withLocalization(CreatePreorder)}
                  />
                  <Route
                    exact
                    path="/createPreorder/:id"
                    component={withLocalization(CreatePreorder)}
                  />
                  <Route
                    exact
                    path="/tryBeforeYouBuy"
                    component={withLocalization(TryBeforeYouBuy)}
                  />
                  <Route
                    exact
                    path="/createRebuy"
                    component={withLocalization(CreateRebuy)}
                  />
                  <Route
                    exact
                    path="/createRebuy/:id"
                    component={withLocalization(CreateRebuy)}
                  />
                  <Route
                    exact
                    path="/rebuy"
                    component={withLocalization(Rebuy)}
                  />
                  <Route
                    exact
                    path="/bundles"
                    component={withLocalization(BundleMenus)}
                  />
                  <Route
                    exact
                    path="/createBundleMenu"
                    component={withLocalization(CreateBundleMenu)}
                  />
                  <Route
                    exact
                    path="/stripeContractsList"
                    component={withLocalization(StripeContractsList)}
                  />
                  <Route
                    exact
                    path="/createStripeContract"
                    component={withLocalization(StripeContract)}
                  />
                  <Route
                    exact
                    path="/referrals"
                    component={withLocalization(Referrals)}
                  />
                  {/* <Route exact path="/pointsProgram" component={withLocalization(WaysToEarnPoint)} /> */}
                  <Route
                    exact
                    path="/notifications"
                    component={withLocalization(Notifications)}
                  />
                  <Route
                    exact
                    path="/chooseTemplate"
                    component={withLocalization(LoyaltyRewards)}
                  />
                  <Route
                    exact
                    path="/manage-staff"
                    component={withLocalization(ManageStaff)}
                  />
                  <Route
                    exact
                    path="/"
                    component={withLocalization(NewDashboard)}
                  />
                  <Route
                    exact
                    path="/customer-model"
                    component={withLocalization(CustomerModal)}
                  />
                  <Route
                    exact
                    path="/customer-model/:token"
                    component={withLocalization(CustomerModal)}
                  />

                  <Route
                    exact
                    path="/fixed-subscription-plans/:id"
                    component={withLocalization(FixedPlan)}
                  />
                  <Route
                    exact
                    path="/power-view-plan/:id/"
                    component={withLocalization(PowerView)}
                  />
                  <Route
                    exact
                    path="/fixed-subscription-plans"
                    component={withLocalization(FixedPlan)}
                  />
                  <Route
                    exact
                    path="/trial-subscription-plan/:id"
                    component={withLocalization(TrialPlan)}
                  />
                  <Route
                    exact
                    path="/trial-subscription-plan"
                    component={withLocalization(TrialPlan)}
                  />
                  <Route
                    exact
                    path="/build-a-box-subscription-plan/:id"
                    component={withLocalization(BuildABoxPlan)}
                  />
                  <Route
                    exact
                    path="/build-a-box-subscription-plan"
                    component={withLocalization(BuildABoxPlan)}
                  />
                  <Route
                    exact
                    path="/mystery-box-subscription-plan/:id"
                    component={withLocalization(MysteryBoxPlan)}
                  />
                  <Route
                    exact
                    path="/mystery-box-subscription-plan"
                    component={withLocalization(MysteryBoxPlan)}
                  />
                  <Route
                    exact
                    path="/newDash"
                    component={() => (
                      <Dashboard
                        domain={props.domain}
                        i18n={i18n}
                        mixpanelId={mixpanelId}
                        formatDate={formatDate}
                      />
                    )}
                  />
                  <Route
                    exact
                    path="/integrations"
                    component={withLocalization(Integrations)}
                  />
                  <Route
                    exact
                    path="/integration-detail/:id/:title/:keys?"
                    component={withLocalization(IntegrationDetail)}
                  />
                  <Route
                    exact
                    path="/smartyoverview"
                    component={withLocalization(SmartyOverview)}
                  />
                  <Route
                    exact
                    path="/smarty"
                    component={withLocalization(Smarty)}
                  />
                  <Route
                    exact
                    path="/smarty/:edit_id"
                    component={withLocalization(Smarty)}
                  />
                  <Route
                    exact
                    path="/edit-smarty-message/:id"
                    component={withLocalization(EditSmartyMessage)}
                  />
                  <Route
                    exact
                    path="/kioskpos"
                    component={withLocalization(KioskOverview)}
                  />
                  <Route
                    exact
                    path="/replenishme"
                    component={withLocalization(ReplenishmeOverview)}
                  />
                  <Route
                    exact
                    path="/app-settings"
                    component={() => (
                      <Settings
                        i18n={i18n}
                        passwordProtected={passwordProtected}
                        setPasswordProtected={setPasswordProtected}
                        domain={props.domain}
                        formatDate={formatDate}
                        shopVersion={shopVersion}
                        shopApiData={shopApiData}
                        currentPlan={currentPlan}
                        setCurrentPlan={setCurrentPlan}
                      />
                    )}
                  />
                  <Route
                    exact
                    path="/app-settings/:tabname"
                    component={() => (
                      <Settings
                        i18n={i18n}
                        passwordProtected={passwordProtected}
                        setPasswordProtected={setPasswordProtected}
                        domain={props.domain}
                        formatDate={formatDate}
                        shopVersion={shopVersion}
                        shopApiData={shopApiData}
                        currentPlan={currentPlan}
                        setCurrentPlan={setCurrentPlan}
                      />
                    )}
                  />
                  <Route
                    exact
                    path="/app-settings/:tabname/:edit_id"
                    component={() => (
                      <Settings
                        i18n={i18n}
                        passwordProtected={passwordProtected}
                        setPasswordProtected={setPasswordProtected}
                        domain={props.domain}
                        formatDate={formatDate}
                        shopVersion={shopVersion}
                        shopApiData={shopApiData}
                        currentPlan={currentPlan}
                        setCurrentPlan={setCurrentPlan}
                      />
                    )}
                  />
                  <Route
                    exact
                    path="/customers"
                    component={withLocalization(Customers)}
                  />
                  <Route
                    exact
                    path="/customersTab/:selected_tab"
                    component={withLocalization(Customers)}
                  />
                  <Route
                    exact
                    path="/customersTab/:selected_tab/:edit_id"
                    component={withLocalization(Customers)}
                  />
                  <Route
                    exact
                    path="/customersTab/:selected_tab/:edit_id/:template_id"
                    component={withLocalization(Customers)}
                  />
                  <Route
                    exact
                    path="/customers/:contractToken"
                    component={withLocalization(Customers)}
                  />
                  <Route
                    exact
                    path="/subscriptionContracts"
                    component={() => (
                      <Customers
                        i18n={i18n}
                        mixpanelId={mixpanelId}
                        domain={props.domain}
                        selected_tab="contracts"
                        formatDate={formatDate}
                      />
                    )}
                  />
                  <Route
                    exact
                    path="/subscriptionOrders"
                    component={() => (
                      <Customers
                        i18n={i18n}
                        mixpanelId={mixpanelId}
                        domain={props.domain}
                        selected_tab="subscription_orders"
                        formatDate={formatDate}
                      />
                    )}
                  />
                  <Route
                    exact
                    path="/subscription-plans"
                    component={() => (
                      <Customers
                        i18n={i18n}
                        mixpanelId={mixpanelId}
                        domain={props.domain}
                        selected_tab="subscription_plans"
                        formatDate={formatDate}
                      />
                    )}
                  />
                  <Route
                    exact
                    path="/bulkAction"
                    component={() => (
                      <Customers
                        i18n={i18n}
                        mixpanelId={mixpanelId}
                        domain={props.domain}
                        selected_tab="bulk_action"
                        formatDate={formatDate}
                      />
                    )}
                  />
                  <Route
                    exact
                    path="/customerMigration"
                    component={() => (
                      <Customers
                        i18n={i18n}
                        mixpanelId={mixpanelId}
                        domain={props.domain}
                        selected_tab="customerMigration"
                        formatDate={formatDate}
                      />
                    )}
                  />
                  <Route
                    exact
                    path="/subscriptionFlows"
                    component={() => (
                      <Customers
                        i18n={i18n}
                        mixpanelId={mixpanelId}
                        domain={props.domain}
                        selected_tab="subscription_workflow"
                        formatDate={formatDate}
                      />
                    )}
                  />
                  <Route
                    exact
                    path="/subscriptionFlows/:workflowId"
                    component={() => (
                      <Customers
                        i18n={i18n}
                        mixpanelId={mixpanelId}
                        domain={props.domain}
                        selected_tab="subscription_workflow"
                        formatDate={formatDate}
                      />
                    )}
                  />
                  <Route
                    exact
                    path="/globalSettings"
                    component={() => (
                      <Customers
                        i18n={i18n}
                        mixpanelId={mixpanelId}
                        domain={props.domain}
                        selected_tab="global_billing_hour"
                        formatDate={formatDate}
                      />
                    )}
                  />
                  <Route
                    exact
                    path="/subProducts"
                    component={() => (
                      <Customers
                        i18n={i18n}
                        mixpanelId={mixpanelId}
                        domain={props.domain}
                        selected_tab="subscription_products"
                        formatDate={formatDate}
                      />
                    )}
                  />
                  <Route
                    exact
                    path="/subProducts/:productId"
                    component={() => (
                      <Customers
                        i18n={i18n}
                        mixpanelId={mixpanelId}
                        domain={props.domain}
                        selected_tab="subscription_products"
                        formatDate={formatDate}
                      />
                    )}
                  />
                  <Route
                    exact
                    path="/dunning"
                    component={() => (
                      <Customers
                        i18n={i18n}
                        mixpanelId={mixpanelId}
                        domain={props.domain}
                        selected_tab="subscription_billing"
                        formatDate={formatDate}
                      />
                    )}
                  />
                  <Route
                    exact
                    path="/subEmail"
                    component={() => (
                      <Customers
                        i18n={i18n}
                        mixpanelId={mixpanelId}
                        domain={props.domain}
                        selected_tab="subscription_email"
                        formatDate={formatDate}
                      />
                    )}
                  />
                  <Route
                    exact
                    path="/subEmail/:emailId"
                    component={() => (
                      <Customers
                        i18n={i18n}
                        mixpanelId={mixpanelId}
                        domain={props.domain}
                        selected_tab="subscription_email"
                        formatDate={formatDate}
                      />
                    )}
                  />
                  {/* <Route exact path="/notifications" component={() => <Customers i18n={i18n} mixpanelId={mixpanelId} domain={props.domain} selected_tab="notifications" />} /> */}

                  <Route
                    exact
                    path="/customers/new"
                    component={withLocalization(CreateCustomer)}
                  />
                  <Route
                    exact
                    path="/customers/:id/edit"
                    component={withLocalization(CreateCustomer)}
                  />
                  <Route
                    exact
                    path="/analytics"
                    component={withLocalization(Analytics)}
                  />
                  <Route
                    exact
                    path="/analytics/:selectedTab"
                    component={withLocalization(Analytics)}
                  />
                  <Route
                    exact
                    path="/analytics/:selectedTab/:subTab"
                    component={withLocalization(Analytics)}
                  />
                  <Route
                    exact
                    path="/installation"
                    component={() => (
                      <Installation
                        i18n={i18n}
                        shopifyDomain={props.domain}
                        passwordProtected={passwordProtected}
                        formatDate={formatDate}
                      />
                    )}
                  />
                  <Route
                    exact
                    path="/chargezenUpsell"
                    component={withLocalization(Upsell)}
                  />
                  <Route
                    exact
                    path="/chargezenUpsell/:id/edit"
                    component={withLocalization(CreateUpsell)}
                  />
                  <Route
                    exact
                    path="/chargezenUpsell/new"
                    component={withLocalization(CreateUpsell)}
                  />

                  <Route
                    exact
                    path="/build-a-box"
                    component={withLocalization(BuildBox)}
                  />
                  <Route
                    exact
                    path="/build-a-box/:id/edit"
                    component={withLocalization(CreateBuildBox)}
                  />
                  <Route
                    exact
                    path="/build-a-box/new"
                    component={withLocalization(CreateBuildBox)}
                  />

                  <Route
                    exact
                    path="/tiazen"
                    component={withLocalization(Tiazen)}
                  />
                  <Route
                    exact
                    path="/tiazen/:tabname"
                    component={withLocalization(Tiazen)}
                  />
                  <Route
                    exact
                    path="/toolbox"
                    component={withLocalization(Toolbox)}
                  />
                  <Route
                    exact
                    path="/toolbox/:selected_tab"
                    component={withLocalization(Toolbox)}
                  />
                  <Route
                    exact
                    path="/toolbox/:selected_tab/:edit_id"
                    component={withLocalization(Toolbox)}
                  />
                  <Route
                    exact
                    path="/toolbox/:selected_tab/:edit_id/:selectedTab"
                    component={withLocalization(Toolbox)}
                  />
                  <Route
                    exact
                    path="/choosePlan"
                    component={withLocalization(PlanBilling)}
                  />
                </AppLayout>
              </Switch>
            </ApolloProvider>
          </AppProvider>
        </DomainContext.Provider>
      </BrowserRouter>
    );
  } else {
    //remove previous ShopDomain from localStora
    localStorage.removeItem('currentShopDomain');
    return (
      <AppProvider i18n={enTranslations} theme={{}}>
        <DisabledNav i18n={i18n} />
        <InstallShop i18n={i18n} />
      </AppProvider>
    );
  }
}
