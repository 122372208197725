import { DatePicker } from '@shopify/polaris'
import dayjs from 'dayjs';
import React, { useCallback, useState } from 'react'

const RangeDatePicker = (props) => {
    const { selectedDates, setSelectedDates, month, year, setDate } = props;

    const handleMonthChange = useCallback(
        (month, year) => setDate({ month, year }),
        [],
    );
    const handleDateChange = (date) => {
        setSelectedDates(date);
    }
    return (
        <div className='custom_date_range_filter_container'>
            <DatePicker
                month={month}
                year={year}
                onChange={handleDateChange}
                onMonthChange={handleMonthChange}
                selected={selectedDates}
                allowRange
            />
        </div>
    )
}

export default RangeDatePicker