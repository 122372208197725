import React from 'react';
import CustomImageDropzone from './CustomImageDropzone';
import { TextField } from '@shopify/polaris';

const ManageSteps = (props) => {
  const {
    currentContentBlock,
    handleStepValueChange,
    currentStep,
    setCurrentStep,
  } = props;
  const handleCurentStep = (index) => {
    currentContentBlock[index];
    setCurrentStep(currentContentBlock?.steps?.[index]);
  };
  return (
    <>
      {currentStep && (
        <>
          <div className="card_body_content">
            <TextField
              label="Title"
              value={currentStep.title}
              onChange={(e) => handleStepValueChange(e, stepIndex, 'title')}
            />
          </div>
          <div className="card_body_content">
            <TextField
              multiline={3}
              label="Body"
              value={step.body}
              onChange={(e) => handleStepValueChange(e, stepIndex, 'body')}
            />
          </div>
          <div className="card_body_content">
            <CustomImageDropzone
              formData={formData}
              setFormData={setFormData}
              handleStepValueChange={handleStepValueChange}
              stepIndex={stepIndex}
              fieldName="image"
              step={step}
            />
          </div>
          <div className="card_body_content">
            <TextField
              label="Image size"
              value={step.image_size}
              onChange={(e) =>
                handleStepValueChange(e, stepIndex, 'image_size')
              }
            />
          </div>
        </>
      )}
      <ul className="polaris-card content_block_lists add_new_step">
        {currentContentBlock?.steps?.map((step, stepIndex) => {
          return (
            <li
              onClick={(e) => {
                handleCurentStep(stepIndex);
              }}
            >
              <span className="left_section">{step?.title}</span>
            </li>
          );
        })}
        <li
          onClick={(e) => {
            addNewStep();
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="lucide lucide-plus-square"
          >
            <rect width="18" height="18" x="3" y="3" rx="2" />
            <path d="M8 12h8" />
            <path d="M12 8v8" />
          </svg>
          <span className="left_section">New Step</span>
        </li>
      </ul>
    </>
  );
};

export default ManageSteps;
