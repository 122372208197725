import React from 'react';
import { Card, TextField } from '@shopify/polaris';

const ManageForm = (props) => {
  const { formData, setFormData } = props;
  return (
    <>
      <Card>
        <Card.Section>
          <div className="card_body_content">
            <TextField
              label="Value label"
              value={formData.value_label}
              onChange={(e) => setFormData({ ...formData, value_label: e })}
            />
          </div>
          <div className="card_body_content">
            <TextField
              label="Recipient Name label"
              value={formData.recipient_name_label}
              onChange={(e) =>
                setFormData({ ...formData, recipient_name_label: e })
              }
            />
          </div>
          <div className="card_body_content">
            <TextField
              label="Recipient Email label"
              value={formData.recipient_email_label}
              onChange={(e) =>
                setFormData({ ...formData, recipient_email_label: e })
              }
            />
          </div>
          <div className="card_body_content">
            <TextField
              label="Delivery Date label"
              value={formData.delivery_date_label}
              onChange={(e) =>
                setFormData({ ...formData, delivery_date_label: e })
              }
            />
          </div>
          <div className="card_body_content">
            <TextField
              label="Gift note label"
              value={formData.gift_note_label}
              onChange={(e) => setFormData({ ...formData, gift_note_label: e })}
            />
          </div>
        </Card.Section>
      </Card>
    </>
  );
};

export default ManageForm;
