import React from 'react';
import supportImage4 from '../../images/women_avatar1.png';
import supportImage3 from '../../images/men_avatar1.png';
import supportImage2 from '../../images/women_avatar2.png';
import supportImage1 from '../../images/men_avatar2.png';

const ChatWithUs = () => {
  return (
    <div className="chargezen_chat_with_us_container">
      <h1>Having issues setting up or using Chargezen?</h1>
      <div className="chargezen_chat_with_us_wrapper">
        <div className="left_section_wrapper">
          <div className="image_wrapper">
            <img src={supportImage1} />
          </div>
          <div className="image_wrapper">
            <img src={supportImage2} />
          </div>
          <div className="image_wrapper">
            <img src={supportImage3} />
          </div>
          <div className="image_wrapper">
            <img src={supportImage4} />
          </div>
        </div>
        <div className="right_section_wrapper">
          <p>
            If you have any questions or run into issues, feel free to chat with
            us. Our customer success and technical support team are on standby
            24/7 to help you via WhatsApp and get things sorted out in just a
            few minutes.
          </p>
          <button
            className="chat_with_us_button"
            onClick={() => {
              window.open('https://wa.me/message/AZADOOG7TYSEC1', '_blank');
            }}
          >
            Chat with us
          </button>
        </div>
      </div>
    </div>
  );
};

export default ChatWithUs;
