import React, { useState, useCallback, useEffect } from 'react';
import { Card, Tabs, Frame, Page, Icon, Toast } from '@shopify/polaris';
import '@shopify/polaris/dist/styles.css';
import SlideItem from './slideItem';
import {
  DeleteMajor,
  NoteMinor,
  ChevronRightMinor,
} from '@shopify/polaris-icons';
import EditReferralPage from './EditReferralPage';
import { useHistory } from 'react-router-dom';
import { useParams } from 'react-router-dom';

function ReferralPage({ domain, i18n, detailView, setDetailView }) {
  const [selected, setSelected] = useState(0);
  const { edit_id } = useParams();
  const history = useHistory();
  const [toastContent, setToastContent] = useState("");
  const [toastActive, setToastActive] = useState(false);
  const toggleToastActive = useCallback(() => setToastContent(), []);

  const handleTabChange = useCallback((selectedTabIndex) => {
    setSelected(selectedTabIndex);
  }, []);

  const [selectedTemplateId, setSelectedTemplateId] = useState();

  const tabs = [
    {
      id: 'all-customers-1',
      content: 'All',
      accessibilityLabel: 'All customers',
      panelID: 'all-customers-content-1',
    },
    {
      id: 'Food-&-Beverage',
      content: 'Food & Beverage',
      panelID: 'Food-&-Beverage',
    },
    {
      id: 'Health-&-Beauty',
      content: 'Health & Beauty',
      panelID: 'Health-&-Beauty',
    },
    {
      id: 'Gift-Guide',
      content: 'Gift Guide',
      panelID: 'Gift-Guide',
    },
    {
      id: 'Fashion',
      content: 'Fashion',
      panelID: 'Fashion',
    },
    {
      id: 'Furniture',
      content: 'Furniture',
      panelID: 'Furniture',
    },
    {
      id: 'Technology',
      content: 'Technology',
      panelID: 'Technology',
    },
  ];
  const tabsData = [
    {
      images: [
        'https://dashboard.quizkitapp.com/_next/image?url=https%3A%2F%2Fquiz-kit-files.s3.us-east-2.amazonaws.com%2Fimages%2F1%2FuzIGKu8QU9oavdeUgBVA&w=1920&q=75',
        'https://dashboard.quizkitapp.com/_next/image?url=https%3A%2F%2Fquiz-kit-files.s3.us-east-2.amazonaws.com%2Fimages%2F1%2Fh3sNy4o9Sxwzuz5l6EpQ&w=1920&q=75',
      ],
      name: 'ReferralPage Template #1',
      categories_data: 'Referral Page',
    },
  ];

  useEffect(() => {
    if (edit_id) {
      setSelectedTemplateId(edit_id);
    }
  }, []);

  return (
    <Frame>
      <div className="rewards_template_wrapper zenn_portal_wrapper">
        {
          toastContent && (
            <Toast content={toastContent} onDismiss={toggleToastActive} />
          )
        }
        {edit_id ? (
          <EditReferralPage
            i18n={i18n}
            domain={domain}
            setSelectedTemplateId={setSelectedTemplateId}
            {...{
              toastContent, setToastContent, toastActive, setToastActive, toggleToastActive
            }}
          />
        ) : (
          <>
            <div className="rewards_choose_template show_content_2">
              <Card>
                <Tabs
                  tabs={tabs}
                  selected={selected}
                  onSelect={handleTabChange}
                >
                  <Card.Section title={tabs[selected].content}>
                    {/* <p>Tab {selected} selected</p> */}
                    <div class="sort-by">
                      <div class="inner-wrap-sort">
                        <label>Sort by:</label>
                        <select
                          id="PolarisSelect1"
                          class="Polaris-Select__Input"
                          aria-invalid="false"
                        >
                          <option value="popular">Popularity</option>
                          <option value="recent">Most recent</option>
                        </select>
                        <svg
                          viewBox="0 0 20 20"
                          class="Polaris-Icon__Svg"
                          focusable="false"
                          aria-hidden="true"
                        >
                          <path d="M7.676 9h4.648c.563 0 .879-.603.53-1.014l-2.323-2.746a.708.708 0 0 0-1.062 0l-2.324 2.746c-.347.411-.032 1.014.531 1.014Zm4.648 2h-4.648c-.563 0-.878.603-.53 1.014l2.323 2.746c.27.32.792.32 1.062 0l2.323-2.746c.349-.411.033-1.014-.53-1.014Z"></path>
                        </svg>
                      </div>
                    </div>
                    {tabsData?.map((data) => {
                      return (
                        <SlideItem
                          data={data}
                          setSelectedTemplateId={setSelectedTemplateId}
                        />
                      );
                    })}
                  </Card.Section>
                </Tabs>
              </Card>
            </div>
          </>
        )}
      </div>
    </Frame>
  );
}

export default ReferralPage;
