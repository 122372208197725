import { Button, ButtonGroup, Checkbox, FormLayout, Icon } from '@shopify/polaris'
import { AddMajor, ArrowDownMinor, ArrowUpMinor, DeleteMajor } from '@shopify/polaris-icons'
import React, { useEffect, useState } from 'react'
import EditCancellation from './EditCancellation';

const CancellationReasons = (props) => {
    const {
        i18n,
        selectedTemplateId,
        domain,
        toggleActive,
        setFormData,
        formData,
        edit,
        showEdit,
        cancellationReasons,
        setCancellationReasons,
        setToastContent,
        fetch_cancellation_flow
    } = props;

    // useEffect(() => {
    //     const cancellation_reasons = [
    //         {
    //             cancellation_reason: "Not satisfied with the product(s) quality",
    //             show_options_to_continue: false,
    //             custom_message_options: "",
    //             custom_message_confirmation: "",
    //             index: 0
    //         },
    //         {
    //             cancellation_reason: "No longer need the product(s)",
    //             show_options_to_continue: false,
    //             custom_message_options: "",
    //             custom_message_confirmation: "",
    //             index: 1
    //         },
    //         {
    //             cancellation_reason: "Already have enough stock",
    //             show_options_to_continue: false,
    //             custom_message_options: "",
    //             custom_message_confirmation: "",
    //             index: 2
    //         },
    //         {
    //             cancellation_reason: "Too expensive to continue",
    //             show_options_to_continue: false,
    //             custom_message_options: "",
    //             custom_message_confirmation: "",
    //             index: 3
    //         },
    //         {
    //             cancellation_reason: "Other reason",
    //             show_options_to_continue: false,
    //             custom_message_options: "",
    //             custom_message_confirmation: "",
    //             index: 4
    //         },
    //     ]
    //     setCancellationReasons([...cancellation_reasons])
    // }, [])

    const initCancellationReason = {
        title: "",
        custom_message_for_cancellation: "",
        custom_message_for_cancellation_confirmation: "",
        delay_sub: false,
        pause_sub: false,
        skip_next_payment: false,
        swap_product: false,
        update_delivery_frequency: false,
        show_options_to_continue: false,
        show_comment_field_for_reason: false
    }

    const changeIndex = (indexToChange, position) => {
        let cancellation_reasons = [...cancellationReasons]
        let swapIndex = indexToChange
        if (position == "up") {
            swapIndex -= 1;
        } else {
            swapIndex += 1;
        }
        cancellation_reasons[swapIndex] = cancellation_reasons[indexToChange]
        cancellation_reasons[indexToChange] = cancellationReasons[swapIndex]
        setCancellationReasons([...cancellation_reasons])
        reorderReasons(cancellation_reasons)
    }

    const reorderReasons = (cancellation_reasons) => {
        let reordered = cancellation_reasons?.map((c, i) => {
            return ({ id: c.id, position: i + 1 })
        })

        fetch('/customer_portal/reorder_cancellation_reasons', {
            method: 'POST',
            body: JSON.stringify({ reordered: reordered, shopify_domain: domain }),
            headers: {
                'Content-Type': 'application/json',
            },
        }).then(r => r.json())
        .then((data)=>{
            if(data?.success){
                
            }
        })
    }

    const deleteCancellationReason = (id) => {
        fetch('/customer_portal/delete_cancellation_reason/'+id+"?shopify_domain="+domain, {
            method: 'DELETE',
        }).then(r => r.json())
        .then((data)=>{
            if(data?.success){
                setToastContent("Deleted Successfully");
                fetch_cancellation_flow();
            }
        })
    }

    const addCancellation = () => {
        showEdit(initCancellationReason)
    }

    return (
        <>
            <div className="show_cancellation_instructions_container cancellation_flow_row_wrapper">
                <h6 className="description">
                    Create customized cancellation options to reduce subscription cancellations by incentivizing customers to continue their subscription.
                </h6>
                <FormLayout.Group>
                    <div className='cancellation_reason_header'>
                        <div className="enable_disable_button_wrapper">
                            <span className="text">
                                Cancellation reason flow is currently {formData?.cancellation_reasons_options ? "enabled" : "disabled"}
                            </span>
                            <span className="button_wrapper">
                                <button
                                    className={`enable_disable_button ${formData?.cancellation_reasons_options ? "" : "enabled"}`}
                                    onClick={() => { setFormData({ ...formData, cancellation_reasons_options: !formData?.cancellation_reasons_options }) }}
                                >
                                    {formData?.cancellation_reasons_options ? "Disable" : "Enable"}
                                </button>
                            </span>
                        </div>
                        <div className='make_cancellation_reason_optional'>
                            <Checkbox
                                label="Make cancellation reasons optional"
                            />
                        </div>
                    </div>
                </FormLayout.Group>

                <div className="show_cancellation_instructions_wrapper cancellation_reasons_container">
                    <h5>Cancellation Reasons</h5>
                    <div className="cancellation_reasons_wrapper">
                        {
                            cancellationReasons?.map((cancellationReason, i) => {
                                return (
                                    <>
                                        <div className="chargezen_row">
                                            <div className="left">{cancellationReason?.title}</div>
                                            <div className="right">
                                                <ButtonGroup>
                                                    <Button
                                                        onClick={() => { showEdit(cancellationReason) }}
                                                    >
                                                        Edit
                                                    </Button>
                                                    <Button
                                                        disabled={i == 0}
                                                        onClick={() => { changeIndex(i, "up") }}
                                                    >
                                                        <Icon
                                                            source={ArrowUpMinor}
                                                            tone="base"
                                                        />
                                                    </Button>
                                                    <Button
                                                        disabled={i == (cancellationReasons.length - 1)}
                                                        onClick={() => { changeIndex(i, "down") }}
                                                    >
                                                        <Icon
                                                            source={ArrowDownMinor}
                                                            tone="base"
                                                        />
                                                    </Button>
                                                    <Button
                                                        onClick={()=>{deleteCancellationReason(cancellationReason?.id)}}
                                                    >
                                                        <Icon
                                                            source={DeleteMajor}
                                                            tone="base"
                                                        />
                                                    </Button>
                                                </ButtonGroup>
                                            </div>
                                        </div>
                                    </>
                                )
                            })
                        }
                        <div className='add_cancellation_button_wrapper'>
                            <button className='add_cancellation_button' onClick={() => { addCancellation() }}>
                                <span>
                                    <svg viewBox="0 0 20 20" class="Polaris-Icon__Svg" focusable="false" aria-hidden="true"><path d="M0 10c0 5.514 4.486 10 10 10s10-4.486 10-10-4.486-10-10-10-10 4.486-10 10zm5 0a1 1 0 0 1 1-1h3v-3a1 1 0 1 1 2 0v3h3a1 1 0 1 1 0 2h-3v3a1 1 0 1 1-2 0v-3h-3a1 1 0 0 1-1-1z"></path></svg>
                                </span>
                                <span>Add cancellation reason</span>
                            </button>
                        </div>
                    </div>
                </div>

            </div>
        </>

    )
}

export default CancellationReasons