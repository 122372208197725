import React from 'react';
import { ProfileMajor, PaymentsMajor } from '@shopify/polaris-icons';
import { Icon } from '@shopify/polaris';
import { useParams, useHistory } from 'react-router-dom';

const CustomerPostalSettingSideBar = (props) => {
  const { settingsSideBar, templateName, domain } = props;
  const { edit_id, selectedTab } = useParams();

  const history = useHistory();
  const changeTab = (tab) => {
    history.push(`/zenn/${edit_id}/${tab}`);
  };

  function textToAcronym(text) {
    try {
      if (text.includes('-')) {
        return text
          .split('-')
          .map((d) => d[0]?.toUpperCase())
          .join('');
      } else if (text.includes(' ')) {
        return text
          .split(' ')
          .map((d) => d[0]?.toUpperCase())
          .join('');
      } else {
        return `${text[0]?.toUpperCase()}${text[1]?.toUpperCase()}`;
      }
    } catch (error) {
      return '';
    }
  }
  return (
    <div className="affiliate_setting_side_container">
      <div className="affiliate_setting_side_wrapper">
        <div className="affiliate_setting_sidebar_header">
          <button className="header_dropdown_button">
            <span className="icon-text">{textToAcronym(domain)}</span>
            <span>
              <div className="affiliate_user_name_wrapper">{domain}</div>
              <div
                className="affiliate_email_wrapper"
                style={{ textAlign: 'left' }}
              >
                Customer portal settings
              </div>
            </span>
          </button>
        </div>
        <div className="affiliate_setting_sidebar_body">
          <ul className="setting_item_list">
            {settingsSideBar?.map((sidebar) => {
              return (
                <li
                  className={`${sidebar?.tab === selectedTab ? 'active' : ''}`}
                  onClick={() => changeTab(sidebar?.tab)}
                >
                  <div class="setting_list_item">
                    <a className="">
                      <div class="setting_list_item_wrapper">
                        <div>
                          <span class="icon_wrapper">{sidebar?.icon}</span>
                        </div>
                        <span class="tab_text">
                          <span>{sidebar?.title}</span>
                        </span>
                        <div></div>
                      </div>
                    </a>
                  </div>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default CustomerPostalSettingSideBar;
